export class NewTruckloadAuditIncident {
  constructor(id: number, shipmentId: number, reason: string, comment: string, userName: string) {
    this.invoiceID = id;
    this.shipmentID = shipmentId;
    this.incidents = [];
    this.incidents.push(new NewTruckloadAuditIncidentType(reason, comment));
    this.userName = userName;
  }
  invoiceID: number;
  shipmentID: number;
  incidents: NewTruckloadAuditIncidentType[];
  userName: string;
}

export class NewTruckloadAuditIncidentType {
  constructor(reason: string, comment: string) {
    this.type = reason;
    this.comment = comment;
    this.assignments = [];
  }
  type: string;
  comment: string;
  assignments: any;
}

export class UpdateAssignment {
  invoiceIDs: number[];
  name: string;
  email: string;
  userName: string;
  constructor(invoiceIDs: number[], name: string, email: string, userName: string) {
    this.invoiceIDs = invoiceIDs;
    this.name = name;
    this.email = email;
    this.userName = userName;
  }
}

export class UpdateIncident {
  incidentID: number;
  shipmentID: number;
  comment: string;
  isComplete: boolean;
  userName: string;
  constructor(reasonID: number, shipmentID: number, comment: string, isComplete: boolean, userName: string) {
    this.incidentID = reasonID;
    this.shipmentID = shipmentID;
    this.comment = comment;
    this.isComplete = isComplete;
    this.userName = userName;
  }
}
export class NewComment {
  incidentID: number;
  comment: string;
  userName: string;
  constructor(incidentID: number, comment: string, userName: string) {
    this.incidentID = incidentID;
    this.comment = comment;
    this.userName = userName;
  }
}
export class ResetTLInvoice {
  shipmentID: number;
  invoiceNumber: string;
}
