export class AuditNoteDetailResponse {
  noteID: number;
  noteDetailID: number;
  invoiceNumber: string;
  invoiceAuditID: number;
  noteDateCreated: Date;
  queueType: string;
  queueStatus: string;
  noteType: string;
  noteStatus: string;
  noteUserCreated: string;
  note: string;
  assignedTo: string;
  errorType: string;
  contractReason: string;
  settlementReason: string;
}

export class AuditUserResponse {
  id: number;
  email: string;
  fullName: string;
}

export class ShipmentInvoiceNoDetailResponse {
  invoiceID: number;
  shipmentID: number;
  analogousID: number;
  type: string;
  invoiceDate: Date;
  invoiceNumber: string;
  status: string;
  auditor: string;
  scac: string;
  mode: string;
  carrierName: string;
  mcNumber?: string;
  dotNumber?: string;
  carrierCode?: string;
  lane: string;
  glCode?: string;
  invoiceWeight?: number;
  actualShipDate?: Date;
  invoiceTotal?: number;
  normalizedTotal?: number;
  distance?: number;
  serviceTotal?: number;
  freightTotal?: number;
  normalizedCurrency: string;
  isSecondary: boolean;
  isBBill: boolean;
  bBillAccountNumber?: string;
  paymentTerms: string;
  isError: boolean;
  errorSource?: string;
  errorReason?: string;
  errorDate?: Date;
  currencyCode?: string;
  charges: Charge[];
}

export class Charge {
  invoiceChargeID: number;
  invoiceID: number;
  sequence: number;
  description: string;
  amount: number;
  ediCode: string;
  rate?: number;
  rateQualifier: string;
  quantity?: number;
  weight?: number;
  dimWeight?: number;
  freightClass?: number;
  fAKFreight?: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  type: string;
  group: string;
  chargeType: string;
  error: boolean;
}

export class InvoiceCharge {
  invoiceChargeID: number;
  description: string;
  rate?: number;
  rateQualifier: string;
  rateCode: string;
  quantity?: number;
  freightClass?: number;
  fakFreightClass?: number;
  type: string;
  amount: number;
  group: string;
  weight: number;
  dimWeight: number;
  ediCode: string;
  canEdit: boolean;
  isMin: boolean;
  isMax: boolean;
}

export interface Invoice {
  charges: InvoiceCharge[];
  distance?: number;
  currencyCode?: string;
}

export class CarrierEmailSentHistory {
  carrierInvoiceNumber: string;
  userInitials: string;
  sentDate: Date;
}
