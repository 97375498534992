import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TLEditUI } from '../../models/TLEdit.ui';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ChargeDescription, Globals } from '../../../../_shared/globals';
import { ChargeDTO, UpdateChargesRequest } from '../../../../models/rate';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ShipmentService } from '../../../../services/shipment.service';
import { CurrencyService } from '@/services/currency.service';

@Component({
  selector: 'app-tl-buy-sell-rate-edit',
  templateUrl: './tl-buy-sell-rate-edit.component.html',
  styleUrls: ['../../../shipment-invoice-audit/shipment-invoice-audit.component.scss',
    './tl-buy-sell-rate-edit.component.scss'],
})
export class TlBuySellRateEditComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  spinner: SpinnerComponent;

  @Input()
  parameters: EditRateParameters;

  @Output()
  close = new EventEmitter<boolean>();

  public areChargesValid: boolean = true;

  public _chargeDescriptions: string[];
  public _localChargeDescriptions: ChargeDescription[];

  readonly _rateQualifiers = Globals.Qualifiers
    .sort((left, right) => left.rateQualifier.localeCompare(right.rateQualifier));

  public get chargeDescriptions(): string[] {
    return this._chargeDescriptions;
  }

  public get rateQualifiers() {
    return this._rateQualifiers;
  }

  public editForm: FormGroup;
  public errorMessage: string = "";
  public disableSubmit: boolean;
  public indexOfChargeToDelete: number = -1;

  public currencyOptions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  };

  private currencyValueCAD: number;

  constructor(
    private formBuilder: FormBuilder,
    private shipmentService: ShipmentService,
    private pcCurrencyService: CurrencyService
  ) { }

  ngOnInit(): void {
    this.createEditForm();
    this.getCurrency();
  }

  getCurrency(): void {
    this.pcCurrencyService.getConversionRate(new Date)
      .subscribe(response => this.currencyValueCAD = response.find(data => data.country == 'CAN').value);
  }

  private setChargeTypeList() {
    let localChargeDescriptions = Globals.ChargeDescriptions.concat(Globals.ExtraChargeDescriptions);
    if (this.parameters !== undefined) {
      if (this.parameters.mode !== undefined && this.parameters.mode !== '') {
        if (this.parameters.mode.toLocaleLowerCase() === 'tl') {
          localChargeDescriptions = Globals.ChargeDescriptions.concat(Globals.ExtraChargeDescriptions).concat(
            Globals.truckloadChargeDescriptions
          );
        } else if (this.isAirOceanIntlGroundMode(this.parameters.mode.toLocaleLowerCase())) {
          localChargeDescriptions = Globals.ChargeDescriptions.concat(Globals.ExtraChargeDescriptions).concat(
            Globals.airOceanINTLGroundChargeDescriptions
          );
        } else if (this.parameters.mode.toLocaleLowerCase() === 'drayage') {
          localChargeDescriptions = Globals.ChargeDescriptions.concat(Globals.drayageChargeDescriptions);
        }
      }
    }
    this._localChargeDescriptions = localChargeDescriptions;
    this._chargeDescriptions = localChargeDescriptions.map((desc) => desc.name).sort((left, right) => left.localeCompare(right));
  }

  deleteCharge(index: number) {
    this.indexOfChargeToDelete = index;
  }

  save() {
    this.disableSubmit = true;
    this.spinner.loading = true;
    this.spinner.text = "Saving the charges...";



    this.shipmentService.updateCharges(this.createRequest()).subscribe(() => {
      this.closeDialog(true);
    }, (error) => {
      console.log(error);
      this.spinner.loading = false;
      this.disableSubmit = false;
      this.errorMessage = `An error ocurred while updating the charges: ${error.error || error}`;
    });
  }

  createRequest(): UpdateChargesRequest {
    const chargesFormArray = this.editForm.get("chargesFormArray") as FormArray;

    const isLinehaul = (description: string): boolean => {
      const key = description.toLocaleLowerCase();
      return key.localeCompare("linehaul") === 0 || key.localeCompare("line haul") === 0;
    };

    const getType = (description: string): string => {
      if (isLinehaul(description)) {
        return "Linehaul";
      }

      if (description.toLocaleLowerCase().startsWith("fuel")) {
        return "Fuel";
      }

      return "Accessorial";
    }

    // Grab the raw value so that disabled fields are included
    const charges = chargesFormArray.controls
      .map((control: FormGroup) => control.getRawValue())
      .map(value => {
        const linehaul = isLinehaul(value.description);
        const accessorial = linehaul === false;
        let amountInUSD: number = value.total;

        if (this.parameters.currencyCode !== 'USD') {
          amountInUSD = Math.round(amountInUSD / this.currencyValueCAD);
        }

        const charge = this._localChargeDescriptions.find((y) => y.name === value.description)

        return <ChargeDTO>{
          chargeID: value.chargeID,
          description: value.description,
          ediCode: (charge && charge.billingCode) ? charge.billingCode : null,
          freightClass: 0,
          quantity: value.quantity,
          rate: value.rate,
          rateQualifier: value.rateQualifier,
          total: value.total,
          isAccessorial: accessorial,
          isLinehaul: linehaul,
          amount: value.total,
          amountInUSD: amountInUSD,
          type: getType(value.description),
          weight: value.weight,
          isMin: value.isMin,
          isMax: value.isMax
        };
      });

    return <UpdateChargesRequest>{
      charges,
      currencyCode: this.parameters.currencyCode,
      entity: this.parameters.isBuyRate ? "carrier" : "customer",
      offerID: this.parameters.quote.id,
      shipmentID: this.parameters.shipmentID,
    };
  }

  closeDialog(result: boolean = false) {
    this.close.emit(result);
  }

  createChargeForm(charge: TLEditUI.Charge) {
    const newFormGroup = this.formBuilder.group({
      chargeID: new FormControl(charge.chargeID),
      description: new FormControl(charge.description, [Validators.required]),
      rate: new FormControl(charge.rate, [Validators.required]),
      rateQualifier: new FormControl(charge.rateQualifier, [Validators.required]),
      quantity: new FormControl(charge.quantity, [Validators.required]),
      total: new FormControl({ value: charge.total, disabled: true }),
      weight: new FormControl(charge.weight),
      isMin: new FormControl(charge.isMin),
      isMax: new FormControl(charge.isMax),
    });

    const calculateTotal = () => {
      const rateQualifier = newFormGroup.controls["rateQualifier"].value;
      let rate = newFormGroup.controls["rate"].value;
      rate = rateQualifier === 'PCT' ? rate * .01 : rate;
      const quantity = newFormGroup.controls["quantity"].value;
      return quantity * rate;
    };

    newFormGroup.controls["quantity"].valueChanges.subscribe(() => {
      newFormGroup.patchValue({ total: calculateTotal() });
    });

    newFormGroup.controls["rate"].valueChanges.subscribe(() => {
      newFormGroup.patchValue({ total: calculateTotal() });
    });

    newFormGroup.controls["total"].valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        this.editForm.patchValue({ total: this.getTotal() });
      });

    newFormGroup.controls["rateQualifier"].valueChanges.subscribe((value) => {
      const quantity = value === "PM" ? this.parameters.distance : 1;
      newFormGroup.patchValue({ quantity: quantity });
      newFormGroup.patchValue({ total: calculateTotal() })
    });

    return newFormGroup;
  }

  getTotal(): number {
    const chargesFormArray = this.editForm.get("chargesFormArray") as FormArray;
    return this.calculateTotal(chargesFormArray.controls.map((x: FormGroup) => x.getRawValue()));
  }

  calculateTotal(charges: any[]): number {
    return charges.reduce((total, charge) => total + charge.total, 0);
  }

  private createEditForm() {
    const linehaulCharges = this.parameters.quote.lineHaulCharges || [];
    const accessorialCharges = this.parameters.quote.accessorialCharges || [];
    const charges = linehaulCharges.concat(accessorialCharges);

    const total = this.calculateTotal(charges);

    this.editForm = this.formBuilder.group({
      chargesFormArray: this.formBuilder.array(charges.map((charge) => this.createChargeForm(charge))),
      total: new FormControl({ value: total, disabled: true })
    });

    this.setChargeTypeList();
  }

  addCharge(): void {
    const chargesFormArray = this.editForm.get('chargesFormArray') as FormArray;
    const newRow = this.createChargeForm({
      chargeID: 0,
      description: '',
      freightClass: 0,
      quantity: 0,
      rate: 0,
      rateQualifier: '',
      total: 0,
      weight: 0,
      isMin: false,
      isMax: false,
      group: '',
      type: '',
      error: false
    });

    chargesFormArray.push(newRow);
  }

  public onConfirmation(response: boolean) {
    if (response) {
      const chargesFormArray = this.editForm.get('chargesFormArray') as FormArray;
      chargesFormArray.removeAt(this.indexOfChargeToDelete);
      this.editForm.patchValue({ total: this.getTotal() });
    }
    this.indexOfChargeToDelete = -1;
  }

  isAirOceanIntlGroundMode(mode: string) {
    return mode.toLowerCase() === 'air' || mode.toLowerCase() === 'ocean' || mode.toLowerCase() === 'intl ground';
  }
}

export class EditRateParameters {
  title: string;
  invoiceID: number;
  shipmentID: number;
  quote: TLEditUI.Quote;
  isBuyRate: boolean;
  distance: number;
  mode?: string = '';
  currencyCode: string;
}
