import { ShipmentDTO, SelectedRate, Item } from 'src/app/services/Shipment';
import { ShipmentInvoiceNoDetailResponse } from 'src/app/services/InvoiceAudit';
import { CustomerProfile } from '@/models/CustomerProfile';
import { Globals } from '@/_shared/globals';
import { EnterpriseWithReference } from '@/models/ShipmentReference';
import { RouteAddressDTO } from '../dto/TLAuditEditDTO';
import { EnterpriseDTO } from '@/services/Enterprise';

export namespace TLEditUI {
  export class TLShipment {
    shipmentID: number;
    invoiceID: number;
    enterpriseID: number;
    bol: string;
    enterpriseName: string;
    accountNumber: string;
    proActive: boolean;
    status: string;
    mode: string;
    weightUOM: string;
    dateCreated: Date = null;
    isSelectedRate: boolean;
    multiStopAddresses: Address[] = [];
    references: Reference[];
    carrierQuote: CarrierQuote;
    customerQuote: CustomerQuote;
    carrierInvoices: CarrierInvoice[] = [];
    customerProfile: CustomerProfile = null;
    margin: number;
    pros: string;
    accountName: string;
    currencyCode?: string;
    salesRep: string;
    customerRep: string;
    operationsEmail: string;
    enterprise: EnterpriseDTO = null;
    // icons
    isHazmat: boolean;
    isExpedited: boolean;
    isHold: boolean;
    isCancelled: boolean;
    isTanker: boolean;
    isTwic: boolean;
    isTemperature: boolean;
    isLiquor: boolean;
    isMultistop: boolean;

    constructor(shipmentDTO: ShipmentDTO, invoiceID: number, addresses: RouteAddressDTO[]) {
      this.shipmentID = shipmentDTO.id;
      this.invoiceID = invoiceID;
      this.bol = shipmentDTO.primaryReference;
      this.references = shipmentDTO.references;
      this.status = shipmentDTO.status;
      this.mode = shipmentDTO.mode;
      this.currencyCode = shipmentDTO.selectedRate.currencyCode;
      this.isSelectedRate = shipmentDTO.selectedRate != null;
      this.carrierQuote = new CarrierQuote(shipmentDTO.selectedRate, shipmentDTO.distance, shipmentDTO.items);
      this.customerQuote = new CustomerQuote(shipmentDTO.selectedRate, shipmentDTO.distance, shipmentDTO.items);
      this.enterpriseID = shipmentDTO.enterpriseID;
      this.pros =
        shipmentDTO.references != null
          ? shipmentDTO.references
            .filter(function (ref) {
              return ref.type.toUpperCase() === 'PRO';
            })
            .map((x) => x.value)
            .reverse()
            .join(', ')
          : '';

      // buy/sell rate margin
      this.margin = Math.ceil(shipmentDTO.selectedRate.customerTotal - shipmentDTO.selectedRate.carrierTotal);

      // set the weights
      this.weightUOM = 'lbs'; // hard coded because we convert everything to lbs

      if (!isNaN(Date.parse(shipmentDTO.dateCreated))) {
        this.dateCreated = new Date(shipmentDTO.dateCreated);
      }

      // set addresses
      const maxSeq = addresses.length;
      if (addresses && addresses.length > 0) {
        this.multiStopAddresses.push(new Address(addresses[0], 'Origin'));
        if (addresses.length > 2) {
          addresses
            .filter((x) => x.sequence !== 1 && x.sequence < maxSeq)
            .forEach((x) => {
              const type =
                x.pickupItems.length > 0 && x.dropoffItems.length > 0 ? 'Pickup/Drop' : x.pickupItems.length > 0 ? 'Pickup' : 'Drop';
              this.multiStopAddresses.push(new Address(x, type));
            });
        }
        this.multiStopAddresses.push(new Address(addresses[maxSeq - 1], 'Destination'));
      }

      this.enterpriseName = shipmentDTO.enterpriseName;
      this.accountNumber = shipmentDTO.accountNumber;
      this.isHazmat =
        shipmentDTO.accessorials.find((x) => x.name.toLowerCase() === 'hazmat' || x.name.toLowerCase() === 'hazardous materials') !==
        undefined;
      this.isExpedited = shipmentDTO.accessorials.find((x) => x.name.toLowerCase() === 'expedite') !== undefined;
      this.isTanker =
        shipmentDTO.accessorials.find(
          (x) => x.name.toLowerCase() === 'tanker endorsed' || x.name.toLowerCase() === 'tanker endorsement'
        ) !== undefined;
      this.isTwic = shipmentDTO.accessorials.find((x) => x.name.toLowerCase() === 'twic') !== undefined;
      this.isLiquor =
        shipmentDTO.accessorials.find((x) => x.name.toLowerCase() === 'liquor permits' || x.name.toLowerCase() === 'liquor pe') !==
        undefined;
      this.isMultistop = shipmentDTO.addresses !== null ? shipmentDTO.addresses.length > 2 : false;
      this.isTemperature = shipmentDTO.items !== null ? shipmentDTO.items.find((x) => x.isTempSensitive) !== undefined : false;
    }

    public setCustomerProfile(customerProfile: CustomerProfile) {
      this.customerProfile = customerProfile;
    }

    // function needed because this get updated separately from the entire dataset
    public setCarrierInvoices(shipmentInvoiceNoDetailResponse: ShipmentInvoiceNoDetailResponse[]) {
      // filter specific invoices
      this.carrierInvoices = [];
      const invoices = shipmentInvoiceNoDetailResponse.filter(function (el) {
        return el.type === 'Carrier';
      });

      // create the invoices
      for (const invoice of invoices) {
        this.carrierInvoices.push(new CarrierInvoice(invoice));
      }

      // if there is more than one carrier invoice get the one that matches the invoiceID sent in
      if (this.carrierInvoices.length > 1) {
        // get the correct record
        const record = this.carrierInvoices.filter((item) => item.invoiceId === this.invoiceID)[0];

        // remove it from the list
        this.carrierInvoices = this.carrierInvoices.filter((item) => item.invoiceId !== this.invoiceID);

        // add this record back to list
        if (record)
          this.carrierInvoices.unshift(record);
      }
    }

    public setEnterpriseData(enterpriseWithReference: EnterpriseWithReference) {
      this.accountName = enterpriseWithReference.enterprise.customer;
      this.salesRep = enterpriseWithReference.enterprise.salesRep;
      this.operationsEmail = enterpriseWithReference.enterprise.operationsContact;
      this.customerRep = enterpriseWithReference.enterprise.accountManager;
    }

    public setEnterpriseDTO(enterprise: EnterpriseDTO) {
      this.enterprise = enterprise;
    }
  }

  export class Reference {
    type: string;
    value: string;
  }

  export class Address {
    companyName: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    sequence: number;
    type: string;

    constructor(address: RouteAddressDTO, type: string) {
      this.sequence = address.sequence;
      this.companyName = address.companyName;
      this.name = address.contactName;
      this.addressLine1 = address.addressLine1;
      this.addressLine2 = address.addressLine2 ? address.addressLine2 : '';
      this.stateProvince = address.stateProvince;
      this.city = address.city;
      this.stateProvince = address.stateProvince;
      this.stateProvince = address.stateProvince;
      this.postalCode = address.postalCode;
      this.type = type;
    }
  }

  export class CarrierInvoice {
    invoiceId: number;
    invoiceNumber: string;
    invoiceDate: Date;
    status: string;
    scac: string;
    mcNumber: string;
    dotNumber: string;
    carrierCode: string;
    mode: string;
    weight = 0;
    isBBill: boolean;
    carrierName: string;
    isSecondary: boolean;
    auditor: string;
    distance: number;
    isOpen = false;
    subTotal = 0;
    grandTotal = 0;
    chargeType: string;
    lineHaulCharges: Charge[] = null;
    accessorialCharges: Charge[] = null;
    currencyCode: string;

    constructor(shipmentInvoiceNoDetailResponse: ShipmentInvoiceNoDetailResponse) {
      this.invoiceId = shipmentInvoiceNoDetailResponse.invoiceID;
      this.invoiceNumber = shipmentInvoiceNoDetailResponse.invoiceNumber;
      this.invoiceDate = shipmentInvoiceNoDetailResponse.invoiceDate;
      this.status = shipmentInvoiceNoDetailResponse.status;
      this.distance = shipmentInvoiceNoDetailResponse.distance;
      this.scac = shipmentInvoiceNoDetailResponse.scac;
      this.mcNumber = shipmentInvoiceNoDetailResponse.mcNumber;
      this.dotNumber = shipmentInvoiceNoDetailResponse.dotNumber;
      this.carrierCode = shipmentInvoiceNoDetailResponse.carrierCode;
      this.mode = shipmentInvoiceNoDetailResponse.mode;
      this.isBBill = shipmentInvoiceNoDetailResponse.isBBill;
      this.carrierName = shipmentInvoiceNoDetailResponse.carrierName;
      this.isSecondary = shipmentInvoiceNoDetailResponse.isSecondary;
      this.auditor = shipmentInvoiceNoDetailResponse.auditor;
      this.currencyCode = shipmentInvoiceNoDetailResponse.currencyCode;
      const charges = shipmentInvoiceNoDetailResponse.charges.map(
        ({ description, freightClass, rate, rateQualifier, quantity, weight, isMin, isMax, amount: total, type, group = '', error = false }) => ({
          chargeID: 0,
          description,
          freightClass,
          rate,
          rateQualifier,
          quantity,
          weight,
          isMin,
          isMax,
          total,
          type,
          group,
          error,
        })
      );

      // not ideal, but map would not work with the filter inline
      for (const charge of charges) {
        charge.group = Globals.ChargeTypes.indexOf(charge.type) === -1 ? 'Accessorial' : 'Linehaul';
      }

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === 'Linehaul';
        });
        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });

        // sort them and set the totals
        this.subTotal = 0;
        if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
          this.lineHaulCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.subTotal = +this.lineHaulCharges.map(this.itemTotal).reduce(this.sum).toFixed(2);
        }
        this.grandTotal = this.subTotal;
        if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
          this.accessorialCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.grandTotal += this.accessorialCharges.map(this.itemTotal).reduce(this.sum);
          this.grandTotal = +this.grandTotal.toFixed(2);
        }
        this.weight = this.getTotalWeight(this.lineHaulCharges);
      }

      // set the weight.  If the items dont have it use the root weight
      if (this.weight === 0) {
        this.weight = shipmentInvoiceNoDetailResponse.invoiceWeight;
      }
    }
    private getTotalWeight(charges: Charge[]): number {
      let total = 0;
      for (const item of charges) {
        if (item.type === 'ITEM') {
          total += item.weight;
        }
      }
      return total;
    }

    private itemTotal(item) {
      return item.total;
    }
    private sum(prev, next) {
      return prev + next;
    }
  }

  export class Quote {
    id: string;
    scac: string;
    mcNumber: string;
    dotNumber: string;
    carrierCode: string;
    carrierName: string;
    total: number;
    isTotalError: boolean;
    distance: number;
    mode: string;
    weight: number;
    subTotal: number;
    grandTotal: number;
    chargeType: string;
    lineHaulCharges: Charge[];
    accessorialCharges: Charge[];
  }

  export class CarrierQuote extends Quote {

    constructor(selectedRate: SelectedRate, distance: number, items: Item[]) {
      super();
      this.id = selectedRate.id;
      this.isTotalError = false;
      this.distance = distance;
      if (selectedRate == null) {
        return;
      }
      this.scac = selectedRate.scac;
      this.dotNumber = selectedRate.dotNumber;
      this.carrierCode = selectedRate.carrierCode;
      this.mcNumber = selectedRate.mcNumber;
      this.carrierName = selectedRate.carrierName;
      this.mode = selectedRate.mode;
      const charges = selectedRate.carrierCharges.map(
        ({ chargeID, description, freightClass, fakFreightClass, rate, rateQualifier, quantity, weight, isMin, isMax, amount: total, type, group = '', error = false }) => ({
          chargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateQualifier,
          quantity,
          weight,
          isMin,
          isMax,
          total,
          type,
          group,
          error,
        })
      );

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // not ideal, but map would not work with the filter inline
        for (const charge of charges) {
          charge.group = Globals.ChargeTypes.indexOf(charge.type) === -1 ? 'Accessorial' : 'Linehaul';
        }

        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === 'Linehaul';
        });
        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });

        // sort them and set the totals
        this.subTotal = 0;
        if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
          this.lineHaulCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.subTotal = +this.lineHaulCharges.map(this.itemTotal).reduce(this.sum).toFixed(2);
        }
        this.grandTotal = this.subTotal;
        if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
          this.accessorialCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.grandTotal += this.accessorialCharges.map(this.itemTotal).reduce(this.sum);
          this.grandTotal = +this.grandTotal.toFixed(2);
        }
      }
      this.weight = this.getTotalWeightQuote(items);
    }
    public getTotalWeightQuote(itemList: Item[]): number {
      let total = 0;
      for (const item of itemList) {
        total += item.weightUOM === 'lbs' ? item.weight : this.kToLbs(item.weight);
      }
      return total;
    }
    private kToLbs(k: number): number {
      const nearExact = k / 0.45359237;
      return Math.round(nearExact);
    }

    private itemTotal(item) {
      return item.total;
    }
    private sum(prev, next) {
      return prev + next;
    }
  }

  export class CustomerQuote extends Quote {
    constructor(selectedRate: SelectedRate, distance: number, items: Item[]) {
      super();
      this.id = selectedRate.id;

      this.isTotalError = false;
      this.distance = distance;
      if (selectedRate == null) {
        return;
      }
      this.scac = selectedRate.scac;
      this.dotNumber = selectedRate.dotNumber;
      this.carrierCode = selectedRate.carrierCode;
      this.mcNumber = selectedRate.mcNumber;
      this.carrierName = selectedRate.carrierName;
      this.mode = selectedRate.mode;
      const charges = selectedRate.customerCharges.map(
        ({ chargeID, description, freightClass, rate, rateQualifier, quantity, weight, isMin, isMax, amount: total, type, group = '', error = false }) => ({
          chargeID,
          description,
          freightClass,
          rate,
          rateQualifier,
          quantity,
          weight,
          isMin,
          isMax,
          total,
          type,
          group,
          error,
        })
      );

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // not ideal, but map would not work with the filter inline
        for (const charge of charges) {
          charge.group = Globals.ChargeTypes.indexOf(charge.type) === -1 ? 'Accessorial' : 'Linehaul';
        }

        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === 'Linehaul';
        });
        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });

        // sort them and set the totals
        this.subTotal = 0;
        if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
          this.lineHaulCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.subTotal = +this.lineHaulCharges.map(this.itemTotal).reduce(this.sum).toFixed(2);
        }
        this.grandTotal = this.subTotal;
        if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
          this.accessorialCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.grandTotal += this.accessorialCharges.map(this.itemTotal).reduce(this.sum);
          this.grandTotal = +this.grandTotal.toFixed(2);
        }
      }
      this.weight = this.getTotalWeightQuote(items);
    }
    public getTotalWeightQuote(itemList: Item[]): number {
      let total = 0;
      for (const item of itemList) {
        total += item.weightUOM === 'lbs' ? item.weight : this.kToLbs(item.weight);
      }
      return total;
    }
    private kToLbs(k: number): number {
      const nearExact = k / 0.45359237;
      return Math.round(nearExact);
    }

    private itemTotal(item) {
      return item.total;
    }
    private sum(prev, next) {
      return prev + next;
    }
  }

  export class Charge {
    chargeID: number;
    description: string;
    freightClass: number;
    rate: number;
    rateQualifier: string;
    quantity: number;
    weight: number;
    isMin: boolean;
    isMax: boolean;
    total: number;
    group: string;
    type: string;
    error: boolean;
  }

  export class UploadErrorInfo {
    constructor(message: string, errorCode: number) {
      this.message = message;
      this.code = errorCode;
    }
    message: string;
    code: number;
  }
}
