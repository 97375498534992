import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'conversionCurrency' })

export class ConversionPipe implements PipeTransform {

  constructor() { }
  transform(value: number, currency: string, conversion: number): number {
    if (!conversion) return;
    let data: number;
    if (currency === 'USD') data = value * conversion
    else data = value / conversion

    return data;
  }
}
