import { BBill } from '../models/TruckloadAudit.ui';

export interface TruckloadAuditDTO {
  shipmentID: number;
  invoiceID: number;
  enterpriseID: number;
  invoiceAuditID: number;
  invoiceNumber: string;
  invoiceTotal: number;
  carrierName: string;
  carrierCode: string;
  shipmentBuyRate: number;
  shipmentSellRate: number;
  customer: string;
  customerAccount: string;
  primaryReference: string;
  invoiceCreatedDate: string;
  isSecondary: boolean;
  uninvoicedAgeDays: number;
  saleRep: string;
  incidents: TruckloadAuditIncidentDTO[];
}

export interface TruckloadAuditIncidentDTO {
  invoiceID: number;
  shipmentID: number;
  incidentID: number;
  type: string;
  status: string;
  createdDate: string;
  createdUser: string;
  updatedDate: string;
  updatedUser: string;
  comments: TLAuditIncidentCommentDTO[];
  assignments: TLAuditIncidentAssignmentDTO[];
}

export interface TLAuditIncidentCommentDTO {
  incidentID: number;
  comment: string;
  commentID: number;
  status: string;
  createdUser: string;
  createdDate: string;
}

export interface TLAuditIncidentAssignmentDTO {
  incidentID: number;
  name: string;
  email: string;
  assignmentID: number;
  isComplete: boolean;
}

// B-Bill related
export class TLAuditRefDataResponse {
  tlAuditSettlementReasons: string[];
}
export class TLBBill {
  constructor(bbill: BBill) {
    this.shipmentID = bbill.shipmentID;
    this.invoiceNumber = bbill.invoiceNumber;
    this.bbillAccountNumber = bbill.accountNumber;
    this.bbillPreviousPayment = bbill.previousPayment;
    this.bbillSettlementReasons = bbill.reasons;
  }
  shipmentID: number;
  invoiceNumber: string;
  bbillAccountNumber: string;
  bbillPreviousPayment: number;
  bbillSettlementReasons: string[];
}
