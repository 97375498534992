import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

@Component({
  selector: 'tl-audit-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class TlAuditFilterComponent implements OnInit, OnChanges {
  private static readonly LocalStorageEntry = 'TL:Audit:FilterDialog';

  @Output() filter: EventEmitter<FilterData> = new EventEmitter();

  @Input() filterSettings: FilterData;

  settings: FilterData;

  selectedValues: FilterData = new FilterData();

  ngOnInit(){
    const data = <FilterData>JSON.parse(localStorage.getItem(TlAuditFilterComponent.LocalStorageEntry));
    
    this.selectedValues.customers = data ? data.customers : [];
    this.selectedValues.customerAccounts = data ? data.customerAccounts : [];
    this.selectedValues.auditors = data ? data.auditors : [];
    this.selectedValues.salesRepresentatives = data ? data.salesRepresentatives : [];
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.filterSettings){
      const prev = JSON.stringify(changes.filterSettings.previousValue || {});
      const current = JSON.stringify(changes.filterSettings.currentValue || {});
      
      if(current && prev !== current){
        this.settings = { ...changes.filterSettings.currentValue };
      }
    }
  }

  public onClearReset() {
    this.selectedValues = new FilterData();
  }

  public onRunClicked() {
    localStorage.setItem(TlAuditFilterComponent.LocalStorageEntry, JSON.stringify(this.selectedValues));
    
    const response = <FilterData>{
      auditors: this.selectedValues.auditors || [],
      customerAccounts: this.selectedValues.customerAccounts || [],
      customers: this.selectedValues.customers || [],
      salesRepresentatives: this.selectedValues.salesRepresentatives || []
    };
    
    this.filter.emit(response);
  }
}

export class FilterData
{
  customers: string[] = [];
  customerAccounts: string[] = [];
  auditors: string[] = [];
  salesRepresentatives: string[] = [];
}