import { Injectable } from '@angular/core';

@Injectable()
export class ConversionHelpers {
  public static getCanadianAmount(usAmount: number, conversionRate: number) {
    if (usAmount <= 0 || conversionRate <= 0) {
      return 0;
    }

    return usAmount / conversionRate;
  }
  public static getUSAmount(canadianAmount: number, conversionRate: number) {
    if (canadianAmount <= 0 || conversionRate <= 0) {
      return 0;
    }
    return canadianAmount * conversionRate;
  }
}
