import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  private _loading: boolean;
  private _message: string;

  @Input()
  public modal: boolean;

  @Input()
  public load_init: boolean = false;

  @Input()
  public init_message: string = 'Please wait...';

  constructor() { }

  ngOnInit() {
    this._loading = this.load_init;
    this._message = this.init_message;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    if (value === false) {
      this.text = 'Please wait...';
    }
  }

  get text(): string {
    return this._message;
  }

  set text(value) {
    this._message = value;
  }
}
