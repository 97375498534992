import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StartupService } from './startup.service';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(private router: Router, private http: HttpClient, private startupService: StartupService, private authService: AuthService) {}

  get showHeader(): boolean {
    return this.authService.BlueShipUser != null;
  }
}
