import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input()
  expanded: boolean;

  @Input()
  readonly: boolean = false;

  @Input()
  title: string;

  @Output()
  edit = new EventEmitter();

  constructor() {
    this.expanded = false;
  }

  ngOnInit() {}

  toggle(): void {
    this.expanded = !this.expanded;
  }
}
