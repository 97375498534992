import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string): Date {
    if (!value.endsWith('Z')) value = `${value}Z`;
    return new Date(value);
  }
}
