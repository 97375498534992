import { Component, OnInit, ViewChild } from '@angular/core';
import { StartupService } from '../../startup.service';
import { ProactiveService } from '../../services/proactive.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { TLDocHoldProactiveResponse, ReleaseCustomerInvoice } from '../../models/Proactive';
import { TlDocProactiveListComponent } from '../../components/tl-doc-proactive-list/tl-doc-proactive-list.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Helpers } from '../../_shared/helpers';
import { Globals } from '../../_shared/globals';
import { FileRestrictions, FileInfo, SelectEvent, SuccessEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { ShipmentDocumentService } from '../../services/shipment-document.service';
import { ShipmentDocumentDTO, ShipmentDocumentUploadDTO } from '../../models/ShipmentDocument';
import { InvoiceService } from '@/services/invoice.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-tldoc-proactive',
  templateUrl: './tldoc-proactive.component.html',
  styleUrls: ['./tldoc-proactive.component.scss'],
})
export class TldocProactiveComponent implements OnInit {
  recordBook: TLDocHoldProactiveResponse[] = [];
  recordCount = 0;
  canDeleteInvoices = false;
  isDataLoading = true;
  openedUploadDialog = false;
  uploadedVideo = false;
  uploadErrorText = '';
  uploadSuccessText = '';
  helpers: Helpers;
  documentTLDocHoldProactiveRecord: TLDocHoldProactiveResponse;
  existingDocuments: ShipmentDocumentDTO[];
  documentList = Globals.DocumentTypes.filter((obj) => obj.canUpload === true);
  documentType: { text: string; value: string } = {
    text: '',
    value: '',
  };
  documentFiles: any[] = [];
  selectedUploadDocumentType: string;
  uploadDocumentSaveUrl: string;
  uploadDocumentRestrictions = Globals.UploadFileRestrictions;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild('tlProactiveHoldGrid', { static: false })
  tlProactiveGrid: TlDocProactiveListComponent;

  @ViewChild('documentuploadTLbtn', { static: false })
  documentuploadTLbtn: UploadComponent;

  constructor(
    private router: Router,
    private startupService: StartupService,
    private invoiceService: InvoiceService,
    private authService: AuthService,
    private pcProactiveService: ProactiveService,
    private pcShipmentService: ShipmentDocumentService
  ) { }

  ngOnInit() {
    this.uploadDocumentSaveUrl = `${this.startupService.documentAPIUrl}v1/document`;
    this.helpers = new Helpers(this.authService);
    this.isInvoiceDeleteable();
    this.loadRecords();
  }

  onReleaseButtonEvent(recordList: number[]) {
    const recordArray: ReleaseCustomerInvoice[] = [];
    for (let index = 0; index < recordList.length; index++) {
      const record = this.recordBook.filter((s) => s.id === recordList[index]);
      const releaseInvoice = {
        id: record[0].id,
        enterpriseID: record[0].enterpriseID,
        enterpriseAccountNumber: record[0].ownerAccount,
      } as ReleaseCustomerInvoice;
      recordArray.push(releaseInvoice);
    }

    // remove it from the server
    this.setRelease(recordArray, recordList);
  }

  onDeleteButtonEvent(deleteRecordID: string) {
    const filterList = this.recordBook.filter((x) => x.carrierInvoiceID === deleteRecordID);
    if (filterList !== null && filterList.length > 0) {
      const deleteDataItem = filterList[0];
      // convert to number
      const deleteCarrierID = +deleteDataItem.carrierInvoiceID;
      if (deleteDataItem !== undefined) {
        this.invoiceService.deleteInvoice(deleteCarrierID).subscribe(
          () => {
            const removedList: number[] = [deleteDataItem.id];
            this.tlProactiveGrid.removeRecords(removedList);
            this.alertMessage.showAlertMessage(
              `Invoice number ${deleteDataItem.invoiceNumber}
             was deleted.`,
              'info'
            );

            this.recordCount = this.recordCount - removedList.length;
            this.appSpinner.loading = false;
          },
          (error) => {
            this.alertMessage.showAlertMessage(
              `Failed to delete customer and carrier invoice. Please contact IT Support if this error persists.`,
              'error'
            );
          }
        );
      }
    }
  }

  // document dialog and upload event
  onUploadDocuments(dataItem: TLDocHoldProactiveResponse) {
    this.uploadSuccessText = '';
    this.uploadErrorText = '';
    this.documentTLDocHoldProactiveRecord = dataItem;

    this.existingDocuments = dataItem.documents;
    this.openedUploadDialog = true;
  }

  closeUpload() {
    this.openedUploadDialog = false;
    this.documentType.text = '';
    this.documentType.value = '';
    this.selectedUploadDocumentType = '';
    this.existingDocuments = [];
    this.documentFiles = [];
    if (this.uploadedVideo) {
      this.updateDocumentTypeOnRow(this.documentTLDocHoldProactiveRecord.id);
    }
    this.documentTLDocHoldProactiveRecord = null;
  }

  selectDocumentEventHandler(event: SelectEvent): void {
    // stop auto upload
    event.preventDefault();

    const extensions = event.files.map((file) => file.extension);
    if (!this.helpers.hasAllowedExtensionsOnly(extensions)) {
      const { allowedExtensions } = Globals.UploadFileRestrictions;
      this.uploadErrorText = `Invalid file type selected. Allowed file types: ${allowedExtensions.join(', ')}`;
      return;
    }

    // loop for all files
    event.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        const reader = new FileReader();

        // called when done reading in the file
        reader.onloadend = () => {
          // create the object
          const base64String = reader.result.toString();
          const documentObj = {
            type: this.selectedUploadDocumentType,
            name: file.name.replace(/\.[^/.]+$/, ''),
            format: Globals.AllowedExtensionsFormatMap[file.extension.toLowerCase()],
            source: 'Api',
            shipmentId: this.documentTLDocHoldProactiveRecord.shipmentID,
            base64Data: base64String.substr(base64String.indexOf(',') + 1),
          } as ShipmentDocumentUploadDTO;

          this.uploadDocument(documentObj, file, reader);
        };

        // read the data
        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  openExistingDocument(shipmentId: number, documentId: string, name: string, format: string) {
    this.pcShipmentService.getDocumentData(shipmentId, documentId).subscribe((data) => {
      this.helpers.downloadFile(data, name, format);
    });
  }
  private loadRecords() {
    this.pcProactiveService
      .getTLDocProactiveHold()
      .pipe(
        map((data) => {
          return data.map((row) => {
            const shipDate = !row.shipmentOriginActualDate
              ? new Date(row.shipmentOriginEarliestDate)
              : new Date(row.shipmentOriginActualDate);
            if (shipDate) {
              row.age = this.helpers.dateCompareTodayInDays(shipDate);
            }
            row.customerInvoiceTotalString = row.customerInvoiceTotal == null ? '' : '$' + row.customerInvoiceTotal.toFixed(2);
            row.carrierInvoiceTotalString = row.carrierInvoiceTotal == null ? '' : '$' + row.carrierInvoiceTotal.toFixed(2);
            return row;
          });
        })
      )
      .subscribe(
        (responseList) => {
          this.recordBook = responseList;
          this.recordCount = responseList.length;
          this.isDataLoading = false;
          this.recordBook.forEach((element) => {
            this.pcShipmentService.getDocumentsForShipment(element.shipmentID).subscribe((documentList) => {
              // only interested in documents that they can upload
              element.documents = documentList.filter((elem) => Globals.DocumentTypes.find(({ value }) => elem.type === value));
              if (element.documents && element.documents.length > 0) {
                element.documentTypes = Array.from(new Set(element.documents.map((elem: any) => elem.type))).join(', ');
              } else {
                element.documentTypes = '';
              }
            });
          });
        },
        (error) => {
          this.alertMessage.showAlertMessage('Error Loading TL Doc Proactive Holds.', 'Error');
          this.isDataLoading = false;
        }
      );
  }

  private dropdownChangeHandler(event) {
    const resultValue = this.documentList.filter((word) => word.value === event);
    this.documentType.text = resultValue[0].text.toString();
    this.documentType.value = resultValue[0].value.toString();
  }
  private isInvoiceDeleteable() {
    this.canDeleteInvoices = this.helpers.hasOperation('CanDelete_InvoiceAdmin');
  }
  private updateDocumentTypeOnRow(id: number) {
    const selectedRecord = this.recordBook.filter((x) => x.id === id);
    if (selectedRecord) {
      const selRecord = selectedRecord[0];
      this.pcShipmentService.getDocumentsForShipment(selRecord.shipmentID).subscribe((documentList) => {
        const filter = documentList.filter((elem) => this.documentList.find(({ value }) => elem.type === value));
        filter.forEach((value) => {
          selRecord.documents.push(value);
        });
        selRecord.documentTypes = Array.from(new Set(selRecord.documents.map((elem: any) => elem.type))).join(', ');
      });
    }
  }

  // service calls
  uploadDocument(documentObj: ShipmentDocumentUploadDTO, file: FileInfo, reader: FileReader) {
    this.uploadErrorText = '';
    this.uploadSuccessText = '';
    this.pcShipmentService.uploadDocument(documentObj).subscribe(
      (response) => {
        // call the endpoint to save the document
        this.documentFiles.push({ ...file, src: <string>reader.result });
        this.documentFiles = [...this.documentFiles];

        // emit that it was successful
        this.documentuploadTLbtn.success.emit();
        this.uploadSuccessText = `This file ${file.name} was successfully uploaded.`;
        this.uploadedVideo = true;
      },
      (err) => {
        if (err.status === 409) {
          this.uploadErrorText = 'This document already exists for this invoice / type.';
        } else {
          if (err.error.errors !== undefined) {
            this.uploadErrorText = err.error.errors.type[0];
          } else {
            this.uploadErrorText = err.error;
          }
        }
      }
    );
  }

  setRelease(releases: ReleaseCustomerInvoice[], recordList: number[]) {
    this.appSpinner.loading = true;
    this.pcProactiveService.releaseProactiveHold(releases).subscribe(
      (data) => {
        // remove them from the grid
        this.tlProactiveGrid.removeRecords(recordList);
        this.alertMessage.showAlertMessage('Successfully released records', 'Success');
        this.recordCount = this.recordCount - recordList.length;
        this.appSpinner.loading = false;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error releasing records', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }
}
