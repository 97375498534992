import { Action } from '@ngrx/store';
import { TLAuditRefDataOldResponse, TLBBillOld } from '../dto/TruckloadAuditOLDDTO';
import { BBillOld } from '../models/TruckloadAuditOld.ui';

export const SHOW_BBILL_OLD = 'Show BBill Old';
export const SAVE_BBILL_OLD = '[Finance API] Save Invoice As BBill Old';
export const SAVE_BBILL_SUCCESS_OLD = '[Finance API] Save Invoice As BBill Old Success';
export const SAVE_BBILL_ERROR_OLD = '[Finance API] Save Invoice As BBill Old Error';
export const LOAD_SETTLEMENT_REASONS_OLD = '[Finance API] Load Audit Settlement Reasons Old';
export const LOAD_SETTLEMENT_REASONS_SUCCESS_OLD = '[Finance API] Load Audit Settlement Reasons Old Success';
export const LOAD_SETTLEMENT_REASONS_ERROR_OLD = '[Finance API] Load Audit Settlement Reasons Old Error';

export class ShowBBillOld implements Action {
  readonly type = SHOW_BBILL_OLD;
  constructor(public visible: boolean, public invoiceID: number, public bbill: BBillOld) {}
}

export class SaveBBillOld implements Action {
  readonly type = SAVE_BBILL_OLD;
  constructor(public invoiceID: number, public bbill: TLBBillOld) {}
}
export class SaveBBillSuccessOld implements Action {
  readonly type = SAVE_BBILL_SUCCESS_OLD;
  constructor(public success: boolean) {}
}
export class SaveBBillErrorOld implements Action {
  readonly type = SAVE_BBILL_ERROR_OLD;
  constructor(public message: string) {}
}

export class LoadSettlementReasonsOld implements Action {
  readonly type = LOAD_SETTLEMENT_REASONS_OLD;
  constructor() {}
}
export class LoadSettlementReasonsSuccessOld implements Action {
  readonly type = LOAD_SETTLEMENT_REASONS_SUCCESS_OLD;
  constructor(public tlAuditRefData: TLAuditRefDataOldResponse) {}
}
export class LoadSettlementReasonsErrorOld implements Action {
  readonly type = LOAD_SETTLEMENT_REASONS_ERROR_OLD;
  constructor(public message: string) {}
}

export type Actions =
  | ShowBBillOld
  | SaveBBillOld
  | SaveBBillSuccessOld
  | SaveBBillErrorOld
  | LoadSettlementReasonsOld
  | LoadSettlementReasonsSuccessOld
  | LoadSettlementReasonsErrorOld;
