import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StartupService } from '@/startup.service';
import { TruckloadAuditDTO, TLAuditRefDataResponse, TLBBill } from '../dto/TruckloadAuditDTO';
import { AssignmentIncidentDTO, MassAssignmentDTO, ShipmentIncidentDTO, TLIncidentDTO, TLIncidentTypeDTO } from '../dto/IncidentDTO';
import {
  TruckloadAuditUI,
  TruckloadAuditReasonUI,
  TruckloadAuditReasonCommentUI,
  TruckloadAuditReasonListUI,
} from '@/pages/tl-audit/models/TruckloadAudit.ui';
import { AuditUserResponse } from '../../../services/InvoiceAudit';
import { NewComment, NewTruckloadAuditIncident, UpdateIncident } from '@/pages/tl-audit/models/TLAudit';
import { AuthService } from '@/auth/auth.service';

@Injectable()
export class TLAuditService {
  shipmentApiHeaders: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.initHttpheaders();
  }

  private initHttpheaders() {
    this.shipmentApiHeaders = new HttpHeaders({
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public getTLAudits(): Observable<TruckloadAuditUI[]> {
    const observable = Observable.create((observer) => {
      return this.http.get<TruckloadAuditDTO[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl`).subscribe(
        (data) => {
          if (data) {
            const tlAuditData = data
              .map((v) => new TruckloadAuditUI(v))
              .sort((b, a) => (a.auditAge < b.auditAge ? -1 : a.auditAge > b.auditAge ? 1 : 0));
            observer.next(tlAuditData);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }

  public getTLIncidents(invoiceID: number, shipmentID: number): Observable<TruckloadAuditReasonListUI> {
    const observable = Observable.create((observer) => {
      return this.http.get<ShipmentIncidentDTO>(`${this.startupService.shipmentApiUrl}v2/Incident/${shipmentID}`, this.httpOptions).subscribe(
        (data) => {
          const tlAuditData = new TruckloadAuditReasonListUI(data, invoiceID);
          observer.next(tlAuditData);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }

  public getAuditors(): Observable<AuditUserResponse[]> {
    return this.http.get<AuditUserResponse[]>(`${this.startupService.financeAPIUrl}v2/user/tl-auditors`, {
      headers: this.httpOptions.headers,
    });
  }

  public getTLAuditBBillSettlmentReasonData(): Observable<TLAuditRefDataResponse> {
    const url = `${this.startupService.financeAPIUrl}v2/references/audit-truckload`;
    var result = this.http.get<TLAuditRefDataResponse>(url, {
      headers: this.httpOptions.headers,
    });
    return result;
  }

  public getTLIncidentReferenceData(): Observable<TLIncidentTypeDTO[]> {
    const url = `${this.startupService.shipmentApiUrl}v2/Incident/RefIncident/type`;
    return this.http.get<TLIncidentTypeDTO[]>(url, this.httpOptions);
  }

  public addTLReason(newReason: NewTruckloadAuditIncident): Observable<TruckloadAuditReasonUI> {
    const observable = Observable.create((observer) => {
      const newIncidentType = newReason.incidents[0].type;

      this.http
        .post<ShipmentIncidentDTO>(`${this.startupService.shipmentApiUrl}v2/Incident`, newReason, {
          headers: this.shipmentApiHeaders
        })
        .subscribe(
          (data) => {
            const savedReason = new TruckloadAuditReasonUI(null);
            const matchingIncident = data.incidents.find((incident) => {
              return incident.type === newIncidentType;
            });
            if (matchingIncident) {
              savedReason.setAuditReason(matchingIncident, data.shipment.shipmentID);
              observer.next(savedReason);
            } else {
              observer.error('Save Failed');
            }
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public updateIncidentStatus(updateIncident: UpdateIncident): Observable<TruckloadAuditReasonUI> {
    this.initHttpheaders();
    const observable = Observable.create((observer) => {
      const updateUrl = `${this.startupService.shipmentApiUrl}v2/Incident/${updateIncident.incidentID}/${updateIncident.isComplete ? 'complete' : 'reopen'
        }`;

      this.http
        .put<TLIncidentDTO>(updateUrl, {
          userName: updateIncident.userName,
          comment: updateIncident.comment,
        }, {
          headers: this.shipmentApiHeaders
        })
        .subscribe(
          (data) => {
            const reason = new TruckloadAuditReasonUI(null);
            reason.setAuditReason(data, updateIncident.shipmentID);
            observer.next(reason);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public addComment(newComment: NewComment): Observable<TruckloadAuditReasonCommentUI> {
    const observable = Observable.create((observer) => {
      this.http
        .post<TLIncidentDTO>(`${this.startupService.shipmentApiUrl}v2/Incident/${newComment.incidentID}/Comment/`, {
          userName: newComment.userName,
          comment: newComment.comment,
        }, {
          headers: this.shipmentApiHeaders
        })
        .subscribe(
          (data) => {
            //map to UI, sort first then grab latest
            const savedComment = new TruckloadAuditReasonCommentUI(null);
            var latestComment = data.comments.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))[0];
            savedComment.setComment(data, latestComment);
            observer.next(savedComment);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public addAuditor(incidentID: number, name: string, email: string, userName: string): Observable<AssignmentIncidentDTO> {
    const observable = Observable.create((observer) => {
      this.http
        .post<AssignmentIncidentDTO>(`${this.startupService.shipmentApiUrl}v2/Incident/${incidentID}/Assignment`, {
          name: name,
          email: email,
          userName: userName,
        }, {
          headers: this.shipmentApiHeaders
        })
        .subscribe(
          (data) => {
            // TODO wire up response
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public addOrUpdateAuditor(invoiceIDs: number[], name: string, email: string, userName: string): Observable<MassAssignmentDTO> {
    const observable = Observable.create((observer) => {
      this.http
        .post<MassAssignmentDTO>(`${this.startupService.shipmentApiUrl}v2/Incident/MassAssignment/Invoices`, {
          invoiceIDs: invoiceIDs,
          name: name,
          email: email,
          userName: userName,
        }, {
          headers: this.shipmentApiHeaders
        })
        .subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public updateAuditor(
    incidentID: number,
    assignmentID: number,
    name: string,
    email: string,
    userName: string
  ): Observable<AssignmentIncidentDTO> {
    const observable = Observable.create((observer) => {
      const url = `${this.startupService.shipmentApiUrl}v2/Incident/${incidentID}/Assignment/${assignmentID}/Contact`;
      this.http
        .put<any>(
          url,
          {
            userName: userName,
            name: name,
            email: email,
          },
          { headers: this.shipmentApiHeaders }
        )
        .subscribe(
          (data) => {
            // TODO wire up response
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public removeAuditor(auditID: number): Observable<TruckloadAuditUI> {
    const observable = Observable.create((observer) => {
      this.http.patch<any>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${auditID}/remove-auditor`, null).subscribe(
        (data) => {
          const savedReason = new TruckloadAuditUI(null);
          //savedReason.setTLAuditFromSave(data);
          observer.next(savedReason);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }

  public createTruckloadBBill(bbill: TLBBill, carrierInvoiceID: number): Observable<boolean> {
    const observable = Observable.create((observer) => {
      this.http.post(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${carrierInvoiceID}/bbill`, bbill).subscribe(
        (data) => {
          observer.next(true);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }
}
