import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SortDescriptor, orderBy, process, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent, FilterService, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ActiveAuditResponse, GridState, UpdateStatusNote } from '../../services/AuditQueue';
import { Helpers, getSettings, setSettings, flatten } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { PageChangeEvent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { StatePersistingService } from '../../services/state-persisting-service.service';
import { GridSettings } from '../../services/grid-setting';

@Component({
  selector: 'app-audit-bg-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './audit-bg-list.component.html',
  styleUrls: ['./audit-bg-list.component.scss'],
})
export class AuditBgListComponent implements OnInit {
  @ViewChild('theBGGrid', { static: false })
  private recordGrid: GridComponent;

  get theRecords(): ActiveAuditResponse[] {
    return this.recordBookGrid;
  }
  get cacheKey() {
    return this._cacheKeyGrid;
  }
  @Input() set cacheKeyGrid(value) {
    this._cacheKeyGrid = value;
  }
  @Input('showCheckboxes')
  set queueType(value: boolean) {
    this.thisShowCheckboxes = value;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }

  @Input('theRecords')
  set theRecords(value: ActiveAuditResponse[]) {
    this.recordBookGrid = value;
    this.selectedRecords = [];
    this.setRecords();
    this.updateFiltersGrid();
  }

  @Input('theGridFilters')
  set theGridFilters(value: boolean) {
    this.refreshFilter = value;
  }
  private refreshFilter: boolean;

  @Output()
  auditinvoice = new EventEmitter<ActiveAuditResponse>();

  @Output()
  selectedrecords = new EventEmitter<string[]>();

  helpers: Helpers;
  isDataLoading: boolean;
  thisShowCheckboxes: boolean;

  pageSize = 25;
  recordBookGrid: ActiveAuditResponse[] = [];
  selectableSettings: SelectableSettings;
  selectedRecords: string[] = [];
  theSelectedRecords: string[] = [];
  sort: SortDescriptor[] = [];
  _cacheKeyGrid: string;
  allQueueData: ActiveAuditResponse[];
  scacListFilter: string[];
  ownerListFilter: string[];
  auditorListFilter: string[];
  statusListFilter: string[];
  errorTypeListFilter: string[];
  selectedScac = new Array<any>();
  selectedOwner = new Array<any>();
  selectedAuditor = new Array<any>();
  selectedStatus = new Array<any>();
  selectedErrorType = new Array<any>();

  public gridSettings: GridSettings = {
    state: {
      skip: 0,
      take: 100,

      // Initial filter descriptor
      filter: {
        logic: "and",
        filters: [],
      }
    },
    gridData: process(this.recordBookGrid, {
      skip: 0,
      take: 5,

      // Initial filter descriptor
      filter: {
        logic: "and",
        filters: [],
      },
    })
  };

  constructor(private authService: AuthService,
    private persistingService: StatePersistingService) {
    this.setSelectableSettings();
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);
    this.selectedRecords = [];
    this.sort = getSettings(this._cacheKeyGrid, 'invoiceDate', 'asc');
  }

  private updateFiltersGrid(): void {
    if (this.refreshFilter && this.gridSettings.state.filter)
      this.setGridSettings;
  }

  private setGridSettings() {
    const gridName = this.queueName();
    const gridSettings: GridSettings =
      this.persistingService.get(gridName);

    if (gridSettings !== null && this.recordBookGrid.length > 0) {
      this.gridSettings = this.mapGridSettings(gridSettings);
    } else {
      this.gridSettings = this.mapGridSettings(this.defaultGridState());
    }
  }
  private defaultGridState() {
    const defaultState: GridSettings = {
      state: {
        skip: 0,
        take: 100,

        // Initial filter descriptor
        filter: {
          logic: 'and',
          filters: [],
        },
      },
    };
    return defaultState;
  }

  public mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    this.mapDateFilter(state.filter);

    return {
      state,
      gridData: process(this.recordBookGrid, state),
    };
  }

  public selectionChange(e) {
    // if there is no 'new' selection and the there are no current selections nothing to do
    if (e.selectedRows[0] === undefined && this.selectedRecords.length === 0) {
      return;
    }

    this.helpers.setSelectedGridRecords(this.selectedRecords, e);

    // send out the event
    this.selectedrecords.emit(this.selectedRecords);
  }
  protected onAuditInvoice(dataItem) {
    this.auditinvoice.emit(dataItem);
  }
  protected onPageChange(event: PageChangeEvent): void {
    this.gridSettings.state.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
    this.setGridSettings();
  }
  protected onSortChange(sort: SortDescriptor[]): void {
    this.sort = Helpers.sortOneColumnAtTime(sort, this.sort);
    this.setRecords();
    setSettings(this._cacheKeyGrid, this.sort);
    this.setGridSettings();
  }
  public getSelectedRecords(): ActiveAuditResponse[] {
    return this.recordBookGrid.filter(function (e) {
      return this.indexOf(e.id) !== -1;
    }, this.selectedRecords);
  }

  public removeSelections() {
    this.selectedRecords = [];
  }
  public firstPage(): void {
    this.onPageChange({ skip: 0, take: this.pageSize });
  }

  public get kendoGridSelectBy(): string {
    const isProactiveOrNonProactive = true;
    return isProactiveOrNonProactive ? 'invoiceId' : 'id';
  }

  // helpers
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
    };
  }
  private setRecords(): void {
    const records = orderBy(this.recordBookGrid, this.sort);
    records.forEach((x) => {
      x.invoiceDate = Helpers.stringToDate(x.invoiceDate);
      x.actualDate = Helpers.stringToDate(x.actualDate);
    });
    this.setGridSettings();
    this.allQueueData = records;
    this.makeListFilters(records);
  }

  private makeListFilters(records: ActiveAuditResponse[]): void {
    this.scacListFilter = Helpers.makeListFiltersAudit(records, this.scacListFilter, 'scac');
    this.ownerListFilter = Helpers.makeListFiltersAudit(records, this.ownerListFilter, 'owner');
    this.statusListFilter = Helpers.makeListFiltersAudit(records, this.statusListFilter, 'status');
    this.errorTypeListFilter = Helpers.makeListFiltersAudit(records, this.errorTypeListFilter, 'errorType');
    this.auditorListFilter = Helpers.makeListFiltersAudit(records, this.auditorListFilter, 'auditor');
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridSettings.state = state;
    this.saveGrid();
    this.setGridSettings();
  }

  public scacFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedScac.splice(0, this.selectedScac.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedScac;
  }

  public scacChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, 'scac');
  }

  public errorTypeFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedErrorType.splice(0, this.selectedErrorType.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedErrorType;
  }

  public errorTypeChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, 'errorType');
  }

  public ownerFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedOwner.splice(0, this.selectedOwner.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedOwner;
  }

  public ownerChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, 'owner');
  }

  public auditorFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedAuditor.splice(0, this.selectedAuditor.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedAuditor;
  }

  public auditorChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, 'auditor');
  }

  public statusFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedStatus.splice(0, this.selectedStatus.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedStatus;
  }

  public statusChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, 'status');
  }

  private queueName(): string {
    return 'audit-bg-list';
  }

  private saveGrid(): void {
    const gridName = this.queueName();
    const gridConfig = {
      state: this.gridSettings.state,
    };
    this.persistingService.set(gridName, gridConfig);
  }

  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === "invoiceDate" || filter.field === "actualDate" && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };
}
