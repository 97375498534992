import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { NewTruckloadAuditReasonOld } from '@/pages/tl-audit-old/models/TLAuditOld';
import { TLAuditServiceOld } from '@/pages/tl-audit-old/services/tl-audit-old.service';
import { TLAuditRefDataOldResponse } from '../../dto/TruckloadAuditOLDDTO';
import { SaveReasonOld, AddAuditSaveReasonOld } from '../../actions/tl-audit-detail-old.actions';
import { TruckloadAuditOldUI, SaveMessageOld } from '@/pages/tl-audit-old/models/TruckloadAuditOld.ui';
import { requiredValidatorLogic } from '@/_shared/dropDown-required-validator.directive';

@Component({
  selector: 'app-tl-manage-reason-old',
  templateUrl: './tl-manage-reason-old.component.html',
  styleUrls: ['./tl-manage-reason-old.component.scss'],
})
export class TlManageReasonComponentOld implements OnInit {
  constructor(private tlAuditService: TLAuditServiceOld, private formBuilder: FormBuilder, private store: Store<AppState>) { }

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  currentInvoiceID: number;
  currentShipmentID: number;
  tlAuditReasons = [];
  unfilteredTLReasons: TLAuditRefDataOldResponse;
  tlAudit: TruckloadAuditOldUI = null;
  addReasonForm: FormGroup = null;
  showAddReasonForm = false;
  actionBtnDisabled = false;
  ngOnInit() {
    this.setListeners();
    this.createForm();
  }

  // TODO subscribe to listener
  public onReasonUpdate(saveMsg: SaveMessageOld) {
    this.alertMessage.showAlertMessage(saveMsg.message, saveMsg.type);
  }
  protected toggleAddReasonForm() {
    this.showAddReasonForm = !this.showAddReasonForm;
  }
  protected saveNewReason() {
    this.actionBtnDisabled = true;
    if (!this.addReasonForm.valid) {
      const error = this.addReasonForm.errors.join(',');
      this.actionBtnDisabled = false;
      this.alertMessage.showAlertMessage(error, 'Error');
      return;
    }

    const reason = this.tlAuditReasons.find((x) => x.value === this.addReasonForm.value.newTLAuditReason);
    const newReason = new NewTruckloadAuditReasonOld(this.tlAudit.invoiceAuditID, reason.text, this.addReasonForm.value.newReasonComment);
    if (this.tlAudit.invoiceAuditID !== 0) {
      this.store.dispatch(new SaveReasonOld(newReason));
    } else {
      this.store.dispatch(
        new AddAuditSaveReasonOld(this.currentInvoiceID, this.currentShipmentID, reason.text, this.addReasonForm.value.newReasonComment)
      );
    }
  }
  protected resetAddReasonForm() {
    this.addReasonForm.reset();
  }

  private createForm() {
    this.addReasonForm = this.formBuilder.group({
      newTLAuditReason: [null, requiredValidatorLogic],
      newReasonComment: ['', [Validators.minLength(3), Validators.maxLength(500)]],
    });
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailOldState.tlAudit)
      .subscribe((x) => {
        if (x) {
          this.tlAudit = x;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.loadingTLAuditError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Something went wrong:' + x, 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.tlAuditReasonData)
      .subscribe((x) => {
        if (x) {
          this.unfilteredTLReasons = x;
          // filter out existing reasons
          this.setAndFilterReasons(x.tlAuditReasons);
          // expand add new if no audit exist yet
          this.checkToExpandAddReason();
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.invoiceID)
      .subscribe((x) => {
        this.currentInvoiceID = x;
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.shipmentID)
      .subscribe((x) => {
        this.currentShipmentID = x;
      });

    this.store
      .select((x) => x.TLAuditDetailOldState.manageReasonsSaveInProgress)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Saving...');
          this.actionBtnDisabled = true;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.saveReasonError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Adding new event failed:' + x, 'Error');
          this.actionBtnDisabled = false;
          this.showSpinner(false);
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.saveReasonSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.actionBtnDisabled = false;
          this.setAndFilterReasons(this.unfilteredTLReasons.tlAuditReasons);
          this.resetAddReasonForm();
          this.alertMessage.showAlertMessage('Event saved successfully', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.updateReasonSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.actionBtnDisabled = false;
          this.alertMessage.showAlertMessage('Event updated successfully', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.updateReasonError)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.alertMessage.showAlertMessage('Updating status on event failed:' + x, 'Error');
          this.actionBtnDisabled = false;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.addCommentSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.actionBtnDisabled = false;
          this.alertMessage.showAlertMessage('Comment saved successfully', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.addCommentError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Adding new comment failed:' + x, 'Error');

          this.showSpinner(false);
          this.actionBtnDisabled = false;
        }
      });
  }

  private setAndFilterReasons(unfilteredReasons: object) {
    this.tlAuditReasons = Object.keys(unfilteredReasons).map(function (key, index) {
      return {
        text: unfilteredReasons[key].toString(),
        value: key,
      };
    });
    if (this.tlAudit !== null && this.tlAudit.invoiceAuditID !== 0) {
      const filteredList = this.tlAuditReasons.filter((reason) => {
        const foundMatch = this.tlAudit.reasons.find((x) => x.reason === reason.text);
        if (foundMatch === undefined) {
          return reason;
        }
      });
      this.tlAuditReasons = filteredList;
    }
  }

  private checkToExpandAddReason() {
    if (this.tlAudit === null || this.tlAudit.invoiceAuditID === 0) {
      this.showAddReasonForm = true;
    } else {
      this.showAddReasonForm = false;
    }
  }
  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }
}
