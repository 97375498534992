import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AuditUserResponse } from '@/services/InvoiceAudit';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { AuthService } from '@/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { LoadTLAuditorList, RemoveAuditorFromAudit, UpdateAuditor } from '../../actions/tl-audit-incident-assignment.actions';
import { UpdateAssignment } from '../../models/TLAudit';
import { AssignmentIncidentUI } from '../../models/TruckloadAudit.ui';

@Component({
  selector: 'app-tl-assign-auditor',
  templateUrl: './tl-assign-auditor.component.html',
  styleUrls: ['./tl-assign-auditor.component.scss'],
})
export class TlAssignAuditorComponent implements OnInit {
  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  selectedAuditor: { fullName: string; email: string } = { fullName: '', email: '' };

  theCurrentAuditor = '';
  invoiceIDs: number[];
  incidentAssigments: AssignmentIncidentUI[];
  auditorList: AuditUserResponse[];
  disableAssignBtn = false;
  disableRemoveBtn = false;
  userName: string; // temporary until JWT token is used
  constructor(private authService: AuthService, private store: Store<AppState>) { }

  ngOnInit() {
    this.userName = this.authService.BlueShipUser.userName;
    this.setListeners();
    this.store.dispatch(new LoadTLAuditorList());
  }

  protected clickAssignToMe() {
    // find the user and call the event
    const matchingAuditor = this.auditorList.find((obj) => obj.fullName === this.authService.BlueShipUser.name);

    // did we find a user?
    if (matchingAuditor == null) {
      this.alertMessage.showAlertMessage('You are not found in the Auditor list', 'Error');
      return;
    }
    this.selectedAuditor = { fullName: matchingAuditor.fullName, email: matchingAuditor.email };

    this.onAuditorChanged(this.selectedAuditor);
  }

  protected onAuditorChanged(newValue: any) {
    this.theCurrentAuditor = newValue.fullName;
    const updateAssignment = new UpdateAssignment(this.invoiceIDs, this.theCurrentAuditor, this.selectedAuditor.email, this.userName);
    this.store.dispatch(new UpdateAuditor(updateAssignment));
  }
  // protected onRemoveAuditor(event) {
  //   this.theCurrentAuditor = '';
  //   this.selectedAuditor = { fullName: '', email: '' };
  //   this.store.dispatch(new RemoveAuditorFromAudit(this.auditorID));
  // }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.tlAuditors)
      .subscribe((x) => {
        if (x) {
          this.auditorList = x;
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.loadAuditorsError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Loading Auditors failed', 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.savedAuditorChangesError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Auditor update failed', 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.savedAuditorChangesSuccess)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Auditor update success', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.selectedAuditor)
      .subscribe((x) => {
        this.theCurrentAuditor = x;
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.invoiceIDs)
      .subscribe((x) => {
        this.invoiceIDs = x;
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.incidentAssigments)
      .subscribe((x) => {
        this.incidentAssigments = x;
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.assignAuditorSaveInProgress)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Saving....');
        } else {
          this.showSpinner(false);
        }
        this.disableRemoveBtn = x;
        this.disableAssignBtn = x;
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.updateAuditorSaveInProgress)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Saving....');
        } else {
          this.showSpinner(false);
        }
        this.disableRemoveBtn = x;
        this.disableAssignBtn = x;
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.updateAuditorChangesError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Auditor update failed', 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.updateAuditorChangesSuccess)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Auditor update success', 'Information');
        }
      });
  }

  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }
}
