import * as bgr from '@/models/BgRating';

export class Shipment {
  public id: number;
  public enterpriseId: number;
  public enterpriseName: string;
  public enterpriseAccountNumber: string;
  public status: string;
  public mode: string;
  public dateConfirmed: Date;
  public dateBooked: Date;
  public dateCreated: Date;
  public refShipmentOrigin: string;
  public refShipmentType: string;
  public distance: number;
  // Billing
  // Carrier
  public shipmentReferences: ShipmentReference[];
  public services: Service[];
  public items: Item[];
  public origin: Address;
  public destination: Address;
  public billTo: Address;
  public quotes: Quote[];
}

export class ShipmentReference {
  public id: number;
  public type: string;
  public value: string;
  public isPrimary: boolean;
  public isDeleted: boolean;
}

export class Service {
  public id: number;
  public name: string;
  public code: string;
  public isDeleted: boolean;
}

export class Item {
  public id: number;
  public description: string;
  public freightClass: number;
  public nmfc: string;
  public isDeleted: boolean;
  public dimension: Dimension;
  public quantity: Quantity;
  public weight: Weight;
  public plannedWeight: Weight;
}

// Already exists?
export class Address {
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public postalCode: string;
  public name: string;
  public stateProvince: string;
  public countryCode: string;
  public earliestDate: Date;
  public latestDate: Date;
  public actualDate: Date;
}

export class Quote {
  public id: number;
  public shipmentId: number;
  public type: string;
  public isSelected: boolean;
  public analagousId: string;
  public requestGroupId: string;
  public carrierCode: string;
  public isDeleted: boolean;
  public mode: string;
  public charges: Charge[];
  public carrierName: string;
  public priceSheet: string;
  public scac: string;
  public distance: number;
  public contractId: number;
  public contractName: string;
  public service: string;
  public serviceDays: number;
  public expectedDeliveryDate: Date;
  public carrierIdentifier: string;
  public originService: string;
  public destinationService: string;
  public currencyCode: string;
  public filterReason: string;
  public isExcluded: boolean;
  public carrierLiabilityOld: string;
  public carrierLiabilityNew: string;
  public quoteNumber: string;
  public note: string;
  public isLeastCost: boolean;
  public quoteTotal: number;
  public normalizedTotal: number;
  public normalizedCurrencyCode: string;
  public isManualCreate: boolean;
  public serviceTotal: number;
  public freightTotal: number;
  public billingAddress: Address;
  public markup: Markup;
  public carrierAccountNumber?: string;

  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    this.id = 0;
    this.shipmentId = 0;
    this.analagousId = rate.carrierRate.analogousID;
    this.requestGroupId = requestGroupId;
    this.contractId = rate.contractID;
    this.contractName = rate.contractName;
    this.carrierName = rate.carrierName;
    this.scac = rate.scac;
    this.mode = rate.mode;
    this.service = rate.carrierRate.description; // Rename to serviceLevel per Geoffry
    this.serviceDays = rate.serviceDays;
    this.distance = distance;
    this.expectedDeliveryDate = new Date(rate.estimatedDelivery);
    this.carrierIdentifier = rate.carrierName;
    this.originService = rate.serviceOrigin;
    this.destinationService = rate.serviceDestination;
    this.currencyCode = rate.currencyCode;
    this.priceSheet = rate.source; // dbo.Quote.PriceSheetID
    this.filterReason = rate.isExcluded ? rate.excludeReason : '';
    this.isExcluded = rate.isExcluded;
    this.isSelected = false;
    this.carrierLiabilityOld = rate.maxLiability.toString();
    this.carrierLiabilityNew = rate.maxLiability.toString();
    this.quoteNumber = '';
    this.note = rate.discountType;
    this.isLeastCost = false;
    this.quoteTotal = 0;
    this.normalizedTotal = 0;
    this.normalizedCurrencyCode = 'USD';
    this.charges = [];
    this.isManualCreate = false;
    this.serviceTotal = 0;
    this.freightTotal = 0;
    this.billingAddress = <Address>{
      addressLine1: rate.billingAddress.addressLine1,
      addressLine2: rate.billingAddress.addressLine2,
      city: rate.billingAddress.city,
      stateProvince: rate.billingAddress.stateProvince,
      postalCode: rate.billingAddress.postalCode,
      countryCode: rate.billingAddress.country,
      name: rate.billingAddress.companyName
    };
    this.markup = null;
    this.carrierAccountNumber = rate.carrierAccountNumber;
  }

  public clone(): Quote {
    let obj = Object.create(this);
    Object.assign(obj, {
      ...this,
      billingAddress: { ...this.billingAddress }
    });
    return obj;
  }

  public setServiceLevel(serviceLevel: bgr.ServiceLevel, billedWeight: number): void {
    this.analagousId = serviceLevel.analogousID;
    this.service = serviceLevel.description;
    this.quoteNumber = serviceLevel.carrierQuoteNumber;
    this.quoteTotal = serviceLevel.totalCharge;
    this.charges = serviceLevel.serviceLevelCharges.map(x => new Charge(x, billedWeight));
  }
}

export class CarrierQuote extends Quote {
  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    super(requestGroupId, distance, rate);
    this.type = 'Charge';
    this.quoteTotal = rate.carrierRate.totalCharge;
    this.normalizedTotal = rate.carrierRate.totalChargeInUSD;
    this.charges = rate.carrierRate.charges.map(x => new Charge(x, rate.billedWeight));
  }
}

export class CustomerQuote extends Quote {
  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    super(requestGroupId, distance, rate);
    this.type = 'Cost';
    this.quoteTotal = rate.customerRate.totalCharge;
    this.normalizedTotal = rate.customerRate.totalChargeInUSD;
    this.charges = rate.customerRate.charges.map(x => new Charge(x, rate.billedWeight));

    if (rate.customerRate.markups.length > 0) {
      this.markup = new Markup(rate.customerRate.markups[0]);
    }
  }
}

export class ThirdPartyQuote extends Quote {
  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    super(requestGroupId, distance, rate);
    this.type = 'Price';
    this.quoteTotal = rate.customerThirdRate.totalCharge;
    this.normalizedTotal = rate.customerThirdRate.totalChargeInUSD;
    this.charges = rate.customerThirdRate.charges.map(x => new Charge(x, rate.billedWeight));
  }
}

export class Dimension {
  public type: string;
  public uom: string;
  public length: number;
  public width: number;
  public height: number;
}

export class Quantity {
  public type: string;
  public uom: string;
  public value: number;
  public numberOfPieces: number;
  public piecesUom: string;
}

export class Weight {
  public type: string;
  public uom: string;
  public value: number;
}

// Due to the similarity between the shipment API and BGR charge structure,
// implementing the interface here so that charge types from different
// sources can be sent into the BGR service
export class Charge implements bgr.Charge {
  static readonly ACCESSORIAL: string = 'accessorial';
  static readonly LINEHAUL: string = 'linehaul';

  public id: number;
  public quoteId: number;
  public type: string;
  public subType: string;
  public sequenceNumber: number;
  public itemGroupId: number;
  public description: string;
  public ediCode: string;
  public amount: number;
  public rate: number;
  public rateQualifier: string;
  public quantity: number;
  public weight: number;
  public dimWeight: number;
  public freightClass: number;
  public fakFreightClass: number;
  public isMin: boolean;
  public isMax: boolean;
  public isNonTaxable: boolean;
  public isDeleted: boolean;

  // This field is not used by shipment API and is only here to satisfy
  // the interface requirement
  public code: string;

  constructor(charge: bgr.Charge, billedWeight: number) {
    let isLinehaul = charge.type.toLocaleLowerCase() === Charge.LINEHAUL;
    let isAccessorial = charge.type.toLocaleLowerCase() === Charge.ACCESSORIAL;
    let type = isLinehaul || isAccessorial ? charge.subType : charge.type;
    this.type = this.mapChargeType(type);
    this.subType = charge.subType;
    this.description = charge.description;
    this.amount = charge.amount;
    this.rate = charge.rate;
    this.rateQualifier = charge.rateQualifier;
    this.quantity = charge.quantity;
    this.weight = charge.weight || billedWeight;
    this.dimWeight = 0;
    this.freightClass = charge.freightClass;
    this.fakFreightClass = charge.fakFreightClass;
    this.isMin = charge.isMin;
    this.isMax = charge.isMax;
    this.isNonTaxable = false;
    this.ediCode = charge.code;
    this.code = charge.code;
  }

  protected mapChargeType(subtype: string): string {
    switch (subtype.toLocaleUpperCase()) {
      case 'LINEHAUL':
        return 'ITEM';
      case 'DISCOUNT':
        return 'DISCOUNT';
      case 'ADJUSTMENT':
        return 'MG_MINMAX_ADJ';
      case 'SMCADJUSTMENT':
      case 'MINIMUM':
        return 'SMC_MIN_ADJ';
      case 'DEFICIT':
        return 'DEFICIT';
      case 'FUELSURCHARGE':
        return 'ACCESSORIAL_FUEL';
      default:
        return 'ACCESSORIAL';
    }
  }
}

export class Markup {
  constructor(markup: bgr.Markup) {
    this.id = 0;
    this.index = markup.index;
    this.amount = markup.amount;
    this.type = markup.type;
  }
  public id: number;
  public index: string;
  public amount: number;
  public type: string;
}
