import { InvoiceService } from '@/services/invoice.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss']
})
export class NoteListComponent implements OnInit {

  @Input() invoiceId: number;
  notes: any[];
  loading: boolean = true;
  error: any;

  constructor(
    private invoiceService: InvoiceService
  ) { }

  ngOnInit() {
    this.invoiceService
      .getNotes(this.invoiceId)
      .subscribe(x => {
        this.notes = x.sort((a, b) => {
          if (a.dateCreated > b.dateCreated) return -1;
          else if (a.dateCreate < b.dateCreate) return 1;
          return 0;
        });
        this.loading = false;
      },
        err => {
          console.log(err);
          this.error = err.message;
          this.loading = false;
        });
  }
}
