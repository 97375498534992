export interface ShipmentInvoice {
  errorReason?: string;
  invoiceDate: Date;
  invoiceID: number;
  invoiceNumber: string;
  invoiceTotal: number;
  isDeleting?: boolean;
  isError: boolean;
  shipmentID: number;
  status: string;
  type: string;
  variance?: number;
  analogousID?: number;
  mode: string;
}

export interface DeleteShipmentInvoice extends ShipmentInvoice {
  isDeleting?: boolean;
}

export const priceSheetIDArray: string[] = [
  'BGR_Blanket',
  'BGR_ASP',
  'BGR_BM_ASP',
  'BGR_Direct',
  'BGR_BM_Blanket',
  'BGR_BlanketProposal',
  'BGR_BM_BlanketProposal',
  'BGR_ASPProposal',
  'BGR_ASPProposalDirect',
  'BGR_BM_ASPProposal',
  'BGR',
  'P44_Dynamic',
  'P44_Volume',
  'Manual'
]
