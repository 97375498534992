import { AuthService } from '@/auth/auth.service';
import { QuoteResponse } from '@/models/BgRating';
import { CustomerCharge, Rate } from '@/models/ShipmentInvoiceAudit';
import { ShipmentInvoiceAuditUI as ui } from '@/pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { BgRatingHelper } from '../bgRatingHelper';
import { BgRatingService } from '../../services/bgRating.service';
import { MarkupCharge, Markup } from '../../models/BgRating';
import { ShipmentUI } from '../../pages/audit/Shipment.ui';
import { ShipmentEditComponent } from '@/components/shipment-edit/shipment-edit.component';

@Component({
  selector: 'app-rates-modal',
  templateUrl: './rates-modal.component.html',
  styleUrls: ['./rates-modal.component.scss'],
  host: {
    class: 'kendoRates'
  }
})
export class RatesModalComponent extends DialogContentBase implements OnInit {

  bgRatingHelper: BgRatingHelper = new BgRatingHelper();
  @Input() bgReRateAll$: Observable<QuoteResponse>;
  @Input() shipment: ui.Shipment = null;
  @Input() uplift?: Markup = null;
  @Input() originEarliestDate?: Date = null;
  @Input() items?: ShipmentUI.ItemUI[] = null;
  rates: Rate[];
  filteredRates: Rate[];
  selectedRate: Rate;
  loading: boolean = true;
  canEditCost: boolean = false;
  originAddress: ui.Address;
  destinationAddress: ui.Address;

  @ViewChild('shipmentEdit', { static: false })
  shipmentEdit: ShipmentEditComponent;

  constructor(public dialog: DialogRef, private authService: AuthService,
    private bgRatingService: BgRatingService) {
    super(dialog);
  }

  ngOnInit() {
    this.getRates();
  }

  getRates(): void {
    this.originAddress = this.shipment.origin;
    this.destinationAddress = this.shipment.destination;
    this.canEditCost = this.authService.hasOperation('CanEdit_Cost');
    this.bgReRateAll$.subscribe(rates => {
      this.loading = false;
      this.rates = this.bgRatingHelper.mapRates(rates);

      this.rates.forEach(rate => {
        if (this.canEditCost) rate.customerCostAdjustment = rate.customerTotal;
      });

      this.filteredRates = this.rates.filter((r) => !r.isExcluded);
    })
  }

  selectRate(rate: Rate): void {
    if (this.uplift.amount != null && this.uplift.type != null) {
      const parameters = {
        scac: rate.scac,
        accountNumber: this.shipment.accountNumber,
        originCountryCode: this.shipment.origin.countryCode,
        originPostalCode: this.shipment.origin.postalCode,
        originStateProvince: this.shipment.origin.stateProvince,
        destinationCountryCode: this.shipment.destination.countryCode,
        destinationPostalCode: this.shipment.destination.postalCode,
        destinationStateProvince: this.shipment.destination.stateProvince,
        upliftAmount: this.uplift.amount,
        upliftType: this.uplift.type,
        shipmentDate: this.originEarliestDate,
        mode: rate.mode,
        currencyCode: rate.currencyCode,
      };

      const charges = rate.carrierCharges.map((charge) => {
        return <MarkupCharge>{
          amount: charge.amount,
          description: charge.description,
          type: this.bgRatingHelper.getBGChargeType(charge.type),
          subType: this.bgRatingHelper.getBGChargeSubType(charge.type),
          code: charge.ediCode,
        };
      });

      const request = this.bgRatingHelper.createUpliftRequest(parameters, this.items, charges)
      this.bgRatingService.getRateUplift(request).subscribe((result) => {
        if (result != null) {
          const resultRate = result.rates.find(x => x.scac == rate.scac);
          if (resultRate != null) {
            rate.customerCharges = resultRate.customerRate.charges.map((c) =>
              <CustomerCharge>{
                chargeID: c.chargeID,
                type: c.type,
                subType: c.subType,
                description: c.description,
                amount: c.amount,
                rate: c.rate,
                rateQualifier: c.rateQualifier,
                quantity: c.quantity,
                weight: c.weight,
                dimWeight: c.dimWeight,
                freightClass: c.freightClass,
                fakFreightClass: c.fakFreightClass,
                isMin: c.isMin,
                isMax: c.isMax,
                isNonTaxable: c.isNonTaxable,
                isLinehaul: c.isLinehaul,
                isAccessorial: c.isAccessorial,
                ediCode: c.code,
                code: c.code
              }
            );
            rate.markups = [];
            rate.markups.push(this.uplift);
            rate.isSelected = true;
            this.dialog.close(rate);
          }
        }
      });
    } else {
      rate.isSelected = true;
      this.dialog.close(rate);
    }
  }

  public onCancel(): void {
    this.dialog.close();
  }

  disableSelectButton(): Boolean {
    return this.hasBlockBookRole;
  }

  getMinCost(rate: Rate): number {
    return rate.customerTotal - rate.customerTotal * 0.05;
  }

  showMinCostWarning(rate: Rate) {
    return this.canEditCost && rate.customerCostAdjustment < this.getMinCost(rate);
  }

  get hasBlockBookRole(): Boolean {
    return this.authService.hasOperation('BlockBook');
  }
}
