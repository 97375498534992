import { BooleanFilterMenuComponent } from '@progress/kendo-angular-grid';
import { AuditCarrierInvoiceUI } from '../pages/audit/AuditCarrierInvoice.ui';
import ui = AuditCarrierInvoiceUI;
import { InvoiceCharge } from '@/services/InvoiceAudit';
// references
export class CarrierResponse {
  refCarrierID: number;
  carrierName: string;
  scac: string;
  mcNumber: string;
  dotNumber: string;
  carrierCode: string;
  stateProvince: string;
  city: string;
  constructor(
    refCarrierID: number,
    carrierName: string,
    scac: string,
    mcNumber: string,
    dotNumber: string,
    carrierCode: string,
    stateProvince: string,
    city: string,
  ) {
    this.refCarrierID = refCarrierID;
    this.carrierName = carrierName;
    this.scac = scac;
    this.mcNumber = mcNumber;
    this.dotNumber = dotNumber;
    this.carrierCode = carrierCode;
    this.stateProvince = stateProvince;
    this.city = city;
  }
}

export class SearchResultDC {
  carrierID: number;
  displayName: string;
  scac: string;
  mcNumber: string;
  dotNumber: string;
  carrierCode: string;
  stateProvince: string;
  city: string;
}

export class CreateCarrierInvoice {
  shipmentID: number;
  invoiceDate: Date;
  status: string;
  lane: string;
  paymentTerms: string;
  invoiceNumber: string;
  carrierName: string;
  scac: string;
  invoiceWeight: number;
  glCode: string;
  actualShipDate: Date;
  distance: number;
  serviceTotal: number;
  freightTotal: number;
  isSecondary: boolean;
  mode: string;
  mcNumber: string;
  dotNumber: string;
  carrierCode: string;
  isBBill: boolean;
  bBillAccountNumber: string;
  sendCreateEvent: boolean;
  invoiceCharges: CreateInvoiceCharge[];
  currencyCode?: string;
  normalizedTotal?: number;
  normalizedCurrencyCode?: string;
  constructor(
    carrierInvoiceAudit: ui.CarrierInvoiceAudit,
    invoiceDate: Date,
    invoiceNumber: string,
    invoiceWeight: number,
    paymentTerms: string,
    serviceTotal: number,
    freightTotal: number,
    invoiceCharges: InvoiceCharge[]
  ) {
    this.shipmentID = carrierInvoiceAudit.shipmentID;
    this.mode = carrierInvoiceAudit.mode;
    this.actualShipDate = carrierInvoiceAudit.dateShipped;
    this.status = carrierInvoiceAudit.status;
    this.lane = carrierInvoiceAudit.lane;
    this.currencyCode = carrierInvoiceAudit.currencyCode;
    this.mcNumber =
      carrierInvoiceAudit.mcNumber === undefined || carrierInvoiceAudit.mcNumber === null || carrierInvoiceAudit.mcNumber === 'n/a'
        ? ''
        : carrierInvoiceAudit.mcNumber;
    this.dotNumber =
      carrierInvoiceAudit.dotNumber === undefined || carrierInvoiceAudit.dotNumber === null || carrierInvoiceAudit.dotNumber === 'n/a'
        ? ''
        : carrierInvoiceAudit.dotNumber;
    this.carrierCode =
      carrierInvoiceAudit.carrierCode === undefined || carrierInvoiceAudit.carrierCode === null || carrierInvoiceAudit.carrierCode === 'n/a'
        ? ''
        : carrierInvoiceAudit.carrierCode;
    this.scac =
      carrierInvoiceAudit.scac === undefined || carrierInvoiceAudit.scac === null || carrierInvoiceAudit.scac === 'n/a'
        ? ''
        : carrierInvoiceAudit.scac;
    this.distance = carrierInvoiceAudit.distance;
    this.carrierName = carrierInvoiceAudit.carrierName;

    this.invoiceDate = invoiceDate;
    this.invoiceNumber = invoiceNumber;
    this.invoiceWeight = invoiceWeight;
    this.paymentTerms = paymentTerms;
    this.serviceTotal = serviceTotal;
    this.freightTotal = freightTotal;

    this.sendCreateEvent = true;
    this.isSecondary = false;
    this.isBBill = false;
    this.bBillAccountNumber = '';
    this.glCode = '';

    let sequence = 1;
    this.invoiceCharges = invoiceCharges.map(
      ({ description, freightClass, fakFreightClass, rate, rateCode: rateQualifier, quantity, amount, group, type }) => ({
        description,
        freightClass,
        fakFreight: fakFreightClass,
        rate,
        rateQualifier,
        quantity,
        amount,
        group,
        type,
        sequence: sequence++,
        isMin: false,
        isMax: false,
        isNonTaxable: false,
        weight: 0,
        dimWeight: 0,
        ediCode: '',
      })
    );
  }
}

export class CreateInvoiceCharge {
  sequence: number;
  type: string;
  group: string;
  description: string;
  ediCode: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreight: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
}

export class EditCarrierInvoice {
  carrierCode: string;
  carrierName: string;
  currency: string;
  distance: number;
  dotNumber: string;
  freightTotal: number;
  invoiceDate: Date;
  invoiceNumber: string;
  invoiceWeight: number;
  mcNumber: string;
  paymentTerms: string;
  scac: string;
  serviceTotal: number;
  charges: EditInvoiceCharge[];
  currencyCode?: string;
  normalizedTotal?: number;
  normalizedCurrencyCode?: string;
  constructor(
    carrierInvoiceAudit: ui.CarrierInvoiceAudit,
    invoiceDate: Date,
    invoiceNumber: string,
    invoiceWeight: number,
    paymentTerms: string,
    serviceTotal: number,
    freightTotal: number,
    invoiceCharges: InvoiceCharge[]
  ) {
    (this.currency = 'USD'),
      (this.mcNumber =
        carrierInvoiceAudit.mcNumber === undefined || carrierInvoiceAudit.mcNumber === null || carrierInvoiceAudit.mcNumber === 'n/a'
          ? ''
          : carrierInvoiceAudit.mcNumber);
    this.dotNumber =
      carrierInvoiceAudit.dotNumber === undefined || carrierInvoiceAudit.dotNumber === null || carrierInvoiceAudit.dotNumber === 'n/a'
        ? ''
        : carrierInvoiceAudit.dotNumber;
    this.carrierCode =
      carrierInvoiceAudit.carrierCode === undefined || carrierInvoiceAudit.carrierCode === null || carrierInvoiceAudit.carrierCode === 'n/a'
        ? ''
        : carrierInvoiceAudit.carrierCode;
    this.scac =
      carrierInvoiceAudit.scac === undefined || carrierInvoiceAudit.scac === null || carrierInvoiceAudit.scac === 'n/a'
        ? ''
        : carrierInvoiceAudit.scac;
    this.distance = carrierInvoiceAudit.distance;
    this.carrierName = carrierInvoiceAudit.carrierName;

    this.invoiceDate = invoiceDate;
    this.invoiceNumber = invoiceNumber;
    this.invoiceWeight = invoiceWeight;
    this.paymentTerms = paymentTerms;
    this.serviceTotal = serviceTotal;
    this.freightTotal = freightTotal;
    this.currencyCode = carrierInvoiceAudit.currencyCode;
    let sequence = 1;
    this.charges = invoiceCharges.map(
      ({
        invoiceChargeID,
        type,
        group,
        description,
        ediCode,
        amount,
        rate,
        rateCode: rateQualifier,
        quantity,
        weight,
        dimWeight,
        freightClass,
        fakFreightClass,
      }) => ({
        id: invoiceChargeID,
        sequence: sequence++,
        type,
        group,
        description,
        ediCode,
        amount,
        rate,
        rateQualifier,
        quantity,
        weight,
        dimWeight,
        freightClass,
        fakFreight: fakFreightClass,
      })
    );
  }
}

export class EditInvoiceCharge {
  id: number;
  sequence: number;
  type: string;
  group: string;
  description: string;
  ediCode: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreight: number;
}

export class CarrierInvoiceResponse {
  invoiceID: number;
  shipmentID: number;
  analogousID: number;
  auditor: string;
  type: string;
  invoiceDate: Date;
  invoiceNumber: string;
  status: string;
  scac: string;
  mode: string;
  carrierName: string;
  mcNumber: string;
  dotNumber: string;
  lane: string;
  glCode: string;
  invoiceWeight: number;
  actualShipDate: Date;
  invoiceTotal: number;
  normalizedTotal: number;
  distance: number;
  serviceTotal: number;
  freightTotal: number;
  normalizedCurrency: string;
  currency: string;
  carrierCode: string;
  currencyCode: string;
  isSecondary: boolean;
  isBBill: boolean;
  bBillAccountNumber: string;
  paymentTerms: string;

  charges: InvoiceChargeResponse[];
}

export class InvoiceChargeResponse {
  invoiceChargeID: number;
  invoiceID: number;
  sequence: number;
  description: string;
  amount: number;
  ediCode: string;
  rate: number;
  rateQualifier: string;
  quantity: number;

  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreight: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;

  type: string;
  group: string;
}
