import { QuoteDetail, QuoteHistory, ShipmentQuoteHistoryResponse } from '@/models/ShipmentQuoteHistoryResponse';
import { ShipmentHistoryService } from '@/services/shipment-history.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shipment-quote-history',
  templateUrl: './shipment-quote-history.component.html',
  styleUrls: ['./shipment-quote-history.component.scss'],
})
export class ShipmentQuoteHistoryComponent implements OnInit {
  @Input()
  shipmentID: number;
  loading: boolean;
  shipmentQuoteHistory: ShipmentQuoteHistoryResponse;
  quotes: QuoteHistory[] = [];
  quote1Selected: QuoteHistory;
  quote1CarrierDetail: QuoteDetail;
  quote1CustomerDetail: QuoteDetail;
  quote2Selected: QuoteHistory;
  quote2CarrierDetail: QuoteDetail;
  quote2CustomerDetail: QuoteDetail;

  constructor(private route: ActivatedRoute, private shipmentHistoryService: ShipmentHistoryService) { }

  ngOnInit() {
    this.getQuoteHistory();
  }

  public getQuoteHistory(): Observable<ShipmentQuoteHistoryResponse> {
    this.loading = true;
    let quoteObservable = this.shipmentHistoryService.getQuoteHistory(this.shipmentID);
    quoteObservable.subscribe((shipmentQuoteHistoryResponse) => {
      this.shipmentQuoteHistory = shipmentQuoteHistoryResponse;
      this.quotes = this.shipmentQuoteHistory.quotes;
      const quoteSelected = this.quotes.filter((quote) => quote.isSelected)[0];

      if (quoteSelected) {
        const quoteSelectedID = quoteSelected.analogousID;
        this.selectQuote1(quoteSelectedID);
      }
      this.loading = false;
    });

    return quoteObservable;
  }

  selectQuote1(quoteID: string) {
    if (!quoteID || quoteID.length === 0) {
      return;
    }
    this.quote1Selected = this.quotes.filter((quote) => quote.analogousID === quoteID)[0];
    if (this.quote1Selected) {
      this.quote1CarrierDetail = this.quote1Selected.charge;
      this.quote1CustomerDetail = this.quote1Selected.cost;
    }
  }

  selectQuote2(quoteID: string) {
    if (!quoteID || quoteID.length === 0) {
      return;
    }
    this.quote2Selected = this.quotes.filter((quote) => quote.analogousID === quoteID)[0];
    if (this.quote2Selected) {
      this.quote2CarrierDetail = this.quote2Selected.charge;
      this.quote2CustomerDetail = this.quote2Selected.cost;
    }
  }
}
