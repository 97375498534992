import { Component, OnInit, ViewChild } from '@angular/core';
import { GridDataResult, RowClassArgs, PageChangeEvent, FilterService } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Helpers, setSettings, getSettings, getFilterSettings, setFilterSettings } from '@/_shared/helpers';
import { AuthService } from '@/auth/auth.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Router } from '@angular/router';
import { StartupService } from '@/startup.service';
import { InvoiceService } from '@/services/invoice.service';
import { TLAuditServiceOld } from '@/pages/tl-audit-old/services/tl-audit-old.service';
import { TruckloadAuditInvoicesFilter } from '@/_shared/FilterFactory';
import { TruckloadAuditOldUI, BBillOld } from './models/TruckloadAuditOld.ui';
import { NotesAutoComponent } from '@/bg-common/app-autocomplete/app-autocomplete';
import { ResetTLInvoiceOld } from './models/TLAuditOld';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { ShowManageReasonsOld, LoadTLAuditDetailOld, ShowAssignAuditorOld } from './actions/tl-audit-detail-old.actions';
import { ShowBBillOld } from './actions/tl-audit-bbill-old.actions';

const flatten = (filter) => {
  const filters = (filter || {}).filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-truckload-audit-old',
  templateUrl: './truckload-audit-old.component.html',
  styleUrls: ['./truckload-audit-old.component.scss'],
})
export class TruckloadAuditComponentOld implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild('searchTlAuditBox', { static: false })
  autoFilter: NotesAutoComponent;

  recordCount = 0;
  gridDataCurrent: TruckloadAuditOldUI[] = [];
  gridDataAll: TruckloadAuditOldUI[] = [];
  gridData: GridDataResult;

  helpers: Helpers;
  resettingInvoices = false;
  openedEditTruckload = false;
  selectedRecords: number[] = [];
  selectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
  };

  pageSize = 25;
  skip = 0;
  truckloadInvoicesFilter = new TruckloadAuditInvoicesFilter();
  _cacheKey = 'tlAudit-setting';
  selectedAudit: TruckloadAuditOldUI = null;
  showManageReasons = false;
  disableManageReasonCancel = false;
  disableAssignAuditorCancel = false;
  showAuditors = false;
  showCreateBBill = false;
  disableBBillCancel = false;
  public isDataloading: boolean;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor;
  public eventReasons = new Array<any>();
  public selectedEventReasons = new Array<any>();
  incidentManagementOn: boolean;
  constructor(
    private router: Router,
    private startupService: StartupService,
    private authService: AuthService,
    private tlAuditService: TLAuditServiceOld,
    private pcInvoiceService: InvoiceService,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.sort = getSettings(this._cacheKey, '', '');
    this.filter = getFilterSettings(this._cacheKey + '-filter');
    this.helpers = new Helpers(this.authService);
    this.loadPage();
    this.setListeners();
    this.incidentManagementOn = this.startupService.backofficeEventManagement;

    if (this.incidentManagementOn) {
      this.router.navigate([`tl-audit-incident`]);
    }
  }

  private loadPage() {
    this.isDataloading = true;
    this.tlAuditService.getTLAudits().subscribe(
      (data) => {
        if (data) {
          this.gridDataAll = data;
          this.gridDataCurrent = data;
          this.recordCount = this.gridDataAll.length;
          this.uniqueEventReasons();
          this.setRecords();
        }
        this.isDataloading = false;
        this.appSpinner.loading = false;
      },
      (err) => {
        this.appSpinner.loading = false;
        this.isDataloading = false;
        if (err.statusText !== 'Not Found') {
          this.alertMessage.showAlertMessage('Error loading the TL Invoice Audit Page', 'Error');
        }
      }
    );
  }

  // tl reset
  protected resetInvoices() {
    if (this.selectedRecords.length === 0) {
      return;
    }
    this.resettingInvoices = true;
    this.appSpinner.loading = true;
    const resetList = this.gridDataAll.filter((elem) => {
      return this.selectedRecords.includes(elem.invoiceID);
    });
    const resetInvoices: ResetTLInvoiceOld[] = [];
    resetList.forEach((x) => {
      resetInvoices.push({ shipmentID: x.shipmentID, invoiceNumber: x.invoiceNumber });
    });
    let allSuccessCount = 0;
    this.pcInvoiceService.resetTruckloadInvoices(resetInvoices).subscribe((data) => {
      data.forEach((element) => {
        if (element.resetSuccessful === true) {
          allSuccessCount += 1;
          this.recordCount = this.recordCount - 1;
          this.gridDataAll.splice(
            this.gridDataAll.findIndex((i) => i.invoiceNumber === element.invoiceNumber && i.shipmentID === element.shipmentID),
            1
          );
        }
      });
      this.gridDataCurrent = this.gridDataAll;
      this.resetPagingAndSorting();
      this.setRecords();
      this.uniqueEventReasons();
      this.appSpinner.loading = false;
      this.alertMessage.showAlertMessage('Invoice Record(s) have been Reset', 'Success');
      this.selectedRecords = [];
      this.resettingInvoices = false;
    });
  }

  // actions on list
  protected editInvoice(e, dataitem) {
    const currentURL = `/truckload-edit/${dataitem.shipmentID}/${dataitem.invoiceID}`;
    this.router.navigate([]).then((result) => {
      window.open(currentURL, '_blank');
    });
  }
  protected viewTruckload(e, dataitem) {
    const url = this.startupService.backOfficeUrl + '/shipment-edit/' + dataitem.shipmentID;
    window.open(url, '_blank');
  }
  protected manageReasons(e, dataitem) {
    this.selectedAudit = dataitem;
    this.store.dispatch(new ShowManageReasonsOld(true));
    this.store.dispatch(new LoadTLAuditDetailOld(this.selectedAudit.invoiceID, this.selectedAudit.shipmentID));
  }

  protected manageAuditor(e, dataitem) {
    this.selectedAudit = dataitem;
    this.store.dispatch(new ShowAssignAuditorOld(this.selectedAudit.invoiceAuditID, this.selectedAudit.auditor, true));
  }
  protected showBBill(e, dataitem) {
    this.selectedAudit = dataitem;
    const bbill = new BBillOld(
      this.selectedAudit.invoiceID,
      this.selectedAudit.shipmentID,
      this.selectedAudit.invoiceNumber,
      this.selectedAudit.customerAccount
    );
    this.store.dispatch(new ShowBBillOld(true, this.selectedAudit.invoiceID, bbill));
  }

  protected closeManageReasons() {
    this.store.dispatch(new ShowManageReasonsOld(false));
  }

  protected closeManageAuditor() {
    this.store.dispatch(new ShowAssignAuditorOld(0, null, false));
  }

  protected closeBBill() {
    this.store.dispatch(new ShowBBillOld(false, 0, null));
  }

  // page events
  protected OnRefresh() {
    this.appSpinner.loading = true;
    this.autoFilter.reset();
    this.selectedRecords = [];
    this.loadPage();
  }

  protected searchFilter(search: string = null): void {
    search
      ? (this.gridDataCurrent = this.gridDataAll.filter((obj) => this.truckloadInvoicesFilter.Match(obj, search.toLowerCase())))
      : (this.gridDataCurrent = this.gridDataAll);
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  public eventReasonFilter(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedEventReasons.splice(0, this.selectedEventReasons.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedEventReasons;
  }

  public eventReasonChange(values: any[], filterService: FilterService): void {
    filterService.filter({
      filters: values.map((value) => ({
        field: 'reasonString',
        operator: 'contains',
        value,
      })),
      logic: 'or',
    });
  }

  protected linkSearch(event) {
    this.searchFilter(event.trim());
  }

  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
    setSettings(this._cacheKey, this.sort);
  }

  protected filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.setRecords();
    setFilterSettings(this._cacheKey + '-filter', this.filter);
  }

  // helpers
  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailOldState.showManageReasons)
      .subscribe((x) => {
        this.showManageReasons = x;
        if (x === false) {
          this.selectedAudit = null;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.showAssignAuditor)
      .subscribe((x) => {
        this.showAuditors = x;
        if (x === false) {
          this.selectedAudit = null;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.updatedAuditor)
      .subscribe((x) => {
        if (this.selectedAudit) {
          const matchingDataItem = this.gridDataCurrent.filter(
            (g) => g.shipmentID === this.selectedAudit.shipmentID && g.invoiceID === this.selectedAudit.invoiceID
          );
          if (matchingDataItem.length > 1 || matchingDataItem.length === 0) {
            console.log('Error updating List for record' + JSON.stringify(this.selectedAudit));
            return;
          }
          matchingDataItem[0].auditor = x;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.manageReasonsSaveInProgress)
      .subscribe((x) => {
        this.disableManageReasonCancel = x;
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.assignAuditorSaveInProgress)
      .subscribe((x) => {
        this.disableAssignAuditorCancel = x;
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.saveInProgress)
      .subscribe((x) => {
        this.disableBBillCancel = x;
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.savedSuccess)
      .subscribe((x) => {
        if (x) {
          this.removeBBill();
        }
      });
    this.store
      .select((x) => x.TLAuditBBillOldState.showBBill)
      .subscribe((x) => {
        this.showCreateBBill = x;
        if (x === false) {
          this.selectedAudit = null;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.tlAudit)
      .subscribe((audit) => {
        if (audit) {
          const matchingDataItem = this.gridDataCurrent.filter((g) => g.shipmentID === audit.shipmentID && g.invoiceID === audit.invoiceID);
          if (matchingDataItem.length > 1 || matchingDataItem.length === 0) {
            console.log('Error updating List for record' + JSON.stringify(audit));
            return;
          }
          if (matchingDataItem[0].invoiceAuditID === 0) {
            matchingDataItem[0].reasonString = audit.reasonString;
            matchingDataItem[0].reasons = audit.reasons;
            matchingDataItem[0].invoiceAuditID = audit.invoiceAuditID;
          } else {
            matchingDataItem[0].reasons = audit.reasons;
            matchingDataItem[0].auditor = audit.auditor;
          }
        }
      });
  }

  private removeBBill() {
    if (this.selectedAudit) {
      const allIndex = this.gridDataAll.indexOf(this.selectedAudit);
      this.gridDataAll.splice(allIndex, 1);
      this.gridDataCurrent = this.gridDataAll;
      this.recordCount = this.gridDataAll.length;
      this.uniqueEventReasons();
      this.setRecords();
    }
    this.closeBBill();
  }
  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  private resetPagingAndSorting() {
    // going back to first page
    this.skip = 0;
    this.pageSize = 25;
    this.sort = [];
  }
  private setRecords(): void {
    if (this.gridDataCurrent == null) {
      return;
    }

    let records = filterBy(this.gridDataCurrent, this.filter);
    records = orderBy(records, this.sort);
    this.gridData = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }

  private uniqueEventReasons() {
    const reasonArray: string[] = [];

    this.gridDataAll.map((item) => {
      item.reasons.forEach((r) => {
        reasonArray.push(r.reason);
      });
    });
    this.eventReasons = reasonArray.filter((value, index, self) => self.indexOf(value) === index);
  }
}
