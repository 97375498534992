import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, Renderer2, AfterViewInit, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { CollectionService } from '../../services/collection.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuthService } from '../../auth/auth.service';
import { Helpers } from '../../_shared/helpers';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { RowClassArgs, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import {
  CollectionItem,
  References,
  CollectionStatusDTO,
  SaveCollection,
  EnterpriseChildDTO,
  PotentialCollection,
  CreateCollection,
} from '../../services/Collection';
import { FormGroup, FormControl } from '@angular/forms';
import { PageChangeEvent, GridDataResult, GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { map } from 'rxjs/operators';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('enterpriseautocomplete')
  public enterpriseAutoComplete: QueryList<AutoCompleteComponent>;

  @ViewChild(GridComponent, { static: true })
  private grid: GridComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  gridView: GridDataResult = null;
  public formGroup: FormGroup;
  recordBook: CollectionItem[];
  recordBookGrid: CollectionItem[];
  selectedStatus: CollectionStatusDTO;

  theEnterpriseAutoComplete: AutoCompleteComponent;
  references: References = null;
  enterprises: EnterpriseChildDTO[] = null;
  enterpriseData: Array<{
    enterpriseID: number;
    name: string;
    accountNumber: string;
    fullName: string;
  }>;

  potentialCollection: PotentialCollection;
  selectedEnterprise: EnterpriseChildDTO;
  isLookup: boolean;
  isImporting: boolean;
  lookupError: boolean;
  editedRowIndex: number;
  messageLookup: string;
  messageImport: string;
  originalText: string;
  helpers: Helpers;
  private docClickSubscription: any = null;

  searchText: string;
  accountNumber: string;
  selectedId: number;
  selectedKeys: number[] = [];

  recordCount: number;
  pageSize: number;
  skip: number;
  isDataloading: boolean;
  public selectableSettings: SelectableSettings;
  deleteTextConfirm: string;
  saveTextConfirm: string;
  deleteDebtId: number;
  opened: boolean;
  openedSave: boolean;
  sender: any;

  defaultStatus: { id: number; status: string } = {
    status: 'All',
    id: 0,
  };

  public sort: SortDescriptor[] = [
    {
      field: 'AccountName',
      dir: 'asc',
    },
  ];

  constructor(private pcService: CollectionService, private router: Router, private authService: AuthService, private renderer: Renderer2) {
    this.itemDisabled = this.itemDisabled.bind(this);
    this.setSelectableSettings();
    this.enterpriseData = [];
  }
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
    };
  }
  ngOnInit() {
    this.editedRowIndex = 0;
    this.skip = 0;
    this.selectedId = 0;
    this.pageSize = 25;
    this.isDataloading = false;
    this.lookupError = false;
    this.isLookup = false;
    this.isImporting = false;
    this.originalText = '';
    this.messageLookup = '';
    this.messageImport = '';
    this.accountNumber = '';
    this.helpers = new Helpers(this.authService);

    // are they allowed to view this page
    if (!this.helpers.hasOperation('CanViewCollection') && !this.helpers.hasOperation('CanWorkCollection')) {
      this.router.navigate([`not-authorized`]);
    }

    // load references
    this.pcService.getReferences().subscribe((data) => {
      this.references = data;
      this.selectedStatus = this.defaultStatus;
      this.loadRecords();
    });
  }
  ngAfterViewInit() {
    this.enterpriseAutoComplete.changes.subscribe((comps: QueryList<AutoCompleteComponent>) => {
      if (this.enterpriseAutoComplete.length === 1) {
        this.theEnterpriseAutoComplete = this.enterpriseAutoComplete.last;
      }
    });
  }

  public handleEnterpriseFilterChange(filter: any): void {
    const searchResults = [];
    this.theEnterpriseAutoComplete.loading = true;

    filter.trim();
    this.originalText = filter;

    // only get data from server if its 3 characters.  Otherwise just filter the results returned
    if (filter.length >= 3) {
      // load the enterprises
      this.pcService
        .getEnterprises(filter)
        .pipe(
          map((data) => {
            return data.map((row) => {
              row.fullName = row.name + ' - ' + row.accountNumber;
              return row;
            });
          })
        )
        .subscribe((ep) => {
          // Sort on client.  Otherwise we would need changes to ShippingAPI and procs
          this.theEnterpriseAutoComplete.loading = false;
          ep.sort((a, b) => a.fullName.localeCompare(b.fullName));
          this.enterprises = ep;
          this.enterpriseData = ep;
        });
    } else {
      this.enterprises = [];
      this.enterpriseData = [];
      this.theEnterpriseAutoComplete.loading = false;
    }
  }
  protected handleEnterpriseValueChange(value) {
    // find this enterprise based on the name
    this.selectedEnterprise = null;
    const selectedEnterprise = this.enterprises.filter((s) => s.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    if (selectedEnterprise.length === 1) {
      this.selectedEnterprise = selectedEnterprise[0];
    }
  }

  protected searchFilter(search: string = null): void {
    if (search) {
      this.recordBookGrid = this.recordBook.filter((obj) => this.filterDelegate(obj, search));
    } else {
      this.recordBookGrid = this.recordBook;
    }
    this.pageChange({ skip: 0, take: this.pageSize });
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
  }
  public ngOnDestroy(): void {
    if (this.docClickSubscription !== null) {
      this.docClickSubscription();
    }
  }
  protected statusChanged(newValue) {
    this.selectedStatus = newValue;
    this.loadRecords();
  }
  protected viewDetails(e, dataitem) {
    // this.router.navigate([`/bad-debt-details/${dataitem.id}`]);
    this.router.navigate([]).then((result) => {
      window.open(`/bad-debt-details/${dataitem.id}`, '_blank');
    });
  }
  protected onFindAccount() {
    this.isImporting = false;
    this.isLookup = true;
    this.lookupError = false;
    this.messageImport = '';
    this.messageLookup = 'Looking up enterprise. Please wait...';

    this.pcService.findCollections(this.selectedEnterprise.accountNumber).subscribe(
      (data) => {
        this.potentialCollection = data;
        this.isLookup = false;
        this.messageImport = 'Enterprise found. Please click Import Enterprise to begin the collection process.';
      },
      (error) => {
        this.isLookup = false;
        this.lookupError = true;
        this.messageLookup = '<i class="fas fa-exclamation-triangle pt-2 m-1"></i>' + error;
      }
    );
  }
  protected onEnterpriseImport() {
    this.messageImport = 'Importing enterprise. Please wait...';
    this.isImporting = true;

    // create the collection
    const createCollection: CreateCollection = new CreateCollection(
      this.potentialCollection.amountOwed,
      this.selectedEnterprise.name,
      this.selectedEnterprise.accountNumber
    );

    // call the service to start the import
    this.pcService.importEnterprise(createCollection).subscribe(
      (data) => {
        this.router.navigate([`/bad-debt-details/${this.pcService.collection.id}`], {
          queryParams: { n: 'true' },
        });
      },
      (error) => { }
    );
  }
  protected onCancelEnterpriseImport() {
    this.isImporting = false;
    this.messageImport = '';
    this.messageLookup = '';
  }
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    const record = this.recordBook.find(({ id }) => id === this.formGroup.value.id);

    if (record.remainingBalance > 0 && itemArgs.dataItem.status === 'Closed – Paid in Full') {
      return true;
    }
    return false;
  }

  // helpers
  public loadRecords() {
    this.isDataloading = true;
    this.pcService.getCollections(this.selectedStatus.id.toString()).subscribe(
      (data) => {
        this.recordBook = this.pcService.collectionBook;
        this.recordBookGrid = this.pcService.collectionBook;
        this.recordCount = this.pcService.collectionBook.length;
        this.setRecords();
        this.searchFilter(this.searchText);
        this.isDataloading = false;
      },
      (error) => {
        // this.handleError(error);
        this.alertMessage.showAlertMessage('Error Loading Records.', 'Error');
        this.isDataloading = false;
      }
    );
  }
  private setRecords(): void {
    const records = orderBy(this.recordBookGrid, this.sort);
    this.gridView = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }
  private filterDelegate(obj: CollectionItem, search: string): boolean {
    search = search.toLowerCase();
    return (
      (obj.accountNumber != null && obj.accountNumber.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.accountName != null && obj.accountName.toString().toLowerCase().indexOf(search) > -1)
    );
  }
  private updateRecord(): void {
    if (this.formGroup && !this.formGroup.invalid) {
      // update the datasource
      const record = this.recordBook.find(({ id }) => id === this.formGroup.value.id);
      Object.assign(record, this.formGroup.value);

      // save the new values from the selects
      // probably a better way to do this?
      const sc = new SaveCollection();
      const st = this.references.statuses.find((x) => x.status === record.status);
      sc.statusID = st === undefined ? 0 : st.id;

      const ca = this.references.agencies.find((x) => x.agency === record.agency);
      sc.agencyID = ca === undefined ? null : ca.id;

      const ea = this.references.escalatedAgencies.find((x) => x.agency === record.escalatedAgency);
      sc.escalatedAgencyID = ea === undefined ? null : ea.id;

      const co = this.references.collectors.find((x) => x.collector === record.collector);
      sc.collectorID = co === undefined ? null : co.id;
      sc.dateWrittenOff = record.dateWrittenOff;

      // update the DB
      this.pcService.update(record.id, sc).subscribe(
        (data) => {
          this.alertMessage.showAlertMessage('Saved Successfully.', 'Success');
          // this.handleSuccess();
        },
        (error) => {
          this.alertMessage.showAlertMessage('An Error occured on Save.', 'Error');
          // this.handleError(error);
        }
      );
    }
  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }

  public onRemoveBadDebt(id) {
    this.pcService.deleteBadDebt(id).subscribe(
      (data) => {
        this.alertMessage.showAlertMessage('Bad Debt Successfully Deleted.', 'Success');
        this.recordCount = this.recordCount - 1;
        this.gridView.data.splice(
          this.gridView.data.findIndex((i) => i.id === id),
          1
        );
        this.recordBookGrid.splice(
          this.recordBookGrid.findIndex((i) => i.id === id),
          1
        );
        this.appSpinner.loading = false;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Deleting Bad Debt', 'Error');
        window.location.reload();
        this.appSpinner.loading = false;
      }
    );
  }

  protected removeDetails(dataitem) {
    this.deleteTextConfirm = dataitem.accountNumber.toString();
    this.deleteDebtId = dataitem.id;
    this.opened = true;
  }

  protected closeConfirm() {
    this.opened = false;
    this.openedSave = false;
  }

  protected onDeleteBadDebt() {
    this.opened = false;
    this.appSpinner.loading = true;
    this.onRemoveBadDebt(this.deleteDebtId);
  }

  protected onSaveBadDebt() {
    this.updateRecord();
    this.openedSave = false;
    this.closeEditor(this.sender);
  }

  createFormGroup = (dataItem) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      status: new FormControl(dataItem.status),
      agency: new FormControl(dataItem.agency),
      escalatedAgency: new FormControl(dataItem.escalatedAgency),
      collector: new FormControl(dataItem.collector),
    });
  public onEditBadDebt({ sender, rowIndex, dataItem }) {
    this.saveTextConfirm = dataItem.accountNumber.toString();
    this.closeEditor(sender);
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.grid.editRow(rowIndex, this.formGroup);
  }

  public onRemoveBadDebtGrid(event) {
    this.deleteDebtId = event.dataItem.id;
    this.deleteTextConfirm = event.dataItem.accountNumber.toString();
    this.opened = true;
  }

  public cancelUpdateBadDebt({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }
  public saveBadDebtHandler({ event, sender, rowIndex, formGroup, isNew }) {
    this.sender = sender;
    this.openedSave = true;
  }
}
