import { Component, OnInit, Input } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EventHistory } from '@/services/EventHistory';
import { ShipmentHistoryService } from '@/services/shipment-history.service';

@Component({
    selector: 'event-history',
    templateUrl: './event-history.component.html',
    styleUrls: ['./event-history.component.scss']
})
export class EventHistoryComponent implements OnInit {
    @Input() shipmentID: number;
    public events: EventHistory[];
    public gridView: GridDataResult;
    public skip = 0;
    public pageSize = 30;
    public loading: boolean = true;

    constructor(
        private shipmentHistoryService: ShipmentHistoryService
    ) { }

    ngOnInit() {
        this.shipmentHistoryService
            .getEventHistory(this.shipmentID)
            .subscribe((events: EventHistory[]) => {
                this.events = events.sort(
                    (a, b) =>
                        this.eventSort(a, b, 'dateCreated') ||
                        this.eventSort(a, b, 'commentDateCreated')
                );
                this.loading = false;
                this.loadItems();
            });
    }

    private eventSort(a: EventHistory, b: EventHistory, prop: string): number {
        return a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0;
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.loadItems();
    }

    private loadItems(): void {
        if (this.events) {
            this.gridView = {
                data: this.events.slice(this.skip, this.skip + this.pageSize),
                total: this.events.length
            };
        }
    }
}
