import { ShipmentService } from '@/services/shipment.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-address-edit-dialog',
  templateUrl: './address-edit-dialog.component.html',
  styleUrls: ['./address-edit-dialog.component.scss']
})
export class AddressEditDialogComponent extends DialogContentBase implements OnInit {

  @Input()
  address: any;

  @ViewChild("autocomplete", { static: true })
  autocomplete: AutoCompleteComponent;

  formGroup: FormGroup;
  postalCode: string;
  data: any[];

  constructor(
    dialog: DialogRef,
    private shipmentService: ShipmentService) {
    super(dialog);
  }

  ngOnInit() {
    this.postalCode = this.address && this.address.postalCode
      ? this.address.postalCode.toString()
      : '';
    this.formGroup = new FormGroup({
      companyName: new FormControl(this.address.companyName, { validators: Validators.required }),
      addressLine1: new FormControl(this.address.addressLine1, { validators: Validators.required }),
      addressLine2: new FormControl(this.address.addressLine2),
      postalCode: new FormControl(this.postalCode),
      city: new FormControl(this.address.city),
      stateProvince: new FormControl(this.address.stateProvince),
      countryCode: new FormControl(this.address.countryCode)
    });
  }

  onSave() {
    let result = Object.assign(this.address, this.formGroup.value);
    this.dialog.close(result);
  }

  onCancel() {
    this.dialog.close();
  }

  onValueChange(event: any): void {
    let selection = this.data && this.data.find(x => x.postalCode === event);

    if (!!selection) {
      this.formGroup.get('city').setValue(selection.city);
      this.formGroup.get('stateProvince').setValue(selection.stateProvince);
      this.formGroup.get('countryCode').setValue(selection.countryCode);
    }
    else {
      this.formGroup.get('city').setValue('');
      this.formGroup.get('stateProvince').setValue('');
      this.formGroup.get('countryCode').setValue('');
    }
  }

  protected filterChange(filter: string): void {
    const code = (filter || '').trim();

    if (code.length < 3) {
      this.data = [];
      return;
    }

    this.autocomplete.loading = true;
    this.shipmentService
      .search(code)
      .subscribe((data) => {
        this.data = data || [];
        this.autocomplete.loading = false;
      }, err => {
        this.autocomplete.loading = false;
      });
  }
}
