import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Helpers, getSettings, setSettings } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { ManualProactiveFilter } from '../../_shared/FilterFactory';
import { ManualProactiveResponse } from '../../models/Proactive';

@Component({
  selector: 'app-manual-proactive-list',
  templateUrl: './manual-proactive-list.component.html',
  styleUrls: ['./manual-proactive-list.component.scss'],
})
export class ManualProactiveListComponent implements OnInit {
  get theRecords(): ManualProactiveResponse[] {
    return this.gridDataAll;
  }

  @Input('theRecords')
  set theRecords(value: ManualProactiveResponse[]) {
    this.gridDataAll = value;
    this.gridDataCurrent = value;
    this.setRecords();
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataloading = value;
  }

  @Input() set cacheKeyGrid(value) {
    this._cacheKeyGrid = value;
  }

  @Output()
  viewdetails = new EventEmitter<ManualProactiveResponse>();

  @Output()
  selectrate = new EventEmitter<ManualProactiveResponse>();

  gridView = null;
  gridDataCurrent: ManualProactiveResponse[];
  gridDataAll: ManualProactiveResponse[];
  gridData: GridDataResult;
  helpers: Helpers;
  pageSize: number;
  skip: number;
  range = { start: null, end: null };
  _cacheKeyGrid: string;
  pageableSettings: any;
  sortableSettings: any;
  manualFilter: ManualProactiveFilter;

  public isDataloading: boolean;
  public state: State = {};
  public sort: SortDescriptor[] = [{ field: 'invoiceDate', dir: 'asc' }];

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.sort = getSettings(this._cacheKeyGrid, 'accountNumber', 'asc');
    this.sortableSettings = { allowUnsort: false };
    this.pageSize = 25;
    this.skip = 0;
    this.gridDataCurrent = this.gridDataAll;
    this.manualFilter = new ManualProactiveFilter();
  }

  protected searchFilter(search: string = null): void {
    search
      ? (this.gridDataCurrent = this.gridDataAll.filter((obj) => this.manualFilter.Match(obj, search.toLowerCase())))
      : (this.gridDataCurrent = this.gridDataAll);
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  // events
  linkSearch(event) {
    this.searchFilter(event);
  }
  protected onSelectRate(dataitem) {
    this.selectrate.emit(dataitem);
  }
  protected onViewDetails(dataItem) {
    this.viewdetails.emit(dataItem);
  }
  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }
  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
    setSettings(this._cacheKeyGrid, this.sort);
  }
  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // helpers
  private setRecords(): void {
    if (this.gridDataCurrent == null) {
      return;
    }
    const records = orderBy(this.gridDataCurrent, this.sort);

    this.gridData = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }

  public removeRecord(id: number): void {
    this.gridDataAll.splice(
      this.gridDataAll.findIndex((i) => i.id === id),
      1
    );
    this.gridView = this.gridDataAll;
    this.setRecords();
  }
}
