export class Comment {
  commentId: number;
  noteUser: string;
  comment: string;
  status: string;
  dateCreated: Date;

  constructor(comment: CommentDTO) {
    this.commentId = comment.noteCommentID;
    this.noteUser = comment.createdBy;
    this.comment = comment.comment;
    this.dateCreated = new Date(comment.dateCreated.includes('Z') ? comment.dateCreated : comment.dateCreated + 'Z');
    this.status = comment.status;
  }
}

export class Note {
  noteId: number;
  type: string;
  priority: string;
  userName: string;
  lastUpdate: Date;
  subject: string;
  comments: Comment[];

  constructor(note: NoteDTO) {
    this.noteId = note.noteID;
    this.type = note.type;
    this.priority = note.priority;
    this.userName = note.createdBy;
    this.lastUpdate = new Date(note.lastUpdate.includes('Z') ? note.lastUpdate : note.lastUpdate + 'Z');
    this.subject = note.subject;
    this.comments = note.comments.map((x) => new Comment(x));
  }
}

export class Notes {
  notes: Note[];
  shipmentID: number;

  constructor(notes: NotesDTO) {
    this.notes = notes.notes
      .map((x) => new Note(x))
      .sort((a, b) => {
        return new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime();
      });
    this.shipmentID = notes.shipmentID;
  }
}

export interface NotesDTO {
  shipmentID: number;
  notes: NoteDTO[];
}

export interface NoteDTO {
  noteID: number;
  type: string;
  priority: string;
  createdBy: string;
  lastUpdate: string;
  subject: string;
  comments: CommentDTO[];
}

export interface CommentDTO {
  noteCommentID: number;
  createdBy: string;
  comment: string;
  status: string;
  dateCreated: string;
}

export interface INote {
  type: string;
  priority: string;
  subject: string;
  comment: string;
  commentStatus: string;
}

export interface IComment {
  comment: string;
  status: string;
}