import { Injectable } from '@angular/core';
import { AuthService } from '@/auth/auth.service';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FormGroup, FormControl } from '@angular/forms';
import { Globals } from '@/_shared/globals';
import { FilterService, RowClassArgs } from '@progress/kendo-angular-grid';
import { AuthOperation } from '@/auth/AuthOperation';

@Injectable()
export class Helpers {
  private static emtpyStringFiltersAudit: string = '(Empty)';
  constructor(private authService: AuthService) { }

  public setSelectedGridRecords(selectedRecords: string[], e: any, propertyName: string = 'id') {
    // if 1 or many new records where selected add only the ones we don't already have
    if (e.selectedRows !== undefined && e.selectedRows.length > 0) {
      if (selectedRecords !== undefined && selectedRecords.length > 0) {
        const newRows = e.selectedRows.filter((r) => selectedRecords.indexOf(r.dataItem[propertyName]) === -1);
        newRows.forEach((r) => {
          selectedRecords.push(r.dataItem[propertyName]);
        });
      } else {
        e.selectedRows.forEach((r) => {
          selectedRecords.push(r.dataItem[propertyName]);
        });
      }
    }

    // if 1 or many records where de selected add only the ones we  have
    if (e.deselectedRows !== undefined && e.deselectedRows.length > 0) {
      if (selectedRecords !== undefined && selectedRecords.length > 0) {
        const newDeselectedRows = e.deselectedRows.filter((r) => selectedRecords.indexOf(r.dataItem[propertyName]) !== -1);
        newDeselectedRows.forEach((r) => {
          const index = selectedRecords.indexOf(r.dataItem[propertyName]);
          selectedRecords.splice(index, 1);
        });
      }
    }
  }

  public hasOperation(operation: AuthOperation | string): boolean {
    return this.authService.BlueShipUser.operations.filter((op) => op.trim().toLowerCase() === operation.toLowerCase()).length > 0;
  }

  public upperCaseFirst(word: string): string {
    if (word == null || word.length === 0) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public dateCompareTodayInDays(actualDate: Date) {
    const today = new Date();
    // TODO actual might be empty then we want to use earliest, make global fn
    const diffMs = today.getTime() - actualDate.getTime(); // milliseconds
    return Math.ceil(diffMs / (1000 * 3600 * 24)); // days
  }

  public downloadFile(bytes: any, documentName: string, documentFormat: string, openWithOutDownload: boolean = false) {
    // create a hidden link
    const self = this;
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    // create the file type and file
    let format = 'application/pdf';
    if (!openWithOutDownload) {
      switch (documentFormat.toLowerCase()) {
        case 'pdf':
          format = 'application/pdf';
          break;
        default:
          format = `image/${documentFormat.toLowerCase()}`;
          break;
      }
    }

    const url = window.URL.createObjectURL(this.base64toBlob(bytes, format));

    if (!openWithOutDownload) {
      link.setAttribute('href', url);
      link.setAttribute('download', documentName + '.' + documentFormat);
      link.click();
    } else {
      window.open(url);
    }

    // clean up
    document.body.removeChild(link);
  }

  public hasAllowedExtensionsOnly(extensions: string[]): boolean {
    return extensions.every((extension) => this.isAllowedExtension(extension));
  }

  public isAllowedExtension(extension: string): boolean {
    const { allowedExtensions } = Globals.UploadFileRestrictions;
    return allowedExtensions.some((allowedExtension) => allowedExtension === extension.toLowerCase());
  }

  private base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public updateAllValidity(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity({ emitEvent: false });
      } else if (control instanceof FormGroup) {
        this.updateAllValidity(control);
      }
    });
  }
  public markAllAsTouchedDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAllAsTouchedDirty(control);
      }
    });
  }

  public isLTLOrInternational(mode: string): boolean {
    return mode.toUpperCase() === 'LTL' ||
      mode.toUpperCase() === 'LTL VOLUME' ||
      mode.toUpperCase() === 'VOLUME' ||
      mode.toUpperCase() === 'INTL GROUND' ||
      mode.toUpperCase() === 'AIR' ||
      mode.toUpperCase() === 'OCEAN' ||
      mode.toUpperCase() === 'FINAL MILE' ||
      mode.toUpperCase() === 'BILL PAY';
  }

  public isLTL(mode: string): boolean {
    return mode.toUpperCase() === 'LTL' ||
      mode.toUpperCase() === 'LTL VOLUME' ||
      mode.toUpperCase() === 'VOLUME';
  }
  public isTL(mode: string): boolean {
    return (
      mode.toUpperCase() === 'TL' ||
      mode.toUpperCase() === 'TL PARTIAL' ||
      mode.toUpperCase() === 'INTERMODAL' ||
      mode.toUpperCase() === 'DRAYAGE'
    );
  }

  public static isKeyIncluded(key: string, items: string[]): boolean {
    if (items.length === 0) {
      return true;
    }
    return key && items.includes(key.toLowerCase());
  }

  public static isStringEmpty(data: string): Boolean {
    return !data || 0 === data.length;
  }

  public static stringToDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    } else {
      if (typeof date === 'string') {
        let from = date.split('T')[0].split('-');
        return new Date(parseInt(from[0]), parseInt(from[1]) - 1, parseInt(from[2]));
      }
    }
  }

  public static filtersBySelectAudit(values: any[], filterService: FilterService, field: string): FilterService {
    filterService.filter({
      filters: values.map(function (value): FilterDescriptor {
        let filter;
        if (value == '(Empty)') {
          filter = {
            field,
            operator: 'isNull',
            value,
          };
        } else {
          filter = {
            field,
            operator: 'endswith',
            value,
          };
        }
        return filter;
      }),
      logic: 'or',
    });
    return filterService;
  }

  public static makeListFiltersAudit(records: any, listFilter: string[], key: string): string[] {
    listFilter = [];
    if (
      records.map((item) => item[key]).indexOf(null) != -1 ||
      records.map((item) => item[key]).indexOf('') != -1 ||
      records.map((item) => item[key]).indexOf(undefined) != -1
    ) {
      listFilter.push(this.emtpyStringFiltersAudit);
      listFilter = listFilter.concat(
        records.map((item) => item[key]).filter((value, index, self) => self.indexOf(value) === index && value != null)
      );
    } else {
      listFilter = records.map((item) => item[key]).filter((value, index, self) => self.indexOf(value) === index && value != null);
    }
    return listFilter;
  }

  public static sortOneColumnAtTime(new_sort: SortDescriptor[], current_sort: SortDescriptor[]): SortDescriptor[] {
    let sort_for = new_sort.filter((sort) => sort.dir != null && sort.dir != undefined);

    if (sort_for.length > 1) {
      let sort_done = current_sort.filter((sort) => sort.dir != null && sort.dir != undefined);

      sort_for.forEach((element) => {
        let new_filter = sort_done.filter((sort) => sort.dir != null && sort.dir != undefined && sort.field == element.field);

        if (new_filter.length == 0) {
          for (let index = 0; index < new_sort.length; index++) {
            new_sort[index].dir = new_sort[index].field == element.field ? element.dir : undefined;
          }
        }
      });
    }
    return new_sort;
  }

  public static helpersDownloadExportData(csvPreppedData: string, title: string): void {
    if (csvPreppedData && csvPreppedData.length > 0) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + escape(csvPreppedData));
      const fileName = title + new Date().toISOString().split('T')[0];
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
export const flatten = (filter) => {
  const filters = (filter || {}).filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

export function setSettings<T>(token: string, gridConfig: SortDescriptor[]): void {
  localStorage.setItem(token, JSON.stringify(gridConfig));
}

export function getSettings<T>(token: string, defaultColumn: string, defaultDir: string): SortDescriptor[] {
  let sortDefault: SortDescriptor;
  sortDefault = { field: defaultColumn, dir: defaultDir } as SortDescriptor;
  const settings = localStorage.getItem(token);
  if (defaultColumn === '') {
    return localStorage.getItem(token) === null ? [] : JSON.parse(settings);
  } else {
    return localStorage.getItem(token) === null ? [sortDefault] : JSON.parse(settings);
  }
}

export function setFilterSettings<T>(token: string, filterConfig: CompositeFilterDescriptor): void {
  localStorage.setItem(token, JSON.stringify(filterConfig));
}

export function getFilterSettings<T>(token: string): CompositeFilterDescriptor {
  const settings = localStorage.getItem(token);
  return settings === null ? null : JSON.parse(settings);
}

export function rowCallback(context: RowClassArgs) {
  const isEven = context.index % 2 === 0;
  return { even: isEven, odd: !isEven, };
}

export function convert12HourTo24Hour(hour: number, twelveHourClock: 'AM' | 'PM') {
  if (twelveHourClock === 'AM') {
    return hour === 12 ? 0 : hour;
  } else {
    return hour === 12 ? hour : hour + 12;
  }
}

export function conver24HourTo12Hour(hour: number) {
  return {
    hour: (hour + 11) % 12 + 1,
    twelveHourClock: hour < 12 ? 'AM' : 'PM',
  }
}