import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { StartupService } from './startup.service';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { CollectionService } from './services/collection.service';
import { CustomerService } from './services/customer.service';
import { ProactiveService } from './services/proactive.service';
import { InvoiceService } from './services/invoice.service';
import { ImageService } from './services/image.service';
import { AuditQueueService } from './services/queue.service';
import { CarrierService } from './services/carrier.service';
import { ShipmentService } from './services/shipment.service';
import { BgRatingService } from './services/bgRating.service';
import { HealthService } from './services/health.service';
import { LocalStorageService } from './services/localstorage.service';
import { EnterpriseService } from './services/enterprise.service';

import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { CollectionDetailsComponent } from './pages/collection/collection-details.component';
import { AuditQueueComponent } from './pages/audit/audit-queue.component';

import { InvoicesUnmatchedComponent } from './pages/invoices/invoices-unmatched.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { UploadModule } from '@progress/kendo-angular-upload';

import { CollectionNoteListComponent } from '../app/components/collection-note-list/collection-note-list.component';
import { CollectionPaymentListComponent } from '../app/components/collection-payment-list/collection-payment-list.component';
import { CollectionInvoiceListComponent } from '../app/components/collection-invoice-list/collection-invoice-list.component';
import { CollectionStatusesComponent } from '../app/components/collection-statuses/collection-statuses.component';
import { InvoicesUnmatchedListComponent } from '../app/components/invoices-unmatched-list/invoices-unmatched-list.component';
import { CarrierImageDetailsComponent } from '../app/components/invoices-unmatched-list/carrier-image-details/carrier-image-details.component';
import { AuditDocumentsListComponent } from '../app/components/audit-documents-list/audit-documents-list.component';
import { AuditListComponent } from './components/audit-list/audit-list.component';
import { AuditCarrierListComponent } from '../app/components/audit-carrier-list/audit-carrier-list.component';
import { AuditBgListComponent } from './components/audit-bg-list/audit-bg-list.component';
import { AuditReturnListComponent } from './components/audit-return-list/audit-return-list.component';
import { AuditSecondaryListComponent } from './components/audit-secondary-list/audit-secondary-list.component';
import { AuditEmailListComponent } from '../app/components/audit-email-list/audit-email-list.component';
import { CustomerProfileListComponent } from '../app/components/customer-profile-list/customer-profile-list.component';

import { ConfirmationDialogComponent } from './_shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './_shared/confirmation-dialog/confirmation-dialog.service';
import { MultiSelectFilterComponent } from './_shared/multiselect-filter/multiselect-filter.component';
import { ShipmentInvoiceAuditComponent } from './pages/shipment-invoice-audit/shipment-invoice-audit.component';
import { ProactiveHoldListComponent } from './components/proactive-hold-list/proactive-hold-list.component';
import { ManualProactiveListComponent } from './components/manual-proactive-list/manual-proactive-list.component';

import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { CustomerDetailsComponent } from './pages/customer-profile/customer-details.component';
import { ProactiveHoldComponent } from './pages/proactive/proactive-hold.component';
import { ManualProactiveComponent } from './pages/proactive/manual-proactive.component';
import { BooleanPipe } from './_shared/pipes/boolean.pipe';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AuditItemListComponent } from './components/shipping-edit/audit-item-list/audit-item-list.component';

import { ShipmentSearchComponent } from '@/pages/shipment-search/shipment-search.component';
import { TldocProactiveComponent } from './pages/proactive/tldoc-proactive.component';
import { TlDocProactiveListComponent } from './components/tl-doc-proactive-list/tl-doc-proactive-list.component';
import { ReferencesEditComponent } from './components/shipping-edit/references-edit/references-edit.component';
import { AddressEditComponent } from './components/address-edit/address-edit.component';
import { CurrencyService } from './services/currency.service';
import { DatePipe } from '@angular/common';
import { StoreModule, Store } from '@ngrx/store';
import { CarrierInvoiceAuditReducer } from './pages/carrier-invoice-audit/reducers/carrier-invoice-audit.reducer';
import { CarrierInvoiceAuditEffects } from './pages/carrier-invoice-audit/effects/carrier-invoice-audit.effects';
import { EffectsModule } from '@ngrx/effects';
import { CarrierInvoiceAuditComponent } from './pages/carrier-invoice-audit/carrier-invoice-audit.component';
import { CarrierInvoiceAuditStateService } from './pages/carrier-invoice-audit/services/carrier-invoice-audit-state.service';
import { CarrierFormComponent } from './pages/carrier-invoice-audit/component/carrier-form/carrier-form.component';
import { InvoiceChargesComponent } from './pages/carrier-invoice-audit/component/invoice-charges/invoice-charges.component';
import { InvoiceTotalsComponent } from './pages/carrier-invoice-audit/component/invoice-totals/invoice-totals.component';
import { AppState } from './appstate.model';
import * as AppActions from './app.actions';
import { appReducer } from './app.reducer';
import { TLAuditDetailReducer } from './pages/tl-audit/reducers/tl-audit-detail.reducer';
import { TLAuditDetailEffects } from './pages/tl-audit/effects/tl-audit-detail.effects';
import { TruckloadAuditComponent } from './pages/tl-audit/truckload-audit.component';
import { TlManageReasonComponent } from './pages/tl-audit/components/tl-manage-reason/tl-manage-reason.component';
import { TlReasonCommentComponent } from './pages/tl-audit/components/tl-reason-comment/tl-reason-comment.component';
import { TLAuditService } from './pages/tl-audit/services/tl-audit.service';
import { TlAssignAuditorComponent } from './pages/tl-audit/components/tl-assign-auditor/tl-assign-auditor.component';
import { RequiredValidator } from './_shared/dropDown-required-validator.directive';
import { TLAuditBBillReducer } from './pages/tl-audit/reducers/tl-audit-bbill.reducer';
import { TLAuditBBillEffects } from './pages/tl-audit/effects/tl-audit-bbill.effects';
import { TlBbillComponent } from './pages/tl-audit/components/tl-bbill/tl-bbill.component';
import { TruckloadEditComponent } from './pages/tl-audit-edit/truckload-edit.component';
import { TLAuditEditReducer } from './pages/tl-audit-edit/reducers/tl-audit-edit.reducer';
import { TLAuditEditHelperService } from './pages/tl-audit-edit/services/tl-audit-edit-helper.service';
import { TLAuditEditEffects } from './pages/tl-audit-edit/effects/tl-audit-edit.effects';
import { TlDocumentsComponent } from './pages/tl-audit-edit/components/tl-documents/tl-documents.component';
import { TlInvoiceListComponent } from './pages/tl-audit-edit/components/tl-invoice-list/tl-invoice-list.component';
import { TlBuySellRateComponent } from './pages/tl-audit-edit/components/tl-buy-sell-rate/tl-buy-sell-rate.component';
import { TlHeaderComponent } from './pages/tl-audit-edit/components/tl-header/tl-header.component';
import { TlAddressComponent } from './pages/tl-audit-edit/components/tl-address/tl-address.component';
import { TLAuditServiceOld } from './pages/tl-audit-old/services/tl-audit-old.service';
import { TlAssignAuditorComponentOld } from './pages/tl-audit-old/components/tl-assign-auditor/tl-assign-auditor-old.component';
import { TruckloadAuditComponentOld } from './pages/tl-audit-old/truckload-audit-old.component';
import { TlManageReasonComponentOld } from './pages/tl-audit-old/components/tl-manage-reason/tl-manage-reason-old.component';
import { TlReasonCommentComponentOld } from './pages/tl-audit-old/components/tl-reason-comment/tl-reason-comment-old.component';
import { TlBbillComponentOld } from './pages/tl-audit-old/components/tl-bbill/tl-bbill-old.component';
import { TLAuditBBillReducerOld } from './pages/tl-audit-old/reducers/tl-audit-bbill-old.reducer';
import { TLAuditDetailReducerOld } from './pages/tl-audit-old/reducers/tl-audit-detail-old.reducer';
import { TLAuditDetailEffectsOld } from './pages/tl-audit-old/effects/tl-audit-detail-old.effects';
import { TLAuditBBillEffectsOld } from './pages/tl-audit-old/effects/tl-audit-bbill-old.effects';
import { TLAuditIncidentAssignmentEffects } from './pages/tl-audit/effects/tl-audit-incident-assignment.effects';
import { TLAuditIncidentAssignmentReducer } from './pages/tl-audit/reducers/tl-audit-incident-assignment.reducer';
import { ShipmentEditComponent } from './components/shipment-edit/shipment-edit.component';
import { ShipmentDataHeaderComponent } from './components/shipment-data-header/shipment-data-header.component';
import { AddressEditModalComponent } from './components/address-edit-modal/address-edit-modal.component';
import { TlAuditFilterComponent } from './pages/tl-audit/filter/filter.component';
import { TlReferencesComponent } from './pages/tl-audit-edit/components/tl-references/tl-references.component';
import { TlBuySellRateEditComponent } from './pages/tl-audit-edit/components/tl-buy-sell-rate-edit/tl-buy-sell-rate-edit.component';
import { LtlBuySellRateEditComponent } from './components/ltl-buy-sell-rate-edit/ltl-buy-sell-rate-edit.component';
import { DuplicateCarrierComponent } from './pages/duplicate-carrier/duplicate-carrier.component';
import { InvoicesSummaryComponent } from './pages/invoices-summary/invoices-summary.component';
import { StatePersistingService } from './services/state-persisting-service.service';

import { InvoiceReportsComponent } from './pages/invoice-reports/invoice-reports.component';
import { DateFormatPipe } from './pages/invoices-summary/date-format.pipe';
import { SwitchComponent } from './_shared/switch/switch.component';
import { UnmatchedDetailsComponent } from './components/unmatched-details/unmatched-details.component';
import { ModalComponent } from './_shared/modal/modal.component';
import { UnmatchedEditComponent } from './components/unmatched-edit/unmatched-edit.component';
import { InvoiceManagementComponent } from './components/invoice-management/invoice-management.component';
import { UntrackedInvoicesComponent } from './components/untracked-invoices/untracked-invoices.component';
import { ConvertToShipmentDialogComponent } from './components/convert-to-shipment-dialog/convert-to-shipment-dialog.component';
import { SuggestedShipmentMatchesComponent } from './components/suggested-shipment-matches/suggested-shipment-matches.component';
import { TrackingService } from './services/tracking.service';
import { AddNoteDialogComponent } from './components/add-note-dialog/add-note-dialog.component';
import { NoteListComponent } from './components/note-list/note-list.component';
import { NotesInvoiceService } from './services/notes.service';
import { RatesModalComponent } from './components/rates-modal/rates-modal.component';
import { MixAndMatchComponent } from './components/mix-and-match/mix-and-match.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ApproveToPayComponent } from './pages/approve-to-pay/approve-to-pay.component';
import { ApproveToPayDetailComponent } from './pages/approve-to-pay-detail/approve-to-pay-detail.component';
import { ApproveToPayService } from './services/approve-to-pay-service';
import { ApproveToPayProfileListComponent } from './pages/approve-to-pay-detail/approve-to-pay-profile-list/approve-to-pay-profile-list.component';
import { ApproveToPayScheduleListComponent } from './pages/approve-to-pay-detail/approve-to-pay-schedule-list/approve-to-pay-schedule-list.component';

import { AuditInvoiceComponent } from './components/audit-invoice/audit-invoice.component';
import { BgCommonModule } from './bg-common/bg-common.module';
import { Helpers } from './_shared/helpers';
import { Router } from '@angular/router';
import * as RoutesModule from '@/app-routing.module';
import { InvoiceImportComponent } from './pages/invoice-import/invoice-import.component';
import { FileUploadModule } from "ng2-file-upload";
import { NotificationsComponent } from './audit/notifications/notifications.component';
import { ImportService } from './services/import.service';
import { featureFlagReducer } from './services/launch-darkly/launch-darkly.reducer';
import { FeatureFlagEffects } from './services/launch-darkly/launch-darkly.effects';

export function startupServiceFactory(
  startupService: StartupService,
  store: Store<AppState>,
  injector: Injector
): () => Promise<void> {
  return () => {
    return new Promise((resolve, reject) => {
      let router = injector.get(Router);

      return startupService.loadConfigurationData().toPromise()
        .then(x => {
          let invoiceAuditRoute: any = {
            path: 'shipment-invoices/:id/:shipmentid/:invoiceid',
            component: ShipmentInvoiceAuditComponent,
            canActivate: [AuthGuardService],
          };

          if (x.enableNewShipmentAudit) {
            invoiceAuditRoute = {
              path: 'shipment-invoices',
              loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule)
            }
          }

          router.resetConfig([
            ...RoutesModule.routes,
            invoiceAuditRoute
          ]);

          store.dispatch(new AppActions.LoadAppConfigSuccess(x));
          resolve();
        }, () => {
          resolve();
        });
      resolve();
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    HeaderMenuComponent,
    DashboardComponent,
    CollectionComponent,
    CollectionDetailsComponent,
    InvoicesUnmatchedComponent,
    AuditQueueComponent,
    NotAuthorizedComponent,
    ConfirmationDialogComponent,
    AuditItemListComponent,
    CustomerProfileComponent,
    ApproveToPayComponent,
    ApproveToPayDetailComponent,
    ApproveToPayScheduleListComponent,
    ApproveToPayProfileListComponent,
    CollectionNoteListComponent,
    CollectionPaymentListComponent,
    CollectionInvoiceListComponent,
    CollectionStatusesComponent,
    InvoicesUnmatchedListComponent,
    CarrierImageDetailsComponent,
    MultiSelectFilterComponent,
    AuditDocumentsListComponent,
    AuditListComponent,
    AuditCarrierListComponent,
    AuditBgListComponent,
    AuditReturnListComponent,
    AuditSecondaryListComponent,
    AuditEmailListComponent,
    AuditItemListComponent,
    CustomerProfileListComponent,
    ShipmentInvoiceAuditComponent,
    ManualProactiveListComponent,
    ProactiveHoldListComponent,
    CustomerDetailsComponent,
    ManualProactiveComponent,
    ProactiveHoldComponent,
    BooleanPipe,
    ShipmentSearchComponent,
    TldocProactiveComponent,
    TlDocProactiveListComponent,
    ReferencesEditComponent,
    AddressEditComponent,
    AddressEditModalComponent,
    CarrierInvoiceAuditComponent,
    CarrierFormComponent,
    InvoiceChargesComponent,
    InvoiceTotalsComponent,
    TruckloadAuditComponent,
    TruckloadAuditComponentOld,
    TlManageReasonComponent,
    TlManageReasonComponentOld,
    TlReasonCommentComponent,
    TlReasonCommentComponentOld,
    TlAssignAuditorComponent,
    TlAssignAuditorComponentOld,
    RequiredValidator,
    TlBbillComponent,
    TlBbillComponentOld,
    TruckloadEditComponent,
    TlDocumentsComponent,
    TlInvoiceListComponent,
    TlBuySellRateComponent,
    TlHeaderComponent,
    TlAddressComponent,
    ShipmentEditComponent,
    ShipmentDataHeaderComponent,
    TlAuditFilterComponent,
    TlReferencesComponent,
    TlBuySellRateEditComponent,
    LtlBuySellRateEditComponent,
    DuplicateCarrierComponent,
    InvoicesSummaryComponent,
    InvoiceReportsComponent,
    DateFormatPipe,
    SwitchComponent,
    UnmatchedDetailsComponent,
    ModalComponent,
    UnmatchedEditComponent,
    InvoiceManagementComponent,
    UntrackedInvoicesComponent,
    ConvertToShipmentDialogComponent,
    SuggestedShipmentMatchesComponent,
    AddNoteDialogComponent,
    NoteListComponent,
    RatesModalComponent,
    LoadingSpinnerComponent,
    MixAndMatchComponent,
    AuditInvoiceComponent,
    InvoiceImportComponent,
    NotificationsComponent
  ],
  imports: [
    BgCommonModule,
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DropDownsModule,
    GridModule,
    DialogModule,
    WindowModule,
    DateInputsModule,
    InputsModule,
    ReactiveFormsModule,
    UploadModule,
    StoreModule.forRoot({
      CarrierInvoiceAuditState: CarrierInvoiceAuditReducer,
      FeatureFlags: featureFlagReducer,
      StartupData: appReducer,
      TLAuditDetailState: TLAuditDetailReducer,
      TLAuditIncidentAssignmentState: TLAuditIncidentAssignmentReducer,
      TLAuditBBillState: TLAuditBBillReducer,
      TLAuditEditState: TLAuditEditReducer,
      TLAuditBBillOldState: TLAuditBBillReducerOld,
      TLAuditDetailOldState: TLAuditDetailReducerOld,
    }),
    EffectsModule.forRoot([
      CarrierInvoiceAuditEffects,
      FeatureFlagEffects,
      TLAuditDetailEffects,
      TLAuditIncidentAssignmentEffects,
      TLAuditBBillEffects,
      TLAuditEditEffects,
      TLAuditDetailEffectsOld,
      TLAuditBBillEffectsOld,
    ]),
    FileUploadModule
  ],
  providers: [
    StartupService,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService, Store, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthGuardService,
    AuthService,
    CollectionService,
    CustomerService,
    ApproveToPayService,
    ProactiveService,
    InvoiceService,
    ImportService,
    ImageService,
    CarrierService,
    AuditQueueService,
    ShipmentService,
    BgRatingService,
    ConfirmationDialogService,
    HealthService,
    LocalStorageService,
    EnterpriseService,
    CurrencyService,
    DatePipe,
    CarrierInvoiceAuditStateService,
    TLAuditService,
    TLAuditServiceOld,
    TLAuditEditHelperService,
    TrackingService,
    StatePersistingService,
    NotesInvoiceService,
    Helpers
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    TlBuySellRateEditComponent,
    LtlBuySellRateEditComponent,
    ConvertToShipmentDialogComponent,
    AddNoteDialogComponent,
    RatesModalComponent,
    MixAndMatchComponent,
    ShipmentInvoiceAuditComponent
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
