import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PageChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { rowCallback } from '@/_shared/helpers';
import { ApproveToPay } from '@/models/ApproveToPay';
import { ApproveToPayService } from '@/services/approve-to-pay-service';
import { takeUntil } from 'rxjs/operators';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { ConfirmDialogComponent } from '@/bg-common/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuthOperation } from '@/auth/AuthOperation';
import { AuthService } from '@/auth/auth.service';

@Component({
  selector: 'app-approve-to-pay',
  templateUrl: './approve-to-pay.component.html',
})
export class ApproveToPayComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  destroy$ = new Subject();
  records: ApproveToPay[] = [];
  filteredRecords: ApproveToPay[] = [];
  paginatedRecords: GridDataResult;
  pageSize: number = 25;
  skip: number = 0;
  range = { start: null, end: null };
  isDataLoading = false;
  sort: SortDescriptor[] = [{ field: 'GroupCode', dir: 'asc' }];
  rowCallback = rowCallback;

  constructor(
    private atpService: ApproveToPayService,
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (!this.authService.hasOperation(AuthOperation.CanViewCustomerProfile)) {
      this.router.navigate([`not-authorized`]);
    }

    this.isDataLoading = true;

    this.atpService
      .getApproveToPayAll()
      .subscribe(
        (res) => {
          this.filteredRecords = this.records = res;
          this.reorderData();
          this.isDataLoading = false;
        },
        () => {
          this.isDataLoading = false;
          this.alertMessage.showAlertMessage('Error loading Approve To Pay', 'Error');
        }
      );
  }

  searchFilter(search: string) {
    if (search) {
      search = search.toLowerCase();
      this.filteredRecords = this.records
        .filter((x) => `${x.groupCode || ''} ${x.invoicePrefix || ''}`.toLowerCase().indexOf(search) > -1);
    } else {
      this.filteredRecords = this.records
    }

    this.pageChange({ skip: 0, take: this.pageSize });
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.reorderData();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.reorderData();
  }

  reorderData(): void {
    const records = orderBy(this.filteredRecords, this.sort);
    this.paginatedRecords = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }

  canEdit() {
    return this.authService.hasOperation(AuthOperation.CanEditCustomerProfile);
  }

  editApproveToPay(approveToPay: ApproveToPay) {
    this.router.navigate([`/approve-to-pay/${approveToPay.id}`]);
  }

  onCreateApproveToPay() {
    this.router.navigate([`/approve-to-pay/new`]);
  }

  removeApproveToPay(approveToPay: ApproveToPay) {
    const dialog = this.dialogService.open({
      title: 'Confirm',
      content: ConfirmDialogComponent
    });

    const instance = dialog.content.instance as ConfirmDialogComponent;
    instance.content = `Are you sure you want to delete '${approveToPay.groupCode}'?`;
    instance.confirmText = 'Delete';

    dialog.result
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (!(result instanceof DialogCloseResult)) {
          this.deleteApproveToPay(approveToPay);
        }
      });
  }

  deleteApproveToPay(approveToPay: ApproveToPay) {
    this.isDataLoading = true;
    this.atpService.deleteApproveToPay(approveToPay.id).subscribe(
      () => {
        this.alertMessage.showAlertMessage(`Successfully deleted '${approveToPay.groupCode}'.`, 'Success');
        this.records.splice(this.records.findIndex((i) => i.id === approveToPay.id), 1);
        this.filteredRecords = this.records;
        this.reorderData();
        this.isDataLoading = false;
      },
      () => {
        this.alertMessage.showAlertMessage(`Failed to delete '${approveToPay.groupCode}'.`, 'Error');
        this.isDataLoading = false;
      }
    );
  }
}
