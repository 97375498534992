import { Enterprise } from "@/services/Enterprise";

export class EditInvoiceParameters {
  shipmentID: number;
  invoiceID: number;
  isEditMode: boolean;
  enterprise?: Enterprise;
  selectedRateCurrencyCode?: string;
  existingInvoiceCountOnShipment?: number;
};
