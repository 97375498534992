import { Injectable } from '@angular/core';
import { StartupData } from './models/StartupData';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from './appstate.model';
import { map } from 'rxjs/operators';
import { overrideConfigFromLocalStorage } from '@bluegrace/bs.npmcommon';

@Injectable()
export class StartupService {
  // To override the configs below, go to the browser console and run "bgEnvConfigOverrides.enable()"
  // This will enable the EnvConfigOverride UI at the bottom right corner of the screen
  private _devConfig = {
    environment: 'DEV',
    financeAPIUrl: 'https://app-finance-api-qa-eastus.azurewebsites.net/api/',
    // financeAPIUrl: 'https://localhost:58302/api/',
    shipmentApi: 'https://shipmentapi-qa.myblueship.com/api/',
    authAPIUrl: 'https://identity-qa.myblueship.com/',
    documentApi: 'https://app-document-api-qa-eastus.azurewebsites.net/api/', // 'http://localhost:56269/api/',
    imageApi: 'https://bs-imageservicesfn-qa.azurewebsites.net/api/', // 'http://localhost:7218/api/',
    trackingApi: 'https://app-tracking-api-qa-eastus.azurewebsites.net/', // http://localhost:4500/
    hideActions: 'false',
    showDebug: 'true',
    daysBack: 120,
    carrierApi: 'https://app-carrier-api-qa-eastus.azurewebsites.net/api/',
    legacyBlueShip: 'https://blueshipqa.myblueship.com/',
    backOffice: 'https://backoffice-staging.myblueship.com',
    showCanada: 'false',
    launchDarklyKey: '5f74ba16cb0a9a0b66152038',
    imageServiceFunctionKey: 'LsxGrftmQ2KlY5fuTuDfv6C9oQ3NWiRxtsQX8b2uxyGeAzFuR_nyBQ==',
    bgRatingApiUrl: 'https://rating-qa.myblueship.com/api/',
    enableConvert: 'true',
    backofficeEventManagement: 'true',
    invoiceVisibility: 'true',
    enableSecondaryToUnmatched: 'true',
    enableNewShipmentAudit: 'true',
    enableStandardizedCharges: 'true',
  };
  _startupData: StartupData;
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select((x) => x.StartupData)
      .subscribe((x) => {
        this._startupData = x;
      });
  }

  loadConfigurationData(): Observable<StartupData> {
    return new Observable<any>((observer) => {
      return this.http
        .get<any>('/config')
        .pipe(
          map((data) => {
            // console.log('loading config values');
            data.hideActions = JSON.parse(data.hideActions);
            data.showDebug = JSON.parse(data.showDebug);
            data.showCanada = JSON.parse(data.showCanada);
            data.enableConvert = JSON.parse(data.enableConvert);
            data.backofficeEventManagement = JSON.parse(data.backofficeEventManagement);
            data.invoiceVisibility = JSON.parse(data.invoiceVisibility);
            data.enableSecondaryToUnmatched = JSON.parse(data.enableSecondaryToUnmatched);
            data.enableNewShipmentAudit = JSON.parse(data.enableNewShipmentAudit);
            data.enableStandardizedCharges = JSON.parse(data.enableStandardizedCharges);
            return data;
          })
        )
        .subscribe(
          (x) => {
            const config = overrideConfigFromLocalStorage(x, ['environment'], 'Gryphon');
            observer.next(config);
            console.log(config);
            observer.complete();
          },
          (err) => {
            this._devConfig.hideActions = JSON.parse(this._devConfig.hideActions);
            this._devConfig.showDebug = JSON.parse(this._devConfig.showDebug);
            this._devConfig.showCanada = JSON.parse(this._devConfig.showCanada);
            this._devConfig.enableConvert = JSON.parse(this._devConfig.enableConvert);
            this._devConfig.backofficeEventManagement = JSON.parse(this._devConfig.backofficeEventManagement);
            this._devConfig.invoiceVisibility = JSON.parse(this._devConfig.invoiceVisibility);
            this._devConfig.enableSecondaryToUnmatched = JSON.parse(this._devConfig.enableSecondaryToUnmatched);
            this._devConfig.enableNewShipmentAudit = JSON.parse(this._devConfig.enableNewShipmentAudit);
            this._devConfig.enableStandardizedCharges = JSON.parse(this._devConfig.enableStandardizedCharges);
            this._devConfig = overrideConfigFromLocalStorage(this._devConfig, ['environment'], 'Gryphon');
            console.log(this._devConfig);
            observer.next(this._devConfig);
            observer.complete();
          }
        );
    });
  }

  get startupData(): any {
    return this._startupData;
  }

  get hideActions(): boolean {
    return this._startupData.hideActions;
  }
  get daysBack(): number {
    return this._startupData.daysBack;
  }
  get showDebug(): boolean {
    return this._startupData.showDebug;
  }
  get showCanada(): boolean {
    return this._startupData.showCanada;
  }
  get financeAPIUrl(): string {
    return this._startupData.financeAPIUrl;
  }
  get documentAPIUrl(): string {
    return this._startupData.documentApi;
  }
  get imageAPIUrl(): string {
    return this._startupData.imageApi;
  }
  get imageServiceFunctionKey(): string {
    return this._startupData.imageServiceFunctionKey;
  }
  get shipmentApiUrl(): string {
    return this._startupData.shipmentApi;
  }
  get trackingApiUrl() {
    return this._startupData.trackingApi;
  }
  get carrierApiUrl(): string {
    return this._startupData.carrierApi;
  }
  get authAPIUrl(): string {
    return this._startupData.authAPIUrl;
  }
  get legacyBlueShipUrl(): string {
    return this._startupData.legacyBlueShip;
  }
  get backOfficeUrl(): string {
    return this._startupData.backOffice;
  }
  get bgRatingApiUrl(): string {
    return this._startupData.bgRatingApiUrl;
  }
  get enableConvert(): boolean {
    return this._startupData.enableConvert;
  }
  get backofficeEventManagement(): boolean {
    return this._startupData.backofficeEventManagement;
  }
  get invoiceVisibility(): boolean {
    return this._startupData.invoiceVisibility;
  }
  get enableSecondaryToUnmatched(): boolean {
    return this._startupData.enableSecondaryToUnmatched;
  }
  get enableNewShipmentAudit(): boolean {
    return this._startupData.enableNewShipmentAudit;
  }
  get enableStandardizeCharges(): boolean {
    return this._startupData.enableStandardizedCharges;
  }
}
