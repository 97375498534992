import * as AppActions from './app.actions';
import { StartupData } from './models/StartupData';
const initialState: StartupData = {
  environment: 'DEV',
  financeAPIUrl: 'http://localhost:62635/api/',
  shipmentApi: 'http://localhost:5000/api/',
  trackingApi: 'http://localhost:4500/',
  authAPIUrl: 'http://localhost:7000/',
  documentApi: 'http://localhost:56269/api/',
  imageApi: 'http://localhost:7218/api/',
  hideActions: false,
  showDebug: true,
  daysBack: 120,
  carrierApi: 'https://bs-carrier-api-qa.azurewebsites.net/api/',
  legacyBlueShip: 'https://blueshipqa.myblueship.com/',
  backOffice: 'https://backoffice-staging.myblueship.com',
  showCanada: false,
  launchDarklyKey: '5f74ba16cb0a9a0b66152038',
  imageServiceFunctionKey: 'LsxGrftmQ2KlY5fuTuDfv6C9oQ3NWiRxtsQX8b2uxyGeAzFuR_nyBQ==',
  bgRatingApiUrl: 'https://bs-rating-api-qa.azurewebsites.net/api/',
  enableConvert: false,
  backofficeEventManagement: false,
  invoiceVisibility: false,
  enableSecondaryToUnmatched: false,
  enableNewShipmentAudit: false,
  enableStandardizedCharges: false
};

export function appReducer(state: StartupData = initialState, action: AppActions.Actions) {
  switch (action.type) {
    case AppActions.LOAD_APP_CONFIG_SUCCESS: {
      return {
        ...state,
        environment: action.startupData.environment,
        financeAPIUrl: action.startupData.financeAPIUrl,
        shipmentApi: action.startupData.shipmentApi,
        trackingApi: action.startupData.trackingApi,
        authAPIUrl: action.startupData.authAPIUrl,
        documentApi: action.startupData.documentApi,
        hideActions: action.startupData.hideActions,
        showDebug: action.startupData.showDebug,
        daysBack: action.startupData.daysBack,
        carrierApi: action.startupData.carrierApi,
        legacyBlueShip: action.startupData.legacyBlueShip,
        backOffice: action.startupData.backOffice,
        imageApi: action.startupData.imageApi,
        showCanada: action.startupData.showCanada,
        launchDarklyKey: action.startupData.launchDarklyKey,
        imageServiceFunctionKey: action.startupData.imageServiceFunctionKey,
        bgRatingApiUrl: action.startupData.bgRatingApiUrl,
        enableConvert: action.startupData.enableConvert,
        backofficeEventManagement: action.startupData.backofficeEventManagement,
        invoiceVisibility: action.startupData.invoiceVisibility,
        enableSecondaryToUnmatched: action.startupData.enableSecondaryToUnmatched,
        enableNewShipmentAudit: action.startupData.enableNewShipmentAudit,
        enableStandardizedCharges: action.startupData.enableStandardizedCharges,
      };
    }
    default:
      return state;
  }
}
