import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StartupService } from '../startup.service';
import { AuthService } from '@/auth/auth.service';
import { ApproveToPay, UpdateApproveToPay } from '@/models/ApproveToPay';
import { ApproveToPaySchedule, UpdateApproveToPaySchedule } from '@/models/ApproveToPaySchedule';
import { CustomerProfile } from '@/models/CustomerProfile';

@Injectable()
export class ApproveToPayService {
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private startupService: StartupService,
    private auth: AuthService
  ) {
    this.headers = this.auth.setHttpHeaders(this.headers);
  }

  public getApproveToPayAll() {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/`;
    return this.http.get<ApproveToPay[]>(url);
  }

  public getApproveToPay(id: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${id}`;
    return this.http.get<ApproveToPay>(url);
  }

  public updateApproveToPay(id: number, profile: UpdateApproveToPay) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${id}`;
    return this.http.put<ApproveToPay>(url, profile);
  }

  public createApproveToPay(profile: UpdateApproveToPay) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/`;
    return this.http.post<ApproveToPay>(url, profile);
  }

  public deleteApproveToPay(id: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${id}`;
    return this.http.delete(url);
  }

  public getApproveToPaySchedules(approveToPayID: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${approveToPayID}/schedule`;
    return this.http.get<ApproveToPaySchedule[]>(url);
  }

  public updateApproveToPaySchedule(id: number, profile: UpdateApproveToPaySchedule) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/schedule/${id}`;
    return this.http.put<ApproveToPaySchedule>(url, profile);
  }

  public createApproveToPaySchedule(approveToPayID: number, profile: UpdateApproveToPaySchedule) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${approveToPayID}/schedule`;
    return this.http.post<ApproveToPaySchedule>(url, profile);
  }

  public deleteApproveToPaySchedule(id: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/schedule/${id}`;
    return this.http.delete(url);
  }

  public getCustomerProfileLinks(approveToPayID: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${approveToPayID}/customer-profile`;
    return this.http.get<CustomerProfile[]>(url);
  }

  public createCustomeProfileLink(approveToPayID: number, customerProfileId: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${approveToPayID}/customer-profile/${customerProfileId}`;
    return this.http.post(url, null);
  }

  public deleteCustomeProfileLink(approveToPayID: number, customerProfileId: number) {
    const url = `${this.startupService.financeAPIUrl}v2/approve-to-pay/${approveToPayID}/customer-profile/${customerProfileId}`;
    return this.http.delete(url);
  }
}
