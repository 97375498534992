import { ShipmentInvoiceAuditDTO, Address, Item, Reference } from '@/models/ShipmentInvoiceAudit';
import { Globals } from '@/_shared/globals';
import { Invoice, InvoiceCharge } from '@/services/InvoiceAudit';

export namespace ShipmentUI {
  export class Shipment implements Invoice {
    id: number;
    bol: string;
    enterpriseName: string;
    enterpriseID: number;
    accountName: string;
    accountNumber: string;
    proActive: boolean;
    status: string;
    mode: string;
    specialInstructions: string;
    carrierTotal = 0;
    customerTotal = 0;
    currencyCode: string;
    qualifiers = Globals.Qualifiers;

    scac = 'n/a';
    carrierName = '';
    serviceName = '';
    selectedRateId: string;
    requestGroupId: string;

    dateCreated: Date = null;
    origin: AddressUI;
    destination: AddressUI;

    references: ReferenceUI[] = [];
    items: ItemUI[] = [];
    accessorialsShipment: AccessorialUI[] = [];
    accessorialsDelivery: AccessorialUI[] = [];
    accessorialsPickup: AccessorialUI[] = [];
    lineHaulCharges: InvoiceCharge[] = null;
    accessorialCharges: InvoiceCharge[] = null;

    constructor(shipment: ShipmentInvoiceAuditDTO, loadReferences: boolean) {
      this.id = shipment.shipment.shipmentID;
      this.enterpriseID = shipment.enterprise.id;
      this.accountName = shipment.enterprise.name;
      this.accountNumber = shipment.enterprise.accountNumber;
      this.status = shipment.shipment.status;
      this.mode = shipment.shipment.modeType;
      this.specialInstructions = shipment.specialInstructions;
      this.setBolNumber(shipment.references);

      // get the selected rate
      const selectedRate = shipment.rates.find((obj) => obj.isSelected === true);

      // carrier / customer charge information
      if (selectedRate != null) {
        this.selectedRateId = selectedRate.id;
        this.requestGroupId = selectedRate.requestGroupID;
        this.scac = selectedRate.scac;
        this.carrierName = selectedRate.carrierName;
        this.serviceName = selectedRate.service;
        this.carrierTotal = selectedRate.carrierTotal;
        this.customerTotal = selectedRate.customerTotal;
        this.currencyCode = selectedRate.currencyCode;
        // Map to customer charge
        this.setCustomerCharges(selectedRate.customerCharges);
      }
      if (shipment.shipment.dateBooked != null && !isNaN(Date.parse(shipment.shipment.dateBooked.toString()))) {
        this.dateCreated = new Date(shipment.shipment.dateBooked);
      }

      // addresses
      if (shipment.addresses != null && shipment.addresses.length > 1) {
        this.origin = new AddressUI(shipment.addresses[0]);
        this.destination = new AddressUI(shipment.addresses[1]);
      }

      // items
      this.setItems(shipment.items);

      // references
      if (loadReferences) {
        this.references = shipment.references.map(
          ({ referenceID: id, type, value, isPrimary, isRequired, isEditable, allowedValues, defaultValue }) =>
            new ReferenceUI(id, type, value, isPrimary, isRequired, isEditable, false, allowedValues, defaultValue)
        );
        const proReferences = shipment.proNumbers.map(
          ({ proNumberID: id, proNumber }) => new ReferenceUI(id, 'PRO', proNumber, false, false, true, false, [], '')
        );
        this.references = this.references.concat(proReferences);
      }

      // accessorials
      this.setAccessorials(shipment);
    }
    private addAccessorials(shipment: ShipmentInvoiceAuditDTO, accessorials: Array<{ name: String; code: String }>, type: number) {
      const foundAccessorials = shipment.accessorials.filter((elem) => accessorials.find(({ code }) => elem.code === code));
      if (foundAccessorials.length === 0) {
        return [];
      }
      return foundAccessorials.map(({ accessorialID: id, code, name }) => ({
        id,
        code,
        name,
      }));
    }
    private setCharges(theCharges: any[]) {
      const charges = theCharges.map(
        ({
          chargeID: invoiceChargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateQualifier: rateCode,
          rateQualifier = '',
          quantity,
          weight,
          isMin,
          isMax,
          dimWeight,
          amount,
          type,
          ediCode,
          canEdit,
          group = '',
          amountInUSD,
          amountInCAD,
        }) => ({
          invoiceChargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateCode,
          rateQualifier,
          quantity,
          weight,
          isMin,
          isMax,
          dimWeight,
          amount,
          type,
          ediCode,
          canEdit,
          group,
          amountInUSD,
          amountInCAD,
        })
      );

      // not ideal, but map would not work with the filter inline
      for (const charge of charges) {
        charge.group = Globals.ChargeTypes.indexOf(charge.type.toUpperCase()) === -1 ? 'Accessorial' : ' Linehaul';
        charge.type = Globals.FinanceChargeTypeMapping[charge.type];
      }

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === ' Linehaul';
        });
        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });

        // sort them
        if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
          this.lineHaulCharges.sort((a, b) => (a.amount < b.amount ? 1 : -1));
        }
        if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
          this.accessorialCharges.sort((a, b) => (a.amount < b.amount ? 1 : -1));
        }
      }
    }
    private getQualifier(invoiceCharge: InvoiceCharge) {
      const record = this.qualifiers.find((obj) => {
        return obj.rateCode === invoiceCharge.rateQualifier;
      });

      // If we dont find it keep it as it is
      if (record == null) {
        invoiceCharge.rateCode = '-1';
      } else {
        invoiceCharge.rateQualifier = record.rateQualifier;
      }
    }

    private setBolNumber(references: Reference[]) {
      const item = references.filter(function (el) {
        return el.type === 'BOL' && el.isPrimary === true;
      });
      this.bol = item.length === 0 ? '' : item[0].value;
    }
    private getFullAccessorials(): AccessorialUI[] {
      const fullList = this.accessorialsShipment.slice(0);
      if (this.accessorialsPickup.length > 0) {
        fullList.push(...this.accessorialsPickup);
      }
      if (this.accessorialsDelivery.length > 0) {
        fullList.push(...this.accessorialsDelivery);
      }
      return fullList;
    }

    // public functions
    public setItems(items: Item[]) {
      this.items = [];
      for (const item of items) {
        this.items.push(new ItemUI(item));
      }
    }
    public setAccessorials(shipment: ShipmentInvoiceAuditDTO) {
      this.accessorialsDelivery = this.addAccessorials(shipment, Globals.DeliveryAccessorials, Globals.AccessorialTypeValue[1].value);
      this.accessorialsShipment = this.addAccessorials(shipment, Globals.ShipmentAccessorials, Globals.AccessorialTypeValue[2].value);
      this.accessorialsPickup = this.addAccessorials(shipment, Globals.PickupAccessorials, Globals.AccessorialTypeValue[3].value);
    }
    public setCustomerCharges(customerCharges: any[]) {
      let i = 0;
      const convertedCharges = customerCharges.map(
        ({
          chargeID = ++i,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateQualifier: rateCode,
          rateQualifier = '',
          quantity,
          weight,
          isMin,
          isMax,
          dimWeight,
          amount,
          type,
          edicode: ediCode,
          group = '',
        }) => ({
          chargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateQualifier,
          rateCode,
          quantity,
          weight,
          isMin,
          isMax,
          dimWeight,
          amount,
          type,
          ediCode,
          group,
        })
      );
      this.setCharges(convertedCharges);

      // map the rate codes for both charge types
      if (this.lineHaulCharges != null) {
        this.lineHaulCharges.map(this.getQualifier, this);
      }
      if (this.accessorialCharges != null) {
        this.accessorialCharges.map(this.getQualifier, this);
      }
    }
    public get charges(): InvoiceCharge[] {
      if (this.accessorialCharges == null || this.lineHaulCharges == null) {
        return null;
      }
      return this.accessorialCharges.concat(this.lineHaulCharges);
    }
    public isLTL(): boolean {
      return this.mode.toUpperCase() === 'LTL' || this.mode.toUpperCase() === 'LTL VOLUME' || this.mode.toUpperCase() === 'VOLUME';
    }
    public isOcean(): boolean {
      return this.mode.toUpperCase() === 'OCEAN';
    }
    public getAccessorials(): AccessorialUI[] {
      return this.getFullAccessorials();
    }
    public getAccessorialCodes(): string[] {
      const newList = this.getFullAccessorials();
      return newList == null ? null : newList.map((a) => a.code);
    }
    public getShipmentAccessorials(): AccessorialUI[] {
      return this.accessorialsShipment;
    }
    public getPickupAccessorials(): AccessorialUI[] {
      return this.accessorialsPickup;
    }
    public getDeliveryAccessorials(): AccessorialUI[] {
      return this.accessorialsDelivery;
    }
    public resetCarrier() {
      this.scac = 'n/a';
    }
    public resetServiceName() {
      this.serviceName = '';
    }
  }

  export class ReferenceUI {
    id: number;
    type: string;
    value: string;
    isPrimary: boolean;
    isRequired: boolean;
    isEditable: boolean;
    isNew: boolean;
    allowedValues: string[];
    defaultValue: string;
    isEditMode: false;
    constructor(
      id: number,
      type: string,
      value: string,
      isPrimary: boolean,
      isRequired: boolean,
      isEditable: boolean,
      isNew: boolean,
      alloedValues: string[],
      defaultValue: string
    ) {
      this.id = id;
      this.type = type;
      this.value = value;
      this.isPrimary = isPrimary;
      this.isRequired = isRequired;
      this.isEditable = isEditable;
      this.isNew = isNew;
      this.allowedValues = alloedValues;
      this.defaultValue = defaultValue;
      this.isEditMode = false;
    }
  }

  export class ItemUI {
    id: number;
    quantity: number;
    quantityUOM: string;
    weight: number;
    weightUOM: string;
    pieces: number;
    piecesUOM: string;
    length: number;
    height: number;
    width: number;
    dimensionUOM: string;
    freightClass: any;
    description: string;
    nmfc: string;

    constructor(item: Item) {
      this.id = item.itemID;
      this.quantity = item.quantity.value;
      this.quantityUOM = item.quantity.uom;
      this.weight = item.weight.value;
      this.weightUOM = item.weight.uom;
      this.pieces = item.quantity.numberOfPieces;
      this.piecesUOM = item.quantity.piecesUom === '' ? 'Pieces' : item.quantity.piecesUom;
      this.length = item.dimension === null ? 0 : item.dimension.length;
      this.height = item.dimension === null ? 0 : item.dimension.height;
      this.width = item.dimension === null ? 0 : item.dimension.width;
      this.dimensionUOM = item.dimension === null ? '' : item.dimension.uom;
      this.description = item.description;
      this.nmfc = item.nmfc;
      this.freightClass = item.freightClass;
    }
  }

  export class AccessorialUI {
    id: number;
    code: string;
    name: string;
  }

  export class AddressUI {
    id: number;
    name: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    countryCode: string;
    sequence: number;

    constructor(address: Address) {
      this.id = address.addressID;
      this.companyName = address.companyName;
      this.name = address.contactName;
      this.addressLine1 = address.addressLine1;
      this.addressLine2 = address.addressLine2;
      this.stateProvince = address.stateProvince;
      this.city = address.city;
      this.postalCode = address.postalCode;
      this.countryCode = address.countryCode;
      this.sequence = address.sequence;
    }

    public updateValues(address: AddressUI) {
      this.companyName = address.companyName;
      this.addressLine1 = address.addressLine1;
      this.addressLine2 = address.addressLine2;
      this.stateProvince = address.stateProvince;
      this.city = address.city;
      this.postalCode = address.postalCode;
      this.countryCode = address.countryCode;
      this.sequence = address.sequence;
    }
  }
}
