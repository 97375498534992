import { BBillOld } from '../models/TruckloadAuditOld.ui';

export interface TruckloadAuditOldDTO {
  shipmentID: number;
  invoiceID: number;
  enterpriseID: number;
  invoiceAuditID: number;
  invoiceNumber: string;
  invoiceTotal: number;
  carrierName: string;
  carrierCode: string;
  shipmentBuyRate: number;
  shipmentSellRate: number;
  customer: string;
  customerAccount: string;
  primaryReference: string;
  invoiceCreatedDate: string;
  isSecondary: boolean;
  auditor: string;
  auditorAssignedDate: string;
  auditCreatedDate: string;
  auditCompletedDate: string;
  reasons: TruckloadAuditReasonOldDTO[];
}

export interface TruckloadAuditReasonOldDTO {
  tlAuditID: number;
  reasonID: number;
  reason: string;
  status: string;
  createdDate: string;
  createdUser: string;
  updatedDate: string;
  updatedUser: string;
  comments: TLAuditReasonCommentOldDTO[];
}

export interface TLAuditReasonCommentOldDTO {
  reasonID: number;
  comment: string;
  commentID: number;
  status: string;
  createdUser: string;
  createdDate: string;
}

export interface TruckloadAuditSaveOldDTO {
  shipmentID: number;
  invoiceID: number;
  truckloadAuditID: number;
  auditor: string;
  auditorDateAssigned: string;
  dateCompleted: string;
  reasons: TruckloadAuditReasonOldDTO[];
}

export class TLAuditRefDataOldResponse {
  tlAuditReasons: Map<object, string>[];
  tlAuditSettlementReasons: string[];
}

export class TLBBillOld {
  constructor(bbill: BBillOld) {
    this.shipmentID = bbill.shipmentID;
    this.invoiceNumber = bbill.invoiceNumber;
    this.bbillAccountNumber = bbill.accountNumber;
    this.bbillPreviousPayment = bbill.previousPayment;
    this.bbillSettlementReasons = bbill.reasons;
  }
  shipmentID: number;
  invoiceNumber: string;
  bbillAccountNumber: string;
  bbillPreviousPayment: number;
  bbillSettlementReasons: string[];
}
