import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShipmentSuggestedMatchDto, SuggestedShipmentsAddress } from '../models/SuggestedShipments';
import { StartupService } from '../startup.service';

@Injectable()
export class TrackingService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  constructor(private http: HttpClient, private startupService: StartupService) { }

  getSuggestedShipmentsByOriginDestination(
    origin: SuggestedShipmentsAddress,
    destination: SuggestedShipmentsAddress,
    scac: string,
  ): Observable<ShipmentSuggestedMatchDto[]> {
    const endpoint = `${this.startupService.trackingApiUrl}shipment/suggested-matches/by-origin-destination`;

    return this.http.post<ShipmentSuggestedMatchDto[]>(
      endpoint,
      { origin, destination, scac },
      { headers: this.headers },
    );
  }
}
