import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ShipmentUI } from '../../pages/audit/Shipment.ui';
import { AddressEditComponent } from '../address-edit/address-edit.component';

@Component({
  selector: 'address-edit-modal',
  templateUrl: './address-edit-modal.component.html',
  styleUrls: ['../shipment-edit/shipment-edit.component.scss'],
})
export class AddressEditModalComponent {
  @Input()
  address: ShipmentUI.AddressUI;

  @Output()
  addressChange: EventEmitter<ShipmentUI.AddressUI> = new EventEmitter<ShipmentUI.AddressUI>();

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('addressEdit', { static: true })
  addressEdit: AddressEditComponent;

  onSave(){
    this.addressChange.emit(this.addressEdit.getAddress());
    this.close.emit(true);
  }

  onClose(){
    this.close.emit(false);
  }
}
