export class BGRatingHelpers {
  static MapCharge(c: any): any {
    return <Charge>{
      amount: c.amount,
      description: c.description,
      dimWeight: c.dimWeight,
      fakFreightClass: c.fakFreightClass,
      freightClass: c.freightClass,
      isAccessorial: c.isAccessorial,
      isLinehaul: c.isLinehaul,
      isMax: c.isMax,
      isMin: c.isMin,
      isNonTaxable: c.isNontaxable,
      quantity: c.quantity,
      rate: c.rate,
      rateQualifier: c.rateQualifier,
      type: this.mapFromType(c.subType),
      subType: c.subType,
      weight: c.weight,
      code: c.code,
      ediCode: c.code
    };
  }

  static GetChargeType(chargeType: string, description: string) {
    switch (chargeType.toUpperCase()) {
      case 'LINEHAUL':
        if (description.toUpperCase() == 'DISCOUNT') return 'DISCOUNT';
        if (description.toUpperCase() == 'ADJUSTMENT') return 'MG_MINMAX_ADJ';
        if (description.toUpperCase() == 'SMCADJUSTMENT') return 'SMC_MIN_ADJ';
        if (description.toUpperCase() == 'DEFICIT') return 'DEFICIT';
        return 'ITEM';
      case 'ITEM':
        return chargeType.toUpperCase();
      case 'FUEL':
        return 'ACCESSORIAL_FUEL';
      case 'ACCESSORIAL_FUEL':
        return chargeType.toUpperCase();
      case 'UNKNOWN':
        return 'UNKNOWN';
      case 'ACCESSORIAL':
        return chargeType.toUpperCase();
      case 'DISCOUNT':
        return chargeType.toUpperCase();
      case 'SMCMINADJ':
        return 'SMC_MIN_ADJ';
      case 'MGMINMAXADJUSTMENT':
        return 'MG_MINMAX_ADJ';
      case 'DEFICIT':
        return chargeType.toUpperCase();
      case 'ACCESSORIALPERCENTAGETOTAL':
        return 'ACCESSORIAL_PERCENTAGE_TOTAL';
      case 'MARKUP':
        return chargeType.toUpperCase();
      default:
        return 'ACCESSORIAL';
    }
  }

  static mapFromType(subType: string): string {
    switch (subType.toLocaleUpperCase()) {
      case 'LINEHAUL':
        return 'ITEM';
      case 'DISCOUNT':
        return subType.toLocaleUpperCase();
      case 'ADJUSTMENT':
        return 'MG_MINMAX_ADJ';
      case 'SMCADJUSTMENT':
      case 'MINIMUM':
        return 'SMC_MIN_ADJ';
      case 'DEFICIT':
        return subType.toLocaleUpperCase();
      case 'FUELSURCHARGE':
        return 'ACCESSORIAL_FUEL';
      default:
        return 'ACCESSORIAL';
    }
  }

  static GetBGChargeType(chargeType: string) {
    switch (chargeType.toUpperCase()) {
      case 'LINEHAUL':
      case 'ITEM':
      case 'DISCOUNT':
      case 'ADJUSTMENT':
      case 'MG_MINMAX_ADJ':
      case 'SMC_MIN_ADJ':
      case 'DEFICIT':
        return 'Linehaul';
      case 'ACCESSORIAL_FUEL':
        return 'Fuel';
      case 'ACCESSORIAL':
      case 'ACCESSORIAL_PERCENTAGE_TOTAL':
        return 'Accessorial';
      default:
        return 'Linehaul';
    }
  }

  static GetBGChargeSubType(chargeSubType: string) {
    switch (chargeSubType.toUpperCase()) {
      case 'LINEHAUL':
      case 'ITEM':
        return 'Linehaul';
      case 'DISCOUNT':
        return 'Discount';
      case 'ADJUSTMENT':
        return 'Adjustment';
      case 'MG_MINMAX_ADJ':
        return 'Adjustment';
      case 'SMC_MIN_ADJ':
        return 'SmcAdjustment';
      case 'DEFICIT':
        return 'Deficit';
      case 'ACCESSORIAL_FUEL':
        return 'FuelSurcharge';
      case 'ACCESSORIAL':
      case 'ACCESSORIAL_PERCENTAGE_TOTAL':
        return 'Accessorial';
      default:
        return 'Linehaul';
    }
  }
}

export class Charge {
  type: string;
  subType: string;
  description: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  code: string;
  ediCode: string;
}
