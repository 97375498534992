//import { CarrierRate, CustomerRate, QuoteRequest, QuoteResponse, ServiceLevel } from '@/models/BgRating';
import * as bgr from '@/models/BgRating';
import { BgRatingService } from '@/services/bgRating.service';
import { InvoiceService } from '@/services/invoice.service';
import { AddressDTO, Item } from '@/services/Shipment';
import { ShipmentService } from '@/services/shipment.service';
import { chainedInstruction } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AddEvent, EditEvent, SaveEvent, RemoveEvent, CancelEvent, SuspendService } from '@progress/kendo-angular-grid';
// import { timeStamp } from 'console';
import * as jsonpatch from 'fast-json-patch'
import { Observer, Operation, validator } from 'fast-json-patch';
import { basename } from 'path';
import { logging } from 'protractor';
import { Observable } from 'rxjs';
import { inherits } from 'util';
import { BgRatingHelper } from '../bgRatingHelper';
import * as jquery from 'jquery';
import { Globals } from '@/_shared/globals';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AddressEditDialogComponent } from '@/bg-common/address-edit-dialog/address-edit-dialog.component';
import { CurrencyPipe } from '@angular/common';
import * as v3_models from '@/models/v3/Shipment';
import { CustomerService } from '@/services/customer.service';
import { CustomerProfile } from '@/models/CustomerProfile';

@Component({
  selector: 'app-audit-invoice',
  templateUrl: './audit-invoice.component.html',
  styleUrls: ['./audit-invoice.component.scss'],
  providers: [CurrencyPipe]
})
export class AuditInvoiceComponent implements OnInit {

  shipmentId: number;
  proactive: boolean;
  proactiveNotes: string;
  current: v3_models.Shipment;
  _original: v3_models.Shipment;
  _customerPricing: string;
  upliftLinehaulOnly: boolean;
  isOpenBook: boolean;

  accessorials: any[];

  private _referenceGridEditIndex: number;
  private _itemGridEditIndex: number;

  private _carrierQuote: any = undefined;
  private _customerQuote: any = undefined;
  private _totalWeight: number = undefined;
  private _plannedWeight: number = undefined;

  get carrierQuote(): any {
    if (!this._carrierQuote) {
      this._carrierQuote = this.current && this.current.quotes.find(x => x.isSelected && x.type == 'Charge');
    }
    return this._carrierQuote;
  }

  get customerQuote(): any {
    if (!this._customerQuote) {
      this._customerQuote = this.current && this.current.quotes.find(x => x.isSelected && x.type == 'Cost');
    }
    return this._customerQuote;
  }

  get totalWeight(): number {
    if (!this.current) {
      return 0;
    }

    if (!this._totalWeight) {
      this._totalWeight = this.current.items.reduce((t, c) => t += c.weight.value, 0);
    }

    return this._totalWeight;
  }

  get plannedWeight(): number {
    if (!this.current) {
      return 0;
    }

    if (!this._plannedWeight) {
      this._plannedWeight = this.current.items.reduce((t, c) => t += c.plannedWeight.value, 0);
    }

    return this._plannedWeight;
  }

  /*
   * Calculates the total weight from the customer quote charges. Only includes
   * charges of type ITEM.
   */
  get customerQuoteWeight(): number {
    if (!this.carrierQuote) {
      return 0;
    }

    return this.carrierQuote.charges
      .filter(x => x.type === 'ITEM')
      .reduce((t, c) => t += c.weight, 0);
  }

  get references(): any[] {
    return this.current && this.current.shipmentReferences.filter(x => !x.isDeleted) || [];
  }

  constructor(
    private route: ActivatedRoute,
    private bgRatingService: BgRatingService,
    private shipmentService: ShipmentService,
    private invoiceService: InvoiceService,
    private dialogService: DialogService,
    private customerService: CustomerService,
    private currencyPipe: CurrencyPipe
  ) {
    this.accessorials = Globals.ShipmentAccessorials
      .concat(Globals.PickupAccessorials)
      .concat(Globals.DeliveryAccessorials);
  }

  ngOnInit() {
    this.shipmentId = +this.route.snapshot.paramMap.get('shipmentId');
    this.proactive = <boolean>JSON.parse(this.route.snapshot.queryParamMap.get('pro') || 'false');
    this.load(this.shipmentId);
  }

  editAddress(title: string, address: any): void {
    let ref = this.dialogService.open({
      content: AddressEditDialogComponent,
      title: title,
      width: 500
    });

    let content = ref.content.instance as AddressEditDialogComponent;
    content.address = Object.assign({}, address);

    console.log(content.address);

    ref.result.subscribe(result => {
      if (result instanceof DialogCloseResult) return;

      jquery.extend(true, address, result);
    });
  }

  addReference() {
    this.current.shipmentReferences
      .push(<v3_models.ShipmentReference>{
        type: "BOL",
        value: "temp"
      });
  }

  removeReference(index: number) {
    (this.current.shipmentReferences as any[]).splice(index, 1);
  }

  onSave() {
    let changes = jsonpatch.compare(this.current, this.current);

    console.log(changes);

    this.shipmentService.patchFullShipment(this.shipmentId, changes)
      .subscribe(x => {
        console.log('okay')
      }, err => {
        console.log(err);
      });
  }

  editCarrierCharges(data: any) {

  }

  hideEditMoveBtn(status: any): boolean {
    return false;
  }

  onAddReference(args: AddEvent): void {
    args.sender.closeRow(this._referenceGridEditIndex);

    let formGroup = new FormGroup({
      'type': new FormControl('', Validators.required),
      'value': new FormControl('', Validators.required)
    });

    args.sender.addRow(formGroup);
    this._referenceGridEditIndex = args.rowIndex;
  }

  onEditReference(args: EditEvent): void {
    args.sender.closeRow(this._referenceGridEditIndex);

    let reference = this.current.shipmentReferences[args.rowIndex];
    let formGroup = new FormGroup({
      'type': new FormControl(reference.type, Validators.required),
      'value': new FormControl(reference.value)
    });

    args.sender.editRow(args.rowIndex, formGroup);
    this._referenceGridEditIndex = args.rowIndex;
  }

  onSaveReference(args: SaveEvent): void {
    let reference = {
      id: 0,
      isDeleted: false,
      isPrimary: false,
      type: null,
      value: null
    };

    if (args.isNew) {
      this.shipmentService.patchFullShipment(this.shipmentId, [{
        op: 'add',
        path: `/shipmentReferences/${this.current.shipmentReferences.length}`,
        value: args.formGroup.value
      }])
        .subscribe(x => {
          console.log('reference added');
        });
      // reference = Object.assign(reference, args.formGroup.value);
      // this.original.shipmentReferences.unshift(reference);
    }
    else {
      let modified = jsonpatch.deepClone(this.current);
      Object.assign(modified.shipmentReferences[args.rowIndex], args.formGroup.value);
      let patch = jsonpatch.compare(this.current, modified);
      this.shipmentService.patchFullShipment(this.shipmentId, patch)
        .subscribe(x => {
          console.log('reference edited');
        });
      // reference = this.original.shipmentReferences[args.rowIndex];
      // reference = Object.assign(reference, args.formGroup.value);
    }

    args.sender.closeRow(args.rowIndex);
  }

  onRemoveReference(args: RemoveEvent): void {
    this.current.shipmentReferences[args.rowIndex].isDeleted = true;
  }

  onAddItem(args: AddEvent): void {
    args.sender.closeRow(this._itemGridEditIndex);

    let formGroup = this.buildItemFormGroup({
      description: null,
      dimension: {
        length: null,
        width: null,
        height: null,
        uom: null
      },
      freightClass: null,
      quantity: {
        value: null,
        uom: null
      },
      weight: {
        value: null,
        uom: null
      },
      nmfc: null
    });

    args.sender.addRow(formGroup);
    this._itemGridEditIndex = args.rowIndex;
  }

  onEditItem(args: EditEvent): void {
    args.sender.closeRow(this._itemGridEditIndex);

    let reference = this.current.items[args.rowIndex];
    let formGroup = this.buildItemFormGroup(reference);

    args.sender.editRow(args.rowIndex, formGroup);
    this._itemGridEditIndex = args.rowIndex;
  }

  onSaveItem(args: SaveEvent): void {

    let item = {
      description: null,
      dimension: {
        length: null,
        width: null,
        height: null,
        uom: null
      },
      freightClass: null,
      quantity: {
        value: null,
        uom: null
      },
      weight: {
        value: null,
        uom: null
      },
      nmfc: null
    };

    if (args.isNew) {
      this.current.items.push(args.formGroup.value);
      // this._patch.push({
      //   op: 'add',
      //   path: `/items/${this.original.items.length}`,
      //   value: args.formGroup.value
      // });
    }
    else {
      console.log(this.current.items[args.rowIndex]);
      console.log(args.formGroup.value);
      this.current.items[args.rowIndex] = jquery.extend(true, this.current.items[args.rowIndex], args.formGroup.value);
      console.log(this.current.items[args.rowIndex]);
      //this.original.items[args.rowIndex] = Object.assign(this.original.items[args.rowIndex], args.formGroup.value);
      // let item = this.original.items[args.rowIndex];
      // let ops = jsonpatch.compare(item, args.formGroup.value);
      // this._patch = this._patch.concat(ops);
    }

    // this.original = jsonpatch.applyPatch(this.current, this._patch).newDocument;

    args.sender.closeRow(args.rowIndex);
  }

  onRemoveItem(args: RemoveEvent): void {
    this.current.items[args.rowIndex].isDeleted = true;
    // this._patch.push({
    //   op: 'replace',
    //   path: `/items/${args.rowIndex}/isDeleted`,
    //   value: 'true'
    // });

    // this.original = jsonpatch.applyPatch(this.current, this._patch);
  }

  onAddService(args: AddEvent): void {
  }

  onEditService(args: EditEvent): void {

  }

  onSaveService(args: SaveEvent): void {

  }

  onRemoveEvent(args: RemoveEvent): void {

  }

  onCancel(args: CancelEvent) {
    args.sender.closeRow(args.rowIndex);
  }

  /*
    return auditItemListComponent.records
      .filter(x => x.weight > 0.0)
      .map((item) => {
        return <QuoteItem>{
          freightClass: (item.freightClass as number).toString(),
          weight: item.weight,
          length: item.length,
          width: item.width,
          height: item.height,
          quantity: item.quantity,
          itemUnitType: item.quantityUOM,
        };
      });
  */
  onRerate(): void {
    this.bgRatingService.quote(this.current, [this.carrierQuote.scac, 'BENCHMARK'])
      .toPromise()
      .then(data => {
        return this.convert(data as bgr.QuoteResponse) as v3_models.Quote[];
      })
      .then((data: v3_models.Quote[]) => {
        let scac = this.carrierQuote.scac;
        let serviceLevel = this.carrierQuote.service;

        this.current.quotes.forEach(x => {
          x.isSelected = false;
        });

        console.log(`searching for: ${scac} -- ${serviceLevel}`);
        console.log(data);
        let service = data.find(x => x.scac === scac && x.service === serviceLevel);

        if (!service) throw 'cannot find carrier';

        data
          .filter(x => x.analagousId === service.analagousId)
          .forEach(x => {
            x.isSelected = true;
          });

        this.current.quotes = this.current.quotes.concat(data);
        var patch = jsonpatch.compare(this._original, this.current);
        console.log(patch);
        return patch;
      })
      .then(patch => {
        console.log(patch);
        return this.shipmentService
          .patchFullShipment(this.shipmentId, patch)
          .toPromise();
      })
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        console.error(err);
      });
  }

  onMarkup(): void {
    this.bgRatingService.markup(this.current)
      .toPromise()
      .then(data => {
        return this.convert(data as bgr.QuoteResponse)
      })
      .then((data: v3_models.Quote[]) => {
        let carrierQuote = this.carrierQuote;
        const mode = carrierQuote.mode;
        const carrierName = carrierQuote.carrierName;

        this.current.quotes.forEach(x => {
          x.isSelected = false;
        });

        data.forEach(x => {
          x.isSelected = true;
          x.mode = mode;
          x.carrierName = carrierName;
          x.isManualCreate = true;
          x.note = 'Manual';
          x.priceSheet = 'Manual';
        });

        console.log(data);

        this.current.quotes = this.current.quotes.concat(data);
        let patch = jsonpatch.compare(this._original, this.current);
        console.log(patch);
        return patch;
      })
      .then(patch => {
        return this.shipmentService
          .patchFullShipment(this.shipmentId, patch)
          .toPromise();
      })
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        console.error(err);
      });
  }

  onRerateCharge(): void {
    console.log(this.carrierQuote);
    this.bgRatingService.quote(this.current, [this.carrierQuote.scac, 'BENCHMARK'])
      .toPromise()
      .then(data => {
        return this.convert(data as bgr.QuoteResponse);
      })
      .then(data => {
        let currentCustomerQuote = this.current.quotes.find(x => x.isSelected && x.type == 'Cost');
        let scac = currentCustomerQuote.scac;
        let serviceLevel = currentCustomerQuote.service;

        this.current.quotes.forEach(x => {
          x.isSelected = false;
        });

        console.log(data);
        console.log(`searching for ${scac} -- ${serviceLevel}`);
        let newCustomerQuote = data.find(x => x.scac == scac && x.service == serviceLevel && x.type == 'Cost');

        if (!newCustomerQuote) throw 'cannot find service';

        data.filter(x => x.analagousId == newCustomerQuote.analagousId)
          .forEach(x => {
            x.isSelected = true;
          });

        newCustomerQuote.charges = jquery.extend(true, [], currentCustomerQuote.charges);
        newCustomerQuote.charges.forEach(x => {
          // x..id = 0;
        });

        console.log('old and new customer quotes');
        console.log(currentCustomerQuote);
        console.log(newCustomerQuote);

        this.current.quotes = this.current.quotes.concat(data);

        let patch = jsonpatch.compare(this._original, this.current);
        return patch;
      })
      .then(patch => {
        return this.shipmentService
          .patchFullShipment(this.shipmentId, patch)
          .toPromise();
      })
      .then(() => {
        console.log('success');
      })
      .catch(err => {
        console.error(err);
      })
  }

  filterCharges(charges: v3_models.Charge[], type: string): v3_models.Charge[] {
    return charges.filter(x => x.type == type);
  }

  private load(shipmentId: number): void {
    this.shipmentService.getFullShipment(this.shipmentId)
      .toPromise()
      .then(data => {
        // let result = jsonpatch.applyPatch(data, this._changeset);
        // console.log(result);
        this._original = data;
        this.current = jsonpatch.deepClone(this._original);
        // this.observer = jsonpatch.observe(this.original, this.onChangesObserved.bind(this));
        return Promise.all([
          this.bgRatingService
            .getMarkupRules(new Date(), this._original.enterpriseAccountNumber)
            .toPromise(),
          this.customerService
            .getSpecificCustomerProfileByEnterpriseID(this._original.enterpriseId)
            .toPromise()
        ]);
      })
      .then(data => {
        var markupRule = data[0] as bgr.MarkupRuleResponse;
        var customerProfile = data[1] as CustomerProfile;

        if (markupRule && markupRule.markupType) {

          let markupValue = markupRule.markupType.toLowerCase() == "flat"
            ? markupRule.markupType + " " + this.currencyPipe.transform(markupRule.markup)
            : markupRule.markup + '%';

          this._customerPricing = markupValue;
        }
        else {
          this._customerPricing = "N/A";
        }

        this.upliftLinehaulOnly = !!(markupRule && markupRule.upliftLinehaulOnly);
        this.isOpenBook = !!(markupRule && markupRule.isOpenBook);

        this.proactive = customerProfile && customerProfile.isProactive;
        this.proactiveNotes = customerProfile && customerProfile.proactiveNotes;
        console.log(this.proactiveNotes);
      });
  }

  private buildItemFormGroup(item: any): FormGroup {
    return new FormGroup({
      'description': new FormControl(item.description, Validators.required),
      'dimension': new FormGroup({
        'length': new FormControl(item.dimension.length),
        'width': new FormControl(item.dimension.width),
        'height': new FormControl(item.dimension.height),
        'uom': new FormControl(item.dimension.uom)
      }),
      'freightClass': new FormControl(item.freightClass),
      'quantity': new FormGroup({
        'value': new FormControl(item.quantity.value, Validators.min(1)),
        'uom': new FormControl(item.quantity.uom, Validators.required)
      }),
      'weight': new FormGroup({
        'value': new FormControl(item.weight.value),
        'uom': new FormControl(item.weight.uom)
      }),
      'nmfc': new FormControl(item.nmfc)
    });
  }

  private buildServiceFormGroup(service: any): FormGroup {
    return new FormGroup({
      'name': new FormControl(service.name, Validators.required),
      'code': new FormControl(service.code, Validators.required)
    });
  }


  private convert(response: bgr.QuoteResponse): v3_models.Quote[] {
    let quotes: v3_models.Quote[] = [];

    for (let rate of response.rates) {
      if (!!rate.carrierRate) {
        let quote = new v3_models.CarrierQuote(response.id, response.distance, rate);
        let serviceLevels = rate.carrierRate.serviceLevels.map(x => {
          let newQuote: v3_models.CarrierQuote = quote.clone();
          newQuote.setServiceLevel(x, rate.billedWeight);
          return newQuote;
        });
        quotes.push(quote, ...serviceLevels as v3_models.Quote[]);
      }

      if (!!rate.customerRate) {
        let quote = new v3_models.CustomerQuote(response.id, response.distance, rate);
        let serviceLevels = rate.customerRate.serviceLevels.map(x => {
          let newQuote: v3_models.CustomerQuote = quote.clone();
          newQuote.setServiceLevel(x, rate.billedWeight);
          return newQuote;
        });
        quotes.push(quote, ...serviceLevels);
      }

      if (!!rate.customerThirdRate) {
        let quote = new v3_models.ThirdPartyQuote(response.id, response.distance, rate);
        let serviceLevels = rate.customerThirdRate.serviceLevels.map(x => {
          let newQuote: v3_models.CustomerQuote = quote.clone();
          newQuote.setServiceLevel(x, rate.billedWeight);
          return newQuote;
        });
        quotes.push(quote, ...serviceLevels);
      }
    }

    return quotes;
  }
}

/*
class Quote {
  id: number;
  shipmentId: number;
  isDeleted: boolean;
  type: string;
  analagousId: string;
  requestGroupId: string;
  contractId: number;
  contractName: string;
  carrierName: string;
  scac: string;
  mode: string;
  service: string;
  serviceDays: number;
  distance: number;
  expectedDeliveryDate: Date;
  carrierIdentifier: string;
  originService: string;
  destinationService: string;
  currencyCode: string;
  priceSheet: string;
  filterReason: string;
  isExcluded: boolean;
  isSelected: boolean;
  // maxLiability -- doesn't appear to be used by back end
  carrierLiabilityOld: string;
  carrierLiabilityNew: string;
  quoteNumber: string; // Is this correct?
  note: string;
  //isPremium -- ignoring, doesn't appear to be used (investigate a little further)
  isLeastCost: boolean;
  //isTMS: false -- not certain whether this is mapped to anything
  quoteTotal: number;
  normalizedTotal: number;
  normalizedCurrencyCode: string;
  charges: Charge[];
  isManualCreate: boolean;
  billingAddress: Address;
  serviceTotal: number;
  freightTotal: number;

  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    this.id = 0;
    this.shipmentId = 0;
    this.analagousId = rate.carrierRate.analogousID;
    this.requestGroupId = requestGroupId;
    this.contractId = rate.contractID;
    this.contractName = rate.contractName;
    this.carrierName = rate.carrierName;
    this.scac = rate.scac;
    this.mode = rate.mode;
    this.service = rate.carrierRate.description; // Rename to serviceLevel per Geoffry
    this.serviceDays = rate.serviceDays;
    this.distance = distance;
    this.expectedDeliveryDate = new Date(rate.estimatedDelivery);
    this.carrierIdentifier = rate.carrierName;
    this.originService = rate.serviceOrigin;
    this.destinationService = rate.serviceDestination;
    this.currencyCode = rate.currencyCode;
    this.priceSheet = rate.source; // dbo.Quote.PriceSheetID
    this.filterReason = rate.isExcluded ? rate.excludeReason : '';
    this.isExcluded = rate.isExcluded;
    this.isSelected = false;
    this.carrierLiabilityOld = rate.maxLiability.toString();
    this.carrierLiabilityNew = rate.maxLiability.toString();
    this.quoteNumber = '';
    this.note = rate.discountType;
    this.isLeastCost = false;
    this.quoteTotal = 0;
    this.normalizedTotal = 0;
    this.normalizedCurrencyCode = 'USD';
    this.charges = [];
    this.isManualCreate = false;
    this.serviceTotal = 0;
    this.freightTotal = 0;
    this.billingAddress = {
      addressLine1: rate.billingAddress.addressLine1,
      addressLine2: rate.billingAddress.addressLine2,
      city: rate.billingAddress.city,
      stateProvince: rate.billingAddress.stateProvince,
      postalCode: rate.billingAddress.postalCode,
      countryCode: rate.billingAddress.country,
      companyName: rate.billingAddress.companyName
    };
  }

  public clone(): Quote {
    let obj = Object.create(this);
    Object.assign(obj, {
      ...this,
      billingAddress: { ...this.billingAddress }
    });
    return obj;
  }

  public setServiceLevel(serviceLevel: bgr.ServiceLevel, billedWeight: number): void {
    this.analagousId = serviceLevel.analogousID;
    this.service = serviceLevel.description;
    this.quoteNumber = serviceLevel.carrierQuoteNumber;
    this.quoteTotal = serviceLevel.totalCharge;
    this.charges = serviceLevel.serviceLevelCharges.map(x => new Charge(x, billedWeight));
    // this.serviceTotal = this.total(this.filter(Charge.ACCESSORIAL));
    // this.freightTotal = this.total(this.filter(Charge.LINEHAUL));
  }

  protected filter(type: string): Charge[] {
    return this.charges.filter(x => !!x.type && x.type.toLocaleLowerCase() === type);
  }

  protected total(charges: Charge[]): number {
    return charges
      .map(x => x.amount)
      .reduce((total, amount) => total + amount, 0);
  }
}

class CarrierQuote extends Quote {
  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    super(requestGroupId, distance, rate);
    this.type = 'Charge';
    this.quoteTotal = rate.carrierRate.totalCharge;
    this.normalizedTotal = rate.carrierRate.totalChargeInUSD;
    this.charges = rate.carrierRate.charges.map(x => new Charge(x, rate.billedWeight));
    // this.serviceTotal = this.total(this.filter(Charge.ACCESSORIAL));
    // this.freightTotal = this.total(this.filter(Charge.LINEHAUL));
  }
}

class CustomerQuote extends Quote {
  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    super(requestGroupId, distance, rate);
    this.type = 'Cost';
    this.quoteTotal = rate.customerRate.totalCharge;
    this.normalizedTotal = rate.customerRate.totalChargeInUSD;
    this.charges = rate.customerRate.charges.map(x => new Charge(x, rate.billedWeight));
    // this.serviceTotal = this.total(this.filter(Charge.ACCESSORIAL));
    // this.freightTotal = this.total(this.filter(Charge.LINEHAUL));
  }
}

class ThirdPartyQuote extends Quote {
  constructor(requestGroupId: string, distance: number, rate: bgr.Rate) {
    super(requestGroupId, distance, rate);
    this.type = 'Price';
    this.quoteTotal = rate.customerThirdRate.totalCharge;
    this.normalizedTotal = rate.customerThirdRate.totalChargeInUSD;
    this.charges = rate.customerThirdRate.charges.map(x => new Charge(x, rate.billedWeight));
    // this.serviceTotal = this.total(this.filter(Charge.ACCESSORIAL));
    // this.freightTotal = this.total(this.filter(Charge.LINEHAUL));
  }
}

class Charge {
  static readonly ACCESSORIAL: string = 'accessorial';
  static readonly LINEHAUL: string = 'linehaul';

  amount: number;
  amountInUSD: number;
  code: string;
  description: string;
  fakFreightClass: number;
  freightClass: number;
  isMax: boolean;
  isMin: boolean;
  quantity: number;
  rate: number;
  rateInUSD: number;
  rateQualifier: string;
  subType: string;
  type: string;
  weight: number;
  dimWeight: number;
  isNonTaxable: boolean;
  isLinehaul: boolean;
  isAccessorial: boolean;
  ediGroup: string;
  ediCode: string;
  group: string;

  constructor(charge: bgr.Charge, billedWeight: number) {
    let isLinehaul = charge.type.toLocaleLowerCase() === Charge.LINEHAUL;
    let isAccessorial = charge.type.toLocaleLowerCase() === Charge.ACCESSORIAL;
    let type = isLinehaul || isAccessorial ? charge.subType : charge.type;
    this.type = this.mapChargeType(type);
    this.subType = charge.subType;
    this.description = charge.description;
    this.amount = charge.amount;
    this.rate = charge.rate;
    this.rateQualifier = charge.rateQualifier;
    this.quantity = charge.quantity;
    this.weight = charge.weight || billedWeight;
    this.dimWeight = 0;
    this.freightClass = charge.freightClass;
    this.fakFreightClass = charge.fakFreightClass;
    this.isMin = charge.isMin;
    this.isMax = charge.isMax;
    this.isNonTaxable = false;
    this.isLinehaul = isLinehaul;
    this.isAccessorial = isAccessorial;
    this.ediGroup = null;
    this.ediCode = '';
    this.group = '';
  }

  protected mapChargeType(subtype: string): string {
    switch (subtype.toLocaleUpperCase()) {
      case 'LINEHAUL':
        return 'ITEM';
      case 'DISCOUNT':
        return 'DISCOUNT';
      case 'ADJUSTMENT':
        return 'MG_MINMAX_ADJ';
      case 'SMCADJUSTMENT':
      case 'MINIMUM':
        return 'SMC_MIN_ADJ';
      case 'DEFICIT':
        return 'DEFICIT';
      case 'FUELSURCHARGE':
        return 'ACCESSORIAL_FUEL';
      default:
        return 'ACCESSORIAL';
    }
  }
}

class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
  companyName: string;
}
*/