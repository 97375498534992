import { InvoiceService } from '@/services/invoice.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.scss']
})
export class AddNoteDialogComponent extends DialogContentBase implements OnInit {

  formGroup: FormGroup;
  loading: boolean = false;
  @Input() selectedRecords: number[];

  constructor(
    dialog: DialogRef,
    private invoiceService: InvoiceService) {
    super(dialog);
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      note: new FormControl("", Validators.required)
    });
  }

  onSave() {
    this.loading = true;

    let note = this.formGroup.value.note;
    let requests = this.selectedRecords
      .map(x => this.invoiceService.postNote(x, note).toPromise());

    Promise.all(requests)
      .then(x => {
        this.dialog.close(x);
      })
      .catch(err => {
        this.dialog.close();
      });
  }

  onCancel() {
    this.dialog.close();
  }
}
