import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @ViewChild(AlertMessageComponent, { static: false })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  spinner: SpinnerComponent;

  alertSubscription: Subscription;
  spinnerSubscription: Subscription;
  destroy$ = new Subject();

  constructor(
    private notifications: NotificationService
  ) { }

  ngOnInit() {
    this.alertSubscription = this.notifications.alertMessages
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.alertMessage.showAlertMessage(x.message, x.type);
      });

    this.spinnerSubscription = this.notifications.isLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.spinner.loading = x;
      });
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
    this.spinnerSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
