import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ShipmentInvoiceAuditUI } from '../../pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import { GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { FormGroup } from '@angular/forms';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import ui = ShipmentInvoiceAuditUI;

@Component({
  selector: 'app-audit-queue-notes-list',
  templateUrl: './audit-queue-notes-list.component.html',
  styleUrls: ['./audit-queue-notes-list.component.scss']
})
export class AuditQueueNotesListComponent implements OnInit {
  get theRecords(): ui.AuditNote[] {
    return this.recordBookGrid;
  }

  @Input('loading')
  loading = false;

  @Input('noRecordsMessage')
  noRecordsMessage = 'No records available.';

  @Input('allowReopen')
  allowReopen = false;

  @Input('currentInvoiceNumber')
  currentInvoiceNumber = '';

  @Input('theRecords')
  set theRecords(value: ui.AuditNote[]) {
    this.recordBookGrid = value;
    if (value != null) {
      this.setRecords();
    }
  }

  @Output()
  insert = new EventEmitter<ui.AuditNote>();

  @Output()
  reopen = new EventEmitter<ui.AuditNote>();

  @Output()
  invoiceAuditId = new EventEmitter<number>();

  sort: SortDescriptor[] = [
    {
      field: 'date',
      dir: 'desc'
    }
  ];

  gridView: GridDataResult;
  references: string[];
  isCompleted = false;
  isReopen = false;
  recordBookGrid: ui.AuditNote[] = [];

  constructor() { }

  ngOnInit() { }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  // page events
  protected onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
  }

  protected onReOpen(dataItem) {
    this.isReopen = true;
    this.reopen.emit(dataItem);
  }

  // helper functons
  private setIsComplete() {
    this.isCompleted = false;
    if (this.theRecords.length > 0 && this.theRecords[0].noteStatus === 'Complete') {
      this.isCompleted = true;
    }
  }
  private setRecords() {
    this.setIsComplete();
    this.gridView = {
      data: orderBy(this.theRecords, this.sort),
      total: this.theRecords.length
    };
  }

  deleteQueueNotes(id: number): void {
    this.invoiceAuditId.emit(id);
  }
}
