import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditUserResponse } from '@/services/InvoiceAudit';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { AuthService } from '@/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { LoadTLAuditorListOld, RemoveAuditorFromAuditOld, AddAuditorToAuditOld } from '../../actions/tl-audit-detail-old.actions';

@Component({
  selector: 'app-tl-assign-auditor-old',
  templateUrl: './tl-assign-auditor-old.component.html',
  styleUrls: ['./tl-assign-auditor-old.component.scss'],
})
export class TlAssignAuditorComponentOld implements OnInit {
  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  selectedAuditor: { fullName: string; id: number } = { fullName: '', id: 0 };

  theCurrentAuditor = '';
  theTLAuditID: number;
  auditorList: AuditUserResponse[];
  disableAssignBtn = false;
  disableRemoveBtn = false;
  constructor(private authService: AuthService, private store: Store<AppState>) { }

  ngOnInit() {
    this.setListeners();
    this.store.dispatch(new LoadTLAuditorListOld());
  }

  protected clickAssignToMe() {
    // find the user and call the event
    this.selectedAuditor = this.auditorList.filter((obj) => obj.fullName === this.authService.BlueShipUser.name)[0];

    // did we find a user?
    if (this.selectedAuditor == null) {
      this.alertMessage.showAlertMessage('You are not found in the Auditor list', 'Error');
      return;
    }

    this.onAuditorChanged(this.selectedAuditor);
  }

  protected onAuditorChanged(newValue: any) {
    this.theCurrentAuditor = newValue.fullName;
    this.store.dispatch(new AddAuditorToAuditOld(this.theTLAuditID, newValue.fullName));
  }
  protected onRemoveAuditor(event) {
    this.theCurrentAuditor = '';
    this.selectedAuditor = { fullName: '', id: 0 };
    this.store.dispatch(new RemoveAuditorFromAuditOld(this.theTLAuditID));
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailOldState.tlAuditors)
      .subscribe((x) => {
        if (x) {
          this.auditorList = x;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.loadingTLAuditError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Loading Auditors failed', 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.savedAuditorChangesError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Auditor update failed', 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.savedAuditorChangesSuccess)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Auditor update success', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.selectedAuditor)
      .subscribe((x) => {
        if (x) {
          this.theCurrentAuditor = x;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.tlAuditID)
      .subscribe((x) => {
        if (x) {
          this.theTLAuditID = x;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.assignAuditorSaveInProgress)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Saving....');
        } else {
          this.showSpinner(false);
        }
        this.disableRemoveBtn = x;
        this.disableAssignBtn = x;
      });
  }

  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }
}
