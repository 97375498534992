export class CustomerProfile {
  customerProfileID: number;
  enterpriseID: number;
  accountName: string;
  accountNumber: string;
  proactiveAuditNumber: string;
  isProactive: boolean;
  isProactiveString: string;
  proactiveStartDate: Date;
  proactiveEndDate: Date;
  notificationVariance: number;
  notificationType: string;
  consolidatedCustomReport: string;
  edmName: string;
  edmEmail: string;
  esrName: string;
  esrEmail: string;
  outboundShipments: boolean;
  notifyByAccountLocation: boolean;
  allSettlementReasons: boolean;
  proactiveNotes: string;
  settlementReasons: Array<string> = [];
  documentTypes: Array<string> = [];
  accountContact: string;
  contactName?: string;
  positiveVariance: number;
  negativeVariance: number;
  isSpecializedAudit: boolean;
  isExceptionCustomer: boolean;
  isExcludeFromAuditAutomation: boolean;
  contacts: Array<Contact> = [];
  public constructor() { }
}

export class CustomerProfileRefDataResponse {
  notificationTypes: string[];
  documentTypes: string[];
  settlementReasons: string[];
}

export class Contact {
  name: string;
  email: string;
}

export class UpdateCustomerProfile {
  documentTypes: Array<string> = [];
  settlementReasons: Array<string> = [];
  allSettlementReasons: boolean;
  proactiveNotes: string;
  isProactive: boolean;
  proactiveAuditNumber: string;
  proactiveStartDate: Date;
  proactiveEndDate: Date;
  notificationVariance: number;
  consolidatedCustomReport: string;
  contacts: Contact[];
  edmName: string;
  edmEmail: string;
  esrName: string;
  esrEmail: string;
  notificationType: string;
  accountContact: string;
  positiveVariance: Number;
  negativeVariance: Number;
  isSpecializedAudit: boolean;
  isExceptionCustomer: boolean;
}

export class CreateCustomerProfile extends UpdateCustomerProfile {
  enterpriseID: number;
  accountName: string;
  accountNumber: string;
}
