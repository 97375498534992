import { AuditCarrierInvoiceUI } from '@/pages/audit/AuditCarrierInvoice.ui';
import { Enterprise } from '@/services/Enterprise';
import { InvoiceCharge } from '@/services/InvoiceAudit';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Globals } from '@/_shared/globals';
import { Helpers } from '@/_shared/helpers';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { aggregateBy, process, State } from '@progress/kendo-data-query';
import { ShipmentUI } from '../../pages/audit/Shipment.ui';
import ui = AuditCarrierInvoiceUI;


const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

function trimUpper(str: string | null): string {
  return (str || '').trim().toUpperCase();
}

@Component({
  selector: 'app-audit-carrier-update-list',
  templateUrl: './audit-carrier-update-list.component.html',
  styleUrls: ['./audit-carrier-update-list.component.scss'],
})
export class AuditCarrierUpdateListComponent implements OnInit {
  @ViewChild('chargesGrid', { static: false })
  private recordGrid: GridComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }

  @Input('quoteTotal')
  set quoteTotal(value: number) {
    this.theQuoteTotal = value;
  }

  invoiceInput: ShipmentUI.Shipment;
  @Input('invoice')
  set invoice(invoice: ShipmentUI.Shipment) {
    this.invoiceInput = invoice;
    this.distance = 0;
    if ((this.invoiceInput.charges || []).length > 0) {
      this.records = this.invoiceInput.charges;
      this.setRecords(true);
    }
  }

  @Input('allowAdd')
  set allowAdd(value: boolean) {
    this.canAdd = value;
  }

  @Input('allowSave')
  allowSave: boolean;

  @Input() conversion: number;

  @Input('showSave')
  showSave: boolean;

  @Input('readonly')
  readonly = false;

  @Input('title')
  title = 'Charges';

  @Input('toggle')
  showToggle: boolean;

  @Input('hideEditDialogButton')
  hideEditDialogButton: boolean = false;

  @Input('deleteRules')
  deleteRules = [
    {
      group: 'LINEHAUL',
      types: [],
      min: 1,
    },
  ];

  @Input() enterprise: Enterprise;

  @Output()
  invoiceChargesChanged = new EventEmitter<InvoiceCharge[]>();

  @Output()
  invoiceTotalChanged = new EventEmitter<number>();

  @Output()
  reset = new EventEmitter<void>();

  @Output()
  save = new EventEmitter<InvoiceCharge[]>();

  @Output()
  opened = new EventEmitter<boolean>();

  @Output()
  editInProgress = new EventEmitter<boolean>();

  @Output()
  onEditCharges = new EventEmitter();

  get controls() {
    return this.newChargeFormGroup.controls;
  }

  get hideGrid(): boolean {
    return this.showToggle && !this.toggled;
  }

  get showAdd(): boolean {
    return !this.readonly && !!this.newChargeFormGroup && this.toggled;
  }

  get toggleTitle(): string {
    return `${this.toggled ? 'Hide' : 'Show'} ${this.title}`;
  }

  // sent in
  protected distance: number;
  protected toggled: boolean;
  canAdd: boolean;
  isDataLoading: boolean;
  theQuoteTotal: number;
  newChargeFormGroup: FormGroup;

  records: InvoiceCharge[] = [];
  selectableSettings: SelectableSettings;

  helpers: Helpers;

  editedRowIndex = -1;
  invoiceChargeID = -1;
  gridTotal = 0;
  lastGroup = '';

  public formGroup: FormGroup = null;

  descriptions = Globals.Descriptions;
  freightClasses = Globals.FreightClasses;
  groupTypes = Globals.GroupTypes;
  qualifiers = Globals.Qualifiers;
  defaultGroupType = {
    group: 'Accessorial',
    displayName: 'Accessorial',
    type: 'ACCESSORIAL',
  };

  constructor(private formBuilder: FormBuilder) {}

  public aggregates: any[] = [{ field: 'amount', aggregate: 'sum' }];
  public state: State = {
    skip: 0,
    take: 50,
    sort: [
      {
        field: 'quanity',
        dir: 'desc',
      },
    ],
    group: [{ field: 'group', aggregates: this.aggregates }],
  };

  public gridData: any = process(this.records, this.state);
  public total: any = aggregateBy(this.records, this.aggregates);

  ngOnInit() {
    // create the form
    this.createForm();
    // default toggled to true if there is no toggler
    this.toggled = !this.showToggle;
  }

  // grid events
  public hideRemoveBtn(item: InvoiceCharge): boolean {
    const itemGroup = trimUpper(item.group);
    const itemType = trimUpper(item.type);
    const hasBrokenRule = this.deleteRules.some((rule) => {
      const ruleGroup = trimUpper(rule.group);
      const ruleTypes = (rule.types || []).map((type: string) => trimUpper(type));
      // if Rule has NO types then ONLY match on Group
      const disregardRuleType = ruleTypes.length === 0;
      if (itemGroup === ruleGroup && (disregardRuleType || ruleTypes.some((ruleType) => ruleType === itemType))) {
        const groupRecords = this.records.filter((record) => {
          const recordGroup = trimUpper(record.group);
          return recordGroup === ruleGroup;
        });
        const matchedRuleRecordCount = groupRecords.reduce((count: number, record) => {
          const recordType = trimUpper(record.type);
          if (disregardRuleType || ruleTypes.some((ruleType) => ruleType === recordType)) {
            ++count;
          }
          return count;
        }, 0);
        return matchedRuleRecordCount <= rule.min;
      }
      return false;
    });
    return hasBrokenRule;
  }
  public onEditCharge({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.editInProgress.emit(true);
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.recordGrid.editRow(rowIndex, this.formGroup);
  }
  public onRemoveCharge(event) {
    // remove the record from the dataset
    this.records = this.records.filter((obj) => obj !== event.dataItem);
    this.gridData = process(this.records, this.state);
    this.calculateTotal();
    this.invoiceChargesChanged.emit(this.records);
  }
  public cancelUpdateCharge({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
    this.editInProgress.emit(false);
  }
  public saveChargeHandler({ sender, rowIndex }) {
    // is this really a fuel?
    let type = this.formGroup.value.type;
    if (this.formGroup.value.description === 'Fuel Surcharge' && this.formGroup.value.group === 'Accessorial') {
      type = 'ACCESSORIAL_FUEL';
    }

    // cant have two fuels
    if (this.isMoreThanOneFuel(this.formGroup.value.group, type, +this.formGroup.value.invoiceChargeID)) {
      return false;
    }

    // update the record
    const record = this.records.find(({ invoiceChargeID }) => invoiceChargeID === this.formGroup.value.invoiceChargeID);
    Object.assign(record, this.formGroup.value);

    // set the correct qualifer and type (in case this is really been changed to a fuel)
    record.rateQualifier = this.getQualifierDescription(record.rateCode);
    record.type = type;

    // run through the rules and refresh the grid
    this.runLineItemRules(record);
    this.gridData = process(this.records, this.state);

    // tell the parent that the record has been added to the grid.
    this.calculateTotal();
    this.invoiceChargesChanged.emit(this.records);
    this.editInProgress.emit(false);
    sender.closeRow(rowIndex);
  }
  protected canEditLinehaulDescription(dataItem: any): boolean {
    return dataItem.type === 'DISCOUNT' ? true : false;
  }
  protected canEditAccessorialDescription(dataItem: any): boolean {
    return dataItem.type === 'ACCESSORIAL_FUEL' ? true : false;
  }
  protected groupSelectionChange(value: any): void {
    if (value.group === ' Linehaul' && this.lastGroup !== ' Linehaul') {
      this.controls.description.setValue('');
      this.controls.description.markAsDirty();
    } else if (value.group === 'Accessorial' && this.lastGroup === ' Linehaul') {
      this.controls.description.setValue('Airport Delivery');
    }
    this.controls.group.setValue(value.group);
    this.controls.type.setValue(value.type);
    this.lastGroup = value.group;
  }
  // Page events
  protected insertNewCharge() {
    // create the group name
    const groupName = this.controls.group.value === 'Linehaul' ? ' Linehaul' : this.controls.group.value;

    // is this really a fuel?
    let type = this.controls.type.value;
    if (this.controls.description.value === 'Fuel Surcharge' && groupName === 'Accessorial') {
      type = 'ACCESSORIAL_FUEL';
    }

    // cannot have more than one ACCESSORIAL_FUEL charge
    if (this.isMoreThanOneFuel(groupName, type)) {
      return;
    }

    const ediMatch = Globals.ChargeDescriptions.find(x => x.name === this.controls.description.value);

    const newRecord = {
      invoiceChargeID: this.invoiceChargeID--,
      description: this.controls.description.value,
      freightClass: this.controls.freightClass.value,
      fakFreightClass: this.controls.fakFreightClass.value,
      rate: this.controls.rate.value,
      rateQualifier: this.controls.rateQualifier.value,
      rateCode: this.getQualifierCode(this.controls.rateQualifier.value),
      quantity: this.controls.quantity.value,
      group: this.controls.group.value,
      type: type,
      amount: 0,
      weight: 0,
      isMin: false,
      isMax: false,
      dimWeight: 0,
      ediCode: ediMatch ? ediMatch.billingCode : '',
      canEdit: true,
      Group: groupName,
    };

    // run through the rules and refresh the grid
    this.records.push(newRecord);
    this.runLineItemRules(newRecord);

    // add to the list
    this.gridData = process(this.records, this.state);
    this.calculateTotal();
    this.invoiceChargesChanged.emit(this.records);
  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }
  public updateDistanceCharges(distance: number) {
    this.records.forEach((record) => {
      if (record.rateCode === 'PM') {
        record.quantity = distance;
        record.amount = record.rate * record.quantity;
      }
    });
    this.setRecords();
  }

  // forms
  private createForm() {
    this.newChargeFormGroup = this.formBuilder.group({
      invoiceChargeID: [this.invoiceChargeID],
      description: ['Airport Delivery', [Validators.required]],
      freightClass: [0, [Validators.required]],
      fakFreightClass: [0, [Validators.required]],
      rate: [0.0, [Validators.required]],
      rateQualifier: ['Flat Rate (FR)', [Validators.required]],
      rateCode: ['FR', [Validators.required]],
      quantity: [0.0, [Validators.required]],
      type: ['Accessorial'],
      amount: [0],
      group: ['Accessorial'],
    });
  }

  // helpers
  private isMoreThanOneFuel(group: string, type: string, invoiceChargeId?: number): boolean {
    if (
      group === 'Accessorial' &&
      type === 'ACCESSORIAL_FUEL' &&
      this.records.some((charge) => charge.type === 'ACCESSORIAL_FUEL' && invoiceChargeId !== +charge.invoiceChargeID)
    ) {
      this.alertMessage.showAlertMessage('Can only have one Fuel Surcharge', 'Error');
      return true;
    }
    return false;
  }
  public disableQuantity(form: boolean): boolean {
    // get the correct values
    let rateCode = '';
    let groupType = '';
    if (form) {
      rateCode = this.formGroup.get('rateCode').value;
      groupType = this.formGroup.get('type').value;
    } else {
      rateCode = this.getQualifierCode(this.controls.rateQualifier.value);
      groupType = this.controls.type.value;
    }

    // disable the controls
    if (
      rateCode === 'FR' ||
      rateCode === 'MN' ||
      rateCode === 'PM' ||
      (rateCode === 'PCT' && groupType === 'DISCOUNT') ||
      (rateCode === 'PCT' && groupType === 'ACCESSORIAL_FUEL')
    ) {
      // zero out the quantity
      if (form) {
        this.formGroup.get('quantity').setValue(0);
      } else {
        this.controls.quantity.setValue(0);
      }
      return true;
    }
    return false;
  }
  private setRecords(firstTime: boolean = false): void {
    this.gridData = process(this.records, this.state);
    if (!firstTime) {
      this.invoiceChargesChanged.emit(this.records);
    }
    this.calculateTotal();
  }
  private getQualifierDescription(rateCode: string): string {
    const record = this.qualifiers.find((obj) => {
      return obj.rateCode === rateCode;
    });
    return record.rateQualifier;
  }
  private getQualifierCode(rateDescription: string): string {
    const record = this.qualifiers.find((obj) => {
      return obj.rateQualifier === rateDescription;
    });
    return record.rateCode;
  }
  private runLineItemRules(record: any) {
    // check for distance
    if (record.rateCode === 'PM' && this.distance !== 0) {
      record.quantity = this.distance;
    }

    // rate codes
    if (record.rateCode === 'FR' || record.rateCode === 'MN') {
      record.quantity = 0;
      record.amount = record.rate;
    } else if (record.description.match('^Fuel') != null && record.rateCode === 'PCT') {
      if (record.quantity > 0) {
        record.amount = (record.rate / 100) * record.quantity;
      }
    } else if ((record.rateCode === 'CW') != null && record.rateCode === 'PH') {
      record.amount = record.rate * record.quantity;
    } else if (record.rateCode === 'PCT') {
      record.amount = (record.rate / 100) * record.quantity;
    } else if (record.rateCode === 'PM') {
      record.quantity = this.distance;
      record.amount = record.rate * record.quantity;
    } else {
      record.amount = record.rate * record.quantity;
    }

    this.processTotals();
  }
  private processTotals() {
    this.updateDiscountRecord();
    this.updateFuelRecord();
  }
  private updateDiscountRecord() {
    // get the last item that is a discount
    let discountRecord = null;
    let index = this.records.length - 1;
    for (; index >= 0; index--) {
      if (this.records[index].type === 'DISCOUNT') {
        discountRecord = this.records[index];
        break;
      }
    }

    if (discountRecord === null || discountRecord.rateCode !== 'PCT') {
      return;
    }

    // get all linehaul items
    const lineHaulItems = this.records.filter(function (e) {
      return e.group === ' Linehaul';
    });

    let tempNoDiscountSum = 0;
    for (index = 0; index < lineHaulItems.length; index++) {
      if (lineHaulItems[index].type !== 'DISCOUNT' && lineHaulItems[index].type !== 'MG_MINMAX_ADJ') {
        tempNoDiscountSum = tempNoDiscountSum + lineHaulItems[index].amount;
      }
    }

    // set the new discount amount
    discountRecord.amount = -1 * tempNoDiscountSum * (discountRecord.rate / 100);
  }
  private updateFuelRecord() {
    // get the last item that is a fuel
    let fuelRecord = null;
    let index = this.records.length - 1;
    for (; index >= 0; index--) {
      if (this.records[index].description.match('^Fuel') != null) {
        fuelRecord = this.records[index];
        break;
      }
    }

    if (fuelRecord !== null && fuelRecord.rateCode === 'PCT') {
      fuelRecord.amount = (fuelRecord.rate / 100) * this.getFreightTotal();
    }
  }
  private numberWithCommas(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private calculateTotal() {
    this.gridTotal = 0;
    this.records.forEach((element) => {
      this.gridTotal += Math.round(element.amount * 100) / 100;
    });
    this.gridTotal = Math.round(this.gridTotal * 100) / 100;
    this.invoiceTotalChanged.emit(this.gridTotal);
  }
  private getChargeTypeTotal(type: string): number {
    let total = 0;
    this.records.forEach((element) => {
      if (element.group.trim() === type) {
        total += element.amount;
      }
    });
    return total;
  }
  private getChargeTypeCharges(type: string): InvoiceCharge[] {
    return this.records.filter(function (element) {
      return element.group.trim() === type;
    });
  }

  protected toggleGrid(e: MouseEvent): void {
    e.preventDefault();
    this.toggled = !this.toggled;
    this.opened.emit(this.toggled);
  }

  // external functions
  public getFreightTotal(): number {
    return +this.getChargeTypeTotal('Linehaul').toFixed(2);
  }
  public getServiceTotal(): number {
    return +this.getChargeTypeTotal('Accessorial').toFixed(2);
  }
  public getTotal(): number {
    return this.getFreightTotal() + this.getServiceTotal();
  }
  public getCharges(): InvoiceCharge[] {
    return this.records;
  }
  public getFreightCharges(): InvoiceCharge[] {
    return this.getChargeTypeCharges('Linehaul');
  }
  public getServiceCharges(): InvoiceCharge[] {
    return this.getChargeTypeCharges('Accessorial');
  }
  public refresh(charges: InvoiceCharge[]) {
    this.records = charges;
    this.setRecords();
  }
  protected cancel(e: MouseEvent): void {
    e.preventDefault();
    this.reset.emit();
    this.toggleGrid(e);
  }
  protected onSave(e: MouseEvent): void {
    e.preventDefault();
    this.save.emit(this.records);
  }

  createFormGroup = (dataItem) =>
    new FormGroup({
      invoiceChargeID: new FormControl(dataItem.invoiceChargeID),
      ediCode: new FormControl(dataItem.ediCode),
      weight: new FormControl(dataItem.weight),
      isMin: new FormControl(dataItem.isMin),
      isMax: new FormControl(dataItem.isMax),
      dimWeight: new FormControl(dataItem.dimWeight),
      type: new FormControl(dataItem.type),
      group: new FormControl(dataItem.group),
      description: new FormControl(dataItem.description, Validators.required),
      freightClass: new FormControl(dataItem.freightClass, Validators.required),
      fakFreightClass: new FormControl(dataItem.fakFreightClass, Validators.required),
      rate: new FormControl(dataItem.rate === 0 ? dataItem.amount : dataItem.rate, Validators.required),
      rateQualifier: new FormControl(dataItem.rateQualifier, Validators.required),
      rateCode: new FormControl(dataItem.rateCode, Validators.required),
      quantity: new FormControl(dataItem.quantity, Validators.required),
    });

  public editCustomerCharges() {
    this.onEditCharges.emit();
  }

  public rateQualifierChange(value: string, formGroup: FormGroup) {
    const qualifier = Globals.Qualifiers.find((q) => q.rateCode === value);
    formGroup.patchValue({ rateQualifier: qualifier.rateQualifier });
  }
}
