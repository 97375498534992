export class ImageDetailPending {
  imageDetailPendingID: number;
  pro: string;
  primaryReference: string;
  isError: boolean;
  errorMessage: string;
  imageName: string;
  imageType: string;
  imageBinary: string;
  isSaveAndStore: boolean;
  source: string;
}

export class ImageDetailPendingMergeRequest {
  pro?: string;
  primaryReference?: string;
  isError?: boolean;
  errorMessage?: string;
  imageName?: string;
  imageType?: string;
  imageBinary?: string;
  isSaveAndStore?: boolean;
}

export class ImageDetailPendingDeleteManyRequest {
  imageDetailPendingIDs: number[];
}

export class ImageDetailPendingResetManyRequest {
  imageDetailPendingIDs: number[];
}

export class ImageDetailPendingSaveAndStoreManyRequest {
  imageDetailPendingIDs: number[];
}

export class ImageDetailPendingSearchResponse {
  totalCount: number;
  imageDetailPendings: ImageDetailPending[];
}
