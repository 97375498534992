import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { StartupData } from '@/models/StartupData';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserAuthenticationSuccess } from './actions/authentication.actions';
import { AuthOperation } from './AuthOperation';

const port = window.location.port && window.location.port !== '80' ? `:${window.location.port}` : '';
const baseUrl = `${location.protocol}//${location.hostname}${port}`;

export function getClientSettings(authApiURL: string): UserManagerSettings {
  return {
    authority: authApiURL,
    client_id: 'finance',
    redirect_uri: `${baseUrl}/auth-callback`,
    post_logout_redirect_uri: baseUrl,
    response_type: 'id_token token',
    scope: 'openid profile finance-api carrier-api',
    silent_redirect_uri: `${baseUrl}/silent-refresh.html`,
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true
  };
}

@Injectable()
export class AuthService {
  private manager;
  private _tokenTime: any;
  private _accessTime: any;
  private _startupData: StartupData;

  constructor(private store: Store<AppState>, private router: Router, private http: HttpClient) {
    this.store
      .select((x) => x.StartupData)
      .subscribe((x) => {
        this._startupData = x;
        this.manager = new UserManager(getClientSettings(x.authAPIUrl));
        this.manager.getUser().then(
          (user) => {
            this.authUser = user;
            if (this.authUser !== null) {
              this.store.dispatch(new UserAuthenticationSuccess(this.authUser, this._startupData));
            }
          },
          (err) => {
            console.log('manager.getUser failed', err);
          }
        );
      });

    const _local = this;
    this.manager.events.addAccessTokenExpiring(() => {
      _local.updateToken();
    });

    this.manager.events.addAccessTokenExpired(() => {
      _local.logout();
    });

    this.manager.events.addUserSignedOut(() => {
      this.manager.removeUser();
      this.authUser = null;
      this.startAuthentication();
    });

    if (!sessionStorage.getItem('access_time')) {
      this.setAccessTime();
    } else {
      this._accessTime = new Date(JSON.parse(sessionStorage.getItem('access_time')));
    }
  }

  get isLoggedIn(): boolean {
    const now = Math.round(Date.now() / 1000);
    return this.authUser && this.authUser.expires_at > now;
  }

  getClaims(): any {
    return this.authUser.profile;
  }

  getAuthorizationHeaderValue(): string {
    return this.authUser ? `${this.authUser.token_type} ${this.authUser.access_token}` : '';
  }

  startAuthentication(returnUrl?: string): void {
    this.manager.signinRedirect({ state: { returnUrl: returnUrl } }).then(
      () => { },
      (err) => {
        console.log('signinRedirect failed', err);
      }
    );
  }

  completeAuthentication(): void {
    this.manager.signinRedirectCallback().then(
      (user) => {
        this.authUser = user;
        this.store.dispatch(new UserAuthenticationSuccess(this.authUser, this._startupData));
        const returnUrl = user && user.state ? user.state.returnUrl : null;
        // are there query params
        this.getUserDetails().subscribe(
          (resp) => {
            if (!resp) {
              alert('no user exists in db');
            } else {
              this.BlueShipUser = {
                userName: resp.userName,
                userID: resp.userID,
                enterpriseID: resp.enterpriseID,
                enterpriseName: resp.enterpriseName,
                name: `${resp.firstName} ${resp.lastName}`,
                email: resp.email,
                operations: resp.operations,
                accountNumber: resp.accountNumber,
              };

              if (returnUrl && returnUrl.includes('?')) {
                this.router.navigateByUrl(decodeURI(returnUrl), {
                  queryParamsHandling: 'preserve',
                  preserveFragment: true,
                });
              } else {
                this.router.navigate([returnUrl || '/'], { queryParamsHandling: 'preserve', preserveFragment: true, replaceUrl: true });
              }
            }
          },
          (err) => {
            console.log('Unable to fetch user details', err);
            // todo, we may not want to log out here, but instead handle this in a different way?
            this.logout();
          }
        );
      },
      (err) => {
        console.log('completeAuthentication failed', err);
        // todo, handle error
      }
    );
  }

  getUserDetails(): Observable<any> {
    return this.http.get<any>(`${this._startupData.financeAPIUrl}v2/user`).pipe(retry(2));
  }

  get authority(): string {
    return this.manager.settings.authority;
  }

  public get BlueShipUser(): BlueShip.User {
    const userString = sessionStorage.getItem('BlueShipUser');
    if (userString) {
      const u = {} as BlueShip.User;
      const u2: BlueShip.User = Object.assign(u, JSON.parse(userString));

      return u2;
    } else {
      return null;
    }
  }

  public set BlueShipUser(value: BlueShip.User) {
    if (value) {
      sessionStorage.setItem('BlueShipUser', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('BlueShipUser');
    }
  }

  public set authUser(value: User) {
    if (value) {
      const tokenTime = new Date(0);
      tokenTime.setUTCSeconds(value.expires_at);
      this._tokenTime = tokenTime;
      sessionStorage.setItem('user', value.toStorageString());
    } else {
      sessionStorage.removeItem('user');
    }
  }

  public get authUser(): User {
    // todo, using sessionStorage is currently only because auth-guard runs before user is retreived
    // There's a better way to do this.
    const userString = sessionStorage.getItem('user');

    if (userString) {
      return <User>Object.assign({}, JSON.parse(userString));
    } else {
      return null;
    }
  }

  logout() {
    this.manager
      .signoutRedirect({
        post_logout_redirect_uri: baseUrl,
      })
      .then(
        () => {
          this.authUser = null;
          this.BlueShipUser = null;
        },
        (err) => {
          console.log('signoutRedirect failed', err);
        }
      );
    // .catch() ?
  }

  setAccessTime() {
    const accessTime = new Date();
    accessTime.setUTCHours(accessTime.getUTCHours() + 6);
    this._accessTime = accessTime;
    sessionStorage.setItem('access_time', JSON.stringify(this._accessTime));
  }

  updateToken() {
    if (this._tokenTime < this._accessTime) {
      this.manager
        .signinSilent()
        .then((user) => {
          this.authUser = user;
          const date = new Date(0);
          date.setUTCSeconds(this.authUser.expires_at);
          this._tokenTime = date;
        })
        .catch(() => {
          this.manager.getUser().then((user) => {
            this.authUser = user;
            const date = new Date(0);
            date.setUTCSeconds(this.authUser.expires_at);
            this._tokenTime = date;
          });
        });
    }
  }

  public setHttpHeaders(headers: HttpHeaders): HttpHeaders {
    return headers
      .append('EnterpriseID', this.BlueShipUser && this.BlueShipUser.enterpriseID ? this.BlueShipUser.enterpriseID.toString() : null)
      .append('UserID', this.BlueShipUser && this.BlueShipUser.userID ? this.BlueShipUser.userID.toString() : null)
      .append('UserName', this.BlueShipUser ? 'FinanceWeb' : 'FinanceWeb');
  }

  hasOperation(operation: AuthOperation | string): boolean {
    return this.BlueShipUser && this.BlueShipUser.operations.includes(operation);
  }

}
