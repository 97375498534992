import { Component, OnInit, ViewChildren, ViewChild, QueryList, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { CustomerService } from '../../services/customer.service';
import { EnterpriseChildDTO } from '../../services/Collection';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Helpers } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { CustomerProfileListComponent } from '../../components/customer-profile-list/customer-profile-list.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { CustomerProfile } from '@/models/CustomerProfile';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-proactivecollection',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
})
export class CustomerProfileComponent implements OnInit, AfterViewInit {
  @ViewChildren('enterpriseautocompleteProactive')
  public enterpriseAutoComplete: QueryList<AutoCompleteComponent>;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild('proactiveGrid', { static: false })
  proactiveGrid: CustomerProfileListComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  GridData: any[];
  enterprises: EnterpriseChildDTO[] = null;
  enterpriseData: Array<{
    enterpriseID: number;
    name: string;
    accountNumber: string;
    fullName: string;
  }>;
  theEnterpriseAutoComplete: AutoCompleteComponent;
  selectedEnterprise: EnterpriseChildDTO;
  recordCount = 0;
  selectedId: number;
  deleteOpened: boolean;
  deleteCustomerProfie: CustomerProfile = null;
  lookupDataItem: any;
  createPopupText = 'Please confirm you wish to create a customer profile for';
  canCreateEnterprise: boolean;
  isDataLoading = true;
  helpers: Helpers;
  enterpriseText: String;

  constructor(private pcService: CustomerService, private router: Router, private authService: AuthService) {
    this.enterpriseData = [];
    this.GridData = new Array<any>();
  }
  ngOnInit() {
    this.selectedId = 0;
    this.appSpinner.loading = true;
    this.pcService
      .getCustomerProfiles()
      .pipe(
        map((data) => {
          return data.map((row) => {
            row.isProactiveString = row.isProactive === false ? 'No' : 'Yes';
            return row;
          });
        })
      )
      .subscribe(
        (res: any[]) => {
          this.GridData = res;
          this.recordCount = res.length;
          this.isDataLoading = false;
          this.appSpinner.loading = false;
        },
        (err) => {
          this.isDataLoading = false;
          this.appSpinner.loading = false;
          if (err.statusText !== 'Not Found') {
            this.alertMessage.showAlertMessage('Error loading the Customer Profiles', 'Error');
          }
        }
      );

    this.helpers = new Helpers(this.authService);
  }

  ngAfterViewInit() {
    this.enterpriseAutoComplete.length === 1 && (this.theEnterpriseAutoComplete = this.enterpriseAutoComplete.last);
  }

  private getEnterprise(filter) {
    this.pcService
      .getEnterprises(filter)
      .pipe(
        map((data) => {
          return data.map((row) => {
            row.fullName = row.name + ' - ' + row.accountNumber;
            return row;
          });
        })
      )
      .subscribe((ep) => {
        ep.sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.enterprises = ep;
        this.enterpriseData = ep;
      });
  }

  protected handleEnterpriseValueChange(value) {
    const selectedEnterprise = this.enterprises.filter((s) => s.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    selectedEnterprise.length === 1 && (this.selectedEnterprise = selectedEnterprise[0]);
  }

  public handleEnterpriseFilterChange(filter: any): void {
    filter.trim();
    filter.length >= 3 ? this.getEnterprise(filter) : (this.enterprises = []);
    this.enterpriseData = [];
    this.selectedEnterprise = null;
  }

  protected viewDetailsExists() {
    this.router.navigate([`/customer-profile-details/${this.lookupDataItem.customerProfileID}`], this.lookupDataItem);
  }

  confirmCreateNewEnterprise() {
    const canCreate = this.GridData.filter((enterprise) => enterprise.enterpriseID === this.selectedEnterprise.enterpriseID);
    canCreate.length === 0 ? (this.canCreateEnterprise = true) : ((this.canCreateEnterprise = false), (this.lookupDataItem = canCreate[0]));
    this.doesEnterpriseExist();
  }

  doesEnterpriseExist() {
    const doesExistAlready = this.GridData.filter((enterprise) => enterprise.enterpriseID === this.selectedEnterprise.enterpriseID);
    doesExistAlready.length > 0
      ? this.alertMessage.showAlertMessage('Enterprise ' + this.selectedEnterprise.name + ' already exists.', 'Error')
      : this.createNewEnterprise();
  }

  createNewEnterprise() {
    this.router.navigate([`/customer-profile-details/${0}`], {
      queryParams: {
        enterpriseId: this.selectedEnterprise.enterpriseID,
        name: this.selectedEnterprise.name,
        account: this.selectedEnterprise.accountNumber,
      },
    });
  }

  protected removeDetails(dataItem: CustomerProfile) {
    this.deleteCustomerProfie = dataItem;
    this.deleteOpened = true;
  }

  closeConfirm() {
    this.deleteOpened = false;
  }

  onDeleteCustomerProfile() {
    this.deleteOpened = false;
    this.onRemoveCustomerProfile();
  }

  public updateGrid() {
    const count = this.recordCount - 1;
    this.recordCount = count;

    const index = this.GridData.findIndex((i) => i.customerProfileID === this.deleteCustomerProfie.customerProfileID);

    this.GridData.splice(
      this.GridData.findIndex((i) => i.customerProfileID === this.deleteCustomerProfie.customerProfileID),
      1
    );
    this.proactiveGrid.refreshGrid();
  }

  public onRemoveCustomerProfile() {
    this.appSpinner.loading = true;
    this.pcService.deleteCustomerProfile(this.deleteCustomerProfie.customerProfileID).subscribe(
      (data) => {
        this.alertMessage.showAlertMessage('Customer Profile Successfully Deleted.', 'Success');
        this.updateGrid();
        this.appSpinner.loading = false;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Deleting Customer Profile', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }
  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }
}
