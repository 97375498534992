import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { CollectionDocument } from '../../services/Collection';

@Component({
  selector: 'app-collection-invoice-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './collection-invoice-list.component.html',
  styleUrls: ['./collection-invoice-list.component.scss']
})
export class CollectionInvoiceListComponent implements OnInit {
  @Input()
  theRecords: CollectionDocument[];

  @Input()
  loadingInvoices: boolean;

  @Output()
  download = new EventEmitter<CollectionDocument>();

  @Output()
  reimport = new EventEmitter();

  ngOnInit() { }

  // events
  public onReImportInvoices() {
    this.theRecords = [];
    this.reimport.emit();
  }
  public downloadDocument(dataItem: CollectionDocument) {
    this.download.emit(dataItem);
  }
}
