export class FilterFactory {
  customer: CustomerFilter;
  quicktariff: QuickTariffFilter;
  bg: BGFilter;
  nonproactive: NonProactiveFilter;
  proactive: ProactiveFilter;
  return: ReturnFilter;
  secondary: SecondaryFilter;
  document: DocumentFilter;
  carrier: CarrierFilter;
  queue: string;
  search: string;
  obj: any;

  constructor() {}

  public init() {
    this.customer = new CustomerFilter();
    this.quicktariff = new QuickTariffFilter();
    this.bg = new BGFilter();
    this.nonproactive = new NonProactiveFilter();
    this.proactive = new ProactiveFilter();
    this.return = new ReturnFilter();
    this.secondary = new SecondaryFilter();
    this.document = new DocumentFilter();
    this.carrier = new CarrierFilter();
  }

  public filterQueue(object: any, searchString: string, queueType: string) {
    this.queue = queueType;
    this.search = searchString;
    this.obj = object;
    return this.filter();
  }

  private filter() {
    return this.BaseMatch() || this.MatchQueue();
  }

  private BaseMatch() {
    return (
      this.filterMatch(this.obj.varianceString, this.search.split('$').join('')) ||
      this.filterMatch(this.obj.invoiceDateString, this.search) ||
      this.filterMatch(this.obj.primaryReference, this.search) ||
      this.filterMatch(this.obj.scac, this.search)
    );
  }

  private MatchQueue() {
    switch (this.queue) {
      case 'Customer':
        return this.customer.Match(this.obj, this.search);
      case 'Quick Tariff':
        return this.quicktariff.Match(this.obj, this.search);
      case 'BG':
        return this.bg.Match(this.obj, this.search);
      case 'Non-Proactive':
        return this.nonproactive.Match(this.obj, this.search);
      case 'Proactive':
        return this.proactive.Match(this.obj, this.search);
      case 'Document':
        return this.document.Match(this.obj, this.search);
      case 'Return':
        return this.return.Match(this.obj, this.search);
      case 'Secondary':
        return this.return.Match(this.obj, this.search);
      case 'Carrier':
        return this.carrier.Match(this.obj, this.search);
      default:
        console.log('Error no filter found for this queue');
        return false;
    }
  }

  public filterMatch(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search.toLowerCase().trim()) > -1;
  }
}

export class CustomerFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.status, search) ||
      super.filterMatch(obj.latestNote, search)
    );
  }
}

export class QuickTariffFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.status, search) ||
      super.filterMatch(obj.latestNote, search) ||
      super.filterMatch(obj.contractName, search)
    );
  }
}

export class BGFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.status, search) ||
      super.filterMatch(obj.latestNote, search) ||
      super.filterMatch(obj.errorType, search)
    );
  }
}

export class NonProactiveFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.latestNote, search)
    );
  }
}

export class ProactiveFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.latestNote, search)
    );
  }
}

export class ReturnFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.queue, search) ||
      super.filterMatch(obj.latestNote, search) ||
      super.filterMatch(obj.secondaryCategory, search)
    );
  }
}

export class SecondaryFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.pro, search) ||
      super.filterMatch(obj.latestNote, search) ||
      super.filterMatch(obj.secondaryCategory, search)
    );
  }
}

export class DocumentFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.auditor, search) ||
      super.filterMatch(obj.shipper, search) ||
      super.filterMatch(obj.consignee, search) ||
      super.filterMatch(obj.status, search) ||
      super.filterMatch(obj.latestNote, search)
    );
  }
}

export class CarrierFilter extends FilterFactory {
  constructor() {
    super();
  }
  public Match(obj: any, search: string) {
    return (
      super.filterMatch(obj.owner, search) ||
      super.filterMatch(obj.invoiceNumber, search) ||
      super.filterMatch(obj.billName, search) ||
      super.filterMatch(obj.aTeamNote, search) ||
      super.filterMatch(obj.emailHistory, search)
    );
  }
}

export class ManualProactiveFilter {
  constructor() {}
  public filterMatch(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search) > -1;
  }
  public Match(obj: any, search: string) {
    return (
      this.filterMatch(obj.owner, search) ||
      this.filterMatch(obj.primaryReference, search) ||
      this.filterMatch(obj.pro, search) ||
      this.filterMatch(obj.carrierName, search) ||
      this.filterMatch(obj.invoiceTotal, search.split('$').join(''))
    );
  }
}

export class ProactiveHoldFilter {
  constructor() {}
  public filterMatch(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search) > -1;
  }
  public Match(obj: any, search: string) {
    return this.filterMatch(obj.owner, search) || this.filterMatch(obj.primaryReference, search) || this.filterMatch(obj.pro, search);
  }
}

export class TruckloadInvoicesFilter {
  constructor() {}
  public filterMatch(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search) > -1;
  }
  public Match(obj: any, search: string) {
    return (
      this.filterMatch(obj.owner, search) ||
      this.filterMatch(obj.primaryReference, search) ||
      this.filterMatch(obj.shipmentCarrierName, search) ||
      this.filterMatch(obj.carrierName, search) ||
      this.filterMatch(obj.invoiceDateString, search) ||
      this.filterMatch(obj.documentTypes, search) ||
      this.filterMatch(obj.holdReason, search) ||
      this.filterMatch(obj.invoiceTotalString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.shipmentBuyRateString, search.split('$').join('').split(',').join(''))
    );
  }
}

export class TruckloadAuditInvoicesFilter {
  constructor() {}
  public filterMatch(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search) > -1;
  }
  public Match(obj: any, search: string) {
    return (
      this.filterMatch(obj.customer, search) ||
      this.filterMatch(obj.primaryReference, search) ||
      this.filterMatch(obj.auditor, search) ||
      this.filterMatch(obj.carrierName, search) ||
      this.filterMatch(obj.auditCompletedDateString, search) ||
      this.filterMatch(obj.invoiceCreatedDateString, search) ||
      this.filterMatch(obj.auditorAssignedDateString, search) ||
      this.filterMatch(obj.auditCreatedDateString, search) ||
      this.filterMatch(obj.auditCompletedDateString, search) ||
      this.filterMatch(obj.isSecondary, search) ||
      this.filterMatch(obj.auditAge, search) ||
      this.filterMatch(obj.reasonString, search.split(',').join('')) ||
      this.filterMatch(obj.buyRateString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.sellRateString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.invoiceTotalString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.marginString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.varianceString, search.split('$').join('').split(',').join(''))
    );
  }
}

export class TLDocHoldProactiveFilter {
  constructor() {}
  public filterMatch(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search) > -1;
  }
  public Match(obj: any, search: string) {
    return (
      this.filterMatch(obj.owner, search) ||
      this.filterMatch(obj.primaryReference, search) ||
      this.filterMatch(obj.ownerAccount, search) ||
      this.filterMatch(obj.documentTypes, search) ||
      this.filterMatch(obj.carrierInvoiceTotalString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.customerInvoiceTotalString, search.split('$').join('').split(',').join('')) ||
      this.filterMatch(obj.age, search) ||
      this.filterMatch(obj.externalTMSID, search)
    );
  }
}
