import { InvoiceService } from '@/services/invoice.service';
import { Component, Input, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-convert-to-shipment-dialog',
  templateUrl: './convert-to-shipment-dialog.component.html',
  styleUrls: ['./convert-to-shipment-dialog.component.scss']
})
export class ConvertToShipmentDialogComponent extends DialogContentBase implements OnInit {

  @Input()
  invoiceIds: number[];
  primaryReference: string;
  references: any[];
  userCreated: string;
  isLoading: boolean = false;

  constructor(
    dialog: DialogRef,
    private invoiceService: InvoiceService) {
    super(dialog);
  }

  ngOnInit() {
    if (this.invoiceIds.length == 1) {
      this.invoiceService
        .getReferences(this.invoiceIds[0])
        .pipe(map(x => x.map(y => y.value)))
        .subscribe(x => {
          this.references = x;
        });
    }
  }

  isSingleInvoice(): boolean {
    return this.invoiceIds && this.invoiceIds.length == 1;
  }

  isValid(): boolean {
    return (this.isSingleInvoice() && !!this.references) || !this.isSingleInvoice();
  }

  onSubmit() {
    this.isLoading = true;
    if (this.invoiceIds.length == 1) {
      this.invoiceService
        .convertInvoices([{
          id: this.invoiceIds[0],
          primaryReference: this.primaryReference,
          userCreated: this.userCreated
        }])
        .subscribe(() => {
          this.isLoading = false;
          this.dialog.close(true);
        });
    }
    else {
      this.invoiceService
        .convertInvoices(this.invoiceIds.map(x => {
          return {
            id: x,
            primaryReference: null
          };
        }))
        .subscribe(() => {
          this.isLoading = false;
          this.dialog.close(true);
        });
    }
  }

  onCancel() {
    this.dialog.close();
  }
}
