import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SortDescriptor, orderBy, process, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent, FilterService, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ActiveAuditResponse, GridState, UpdateStatusNote } from '../../services/AuditQueue';
import { Helpers, setSettings, getSettings, flatten } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { PageChangeEvent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-audit-carrier-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './audit-carrier-list.component.html',
  styleUrls: ['./audit-carrier-list.component.scss'],
})
export class AuditCarrierListComponent implements OnInit {
  @ViewChild('chargesGrid', { static: false })
  private recordGrid: GridComponent;

  get theRecords(): ActiveAuditResponse[] {
    return this.recordBookGrid;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }

  @Input('theRecords')
  set theRecords(value: ActiveAuditResponse[]) {
    this.recordBookGrid = value;
    this.setRecords();
    this.updateFiltersGrid();
  }

  @Input('theGridFilters')
  set theGridFilters(value: boolean) {
    this.refreshFilter = value;
  }
  private refreshFilter: boolean;

  get cacheKey() {
    return this._cacheKeyGrid;
  }

  @Input() set cacheKeyGrid(value) {
    this._cacheKeyGrid = value;
  }

  @Output()
  auditinvoice = new EventEmitter<ActiveAuditResponse>();

  @Output()
  selectedrecords = new EventEmitter<string[]>();

  gridView: GridDataResult;
  helpers: Helpers;
  isDataLoading: boolean;

  skip = 0;
  pageSize = 25;
  recordBookGrid: ActiveAuditResponse[] = [];
  selectableSettings: SelectableSettings;
  selectedRecords: string[] = [];
  sort: SortDescriptor[] = [];
  _cacheKeyGrid: string;
  allQueueData: ActiveAuditResponse[];
  gridState: DataStateChangeEvent = new GridState();
  scacListFilter: string[];
  ownerListFilter: string[];
  billNameListFilter: string[];
  selectedScac = new Array<any>();
  selectedOwner = new Array<any>();
  selectedBillName = new Array<any>();

  constructor(private authService: AuthService) {
    this.setSelectableSettings();
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);
    this.sort = getSettings(this._cacheKeyGrid, 'invoiceDate', 'asc');
  }

  private updateFiltersGrid(): void {
    if (this.allQueueData.length > 0) {
      if (!this.refreshFilter) {
        this.gridState = new GridState();
        this.gridView = process(this.allQueueData, this.gridState);
      } else {
        this.gridView = process(this.recordBookGrid, this.gridState);
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  public selectionChange(e) {
    // if there is no 'new' selection and the there are no current selections nothing to do
    if (e.selectedRows[0] === undefined && this.selectedRecords.length === 0) {
      return;
    }

    this.helpers.setSelectedGridRecords(this.selectedRecords, e);

    // send out the event
    this.selectedrecords.emit(this.selectedRecords);
  }
  protected onAuditInvoice(dataItem) {
    this.auditinvoice.emit(dataItem);
  }
  protected onPageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
    this.gridView = process(this.allQueueData, this.gridState)
  }
  protected onSortChange(sort: SortDescriptor[]): void {
    this.sort = Helpers.sortOneColumnAtTime(sort, this.sort);
    this.setRecords();
    setSettings(this._cacheKeyGrid, this.sort);
    this.gridView = process(this.allQueueData, this.gridState);
  }
  public removeSelections() {
    this.selectedRecords = [];
  }
  public firstPage(): void {
    this.onPageChange({ skip: 0, take: this.pageSize });
  }

  // helpers
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
    };
  }

  private setRecords(): void {
    const records = orderBy(this.recordBookGrid, this.sort);
    records.forEach((x) => {
      x.invoiceDate = Helpers.stringToDate(x.invoiceDate);
      x.actualDate = Helpers.stringToDate(x.actualDate);
    });
    this.gridView = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
    this.allQueueData = records;
    this.makeListFilters(records);
  }

  private makeListFilters(records: ActiveAuditResponse[]): void {
    this.scacListFilter = Helpers.makeListFiltersAudit(records, this.scacListFilter, "scac");
    this.ownerListFilter = Helpers.makeListFiltersAudit(records, this.ownerListFilter, "owner");
    this.billNameListFilter = Helpers.makeListFiltersAudit(records, this.billNameListFilter, "billName");
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.gridView = process(this.allQueueData, state);
  }

  public scacFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedScac.splice(0, this.selectedScac.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedScac;
  }

  public scacChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, "scac");
  }

  public ownerFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedOwner.splice(0, this.selectedOwner.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedOwner;
  }

  public ownerChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, "owner");
  }

  public billNameFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedBillName.splice(0, this.selectedBillName.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedBillName;
  }

  public billNameChange(values: any[], filterService: FilterService): void {
    filterService = Helpers.filtersBySelectAudit(values, filterService, "billName");
  }
}
