import { TLAuditEditState } from '../models/tl-audit-edit-state.model';
import * as TLAuditEditActions from '../actions/tl-audit-edit.actions';
import { TLEditUI } from '../models/TLEdit.ui';

const initialState: TLAuditEditState = {
  loadingTLEdit: false,
  loadingTLEditSuccess: false,
  loadingTLEditError: null,
  shipment: null,
  shipmentID: 0,
  invoiceID: 0,
  tlAuditID: 0,
  movingInvoice: false,
  movedInvoiceSuccess: false,
  movedInvoiceError: null,
  deletingInvoice: false,
  deletedInvoiceSuccess: false,
  deletedInvoiceError: null,
  loadingDocuments: false,
  loadingDocumentsSuccess: false,
  loadingDocumentError: null,
  documents: null,
  uploadingDocumentSuccess: false,
  lastUploadedFileName: null,
  uploadErrorInfo: null,
  downloadingFileSuccess: false,
  downloadingFileError: null,
  downloadingRawData: null,
};
export function TLAuditEditReducer(state: TLAuditEditState = initialState, action: TLAuditEditActions.Actions) {
  switch (action.type) {
    case TLAuditEditActions.LOAD_TL_AUDIT_EDIT: {
      return {
        ...state,
        shipmentID: action.shipmentID,
        invoiceID: action.invoiceID,
      };
    }
    case TLAuditEditActions.LOAD_TL_AUDIT_EDIT_SUCCESS: {
      return {
        ...state,
        loadingTLEdit: false,
        shipment: action.tlEditDetail,
      };
    }
    case TLAuditEditActions.LOAD_TL_AUDIT_EDIT_ERROR: {
      return {
        ...state,
        loadingTLEdit: false,
        loadingTLEditError: action.message,
      };
    }
    case TLAuditEditActions.MOVE_TL_AUDIT_EDIT: {
      return {
        ...state,
        movingInvoice: true,
      };
    }
    case TLAuditEditActions.MOVE_TL_AUDIT_EDIT_SUCCESS: {
      return {
        ...state,
        movingInvoice: false,
        movedInvoiceSuccess: true,
      };
    }
    case TLAuditEditActions.MOVE_TL_AUDIT_EDIT_ERROR: {
      return {
        ...state,
        movingInvoice: false,
        movedInvoiceError: action.message,
      };
    }
    case TLAuditEditActions.DELETE_TL_AUDIT_EDIT: {
      return {
        ...state,
        deletingInvoice: true,
      };
    }
    case TLAuditEditActions.DELETE_TL_AUDIT_EDIT_SUCCESS: {
      return {
        ...state,
        deletingInvoice: false,
        deletedInvoiceSuccess: true,
      };
    }
    case TLAuditEditActions.DELETE_TL_AUDIT_EDIT_ERROR: {
      return {
        ...state,
        deletingInvoice: false,
        deletedInvoiceError: action.message,
      };
    }
    case TLAuditEditActions.LOAD_TL_AUDIT_DOCUMENTS: {
      return {
        ...state,
        loadingDocuments: true,
      };
    }
    case TLAuditEditActions.LOAD_TL_AUDIT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loadingDocuments: false,
        loadingDocumentsSuccess: true,
        documents: action.shipmentdocuments,
      };
    }
    case TLAuditEditActions.LOAD_TL_AUDIT_DOCUMENTS_ERROR: {
      return {
        ...state,
        loadingDocuments: false,
        loadingDocumentsSuccess: false,
        loadingDocumentError: action.message,
      };
    }
    case TLAuditEditActions.UPLOAD_TL_AUDIT_DOCUMENT: {
      return {
        ...state,
        lastUploadedFileName: null,
        uploadingDocumentSuccess: false,
      };
    }
    case TLAuditEditActions.UPLOAD_TL_AUDIT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        uploadingDocumentSuccess: true,
        lastUploadedFileName: action.fileName,
      };
    }
    case TLAuditEditActions.UPLOAD_TL_AUDIT_DOCUMENT_ERROR: {
      const errorInfo = new TLEditUI.UploadErrorInfo(action.message, action.errorCode);
      return {
        ...state,
        uploadingDocumentSuccess: false,
        uploadingDocumentError: errorInfo,
      };
    }
    case TLAuditEditActions.DOWNLOAD_TL_AUDIT_DOCUMENTS: {
      return {
        ...state,
        lastUploadedFileName: null,
        downloadingRawData: null,
      };
    }
    case TLAuditEditActions.DOWNLOAD_TL_AUDIT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        downloadingFileSuccess: true,
        downloadingRawData: action.fileData,
      };
    }
    case TLAuditEditActions.DOWNLOAD_TL_AUDIT_DOCUMENTS_ERROR: {
      return {
        ...state,
        downloadingFileSuccess: false,
        downloadingFileError: action.message,
      };
    }
    default:
      return state;
  }
}
