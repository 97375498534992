import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SortDescriptor, orderBy, GroupDescriptor, process, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ShipmentHistory } from '../../services/ShipmentHistory';
import { AuthService } from '../../auth/auth.service';
import { PageChangeEvent, GridDataResult, RowClassArgs, DataStateChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-audit-history-audit-list',
  templateUrl: './audit-history-audit-list.component.html',
  styleUrls: ['./audit-history-audit-list.component.scss'],
})
export class AuditHistoryAuditListComponent implements OnInit {
  @ViewChild('theAuditHistoryGrid', { static: false })
  private recordGrid: GridComponent;

  get theRecords(): ShipmentHistory[] {
    return this.recordBookGrid;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }

  @Input('theRecords')
  set theRecords(value: ShipmentHistory[]) {
    this.recordBookGrid = value;
    this.setRecords();
  }

  public state: State = {
    skip: 0,
    take: 100,
    group: [],
  };

  gridView: GridDataResult;
  isDataLoading: boolean;
  recordBookGrid: ShipmentHistory[] = [];
  sort: SortDescriptor[] = [
    {
      field: 'updateDate',
      dir: 'desc',
    },
  ];

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.recordBookGrid, this.state);
  }

  constructor(private authService: AuthService) {}

  public formatTime(timeString) {
    // convert to number
    if (timeString.length === 0) {
      return;
    }
    let hour = +timeString.substring(0, 2);
    if (hour > 12) {
      hour -= 12;
    }

    // set back to the correct time
    return ('00' + hour).slice(-2) + timeString.substring(2, timeString.length);
  }
  ngOnInit() {
    this.setRecords();
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // helpers
  private setRecords(): void {
    this.gridView = process(this.recordBookGrid, this.state);
  }
}
