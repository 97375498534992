import { Action } from '@ngrx/store';
import { TruckloadAuditOldUI, TruckloadAuditReasonOldUI, TruckloadAuditReasonCommentOldUI } from '../models/TruckloadAuditOld.ui';
import { TLAuditRefDataOldResponse } from '../dto/TruckloadAuditOLDDTO';
import { NewTruckloadAuditReasonOld } from '../models/TLAuditOld';
import { AuditUserResponse } from '@/services/InvoiceAudit';

export const SHOW_MANAGE_REASON_OLD = 'Mange Reasons Old';
export const SHOW_ASSIGN_AUDITOR_OLD = 'Assign Auditor Old';

export const LOAD_TL_AUDITOR_LIST_OLD = '[Finance API] Load All TL Auditors Old';
export const LOAD_TL_AUDITOR_LIST_SUCCESS_OLD = '[Finance API] Load All TL Auditors Old Success';
export const LOAD_TL_AUDITOR_LIST_ERROR_OLD = '[Finance API] Load All TL Auditors Old Error';

export const ADD_AUDITOR_TO_AUDIT_OLD = '[Finance API] Add Auditor to Audit Old';
export const REMOVE_AUDITOR_TO_AUDIT_OLD = '[Finance API] Remove Auditor from Audit Old';
export const ADD_AUDITOR_TO_AUDIT_SUCCESS_OLD = '[Finance API] Add/ Remove Auditor to Audit Old Success';
export const ADD_AUDITOR_TO_AUDIT_ERROR_OLD = '[Finance API] Add /Remove Auditor to Audit Old Error';

export const LOAD_TL_AUDIT_DETAIL_OLD = '[Finance API] Load TL Audit Detail Old';
export const LOAD_TL_AUDIT_DETAIL_SUCCESS_OLD = '[Finace API] Load TL Audit Detail Old Success';
export const LOAD_TL_AUDIT_DETAIL_ERROR_OLD = '[Finance API] Load TL Audit Detail Old Error';

export const SAVE_REASON_OLD = 'Save New Reason Old';
export const SAVE_REASON_SUCCESS_OLD = '[Finace API] Save Reason Old Success';
export const SAVE_REASON_ERROR_OLD = '[Finace API] Save Reason Old Error';

export const ADD_AUDIT_SAVE_REASON_OLD = 'Adding Audit and Save New Reason Old';
export const ADD_AUDIT_SAVE_REASON_SUCCESS_OLD = '[Finace API] Adding Audit and Save Reason Old Success';
export const ADD_AUDIT_SAVE_REASON_ERROR_OLD = '[Finace API] Adding Audit and Save Reason Old Error';

export const SAVE_REASON_STATUS_OLD = 'Save New Reason Status Old';
export const SAVE_REASON_STATUS_SUCCESS_OLD = '[Finace API] Save Reason Status Old Success';
export const SAVE_REASON_STATUS_ERROR_OLD = '[Finace API] Save Reason Status Old Error';

export const ADD_COMMENT_OLD = 'Add Comment to Reason Old';
export const ADD_COMMENT_SUCCESS_OLD = '[Finace API] Add Comment to Reason Old Success';
export const ADD_COMMENT_ERROR_OLD = '[Finace API] Add Comment to Reason Old Error';

export class ShowManageReasonsOld implements Action {
  readonly type = SHOW_MANAGE_REASON_OLD;
  constructor(public visible: boolean) {}
}

export class ShowAssignAuditorOld implements Action {
  readonly type = SHOW_ASSIGN_AUDITOR_OLD;
  constructor(public auditID: number, public auditor: string, public visible: boolean) {}
}

export class AddAuditorToAuditOld implements Action {
  readonly type = ADD_AUDITOR_TO_AUDIT_OLD;
  constructor(public tlauditID: number, public name: string) {}
}

export class RemoveAuditorFromAuditOld implements Action {
  readonly type = REMOVE_AUDITOR_TO_AUDIT_OLD;
  constructor(public tlauditID: number) {}
}
export class AddAuditorToAuditSuccessOld implements Action {
  readonly type = ADD_AUDITOR_TO_AUDIT_SUCCESS_OLD;
  constructor(public updatedAudit: TruckloadAuditOldUI) {}
}
export class AddAuditorToAuditErrorOld implements Action {
  readonly type = ADD_AUDITOR_TO_AUDIT_ERROR_OLD;
  constructor(public message: string) {}
}

export class LoadTLAuditorListOld implements Action {
  readonly type = LOAD_TL_AUDITOR_LIST_OLD;
  constructor() {}
}
export class LoadTLAuditorListSuccessOld implements Action {
  readonly type = LOAD_TL_AUDITOR_LIST_SUCCESS_OLD;
  constructor(public auditors: AuditUserResponse[]) {}
}
export class LoadTLAuditorListErrorOld implements Action {
  readonly type = LOAD_TL_AUDITOR_LIST_ERROR_OLD;
  constructor(public message: string) {}
}

export class LoadTLAuditDetailOld implements Action {
  readonly type = LOAD_TL_AUDIT_DETAIL_OLD;
  constructor(public invoiceID: number, public shipmentID: number) {}
}

export class LoadTLAuditDetailSuccessOld implements Action {
  readonly type = LOAD_TL_AUDIT_DETAIL_SUCCESS_OLD;
  constructor(public tlAuditDetail: TruckloadAuditOldUI, public reasonData: TLAuditRefDataOldResponse) {}
}

export class LoadTLAuditDetailErrorOld implements Action {
  readonly type = LOAD_TL_AUDIT_DETAIL_ERROR_OLD;
  constructor(public message: string) {}
}

export class SaveReasonOld implements Action {
  readonly type = SAVE_REASON_OLD;
  constructor(public newReason: NewTruckloadAuditReasonOld) {}
}

export class SaveReasonSuccessOld implements Action {
  readonly type = SAVE_REASON_SUCCESS_OLD;
  constructor(public newSavedReason: TruckloadAuditReasonOldUI) {}
}

export class SaveReasonErrorOld implements Action {
  readonly type = SAVE_REASON_ERROR_OLD;
  constructor(public message: string) {}
}

export class SaveReasonStatusOld implements Action {
  readonly type = SAVE_REASON_STATUS_OLD;
  constructor(public invoiceAuditID: number, public reasonID: number, public status: string, public comment: string) {}
}

export class SaveReasonStatusSuccessOld implements Action {
  readonly type = SAVE_REASON_STATUS_SUCCESS_OLD;
  constructor(public newSavedReason: TruckloadAuditReasonOldUI) {}
}

export class SaveReasonStatusErrorOld implements Action {
  readonly type = SAVE_REASON_STATUS_ERROR_OLD;
  constructor(public message: string) {}
}

export class AddAuditSaveReasonOld implements Action {
  readonly type = ADD_AUDIT_SAVE_REASON_OLD;
  constructor(public invoiceID: number, public shipmentID: number, public reason: string, public comment: string) {}
}

export class AddAuditSaveReasonSuccessOld implements Action {
  readonly type = ADD_AUDIT_SAVE_REASON_SUCCESS_OLD;
  constructor(public newSavedAudit: TruckloadAuditOldUI) {}
}

export class AddAuditSaveReasonErrorOld implements Action {
  readonly type = ADD_AUDIT_SAVE_REASON_ERROR_OLD;
  constructor(public message: string) {}
}

export class AddCommentOld implements Action {
  readonly type = ADD_COMMENT_OLD;
  constructor(public invoiceAuditID: number, public reasonID: number, public comment: string) {}
}

export class AddCommentSuccessOld implements Action {
  readonly type = ADD_COMMENT_SUCCESS_OLD;
  constructor(public newSavedComment: TruckloadAuditReasonCommentOldUI) {}
}

export class AddCommentErrorOld implements Action {
  readonly type = ADD_COMMENT_ERROR_OLD;
  constructor(public message: string) {}
}

// Actions
export type Actions =
  | ShowManageReasonsOld
  | ShowAssignAuditorOld
  | LoadTLAuditDetailOld
  | LoadTLAuditDetailSuccessOld
  | LoadTLAuditDetailErrorOld
  | SaveReasonOld
  | SaveReasonSuccessOld
  | SaveReasonErrorOld
  | AddAuditSaveReasonOld
  | AddAuditSaveReasonSuccessOld
  | AddAuditSaveReasonErrorOld
  | SaveReasonStatusOld
  | SaveReasonStatusSuccessOld
  | SaveReasonStatusErrorOld
  | AddCommentOld
  | AddCommentSuccessOld
  | AddCommentErrorOld
  | LoadTLAuditorListOld
  | LoadTLAuditorListSuccessOld
  | LoadTLAuditorListErrorOld
  | AddAuditorToAuditOld
  | RemoveAuditorFromAuditOld
  | AddAuditorToAuditSuccessOld
  | AddAuditorToAuditErrorOld;
