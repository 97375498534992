export class ShipmentInvoiceAuditDTO {
  rates: Rate[];
  shipment: Shipment;
  specialInstructions: string;
  accessorials: Accessorial[];
  references: Reference[];
  enterprise: Enterprise;
  addresses: Address[];
  items: Item[];
  proNumbers: ProNumbers[];
}
export class ShipmentInvoiceAuditSaveRateDTO {
  shipmentID: number;
  rates: Rate[];
}
export interface Enterprise {
  id: number;
  name: string;
  accountNumber: string;
  customerCode: string;
}
export interface Shipment {
  shipmentID: number;
  status: string;
  rateDerivation: string;
  isConfirmed: boolean;
  dateConfirmed?: Date;
  tmsid: number;
  refShipmentOriginID: number;
  refShipmentTypeID: number;
  isTest: boolean;
  tenderStatus: string;
  dateBooked: Date;
  confirmedLinearFootage: number;
  dateCovered?: Date;
  shipmentGuid?: string;
  tenderRejectionReason?: string;
  podrequired: boolean;
  showCorporateTl: boolean;
  nbbg: boolean;
  invoiceHold: boolean;
  modeType: string;
  isHold: boolean;
  holdReason?: string;
  isPodavailable: boolean;
  isBusinessCritical: boolean;
  mustAcknowledgeBy?: Date;
  canConsolidate: boolean;
  tempRequirement?: string;
  activityDate?: Date;
}

export interface CarrierCharge {
  chargeID?: number; // not sent in
  type: string;
  description: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  isLinehaul: boolean;
  isAccessorial: boolean;
  ediCode: string;
  group: string; // not sent in
}

export interface CustomerCharge {
  chargeID?: number; // not sent in
  type: string;
  description: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  isLinehaul: boolean;
  isAccessorial: boolean;
}

export interface Charge {
  chargeID: number;
  type: string;
  description: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  isLinehaul: boolean;
  isAccessorial: boolean;
  ediCode: string;
}

export interface ThirdPartyCharge {
  type: string;
  description?: string;
  amount: number;
  rate: number;
  rateQualifier?: any;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  isLinehaul: boolean;
  isAccessorial: boolean;
}

export class Rate {
  id: string;
  requestGroupID: string;
  contractId: number;
  contractName: string;
  carrierAccountNumber: string;
  carrierAccountNumberDisplay: string;
  carrierName: string;
  scac: string;
  mode: string;
  service: string;
  serviceDays: number;
  distance: number;
  expectedDeliveryDate: Date;
  carrierIdentifier: string;
  originService: string;
  destinationService: string;
  currencyCode: string;
  priceSheetID: string;
  filterReason?: string;
  isExcluded: boolean;
  isSelected: boolean; // not needed?
  maxLiability: string;
  carrierLiabilityOld: string;
  carrierLiabilityNew: string;
  carrierQuoteNumber?: any;
  isPremium: boolean;
  isLeastCost: boolean;
  isTMS: boolean;
  carrierTotal: number;
  carrierNormalizedTotal: number;
  normalizedCurrencyCode: string;
  customerTotal: number;
  customerNormalizedTotal: number;
  thirdPartyTotal: number;
  carrierServiceTotal: number;
  carrierLinehaulTotal: number;
  customerServiceTotal: number;
  customerLinehaulTotal: number;
  thirdPartyServiceTotal: number;
  thirdPartyLinehaulTotal: number;
  thirdPartyNormalizedTotal: number;
  dateCreated: Date;
  carrierCharges: CarrierCharge[];
  customerCharges: CustomerCharge[];
  thirdPartyCharges: ThirdPartyCharge[];
  note: string;
  markups: Markup[];
  isManualCreate: boolean;
  billingAddress: BillingAddress;
  rating?: number;
  customerCostAdjustment?: number;
}

export interface BillingAddress {
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
}

export interface Markup {
  index: string;
  amount: number;
  type: string;
  markupIndexID: number;
}

export interface Accessorial {
  accessorialID: number;
  code: string;
  name: string;
}

export interface Reference {
  referenceID: number;
  type: string;
  value: string;
  isPrimary: boolean;
  isDefaulted: boolean;
  isRequired: boolean;
  excludeBlueShip: boolean;
  excludeTms: boolean;
  excludeBillOfLading: boolean;
  includeReport: boolean;
  includePalletLabel: boolean;
  isEditable: boolean;
  allowedValues: string[];
  defaultValue?: string;
}

export interface ProNumbers {
  proNumberID: number;
  proNumber: string;
  dateCreated: Date;
}

export interface Address {
  addressID: number;
  sequence: number;
  earliestDate: Date;
  latestDate: Date;
  actualDate?: Date;
  lateReason: string;
  lateReasonCode: string;
  earliestAppointmentDate?: Date;
  latestAppointmentDate?: Date;
  actualAppointmentDate?: Date;
  appointmentType?: any;
  appointmentNumber?: any;
  locationCode: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  isResidential?: boolean;
  isPrimary?: boolean;
  companyName: string;
  stateProvince: string;
  countryCode: string;
  contactName: string;
  contactPhone: string;
  contactFax: string;
  contactEmail: string;
  locationComments: string;
  latDegrees?: any;
  latDirection?: any;
  longDegrees?: any;
  longDirection?: any;
  pickupItems: number[];
  dropoffItems: number[];
}

export interface Dimension {
  type: string;
  uom: string;
  length: number;
  width: number;
  height: number;
}

export interface HazMatDetail {
  shippingName: string;
  hazMatIdentifier: string;
  packageGroup: string;
  contactName: string;
  contactPhone: string;
  isHazMatPlacards?: any;
  placardsDetails?: any;
  flashPointTempUom?: any;
  flashPointTemp?: any;
  emsnumber?: any;
  hazMatClass: string;
}

export interface Quantity {
  uom: string;
  value: number;
  numberOfPieces: number;
  piecesUom: string;
}

export interface Weight {
  uom: string;
  value: number;
}

export interface Item {
  itemID: number;
  itemIdentifier: string;
  description: string;
  freightClass: number;
  nmfc: string;
  hawb: string;
  cubicVolume: number;
  isStackable: boolean;
  productNumber: string;
  isTempSensitive: boolean;
  lowTemp: number;
  highTemp: number;
  tempUom: string;
  fluidVolume: number;
  fluidVolumeUom: string;
  glcode: string;
  monetaryValue: number;
  orderRef: string;
  itemCategoryName?: string;
  itemCommodityName?: string;
  dimension: Dimension;
  hazMatDetail: HazMatDetail;
  quantity: Quantity;
  weight: Weight;
  pickupAddressSequence: number;
  dropOffAddressSequence: number;
}

export class PendingInvoiceReference {
  id: number;
  pendingCarrierInvoiceID: number;
  isPrimary: boolean;
  type: string;
  value: string;
}