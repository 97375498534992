import { AppState } from '@/appstate.model';
import { AuthService } from '@/auth/auth.service';
import { LocalStorageService } from '@/services/localstorage.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Helpers } from '@/_shared/helpers';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoadTLAuditEdit } from './actions/tl-audit-edit.actions';
import { EditRateParameters } from './components/tl-buy-sell-rate-edit/tl-buy-sell-rate-edit.component';
import { TLEditUI } from './models/TLEdit.ui';

@Component({
  selector: 'app-truckload-edit',
  templateUrl: './truckload-edit.component.html',
  styleUrls: ['./truckload-edit.component.scss'],
})
export class TruckloadEditComponent implements OnInit, OnDestroy {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  shipmentID: number;
  invoiceID: number;
  isLoading = false;
  loadingMessage: string;
  isRefreshing = false;

  shipment: TLEditUI.TLShipment;
  helpers: Helpers = null;
  totalVariance = 0;
  errorList: [] = null;
  openRateEditDialog: boolean;
  editRateParameters: EditRateParameters;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>
  ) {
    this.shipmentID = +this.route.snapshot.paramMap.get('shipmentid');
    this.invoiceID = +this.route.snapshot.paramMap.get('invoiceid');
  }

  ngOnInit() {
    // are they allowed to view this page
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    if (!this.helpers.hasOperation('CanEditTLDocProactive')) {
      this.router.navigate([`not-authorized`]);
    }

    this.setListeners();
    this.loadingMessage = 'Loading invoice...';
    this.isLoading = true;
    this.store.dispatch(new LoadTLAuditEdit(this.invoiceID, this.shipmentID));
    // TODO find a more elegant solution
    // listen for changes to the localstorage
    window.addEventListener('storage', this.storageHandler.bind(this), false);
  }
  ngOnDestroy() {
    // remove the lister and remove the storage item
    window.removeEventListener('storage', this.storageHandler);
    this.localStorageService.remove(this.shipmentID.toString());
  }
  storageHandler() {
    // is this us?
    if (this.localStorageService.get(this.shipmentID.toString()) != null) {
      this.localStorageService.remove(this.shipmentID.toString());
      this.refreshPage(null);
    }
  }

  protected refreshPage(e: MouseEvent) {
    if (e) {
      e.preventDefault();
    }
    this.isRefreshing = true;
    this.showSpinner(true, 'Refreshing page...');
    this.store.dispatch(new LoadTLAuditEdit(this.invoiceID, this.shipmentID));
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditEditState.shipment)
      .subscribe((x) => {
        if (x) {
          this.shipment = x;
          this.isLoading = false;
          this.isRefreshing = false;
          this.showSpinner(false);
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.movingInvoice)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Moving invoice...');
        } else {
          this.showSpinner(false);
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.movedInvoiceError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Error moving invoice ' + x, 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.deletingInvoice)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Deleting invoice...');
        } else {
          this.showSpinner(false);
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.deletedInvoiceError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Error deleting invoice ' + x, 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.uploadErrorInfo)
      .subscribe((x) => {
        if (x) {
          if (x.code === 409) {
            this.alertMessage.showAlertMessage('This document already exists for this invoice / type.', 'Error');
          } else {
            this.alertMessage.showAlertMessage('Error uploading document' + x.message, 'Error');
          }
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.lastUploadedFileName)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage(`This file ${x} was successfully uploaded.`, 'Success');
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.downloadingFileError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('An error occurred trying to download/ open the file.' + x, 'Error');
        }
      });
  }

  public editSellRates() {
    this.openRateEditDialog = true;

    this.editRateParameters = {
      title: "Edit Sell Rates",
      invoiceID: this.invoiceID,
      shipmentID: this.shipment.shipmentID,
      quote: this.shipment.customerQuote,
      isBuyRate: false,
      distance: this.shipment.customerQuote.distance,
      mode: this.shipment.mode,
      currencyCode: this.shipment.currencyCode
    };
  }

  public editBuyRates() {
    this.openRateEditDialog = true;
    this.editRateParameters = {
      title: "Edit Buy Rates",
      invoiceID: this.invoiceID,
      shipmentID: this.shipment.shipmentID,
      quote: this.shipment.carrierQuote,
      isBuyRate: true,
      distance: this.shipment.carrierQuote.distance,
      mode: this.shipment.mode,
      currencyCode: this.shipment.currencyCode
    };
  }

  public closeRateEditDialog(response: boolean) {
    if (response === true) {
      this.store.dispatch(new LoadTLAuditEdit(this.invoiceID, this.shipmentID));
      this.alertMessage.showAlertMessage("The charges were updated successfully", "Success");
    }
    this.openRateEditDialog = false;
  }

  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }
}
