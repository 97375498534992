import { ShipmentService } from '@/services/shipment.service';
import { StartupService } from '@/startup.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-untracked-invoices',
  templateUrl: './untracked-invoices.component.html',
  styleUrls: ['./untracked-invoices.component.scss']
})
export class UntrackedInvoicesComponent implements OnInit {

  invoices: Observable<any>;
  loading: boolean = false;

  constructor(private startup: StartupService, private shipments: ShipmentService) { }

  ngOnInit() {
    this.loading = true;
    this.shipments.getUntracked().subscribe(x => {
      this.loading = false;
      this.invoices = x;
    });
  }

  openShipment(shipmentId: number): void {
    window.open(`${this.startup.backOfficeUrl}/shipment-edit-ltl/${shipmentId}`);
  }
}
