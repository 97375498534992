import { CarrierInvoiceAudit, CarrierInvoiceCharge } from './CarrierInvoiceAudit';
import { CarrierResponse, SearchResultDC } from '@/models/Carrier';
import { ShipmentDTO } from '@/services/Shipment';

export class CarrierInvoiceAuditState {
  carrierInvoiceAudit: CarrierInvoiceAudit;
  loading: boolean;
  loadingError: string;
  saving: boolean;
  savedAudit: any;
  savingError: string;
  isInternational: boolean;
  editCurrencyCode: string;
  distance: number;
  editMode: boolean;
  conversionRate: number;

  invoiceTotal: number;
  invoiceTotalInUSD: number;
  quoteTotal: number;
  quoteTotalInUSD: number;

  // charges
  editInProgress: boolean;
  serviceTotal: number;
  freightTotal: number;
  serviceTotalInUSD: number;
  freightTotalInUSD: number;
  charges: CarrierInvoiceCharge[];

  // carrier
  carrierLoading: boolean;
  carrierLoadingError: string;
  carriers: CarrierResponse[];
}
