import { Action } from '@ngrx/store';
import {
  TruckloadAuditUI,
  TruckloadAuditReasonUI,
  TruckloadAuditReasonCommentUI,
  TruckloadAuditReasonListUI,
} from '../models/TruckloadAudit.ui';
import { NewComment, NewTruckloadAuditIncident, UpdateIncident } from '../models/TLAudit';
import { TLIncidentTypeDTO } from '../dto/IncidentDTO';

export const SHOW_MANAGE_REASON = 'Manage Reasons';

export const LOAD_TL_AUDIT_DETAIL = '[Finance API] Load TL Audit Detail';
export const LOAD_TL_AUDIT_DETAIL_SUCCESS = '[Finace API] Load TL Audit Detail Success';
export const LOAD_TL_AUDIT_DETAIL_ERROR = '[Finance API] Load TL Audit Detail Error';

export const SAVE_REASON = 'Save New Reason';
export const SAVE_REASON_SUCCESS = '[Finace API] Save Reason Success';
export const SAVE_REASON_ERROR = '[Finace API] Save Reason Error';

export const SAVE_REASON_STATUS = 'Save New Reason Status';
export const SAVE_REASON_STATUS_SUCCESS = '[Finace API] Save Reason Status Success';
export const SAVE_REASON_STATUS_ERROR = '[Finace API] Save Reason Status Error';

export const ADD_COMMENT = 'Add Comment to Reason';
export const ADD_COMMENT_SUCCESS = '[Finace API] Add Comment to Reason Success';
export const ADD_COMMENT_ERROR = '[Finace API] Add Comment to Reason Error';

export class ShowManageReasons implements Action {
  readonly type = SHOW_MANAGE_REASON;
  constructor(public visible: boolean) { }
}

export class LoadTLAuditDetail implements Action {
  readonly type = LOAD_TL_AUDIT_DETAIL;
  constructor(public invoiceID: number, public shipmentID: number) { }
}

export class LoadTLAuditDetailSuccess implements Action {
  readonly type = LOAD_TL_AUDIT_DETAIL_SUCCESS;
  constructor(public tlAuditDetail: TruckloadAuditReasonListUI, public reasonData: TLIncidentTypeDTO[]) { }
}

export class LoadTLAuditDetailError implements Action {
  readonly type = LOAD_TL_AUDIT_DETAIL_ERROR;
  constructor(public message: string) { }
}

export class SaveReason implements Action {
  readonly type = SAVE_REASON;
  constructor(public newReason: NewTruckloadAuditIncident) { }
}

export class SaveReasonSuccess implements Action {
  readonly type = SAVE_REASON_SUCCESS;
  constructor(public newSavedReason: TruckloadAuditReasonUI) { }
}

export class SaveReasonError implements Action {
  readonly type = SAVE_REASON_ERROR;
  constructor(public message: string) { }
}

export class SaveReasonStatus implements Action {
  readonly type = SAVE_REASON_STATUS;
  constructor(public updateReason: UpdateIncident) { }
}

export class SaveReasonStatusSuccess implements Action {
  readonly type = SAVE_REASON_STATUS_SUCCESS;
  constructor(public updatedReason: TruckloadAuditReasonUI) { }
}

export class SaveReasonStatusError implements Action {
  readonly type = SAVE_REASON_STATUS_ERROR;
  constructor(public message: string) { }
}

export class AddComment implements Action {
  readonly type = ADD_COMMENT;
  constructor(public newComment: NewComment) { }
}

export class AddCommentSuccess implements Action {
  readonly type = ADD_COMMENT_SUCCESS;
  constructor(public newSavedComment: TruckloadAuditReasonCommentUI) { }
}

export class AddCommentError implements Action {
  readonly type = ADD_COMMENT_ERROR;
  constructor(public message: string) { }
}

// Actions
export type Actions =
  | ShowManageReasons
  | LoadTLAuditDetail
  | LoadTLAuditDetailSuccess
  | LoadTLAuditDetailError
  | SaveReason
  | SaveReasonSuccess
  | SaveReasonError
  | SaveReasonStatus
  | SaveReasonStatusSuccess
  | SaveReasonStatusError
  | AddComment
  | AddCommentSuccess
  | AddCommentError;
