
export class ShipmentSearchDTO {
  shipmentID: number;
  value: string;
  type: string;
  isPrimary: string;
  accountName?: string;
  accountNumber: string;
  enterpriseName?: string;
}

export class ShipmentDTO {
  id: number;
  enterpriseID: number;
  primaryReference: string;
  businessUnit: string;
  mcNumber: string;
  dotNumber: string;
  carrierCode: string;
  mode: string;
  carrierName: string;
  enterpriseName: string;
  accountNumber: string;
  scac?: any;
  distance: number;
  expectedPickupDate: string;
  expectedDeliveryDate: string;
  actualPickupDate: string;
  outForDelivery: string;
  actualDeliveryDate: string;
  dateCreated: string;
  isTest: boolean;
  confirmedLinearFootage: number;
  specialInstructions: string;
  status: string;
  truckType?: any;
  targetBuy: number;
  targetSell: number;
  items: Item[];
  addresses: AddressDTO[];
  accessorials: Accessorial[];
  references: Reference[];
  insurance?: any;
  rates?: any;
  selectedRate: SelectedRate;
  rejectReason?: any;
  paymentMethod?: any;
  billingAddress: BillingAddressDTO;
  tracking: Tracking[];
  carrier: Carrier;
  truckTypes: TruckType[];
  lockExpireTime?: any;
  lockUserFullName?: any;
  userCreated: string;
}
export class TruckType {
  truckType: string;
  equipment?: any;
  isSelected: boolean;
  code: string;
}

export class Carrier {
  scac: string;
  carrierName?: any;
  dispatchName?: any;
  driverName?: any;
  tractorNumber?: any;
  dispatchPhone?: any;
  driverPhone?: any;
  trailerNumber?: any;
}

export class Tracking {
  date: string;
  estimatedDeliveryDate?: any;
  code: string;
  codeDescription: string;
  city: string;
  state: string;
  statusReasonCode: string;
  statusReasonCodeDescription: string;
  userCreated: string;
}

export class BillingAddressDTO {
  name?: any;
  addressLine1: string;
  addressLine2?: any;
  city: string;
  postalCode: string;
  companyName: string;
  stateProvince: string;
  countryCode: string;
  locationCode?: any;
  locationComments?: any;
  contactName: string;
  contactPhone: string;
  contactFax?: any;
  contactEmail?: any;
  paymentMethod: string;
}

export class SelectedRate {
  id: string;
  carrierName: string;
  carrierCode?: any;
  scac: string;
  mcNumber: string;
  dotNumber: string;
  mode: string;
  service: string;
  serviceDays: number;
  expectedDeliveryDate: string;
  rating: number;
  priceSheetID: string;
  contractID: string;
  contractName: string;
  originService?: any;
  destinationService?: any;
  carrierTotal: number;
  carrierTotalInUSD: number;
  customerTotal: number;
  customerTotalInUSD: number;
  customerNormalizedTotal: number;
  customerNormalizedCurrencyCode: string;
  thirdPartyTotal: number;
  thirdPartyTotalInUSD: number;
  currencyCode: string;
  isBlockInsurance: boolean;
  maxLiability?: any;
  carrierCharges: CarrierCharge[];
  customerCharges: CarrierCharge[];
  thirdPartyCharges: any[];
  parentType: string;
  amount?: number;
  carrierAccountNumber?: string;
}

export class CarrierCharge {
  chargeID: number;
  description: string;
  type: string;
  amount: number;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  dimWeight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
  isNonTaxable: boolean;
  group: string;
  error: boolean;
  ediCode: string;
}

export class Reference {
  type: string;
  value: string;
}

export class Accessorial {
  code: string;
  name: string;
}

export class AddressDTO {
  earliestTime: string;
  latestTime: string;
  sequence: number;
  locationCode: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateProvince: string;
  countryCode: string;
  postalCode: string;
  contactName: string;
  phone: string;
  fax: string;
  email: string;
  locationComments: string;
  internationalGoverningDistrict: string;
}

export class Item {
  id: number;
  description: string;
  freightClass: number;
  pieces: number;
  piecesUOM: string;
  nmfc: string;
  hawb?: any;
  weight: number;
  weightUOM: string;
  originalPlannedWeight?: number;
  originalPlannedWeightUOM?: string;
  quantity: number;
  quantityUOM: string;
  length: number;
  width: number;
  height: number;
  dimensionUOM: string;
  isTempSensitive: boolean;

}

export interface PostalInfoSearchDTO {
  city: string;
  countryCode: string;
  postalCode: string;
  stateProvince: string;
  cityState?: string;
  zipCityState?: string;
}
