import { Component, OnInit, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MenuItem, MenuTypes, InitialData } from './MenuItems';
import { StartupService } from '@/startup.service';
import { Helpers } from '@/_shared/helpers';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  label: string;
  public user: string;
  isMenuActive: Boolean;
  MenuItems: Array<MenuItem>;
  InvoiceVisibilityMenuItem: MenuItem;
  menuTypes = MenuTypes;
  incidentManagementOn: boolean;
  invoiceVisibilityOn: boolean;
  constructor(
    private authService: AuthService,
    private startupService: StartupService,
    private helpers: Helpers
  ) { }

  ngOnInit() {
    this.isMenuActive = false;
    this.MenuItems = InitialData;
    this.InvoiceVisibilityMenuItem = this.MenuItems.find((x) => x.Label === 'Invoice Visibility');

    // Configure menu based on feature flags
    this.incidentManagementOn = this.startupService.backofficeEventManagement;
    this.invoiceVisibilityOn = this.startupService.invoiceVisibility;

    if (this.incidentManagementOn && this.MenuItems) {
      for (const parent of this.MenuItems) {
        let tlAudit = parent.Children.find((x) => x.Label === 'TL Audit');
        if (tlAudit != undefined && tlAudit !== null) {
          tlAudit.routerLink = ['tl-audit-incident'];
        }
      }
    }

    let invoiceVisibilityIndex = this.MenuItems.findIndex((x) => x.Label === 'Invoice Visibility');
    if (invoiceVisibilityIndex >= 0 && !this.invoiceVisibilityOn) {
      this.MenuItems.splice(invoiceVisibilityIndex, 1);
    }
    else if (invoiceVisibilityIndex < 0 && this.invoiceVisibilityOn) {
      this.MenuItems.push(this.InvoiceVisibilityMenuItem);
    }
  }

  openMenu() {
    const root = document.getElementsByTagName('html')[0];
    this.isMenuActive = !this.isMenuActive;
    if (this.isMenuActive) {
      root.classList.add('clipped');
    } else {
      root.classList.remove('clipped');
    }
  }
  ngOnDestroy() {
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('initials');
  }

  logout() {
    this.authService.logout();
  }

  get userName(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem('userName') || '';
    }
    sessionStorage.setItem('userName', this.authService.BlueShipUser.name);
    return this.authService.BlueShipUser.name;
  }

  get initials(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem('initials') || '';
    }
    const split = this.authService.BlueShipUser.name.split(' ');
    const initials = split.length > 1 ? `${split[0].substring(0, 1)}${split[1].substring(0, 1)}` : split[0].substring(0, 2);
    sessionStorage.setItem('initials', initials);
    return initials;
  }

  get enterpriseName(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem('enterpriseName') || '';
    }
    const enterpriseName = this.authService.BlueShipUser.enterpriseName;
    sessionStorage.setItem('enterpriseName', enterpriseName);
    return enterpriseName;
  }

  hasOperation(operations: string[]) {
    // if no operations are set on the menu item, let them see the link
    if (!operations) {
      return true;
    }

    // if the user has any of the operations, let them see the link
    return operations.reduce((prev, curr) => {
      if (this.helpers.hasOperation(curr)) {
        return true;
      }
      return prev;
    }, false);
  }

  // Search control
  ngAfterViewInit() { }
}
