import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { StartupService } from '@/startup.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { ShipmentService } from '../../services/shipment.service';
import { AuthOperation } from '@/auth/AuthOperation';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  refreshIntervalId: number;
  callCount: number;
  searchText: string;
  hideAudit = false;
  hideBadDebt = false;
  hideInvoices = false;
  hideShipmentSearch = false;
  hasShipmentSearchAdmin = false;
  hideCustomerProfile = true;
  hideProactive = true;
  hideTLHold = true;
  hideNewAudit = true;
  openedOkDialog = false;
  searchFilterChecked = false;
  searchTextType = 'Search'
  helpers: Helpers;
  incidentManagementOn: boolean;
  hideInvoiceVisibility: boolean;
  hideInvoiceImport: boolean;

  primaryText: string;
  primarysFiltered: string[] = null;
  primaryData: string[] = null;

  constructor(private authService: AuthService, private startupService: StartupService, private pcShippingService: ShipmentService, private store: Store<AppState>, private router: Router) {
    this.primaryData = [];
  }

  ngOnInit() {

    this.helpers = new Helpers(this.authService);
    this.callCount = 0;
    // show only the correct ones.
    this.hideAudit = !this.helpers.hasOperation('InvoiceAudit');
    this.hideInvoices = !this.helpers.hasOperation('CanWorkUnmatchedInvoices');
    this.hideShipmentSearch = !this.helpers.hasOperation('CanSearch_ShipmentInvoice');
    this.hasShipmentSearchAdmin =
      this.helpers.hasOperation('CanCreate_CarrierInvoice') || this.helpers.hasOperation('CanDelete_InvoiceAdmin');

    this.hideBadDebt = !this.helpers.hasOperation('CanViewCollection') && !this.helpers.hasOperation('CanWorkCollection');
    this.hideCustomerProfile = !this.helpers.hasOperation(AuthOperation.CanViewCustomerProfile) && !this.helpers.hasOperation(AuthOperation.CanEditCustomerProfile);
    this.hideProactive = !this.helpers.hasOperation('CanEditProactive');
    this.hideTLHold = !this.helpers.hasOperation('CanEditTLDocProactive');
    this.incidentManagementOn = this.startupService.backofficeEventManagement;
    this.hideInvoiceVisibility = this.startupService.invoiceVisibility;
    this.hideInvoiceImport = !this.helpers.hasOperation('CanImport_Invoice');
  }

  public closeOkConfirm() {
    this.openedOkDialog = false;
  }

  get isSearchTextValid(): boolean {
    return !!this.searchText;
  }

  get tlAuditPath() {
    if (this.incidentManagementOn) {
      return '/tl-audit-incident';
    } else {
      return '/truckload-audit';
    }
  }

  searchFilterCheckedEvent(event) {
    this.searchFilterChecked = event.target.checked;
    this.searchTextType = this.searchFilterChecked ? "Submit" : "Search";
  }

  searchInputKeyUp(e: KeyboardEvent): void {
    if (e.keyCode === 13 && this.isSearchTextValid) {
      e.preventDefault();
      this.sendToSearchPage();
    }
  }

  sendToSearchPage(): void {
    this.router.navigateByUrl(`/shipment-search?q=${this.searchText}`);
  }

  searchSendToSearchPage(): void {
    this.router.navigateByUrl(`/shipment-search?q=${this.primaryText}`);
  }

  public handlePrimaryFilterChange(filter: any): void {
    filter.trim();
    if (filter.length === 5) {
      this.searchPrimarys(filter)
    }
    else if (filter.length > 5) {
      this.primarysFiltered = this.primaryData.filter((s) => s.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    }
    else {
      this.primarysFiltered = [];
      this.primaryData = [];
    }
  }

  private searchPrimarys(filter) {
    this.pcShippingService
      .searchPrimarys(filter, this.startupService.daysBack)
      .subscribe((ep) => {
        this.primarysFiltered = ep;
        this.primaryData = ep;
      });
  }
}
