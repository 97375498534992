import { ConvertJSONToCSVHelper, FormInvoiceReportsCtrls, HEADER_CLASS, IAgedCarrierInvoices, IExportDateInvoiceReport } from '@/models/invoice-reports.interface';
import { InvoiceService } from '@/services/invoice.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SortDescriptor } from '@progress/kendo-data-query';
@Component({
  selector: 'app-invoice-reports',
  templateUrl: './invoice-reports.component.html',
  styleUrls: ['./invoice-reports.component.scss'],
  host: {
    class: 'table-custom',
  }
})
export class InvoiceReportsComponent implements OnInit {

  formGrup: FormGroup;
  ctrlFrm = FormInvoiceReportsCtrls;

  day = new Date().getDate();
  month = new Date().getMonth();
  year = new Date().getFullYear();

  todayDateStart = new Date(this.year, this.month, this.day);
  todayDateEnd = new Date(this.year, this.month, this.day);
  today = new Date(this.year, this.month, this.day);

  loading: boolean = false;
  dataTable: IAgedCarrierInvoices[] = [];
  dataCsvInvoice: any[] = [];
  convertJson: string = '';
  headerClass = HEADER_CLASS.style;
  sort: SortDescriptor[] = [{ field: 'extractedValue', dir: 'asc' }];

  constructor(private formBuild: FormBuilder, private invoiceService: InvoiceService) { }

  ngOnInit(): void {
    this.initializeFrm();
    this.searchDataTables();
  }

  initializeFrm(): void {
    this.formGrup = this.formBuild.group({
      [this.ctrlFrm.startDate]: [new Date(this.todayDateStart.setDate(this.todayDateStart.getDate() - 90)), [Validators.required]],
      [this.ctrlFrm.endDate]: [new Date(this.todayDateEnd.setDate(this.todayDateEnd.getDate() - 30)), [Validators.required]]
    });

  }

  searchDataTables(): void {
    this.loading = true;
    this.convertJson = '';
    this.dataCsvInvoice = [];
    this.invoiceService.getAgedCarrierInvoices(this.formGrup.value).subscribe((data) => {
      this.dataTable = data;

      if (data.length > 0) {
        data.map((info) =>
          this.dataCsvInvoice.push({
            PRO: info.pro,
            AgedDate: info.agedDays,
            ExtractNumber: info.extractedValue,
            Status: info.friendlyName,
            PrimaryReference: info.primaryReference,
            CarrierTotal: info.serviceTotal,
            Scac: info.scac,
            MC: info.mcNumber
          }))

        this.convertJson = ConvertJSONToCSVHelper(this.dataCsvInvoice);
      } else {
        this.convertJson = '';
      }
      this.loading = false;
    });
  }

  onChangeDateStart(value: Date): void {
    if (this.formGrup.value.endDate < value)
      this.formGrup.controls[this.ctrlFrm.endDate].setValue(null);
  }

  onChangeDateEnd(value: Date): void {
    if (this.formGrup.value.startDate > value)
      this.formGrup.controls[this.ctrlFrm.startDate].setValue(null);
  }

  downloadExportData(csvPreppedData: string) {
    if (csvPreppedData && csvPreppedData.length > 0) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + escape(csvPreppedData));
      const fileName = 'Export-All-Invoice-Report-' + new Date().getDate();
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
