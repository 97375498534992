import { TLAuditDetailState } from '../models/tl-audit-detail-state.model';
import * as TLAuditDetailActions from '../actions/tl-audit-detail.actions';

const initialState: TLAuditDetailState = {
  showManageReasons: false,
  manageReasonsSaveInProgress: false,
  saveReasonSuccess: false,
  saveReasonError: null,
  tlAudit: null,
  tlAuditReasonList: null,
  loadingTLAudit: false,
  loadingTLAuditSuccess: false,
  loadingTLAuditError: null,
  tlIncidentTypes: null,
  shipmentID: 0,
  invoiceID: 0,
  tlAuditID: 0,
  updateReasonSuccess: false,
  updateReasonError: null,
  addCommentSuccess: false,
  addCommentError: null,
};
export function TLAuditDetailReducer(state: TLAuditDetailState = initialState, action: TLAuditDetailActions.Actions) {
  switch (action.type) {
    case TLAuditDetailActions.SHOW_MANAGE_REASON: {
      return { ...state, showManageReasons: action.visible };
    }
    case TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL: {
      return {
        ...state,
        loadingTLAudit: true,
        invoiceID: action.invoiceID,
        shipmentID: action.shipmentID,
        saveReasonSuccess: false,
        saveReasonError: null,
        addCommentSuccess: false,
        addCommentError: null,
        updateReasonSuccess: false,
        updateReasonError: null,
        tlAudit: null,
        tlAuditReasonList: null,
      };
    }
    case TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL_SUCCESS: {
      return {
        ...state,
        loadingTLAudit: false,
        loadingTLAuditSuccess: true,
        tlIncidentTypes: action.reasonData,
        tlAuditReasonList: action.tlAuditDetail,
      };
    }
    case TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL_ERROR: {
      return { ...state, loadingTLAudit: false, loadingTLAuditSuccess: false, loadingTLAuditError: action.message };
    }
    case TLAuditDetailActions.SAVE_REASON: {
      return {
        ...state,
        saveReasonSuccess: false,
        saveReasonError: null,
        addCommentSuccess: false,
        addCommentError: null,
        manageReasonsSaveInProgress: true,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_SUCCESS: {
      const currentAudit = state.tlAuditReasonList;
      const currentReasons = currentAudit.reasons;
      currentReasons
        .splice(0, 0, action.newSavedReason)
        .sort((a, b) => +b.status.localeCompare(a.status) || +a.reason.localeCompare(b.reason) - 1);
      currentAudit.reasonString = currentReasons.map((r) => r.reason).toString();
      return {
        ...state,
        manageReasonsSaveInProgress: false,
        saveReasonSuccess: true,
        tlAuditReasonList: currentAudit,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_ERROR: {
      return { ...state, manageReasonsSaveInProgress: false, saveReasonSuccess: false, saveReasonError: action.message };
    }
    case TLAuditDetailActions.SAVE_REASON_STATUS: {
      return {
        ...state,
        saveReasonSuccess: false,
        saveReasonError: null,
        addCommentSuccess: false,
        addCommentError: null,
        updateReasonError: null,
        updateReasonSuccess: false,
        manageReasonsSaveInProgress: true,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_STATUS_SUCCESS: {
      const currentAudit = state.tlAuditReasonList;
      const currentReason = currentAudit.reasons.filter((x) => x.reasonID === action.updatedReason.reasonID);
      if (currentReason && currentReason.length === 1) {
        currentReason[0].status = action.updatedReason.status;
        currentReason[0].comments = action.updatedReason.comments;
      }
      currentAudit.reasons.sort((a, b) => +b.status.localeCompare(a.status) || +a.reason.localeCompare(b.reason) - 1);
      currentAudit.reasonString = currentAudit.reasons.map((r) => r.reason).toString();

      return {
        ...state,
        manageReasonsSaveInProgress: false,
        updateReasonSuccess: true,
        tlAuditReasonList: currentAudit,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_STATUS_ERROR: {
      return { ...state, manageReasonsSaveInProgress: false, updateReasonSuccess: false, updateReasonError: action.message };
    }
    case TLAuditDetailActions.ADD_COMMENT: {
      return {
        ...state,
        addCommentSuccess: false,
        addCommentError: null,
        saveReasonSuccess: false,
        saveReasonError: null,
        updateReasonSuccess: false,
        updateReasonError: null,
        manageReasonsSaveInProgress: true,
      };
    }
    case TLAuditDetailActions.ADD_COMMENT_SUCCESS: {
      const currentAudit = state.tlAuditReasonList;
      const currentReason = currentAudit.reasons.filter((x) => x.reasonID === action.newSavedComment.reasonID);
      if (currentReason && currentReason.length === 1) {
        const currentComments = currentReason[0].comments;
        if (currentComments) {
          currentComments.splice(0, 0, action.newSavedComment);
        } else {
          currentReason[0].comments = [action.newSavedComment];
        }
      }
      return {
        ...state,
        manageReasonsSaveInProgress: false,
        addCommentSuccess: true,
        tlAuditReasonList: currentAudit,
      };
    }
    case TLAuditDetailActions.ADD_COMMENT_ERROR: {
      return { ...state, manageReasonsSaveInProgress: false, addCommentSuccess: false, addCommentError: action.message };
    }

    default:
      return state;
  }
}
