export interface IDuplicateCarrier {
  pendingInvoiceID: number;
  primaryReference: string;
  pro: string;
  carrierInvoiceNumber: string;
  scac: string;
  invoiceTotal: number;
  mode: string;
  carrierName: string;
  customerAccountNumber: string;
  invoiceDate: Date;
  dateCreated: Date;
}

export type IExportDuplicate = Omit<IDuplicateCarrier, 'pendingInvoiceID'>;
export interface IDatesDuplicateCarrier {
  startDate: string;
  endDate: string;
}

export interface IResetDuplicateCarrier {
  pendingInvoiceID: number;
  skipDuplicateCheck: boolean;
}

export enum FormDuplicateCarrierCtrls {
  startDate = 'startDate',
  endDate = 'endDate'
}

export interface IDeleteDuplicateCarrier {
  pendingInvoiceIDs: number[]
}
export class FormatDataExportExcel {

  static formt(data: IDuplicateCarrier[]): Array<IExportDuplicate> {
    return data.map((info) => {
      return {
        primaryReference: info.primaryReference,
        pro: info.pro,
        carrierInvoiceNumber: info.carrierInvoiceNumber,
        scac: info.scac,
        invoiceTotal: info.invoiceTotal,
        mode: info.mode,
        carrierName: info.carrierName,
        customerAccountNumber: info.customerAccountNumber,
        invoiceDate: info.invoiceDate,
        dateCreated: info.dateCreated,
      }
    });
  }

  static managementFormt(data: any[]): Array<any> {

    return data.map((info) => {
      return {
        Primary: info.primaryReference,
        Invoice: info.invoiceNumber,
        Owner: info.customerName,
        AccountNumber: info.customerAccountNumber,
        BillTo: info.BilToFull,
        Shipper: info.shipper,
        SalesRep: info.salesRep,
        Department: info.department,
        DepartmentGroup: info.departmentGroup,
        Origin: `${info.originCity} - ${info.origin.state}`,
        Receiver: info.receiver,
        Destination: `${info.destination.city} -${info.destination.state} - ${info.destination.zip} `,
        CarrierName: info.carrierName,
        Total: info.invoiceTotal,
        PickupDate: info.pickupDate,
        Aged: info.aged,
        ReadyToConvert: info.flagStatus === "Flagged" ? 'Yes' : 'No'
      }
    });
  }

  static proactiveHoldFormat(data: any[]): Array<any> {

    return data.map((info) => {
      return {
        Owner: info.owner,
        Primary: info.primaryReference,
        Pro: info.pro,
        InvoiceGP: info.invoiceGP,
        NotificationSent: info.sent ? 'Yes' : 'No'
      }
    });
  }
}

export function ConvertJSONToCSVHelper(uninvoicedData: any[]): string {
  const json = uninvoicedData;

  const replacer = function (key, value) {
    return value === null ? '' : value;
  };

  const header = Object.keys(json[0]);
  const csv = json.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  const finalcsv = csv.join('\r\n');
  return finalcsv;
}
