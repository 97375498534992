export enum AuthOperation {
    CanImport_Invoice = 'CanImport_Invoice',
    CanEditCustomerProfile = 'CanEditCustomerProfile',
    CanViewCustomerProfile = 'CanViewCustomerProfile',
    CanWorkUnmatchedInvoices = 'CanWorkUnmatchedInvoices',
    CanViewCollection = 'CanViewCollection',
    CanWorkCollection = 'CanWorkCollection',
    CanEditProactive = 'CanEditProactive',
    CanEditTLDocProactive = 'CanEditTLDocProactive',
    InvoiceAudit = 'InvoiceAudit',
    CanAccess_InvoiceVisibility = 'CanAccess_InvoiceVisibility'
}