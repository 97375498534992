import { PendingAddressResponse } from '@/services/Invoice';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {

  @Input() data: any;
  @Input() hideName: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
