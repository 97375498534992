import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import * as TLIncidentAssignmentActions from '../actions/tl-audit-incident-assignment.actions';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TLAuditService } from '../services/tl-audit.service';
import { AssignmentIncidentUI, TruckloadAuditReasonAssignmentUI } from '../models/TruckloadAudit.ui';
import { MassAssignmentDTO } from '../dto/IncidentDTO';

@Injectable()
export class TLAuditIncidentAssignmentEffects {
  @Effect()
  retrieveTLAuditorList$ = this.actions$.pipe(
    ofType(TLIncidentAssignmentActions.LOAD_TL_AUDITOR_LIST),
    mergeMap((action: TLIncidentAssignmentActions.LoadTLAuditorList) =>
      this.tlAuditServicce.getAuditors().pipe(
        map((data) => {
          return new TLIncidentAssignmentActions.LoadTLAuditorListSuccess(data);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLIncidentAssignmentActions.LoadTLAuditorListError(err.message));
        })
      )
    )
  );
  @Effect()
  updateAuditor$ = this.actions$.pipe(
    ofType(TLIncidentAssignmentActions.UPDATE_AUDITOR),
    mergeMap((action: TLIncidentAssignmentActions.UpdateAuditor) => {
      return this.tlAuditServicce
        .addOrUpdateAuditor(
          action.updateAuditor.invoiceIDs,
          action.updateAuditor.name,
          action.updateAuditor.email,
          action.updateAuditor.userName
        )
        .pipe(
          map((data: MassAssignmentDTO) => {
            const assignmentList = data.assignedIncidents.map((result) => {
              if (result) {
                return new AssignmentIncidentUI(
                  result.invoice ? result.invoice.invoiceID : 0,
                  result.incidentID,
                  result.assignments[0].assignmentID
                );
              }
            }) as AssignmentIncidentUI[];
            if (assignmentList.length > 0) {
              const assignmentUI = new TruckloadAuditReasonAssignmentUI(data.assignedIncidents[0].assignments[0], assignmentList);
              return new TLIncidentAssignmentActions.UpdateAuditorSuccess(assignmentUI);
            }
            return of(new TLIncidentAssignmentActions.UpdateAuditorError('Add Auditor failed'));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new TLIncidentAssignmentActions.UpdateAuditorError(err.message));
          })
        );
    })
  );

  @Effect()
  removeAuditorFromAudit$ = this.actions$.pipe(
    ofType(TLIncidentAssignmentActions.REMOVE_AUDITOR_TO_AUDIT),
    mergeMap((action: TLIncidentAssignmentActions.RemoveAuditorFromAudit) =>
      this.tlAuditServicce.removeAuditor(action.auditorID[0]).pipe(
        map((data) => {
          return new TLIncidentAssignmentActions.UpdateAuditorSuccess(null);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLIncidentAssignmentActions.UpdateAuditorError(err.message));
        })
      )
    )
  );

  constructor(private actions$: Actions, private tlAuditServicce: TLAuditService) {}
}
