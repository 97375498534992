import { AuditUserResponse } from '@/services/InvoiceAudit';
import { Action } from '@ngrx/store';
import { UpdateAssignment } from '../models/TLAudit';
import { TruckloadAuditReasonAssignmentUI, TruckloadAuditUI } from '../models/TruckloadAudit.ui';

export const SHOW_ASSIGN_AUDITOR = 'Assign Auditor';

export const LOAD_TL_AUDITOR_LIST = '[Finance API] Load All TL Auditors';
export const LOAD_TL_AUDITOR_LIST_SUCCESS = '[Finance API] Load All TL Auditors Success';
export const LOAD_TL_AUDITOR_LIST_ERROR = '[Finance API] Load All TL Auditors Error';
export const REMOVE_AUDITOR_TO_AUDIT = '[Incident API] Remove Auditor from Audit';
export const UPDATE_AUDITOR = '[Incident API] Update Auditor for Audit';
export const UPDATE_AUDITOR_SUCCESS = '[Incident API] Update Auditor for Audit Success';
export const UPDATE_AUDITOR_ERROR = '[Incident API] Update Auditor for Audit Error';

export class ShowAssignAuditor implements Action {
  readonly type = SHOW_ASSIGN_AUDITOR;
  constructor(public invoiceIDs: number[], public auditorAssignment: TruckloadAuditReasonAssignmentUI, public visible: boolean) {}
}

export class LoadTLAuditorList implements Action {
  readonly type = LOAD_TL_AUDITOR_LIST;
  constructor() {}
}
export class LoadTLAuditorListSuccess implements Action {
  readonly type = LOAD_TL_AUDITOR_LIST_SUCCESS;
  constructor(public auditors: AuditUserResponse[]) {}
}
export class LoadTLAuditorListError implements Action {
  readonly type = LOAD_TL_AUDITOR_LIST_ERROR;
  constructor(public message: string) {}
}
export class RemoveAuditorFromAudit implements Action {
  readonly type = REMOVE_AUDITOR_TO_AUDIT;
  constructor(public auditorID: number[]) {}
}
export class UpdateAuditor implements Action {
  readonly type = UPDATE_AUDITOR;
  constructor(public updateAuditor: UpdateAssignment) {}
}
export class UpdateAuditorSuccess implements Action {
  readonly type = UPDATE_AUDITOR_SUCCESS;
  constructor(public newSavedAuditor: TruckloadAuditReasonAssignmentUI) {}
}
export class UpdateAuditorError implements Action {
  readonly type = UPDATE_AUDITOR_ERROR;
  constructor(public message: string) {}
}

export type Actions =
  | ShowAssignAuditor
  | RemoveAuditorFromAudit
  | LoadTLAuditorList
  | LoadTLAuditorListSuccess
  | LoadTLAuditorListError
  | UpdateAuditor
  | UpdateAuditorSuccess
  | UpdateAuditorError;
