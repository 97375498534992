import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ShipmentEnterpriseReference, SaveShipmentReference } from '@/models/ShipmentReference';
import { ConfirmationDialogService } from '@/_shared/confirmation-dialog/confirmation-dialog.service';
import { ShipmentService } from '@/services/shipment.service';
import { ShipmentUI } from '@/pages/audit/Shipment.ui';
import ui = ShipmentUI;
import { ReturnFilter } from '@/_shared/FilterFactory';

@Component({
  selector: 'app-references-edit',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./references-edit.component.scss'],
  templateUrl: './references-edit.component.html',
})
export class ReferencesEditComponent implements OnInit {
  get theRecords(): ui.ReferenceUI[] {
    return this.records;
  }

  @Input('theRecords')
  set theRecords(value: ui.ReferenceUI[]) {
    this.records = value;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }
  @Input('showAllReferences')
  set showAllReferences(value: boolean) {
    this._showAllReferences = value;
  }

  @Input('shipmentID')
  set shipmentID(shipmentID: number) {
    this._shipmentID = shipmentID;
  }

  @Input('enterpriseID')
  set enterpriseID(enterpriseID: number) {
    this._enterpriseID = enterpriseID;
  }

  @Output()
  saveComplete = new EventEmitter<string>();

  @Output()
  saveError = new EventEmitter<string>();

  @Output()
  saveStart = new EventEmitter<string>();

  get controls() {
    return this.referenceNewForm.controls;
  }
  get controlsEdit() {
    return this.referenceEditForm.controls;
  }

  // sent in
  _shipmentID: number;
  _enterpriseID: number;
  _showAllReferences: boolean;
  records: ui.ReferenceUI[] = [];
  isDataLoading: boolean;

  // form variables
  referenceNewForm: FormGroup = null;
  referenceEditForm: FormGroup = null;
  addEnterpriseReference = new AddEnterpriseReference();
  // process variables
  newlyAddedReference: any = null;
  isAddNewMode = false;
  isSaving = false;
  enterpriseReferences: ShipmentEnterpriseReference[] = [];
  newReferenceValues: string[] = [];
  newReferenceType: ShipmentEnterpriseReference = null;
  currentDataItem: any = null;
  previousValue: string;
  referenceEditMode: false;
  isAddError = false;
  editedRowIndex: number;
  // holds all references even once we do not display
  theRecordsWithPrimary: ui.ReferenceUI[] = [];

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private shipmentService: ShipmentService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.theRecordsWithPrimary = this.theRecords;
    this.setDisplayReferences();
    this.loadEnterpriseReferences();
  }

  // add a new reference
  protected onReferenceTypeChange(type: string): void {
    const obj = this.enterpriseReferences.find((x) => x.type === this.controls.newReferenceTypeDropdown.value);
    this.controls.newReferenceValueDropDown.setValue('');
    this.controls.newReferenceValueInput.setValue('');
    this.newReferenceValues = obj.values;
  }
  protected onNewReferenceSave() {
    // must send a list to our save
    const references: SaveShipmentReference[] = [];
    const value =
      this.controls.newReferenceValueDropDown.value !== ''
        ? this.controls.newReferenceValueDropDown.value
        : this.controls.newReferenceValueInput.value;

    // ensure its not added already
    if (this.alreadyAdded(value, this.controls.newReferenceTypeDropdown.value, 1)) {
      return;
    }

    // is this a PRO
    if (this.controls.newReferenceTypeDropdown.value === 'PRO') {
      this.onNewReferenceCancel();
      this.saveShipmentProReference(value);
    } else {
      // add it to the list
      references.push(new SaveShipmentReference(0, this.controls.newReferenceTypeDropdown.value, value, false));

      // hold on to the new one
      this.newlyAddedReference = { value: value, type: this.controls.newReferenceTypeDropdown.value };

      // add/map existing references
      this.theRecordsWithPrimary.forEach((item) => {
        if (item.type !== 'PRO') {
          references.push(new SaveShipmentReference(item.id, item.type, item.value, item.isPrimary));
        }
      });

      // add the records
      this.onNewReferenceCancel();
      this.saveGeneralNonPROShipmentReference(references, 'Saving Reference...', 'Successfully added a new reference.');
    }
  }
  private onNewReferenceCancel() {
    this.isAddError = false;
    this.controls.newReferenceTypeDropdown.setValue('');
    this.controls.newReferenceValueDropDown.setValue('');
    this.controls.newReferenceValueInput.setValue('');
    this.isAddNewMode = false;
    this.isSaving = false;
  }

  // editing existing item
  protected onEditReference(event, dataItem): void {
    if (dataItem.isEditMode) {
      return;
    }
    this.onCancelSaveReference(null, this.currentDataItem);
    this.previousValue = dataItem.value;
    dataItem.isEditMode = true;
    this.currentDataItem = dataItem;
    this.referenceEditForm = this.createEditReferenceForm();
  }
  public onSaveReference(event, dataItem) {
    // ensure its not added already
    if (this.alreadyAdded(dataItem.value, dataItem.type, 2)) {
      return;
    }

    // is the form in error
    if (this.disabledEditSaveBtn()) {
      return;
    }
    // close the edit mode
    dataItem.isEditMode = false;

    // is this a PRO
    if (dataItem.type === 'PRO') {
      this.updateShipmentProReference(dataItem.id, dataItem.value);
    } else {
      // obtain all the references
      const references = [];
      this.theRecordsWithPrimary.forEach((item) => {
        if (item.type !== 'PRO') {
          if (dataItem.id === item.id) {
            const existingReference = new SaveShipmentReference(dataItem.id, dataItem.type, dataItem.value, dataItem.isPrimary);
            references.push(existingReference);
          } else {
            const existingReference = new SaveShipmentReference(item.id, item.type, item.value, item.isPrimary);
            references.push(existingReference);
          }
        }
      });
      this.saveGeneralNonPROShipmentReference(references, 'Saving Reference...', 'The reference was successfully saved.');
    }
  }
  public onCancelSaveReference(event, dataItem) {
    if (dataItem) {
      dataItem.isEditMode = false;
    }

    // put back the old value?
    if (this.currentDataItem) {
      this.currentDataItem.value = this.previousValue;
      this.currentDataItem = null;
    }
    this.referenceEditForm = null;
  }

  protected onRemoveReference(event, theRecord) {
    this.confirmationDialogService
      .confirm('Confirm Deletion', 'Do you want to delete this reference?', 'Delete', 'Cancel')
      .then((result) => {
        if (result === true) {
          // is this a PRO
          if (theRecord.type === 'PRO') {
            this.removeShipmentProReference(theRecord.id);
          } else {
            const references = [];
            this.theRecordsWithPrimary.forEach((item) => {
              if (item.type !== 'PRO') {
                if (item.id !== theRecord.id) {
                  references.push(new SaveShipmentReference(item.id, item.type, item.value, item.isPrimary));
                }
              }
            });

            // add new refereces without the deleted one
            this.saveGeneralNonPROShipmentReference(references, 'Removing Reference...', 'Successfully removed the reference.');
          }
        }
      });
  }

  protected disabledSaveBtn(): boolean {
    return (!this.controls.newReferenceValueDropDown.value && !this.controls.newReferenceValueInput.value) || this.isSaving;
  }

  protected disabledEditSaveBtn(): boolean {
    return (
      (this.controlsEdit.editReferenceValueDropDown !== null &&
        (this.controlsEdit.editReferenceValueDropDown.value === undefined || this.controlsEdit.editReferenceValueDropDown.value === '')) ||
      this.controlsEdit.editReferenceValueInput.value === ''
    );
  }
  private setReferenceEditFormValidators() {
    this.controlsEdit.editReferenceValueInput.setValidators(null);
    this.controlsEdit.editReferenceValueDropDown.setValidators(null);
  }

  // helpers
  private alreadyAdded(value: string, type: string, errorCount: number): boolean {
    this.isAddError = false;
    const items = this.theRecordsWithPrimary.filter((obj) => obj.value === value && obj.type === type);
    if (items.length >= errorCount) {
      this.isAddError = true;
    }
    return this.isAddError;
  }
  private createEditReferenceForm(): FormGroup {
    return this.formBuilder.group({
      editReferenceValueInput: [this.addEnterpriseReference.value, [Validators.required]],
      editReferenceValueDropDown: [this.addEnterpriseReference.value, [Validators.required]],
    });
  }
  private createNewReferenceForm() {
    this.referenceNewForm = this.formBuilder.group({
      newReferenceTypeDropdown: [this.addEnterpriseReference.type],
      newReferenceValueInput: [this.addEnterpriseReference.value, [Validators.required]],
      newReferenceValueDropDown: [this.addEnterpriseReference.value, [Validators.required]],
    });
  }
  private moveProReference() {
    this.records.unshift(
      this.records.splice(
        this.records.findIndex((item) => item.type === 'PRO'),
        1
      )[0]
    );
  }
  private sortReferencesAlpha(objectList: any[]) {
    objectList.sort(function (a, b) {
      const typeA = a.type.toUpperCase();
      const typeB = b.type.toUpperCase();
      if (typeA < typeB) {
        return -1;
      }
      if (typeA > typeB) {
        return 1;
      }
      return 0;
    });
  }

  private setDisplayReferences() {
    // remove primary from display
    this.theRecords = this.theRecordsWithPrimary.filter((obj) => obj.isPrimary !== true);
    // sort alpha
    this.sortReferencesAlpha(this.theRecords);

    // only if any are new do we move them to front
    const newRecords = this.theRecords.filter((elem) => elem.isNew === true);
    if (newRecords.length > 0) {
      newRecords.forEach((elem) => {
        this.records.unshift(
          this.records.splice(
            this.records.findIndex((obj) => obj.id === elem.id),
            1
          )[0]
        );
      });
    }

    // move latest pro to front
    this.moveProReference();
  }

  private removeNewFlag() {
    // remove new flag  from display
    const newFlagList = this.theRecordsWithPrimary.filter((elem) => elem.isNew === true);
    if (newFlagList.length > 0) {
      newFlagList.forEach((x) => {
        x.isNew = false;
      });
    }
  }

  // service calls
  private loadEnterpriseReferences(): void {
    this.shipmentService.getShipmentEnterpriseReferences(this._enterpriseID).subscribe((data) => {
      this.enterpriseReferences = data.references;
      if (!data.references.some((x) => x.type === 'PRO')) {
        const reference = new ShipmentEnterpriseReference();
        reference.type = 'PRO';
        reference.values = [];
        this.enterpriseReferences.push(reference);
      }
      this.sortReferencesAlpha(this.enterpriseReferences);
      this.createNewReferenceForm();
    });
  }

  // Update a PRO reference
  private updateShipmentProReference(id: number, value: string) {
    this.isSaving = true;
    this.saveStart.emit('Updating Reference...');
    this.shipmentService.updateShipmentProReference(id, this._shipmentID, value).subscribe(
      (data) => {
        // remove any new flags
        this.removeNewFlag();
        // an edit actually returns a new record (???) Sooo....
        // remove the pros from the current list
        this.theRecordsWithPrimary = this.theRecordsWithPrimary.filter((obj) => obj.type !== 'PRO');

        // loop through the returned pros and create the PRO references
        data.proNumbers.forEach((item) => {
          this.theRecordsWithPrimary.push(new ui.ReferenceUI(item.proNumberID, 'PRO', item.proNumber, false, false, true, false, [], ''));
        });

        // remove primary but show updated pro
        // sort alpha move
        // pro to front
        this.setDisplayReferences();
        this.onNewReferenceCancel();
        this.saveComplete.emit('The reference was successfully updated.');
        this.cd.detectChanges();
      },
      (err) => {
        this.saveError.emit(`Error updating the reference. ${err}`);
        this.isSaving = false;
      }
    );
  }

  // Remove a PRO item
  private removeShipmentProReference(id: number) {
    this.isSaving = true;
    this.saveStart.emit('Removing Reference...');
    this.shipmentService.removeShipmentProReference(id, this._shipmentID).subscribe(
      (data) => {
        // remove any new flags
        this.removeNewFlag();
        // remove the item from the
        this.theRecordsWithPrimary = this.theRecordsWithPrimary.filter((obj) => obj.id !== id);
        // remove primary but show updated pro
        // sort alpha move
        // pro to front
        this.setDisplayReferences();
        this.onNewReferenceCancel();
        this.saveComplete.emit('The reference was successfully removed.');
        this.cd.detectChanges();
      },
      (err) => {
        this.saveError.emit(`Error removing the reference. ${err}`);
        this.isSaving = false;
      }
    );
  }

  // Add a PRO reference
  private saveShipmentProReference(value: string) {
    this.isSaving = true;
    this.saveStart.emit('Saving Reference...');
    this.shipmentService.saveShipmentProReference(value, this._shipmentID).subscribe(
      (data) => {
        // remove any new flags
        this.removeNewFlag();
        // remove the pros from the current list
        this.theRecordsWithPrimary = this.theRecordsWithPrimary.filter((obj) => obj.type !== 'PRO');

        // loop through the returned pros and create the PRO references
        data.proNumbers.forEach((item) => {
          this.theRecordsWithPrimary.push(new ui.ReferenceUI(item.proNumberID, 'PRO', item.proNumber, false, false, true, false, [], ''));
        });

        // remove primary but show updated pro
        // sort alpha move
        // pro to front
        this.setDisplayReferences();

        // latest pro should be first item in list, so mark it as new
        this.theRecords[0].isNew = true;
        this.saveComplete.emit('The reference was successfully saved.');
        this.onNewReferenceCancel();
        this.cd.detectChanges();
      },
      (err) => {
        this.saveError.emit(`Error aadding the reference. ${err}`);
        this.isSaving = false;
      }
    );
  }

  private saveGeneralNonPROShipmentReference(references: SaveShipmentReference[], startMessage: string, successMessage: string) {
    this.isSaving = true;
    this.saveStart.emit(startMessage);
    this.shipmentService.saveShipmentReferences(this._shipmentID, references).subscribe(
      (data) => {
        const holdPROList = this.theRecordsWithPrimary.filter((x) => x.type === 'PRO');
        this.theRecordsWithPrimary = data.references.map((item) => {
          const updatedReferences = new ui.ReferenceUI(
            item.referenceID,
            item.type,
            item.value,
            item.isPrimary,
            item.isRequired,
            item.isEditable,
            false,
            item.allowedValues,
            item.defaultValue
          );
          return updatedReferences;
        });

        // add pro reference back
        this.theRecordsWithPrimary = this.theRecordsWithPrimary.concat(holdPROList);
        // remove new flag
        this.removeNewFlag();

        if (this.newlyAddedReference !== null) {
          const foundRecords = this.theRecordsWithPrimary.filter(
            (elem) => elem.value === this.newlyAddedReference.value && elem.type === this.newlyAddedReference.type
          );
          foundRecords.forEach((item) => {
            item.isNew = true;
          });
          this.newlyAddedReference = null;
        }

        this.setDisplayReferences();

        this.saveComplete.emit(successMessage);
        this.onNewReferenceCancel();
        this.cd.detectChanges();
      },
      (err) => {
        this.saveError.emit(`Error updating references. ${err}`);
        this.isSaving = false;
      }
    );
  }
}

export class AddEnterpriseReference {
  type: string;
  value: string;
}
