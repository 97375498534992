export interface IDetailedSummary {
  importSource: string;
  extractedValue: string | number;
  importDate: any;
  blueShipGryphonAudit: number;
  carrierInvoiceShipmentNotFound: number;
  itInvoiceError: number;
  duplicateCarrierInvoices: number;
  unmatchedInvoicesGryphon: number;
  customerInvoiceErrorBlueShip: number;
  pendingtoREMS: number;
  invoiceBlockedFromREMS: number;
  manualProactiveGryphon: number;
  proactiveHoldGryphon: number;
  proactiveSentGryphon: number;
  invoicesExported: number;
  invoicesImported: number;
  invoicesDropped: number;
  totalInvoicesActual: number;
  inREMS: number;
}

export interface IFormDateCtrl {
  startDate: string,
  endDate: string
}

export function ConvertJSONToCSVHelper(uninvoicedData: IDetailedSummary[]): string {
  const json = uninvoicedData.map((info) => {
    return {
      InputMethod: info.importSource,
      ImportStamp: info.importDate,
      Extract: info.extractedValue,
      Exported: info.invoicesExported,
      Dropped: info.invoicesDropped,
      FileTotal: info.invoicesImported,
      Created: info.totalInvoicesActual,
      Duplicates: info.duplicateCarrierInvoices,
      Unmatched: info.unmatchedInvoicesGryphon,
      CarrierAudit: info.blueShipGryphonAudit,
      ManualProactive: info.manualProactiveGryphon,
      ProactiveHold: info.proactiveHoldGryphon,
      ProactiveSent: info.proactiveSentGryphon,
      CustomerInvErro: info.customerInvoiceErrorBlueShip,
      ITInvError: info.itInvoiceError,
      Blocked: info.invoiceBlockedFromREMS,
      REMSPending: info.pendingtoREMS,
      ShipmentNotFound: info.carrierInvoiceShipmentNotFound,
      InREMS: info.inREMS
    }
  });

  return generatecsv(json);
}

export function ConvertJSONToCSVHelperManually(uninvoicedData: IDetailedSummary[]): string {
  const json = uninvoicedData.map((info) => {
    return {
      InputMethod: info.importSource,
      ImportStamp: info.importDate,
      Created: info.totalInvoicesActual,
      Duplicates: info.duplicateCarrierInvoices,
      Unmatched: info.unmatchedInvoicesGryphon,
      CarrierAudit: info.blueShipGryphonAudit,
      ManualProactive: info.manualProactiveGryphon,
      ProactiveHold: info.proactiveHoldGryphon,
      ProactiveSent: info.proactiveSentGryphon,
      CustomerInvError: info.customerInvoiceErrorBlueShip,
      ITInvError: info.itInvoiceError,
      REMSPending: info.pendingtoREMS,
      InREMS: info.inREMS
    }
  });

  return generatecsv(json);
}

function generatecsv(json): string {

  const replacer = function (key, value) {
    return value === null ? '' : value;
  };

  const header = Object.keys(json[0]);
  const csv = json.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  const finalcsv = csv.join('\r\n');
  return finalcsv;
}

export class ColumnKendoGrid {
  columns = [
    {
      field: 'importSource',
      title: 'Input Method',
      width: 165,
      hidden: false
    },
    {
      field: 'importDate',
      title: 'Import Stamp',
      width: 170,
      hidden: false
    },
    {
      field: 'extractedValue',
      title: 'Extract',
      width: 120,
      hidden: false
    },
    {
      field: 'invoicesExported',
      title: 'Exported',
      width: 130,
      hidden: false
    },
    {
      field: 'invoicesDropped',
      title: 'Dropped',
      width: 135,
      hidden: false
    },
    {
      field: 'invoicesImported',
      title: 'File Total',
      width: 135,
      hidden: false
    },
    {
      field: 'totalInvoicesActual',
      title: 'Created',
      width: 120,
      hidden: false
    },
    {
      field: 'duplicateCarrierInvoices',
      title: 'Duplicates',
      width: 145,
      hidden: false
    },
    {
      field: 'unmatchedInvoicesGryphon',
      title: 'Unmatched',
      width: 150,
      hidden: false
    },
    {
      field: 'blueShipGryphonAudit',
      title: 'Carrier Audit',
      width: 160,
      hidden: false
    },
    {
      field: 'manualProactiveGryphon',
      title: 'Manual Proactive',
      width: 195,
      hidden: false
    },
    {
      field: 'proactiveHoldGryphon',
      title: 'Proactive Hold',
      width: 185,
      hidden: false
    },
    {
      field: 'proactiveSentGryphon',
      title: 'Proactive Sent',
      width: 190,
      hidden: false
    },
    {
      field: 'customerInvoiceErrorBlueShip',
      title: 'Customer Inv Error',
      width: 210,
      hidden: false
    },
    {
      field: 'itInvoiceError',
      title: 'IT Inv Error',
      width: 155,
      hidden: false
    },
    {
      field: 'invoiceBlockedFromREMS',
      title: 'Blocked',
      width: 125,
      hidden: false
    },
    {
      field: 'pendingtoREMS',
      title: 'REMS Pending',
      width: 180,
      hidden: false
    },
    {
      field: 'carrierInvoiceShipmentNotFound',
      title: 'Shipment Not Found',
      width: 220,
      hidden: false
    },
    {
      field: 'inREMS',
      title: 'In REMS',
      width: 145,
      hidden: false
    }
  ];

  columnsManually = [
    {
      field: 'importSource',
      title: 'Input Method',
      width: 165,
      hidden: false
    },
    {
      field: 'importDate',
      title: 'Import Stamp',
      width: 170,
      hidden: false
    },
    {
      field: 'totalInvoicesActual',
      title: 'Created',
      width: 120,
      hidden: false
    },
    {
      field: 'duplicateCarrierInvoices',
      title: 'Duplicates',
      width: 145,
      hidden: false
    },
    {
      field: 'unmatchedInvoicesGryphon',
      title: 'Unmatched',
      width: 150,
      hidden: false
    },
    {
      field: 'blueShipGryphonAudit',
      title: 'Carrier Audit',
      width: 160,
      hidden: false
    },
    {
      field: 'manualProactiveGryphon',
      title: 'Manual Proactive',
      width: 195,
      hidden: false
    },
    {
      field: 'proactiveHoldGryphon',
      title: 'Proactive Hold',
      width: 185,
      hidden: false
    },
    {
      field: 'proactiveSentGryphon',
      title: 'Proactive Sent',
      width: 190,
      hidden: false
    },
    {
      field: 'customerInvoiceErrorBlueShip',
      title: 'Customer Inv Error',
      width: 210,
      hidden: false
    },
    {
      field: 'itInvoiceError',
      title: 'IT Inv Error',
      width: 185,
      hidden: false
    },
    {
      field: 'pendingtoREMS',
      title: 'REMS Pending',
      width: 180,
      hidden: false
    },
    {
      field: 'inREMS',
      title: 'In REMS',
      width: 145,
      hidden: false
    }
  ]
}
