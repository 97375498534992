import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TLEditUI } from '../../models/TLEdit.ui';
import { TLAuditEditHelperService } from '../../services/tl-audit-edit-helper.service';
import { AppState } from '../../../../appstate.model'
import { CurrencyService } from '@/services/currency.service';
import { Enterprise } from '@/services/Enterprise';
@Component({
  selector: 'app-tl-buy-sell-rate',
  templateUrl: './tl-buy-sell-rate.component.html',
  styleUrls: ['./tl-buy-sell-rate.component.scss'],
})
export class TlBuySellRateComponent implements OnInit {
  @Input()
  isBuyRate = false;

  quote: TLEditUI.Quote;
  totalVariance: Number;
  subtotalVariance: number;
  margin: number;
  currencyCode: string;
  conversion: number = 0;
  enterprise: Enterprise;

  constructor(
    private invoiceHelperService: TLAuditEditHelperService,
    private store: Store<AppState>,
    private currencyService: CurrencyService
  ) { }

  ngOnInit() {
    this.setListeners();
  }

  private getCurrencyCode(date: Date): void {
    this.currencyService.getConversionRate(date).subscribe((info) => {
      this.conversion = info.find((data) => data.country == 'CAN').value;
    });
  }

  private calculateVariances(invoice: TLEditUI.CarrierInvoice) {
    // calculate the subtotal variance
    this.subtotalVariance = this.invoiceHelperService.auditCalculateSubtotalCostVariance(invoice, this.quote as TLEditUI.CarrierQuote);

    // calculate the total variance
    this.totalVariance = this.invoiceHelperService.auditCalculateTotalCostVariance(invoice, this.quote as TLEditUI.CarrierQuote);
  }
  private setListeners() {
    this.store
      .select((x) => x.TLAuditEditState.shipment)
      .subscribe((x) => {
        if (x) {
          this.currencyCode = x.currencyCode;
          this.getCurrencyCode(x.carrierInvoices[0].invoiceDate);
          this.enterprise = x.enterprise.enterprise;
          if (this.isBuyRate) {
            this.quote = x.carrierQuote;
            this.calculateVariances(x.carrierInvoices[0]);
          } else {
            this.quote = x.customerQuote;
            this.margin = x.margin;
          }
        }
      });
  }
}
