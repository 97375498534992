import { Charge } from "../../models/ShipmentInvoiceAudit";
import { Globals } from "../../_shared/globals";
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { InvoiceCharge } from "../../services/InvoiceAudit";
import { ShipmentInvoiceAuditUI as ui } from './ShipmentInvoiceAudit.ui';
import { InvoiceService } from '@/services/invoice.service';
import { ShipmentUI } from "../audit/Shipment.ui";

export class ChargesUpdateHelper {
  public static mapCustomerCharges = (charges: InvoiceCharge[]): Charge[] => {
    return (charges || []).map(
      (item) =>
        <Charge>{
          amount: item.amount,
          chargeID: item.invoiceChargeID,
          description: item.description,
          dimWeight: item.dimWeight,
          fakFreightClass: item.fakFreightClass,
          freightClass: item.freightClass,
          isMin: item.isMin,
          isMax: item.isMax,
          isNonTaxable: false,
          quantity: item.quantity,
          rate: item.rate,
          rateCode: item.rateCode,
          rateQualifier: item.rateQualifier,
          type: item.type,
          weight: item.weight,
          ediCode: item.ediCode,
          isAccessorial: !ChargesUpdateHelper.isLinehaul(item.type),
          isLinehaul: ChargesUpdateHelper.isLinehaul(item.type),
        }
    );
  }

  public static updateCustomerCharges(shipmentInvoice: ShipmentUI.Shipment, alertMessage: AlertMessageComponent, response: { customerCharges: Charge[] }) {
    const mapCustomerCharge = (item: Charge) => {
      const qualifier = Globals.Qualifiers.find(r => r.rateQualifier == item.rateQualifier);

      return {
        amount: item.amount,
        amountInUSD: item.amount,
        canEdit: true,
        description: item.description,
        dimWeight: item.dimWeight,
        ediCode: null,
        fakFreightClass: item.fakFreightClass,
        freightClass: item.freightClass,
        group: item.isLinehaul ? " Linehaul" : "Accessorial",
        invoiceChargeID: item.chargeID,
        quantity: item.quantity,
        rate: item.rate,
        rateCode: qualifier ? qualifier.rateCode : null,
        rateQualifier: item.rateQualifier,
        type: item.type,
        weight: item.weight,
        isMin: item.isMin,
        isMax: item.isMax
      };
    }

    if (response) {
      shipmentInvoice.lineHaulCharges = response.customerCharges.filter(ch => ch.isLinehaul).map(charge => mapCustomerCharge(charge));
      shipmentInvoice.accessorialCharges = response.customerCharges.filter(ch => ch.isAccessorial).map(charge => mapCustomerCharge(charge));

      alertMessage.showAlertMessage("The customer charges were updated successfully", "Success");
    }
  }

  public static mapCarrierCharges = (charges: any[]): Charge[] => {
    return (charges || []).map(item => <Charge>{
      amount: item.total,
      chargeID: item.chargeID,
      description: item.description,
      fakFreightClass: item.fakFreightClass,
      freightClass: item.freightClass,
      isMax: item.isMax,
      isMin: item.isMin,
      isNonTaxable: false,
      quantity: item.quantity,
      rate: item.rate,
      rateCode: item.rateCode,
      rateQualifier: item.rateQualifier,
      type: item.type,
      weight: item.weight,
      dimWeight: null,
      ediCode: item.ediCode,
      isAccessorial: !ChargesUpdateHelper.isLinehaul(item.type),
      isLinehaul: ChargesUpdateHelper.isLinehaul(item.type),
    });
  }

  public static updateCarrierCharges(shipment: ui.Shipment, invoiceAudit: any, pcInvoiceService: InvoiceService, alertMessage: AlertMessageComponent, response: { carrierCharges: Charge[] }) {
    const mapCarrierCharge = (item: Charge) => {
      return {
        chargeID: item.chargeID,
        description: item.description,
        freightClass: item.freightClass,
        fakFreightClass: item.fakFreightClass,
        rate: item.rate,
        rateQualifier: item.rateQualifier,
        quantity: item.quantity,
        weight: item.weight,
        isMin: item.isMin,
        isMax: item.isMax,
        total: item.amount,
        ediCode: item.ediCode,
        group: ChargesUpdateHelper.getGroup(item.type),
        type: item.type,
        error: false
      }
    };

    if (response) {
      shipment.carrierQuote.lineHaulCharges = response.carrierCharges.filter(ch => ch.isLinehaul).map(charge => mapCarrierCharge(charge));
      shipment.carrierQuote.accessorialCharges = response.carrierCharges.filter(ch => ch.isAccessorial).map(charge => mapCarrierCharge(charge));

      shipment.carrierQuote.updateCalculatedFields([]);

      //invoiceAudit is of type(any). The properties below do not exist...
      invoiceAudit.subtotalVariance = pcInvoiceService.auditCalculateSubtotalCostVariance(shipment.carrierInvoices[0], shipment.carrierQuote);
      invoiceAudit.totalVariance = pcInvoiceService.auditCalculateTotalCostVariance(shipment.carrierInvoices[0], shipment.carrierQuote);

      alertMessage.showAlertMessage("The carrier charges were updated successfully", "Success");
    }
  }

  private static isLinehaul = (type: string): boolean => Globals.ChargeTypes.indexOf((type || "").toLocaleUpperCase()) >= 0;

  private static getGroup = (type: string) => {
    const index = Globals.ChargeTypes.indexOf((type || "").toLocaleUpperCase());
    return index >= 0 ? 'Linehaul' : 'Accessorial';
  };
}