import { Component, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { ShipmentHistoryService } from '@/services/shipment-history.service';
import { ShipmentHistory } from '@/services/ShipmentHistory';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-history',
  templateUrl: './shipment-history.component.html',
  styleUrls: ['./shipment-history.component.scss'],
})
export class ShipmentHistoryComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @Input()
  shipmentID: number;

  @Input()
  title: string;

  recordCount: number;
  recordBook: ShipmentHistory[];
  isDataloading: boolean;

  constructor(private route: ActivatedRoute, private historyService: ShipmentHistoryService) { }

  ngOnInit() {
    this.isDataloading = false;

    if (!this.shipmentID) {
      this.route.queryParamMap.subscribe((queryParams) => {
        this.shipmentID = +queryParams.get('shipmentID');
      });
    }

    if (!this.title) {
      this.title = 'Shipment Audit History';
    }

    this.loadRecords();
  }

  public loadRecords() {
    this.isDataloading = true;
    this.historyService
      .getHistory(this.shipmentID)
      .pipe(
        map((data) => {
          return data.map((row) => {
            const theDate = new Date(row.updateDate);
            row.updateStringTime =
              ('0' + theDate.getHours()).slice(-2) +
              ':' +
              ('0' + theDate.getMinutes()).slice(-2) +
              ':' +
              ('0' + theDate.getSeconds()).slice(-2) +
              ' ' +
              (theDate.getHours() >= 12 ? 'PM' : 'AM');
            row.updateStringDate =
              ('0' + (theDate.getMonth() + 1)).slice(-2) + '/' + ('0' + theDate.getDate()).slice(-2) + '/' + theDate.getFullYear();

            return row;
          });
        })
      )
      .subscribe(
        (_data) => {
          this.recordBook = this.historyService.shipmentHistoryBook;
          this.recordCount = this.historyService.shipmentHistoryBook.length;
          this.isDataloading = false;
        },
        (_error) => {
          this.alertMessage.showAlertMessage('Error Loading Records.', 'Error');
          this.isDataloading = false;
        }
      );
  }
}
