import { CarrierInvoiceItem, CarrierInvoiceItemDimension, PendingCarrierInvoice } from '@/services/Invoice';
import { InvoiceService } from '@/services/invoice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { AddEvent, CancelEvent, EditEvent, GridComponent, LessFilterOperatorComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { ShipmentRefTypes } from '@/_shared/ShipmentRefTypes';
import { Observable } from 'rxjs';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AddressEditDialogComponent } from '@/bg-common/address-edit-dialog/address-edit-dialog.component';
import { ShipmentService } from '@/services/shipment.service';
import { SaveShipmentReference, ShipmentEnterpriseReference, ShipmentReference } from '@/models/ShipmentReference';
import { PendingInvoiceReference } from '@/models/ShipmentInvoiceAudit';

@Component({
  selector: 'app-unmatched-edit',
  templateUrl: './unmatched-edit.component.html',
  styleUrls: ['./unmatched-edit.component.scss']
})
export class UnmatchedEditComponent implements OnInit {

  invoice: PendingCarrierInvoice;
  invoiceId: number;
  itemsLoading: boolean;
  chargesLoading: boolean;
  items: CarrierInvoiceItem[] = [];
  editIndex: number;
  formGroup: FormGroup;

  freightClasses: String[];
  weightTypes: String[];
  pieceTypes: String[];
  unitTypes: String[];
  dimensionTypes: String[];
  packageGroups: String[];
  removeDialog: boolean;
  showBillToAddressForm: boolean = false;
  settingPrimaryReference: boolean = false;


  // form variables
  referenceNewForm: FormGroup = null;
  referenceEditForm: FormGroup = null;
  addEnterpriseReference = new AddEnterpriseReference();
  enterpriseReferences: ShipmentEnterpriseReference[] = [];
  newReferenceValues: string[] = [];
  // process variables
  newlyAddedReference: any = null;
  isAddNewMode = false;
  isSaving = false;
  isAddError = false;
  referenceErrorMessage: any;

  get controls() {
    return this.referenceNewForm.controls;
  }

  constructor(
    private dialogService: DialogService,
    private invoiceService: InvoiceService,
    private shipmentService: ShipmentService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute) {

    this.freightClasses = ShipmentRefTypes.freightClasses;
    this.weightTypes = ShipmentRefTypes.weightTypes;
    this.pieceTypes = ShipmentRefTypes.pieceTypes;
    this.unitTypes = ShipmentRefTypes.unitTypes;
    this.dimensionTypes = ShipmentRefTypes.dimensionTypes;
    this.packageGroups = ShipmentRefTypes.hazmatPackageGroups;
  }

  ngOnInit() {
    this.itemsLoading = true;
    this.chargesLoading = true;
    this.invoiceId = +this.route.snapshot.paramMap.get('id');
    this.invoiceService
      .getCarrierInvoiceItems(this.invoiceId)
      .subscribe(x => {
        this.items = x;
        this.itemsLoading = false;
      });
    this.invoiceService
      .getInvoiceByID(this.invoiceId)
      .subscribe(x => {
        this.invoice = x;
        this.chargesLoading = false;
      });

    //
    this.loadEnterpriseReferences();
  }

  editAddress(title: string, propertyName: string): void {
    let ref = this.dialogService.open({
      content: AddressEditDialogComponent,
      title: title,
      width: 500
    });

    let content = ref.content.instance as AddressEditDialogComponent;
    content.address = Object.assign({}, this.invoice[propertyName]);

    ref.result.subscribe(result => {
      if (result instanceof DialogCloseResult) return;

      this.invoiceService
        .updateAddress(this.invoiceId, result, propertyName)
        .subscribe(x => {
          this.invoice[propertyName] = x;
        });
    });
  }

  onNew(event: AddEvent): void {
    this.closeEditor(event.sender, this.editIndex);

    // Initialize a new data item
    let dataItem = new CarrierInvoiceItem();
    dataItem = {
      id: 0,
      carrierInvoiceId: this.invoiceId,
      freightClass: 0,
      nmfc: '0',
      description: '',
      dimension: this.createDimensions(),
      weight: {
        value: 0,
        uom: this.weightTypes[0].toString()
      },
      quantity: {
        value: 0,
        uom: this.unitTypes[0].toString(),
        numberOfPieces: 0,
        piecesUom: this.pieceTypes[0].toString()
      }
    };

    this.formGroup = this.createFormGroup(dataItem);
    event.sender.addRow(this.formGroup);
  }

  onEdit(event: EditEvent): void {
    this.closeEditor(event.sender, this.editIndex);
    this.formGroup = this.createFormGroup(event.dataItem);
    this.editIndex = event.rowIndex;
    event.sender.editRow(this.editIndex, this.formGroup);
  }

  onCancel(event: CancelEvent): void {
    this.closeEditor(event.sender, this.editIndex);
  }

  onSave(event: SaveEvent): void {
    let observable: Observable<CarrierInvoiceItem>;

    let isNew: boolean = false;
    let invoiceItem: CarrierInvoiceItem = {
      id: this.formGroup.value.id,
      carrierInvoiceId: this.invoiceId,
      quantity: {
        value: this.formGroup.value.quantity,
        uom: this.formGroup.value.quantityUom,
        numberOfPieces: this.formGroup.value.pieces,
        piecesUom: this.formGroup.value.piecesUom
      },
      weight: {
        value: this.formGroup.value.weight,
        uom: this.formGroup.value.weightUom
      },
      dimension: {
        length: this.formGroup.value.length,
        width: this.formGroup.value.width,
        height: this.formGroup.value.height,
        uom: this.formGroup.value.dimensionUom
      },
      freightClass: this.formGroup.value.freightClass,
      nmfc: this.formGroup.value.nmfc,
      description: this.formGroup.value.description
    };

    if (invoiceItem.id === 0) {
      isNew = true;
      observable = this.invoiceService.createCarrierInvoiceItem(this.invoiceId, invoiceItem);
    }
    else {
      observable = this.invoiceService.updateCarrierInvoiceItem(invoiceItem);
    }

    observable.subscribe(x => {
      this.closeEditor(event.sender, this.editIndex);

      // When an item is added or updated, update or add the
      // individual item rather than pulling the entire collection
      if (isNew) {
        this.items.push(x);
      }
      else {
        const index = this.items.findIndex(i => i.id === x.id);
        this.items[index] = x;
      }
    });
  }

  onRemove(event: RemoveEvent): void {
    this.closeEditor(event.sender, this.editIndex);
    this.removeDialog = true;
    this.editIndex = event.rowIndex;
  }

  closeRemoveDialog(status: string): void {
    if (status === 'yes') {
      let dataItem = this.items[this.editIndex];
      this.invoiceService
        .deleteCarrierInvoiceItem(dataItem)
        .subscribe(() => {
          this.items.splice(this.editIndex, 1);
          this.editIndex = undefined;
          this.removeDialog = false;
        });
    }
    else {
      this.removeDialog = false;
    }
  }

  closeEditor(grid, rowIndex): void {
    grid.closeRow(rowIndex);
    this.editIndex = undefined;
    this.formGroup = undefined;
  }

  createFormGroup(dataItem: CarrierInvoiceItem): FormGroup {
    // It is possible that to receive items that do not have the
    // dimensions set. If that is the create a default with 0's
    // so the form can be correctly populated
    if (!dataItem.dimension) {
      dataItem.dimension = this.createDimensions();
    }

    return new FormGroup({
      id: new FormControl(dataItem.id),
      quantity: new FormControl(dataItem.quantity.value, [Validators.required, Validators.min(0)]),
      quantityUom: new FormControl(dataItem.quantity.uom),
      weight: new FormControl(dataItem.weight.value, [Validators.required, Validators.min(1)]),
      weightUom: new FormControl(dataItem.weight.uom),
      pieces: new FormControl(dataItem.quantity.numberOfPieces),
      piecesUom: new FormControl(dataItem.quantity.piecesUom),
      length: new FormControl(dataItem.dimension.length, Validators.min(0)),
      height: new FormControl(dataItem.dimension.height, Validators.min(0)),
      width: new FormControl(dataItem.dimension.width, Validators.min(0)),
      dimensionUom: new FormControl(dataItem.dimension.uom),
      freightClass: new FormControl(dataItem.freightClass, Validators.required),
      description: new FormControl(dataItem.description, Validators.required),
      nmfc: new FormControl(dataItem.nmfc),
    });
  }

  /*
   * Checks for when the item dimensions are considered set and returns
   * true if they are.
   */
  hasDimensions(dataItem: CarrierInvoiceItem): boolean {
    return !!dataItem.dimension
      && dataItem.dimension.length > 0
      && dataItem.dimension.width > 0
      && dataItem.dimension.height > 0;
  }

  private createDimensions(): CarrierInvoiceItemDimension {
    return {
      length: 0,
      width: 0,
      height: 0,
      uom: this.dimensionTypes[0].toString()
    };
  }

  onEditReference(event: EditEvent): void {
    this.closeEditor(event.sender, this.editIndex);

    let formGroup = new FormGroup({
      type: new FormControl(event.dataItem.type, { validators: Validators.required }),
      value: new FormControl(event.dataItem.value, { validators: Validators.required })
    });

    event.sender.editRow(event.rowIndex, formGroup);
  }

  onSaveReference(event: SaveEvent): void {
    if (this.isAddNewMode) {
      let group = this.formGroup.value;
      this.saveReference(group, event);
    }
    else {
      Object.assign(event.dataItem, event.formGroup.value);

      this.invoiceService
        .updateReference(this.invoiceId, event.dataItem)
        .subscribe(result => {
          event.sender.closeRow(event.rowIndex);
        });
    }
  }

  onRemoveReference(event: RemoveEvent): void {
    let ref = this.dialogService.open({
      title: "Confirm Deletion",
      content: "Do you want to delete the invoice reference?",
      actions: [
        { text: "Yes" },
        { text: "No" }
      ]
    });

    ref.result.subscribe(x => {
      let closeResult = x as any;

      if (closeResult.text.toLowerCase() === 'no') return;

      return this.invoiceService
        .deleteReference(this.invoiceId, event.dataItem.id)
        .toPromise()
        .then(x => {
          this.invoice.references.splice(event.rowIndex, 1);
        });
    });

  }

  onCancelReference(event: CancelEvent): void {
    event.sender.closeRow(event.rowIndex);
    this.onNewReferenceCancel();
  }

  onSetPrimaryReference(dataItem: any): void {
    if (this.isAddNewMode)
      return;

    let currentIdx = this.invoice.references.findIndex(x => x.isPrimary);
    let nextIdx = this.invoice.references.findIndex(x => x.id === dataItem.id);
    let currentPrimary = this.invoice.references[currentIdx];
    let nextPrimary = Object.assign({}, dataItem);
    let p = Promise.resolve();

    this.settingPrimaryReference = true;

    if (currentIdx !== -1) {
      currentPrimary.isPrimary = false;
      p = this.invoiceService
        .updateReference(this.invoiceId, currentPrimary)
        .toPromise()
        .then(x => {
          currentPrimary = x;
        });
    }

    p
      .then(() => {
        nextPrimary.isPrimary = true;
        return this.invoiceService.updateReference(this.invoiceId, nextPrimary).toPromise();
      })
      .then(x => {
        this.invoice.references[nextIdx] = x;
        this.invoice.references[currentIdx] = currentPrimary;
        this.settingPrimaryReference = false;
      })
      .catch(x => {
        this.settingPrimaryReference = false;
      });
  }



  private createNewReferenceForm() {
    this.referenceNewForm = this.formBuilder.group({
      newReferenceTypeDropdown: [this.addEnterpriseReference.type],
      newReferenceValueInput: [this.addEnterpriseReference.value, [Validators.required, Validators.minLength(2)]],
      newReferenceValueDropDown: [this.addEnterpriseReference.value, [Validators.required]],
    });
  }

  protected onReferenceTypeChange(type: string): void {
    const obj = this.enterpriseReferences.find((x) => x.type === this.controls.newReferenceTypeDropdown.value);
    this.controls.newReferenceValueDropDown.setValue('');
    this.controls.newReferenceValueInput.setValue('');
    this.newReferenceValues = obj.values;
  }

  private loadEnterpriseReferences(): void {
    this.invoiceService.getReferenceTypes().subscribe(
      (data) => {
        if (data) {
          this.enterpriseReferences = data;
          this.sortReferencesAlpha(this.enterpriseReferences);
          this.createNewReferenceForm();
        }

      },
      (err) => {
        // show error message
      }
    );


  }

  private sortReferencesAlpha(objectList: any[]) {
    objectList.sort(function (a, b) {
      const typeA = a.type.toUpperCase();
      const typeB = b.type.toUpperCase();
      if (typeA < typeB) {
        return -1;
      }
      if (typeA > typeB) {
        return 1;
      }
      return 0;
    });
  }

  protected disabledSaveBtn(): boolean {
    return (!this.controls.newReferenceValueDropDown.value && !this.controls.newReferenceValueInput.value) || this.controls.newReferenceValueInput.value.length < 2 || this.isSaving;
  }

  // helpers
  private alreadyAdded(value: string, type: string, errorCount: number): boolean {
    this.isAddError = false;
    const items = this.invoice.references.filter((obj) => obj.value === value && obj.type === type);
    if (items.length >= errorCount) {
      this.isAddError = true;
      this.referenceErrorMessage = "A reference already exists for this Type and Value.";
    }
    return this.isAddError;
  }

  public onNewReference({ sender }) {
    this.onNewReferenceCancel();

    let dataItem = new SaveShipmentReference(0, '', '', false);
    let referenceFormGroup = new FormGroup({
      id: new FormControl(dataItem.referenceID),
      type: new FormControl(dataItem.type, [Validators.required]),
      value: new FormControl(dataItem.value, [Validators.required, Validators.minLength(2)]),
      isPrimary: new FormControl(dataItem.isPrimary)
    });

    this.isAddNewMode = true;

    this.formGroup = referenceFormGroup;
    sender.addRow(this.formGroup);
  }


  private onNewReferenceSave() {
    // must send a list to our save
    const references: SaveShipmentReference[] = [];
    const value =
      this.controls.newReferenceValueDropDown.value !== ''
        ? this.controls.newReferenceValueDropDown.value
        : this.controls.newReferenceValueInput.value;

    // ensure its not added already
    if (this.alreadyAdded(value, this.controls.newReferenceTypeDropdown.value, 1)) {
      return;
    }

    let reference = new SaveShipmentReference(0, this.controls.newReferenceTypeDropdown.value, value, false);
    this.saveReference(reference);
  }

  private onNewReferenceCancel() {
    this.isAddError = false;
    this.referenceErrorMessage = '';
    this.controls.newReferenceTypeDropdown.setValue('');
    this.controls.newReferenceValueDropDown.setValue('');
    this.controls.newReferenceValueInput.setValue('');
    this.isAddNewMode = false;
    this.isSaving = false;
  }

  private saveReference(reference: SaveShipmentReference, event: SaveEvent = null) {
    this.isSaving = true;
    let invoiceRef = new PendingInvoiceReference();
    invoiceRef.pendingCarrierInvoiceID = this.invoiceId;
    invoiceRef.type = reference.type;
    invoiceRef.value = reference.value;
    invoiceRef.isPrimary = false;
    this.invoiceService.saveReference(this.invoiceId, invoiceRef).subscribe(
      (data) => {
        // reload references
        this.invoiceService.getReferences(this.invoiceId).subscribe((result) => this.invoice.references = result);
        if (event) {
          event.sender.closeRow(event.rowIndex);
        }
      },
      (err) => {
        this.isAddError = true;
        this.isAddNewMode = true;
        this.referenceErrorMessage = `Error updating references. ${err.error}`;
      }
    );

    this.onNewReferenceCancel();
  }
}

export class AddEnterpriseReference {
  type: string;
  value: string;
}