import { Component, OnInit, ViewChild } from '@angular/core';
import { GridDataResult, RowClassArgs, PageChangeEvent, FilterService } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Helpers, setSettings, getSettings, getFilterSettings, setFilterSettings } from '@/_shared/helpers';
import { AuthService } from '@/auth/auth.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Router } from '@angular/router';
import { StartupService } from '@/startup.service';
import { InvoiceService } from '@/services/invoice.service';
import { TLAuditService } from '@/pages/tl-audit/services/tl-audit.service';
import { TruckloadAuditInvoicesFilter } from '@/_shared/FilterFactory';
import { TruckloadAuditUI, BBill, TruckloadAuditReasonAssignmentUI } from './models/TruckloadAudit.ui';
import { NotesAutoComponent } from '@/bg-common/app-autocomplete/app-autocomplete';
import { ResetTLInvoice } from './models/TLAudit';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { ShowManageReasons, LoadTLAuditDetail } from './actions/tl-audit-detail.actions';
import { ShowAssignAuditor } from './actions/tl-audit-incident-assignment.actions';
import { ShowBBill } from './actions/tl-audit-bbill.actions';
import { FilterData } from './filter/filter.component';

const flatten = (filter) => {
  const filters = (filter || {}).filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-truckload-audit',
  templateUrl: './truckload-audit.component.html',
  styleUrls: ['./truckload-audit.component.scss'],
})
export class TruckloadAuditComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild('searchTlAuditBox', { static: false })
  autoFilter: NotesAutoComponent;

  recordCount = 0;
  gridDataCurrent: TruckloadAuditUI[] = [];
  gridDataAll: TruckloadAuditUI[] = [];
  gridData: GridDataResult;

  helpers: Helpers;
  resettingInvoices = false;
  massAssigningAuditors = false;
  openedEditTruckload = false;
  selectedRecords: number[] = [];
  selectedInvoiceIDs: number[] = [];
  invoiceNumber: string;
  selectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
  };

  pageSize = 25;
  skip = 0;
  truckloadInvoicesFilter = new TruckloadAuditInvoicesFilter();
  _cacheKey = 'tlAudit-setting';
  selectedAudit: TruckloadAuditUI = null;
  showManageReasons = false;
  disableManageReasonCancel = false;
  disableAssignAuditorCancel = false;
  showAuditors = false;
  showCreateBBill = false;
  disableBBillCancel = false;
  public isDataloading: boolean;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor;
  public eventReasons = new Array<any>();
  public selectedEventReasons = new Array<any>();
  public filterHelper: FilterHelper = new FilterHelper();

  incidentManagementOn: boolean;

  constructor(
    private router: Router,
    private startupService: StartupService,
    private authService: AuthService,
    private tlAuditService: TLAuditService,
    private pcInvoiceService: InvoiceService,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.sort = getSettings(this._cacheKey, '', '');
    this.filter = getFilterSettings(this._cacheKey + '-filter');
    this.helpers = new Helpers(this.authService);
    this.loadPage();
    this.setListeners();

    this.incidentManagementOn = this.startupService.backofficeEventManagement;

    if (!this.incidentManagementOn) {
      this.router.navigate([`truckload-audit`]);
    }
  }

  private loadPage() {
    this.isDataloading = true;
    this.tlAuditService.getTLAudits().subscribe(
      (data) => {
        this.setDataToGrid(data);
        this.isDataloading = false;
        this.appSpinner.loading = false;
      },
      (err) => {
        this.appSpinner.loading = false;
        this.isDataloading = false;
        if (err.statusText !== 'Not Found') {
          this.alertMessage.showAlertMessage('Error loading the TL Invoice Audit Page', 'Error');
        }
      }
    );
  }

  setDataToGrid(data: TruckloadAuditUI[]) {
    if (!data) {
      return;
    }

    this.filterHelper.setFilterData(data);

    this.gridDataAll = data;
    this.gridDataCurrent = data;

    this.recordCount = this.gridDataAll.length;
    this.uniqueEventReasons();
    this.setRecords();
  }

  // tl reset
  protected resetInvoices() {
    if (this.selectedRecords.length === 0) {
      return;
    }
    this.resettingInvoices = true;
    this.appSpinner.loading = true;
    const resetList = this.gridDataAll.filter((elem) => {
      return this.selectedRecords.includes(elem.invoiceID);
    });
    const resetInvoices: ResetTLInvoice[] = [];
    resetList.forEach((x) => {
      resetInvoices.push({ shipmentID: x.shipmentID, invoiceNumber: x.invoiceNumber });
    });
    let allSuccessCount = 0;
    this.pcInvoiceService.resetTruckloadInvoices(resetInvoices).subscribe((data) => {
      data.forEach((element) => {
        if (element.resetSuccessful === true) {
          allSuccessCount += 1;
          this.recordCount = this.recordCount - 1;
          this.gridDataAll.splice(
            this.gridDataAll.findIndex((i) => i.invoiceNumber === element.invoiceNumber && i.shipmentID === element.shipmentID),
            1
          );
        }
      });
      this.gridDataCurrent = this.gridDataAll;
      this.resetPagingAndSorting();
      this.setRecords();
      this.uniqueEventReasons();
      // this.autoFilter.reset();
      this.appSpinner.loading = false;
      // TODO list the ones that didn't
      // if (allSuccessCount === resetList.length) {
      this.alertMessage.showAlertMessage('Invoice Record(s) have been Reset', 'Success');
      // } else {
      //    this.alertMessage.showAlertMessage('Some Invoice Record(s) could not Reset. Check Invoice Status.', 'Success');
      //  }
      this.selectedRecords = [];
      this.resettingInvoices = false;
    });
  }

  protected massAssignAuditor() {
    if (this.selectedRecords.length === 0) {
      return;
    }

    const auditor: TruckloadAuditReasonAssignmentUI = new TruckloadAuditReasonAssignmentUI(null, null);
    auditor.setAssignment('', '', null);
    this.selectedInvoiceIDs = this.selectedRecords;
    this.invoiceNumber = 'multiple invoices';
    this.store.dispatch(new ShowAssignAuditor(this.selectedInvoiceIDs, auditor, true));
  }

  // actions on list
  protected editInvoice(e, dataitem) {
    const currentURL = `/truckload-edit/${dataitem.shipmentID}/${dataitem.invoiceID}`;
    this.router.navigate([]).then((result) => {
      window.open(currentURL, '_blank');
    });
  }
  protected viewTruckload(e, dataitem) {
    const url = this.startupService.backOfficeUrl + '/shipment-edit/' + dataitem.shipmentID;
    window.open(url, '_blank');
  }
  protected manageReasons(e, dataitem) {
    this.selectedAudit = dataitem;
    this.store.dispatch(new ShowManageReasons(true));
    this.store.dispatch(new LoadTLAuditDetail(this.selectedAudit.invoiceID, this.selectedAudit.shipmentID));
  }

  protected manageAuditor(e, dataitem) {
    this.selectedAudit = dataitem;
    this.selectedInvoiceIDs = [this.selectedAudit.invoiceID];
    this.invoiceNumber = this.selectedAudit.invoiceNumber;
    this.store.dispatch(new ShowAssignAuditor(this.selectedInvoiceIDs, this.selectedAudit.auditor, true));
  }

  protected showBBill(e, dataitem) {
    this.selectedAudit = dataitem;
    const bbill = new BBill(
      this.selectedAudit.invoiceID,
      this.selectedAudit.shipmentID,
      this.selectedAudit.invoiceNumber,
      this.selectedAudit.customerAccount
    );
    this.store.dispatch(new ShowBBill(true, this.selectedAudit.invoiceID, bbill));
  }

  protected closeManageReasons() {
    this.store.dispatch(new ShowManageReasons(false));
  }

  protected closeManageAuditor() {
    if (this.disableAssignAuditorCancel) {
      this.alertMessage.showAlertMessage('You can not close the dialog in the middle of a save', 'Warning');
    } else {
      this.store.dispatch(new ShowAssignAuditor(null, null, false));
    }
  }

  protected closeBBill() {
    this.store.dispatch(new ShowBBill(false, 0, null));
  }

  // page events
  protected OnRefresh() {
    this.appSpinner.loading = true;
    this.autoFilter.reset();
    this.selectedRecords = [];
    this.loadPage();
  }

  protected searchFilter(search: string = null): void {
    search
      ? (this.gridDataCurrent = this.gridDataAll.filter((obj) => this.truckloadInvoicesFilter.Match(obj, search.toLowerCase())))
      : (this.gridDataCurrent = this.gridDataAll);
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  public eventReasonFilter(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.selectedEventReasons.splice(0, this.selectedEventReasons.length, ...flatten(filter).map(({ value }) => value));
    return this.selectedEventReasons;
  }

  public eventReasonChange(values: any[], filterService: FilterService): void {
    filterService.filter({
      filters: values.map((value) => ({
        field: 'reasonString',
        operator: 'contains',
        value,
      })),
      logic: 'or',
    });
  }

  protected linkSearch(event) {
    this.searchFilter(event.trim());
  }

  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
    setSettings(this._cacheKey, this.sort);
  }

  protected filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.setRecords();
    setFilterSettings(this._cacheKey + '-filter', this.filter);
  }

  // helpers
  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailState.showManageReasons)
      .subscribe((x) => {
        this.showManageReasons = x;
        if (x === false) {
          this.selectedAudit = null;
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.showAssignAuditor)
      .subscribe((x) => {
        this.showAuditors = x;
        if (x === false) {
          this.selectedAudit = null;
        }
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.updatedAuditor)
      .subscribe((x) => {
        if (this.selectedInvoiceIDs) {
          this.selectedInvoiceIDs.forEach((y) => {
            const match = this.gridDataCurrent.filter((z) => z.invoiceID == y);
            if (match.length > 1 || match.length === 0) {
              console.log('Error updating List for record invoice ID ' + JSON.stringify(y));
              return;
            }
            match[0].auditor = x;
          });
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.manageReasonsSaveInProgress)
      .subscribe((x) => {
        this.disableManageReasonCancel = x;
      });
    this.store
      .select((x) => x.TLAuditIncidentAssignmentState.assignAuditorSaveInProgress)
      .subscribe((x) => {
        this.disableAssignAuditorCancel = x;
      });
    this.store
      .select((x) => x.TLAuditBBillState.saveInProgress)
      .subscribe((x) => {
        this.disableBBillCancel = x;
      });
    this.store
      .select((x) => x.TLAuditBBillState.savedSuccess)
      .subscribe((x) => {
        if (x) {
          this.removeBBill();
        }
      });
    this.store
      .select((x) => x.TLAuditBBillState.showBBill)
      .subscribe((x) => {
        this.showCreateBBill = x;
        if (x === false) {
          this.selectedAudit = null;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.tlAuditReasonList)
      .subscribe((audit) => {
        if (audit) {
          const matchingDataItem = this.gridDataCurrent.filter((g) => g.shipmentID === audit.shipmentID && g.invoiceID === audit.invoiceID);
          if (matchingDataItem.length > 1 || matchingDataItem.length === 0) {
            console.log('Error updating List for record' + JSON.stringify(audit));
            return;
          }
          matchingDataItem[0].reasons = audit.reasons;
          matchingDataItem[0].reasonString = audit.reasonString;
        }
      });
  }

  private removeBBill() {
    if (this.selectedAudit) {
      const allIndex = this.gridDataAll.indexOf(this.selectedAudit);
      this.gridDataAll.splice(allIndex, 1);
      this.gridDataCurrent = this.gridDataAll;
      this.recordCount = this.gridDataAll.length;
      this.uniqueEventReasons();
      this.setRecords();
    }
    this.closeBBill();
  }
  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  private resetPagingAndSorting() {
    // going back to first page
    this.skip = 0;
    this.pageSize = 25;
    this.sort = [];
  }

  private setRecords(): void {
    if (this.gridDataCurrent == null) {
      return;
    }

    let records = this.filterHelper.applyDialogFilters(this.gridDataCurrent);
    records = filterBy(records, this.filter);
    records = orderBy(records, this.sort);
    this.recordCount = records.length;
    this.gridData = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }

  private uniqueEventReasons() {
    const reasonArray: string[] = [];

    this.gridDataAll.map((item) => {
      item.reasons.forEach((r) => {
        reasonArray.push(r.reason);
      });
    });
    this.eventReasons = reasonArray.filter((value, index, self) => self.indexOf(value) === index);
  }

  isFilterDialogOpened: boolean = true;

  closeFilterDialog() {
    this.isFilterDialogOpened = false;
  }

  openFilterDialog() {
    this.isFilterDialogOpened = true;
  }

  onFilter(filterData: FilterData) {
    this.filterHelper.filterData = filterData;
    this.setRecords();
    this.closeFilterDialog();
  }
}

export class FilterHelper {
  private auditorsSet = new Set<string>();
  private customerAccountsSet = new Set<string>();
  private customersSet = new Set<string>();
  private salesRepresentativesSet = new Set<string>();

  public filterData: FilterData = new FilterData();

  public get filterSettings(): FilterData {
    return <FilterData>{
      auditors: Array.from(this.auditorsSet).sort(),
      customerAccounts: Array.from(this.customerAccountsSet).sort(),
      customers: Array.from(this.customersSet).sort(),
      salesRepresentatives: Array.from(this.salesRepresentativesSet).sort(),
    };
  }

  public applyDialogFilters(data: TruckloadAuditUI[]): TruckloadAuditUI[] {
    const isEmpty = (data: string) => !data || 0 === data.length;
    const mapToLowerCase = (items: string[]) => items.filter((item) => !isEmpty(item)).map((item) => item.toLowerCase());
    const included = (key: string, items: string[]) => (items.length === 0 ? true : key && items.includes(key.toLowerCase()));

    let filteredData = data;
    if (this.filterData) {
      const auditors = mapToLowerCase(this.filterData.auditors);
      const customerAccounts = mapToLowerCase(this.filterData.customerAccounts);
      const customers = mapToLowerCase(this.filterData.customers);
      const salesRepresentatives = mapToLowerCase(this.filterData.salesRepresentatives);

      filteredData = filteredData.filter((item) => {
        return (
          included((item.auditor || { name: '' }).name, auditors) &&
          included(item.customerAccount, customerAccounts) &&
          included(item.customer, customers) &&
          included(item.saleRep, salesRepresentatives)
        );
      });
    }

    return filteredData;
  }

  public setFilterData(data: TruckloadAuditUI[]) {
    this.auditorsSet.clear();
    this.customersSet.clear();
    this.customerAccountsSet.clear();
    this.salesRepresentativesSet.clear();

    data.forEach((element) => {
      if (element.auditor) {
        this.auditorsSet.add((element.auditor || { name: '' }).name);
      }

      if (element.customerAccount) {
        this.customerAccountsSet.add(element.customerAccount);
      }

      if (element.customer) {
        this.customersSet.add(element.customer);
      }

      if (element.saleRep) {
        this.salesRepresentativesSet.add(element.saleRep);
      }
    });
  }
}
