import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  isIE: boolean;
  constructor() {}

  ngOnInit() {
    this.isIE = window.navigator.userAgent.indexOf('Trident') !== -1 || window.navigator.userAgent.indexOf('MSIE ') !== -1;
  }
}
