import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageChangeEvent, GridDataResult, SelectableSettings, RowClassArgs, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { getSettings, setSettings } from '../../_shared/helpers';
import { TLDocHoldProactiveFilter } from '../../_shared/FilterFactory';
import { TLDocHoldProactiveResponse } from '../../models/Proactive';

@Component({
  selector: 'app-tl-doc-proactive-list',
  templateUrl: './tl-doc-proactive-list.component.html',
  styleUrls: ['./tl-doc-proactive-list.component.scss']
})
export class TlDocProactiveListComponent implements OnInit {
  get theRecords(): TLDocHoldProactiveResponse[] {
    return this.gridDataAll;
  }

  @Input('theRecords')
  set theRecords(value: TLDocHoldProactiveResponse[]) {
    this.gridDataAll = value;
    this.gridDataCurrent = value;
    this.setRecords();
  }

  @Input()
  set canDeletedInvoices(value) {
    this.canDeleteInvoice = value;
  }

  @Input() set cacheKeyGrid(value) {
    this._cacheKeyGrid = value;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataloading = value;
  }

  @Output()
  uploaddocuments = new EventEmitter<TLDocHoldProactiveResponse>();

  @Output()
  releaseButtonEvent = new EventEmitter<number[]>();

  @Output()
  deleteButtonEvent = new EventEmitter<string>();

  gridDataCurrent: TLDocHoldProactiveResponse[];
  gridDataAll: TLDocHoldProactiveResponse[];
  tlDocGridData: GridDataResult;
  selectedRecords: number[] = [];
  selectedRecord: number;
  deleteRecord: string;
  _cacheKeyGrid: string;
  canDeleteInvoice: boolean;
  showReleaseModal: boolean;
  showDeleteModal: boolean;
  pageSize: number;
  skip: number;
  range = { start: null, end: null };
  sortableSettings: any;
  tlDocHoldProactiveFilter: TLDocHoldProactiveFilter;

  public selectableSettings: SelectableSettings;
  public isDataloading: boolean;
  public state: State = {};
  public sort: SortDescriptor[] = [{ field: 'Owner', dir: 'asc' }];

  constructor() {}

  ngOnInit() {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple'
    };
    this.sortableSettings = { allowUnsort: false };
    this.pageSize = 25;
    this.skip = 0;
    this.gridDataCurrent = this.gridDataAll;

    this.sort = getSettings(this._cacheKeyGrid, 'owner', 'asc');
    this.tlDocHoldProactiveFilter = new TLDocHoldProactiveFilter();
  }

  protected searchFilter(search: string = null): void {
    search
      ? (this.gridDataCurrent = this.gridDataAll.filter(obj => this.tlDocHoldProactiveFilter.Match(obj, search.toLowerCase())))
      : (this.gridDataCurrent = this.gridDataAll);
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
    setSettings(this._cacheKeyGrid, this.sort);
  }

  private setRecords(): void {
    if (this.gridDataCurrent == null) {
      return;
    }
    const records = orderBy(this.gridDataCurrent, this.sort);
    this.tlDocGridData = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length
    };
    // this.selectedRecords = [];
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven
    };
  }

  onUploadDocuments(dataItem) {
    this.uploaddocuments.emit(dataItem);
  }

  linkSearch(event) {
    this.searchFilter(event);
  }

  closeConfirm() {
    this.showReleaseModal = false;
    this.showDeleteModal = false;
  }

  confirmRelease() {
    this.showReleaseModal = true;
  }

  deleteInvoice(e: RemoveEvent): void {
    this.deleteRecord = e.dataItem.carrierInvoiceID;
    this.showDeleteModal = true;
  }

  onConfirmRelease() {
    if (this.selectedRecord) {
      this.releaseButtonEvent.emit([this.selectedRecord]);
    } else {
      this.releaseButtonEvent.emit(this.selectedRecords);
    }
    this.closeConfirm();
  }

  onConfirmDelete() {
    // TODO delete
    this.closeConfirm();
    this.deleteButtonEvent.emit(this.deleteRecord);
  }

  // row click
  onReleaseProactive(dataItem) {
    this.selectedRecord = dataItem.id;
    this.confirmRelease();
  }

  removeRecords(recordList: number[]) {
    for (let t = 0; t < recordList.length; t++) {
      this.gridDataAll.splice(
        this.gridDataAll.findIndex(i => i.id === recordList[t]),
        1
      );
    }
    this.gridDataCurrent = this.gridDataAll;
    this.selectedRecords = [];
    this.selectedRecord = null;
    this.setRecords();
  }
}
