import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { LoadCarrierInvoiceEdit, LoadCarrierInvoiceAdd, CarrierInvoiceSave, CleanAuditSaveState } from './actions/carrier-invoice-audit.actions';
import { CarrierInvoiceAudit, CarrierInvoiceCharge } from './models/CarrierInvoiceAudit';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Helpers } from '@/_shared/helpers';
import { AuthService } from '@/auth/auth.service';
import { CarrierFormComponent } from './component/carrier-form/carrier-form.component';
import { EditInvoiceParameters } from '../../models/edit-invoice-parameters';

@Component({
  selector: 'app-carrier-invoice-audit',
  templateUrl: './carrier-invoice-audit.component.html',
  styleUrls: ['./carrier-invoice-audit.component.scss'],
})
export class CarrierInvoiceAuditComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild('invoiceAuditCarrierForm', { static: false })
  private invoiceAuditCarrierForm: CarrierFormComponent;

  @Input()
  public parameters: EditInvoiceParameters;

  @Output()
  onInvoiceCreated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onInvoiceUpdated: EventEmitter<any> = new EventEmitter<any>();

  helpers: Helpers = null;
  pageTitle: string;
  shipmentID: number;
  invoiceID: number;
  editMode: boolean;
  // loading information
  isDataloading: boolean;
  loadingMessage: string;
  errorLoading: boolean;
  carrierInvoiceAudit: CarrierInvoiceAudit = null;

  submittingForm = false;
  chargesInEditProgress = false;

  // helpers
  isLTL = false;
  invoiceTotal: number;
  invoiceTotalInUSD: number;

  //
  savingError: string;

  // needed for save
  serviceTotal: number;
  freightTotal: number;
  serviceTotalInUSD: number;
  freightTotalInUSD: number;
  currentEditCurrency: string;
  isInternational = false;
  charges: CarrierInvoiceCharge[] = null;

  constructor(private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.helpers = new Helpers(this.authService);
  }

  ngOnInit() {
    this.loadingMessage = 'Loading the page...';
    this.setListeners();

    this.editMode = this.parameters.isEditMode;
    this.shipmentID = this.parameters.shipmentID;
    this.invoiceID = this.parameters.invoiceID;

    if (this.editMode) {
      this.store.dispatch(new LoadCarrierInvoiceEdit(this.invoiceID, this.shipmentID));
    } else {
      this.store.dispatch(new LoadCarrierInvoiceAdd(this.shipmentID));
    }
  }

  private setListeners() {
    this.store
      .select((x) => x.CarrierInvoiceAuditState.loading)
      .subscribe((x) => {
        this.isDataloading = x;
        if (this.isDataloading) {
          this.showSpinner(x, 'Loading finance data...');
        } else {
          this.showSpinner(x, '');
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.loadingError)
      .subscribe((x) => {
        if (x) {
          this.errorLoading = x !== null && x.length > 0;
          this.loadingMessage = 'Error Loading Page: ' + x;
          this.alertMessage.showAlertMessage('Error loading the carrier page', 'Error');
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.carrierInvoiceAudit)
      .subscribe((x) => {
        if (x) {
          this.carrierInvoiceAudit = x;
          this.isLTL = this.helpers.isLTLOrInternational(this.carrierInvoiceAudit.mode);
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.editInProgress)
      .subscribe((x) => {
        this.chargesInEditProgress = x;
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.invoiceTotal)
      .subscribe((x) => {
        this.invoiceTotal = x;
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.invoiceTotalInUSD)
      .subscribe((x) => {
        this.invoiceTotalInUSD = x;
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.serviceTotal)
      .subscribe((x) => {
        if (x) {
          this.serviceTotal = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.serviceTotalInUSD)
      .subscribe((x) => {
        if (x) {
          this.serviceTotalInUSD = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.freightTotal)
      .subscribe((x) => {
        if (x) {
          this.freightTotal = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.freightTotalInUSD)
      .subscribe((x) => {
        if (x) {
          this.freightTotalInUSD = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.editCurrencyCode)
      .subscribe((x) => {
        if (x) {
          this.currentEditCurrency = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.isInternational)
      .subscribe((x) => {
        if (x) {
          this.isInternational = x;
        }
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.charges)
      .subscribe((x) => {
        if (x) {
          this.charges = x;
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.saving)
      .subscribe((x) => {
        if (x) {
          this.submittingForm = x;
          this.showSpinner(x, 'Saving Invoice ...');
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.savedAudit)
      .subscribe((data) => {
        if (data) {
          const dataCopy = Object.assign({}, data);
          this.store.dispatch(new CleanAuditSaveState());
          this.showSpinner(false, '');
          if (this.editMode) {
            this.onInvoiceUpdated.emit(dataCopy);
          } else {
            this.onInvoiceCreated.emit(dataCopy);
          }
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.savingError)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false, '');
          this.savingError = x;
          console.log('Error on save' + x);
          if (this.savingError !== null && this.savingError.length > 0) {
            this.alertMessage.showAlertMessage('Error saving the changes', 'Error');
          }
        }
      });
  }

  public submitForm() {
    // the total cannot be negative
    if (this.invoiceTotal < 0) {
      this.alertMessage.showAlertMessage('The Invoice cannot be negative', 'Error');
      return;
    }

    // carrier data and distance are updated via events
    // these fields just need to be saved
    this.carrierInvoiceAudit.invoiceDate = this.invoiceAuditCarrierForm.carrierForm.get('invoiceDate').value;
    this.carrierInvoiceAudit.invoiceNumber = this.invoiceAuditCarrierForm.carrierForm.get('invoiceNumber').value;
    this.carrierInvoiceAudit.weight = this.invoiceAuditCarrierForm.carrierForm.get('weight').value;
    this.carrierInvoiceAudit.paymentMethod = this.invoiceAuditCarrierForm.carrierForm.get('paymentMethod').value;

    this.submittingForm = true;
    this.store.dispatch(new CarrierInvoiceSave(this.carrierInvoiceAudit, this.serviceTotal, this.freightTotal, this.charges));
  }

  protected isCarrierFormInvalid(): boolean {
    return this.invoiceAuditCarrierForm === undefined || this.invoiceAuditCarrierForm.isFormValid === false;
  }

  protected loadFlag(): string {
    if (this.carrierInvoiceAudit.enterpriseCurrencyCode === 'USD') {
      return '/assets/united-states-of-america-flag-icon-32.png';
    }
    if (this.carrierInvoiceAudit.enterpriseCurrencyCode === 'CAD') {
      return '/assets/canada-flag-icon-32.png';
    }
  }

  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }

  public get submitDisabled(): boolean {
    return (!this.isLTL && this.carrierInvoiceAudit.carrierCode === 'n/a')
      || this.isCarrierFormInvalid()
      || this.chargesInEditProgress
  }
}
