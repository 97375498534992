import { EnterpriseChildDTO } from '@/services/Collection';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import {
  CustomerProfile,
  CustomerProfileRefDataResponse,
  UpdateCustomerProfile,
  CreateCustomerProfile,
} from '../../models/CustomerProfile';
import { CustomerService } from '../../services/customer.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Helpers } from '../../_shared/helpers';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Globals } from '@/_shared/globals';

@Component({
  selector: 'app-customer-profile-details',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  get controls() {
    return this.customerForm.controls;
  }

  // sent in
  createEnterpriseName = '';
  createEnterpriseAccount = '';
  customerProfileID = 0;
  enterpriseID = 0;

  referenceData: CustomerProfileRefDataResponse = null;
  customerProfile: CustomerProfile = null;

  customerForm: FormGroup;
  helpers: Helpers;
  maxDate: Date;

  saveConfirmationDialog = false;
  deleteConfirmationDialog = false;
  canEditCustomerProfile = false;
  copyProfileCustomerDialog = false;
  showPageErrors = false;
  originalIsProactive: boolean;
  saveBtnClicked = false;
  isNewRecord = false;
  isLoading = true;
  selectedContactId = 0;
  selectedContactName = '';
  duplicateRecord = false;
  copyFromCustomerID = 0;
  showCopyProfileButton = true;

  enterprises: EnterpriseChildDTO[] = null;
  enterpriseData: Array<{
    enterpriseID: number;
    name: string;
    accountNumber: string;
    fullName: string;
  }>;
  theEnterpriseAutoComplete: AutoCompleteComponent;
  selectedEnterprise: EnterpriseChildDTO;
  recordCount = 0;
  selectedId: number;
  enterpriseText: String;
  proctiveShipmentFields = Globals.ProctiveShipmentFields;
  shipmentDataPointObjArray = [];

  settlementNote = 'Choose Settlement Reasons to be excluded from notification';
  documentNote = 'Documents that will be attached on individual shipment notifications';
  varianceNote = 'Range is $5 to $100';

  constructor(
    private pcService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // are they allowed to be here?
    this.helpers = new Helpers(this.authService);
    if (!this.helpers.hasOperation('CanEditCustomerProfile') && !this.helpers.hasOperation('CanViewCustomerProfile')) {
      this.router.navigate([`not-authorized`]);
    }

    this.canEditCustomerProfile = this.hasOperation('CanEditCustomerProfile') ? true : false;
    this.loadParameters();
    this.isNewRecord = this.customerProfileID === 0 ? true : false;

    // check if the record is going to be duplicated
    this.duplicateRecord = this.copyFromCustomerID > 0 ? true : false;
    if (this.duplicateRecord) {
      this.showCopyProfileButton = false;
      this.isNewRecord = true;
    }

    this.maxDate = new Date('December 31, 9999 0:0:0');

    // add the correct services to call
    const observableBatch = [];
    observableBatch.push(this.pcService.getCustomerProfileReferenceData());
    if (!this.isNewRecord) {
      observableBatch.push(this.pcService.getSpecificCustomerProfile(this.customerProfileID));
    }
    else {
      if (this.duplicateRecord) {
        observableBatch.push(this.pcService.getSpecificCustomerProfile(this.copyFromCustomerID));
      }
    }

    // call them
    this.appSpinner.loading = true;
    const combined = forkJoin(observableBatch);
    combined.subscribe(
      (data) => {
        this.referenceData = data[0] as CustomerProfileRefDataResponse;

        const customerProfile = this.isNewRecord === true
          ? this.duplicateRecord ? (data[1] as CustomerProfile) : new CustomerProfile()
          : (data[1] as CustomerProfile);

        if (this.isNewRecord) {
          customerProfile.accountName = this.createEnterpriseName;
          customerProfile.accountNumber = this.createEnterpriseAccount;
          customerProfile.enterpriseID = this.enterpriseID;
        }
        this.customerProfile = customerProfile;
        this.appSpinner.loading = false;
        this.isLoading = false;
        this.originalIsProactive = this.customerProfile.isProactive;

        // set the dates
        this.initDates();

        // create the form
        this.createForm(customerProfile);
      },
      (err) => {
        this.isLoading = false;
        this.appSpinner.loading = false;
        this.alertMessage.showAlertMessage('Error loading the customer profile page', 'Error');
      }
    );
  }

  // events
  public validateEmail() { }
  private onProactiveChange(isProactive: boolean) {
    this.customerProfile.proactiveEndDate = this.maxDate;
    if (!isProactive) {
      if (this.isNewRecord) {
        this.customerProfile.proactiveEndDate = this.maxDate;
      } else {
        this.customerProfile.proactiveEndDate = new Date();
      }
    }
    // since the Ui hides these inputs we make sure the validation only triggers if the inputs are visible
    this.updateProactiveValidation(isProactive);
  }
  private redirectToGrid() {
    this.router.navigate([`/customer-profile/`]);
  }
  public submitForm() {
    this.saveBtnClicked = true;
    this.showPageErrors = false;
    if (this.customerForm.invalid) {
      this.showPageErrors = true;
      this.saveBtnClicked = false;
      this.validateAllFormFields(this.customerForm);
      window.scroll({ top: 0, behavior: 'smooth' });
      return;
    }
    this.saveConfirmationDialog = true;
  }
  closeDialog() {
    this.saveConfirmationDialog = false;
    this.deleteConfirmationDialog = false;
    this.copyProfileCustomerDialog = false;
    this.saveBtnClicked = false;
  }
  public addContactClick() {
    // is there a control already available?
    let addNew = true;
    const parent = this;
    const contacts = <FormArray>this.customerForm.get('contacts');
    contacts.controls.forEach(function (formgroup) {
      if (formgroup.get('email').value.length === 0) {
        parent.alertMessage.showAlertMessage('An empty contact already exists', 'Error');
        addNew = false;
        return;
      }
    });
    if (!addNew) {
      return;
    }

    // add a new contact
    contacts.push(
      this.formBuilder.group({
        name: '',
        email: ['', [Validators.required, this.emailValidator]],
      })
    );

    // get the last one added and mark it as dirty
    const formGroup = contacts.controls[contacts.controls.length - 1];
    formGroup.get('email').markAsDirty();
  }
  public removeContactClick(index: number) {
    const contacts = <FormArray>this.customerForm.get('contacts');

    this.selectedContactId = index;
    this.selectedContactName = contacts.controls[index].get('name').value;
    if (contacts.controls[index].get('email').value.length === 0) {
      this.removeCustomer();
    } else {
      this.deleteConfirmationDialog = true;
    }
  }
  public onNotificationTypeChange(value: string): void {
    if (value && value.includes('Consolidated')) {
      this.controls.documentTypes.setValue([]);
    }
  }

  // form functions
  private createForm(customerProfile: CustomerProfile) {

    if (customerProfile.consolidatedCustomReport !== undefined && customerProfile.consolidatedCustomReport !== null && customerProfile.consolidatedCustomReport.length > 0) {
      var list = JSON.parse(customerProfile.consolidatedCustomReport);
      this.shipmentDataPointObjArray = list.columnInfo;
    }

    customerProfile.isProactive = customerProfile.isProactive != null ? customerProfile.isProactive : false;
    this.customerForm = this.formBuilder.group({
      accountContact: [customerProfile.accountContact != null ? customerProfile.accountContact : ''],
      edmName: [customerProfile.edmName, [Validators.required]],
      edmEmail: [customerProfile.edmEmail, [Validators.required, this.emailValidator]],
      esrName: [customerProfile.esrName, [Validators.required]],
      esrEmail: [customerProfile.esrEmail, [Validators.required, this.emailValidator]],
      isProactive: [customerProfile.isProactive],
      proactiveAuditNumber: [
        customerProfile.proactiveAuditNumber != null ? customerProfile.proactiveAuditNumber : '',
        [Validators.required],
      ],
      notificationType: [customerProfile.notificationType != null ? customerProfile.notificationType : 'Individual'],
      shipmentDataPoints: [customerProfile.consolidatedCustomReport != null ? customerProfile.consolidatedCustomReport : ''],
      notificationVariance: [customerProfile.notificationVariance != null ? customerProfile.notificationVariance : 5],
      documentTypes: [customerProfile.documentTypes != null ? customerProfile.documentTypes : ''],
      settlementReasons: [customerProfile.settlementReasons],
      proactiveNotes: [customerProfile.proactiveNotes != null ? customerProfile.proactiveNotes : ''],
      contacts: this.formBuilder.array([]),
      positiveVariance: [customerProfile.positiveVariance != null ? customerProfile.positiveVariance : 0],
      negativeVariance: [customerProfile.negativeVariance != null ? customerProfile.negativeVariance : 0],
      isSpecializedAudit: [customerProfile.isSpecializedAudit != null ? customerProfile.isSpecializedAudit : false],
      isExceptionCustomer: [customerProfile.isExceptionCustomer != null ? customerProfile.isExceptionCustomer : false],
      isExcludeFromAuditAutomation: [customerProfile.isExcludeFromAuditAutomation != null ? customerProfile.isExcludeFromAuditAutomation : false],
    });

    // add the contacts
    const contacts = <FormArray>this.customerForm.get('contacts');
    if (customerProfile.contacts && customerProfile.contacts.length) {
      customerProfile.contacts.forEach((x) => {
        contacts.push(
          this.formBuilder.group({
            name: [x.name],
            email: [x.email, [Validators.required, this.emailValidator]],
          })
        );
      });
    } else {
      contacts.push(
        this.formBuilder.group({
          name: [''],
          email: ['', [Validators.required, this.emailValidator]],
        })
      );
    }

    // subscribe to the form events
    this.customerForm.get('isProactive').valueChanges.subscribe((data: boolean) => {
      this.onProactiveChange(data);
    });

    // update dynamic validators
    // since the Ui hides these inputs we make sure the validation only triggers if the inputs are visible
    this.updateProactiveValidation(customerProfile.isProactive);
  }
  public saveCustomerProfile() {
    this.saveConfirmationDialog = false;

    // zero out the time
    this.customerProfile.proactiveEndDate.setHours(0, 0, 0, 0);
    this.customerProfile.proactiveStartDate.setHours(0, 0, 0, 0);

    // get model from the form
    const customerProfile = <CustomerProfile>this.customerForm.value;

    // convert the shipmentDataPoints to JSON
    var consolidatedCustomReport = "";
    if (customerProfile.notificationType == 'Consolidated Custom' && this.shipmentDataPointObjArray.length > 0) {
      var newList = {
        columnInfo: this.shipmentDataPointObjArray
      }
      consolidatedCustomReport = JSON.stringify(newList);
    }

    // are we saving or creating?
    if (this.isNewRecord) {
      const theRecord = <CreateCustomerProfile>{
        enterpriseID: this.enterpriseID,
        accountName: this.createEnterpriseName,
        accountNumber: this.createEnterpriseAccount,
        proactiveStartDate: this.customerProfile.proactiveStartDate,
        proactiveEndDate: this.customerProfile.proactiveEndDate,
        allSettlementReasons: false,
        documentTypes: customerProfile.documentTypes,
        settlementReasons: customerProfile.settlementReasons,
        proactiveNotes: customerProfile.proactiveNotes,
        isProactive: customerProfile.isProactive,
        proactiveAuditNumber: customerProfile.proactiveAuditNumber,
        notificationVariance: customerProfile.notificationVariance,
        consolidatedCustomReport: consolidatedCustomReport,
        contacts: customerProfile.contacts,
        edmName: customerProfile.edmName,
        edmEmail: customerProfile.edmEmail,
        esrName: customerProfile.esrName,
        esrEmail: customerProfile.esrEmail,
        notificationType: customerProfile.notificationType,
        accountContact: customerProfile.accountContact,
        positiveVariance: customerProfile.positiveVariance,
        negativeVariance: customerProfile.negativeVariance,
        isSpecializedAudit: customerProfile.isSpecializedAudit,
        isExceptionCustomer: customerProfile.isExceptionCustomer,
      };

      // call the service
      this.createProfile(theRecord);
    } else {
      // did they change the proactive
      if (customerProfile.isProactive && !this.originalIsProactive) {
        const today = new Date();
        this.customerProfile.proactiveStartDate.setMonth(today.getMonth());
        this.customerProfile.proactiveStartDate.setFullYear(today.getFullYear());
        this.customerProfile.proactiveStartDate.setDate(today.getDate());
      }

      const theRecord = <UpdateCustomerProfile>{
        proactiveStartDate: this.customerProfile.proactiveStartDate,
        proactiveEndDate: this.customerProfile.proactiveEndDate,
        allSettlementReasons: false,
        documentTypes: customerProfile.documentTypes,
        settlementReasons: customerProfile.settlementReasons,
        proactiveNotes: customerProfile.proactiveNotes,
        isProactive: customerProfile.isProactive,
        proactiveAuditNumber: customerProfile.proactiveAuditNumber,
        notificationVariance: customerProfile.notificationVariance,
        consolidatedCustomReport: consolidatedCustomReport,
        contacts: customerProfile.contacts,
        edmName: customerProfile.edmName,
        edmEmail: customerProfile.edmEmail,
        esrName: customerProfile.esrName,
        esrEmail: customerProfile.esrEmail,
        notificationType: customerProfile.notificationType,
        accountContact: customerProfile.accountContact,
        positiveVariance: customerProfile.positiveVariance,
        negativeVariance: customerProfile.negativeVariance,
        isSpecializedAudit: customerProfile.isSpecializedAudit,
        isExceptionCustomer: customerProfile.isExceptionCustomer,
        isExcludeFromAuditAutomation: customerProfile.isExcludeFromAuditAutomation,
      };

      // call the service
      this.updateProfile(theRecord);
    }
  }

  // service calls
  private updateProfile(profile: UpdateCustomerProfile) {
    this.appSpinner.loading = true;
    this.pcService.updateCustomerProfile(profile, this.customerProfileID).subscribe(
      (data) => {
        this.appSpinner.loading = false;
        this.alertMessage.showAlertMessage('Successfully updated', 'Success');
        this.router.navigate([`/customer-profile/`]);
      },
      (error) => {
        this.appSpinner.loading = false;
        this.saveBtnClicked = false;
        this.alertMessage.showAlertMessage('Error updating', 'Error');
      }
    );
  }

  private createProfile(profile: CreateCustomerProfile) {
    this.appSpinner.loading = true;
    this.pcService.createCustomerProfile(profile).subscribe(
      (data) => {
        this.appSpinner.loading = false;
        this.alertMessage.showAlertMessage('Successfully created', 'Success');
        this.router.navigate([`/customer-profile/`]);
      },
      (error) => {
        this.appSpinner.loading = false;
        this.alertMessage.showAlertMessage('Error creating', 'Error');
      }
    );
  }

  // helpers
  private emailValidator(control: FormControl) {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    if (control.value != null) {
      const email = control.value.trim();
      if (!regexEmail.test(email)) {
        return {
          invalidEmail: {
            invalidEmail: 'Invalid Email',
          },
        };
      }
    }
    return null;
  }
  private initDates() {
    if (
      this.customerProfile.proactiveStartDate !== undefined &&
      !(typeof this.customerProfile.proactiveStartDate.getMonth === 'function')
    ) {
      this.customerProfile.proactiveStartDate = new Date(this.customerProfile.proactiveStartDate);
    } else {
      this.customerProfile.proactiveStartDate = new Date();
    }
    if (this.customerProfile.proactiveEndDate !== undefined && !(typeof this.customerProfile.proactiveEndDate.getMonth === 'function')) {
      this.customerProfile.proactiveEndDate = new Date(this.customerProfile.proactiveEndDate);
    } else {
      this.customerProfile.proactiveEndDate = new Date();
    }
  }
  private removeCustomer() {
    this.deleteConfirmationDialog = false;
    const contacts = <FormArray>this.customerForm.get('contacts');
    contacts.removeAt(this.selectedContactId);
  }
  private clearProactiveValidator(control: AbstractControl) {
    control.clearValidators();
    control.updateValueAndValidity();
  }
  private addProactiveValidators(control: AbstractControl) {
    control.setValidators([Validators.required, Validators.min(5), Validators.max(100)]);
    control.updateValueAndValidity();
  }
  // since the Ui hides these inputs we make sure the validation only triggers if the inputs are visible
  private updateProactiveValidation(isProactive: boolean) {
    if (!isProactive) {
      // clear validators
      this.clearProactiveValidator(this.customerForm.get('notificationType'));
      this.clearProactiveValidator(this.customerForm.get('notificationVariance'));
    } else {
      // add validators
      this.addProactiveValidators(this.customerForm.get('notificationType'));
      this.addProactiveValidators(this.customerForm.get('notificationVariance'));
    }
  }

  private loadParameters() {
    this.route.queryParams.subscribe((params) => {
      this.customerProfileID = parseInt(this.route.snapshot.paramMap.get('id'), 10);
      this.copyFromCustomerID = parseInt(params.copyFrom, 10);
      this.createEnterpriseName = params.name;
      this.createEnterpriseAccount = params.account;
      this.enterpriseID = parseInt(params.enterpriseId, 10);
    });
  }
  private scrollToErrorDisplay() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }
  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }
  validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormArray) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public showCopyCustomerProfileModal() {
    this.copyProfileCustomerDialog = true;
  }

  public copyCustomerProfile() {
    // show confirmation modal
    const url = this.router.serializeUrl(this.router.createUrlTree(['/customer-profile-details/0/'], {
      queryParams: {
        copyFrom: this.customerProfileID,
        name: this.selectedEnterprise.name,
        account: this.selectedEnterprise.accountNumber,
        enterpriseId: this.selectedEnterprise.enterpriseID
      }
    }));

    window.open(url, '_blank');
    this.copyProfileCustomerDialog = false;
  }

  private getEnterprise(filter) {
    this.pcService
      .getEnterprises(filter)
      .pipe(
        map((data) => {
          return data.map((row) => {
            row.fullName = row.name + ' - ' + row.accountNumber;
            return row;
          });
        })
      )
      .subscribe((ep) => {
        ep.sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.enterprises = ep;
        this.enterpriseData = ep;
      });
  }

  protected handleEnterpriseValueChange(value) {
    const selectedEnterprise = this.enterprises.filter((s) =>
      s.fullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);

    selectedEnterprise.length === 1 && (this.selectedEnterprise = selectedEnterprise[0]);
  }

  public handleEnterpriseFilterChange(filter: any): void {
    filter.trim();
    filter.length >= 3 ? this.getEnterprise(filter) : (this.enterprises = []);
    this.enterpriseData = [];
    this.selectedEnterprise = null;
  }
}
