import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { EnterpriseDTO } from './Enterprise';
import { catchError } from 'rxjs/internal/operators/catchError';
@Injectable()
export class EnterpriseService {
  shipmentApiHeaders: HttpHeaders;
  shipmentApiHeadersJson: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeadersJson = new HttpHeaders({
      // testing
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  public getEnterprise(enterpriseID: number): Observable<EnterpriseDTO> {
    const url = `${this.startupService.shipmentApiUrl}v2/${enterpriseID}/enterprise`;
    return this.http
      .get<EnterpriseDTO>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }
}
