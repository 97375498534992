import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageChangeEvent, GridDataResult, SelectableSettings, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Helpers, getSettings, setSettings } from '../../_shared/helpers';
import { ProactiveHoldFilter } from '../../_shared/FilterFactory';
import { ProactiveResponse } from '../../models/Proactive';
import { AuthService } from '../../auth/auth.service';
import { ConvertJSONToCSVHelper } from '@/models/invoice-reports.interface';
import { FormatDataExportExcel } from '@/pages/duplicate-carrier/duplicate-carrier.interface';

let sharedStatusForSelection: string;

@Component({
  selector: 'app-proactive-hold-list',
  templateUrl: './proactive-hold-list.component.html',
  styleUrls: ['./proactive-hold-list.component.scss'],
})
export class ProactiveHoldListComponent implements OnInit {
  get theRecords(): ProactiveResponse[] {
    return this.gridDataAll;
  }

  @Input('theRecords')
  set theRecords(value: ProactiveResponse[]) {
    this.gridDataAll = value;
    this.gridDataCurrent = value;
    this.recordBookGrid = value;

    this.setRecords();
    this.proactiveHoldExcelFormat();
  }

  @Input() set cacheKeyGrid(value) {
    this._cacheKeyGrid = value;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataloading = value;
  }

  @Output()
  releaseButtonEvent = new EventEmitter<number[]>();

  @Output()
  disputeButtonEvent = new EventEmitter<number[]>();

  @Output()
  revertButtonEvent = new EventEmitter<number[]>();

  gridDataCurrent: ProactiveResponse[];
  gridDataAll: ProactiveResponse[];
  gridData: GridDataResult;
  helpers: Helpers;
  selectedRecords: number[] = [];
  selectedRecord: number;
  selectableSettings: SelectableSettings;
  canRevertInvoiceToManual: boolean;
  disableBulkActionForHold: boolean;
  disableBulkActionForSent: boolean;
  _cacheKeyGrid: string;
  releaseModal: boolean;
  disputeModal: boolean;
  revertModal: boolean;
  pageSize: number;
  skip: number;
  range = { start: null, end: null };
  sortableSettings: any;
  proactiveHoldFilter: ProactiveHoldFilter;
  public isDataloading: boolean;
  recordBookGrid: ProactiveResponse[] = [];
  convertJson: string = '';
  public state: State = {};
  public sort: SortDescriptor[] = [{ field: 'AccountName', dir: 'asc' }];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.gridDataCurrent = null;
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
    };
    this.sortableSettings = { allowUnsort: false };
    this.pageSize = 25;
    this.skip = 0;
    this.gridDataCurrent = this.gridDataAll;

    this.sort = getSettings(this._cacheKeyGrid, 'accountNumber', 'asc');
    this.proactiveHoldFilter = new ProactiveHoldFilter();
    this.helpers = new Helpers(this.authService);
    this.canRevertInvoiceToManual = this.helpers.hasOperation('CanRevertInvoiceToManual')
  }

  // ensure all selected items have the same status
  public selectionChange(e) {
    if (e.selectedRows.length > 1) {
      // selected rows will be more than 1 if they click all
      const statusForFilter = e.selectedRows[0].dataItem.invoiceStatus;
      this.selectedRecords = e.selectedRows
        .filter((obj) => obj.dataItem.invoiceStatus === statusForFilter)
        .map((x) => x.dataItem.id);
      sharedStatusForSelection = this.selectedRecords.length == 0 ? null : statusForFilter;
    } else {
      // selecting or deselecting one by one
      sharedStatusForSelection = this.selectedRecords.length + e.selectedRows.length - e.deselectedRows.length == 0
        ? null
        : e.selectedRows.length > 0
          ? e.selectedRows[0].dataItem.invoiceStatus
          : e.deselectedRows[0].dataItem.invoiceStatus;
    }
    this.disableBulkActionForSent = sharedStatusForSelection == 'ProactiveHold';
    this.disableBulkActionForHold = !this.canRevertInvoiceToManual || sharedStatusForSelection == 'ProactiveSent';
  }

  protected searchFilter(search: string = null): void {
    search
      ? (this.gridDataCurrent = this.gridDataAll.filter((obj) => this.proactiveHoldFilter.Match(obj, search.toLowerCase())))
      : (this.gridDataCurrent = this.gridDataAll);
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
    setSettings(this._cacheKeyGrid, this.sort);
  }

  private setRecords(): void {
    if (this.gridDataCurrent == null) {
      return;
    }
    const records = orderBy(this.gridDataCurrent, this.sort);
    this.gridData = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
    this.selectedRecords = [];
    this.selectedRecord = null;
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
      'k-disabled': sharedStatusForSelection && sharedStatusForSelection !== context.dataItem.invoiceStatus,
    };
  }

  linkSearch(event) {
    this.searchFilter(event);
  }

  closeConfirm() {
    this.releaseModal = false;
    this.disputeModal = false;
    this.revertModal = false;
  }

  confirmRelease() {
    this.releaseModal = true;
  }
  confirmDispute() {
    this.disputeModal = true;
  }
  confirmRevert() {
    this.revertModal = true;
  }

  // button on multi selection
  onConfirmRelease() {
    if (this.selectedRecord) {
      this.releaseButtonEvent.emit([this.selectedRecord]);
    } else {
      this.releaseButtonEvent.emit(this.selectedRecords);
    }
    this.closeConfirm();
  }
  onConfirmDispute() {
    if (this.selectedRecord) {
      this.disputeButtonEvent.emit([this.selectedRecord]);
    } else {
      this.disputeButtonEvent.emit(this.selectedRecords);
    }
    this.closeConfirm();
  }
  onConfirmRevert() {
    if (this.selectedRecord) {
      this.revertButtonEvent.emit([this.selectedRecord]);
    } else {
      this.revertButtonEvent.emit(this.selectedRecords);
    }
    this.closeConfirm();
  }

  // row click
  onReleaseProactive(dataItem) {
    this.selectedRecord = dataItem.id;
    this.confirmRelease();
  }
  onDisputeProactive(dataItem) {
    this.selectedRecord = dataItem.id;
    this.confirmDispute();
  }
  onRevertProactive(dataItem) {
    this.selectedRecord = dataItem.id;
    this.confirmRevert();
  }

  removeRecords(recordList: number[]) {
    for (let t = 0; t < recordList.length; t++) {
      this.gridDataAll.splice(
        this.gridDataAll.findIndex((i) => i.id === recordList[t]),
        1
      );
    }
    this.gridDataCurrent = this.gridDataAll;
    this.setRecords();
  }

  public proactiveHoldExcelFormat(): void {
    let data;
    if (this.recordBookGrid.length !== 0) {
      data = FormatDataExportExcel.proactiveHoldFormat(this.recordBookGrid);
      this.convertJson = ConvertJSONToCSVHelper(data);
    }
  }

  downloadExportData(csvPreppedData: string) {
    if (csvPreppedData && csvPreppedData.length > 0) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + escape(csvPreppedData));
      const fileName = 'Export-All-Proactive-Hold' + new Date().getDate();
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
