import { InvoiceService } from '@/services/invoice.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateFormatPipe } from './date-format.pipe';
import { FormInvoiceReportsCtrls, HEADER_CLASS } from './form.enums';
import { ColumnKendoGrid, ConvertJSONToCSVHelper, ConvertJSONToCSVHelperManually, IDetailedSummary } from './invoices-summary.interface';

@Component({
  selector: 'app-invoices-summary',
  templateUrl: './invoices-summary.component.html',
  styleUrls: ['./invoices-summary.component.scss'],
  providers: [DateFormatPipe, DatePipe],
  host: {
    class: 'table-custom',
  },
})
export class InvoicesSummaryComponent implements OnInit {

  formGrup: FormGroup;
  ctrlFrm = FormInvoiceReportsCtrls;
  dataInvoiceDetailedSummary: IDetailedSummary[] = [];
  dataInvoiceDetailedSummaryManually: IDetailedSummary[] = [];
  headerClass = HEADER_CLASS;
  todayDate = new Date();
  startDateMax = new Date();
  loading: boolean = false;

  gridSettings = null;
  gridSettingsManually = null;

  showHiddenColumns: boolean = false;
  showHiddenColumnsManually: boolean = false;

  columnsConfig = new ColumnKendoGrid().columns;
  columnsConfigManually = new ColumnKendoGrid().columnsManually;

  constructor(
    private formBuild: FormBuilder,
    private invoiceService: InvoiceService,
    private dateFormat: DateFormatPipe,
    private datePipe: DatePipe
  ) {
    this.validateGridDataLocalStore();
  }

  ngOnInit(): void {
    this.initializeFrm();
    this.searchDataTables();
  }

  initializeFrm(): void {
    let start = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate());
    this.formGrup = this.formBuild.group({
      [this.ctrlFrm.startDate]: [start, [Validators.required]],
      [this.ctrlFrm.endDate]: [this.todayDate, [Validators.required]]
    });
  }

  searchDataTables(): void {
    this.loading = true;
    this.invoiceService.getInvoiceDetailedSummary(this.formGrup.value).subscribe((data) => {
      this.assignDataInvoice(data);
      this.loading = false;
    });
  }

  validateGridDataLocalStore(): void {
    this.gridSettings = JSON.parse(localStorage.getItem('columnsConfig'));
    this.gridSettingsManually = JSON.parse(localStorage.getItem('columnsConfigManually'));

    if (this.gridSettings !== null) {
      this.columnsConfig = this.gridSettings;
      this.showHiddenColumns = !!this.validationHiddenColumns;
    }

    if (this.gridSettingsManually !== null) {
      this.columnsConfigManually = this.gridSettingsManually;
      this.showHiddenColumnsManually = !!this.validationHiddenColumnsManually;
    }
  }

  onVisibilityChange(e: any): void {
    e.columns.forEach((column) => {
      this.columnsConfig.find(
        (col) => col.field === column.field
      ).hidden = column.hidden;
    });
    localStorage.setItem('columnsConfig', JSON.stringify(this.columnsConfig,));
    this.showHiddenColumns = !!this.validationHiddenColumns;
  }

  onVisibilityChangeManually(e: any): void {
    e.columns.forEach((column) => {
      this.columnsConfigManually.find(
        (col) => col.field === column.field
      ).hidden = column.hidden;
    });
    localStorage.setItem('columnsConfigManually', JSON.stringify(this.columnsConfigManually,));
    this.showHiddenColumnsManually = !!this.validationHiddenColumnsManually;
  }

  get validationHiddenColumns(): boolean {
    return this.columnsConfig.some(data => data.hidden);
  }

  get validationHiddenColumnsManually(): boolean {
    return this.columnsConfigManually.some(data => data.hidden);
  }

  private assignDataInvoice(data: IDetailedSummary[]): void {
    this.dataInvoiceDetailedSummary = [];
    this.dataInvoiceDetailedSummaryManually = [];

    let invoicesExportedCount: number = 0;
    let totalInvoicesActualCount: number = 0;
    let duplicateCarrierInvoicesCount: number = 0;
    let unmatchedInvoicesGryphonCount: number = 0;
    let blueShipGryphonAuditCount: number = 0;
    let manualProactiveGryphonCount: number = 0;
    let proactiveHoldGryphonCount: number = 0;
    let proactiveSentGryphonCount: number = 0;
    let customerInvoiceErrorBlueShipCount: number = 0;
    let itInvoiceErrorCount: number = 0;
    let pendingtoREMSCount: number = 0;
    let inREMSCount: number = 0;

    let invoicesExportedCountManually: number = 0;
    let totalInvoicesActualCountManually: number = 0;
    let duplicateCarrierInvoicesCountManually: number = 0;
    let unmatchedInvoicesGryphonCountManually: number = 0;
    let blueShipGryphonAuditCountManually: number = 0;
    let manualProactiveGryphonCountManually: number = 0;
    let proactiveHoldGryphonCountManually: number = 0;
    let proactiveSentGryphonCountManually: number = 0;
    let customerInvoiceErrorBlueShipCountManually: number = 0;
    let itInvoiceErrorCountManually: number = 0;
    let pendingtoREMSCountManually: number = 0;
    let inREMSCountManually: number = 0;

    data.forEach(element => {

      element.importDate = this.datePipe.transform(this.dateFormat.transform(element.importDate), 'short');

      if (!element.importSource.includes('Manual')) {

        invoicesExportedCount = element.invoicesExported + invoicesExportedCount;
        totalInvoicesActualCount = element.totalInvoicesActual + totalInvoicesActualCount;
        duplicateCarrierInvoicesCount = element.duplicateCarrierInvoices + duplicateCarrierInvoicesCount;
        unmatchedInvoicesGryphonCount = element.unmatchedInvoicesGryphon + unmatchedInvoicesGryphonCount;
        blueShipGryphonAuditCount = element.blueShipGryphonAudit + blueShipGryphonAuditCount;
        manualProactiveGryphonCount = element.manualProactiveGryphon + manualProactiveGryphonCount;
        proactiveHoldGryphonCount = element.proactiveHoldGryphon + proactiveHoldGryphonCount;
        proactiveSentGryphonCount = element.proactiveSentGryphon + proactiveSentGryphonCount;
        customerInvoiceErrorBlueShipCount = element.customerInvoiceErrorBlueShip + customerInvoiceErrorBlueShipCount;
        itInvoiceErrorCount = element.itInvoiceError + itInvoiceErrorCount;
        pendingtoREMSCount = element.pendingtoREMS + pendingtoREMSCount;
        inREMSCount = element.inREMS + inREMSCount;

        this.dataInvoiceDetailedSummary.push(element)

      } else {

        invoicesExportedCountManually = element.invoicesExported + invoicesExportedCountManually;
        totalInvoicesActualCountManually = element.totalInvoicesActual + totalInvoicesActualCountManually;
        duplicateCarrierInvoicesCountManually = element.duplicateCarrierInvoices + duplicateCarrierInvoicesCountManually;
        unmatchedInvoicesGryphonCountManually = element.unmatchedInvoicesGryphon + unmatchedInvoicesGryphonCountManually;
        blueShipGryphonAuditCountManually = element.blueShipGryphonAudit + blueShipGryphonAuditCountManually;
        manualProactiveGryphonCountManually = element.manualProactiveGryphon + manualProactiveGryphonCountManually;
        proactiveHoldGryphonCountManually = element.proactiveHoldGryphon + proactiveHoldGryphonCountManually;
        proactiveSentGryphonCountManually = element.proactiveSentGryphon + proactiveSentGryphonCountManually;
        customerInvoiceErrorBlueShipCountManually = element.customerInvoiceErrorBlueShip + customerInvoiceErrorBlueShipCountManually;
        itInvoiceErrorCountManually = element.itInvoiceError + itInvoiceErrorCountManually;
        pendingtoREMSCountManually = element.pendingtoREMS + pendingtoREMSCountManually;
        inREMSCountManually = element.inREMS + inREMSCountManually;

        this.dataInvoiceDetailedSummaryManually.push(element)
      }

    });
    if (this.dataInvoiceDetailedSummary.length > 0) {
      this.dataInvoiceDetailedSummary.push({
        importSource: 'Total',
        extractedValue: '',
        importDate: '',
        blueShipGryphonAudit: blueShipGryphonAuditCount,
        carrierInvoiceShipmentNotFound: 0,
        itInvoiceError: itInvoiceErrorCount,
        duplicateCarrierInvoices: duplicateCarrierInvoicesCount,
        unmatchedInvoicesGryphon: unmatchedInvoicesGryphonCount,
        customerInvoiceErrorBlueShip: customerInvoiceErrorBlueShipCount,
        pendingtoREMS: pendingtoREMSCount,
        invoiceBlockedFromREMS: 0,
        manualProactiveGryphon: manualProactiveGryphonCount,
        proactiveHoldGryphon: proactiveHoldGryphonCount,
        proactiveSentGryphon: proactiveSentGryphonCount,
        invoicesExported: invoicesExportedCount,
        invoicesImported: 0,
        invoicesDropped: 0,
        totalInvoicesActual: totalInvoicesActualCount,
        inREMS: inREMSCount
      });
    }

    if (this.dataInvoiceDetailedSummaryManually.length > 0) {
      this.dataInvoiceDetailedSummaryManually.push({
        importSource: 'Total',
        extractedValue: '',
        importDate: '',
        blueShipGryphonAudit: blueShipGryphonAuditCountManually,
        carrierInvoiceShipmentNotFound: 0,
        itInvoiceError: itInvoiceErrorCountManually,
        duplicateCarrierInvoices: duplicateCarrierInvoicesCountManually,
        unmatchedInvoicesGryphon: unmatchedInvoicesGryphonCountManually,
        customerInvoiceErrorBlueShip: customerInvoiceErrorBlueShipCountManually,
        pendingtoREMS: pendingtoREMSCountManually,
        invoiceBlockedFromREMS: 0,
        manualProactiveGryphon: manualProactiveGryphonCountManually,
        proactiveHoldGryphon: proactiveHoldGryphonCountManually,
        proactiveSentGryphon: proactiveSentGryphonCountManually,
        invoicesExported: invoicesExportedCountManually,
        invoicesImported: 0,
        invoicesDropped: 0,
        totalInvoicesActual: totalInvoicesActualCountManually,
        inREMS: inREMSCountManually
      });
    }

  }

  onChange(value: Date): void {
    this.startDateMax = value;

    let startDate = this.formGrup.get(this.ctrlFrm.startDate).value;
    let endDate = this.formGrup.get(this.ctrlFrm.endDate).value;

    if (startDate > endDate)
      this.formGrup.controls[this.ctrlFrm.startDate].setValue(null);
  }

  showAllHidden(manually: boolean): void {
    if (manually) {
      this.columnsConfigManually = new ColumnKendoGrid().columnsManually;
      localStorage.removeItem('columnsConfigManually');
      this.showHiddenColumnsManually = false;
    } else {
      this.columnsConfig = new ColumnKendoGrid().columns;
      localStorage.removeItem('columnsConfig');
      this.showHiddenColumns = false;
    }
  }

  downloadExportData(typeInvoice: boolean, title: string): void {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + escape(
      (typeInvoice) ? ConvertJSONToCSVHelperManually(this.dataInvoiceDetailedSummaryManually)
        : ConvertJSONToCSVHelper(this.dataInvoiceDetailedSummary)));

    const fileName = title + new Date().getDate();
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
