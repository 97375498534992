import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flagIconCountry'
})
export class FlagIconCountryPipe implements PipeTransform {

  transform(value: string): string {
    return (value == 'USD') ? '../../../assets/united-states-of-america-flag-icon-32.png' : '../../../assets/canada-flag-icon-32.png';
  }

}
