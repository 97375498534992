import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as TLAuditBBillActions from '../actions/tl-audit-bbill-old.actions';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { TLAuditServiceOld } from '../services/tl-audit-old.service';

@Injectable()
export class TLAuditBBillEffectsOld {
  @Effect()
  saveBBillData$ = this.actions$.pipe(
    ofType(TLAuditBBillActions.SAVE_BBILL_OLD),
    mergeMap((action: TLAuditBBillActions.SaveBBillOld) =>
      this.tlAuditServicce.createTruckloadBBill(action.bbill, action.invoiceID).pipe(
        map((initialState) => {
          return new TLAuditBBillActions.SaveBBillSuccessOld(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditBBillActions.SaveBBillErrorOld(err.error));
        })
      )
    )
  );
  @Effect()
  getBBillRefData$ = this.actions$.pipe(
    ofType(TLAuditBBillActions.LOAD_SETTLEMENT_REASONS_OLD),
    mergeMap((action: TLAuditBBillActions.LoadSettlementReasonsOld) =>
      this.tlAuditServicce.getTLAuditReferenceData().pipe(
        map((initialState) => {
          return new TLAuditBBillActions.LoadSettlementReasonsSuccessOld(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditBBillActions.LoadSettlementReasonsErrorOld(err.error));
        })
      )
    )
  );
  constructor(private actions$: Actions, private tlAuditServicce: TLAuditServiceOld) {}
}
