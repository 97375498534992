import { FileRestrictions } from '@progress/kendo-angular-upload';

export class ChargeDescription {
  name: string;
  billingCode: string;
}

export type DocumentType = { text: String; value: String; canUpload: Boolean };

export class Globals {

  public static invoiceCurrencyList: Array<string> = ['USD', 'CAD'];

  public static UploadFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tif', '.tiff'],
  };
  public static AllowedExtensionsFormatMap: { [extension: string]: string } = {
    '.gif': 'GIF',
    '.jpeg': 'JPG',
    '.jpg': 'JPG',
    '.pdf': 'PDF',
    '.png': 'PNG',
    '.tif': 'TIFF',
    '.tiff': 'TIFF',
  };

  static mimeTypes = [
    { mime: 'image/jpeg', format: 'JPG' },
    { mime: 'image/png', format: 'PNG' },
    { mime: 'image/tiff', format: 'TIF' },
    { mime: 'application/pdf', format: 'PDF' }
  ];

  public static ProctiveShipmentFields: Array<{ title: String; field: String }> = [
    { title: 'Customer ID', field: 'CustomerID' },
    { title: 'Customer PO Number', field: 'Customer PO Number' },
    { title: 'Destination State', field: 'Destination State' },
    { title: 'Delivery Number', field: 'Delivery Number' },
    { title: 'External TMS ID', field: 'External TMS ID' },
    { title: 'Origin State', field: 'Origin State' },
    { title: 'Pallet Count', field: 'Pallet Count' },
    { title: 'Shipment Number', field: 'Shipment Number' },
    { title: 'Shipment Weight', field: 'Weight' },
    { title: 'Sold To', field: 'Sold To' },
    { title: 'Transfer Order Number', field: 'Transfer Order' },
  ];

  public static QueueList: Array<{ text: String; value: String }> = [
    { text: 'Customer', value: 'customer' },
    { text: 'Quick Tariff', value: 'quicktariff' },
    { text: 'BG', value: 'bg' },
    { text: 'Proactive', value: 'proactive' },
    { text: 'Non-Proactive', value: 'nonproactive' },
  ];

  // document types
  public static DocumentTypes: DocumentType[] = [
    { text: 'Billing-Invoice', value: 'BillingInvoice', canUpload: false },
    { text: 'Carrier-Invoice', value: 'CarrierInvoice', canUpload: false },
    { text: 'Carrier-Load-Tender', value: 'CarrierLoadTender', canUpload: false },
    { text: 'Certificate-Insurance', value: 'CertificateInsurance', canUpload: true },
    { text: 'Commercial', value: 'Commercial', canUpload: true },
    { text: 'Customer-Approval-Email', value: 'CustomerApprovalEmail', canUpload: true },
    { text: 'Customer-Rate-Confirmation', value: 'CustomerRateConfirmation', canUpload: true },
    { text: 'Delivery-Receipt', value: 'DeliveryReceipt', canUpload: true },
    { text: 'Letter-Authority', value: 'LetterAuthority', canUpload: true },
    { text: 'Lumper-Receipt', value: 'LumperReceipt', canUpload: true },
    { text: 'On-Hand-Notice', value: 'OnHandNotice', canUpload: true },
    { text: 'Quote-Document', value: 'QuoteDoc', canUpload: true },
    { text: 'Revised-Carrier-Load-Tender', value: 'RevisedCarrierLoadTender', canUpload: false },
    { text: 'Scale-Ticket', value: 'ScaleTicket', canUpload: true },
    { text: 'Tendered-BOL', value: 'TenderedBOL', canUpload: true },
    { text: 'Weight-Inspection', value: 'WeightInspection', canUpload: true },
  ];

  public static DeliveryAccessorials: Array<{ name: String; code: String }> = [
    { name: 'Airport Delivery', code: 'APD' },
    { name: 'Appointment', code: 'APT' },
    { name: 'Container Freight Station Delivery', code: 'CFP:DP' },
    { name: 'Grocery Warehouse Delivery', code: 'GRD' },
    { name: 'Inside Delivery', code: 'ID2' },
    { name: 'Lift Gate at Delivery', code: 'LGD' },
    { name: 'Notify Consignee', code: 'MNC' },
    { name: 'Residential Delivery', code: 'RD' },
    { name: 'School Delivery', code: 'SD' },
    { name: 'Trade Show Delivery', code: 'TRDS' },
  ];

  public static ShipmentAccessorials: Array<{ name: String; code: String }> = [
    { name: 'BOL Correction', code: 'BOL' },
    { name: 'Freeze Protection Fee', code: 'DNF' },
    { name: 'Hazardous Materials', code: 'HAZ' },
    { name: 'Inspection Fee', code: 'ISF' },
    { name: 'Limited Access Fee', code: 'LACS' },
    { name: 'Military Access', code: 'MIL' },
    { name: 'Over Dimension', code: 'OVD' },
    { name: 'Prepaid and Add', code: 'PSP' },
    { name: 'Single Shipment', code: 'SING' },
    { name: 'Sorting and Segregation', code: 'SEG' },
    { name: 'Tanker Endorsement', code: 'TNK' },
    { name: 'Weight Verification', code: 'WGT' },
  ];

  public static PickupAccessorials: Array<{ name: String; code: String }> = [
    { name: 'Airport Pickup', code: 'ARP' },
    { name: 'Container Freight Station Pickup', code: 'CFP:O' },
    { name: 'Grocery Warehouse Pickup', code: 'GRP' },
    { name: 'Inside Pickup', code: 'IP' },
    { name: 'Lift Gate Pickup', code: 'LGO' },
    { name: 'Residential Pickup', code: 'RP' },
    { name: 'Trade Show Pickup', code: 'TRDS1' },
  ];
  public static AccessorialType: Array<{ text: String; value: String }> = [
    { text: 'Airport Pickup', value: 'ARP' },
    { text: 'Container Freight Station Pickup', value: 'CFP:O' },
    { text: 'Grocery Warehouse Pickup', value: 'GRP' },
    { text: 'Inside Pickup', value: 'IP' },
    { text: 'Lift Gate Pickup', value: 'LGO' },
    { text: 'Residential Pickup', value: 'RP' },
    { text: 'Trade Show Pickup', value: 'TRDS1' },
  ];

  // statuses
  public static SettlementReasons: Array<string> = [
    'Additional Quantity',
    'Address Change',
    'After Hours',
    'Airport Delivery Fee',
    'Airport Pickup Fee',
    'Appointment Fee',
    'Blind Shipment Fee',
    'BOL Correction Fee',
    'Capacity',
    'Carpet Rate Applied',
    'Carrier Change',
    'Carrier Payable Only',
    'Class Corrected Per BOL',
    'Construction Site Delivery',
    'Construction Site Pickup',
    'Container Freight Station Delivery',
    'Container Freight Station Pickup',
    'Cube Fee',
    'Cubic Capacity Applied',
    'Detention Fee',
    'Distribution Center Delivery',
    'Dry Run Fee',
    'Freeze Protection',
    'Freight Given To Different Carrier',
    'Grocery Warehouse Delivery',
    'Guaranteed Delivery Fee',
    'Hand Unload fee',
    'HazMat Fee',
    'In-Bond Fee',
    'Inside Delivery',
    'Inside Pickup',
    'Inspection Fee',
    'Liftgate Delivery',
    'Liftgate Pickup',
    'Limited Access Delivery',
    'Limited Access Pickup',
    'Linear Footage Applied',
    'Lumper fee',
    'Military Site Delivery',
    'Non-Stack Fee',
    'Notify Consignee',
    'Over Dimension Fee',
    'Pallet Exchange Fee',
    'Port Charges',
    'Priority Service',
    'Reclass',
    'Reconsignment',
    'Reconsignment Fee',
    'Redelivery',
    'Redelivery Fee',
    'Residential Delivery',
    'Residential Pickup',
    'Return to Shipper',
    'Reweigh',
    'Reweigh Fee',
    'School Site Delivery',
    'Self Storage',
    'Sort & Segregate Fee',
    'Special Delivery',
    'Storage Fee',
    'Sufferance',
    'Trade Show Delivery',
    'Trade Show Pickup',
    'Weight Corrected Per BOL',
    'Wrong Dest State',
    'Wrong Dest Zip Code',
    'Wrong Origin State',
    'Wrong Origin Zip Code',
  ];

  // statuses
  public static Descriptions: Array<string> = [
    'Admin/Processing Fee',
    'Airport Delivery',
    'Airport Pickup',
    'Appointment',
    'Arbitrary Flat Fee',
    'BG Fee',
    'BOL Correction',
    'COD',
    'Comcheck Fee',
    'Construction Site',
    'Container',
    'Container Freight Station Delivery D',
    'Container Freight Station Pick Up P',
    'Contract Issue',
    'Cubic Capacity',
    'Detention Charge',
    'Driver Assist',
    'Dry Run Fee',
    'Excessive Length',
    'Farm Delivery',
    'Freeze Protection Fee',
    'Fuel Surcharge',
    'Grocery Warehouse Delivery',
    'Grocery Warehouse Pickup',
    'Guarantee Delivery',
    'Harmonized Sales Tax',
    'Hazardous Material',
    'High Cost Delivery',
    'In-Bond Charge',
    'Inbound Handling',
    'Inside Delivery',
    'Inside Pickup',
    'Inspection Fee',
    'Insurance Fee',
    'Layover Charge',
    'Lift Gate',
    'Lift Gate at Delivery',
    'Lift Gate at Pick-up',
    'Limited Access',
    'Limited Access Fee',
    'Loading Charge',
    'Lumper Charge',
    'Mark and Tag',
    'Matching Charge',
    'Military Access',
    'Military Access Fee',
    'Notify Consignee',
    'Other',
    'Outbound Handling',
    'Over Dimension',
    'Prepaid and Add',
    'Priority',
    'Reclass',
    'Reconsignment',
    'Redelivery Fee',
    'Residential',
    'Residential Delivery',
    'Residential Pickup',
    'Reweigh Fee',
    'School Delivery',
    'Sorting and Segregating',
    'Special Handling',
    'Storage Fee',
    'Sufferance Fee',
    'Tanker Endorsement',
    'Trade Show',
    'Trade Show Delivery',
    'Trade Show Pickup',
    'Truck Order Not Used',
    'Utility Fee',
    'Weight Verification',
  ];

  public static FreightClasses: Array<string> = [
    '0',
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500',
  ];

  public static SecondaryInvoiceTypes: Array<string> = [
    'B-Bill',
    'Unmatched',
    'Revised',
  ];

  public static Qualifiers: Array<{
    refRateQualifierID: number;
    rateCode: string;
    rateQualifier: string;
  }> = [
      { refRateQualifierID: 6, rateCode: 'AV', rateQualifier: 'AV' },
      { refRateQualifierID: 10, rateCode: 'CT', rateQualifier: 'CT' },
      { refRateQualifierID: 2, rateCode: 'CW', rateQualifier: 'Per Hundred Weight (CW)' },
      { refRateQualifierID: 1, rateCode: 'FR', rateQualifier: 'Flat Rate (FR)' },
      { refRateQualifierID: 5, rateCode: 'LB', rateQualifier: 'Per pound (LB)' },
      { refRateQualifierID: 8, rateCode: 'MN', rateQualifier: 'Minimum (MN)' },
      { refRateQualifierID: 7, rateCode: 'NA', rateQualifier: 'NA' },
      { refRateQualifierID: 3, rateCode: 'PCT', rateQualifier: 'Percent (PCT)' },
      { refRateQualifierID: 4, rateCode: 'PH', rateQualifier: 'Per Hundred Weight (PH)' },
      {
        refRateQualifierID: 12,
        rateCode: 'PHR',
        rateQualifier: 'Per House (PHR)',
      },
      { refRateQualifierID: 11, rateCode: 'PM', rateQualifier: 'Per Mile (PM)' },
      { refRateQualifierID: 13, rateCode: 'PS', rateQualifier: 'Per Stop (PS)' },
      { refRateQualifierID: 9, rateCode: 'PW', rateQualifier: 'PW' },
    ];

  public static AccessorialTypeValue: Array<{ text: String; value: number }> = [
    { text: 'Unknown', value: 0 },
    { text: 'Delivery', value: 1 },
    { text: 'Shipment', value: 2 },
    { text: 'Pickup', value: 3 },
  ];

  public static FinanceChargeTypeMapping: { [name: string]: string } = {
    Deficit: 'DEFICIT',
    Discount: 'DISCOUNT',
    Fuel: 'ACCESSORIAL_FUEL',
    AccessorialPercentageTotal: 'ACCESSORIAL_FUEL', // The opposite will NOT work
    Linehaul: 'ITEM',
    Accessorial: 'Accessorial',
    MgMinMaxAdjustment: 'MG_MINMAX_ADJ',
    SmcMinAdj: 'SMC_MIN_ADJ',
    Markup: 'MARKUP'
  };

  public static ShippingChargeTypeMapping: { [name: string]: string } = {
    AccessorialPercentageTotal: 'Fuel',
    Fuel: 'Fuel',
    Accessorial: 'Accessorial',
    Linehaul: 'Linehaul',
    Discount: 'Discount',
    Deficit: 'Deficit',
    MgMinMaxAdjustment: 'MgMinMaxAdjustment',
    SmcMinAdj: 'SmcMinAdj',
    SmcAdjustment: 'SmcMinAdj',
    Adjustment: 'MgMinMaxAdjustment',
    Markup: 'Accessorial'
  };

  // charge types
  public static ChargeTypes: Array<String> = [
    'DEFICIT',
    'DISCOUNT',
    'ITEM',
    'LINEHAUL',
    'MG_MINMAX_ADJ',
    'SMC_MIN_ADJ',
    'MGMINMAXADJUSTMENT',
    'SMCMINADJ',
  ];
  public static GroupTypes: Array<{
    group: string;
    displayName: string;
    type: string;
  }> = [
      { group: ' Linehaul', displayName: 'Deficit', type: 'DEFICIT' },
      { group: ' Linehaul', displayName: 'Discount', type: 'DISCOUNT' },
      { group: ' Linehaul', displayName: 'Linehaul', type: 'ITEM' },
      {
        group: ' Linehaul',
        displayName: 'MG Min/Max Adjustment',
        type: 'MG_MINMAX_ADJ',
      },
      {
        group: ' Linehaul',
        displayName: 'SMC Min Adjustment',
        type: 'SMC_MIN_ADJ',
      },
    ];
  public static QueueTypes: Array<string> = ['Customer', 'Carrier', 'Docs', 'BG', 'Quick Tariff', 'Return'];
  public static PaymentTypes: Array<string> = ['Third Party', 'Collect', 'Prepaid'];

  public static base64ToBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public static ChargeDescriptions: ChargeDescription[] = [
    { name: 'BG Fee', billingCode: 'BGFEE' },
    { name: 'Border-crossing', billingCode: 'CROS' },
    { name: 'Com-check', billingCode: 'COM' },
    { name: 'Customs', billingCode: 'CUST' },
    { name: 'Detention', billingCode: 'DET' },
    { name: 'Detention Loading', billingCode: 'DTL' },
    { name: 'Detention Unloading', billingCode: 'DTU' },
    { name: 'Driver Assist', billingCode: 'DRA' },
    { name: 'Driver Count', billingCode: 'DRC' },
    { name: 'Driver Unload', billingCode: 'DRU' },
    { name: 'Dry-run Fee', billingCode: 'DRF' },
    { name: 'Expedite', billingCode: 'EXP' },
    { name: 'Fees', billingCode: 'FEES' },
    { name: 'Fuel', billingCode: 'FUEL' },
    { name: 'Fuel Surcharge', billingCode: 'FUE' },
    { name: 'HazMat', billingCode: 'HAZ' },
    { name: 'Insurance', billingCode: 'INS' },
    { name: 'Layover Fee', billingCode: 'LAY' },
    { name: 'Liftgate', billingCode: 'LIFT' },
    { name: 'Linehaul', billingCode: 'LH' },
    { name: 'Line Haul', billingCode: 'LH' },
    { name: 'Lumper', billingCode: 'LUMP' },
    { name: 'Mark and Tag', billingCode: 'MAT' },
    { name: 'Misc.', billingCode: 'MISC' },
    { name: 'Other', billingCode: 'OTH' },
    { name: 'Overweight Charges', billingCode: 'OWC' },
    { name: 'Permits', billingCode: 'PERM' },
    { name: 'Reconsignment', billingCode: 'RECO' },
    { name: 'Redelivery', billingCode: 'REDE' },
    { name: 'Rental', billingCode: 'RENT' },
    { name: 'Scale Ticket', billingCode: 'TIX' },
    { name: 'Sort and Segregation', billingCode: 'SORT' },
    { name: 'Stop Off Charge', billingCode: 'SOC' },
    { name: 'Storage', billingCode: 'STOR' },
    { name: 'Tailgate', billingCode: 'TAIL' },
    { name: 'Tanker', billingCode: 'TANK' },
    { name: 'Tarps', billingCode: 'TARP' },
    { name: 'Taxes', billingCode: 'TAX' },
    { name: 'Team', billingCode: 'TEAM' },
    { name: 'Tolls', billingCode: 'TOLL' },
    { name: 'Trailer Detention', billingCode: 'TDET' },
    { name: 'Truck Ordered Not Used', billingCode: 'VOR' },
    { name: 'Wash out', billingCode: 'WASH' },
    { name: 'Seal', billingCode: 'SEAL' },
    { name: 'Block and Brace', billingCode: 'BLOC' },
    { name: 'Claim', billingCode: 'CLAIM' },
    { name: 'Out of Route Miles', billingCode: 'ORM' },
    { name: 'Return', billingCode: 'RETU' },
    { name: 'Reschedule', billingCode: 'RESC' },
    { name: 'Late Fee', billingCode: 'LATE' },
    { name: 'On Time Delivery', billingCode: 'OTD' },
    { name: 'Refund', billingCode: 'REFU' },
    { name: 'TWIC', billingCode: 'TWIC' },
    { name: 'Tanker Endorsement', billingCode: 'TANK' },
    { name: 'Gate Fee', billingCode: 'GATE' },
    { name: 'Escort', billingCode: 'ESCO' },
    { name: 'Overdimensional', billingCode: 'OVER' },
    { name: 'After Hours P/U or Delivery', billingCode: '540' },
    { name: 'Amazon P/U or Delivery', billingCode: 'AMPD' },
    { name: 'Arbitrary (High Cost Delivery Area)', billingCode: 'CDA' },
    { name: 'Arrival Notice', billingCode: 'ARNO' },
    { name: 'Blind Shipment', billingCode: 'BLND' },
    { name: 'BOL/Address Correction', billingCode: '20' },
    { name: 'COD Fe', billingCode: 'COL' },
    { name: 'Construction Site P/U Or Deliver', billingCode: 'CSPD' },
    { name: 'Cube Fee', billingCode: 'CUBE' },
    { name: 'Demurrage', billingCode: 'DEM' },
    { name: 'Distribution Center Delivery', billingCode: 'DCD' },
    { name: 'Do Not Stack', billingCode: 'DNS' },
    { name: 'Documents', billingCode: 'DOC' },
    { name: 'Drayage', billingCode: 'DRC' },
    { name: 'Excessive Length', billingCode: 'EXL' },
    { name: 'Extended Service Rate', billingCode: 'EXSR' },
    { name: 'Extra Liability', billingCode: 'LIAB' },
    { name: 'Farm Delivery', billingCode: 'FRMD' },
    { name: 'Farm Pickup', billingCode: 'FRMP' },
    { name: 'Fast Way Service', billingCode: 'FAST' },
    { name: 'Flip Fee', billingCode: 'FLIP' },
    { name: 'Freeze Protection Fee', billingCode: 'PSC' },
    { name: 'Guaranteed Delivery', billingCode: 'GUDE' },
    { name: 'Hand Unload Fee', billingCode: 'HAND' },
    { name: 'Holiday Delivery', billingCode: 'HOLD' },
    { name: 'Holiday Pickup', billingCode: 'HOLP' },
    { name: 'Inland Freight', billingCode: '450' },
    { name: 'Inland Fuel Surcharge', billingCode: 'INFL' },
    { name: 'Island Charge', billingCode: 'ILND' },
    { name: 'Manhattan Charge', billingCode: 'MAN' },
    { name: 'Mexico Fee', billingCode: 'MEX' },
    { name: 'Military Access Delivery', billingCode: 'MILA' },
    { name: 'Mine Site / Rmt Govt Delivery', billingCode: 'MINE' },
    { name: 'Ocean Charges', billingCode: 'COF' },
    { name: 'Other Accessorial Service Charge', billingCode: 'MSC' },
    { name: 'Pallet Jack On Delivery', billingCode: 'PJKD' },
    { name: 'Pallet Jack On Pickup', billingCode: 'PJKP' },
    { name: 'Port Congestion', billingCode: 'PCON' },
    { name: 'Pup Needed For Delivery', billingCode: 'PUPD' },
    { name: 'Same Day Delivery', billingCode: 'SAME' },
    { name: 'Saturday Delivery', billingCode: 'SATD' },
    { name: 'Saturday Pickup', billingCode: 'SATP' },
    { name: 'Single Shipment', billingCode: 'SSF' },
    { name: 'Special Delivery', billingCode: '685' },
    { name: 'Special Handling', billingCode: '690' },
    { name: 'Special Messenger Services', billingCode: 'MESS' },
    { name: 'Special Pickup', billingCode: '695' },
    { name: 'Straight Truck Delivery', billingCode: 'STCK' },
    { name: 'Sufferance Fee', billingCode: 'SUFF' },
    { name: 'Sunday Delivery', billingCode: 'SUND' },
    { name: 'Sunday Pickup', billingCode: 'SUNP' },
    { name: 'Terminal Handling Charge', billingCode: 'TERM' },
    { name: 'Trailer Clean Out', billingCode: 'TCO' },
    { name: 'Unloading Charge', billingCode: 'UND' },
    { name: 'Uplift', billingCode: 'ULFT' },
    { name: 'Vehicle Ordered But Not Used', billingCode: 'VOR' },
    { name: 'Wait Time', billingCode: 'WTG' }
  ];

  public static ExtraChargeDescriptions: ChargeDescription[] = [
    { name: 'Chassis', billingCode: 'CHE' },
    { name: 'Chassis Split', billingCode: 'CTF' },
    { name: 'Congestion', billingCode: 'CON' },
    { name: 'Bobtail Run', billingCode: 'BOB' },
    { name: 'Drop Trailer', billingCode: 'DTR' },
    { name: 'Dry Run', billingCode: 'DRU' },
    { name: 'In Bond', billingCode: 'BND' },
    { name: 'Inspection', billingCode: 'IAC' },
    { name: 'Lift Fee', billingCode: 'LFC' },
    { name: 'Per Diem', billingCode: 'PED' },
    { name: 'Pier Pass', billingCode: 'PIR' },
    { name: 'Pre Pull', billingCode: 'PPL' },
    { name: 'Residential', billingCode: 'RES' },
    { name: 'Transload', billingCode: 'TRL' },
    { name: 'Tri Axle', billingCode: 'EAX' },
    { name: 'Weight Verification', billingCode: 'WTV' },
  ];

  public static drayageChargeDescriptions: ChargeDescription[] = [
    { name: 'Admin/Processing Fee', billingCode: '586' },
    { name: 'Refrigeration', billingCode: 'REF' },
    { name: 'Pallet Exchange Charge', billingCode: 'PEC' },
    { name: 'Inbound Handling', billingCode: 'HHBIB' },
    { name: 'Loading', billingCode: 'LDG' },
    { name: 'Local Delivery/Drayage', billingCode: 'LOC' },
    { name: 'Outbound Handling', billingCode: 'HHBOB' },
  ];

  public static airOceanINTLGroundChargeDescriptions: ChargeDescription[] = [{ name: 'Duty', billingCode: '315' }];

  public static truckloadChargeDescriptions: ChargeDescription[] = [
    { name: 'Admin/Processing Fee', billingCode: '586' },
    { name: 'Inbound Handling', billingCode: 'HHBIB' },
    { name: 'Outbound Handling', billingCode: 'HHBOB' },
    { name: 'Harmonized Sales Tax', billingCode: 'HST' },
  ];

  public static targetRateReasons: Array<{ text: string; value: string }> = [
    { text: 'Standard', value: 'Standard' },
    { text: 'Customer Satisfaction', value: 'Satisfaction' },
    { text: 'Market Rate', value: 'Market' },
  ];
}
