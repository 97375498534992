import { Action } from '@ngrx/store';
import { TLAuditRefDataResponse, TLBBill } from '../dto/TruckloadAuditDTO';
import { BBill } from '../models/TruckloadAudit.ui';

export const SHOW_BBILL = 'Show BBill';
export const SAVE_BBILL = '[Finance API] Save Invoice As BBill';
export const SAVE_BBILL_SUCCESS = '[Finance API] Save Invoice As BBill Success';
export const SAVE_BBILL_ERROR = '[Finance API] Save Invoice As BBill Error';
export const LOAD_SETTLEMENT_REASONS = '[Finance API] Load Audit Settlement Reasons';
export const LOAD_SETTLEMENT_REASONS_SUCCESS = '[Finance API] Load Audit Settlement Reasons Success';
export const LOAD_SETTLEMENT_REASONS_ERROR = '[Finance API] Load Audit Settlement Reasons Error';

export class ShowBBill implements Action {
  readonly type = SHOW_BBILL;
  constructor(public visible: boolean, public invoiceID: number, public bbill: BBill) {}
}

export class SaveBBill implements Action {
  readonly type = SAVE_BBILL;
  constructor(public invoiceID: number, public bbill: TLBBill) {}
}
export class SaveBBillSuccess implements Action {
  readonly type = SAVE_BBILL_SUCCESS;
  constructor(public success: boolean) {}
}
export class SaveBBillError implements Action {
  readonly type = SAVE_BBILL_ERROR;
  constructor(public message: string) {}
}

export class LoadSettlementReasons implements Action {
  readonly type = LOAD_SETTLEMENT_REASONS;
  constructor() {}
}
export class LoadSettlementReasonsSuccess implements Action {
  readonly type = LOAD_SETTLEMENT_REASONS_SUCCESS;
  constructor(public tlAuditRefData: TLAuditRefDataResponse) {}
}
export class LoadSettlementReasonsError implements Action {
  readonly type = LOAD_SETTLEMENT_REASONS_ERROR;
  constructor(public message: string) {}
}

export type Actions =
  | ShowBBill
  | SaveBBill
  | SaveBBillSuccess
  | SaveBBillError
  | LoadSettlementReasons
  | LoadSettlementReasonsSuccess
  | LoadSettlementReasonsError;
