import { AuthService } from '@/auth/auth.service';
import { AuditCarrierUpdateListComponent } from '@/bg-common/audit-carrier-update-list/audit-carrier-update-list.component';
import { AuditQueueNotesListComponent } from '@/bg-common/audit-queue-notes-list/audit-queue-notes-list.component';
import { AuditQueueStatusUpdateComponent } from '@/bg-common/audit-queue-status-update/audit-queue-status-update.component';
import { ConvertToUnmatchedDialogComponent } from '@/bg-common/convert-to-unmatched-dialog/convert-to-unmatched-dialog.component';
import { RatesModalComponent } from '@/components/rates-modal/rates-modal.component';
import { MixAndMatchComponent } from '@/components/mix-and-match/mix-and-match.component';
import { ShipmentEditComponent } from '@/components/shipment-edit/shipment-edit.component';
import { priceSheetIDArray } from '@/models/Shipment';
import {
  CreateAuditStatusNote, InvoiceAuditRefDataResponse,
  SaveAuditStatusNote, SaveBGAuditStatusNote, SaveCustomerAuditStatusNote, UpdateStatusNote
} from '@/services/AuditQueue';
import { CarrierService } from '@/services/carrier.service';
import { CurrencyService } from '@/services/currency.service';
import { CustomerService } from '@/services/customer.service';
import { Enterprise } from '@/services/Enterprise';
import { InvoiceAudit, ReopenQueue, UpdateCarrierInvoice, UpdateCarrierInvoiceAction } from '@/services/Invoice';
import { InvoiceService } from '@/services/invoice.service';
import { AuditUserResponse } from '@/services/InvoiceAudit';
import { LocalStorageService } from '@/services/localstorage.service';
import { AuditQueueService } from '@/services/queue.service';
import { ShipmentDTO, Tracking } from '@/services/Shipment';
import { ShipmentService } from '@/services/shipment.service';
import { StartupService } from '@/startup.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { ConfirmationDialogService } from '@/_shared/confirmation-dialog/confirmation-dialog.service';
import { Globals } from '@/_shared/globals';
import { Helpers } from '@/_shared/helpers';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { forkJoin } from 'rxjs';
import { BgRatingHelper } from '../../components/bgRatingHelper';
import { LtlBuySellRateEditComponent } from '../../components/ltl-buy-sell-rate-edit/ltl-buy-sell-rate-edit.component';
import { MarkupCharge, Markup } from '../../models/BgRating';
import { EditInvoiceParameters } from '../../models/edit-invoice-parameters';
import { BgRatingService } from '../../services/bgRating.service';
import { EnterpriseService } from '../../services/enterprise.service';
import { ShipmentUI } from '../audit/Shipment.ui';
import { ShipmentQuoteHistoryComponent } from '@/bg-common/shipment-quote-history/shipment-quote-history.component';
import { InvoiceStatus } from '../invoices/enums';
import { ChargesUpdateHelper } from './charges-update-helper';
import { ShipmentInvoiceAuditUI as ui } from './ShipmentInvoiceAudit.ui';
import { Rate } from '@/models/ShipmentInvoiceAudit';

@Component({
  selector: 'app-shipment-invoice-audit',
  templateUrl: './shipment-invoice-audit.component.html',
  styleUrls: ['./shipment-invoice-audit.component.scss'],
})
export class ShipmentInvoiceAuditComponent implements OnInit, OnDestroy {
  currentInvoiceNumber: string;
  currentURL: string;
  currentInvoiceId: number;
  openedMoveShipment: boolean;
  openedDeleteInvoice: boolean;
  openedCreateBBill: boolean;
  openedCreateRevised: boolean;
  openedMakePrimary: boolean;
  openedAuditorConfirm: boolean;
  creatingBBill: boolean;
  bBillErrorMsg: '';
  deletingInvoice: boolean;
  disableAssignBtn: boolean;
  isLoading: boolean;
  isRefreshing: boolean;
  refreshTimeout = 0;
  shipment: ui.Shipment = null;
  shipmentInvoice: ShipmentUI.Shipment = null;
  helpers: Helpers = null;

  reopenQueueInvoiceNumber = '';

  bBillPreviousPayment = 0;
  bBillAccountNumber = '';
  bBillReason = [];

  selectedAuditor: { fullName: string; id: number } = { fullName: '', id: 0 };
  lastSelectedAuditor: { fullName: string; id: number } = {
    fullName: '',
    id: 0,
  };
  auditorList: AuditUserResponse[];
  tracking: Tracking[];
  trackingDetail: [];
  queueTypeList = Globals.QueueTypes;
  invoiceAuditReferences: InvoiceAuditRefDataResponse;
  queueStatusList: string[];
  errorTypeList: string[];
  contractReasonList: string[];
  settlementReasonList: string[];

  invoiceAuditID: number;
  shipmentID: number;
  invoiceID: number;
  endpoint: string;
  currentQueue: string;
  currentStatus = '';
  enterprise: string;
  loadingMessage: string;
  proActive: boolean;
  selectedQueueType: string;
  buttonText: string;
  showNoteControl = false;
  invoiceAudit = new InvoiceAudit();
  carrierEmailSentHistoryList = null;
  readOnlyCustomerChargesVisible: boolean;
  activeTab: number = 1;
  manualRateOperationAvailable: boolean;
  bgRatingHelper: BgRatingHelper = new BgRatingHelper();
  isPricesheetID: boolean = false;
  enterpriseCountry: Enterprise;
  conversion: number = 0;
  date: Date;
  uplift = new Markup();
  enableConvertToUnmatched: boolean = false;
  canEditnote: boolean = false;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild('theQueueControl', { static: false })
  auditQueueStatusUpdateComponent: AuditQueueStatusUpdateComponent;

  @ViewChild('theNoteControl', { static: false })
  auditNoteStatusUpdateComponent: AuditQueueStatusUpdateComponent;

  @ViewChild(AuditQueueNotesListComponent, { static: true })
  noteListGrid: AuditQueueNotesListComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild('auditcarrierupdatelist', { static: false })
  auditCarrierUpdateList: AuditCarrierUpdateListComponent;

  @ViewChild('shipmentEdit', { static: false })
  shipmentEdit: ShipmentEditComponent;

  @ViewChild('quoteHistory', { static: false })
  quoteHistory: ShipmentQuoteHistoryComponent;

  constructor(
    private pcInvoiceService: InvoiceService,
    private startupService: StartupService,
    private pcQueueService: AuditQueueService,
    private pcShipmentService: ShipmentService,
    private enterpriseService: EnterpriseService,
    private pcCustomerService: CustomerService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private confirmationDialogService: ConfirmationDialogService,
    private carrierService: CarrierService,
    private bgRatingService: BgRatingService,
    private dialogService: DialogService,
    private currencyService: CurrencyService,
  ) {
    // get the shipment id
    this.buttonText = 'Add Queue';
    this.invoiceAuditID = +this.route.snapshot.paramMap.get('id');
    this.shipmentID = +this.route.snapshot.paramMap.get('shipmentid');
    this.invoiceID = +this.route.snapshot.paramMap.get('invoiceid');
    this.loadingMessage = 'Loading invoice...';
    this.disableAssignBtn = false;
    this.creatingBBill = false;

    // remove the return queue
    this.queueTypeList = this.queueTypeList.filter((item) => item !== 'Return');

    this.route.queryParamMap.subscribe((queryParams) => {
      this.enterprise = queryParams.get('owner');
      this.proActive = queryParams.get('pro') === 'true';
    });

    this.enableConvertToUnmatched = this.startupService.enableSecondaryToUnmatched;
  }

  ngOnInit() {
    // are they allowed to view this page
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    if (!this.helpers.hasOperation('InvoiceAudit')) {
      this.router.navigate([`not-authorized`]);
    }

    this.manualRateOperationAvailable = this.hasOperation('CanAdd_ManualRate');
    // listen for changes to the localstorage
    window.addEventListener('storage', this.storageHandler.bind(this), false);

    this.loadPage();
  }



  ngOnDestroy() {
    // remove the lister and remove the storage item
    window.removeEventListener('storage', this.storageHandler);
    this.localStorage.remove(this.shipmentID.toString());
  }

  private getManualInvoice() {
    return (this.shipment.carrierInvoices || []).find((invoice) => invoice.status.toLowerCase() === 'manual');
  }

  get manualRateEnabled(): boolean {
    const manualInvoice = this.getManualInvoice();
    return this.isAuditor() && manualInvoice && this.manualRateOperationAvailable;
  }

  storageHandler() {
    // is this us?
    if (this.localStorage.get(this.shipmentID.toString()) != null) {
      this.localStorage.remove(this.shipmentID.toString());
      this.refresh(null);
    }
  }

  public clickViewBill() {
    window.open(`/audit-view-bill/${this.shipmentID}`, '_blank');
  }

  public changeQueue() {
    // wipe them out
    this.errorTypeList = [];
    this.contractReasonList = [];
    this.settlementReasonList = [];
    this.showNoteControl = false;
    this.auditQueueStatusUpdateComponent.reset(0);

    // set the correct lists
    this.setLists(this.selectedQueueType);
  }

  public onSuccessRerate($event: boolean): void {
    if ($event) {
      this.quoteHistory.getQuoteHistory();
    }
  }

  public addNote() {
    const updateDefaults = new UpdateStatusNote();
    if (this.shipment.auditNotes.length > 0) {
      // get the most current non-complete note
      const auditNote = this.setCurrentQueue();
      updateDefaults.selectedStatus = auditNote.noteStatus;

      if (auditNote.queueType === 'Customer') {
        updateDefaults.settlementReasonList = auditNote.settlementReason.split(',').map(function (item) {
          return item.trim();
        });
      } else if (auditNote.queueType === 'BG') {
        updateDefaults.selectedErrorType = auditNote.errorType;
        updateDefaults.selectedContractReason = auditNote.contractReason;
      }
    }
    this.auditNoteStatusUpdateComponent.defaults(updateDefaults, 1);
    this.showNoteControl = true;
    this.selectedQueueType = null;
  }

  public toggleInvoiceOpen(index: number) {
    if (this.shipment.carrierInvoices.length === 1) {
      return;
    }
    this.shipment.carrierInvoices[index].isOpen = !this.shipment.carrierInvoices[index].isOpen;
  }

  public readOnlyCustomerChargesOpened(opened: boolean): void {
    this.readOnlyCustomerChargesVisible = opened;
  }

  public onAuditorChanged(newValue: any) {
    // confirm we have an invoice
    if (!this.confirmCarrierInvoice()) {
      return;
    }
    this.disableAssignBtn = true;
    const obj = {
      updateCarrierAction: UpdateCarrierInvoiceAction.Auditor,
      confirmedOverwrite: false,
      auditor: newValue.fullName,
    } as UpdateCarrierInvoice;

    // ask the api to update the auditor - checking first
    this.pcInvoiceService.updateAuditor(obj, this.shipment.carrierInvoices[0].invoiceId).subscribe(
      (data) => {
        // did they get assigned to do we need confirmation
        if (data.validationResult.isValid === false) {
          this.openedAuditorConfirm = true;
        } else {
          this.disableAssignBtn = false;
          this.alertMessage.showAlertMessage('Assigning Auditor was Successful', 'Success');
        }
      },
      (error) => {
        this.disableAssignBtn = false;
        this.alertMessage.showAlertMessage('Error Assigning Auditor', 'Error');
      }
    );
  }
  protected clickAssignToMe() {
    // find the user and call the event
    this.lastSelectedAuditor = this.selectedAuditor;
    this.selectedAuditor = this.auditorList.filter((obj) => obj.fullName === this.authService.BlueShipUser.name)[0];

    // did we find a user?
    if (this.selectedAuditor == null) {
      this.alertMessage.showAlertMessage('You are not found in the Auditor list', 'Error');
      return;
    }

    this.onAuditorChanged(this.selectedAuditor);
  }
  protected OnSaveQueue(dataItem: UpdateStatusNote) {
    if (!this.confirmCarrierInvoice()) {
      return;
    }

    if (dataItem.selectedStatus === 'Complete') {
      this.showSpinner(true, 'Saving Queue...', true);
    }

    // fill in the variables.
    const record = {
      shipmentID: this.shipmentID,
      queueType: this.selectedQueueType,
      note: dataItem.textNotes,
      status: dataItem.selectedStatus,
      invoiceNumber: this.shipment.carrierInvoices[0].invoiceNumber, // always the first one since it is matched on invoiceID sent in
      settlementReasons: dataItem.settlementReasonList,
      errorType: dataItem.selectedErrorType,
      contractReason: dataItem.selectedContractReason,
    } as CreateAuditStatusNote;

    // update the db
    this.addAuditQueue(record);
  }
  protected OnCancelQueue(dataItem: boolean) {
    this.selectedQueueType = null;
  }
  protected OnReOpenQueue(dataItem: ui.AuditNote) {
    // call the end point
    this.reOpenQueue(dataItem);
  }
  protected OnSaveNote(dataItem: UpdateStatusNote) {
    // did they set the status?
    if (!dataItem.selectedStatus) {
      dataItem.selectedStatus = this.currentStatus;
    }

    if (dataItem.selectedStatus === 'Complete') {
      this.showSpinner(true, 'Saving Queue...', true);
    }

    // save to the correct queue type
    switch (this.currentQueue) {
      case 'Docs':
        this.saveAuditStatusNote(dataItem, 'documents');
        break;

      case 'Customer':
        this.saveCustomerAuditStatusNote(dataItem);
        break;

      case 'BG':
        this.saveBGAuditStatusNote(dataItem);
        break;

      case 'Quick Tariff':
        this.saveAuditStatusNote(dataItem, 'quick-tariffs');
        break;

      case 'Carrier':
        this.saveAuditStatusNote(dataItem, 'carriers');
        break;
    }
  }
  protected OnCancelNote(dataItem: boolean) {
    this.auditNoteStatusUpdateComponent.reset(0);
    this.showNoteControl = false;
  }

  public onMoveInvoice(invoiceId: number, invoiceNumber: string) {
    this.currentInvoiceId = invoiceId;
    this.currentInvoiceNumber = invoiceNumber;
    this.openedMoveShipment = true;
  }

  private onDeleteInvoice(invoiceId: number, invoiceNumber: string) {
    this.currentInvoiceId = invoiceId;
    this.currentInvoiceNumber = invoiceNumber;
    this.openedDeleteInvoice = true;
  }
  private onCreateBBill() {
    // clear it out
    this.resetBBill();

    this.bBillPreviousPayment = null;
    this.currentInvoiceId = this.invoiceID;

    // Filter on the BG Number and Status
    if (!this.confirmCarrierInvoice()) {
      return;
    }

    // do we have notes
    const auditNotes = this.shipment.auditNotes.filter((obj) => obj.invoiceNumber === this.shipment.carrierInvoices[0].invoiceNumber);

    if (auditNotes.length > 0) {
      // do we have customer notes
      let customerQueues = auditNotes.filter((x) => x.queueType === 'Customer');

      if (customerQueues.length > 0) {
        // do we need to sort?
        if (customerQueues.length > 1) {
          customerQueues = customerQueues.sort((a, b) => (b.date > a.date ? 1 : -1));
        }

        // take the first one
        this.bBillReason = customerQueues[0].settlementReason.split(',').map(function (item) {
          return item.trim();
        });
      }
    }

    this.openedCreateBBill = true;
  }
  private onCreateRevised() {
    this.currentInvoiceId = this.invoiceID;

    if (!this.confirmCarrierInvoice()) {
      return;
    }

    this.openedCreateRevised = true;
  }
  private onMakePrimary() {
    this.currentInvoiceId = this.invoiceID;

    if (!this.confirmCarrierInvoice()) {
      return;
    }

    this.openedMakePrimary = true;
  }
  private saveAuditStatusNote(dataItem: UpdateStatusNote, endpoint: string) {
    const record = {
      invoiceAuditID: this.invoiceAuditID,
      shipmentID: this.shipmentID,
      note: dataItem.textNotes,
      status: dataItem.selectedStatus,
    };

    // create a list
    const recordList: SaveAuditStatusNote[] = [];
    recordList.push(record);

    // Update the records
    this.updateAuditStatusNotes(recordList, endpoint);
  }

  private saveBGAuditStatusNote(dataItem: UpdateStatusNote) {
    const record = {
      invoiceAuditID: this.invoiceAuditID,
      shipmentID: this.shipmentID,
      note: dataItem.textNotes,
      status: dataItem.selectedStatus,
      errorType: dataItem.selectedErrorType,
      contractReason: dataItem.selectedContractReason,
    };

    // create a list
    const recordList: SaveBGAuditStatusNote[] = [];
    recordList.push(record);

    // Update the records
    this.updateBGAuditStatusNotes(recordList);
  }
  private saveCustomerAuditStatusNote(dataItem: UpdateStatusNote) {
    const record = {
      invoiceAuditID: this.invoiceAuditID,
      shipmentID: this.shipmentID,
      note: dataItem.textNotes,
      status: dataItem.selectedStatus,
      settlementReasons: dataItem.settlementReasonList,
    };

    // create a list
    const recordList: SaveCustomerAuditStatusNote[] = [];
    recordList.push(record);

    // Update the records
    this.updateCustomerStatusNotes(recordList);
  }

  // Helper functions
  private resetBBill() {
    this.bBillPreviousPayment = 0;
    this.bBillAccountNumber = this.shipment.accountNumber;
    this.bBillErrorMsg = '';
    this.bBillReason = [];
  }
  private isAuditor(): boolean {
    return this.selectedAuditor !== null && this.selectedAuditor.fullName.length > 0;
  }
  private showCreateBBillButton(): boolean {
    if (!this.confirmCarrierInvoice()) {
      return false;
    }
    const status = this.shipment.carrierInvoices[0].status;
    if (
      this.isAuditor() &&
      (status === 'Manual' || status === 'New') &&
      this.helpers.hasOperation('CanCreate_BBill') &&
      this.shipment.carrierInvoices[0].isBBill === false &&
      this.shipment.carrierInvoices[0].isSecondary === true
    ) {
      return true;
    }
    return false;
  }
  private showCreateRevisedButton(): boolean {
    if (!this.confirmCarrierInvoice()) {
      return false;
    }
    const status = this.shipment.carrierInvoices[0].status;
    if (
      status !== 'Revised' &&
      this.isAuditor() &&
      (status === 'Manual' || status === 'New' || status === 'Complete') &&
      this.shipment.carrierInvoices[0].isSecondary === true
    ) {
      return true;
    }
    return false;
  }
  private showMakePrimaryButton(): boolean {
    if (!this.confirmCarrierInvoice()) {
      return false;
    }
    const status = this.shipment.carrierInvoices[0].status;
    const isSecondary = this.shipment.carrierInvoices[0].isSecondary;
    if (this.isAuditor() && (status === 'Manual' || status === 'New') && isSecondary === true) {
      return true;
    }
    return false;
  }
  private createBBillDisabled(): boolean {
    if (
      this.bBillReason == null ||
      this.bBillReason.length === 0 ||
      this.creatingBBill === true ||
      this.bBillPreviousPayment < 0 ||
      this.bBillPreviousPayment > 10000
    ) {
      return true;
    }
  }
  private hideConvertBtn(status: string): boolean {
    return !this.isAuditor() || (status !== 'Manual' && status !== 'New');
  }

  private hideDeleteBtn(status: string): boolean {
    // must have an auditor assigned
    if (!this.isAuditor()) {
      return true;
    }
    // must have one of these permissions
    if (!this.hasOperation('canDelete_Invoice') && !this.hasOperation('CanDelete_InvoiceAdmin')) {
      return true;
    }
    // if its not manual or new AND they dont have the admin delete
    if (status !== 'Manual' && status !== 'New') {
      if (!this.hasOperation('CanDelete_InvoiceAdmin')) {
        return true;
      }
    }
    return false;
  }
  private hideEditMoveBtn(status: string): boolean {
    if (!this.isAuditor() || (status !== 'Manual' && status !== 'New') || !this.hasOperation('canEdit_Invoice')) {
      return true;
    }
  }
  private hideQueueBtn(): boolean {
    // current invoice status
    const status = this.shipment.carrierInvoices[0].status;
    return (
      !this.isAuditor() ||
      status.length === 0 ||
      (status !== 'Manual' && status !== 'New') ||
      !this.hasOperation('canCreate_InvoiceQueue') ||
      (this.currentStatus !== '' && this.currentStatus !== 'Complete') // current Queue Status
    );
  }
  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }
  private confirmCarrierInvoice(): boolean {
    return this.shipment.carrierInvoices.length === 0 ? false : true;
  }
  private setLists(selectedQueueType: string) {
    switch (selectedQueueType) {
      case 'Docs':
        this.queueStatusList = this.invoiceAuditReferences.docsAuditStatus;
        break;

      case 'Quick Tariff':
        this.endpoint = 'quick-tariff';
        this.queueStatusList = this.invoiceAuditReferences.quickTariffAuditStatus;
        break;

      case 'Customer':
        this.queueStatusList = this.invoiceAuditReferences.customerAuditStatus;
        this.settlementReasonList = this.invoiceAuditReferences.settlementReasons;
        break;

      case 'BG':
        this.queueStatusList = this.invoiceAuditReferences.bgAuditStatus;
        this.errorTypeList = this.invoiceAuditReferences.errorTypes;
        this.contractReasonList = this.invoiceAuditReferences.contractReason;
        break;

      case 'Carrier':
        this.queueStatusList = this.invoiceAuditReferences.carrierAuditStatus;
        break;
    }
  }
  private updateQueueList() {
    if (this.shipment == null || this.shipment.auditNotes == null || this.shipment.auditNotes.length === 0) {
      return;
    }
    if (!this.confirmCarrierInvoice()) {
      return;
    }

    // do we have notes
    const auditNotes = this.shipment.auditNotes.filter((obj) => obj.invoiceNumber === this.shipment.carrierInvoices[0].invoiceNumber);
    auditNotes.sort(function (a, b) {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

    if (auditNotes.length > 0) {
      this.currentStatus = auditNotes[0].queueStatus;
    }

    // get a list of unique status for the main invoice
    const invoiceNumber = this.shipment.carrierInvoices[0].invoiceNumber;
    const usedStatues = this.removeDuplicates(this.shipment.auditNotes, 'noteType')
      .filter(function (event) {
        return event.invoiceNumber === invoiceNumber;
      })
      .map((a) => a.noteType);

    // remove these from the status list
    this.queueTypeList = this.queueTypeList.filter(function (el) {
      return usedStatues.indexOf(el) < 0;
    });
  }
  private removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  private openFirstInvoice() {
    if (this.shipment && this.shipment.carrierInvoices && this.shipment.carrierInvoices.length > 0) {
      this.shipment.carrierInvoices[0].isOpen = true;
    }
  }
  get deleteInvoiceDisabled(): boolean {
    return this.openedDeleteInvoice || this.deletingInvoice;
  }
  get deleteInvoiceDisabledAttr(): string | null {
    return this.deleteInvoiceDisabled ? 'disabled' : null;
  }
  get userName(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem('userName') || '';
    }
    sessionStorage.setItem('userName', this.authService.BlueShipUser.name);
    return this.authService.BlueShipUser.name;
  }
  private setAuditor() {
    if (!this.confirmCarrierInvoice()) {
      return;
    }

    // set the auditor
    this.selectedAuditor = this.auditorList.filter((obj) => obj.fullName === this.shipment.carrierInvoices[0].auditor)[0];

    // did we find a user?
    if (this.selectedAuditor == null) {
      this.selectedAuditor = { fullName: '', id: 0 };
    }
    this.lastSelectedAuditor = this.selectedAuditor;
  }
  private setStatusList() {
    if (this.currentQueue == null || this.currentQueue.length === 0) {
      return;
    }
    this.endpoint = this.currentQueue.toLowerCase();

    // set the correct lists
    this.setLists(this.currentQueue);
  }
  private closeMoveInvoice() {
    this.openedMoveShipment = false;
  }
  private closeDeleteInvoice() {
    this.openedDeleteInvoice = false;
  }
  private closeCreateBBill() {
    this.openedCreateBBill = false;
  }
  private closeCreateRevised() {
    this.openedCreateRevised = false;
  }
  private closeMakePrimary() {
    this.openedMakePrimary = false;
  }
  private closeAuditConfirm() {
    this.disableAssignBtn = false;
    this.openedAuditorConfirm = false;
    this.selectedAuditor = this.lastSelectedAuditor;
  }

  private auditInvoice() {
    if (!this.confirmCarrierInvoice()) {
      return;
    }

    // calculate the subtotal variance
    this.invoiceAudit.subtotalVariance = this.pcInvoiceService.auditCalculateSubtotalCostVariance(
      this.shipment.carrierInvoices[0],
      this.shipment.carrierQuote
    );

    // calculate the total variance
    this.invoiceAudit.totalVariance = this.pcInvoiceService.auditCalculateTotalCostVariance(
      this.shipment.carrierInvoices[0],
      this.shipment.carrierQuote
    );

    // compare the invoice to the quote
    this.pcInvoiceService.auditInvoiceTotals(this.shipment.carrierInvoices[0].lineHaulCharges, this.shipment.carrierQuote.lineHaulCharges);
    this.pcInvoiceService.auditInvoiceTotals(
      this.shipment.carrierInvoices[0].accessorialCharges,
      this.shipment.carrierQuote.accessorialCharges
    );

    // other possible errors
    // weight
    this.invoiceAudit.errorList = [];
    const total = this.pcInvoiceService.auditCalculateWeightVariance(
      this.shipment.mode,
      this.shipment.carrierInvoices[0].weight,
      this.shipment.carrierQuote.weight
    );
    if (total !== 0) {
      this.invoiceAudit.errorList.push('Weight variance: ' + total.toLocaleString() + ' lbs');
    }

    // Scac
    let errorMsg = this.pcInvoiceService.auditScacVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
    if (errorMsg.length !== 0) {
      this.invoiceAudit.errorList.push(errorMsg);
    }

    const invoiceMode = this.shipment.carrierInvoices[0].mode;
    if (invoiceMode !== 'LTL' && invoiceMode !== 'LTL Volume' && invoiceMode !== 'Volume') {
      // MC Number
      errorMsg = this.pcInvoiceService.auditMCNumberVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
      if (errorMsg.length !== 0) {
        this.invoiceAudit.errorList.push(errorMsg);
      }

      // dot number
      errorMsg = this.pcInvoiceService.auditDOTNumberVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
      if (errorMsg.length !== 0) {
        this.invoiceAudit.errorList.push(errorMsg);
      }

      // carrier code
      errorMsg = this.pcInvoiceService.auditCarrierCodeNumberVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
      if (errorMsg.length !== 0) {
        this.invoiceAudit.errorList.push(errorMsg);
      }
    }

    // is there a pro
    const result = this.shipment.references.find((x) => x.type === 'PRO');
    if (result == null) {
      this.invoiceAudit.errorList.push('Missing PRO number');
    }

    // is there a selected rate
    if (!this.shipment.isSelectedRate) {
      this.invoiceAudit.errorList.push('No selected rate');
    }

    if (this.shipment.displayREMSWarningMessage)
      this.invoiceAudit.errorList.push('Do not make changes on this Shipment - Invoice has been sent to REMS ');

    if (this.shipment.displayREMSWarningMessageRevert) this.invoiceAudit.errorList.push('Invoice has been reverted to manual');
  }
  private isFirstInvoice(): boolean {
    if (!this.confirmCarrierInvoice()) {
      return;
    }
    return this.shipment.carrierInvoices[0].invoiceId === this.currentInvoiceId;
  }
  private removeInvoice() {
    const that = this;
    this.shipment.carrierInvoices = this.shipment.carrierInvoices.filter(function (obj) {
      return obj.invoiceId !== that.currentInvoiceId;
    });
  }

  // service calls
  private loadPage() {
    // the calls
    this.isLoading = true;
    const shipment = this.pcShipmentService.getShipmentByID(this.shipmentID);
    const auditReferences = this.pcQueueService.getAuditReferences();
    const auditorList = this.pcInvoiceService.getAuditors();
    const auditorNotes = this.pcInvoiceService.getAuditNotes(this.shipmentID);
    const carrierInvoices = this.pcInvoiceService.getCarrierInvoicesByID(this.shipmentID);

    // call them
    const combined = forkJoin([shipment, auditReferences, auditorList, auditorNotes, carrierInvoices]);
    combined
      .toPromise()
      .then((data) => {
        this.shipment = new ui.Shipment(data[0], this.invoiceID, this.enterprise, this.proActive);
        this.canEditnote = this.isEditable(data[0]);
        this.isPricesheetID = priceSheetIDArray.some((priceSheetID) => priceSheetID === data[0].selectedRate.priceSheetID);
        this.loadShipmentAudit();

        this.invoiceAuditReferences = data[1];
        this.auditorList = data[2];

        this.shipment.setAuditNotes(data[3]);
        this.shipment.setCarrierInvoices(data[4]);
        this.getCurrencyCode(data[4][0].invoiceDate);
        this.shipment.carrierQuote.originalPlannedWeight = this.shipment.getPlannedWeight(data[0].items);

        this.tracking = data[0].tracking;
        this.tracking.forEach((element) => {
          element.userCreated = data[0].userCreated;
        });
        const selectedRate = data[0].selectedRate;
        if (selectedRate.amount !== null && selectedRate.parentType !== null) {
          this.uplift.amount = selectedRate.amount;
          this.uplift.type = this.getBGRatingParentType(selectedRate.parentType);
        }

        return this.pcShipmentService.getEnterprise(this.shipment.enterpriseID).toPromise();
      })
      .then(
        (response) => {
          this.enterpriseCountry = response.enterprise;

          this.fuelChargesOrder();

          // set invoice number for re-open logic in note list
          if (this.shipment.carrierInvoices !== undefined && this.shipment.carrierInvoices.length > 0) {
            const currentCarrierInvoice = this.shipment.carrierInvoices[0];
            if (currentCarrierInvoice.status === 'New' || currentCarrierInvoice.status === 'Manual') {
              this.reopenQueueInvoiceNumber = this.shipment.carrierInvoices[0].invoiceNumber;
            }
          }
          // set the current queue
          this.setCurrentQueue();

          // Update the queue list then can choose from.  This is better than hittin the DB again
          this.updateQueueList();
          this.setStatusList();
          this.setAuditor();
          this.openFirstInvoice();
          this.auditInvoice();
          this.isLoading = false;
          if (this.isRefreshing) {
            window.clearTimeout(this.refreshTimeout);
            this.showSpinner(false);
            this.isRefreshing = false;
            this.alertMessage.showAlertMessage('Refresh complete', 'Success');
          }

          this.getCustomerProfileByEnterpriseID(this.shipment.enterpriseID);
          this.getCarrierEmailSentHistory(this.shipment.shipmentID);
        },
        (err) => {
          this.loadingMessage = 'Error Loading Page: ' + err.message;
          this.alertMessage.showAlertMessage('Error loading the audit page', 'Error');
        }
      );
  }

  getBGRatingParentType(type: string): string {
    if (type.toUpperCase() === 'UPLIFT FLAT') { return 'flat' }
    if (type.toUpperCase() === 'UPLIFT PERCENT') { return 'percent' }
    return null;
  }

  fuelChargesOrder(): void {
    this.shipment.carrierInvoices.forEach((element) => {
      let accessorialCharges: ui.Charge[] = [];

      let delivery = element.accessorialCharges.filter((data) => data.description == 'LIMITED ACCESS DELIVERY');
      let provision = element.accessorialCharges.filter((data) => data.description == 'SPECIAL FUEL PROVISION');
      let dataFilter = element.accessorialCharges.filter(
        (data) => data.description != 'LIMITED ACCESS DELIVERY' && data.description != 'SPECIAL FUEL PROVISION'
      );

      accessorialCharges.unshift(...delivery);
      accessorialCharges.unshift(...provision);
      accessorialCharges.push(...dataFilter);

      element.accessorialCharges = accessorialCharges;
    });
  }

  loadShipmentAudit() {
    this.pcShipmentService.getShipmentByIDInvoiceAudit(this.shipmentID).subscribe(
      (data) => {
        this.shipmentInvoice = new ShipmentUI.Shipment(data, true);
      },
      (err) => {
        this.loadingMessage = 'Error Loading Shipment Audit: ' + err.message;
        this.alertMessage.showAlertMessage('Error loading the shipment audit', 'Error');
      }
    );
  }

  private isEditable(shipment: ShipmentDTO): boolean {
    return (shipment.status || '').toLowerCase() !== 'cancelled';
  }

  private setCurrentQueue(): ui.AuditNote {
    // are there any notes?
    this.currentQueue = '';
    if (this.shipment.auditNotes === null || this.shipment.auditNotes.length === 0) {
      return;
    }

    // Filter on the BG Number and Status
    if (!this.confirmCarrierInvoice()) {
      return;
    }
    const queueList = this.shipment.auditNotes.filter(
      (obj) => obj.invoiceNumber === this.shipment.carrierInvoices[0].invoiceNumber && obj.noteStatus !== 'Complete'
    );
    if (queueList.length === 0) {
      return;
    }

    // sort on dates
    queueList.sort(function (a, b) {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

    this.currentQueue = queueList[0].queueType;
    this.invoiceAuditID = queueList[0].invoiceAuditID;
    return queueList[0];
  }
  private getCarrierEmailSentHistory(shipmentID: number) {
    // is there a carrier queue?
    if (!this.isCarrierQueue()) {
      return;
    }

    // call the endpoint
    this.pcInvoiceService.getCarrierEmailSentHistory(shipmentID).subscribe(
      (response) => {
        this.carrierEmailSentHistoryList = response;
      },
      (error) => { }
    );
  }
  private isCarrierQueue(): boolean {
    if (!this.confirmCarrierInvoice()) {
      return false;
    }
    const queueList = this.shipment.auditNotes.filter((obj) => obj.noteType === 'Carrier');
    return queueList.length === 0 ? false : true;
  }
  private getCustomerProfileByEnterpriseID(enterpriseID: number) {
    if (this.shipment == null) {
      return;
    }
    this.pcCustomerService.getSpecificCustomerProfileByEnterpriseID(enterpriseID).subscribe(
      (response) => {
        if (response && response.isProactive) {
          this.shipment.setCustomerProfile(response);
        }
      },
      (error) => { }
    );
  }

  private loadAuditNotes() {
    this.pcInvoiceService.getAuditNotes(this.shipmentID).subscribe(
      (responseList) => {
        // map object to UI object
        this.shipment.setAuditNotes(responseList);

        // set the current queue
        this.setCurrentQueue();

        // Update the queue list then can choose from.  This is better than hittin the DB again
        this.updateQueueList();
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error loading the audit records', 'Error');
      }
    );
  }

  private updateAuditStatusNotes(saveList: SaveAuditStatusNote[], endpoint: string) {
    this.pcQueueService.updateAuditStatusNotes(saveList, endpoint).subscribe(
      (responseList) => {
        if (saveList[0].status === 'Complete') {
          window.location.reload();
        }

        this.alertMessage.showAlertMessage('Successfully saved the note', 'Success');
        this.OnCancelNote(null);
        this.loadAuditNotes();
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error adding the note.', 'Error');
      },
      () => {
        this.showSpinner(false);
      }
    );
  }
  private updateBGAuditStatusNotes(saveList: SaveBGAuditStatusNote[]) {
    this.pcQueueService.updateBGAuditStatusNotes(saveList).subscribe(
      (responseList) => {
        this.alertMessage.showAlertMessage('Successfully saved the note', 'Success');
        if (saveList[0].status === 'Complete') {
          window.location.reload();
        }

        this.OnCancelNote(null);
        this.loadAuditNotes();
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error adding the note.', 'Error');
      },
      () => {
        this.showSpinner(false);
      }
    );
  }
  private updateCustomerStatusNotes(saveList: SaveCustomerAuditStatusNote[]) {
    this.pcQueueService.updateCustomerAuditStatusNotes(saveList).subscribe(
      (responseList) => {
        this.alertMessage.showAlertMessage('Successfully saved the note', 'Success');

        if (saveList[0].status === 'Complete') {
          window.location.reload();
        }

        this.OnCancelNote(null);
        this.loadAuditNotes();
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error adding the note', 'Error');
      },
      () => {
        this.showSpinner(false);
      }
    );
  }

  public clickAddManualRate($event) {
    this.confirmationDialogService.confirm('Add Manual Rate', 'Are you sure you want to add a manual rate?', 'Yes', 'No').then((result) => {
      if (result === true) {
        this.getTheMarkupsAndSave();
      }
    });
  }

  private getTheMarkupsAndSave() {
    const invoice = this.getManualInvoice();
    if (invoice) {
      const quote = this.shipment.carrierQuote as ui.CarrierQuote;
      quote.carrierName = invoice.carrierName;
      quote.carrierCode = invoice.carrierCode;
      quote.scac = invoice.scac;
      quote.distance = invoice.distance;
      quote.weight = invoice.weight;
      quote.dotNumber = invoice.dotNumber;
      quote.mcNumber = invoice.mcNumber;
      quote.mode = invoice.mode;

      this.pcInvoiceService.standardizeCarrierInvoiceCharges(invoice)
        .subscribe(charges => {

          quote.lineHaulCharges = charges.filter(c => c.group === 'Linehaul').map((charge) => Object.assign({}, charge));
          quote.accessorialCharges = charges.filter(c => c.group !== 'Linehaul').map((charge) => Object.assign({}, charge));

          if (this.uplift.amount != null && this.uplift.type != null) {
            this.upliftQuote(quote, true);
          } else {
            this.markupQuote(quote, true);
          }
        });
    }
  }
  upliftQuote(quote: ui.CarrierQuote, isManualCreate: boolean) {
    let carrierInvoice = this.shipment.carrierInvoices.find((x) => !x.isSecondary);
    if (!carrierInvoice && this.shipment.carrierInvoices.length > 0) {
      carrierInvoice = this.shipment.carrierInvoices[0];
    }

    const currencyCode = carrierInvoice ? carrierInvoice.currencyCode : this.shipmentEdit.shipmentData.currencyCode;
    const parameters = {
      scac: quote.scac,
      accountNumber: this.shipment.accountNumber,
      originCountryCode: this.shipment.origin.countryCode,
      originPostalCode: this.shipment.origin.postalCode,
      originStateProvince: this.shipment.origin.stateProvince,
      destinationCountryCode: this.shipment.destination.countryCode,
      destinationPostalCode: this.shipment.destination.postalCode,
      destinationStateProvince: this.shipment.destination.stateProvince,
      upliftAmount: this.uplift.amount,
      upliftType: this.uplift.type,
      shipmentDate: this.shipmentEdit.shipment.addresses[0].earliestDate,
      mode: this.shipmentEdit.shipmentData.mode,
      currencyCode: currencyCode,
    };

    const chargesToMarkup = quote.lineHaulCharges.concat(quote.accessorialCharges);
    const charges = chargesToMarkup.map((charge) => {
      return <MarkupCharge>{
        amount: charge.total,
        description: charge.description,
        type: this.bgRatingHelper.getBGChargeType(charge.type),
        subType: this.bgRatingHelper.getBGChargeSubType(charge.type),
        code: charge.ediCode,
      };
    });

    const request = this.bgRatingHelper.createUpliftRequest(parameters, this.shipmentEdit.uiShipment.items, charges)

    this.bgRatingService.getRateUplift(request).subscribe(
      (response) => {
        this.showSpinner(false);

        const error = response.errorMessage && response.errorMessage.length > 0;
        const rates = !error ? this.bgRatingHelper.mapRates(response, chargesToMarkup) : [];
        const rate = rates.length > 0 ? rates[0] : null;

        if (error || rate === null) {
          if (error) {
            console.log(response.errorMessage);
          }

          this.alertMessage.showAlertMessage('The uplift rates could not be retrieved', 'Error');
          return;
        }
        rate.isSelected = true;
        rate.mode = quote.mode;
        rate.carrierName = quote.carrierName;
        rate.isManualCreate = isManualCreate;
        rate.note = 'Manual';
        rate.priceSheetID = 'Manual';

        this.shipmentEdit.shipmentRerateResultsDTO = [rate];
        this.shipmentEdit.savePage();
      },
      (error) => {
        console.log(error);
        this.showSpinner(false);
        this.alertMessage.showAlertMessage('An error ocurred while retrieving the uplift rates', 'Error');
      }
    );
  }

  markupQuote(quote: ui.CarrierQuote, isManualCreate: boolean) {
    // Grab the primary invoice for the currency code. If there is none grab the first
    // secondary
    let carrierInvoice = this.shipment.carrierInvoices.find((x) => !x.isSecondary);
    if (!carrierInvoice && this.shipment.carrierInvoices.length > 0) {
      carrierInvoice = this.shipment.carrierInvoices[0];
    }
    const currencyCode = carrierInvoice ? carrierInvoice.currencyCode : this.shipmentEdit.shipmentData.currencyCode;
    const parameters = {
      scac: quote.scac,
      accountNumber: this.shipment.accountNumber,
      originCountryCode: this.shipment.origin.countryCode,
      originPostalCode: this.shipment.origin.postalCode,
      originStateProvince: this.shipment.origin.stateProvince,
      destinationCountryCode: this.shipment.destination.countryCode,
      destinationPostalCode: this.shipment.destination.postalCode,
      destinationStateProvince: this.shipment.destination.stateProvince,
      shipmentDate: this.shipmentEdit.shipment.addresses[0].earliestDate,
      mode: this.shipmentEdit.shipmentData.mode,
      currencyCode: currencyCode,
    };
    const contractName = quote.contractName;

    const chargesToMarkup = quote.lineHaulCharges.concat(quote.accessorialCharges);
    const charges = chargesToMarkup.map((charge) => {
      return <MarkupCharge>{
        amount: charge.total,
        description: charge.description,
        type: this.bgRatingHelper.getBGChargeType(charge.type),
        subType: this.bgRatingHelper.getBGChargeSubType(charge.type),
        code: charge.ediCode,
      };
    });

    this.showSpinner(true, 'Retrieving the markups for the manual rate...', true);
    this.parseWeightToInteger();

    const request = this.bgRatingHelper.createQuoteMarkupRequest(parameters, this.shipmentEdit.uiShipment.items, charges);

    this.bgRatingService.getMarkupCharges(request).subscribe(
      (response) => {
        this.showSpinner(false);

        const error = response.errorMessage && response.errorMessage.length > 0;
        const rates = !error ? this.bgRatingHelper.mapRates(response, chargesToMarkup) : [];
        const rate = rates.length > 0 ? rates[0] : null;

        if (error || rate === null) {
          if (error) {
            console.log(response.errorMessage);
          }

          this.alertMessage.showAlertMessage('The markup rates could not be retrieved', 'Error');
          return;
        }
        rate.contractName = contractName;
        rate.isSelected = true;
        rate.mode = quote.mode;
        rate.carrierName = quote.carrierName;
        rate.isManualCreate = isManualCreate;
        rate.note = 'Manual';
        rate.priceSheetID = 'Manual';

        this.shipmentEdit.shipmentRerateResultsDTO = [rate];
        this.shipmentEdit.savePage();
      },
      (error) => {
        console.log(error);
        this.showSpinner(false);
        this.alertMessage.showAlertMessage('An error ocurred while retrieving the markup rates', 'Error');
      }
    );
  }

  private addAuditQueue(auditQueue: CreateAuditStatusNote) {
    this.pcQueueService.addAuditQueue(auditQueue).subscribe(
      (response) => {
        this.alertMessage.showAlertMessage('Saved Successfully', 'Success');
        if (auditQueue.status === 'Complete') {
          window.location.reload();
        }

        // update the status, notes, current endpoint and status lists
        this.selectedQueueType = null;
        this.invoiceAuditID = response.id;
        this.currentStatus = auditQueue.status;
        this.currentQueue = auditQueue.queueType;
        this.setStatusList();

        // reload the notes
        this.loadAuditNotes();
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error adding the new queue.', 'Error');
      },
      () => {
        this.showSpinner(false);
      }
    );
  }
  public reOpenQueue(dataItem: ui.AuditNote) {
    // get the correct enum
    enum AuditType {
      Unknown = 'Empty',
      Carrier = 'Carrier',
      Customer = 'Customer',
      BG = 'BG',
      Docs = 'Docs',
      QuickTariff = 'Quick Tariff',
      Return = 'Return',
    }

    const queueType = dataItem.queueType.replace(/\s/g, '');
    const obj = {
      shipmentID: this.shipmentID,
      queueType: AuditType[queueType],
      invoiceNumber: dataItem.invoiceNumber,
    } as ReopenQueue;

    this.pcInvoiceService.reOpenQueue(dataItem.invoiceAuditID, obj).subscribe(
      (responseList) => {
        // map object to UI object
        this.shipment.setAuditNotes(responseList);

        // set the current queue
        this.setCurrentQueue();

        // Update the queue list then can choose from.  This is better than hittin the DB again
        this.updateQueueList();
      },
      (error) => {
        const showerror = 'undefined' === typeof error['error'] ? error : error.error;
        this.alertMessage.showAlertMessage(showerror, 'Error');
      }
    );
  }
  public moveInvoiceInputHandler(primaryReference: string) {
    this.closeMoveInvoice();

    const obj = {
      confirmedOverwrite: true,
      auditor: this.selectedAuditor.fullName,
      updateCarrierAction: UpdateCarrierInvoiceAction.Move,
      closingNote: 'Moved to Shipment ' + primaryReference,
      primaryReference: primaryReference,
    } as UpdateCarrierInvoice;

    // should we close the tab or remove the invoice?
    this.pcInvoiceService.moveInvoice(obj, this.currentInvoiceId).subscribe(
      (data) => {
        // should we close the tab or remove the invoice?
        if (this.isFirstInvoice()) {
          close();
        } else {
          this.removeInvoice();
        }
      },
      (error) => {
        const showerror = 'undefined' === typeof error['error'] ? error : error.error;
        this.alertMessage.showAlertMessage(showerror, 'Error');
      }
    );
  }
  private createBBillHandler() {
    this.bBillErrorMsg = '';
    this.creatingBBill = true;
    const obj = {
      shipmentID: this.shipmentID,
      bbillAccountNumber: this.bBillAccountNumber,
      bbillPreviousPayment: this.bBillPreviousPayment,
      bbillSettlementReasons: this.bBillReason,
      updateCarrierAction: UpdateCarrierInvoiceAction.BBill,
    } as UpdateCarrierInvoice;

    this.pcInvoiceService.createBBill(obj, this.currentInvoiceId).subscribe(
      (data) => {
        this.creatingBBill = false;
        this.closeCreateBBill();
        this.alertMessage.showAlertMessage('Creating a B-Bill was Successful', 'Success');

        this.loadPage();
      },
      (error) => {
        this.creatingBBill = false;
        this.bBillErrorMsg = 'undefined' === typeof error['error'] ? error : error.error;
      }
    );
  }

  private createInvoiceRevisedSuccessHandler() {
    this.closeCreateRevised();
    this.showSpinner(true, 'Create Revised invoice...');

    this.pcInvoiceService.createRevisedInvoice(this.currentInvoiceId).subscribe(
      (data) => {
        // update the invoice status
        let carrierInvoice = this.shipment.carrierInvoices.find((x) => x.isSecondary);
        carrierInvoice.status = 'Revised';
      },
      (error) => {
        const showerror = 'undefined' === typeof error['error'] ? error : error.error;
        this.alertMessage.showAlertMessage(showerror, 'Error');
      },
      () => {
        this.showSpinner(false);
      }
    );
  }
  private createMakePrimarySuccessHandler() {
    this.closeMakePrimary();
    this.showSpinner(true, 'Make Primary invoice...');

    this.pcInvoiceService.createPrimaryInvoice(this.currentInvoiceId).subscribe(
      (data) => {
        let carrierInvoice = this.shipment.carrierInvoices.find((x) => x.isSecondary);
        carrierInvoice.isSecondary = false;
      },
      (error) => {
        const showerror = 'undefined' === typeof error['error'] ? error : error.error;
        this.alertMessage.showAlertMessage(showerror, 'Error');
        this.showSpinner(false);
      },
      () => {
        this.showSpinner(false);
      }
    );
  }
  private deleteInvoiceSuccessHandler() {
    this.closeDeleteInvoice();
    this.deletingInvoice = true;
    this.showSpinner(true, 'Deleting invoice...');

    this.pcInvoiceService.deleteInvoice(this.currentInvoiceId).subscribe(
      (data) => {
        // should we close the tab or remove the invoice?
        if (this.isFirstInvoice()) {
          close();
        } else {
          this.removeInvoice();
        }
      },
      (error) => {
        const showerror = 'undefined' === typeof error['error'] ? error : error.error;
        this.alertMessage.showAlertMessage(showerror, 'Error');
      },
      () => {
        this.deletingInvoice = false;
        this.showSpinner(false);
      }
    );
  }

  public assignAuditorHandler() {
    if (!this.confirmCarrierInvoice()) {
      return;
    }
    this.openedAuditorConfirm = false;

    const obj = {
      updateCarrierAction: UpdateCarrierInvoiceAction.Auditor,
      confirmedOverwrite: true,
      auditor: this.selectedAuditor.fullName,
    } as UpdateCarrierInvoice;

    // ask the api to update the auditor - checking first
    this.pcInvoiceService.updateAuditor(obj, this.shipment.carrierInvoices[0].invoiceId).subscribe(
      (data) => {
        this.disableAssignBtn = false;
        if (data.validationResult.isValid === true) {
          this.alertMessage.showAlertMessage('Assigning Auditor was Successful', 'Success');
          this.lastSelectedAuditor = this.selectedAuditor;
        } else {
          this.alertMessage.showAlertMessage('Error Assigning Auditor', 'Error');
        }
      },
      (error) => {
        this.disableAssignBtn = false;
        this.alertMessage.showAlertMessage('Error Assigning Auditor', 'Error');
      }
    );
  }

  private showSpinner(show: boolean, text: string = '', modal: boolean = false) {
    this.appSpinner.loading = show;
    this.appSpinner.modal = modal;
    this.appSpinner.text = text;
  }

  private copyBol() {
    navigator.clipboard.writeText(this.shipment.bol);
  }

  private copyInvoice() {
    navigator.clipboard.writeText(this.shipment.carrierInvoices[0].invoiceNumber);
  }

  completeCarrierInvoice() {
    if (this.canCompleteInvoice && this.shipment.carrierInvoices.length > 0) {
      let bodyMsg = 'Are you sure you want to complete invoice ' + this.shipment.carrierInvoices[0].invoiceNumber + '?';
      const that = this;
      const warningList = this.invoiceAudit.errorList.map((x) => x);
      if (this.invoiceAudit.totalVariance !== 0) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        });
        warningList.push('Variance : ' + formatter.format(this.invoiceAudit.totalVariance));
      }
      this.confirmationDialogService
        .confirm('Confirm action', bodyMsg, 'Complete', 'Cancel', null, warningList)
        .then(function (result) {
          if (result === true) {
            that.carrierService.completeCarrierInvoice(that.invoiceID).subscribe((invoice) => {
              if (that.getInvoiceCompleteStatus(parseInt(invoice.status)) === InvoiceStatus.Complete) {
                that.shipment.carrierInvoices[0].status = 'Complete';
              }
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  getInvoiceCompleteStatus(status: number): InvoiceStatus {
    if (status === 1) {
      return InvoiceStatus.Complete;
    }
    return InvoiceStatus.None;
  }

  public get allowToReopen(): boolean {
    const status = (this.shipment.carrierInvoices[0].status || '').toLocaleLowerCase();
    return status != 'complete' && status != 'approved';
  }

  private getCurrencyCode(date: Date): void {
    this.currencyService.getConversionRate(date).subscribe((info) => {
      this.conversion = info.find((data) => data.country == 'CAN').value;
    });
  }


  private refresh(e: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    window.clearTimeout(this.refreshTimeout);
    this.isRefreshing = true;
    this.refreshTimeout = window.setTimeout(() => {
      this.showSpinner(true, 'Refreshing invoice...', true);
    }, 250);
    this.loadPage();
  }

  public onCustomerChargesUpdate($event) {
    this.auditCarrierUpdateList.refresh($event);
  }

  public onShipmentUpdate($event) {
    this.refresh(null);
  }

  public rateShipment() {
    if (this.uplift) {
      this.shipmentEdit.onRatePage(this.uplift);
    } else {
      this.shipmentEdit.onRatePage();
    }
  }

  public rateShipmentAll(): void {
    let dialogLayout = {
      width: 1800,
      maxHeight: `${95}%`,
      content: RatesModalComponent,
    };

    const dialogRef: DialogRef = this.dialogService.open(dialogLayout);
    const ratesModal = dialogRef.content.instance;
    ratesModal.bgReRateAll$ = this.shipmentEdit.getQuote();
    ratesModal.shipment = this.shipment;
    ratesModal.uplift = this.uplift;
    ratesModal.originEarliestDate = this.shipmentEdit.shipment.addresses[0].earliestDate;
    ratesModal.items = this.shipmentEdit.uiShipment.items;


    dialogRef.result.subscribe((response: Rate[]) => {
      if (!(response instanceof DialogCloseResult))
        this.shipmentEdit.saveRates(response);
    });
  }

  public rateChargeShipment() {
    this.shipmentEdit.bgRerateCharge();
  }

  get shipmentEditEnabled(): boolean {
    return !!this.isPricesheetID && !!this.shipmentEdit && this.shipmentEdit.validPage() && this.isAuditor();
  }

  get canCompleteInvoice(): boolean {
    return (
      this.isAuditor() &&
      this.hasOperation('canComplete_Invoice') &&
      (this.shipment.auditNotes.length === 0 || this.allAuditQueuesAreCompleted) &&
      this.shipment.carrierInvoices[0].status !== 'Complete' &&
      this.shipment.carrierInvoices[0].status !== 'Approved'
    );
  }

  get allAuditQueuesAreCompleted(): boolean {
    return this.shipment.auditNotes.every((audit) => audit.queueStatus.toLowerCase() === 'complete');
  }

  get invoiceHasErrors(): boolean {
    return this.invoiceAudit.errorList.length > 0;
  }

  get firstInvoiceIsPrimary() {
    return this.shipment.carrierInvoices.length > 0 && this.shipment.carrierInvoices[0].isSecondary === false;
  }

  public onReferencesUpdate(references: ShipmentUI.ReferenceUI[]) {
    const errorMessage = 'Missing PRO number';
    this.invoiceAudit.errorList = this.invoiceAudit.errorList.filter((error: string) => error !== errorMessage);

    if (!references.find((x) => x.type === 'PRO')) {
      this.invoiceAudit.errorList.push(errorMessage);
    }
  }

  //Add-Edit Invoice Dialog
  public editingInvoice: boolean = false;
  public editingCanadaInvoice = false;

  public editInvoiceDialogTitle: string = '';

  public editInvoiceParameters: EditInvoiceParameters = <EditInvoiceParameters>{
    invoiceID: 0,
    shipmentID: 0,
    isEditMode: true,
    enterprise: null,
  };

  public addCarrierInvoice() {
    this.editInvoiceDialogTitle = 'Add Carrier Invoice';
    this.editInvoiceParameters.invoiceID = 0;
    this.editInvoiceParameters.shipmentID = this.shipmentID;
    this.editInvoiceParameters.isEditMode = false;
    this.editInvoiceParameters.enterprise = this.enterpriseCountry;
    this.editInvoiceParameters.selectedRateCurrencyCode = this.shipment.currencyCode;

    if (this.startupService.showCanada) {
      this.editingCanadaInvoice = true;
    } else {
      this.editingInvoice = true;
    }

    document.body.classList.add('not-scroll');
  }

  public onEditInvoice(invoiceId: number) {
    this.editInvoiceDialogTitle = 'Edit Carrier Invoice';

    this.editInvoiceParameters.invoiceID = invoiceId;
    this.editInvoiceParameters.shipmentID = this.shipmentID;
    this.editInvoiceParameters.isEditMode = true;
    this.editInvoiceParameters.enterprise = this.enterpriseCountry;
    this.editInvoiceParameters.selectedRateCurrencyCode = this.shipment.currencyCode;

    if (this.startupService.showCanada) {
      this.editingCanadaInvoice = true;
    } else {
      this.editingInvoice = true;
    }

    document.body.classList.add('not-scroll');
  }

  public closeEditInvoice() {
    this.editingInvoice = false;
    this.editingCanadaInvoice = false;

    document.body.classList.remove('not-scroll');
  }

  public onInvoiceUpdated($event) {
    this.closeEditInvoice();
    this.refresh(null);
  }

  public onInvoiceCreated($event) {
    this.closeEditInvoice();
    this.refresh(null);
  }

  public editCarrierCharges(carrierQuote) {
    document.body.classList.add('not-scroll');

    const dialogRef = this.dialogService.open({
      content: LtlBuySellRateEditComponent,
    });

    const instance = dialogRef.content.instance as LtlBuySellRateEditComponent;
    instance.parameters = {
      title: 'Edit Carrier Charges',
      invoiceID: this.invoiceID,
      shipmentID: this.shipment.shipmentID,
      quote: {
        id: carrierQuote.id,
        accessorialCharges: ChargesUpdateHelper.mapCarrierCharges(carrierQuote.accessorialCharges),
        lineHaulCharges: ChargesUpdateHelper.mapCarrierCharges(carrierQuote.lineHaulCharges),
      },
      isBuyRate: true,
      currencyCode: this.shipment.currencyCode,
      distance: carrierQuote.distance,
    };

    dialogRef.result.subscribe((response) => {
      if (!(response instanceof DialogCloseResult)) {
        this.updateCharges();
        ChargesUpdateHelper.updateCarrierCharges(
          this.shipment,
          this.shipmentInvoice,
          this.pcInvoiceService,
          this.alertMessage,
          instance.savedCharges
        );
      }
    });

    document.body.classList.remove('not-scroll');
  }

  editCustomerCharges() {
    document.body.classList.add('not-scroll');

    const dialogRef = this.dialogService.open({
      content: LtlBuySellRateEditComponent,
    });

    const instance = dialogRef.content.instance as LtlBuySellRateEditComponent;
    instance.parameters = {
      title: 'Edit Customer Charges',
      invoiceID: this.invoiceID,
      currencyCode: this.shipmentInvoice.currencyCode,
      shipmentID: this.shipment.shipmentID,
      quote: {
        accessorialCharges: ChargesUpdateHelper.mapCustomerCharges(this.shipmentInvoice.accessorialCharges),
        lineHaulCharges: ChargesUpdateHelper.mapCustomerCharges(this.shipmentInvoice.lineHaulCharges),
        id: this.shipmentInvoice.selectedRateId,
      },
      isBuyRate: false,
      distance: this.shipment.carrierQuote.distance,
    };

    dialogRef.result.subscribe((response) => {
      if (!(response instanceof DialogCloseResult)) {
        this.updateCharges();
        ChargesUpdateHelper.updateCustomerCharges(this.shipmentInvoice, this.alertMessage, instance.savedCharges);
        this.auditCarrierUpdateList.invoice = this.shipmentInvoice;
      }
    });

    document.body.classList.remove('not-scroll');
  }

  parseWeightToInteger() {
    let items = this.shipmentEdit.uiShipment.items;
    for (let i = 0; i < items.length; i++) {
      items[i].weight = Math.round(items[i].weight);
    }
  }

  isSecondary(carrierInvoice: any): boolean {
    return carrierInvoice.isSecondary && (carrierInvoice.status === 'New' || carrierInvoice.status === 'Manual');
  }

  convertToUnmatched(carrierInvoice: any): void {
    // Query the pending carrier invoice for the primary reference number
    this.pcInvoiceService
      .getInvoiceByInvoiceID(carrierInvoice.invoiceId)
      .toPromise()
      .then((pendingCarrierInvoice) => {
        let referenceNumber: string = '';

        if (pendingCarrierInvoice && pendingCarrierInvoice.references) {
          const primaryReference = pendingCarrierInvoice.references.find((x) => x.isPrimary);
          referenceNumber = primaryReference ? primaryReference.value : referenceNumber;
        }

        const dialogRef = this.dialogService.open({
          title: 'Convert Invoice to Unmatched',
          content: ConvertToUnmatchedDialogComponent,
          minWidth: 300,
        });

        let instance = dialogRef.content.instance as ConvertToUnmatchedDialogComponent;
        instance.referenceNumber = referenceNumber;
        return dialogRef.result.toPromise();
      })
      .then((result: any) => {
        if (result instanceof DialogCloseResult) return;

        return this.pcInvoiceService
          .convertToUnmatched(carrierInvoice.invoiceId, result.referenceNumber)
          .toPromise()
          .then(() => {
            if (this.isFirstInvoice) {
              close();
            } else {
              return this.pcInvoiceService
                .getCarrierInvoicesByID(this.shipmentID)
                .toPromise()
                .then((data2) => {
                  this.shipment.setCarrierInvoices(data2);
                  this.openFirstInvoice();
                  this.auditInvoice();
                  this.setStatusList();
                });
            }
          });
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  private updateCharges(): void {
    const shipment = this.pcShipmentService.getShipmentByID(this.shipmentID);
    const auditorNotes = this.pcInvoiceService.getAuditNotes(this.shipmentID);
    const carrierInvoices = this.pcInvoiceService.getCarrierInvoicesByID(this.shipmentID);

    const combined = forkJoin([shipment, auditorNotes, carrierInvoices]);
    combined.subscribe(
      (data) => {
        this.shipment = new ui.Shipment(data[0], this.invoiceID, this.enterprise, this.proActive);
        this.loadShipmentAudit();

        this.shipment.setAuditNotes(data[1]);
        this.shipment.setCarrierInvoices(data[2]);

        this.updateQueueList();
        this.openFirstInvoice();
        this.auditInvoice();
        this.fuelChargesOrder();
        this.alertMessage.showAlertMessage('The customer charges were updated successfully', 'Success');
      },
      (err) => {
        this.loadingMessage = 'Error Loading Page: ' + err.message;
        this.alertMessage.showAlertMessage('Error loading the audit page', 'Error');
      }
    );
  }

  async onDeleteQueueNote(id: number): Promise<void> {
    let promiseConfirmation = await this.confirmationDialogService
      .confirm('Confirm', 'Are you sure you want to delete this queue?')
      .catch(() => { });

    if (promiseConfirmation) {
      this.pcQueueService.deleteQueueNotes(id).subscribe(
        () => {
          this.alertMessage.showAlertMessage('Queue correctly deleted', 'Success');
          window.location.reload();
        },
        () => this.alertMessage.showAlertMessage('Error while deleting queue', 'Error')
      );
    }
  }

  mixAndMatch() {
    let dialogLayout = {
      width: 1800,
      maxHeight: 800,
      content: MixAndMatchComponent,
    };

    const dialogRef: DialogRef = this.dialogService.open(dialogLayout);
    const mixAndMatchModal = dialogRef.content.instance;
    mixAndMatchModal.shipmentID = this.shipment.shipmentID;

    dialogRef.result.subscribe((response) => {
      if (!(response instanceof DialogCloseResult)) {
        this.loadPage();
      }
    });
  }
}
