import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { ApproveToPayService } from '@/services/approve-to-pay-service';
import { ApproveToPay, UpdateApproveToPay } from '@/models/ApproveToPay';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '@/auth/auth.service';
import { AuthOperation } from '@/auth/AuthOperation';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-approve-to-pay-detail',
  templateUrl: './approve-to-pay-detail.component.html',
})
export class ApproveToPayDetailComponent implements OnInit, OnDestroy {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  approveToPay: ApproveToPay;
  approveToPayForm: FormGroup;
  routeId: number;
  isDataLoading = false;
  routeSubscription: Subscription;
  get isNewRecord() { return this.routeId === -1; }
  get controls() { return this.approveToPayForm.controls; }
  get editApproveToPay() { return this.approveToPayForm.value as UpdateApproveToPay; }

  constructor(
    private atpService: ApproveToPayService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    if (!this.authService.hasOperation(AuthOperation.CanViewCustomerProfile)) {
      this.router.navigate([`not-authorized`]);
    }

    this.isDataLoading = true;

    this.routeSubscription = this.route.queryParams.subscribe(() => {
      this.isDataLoading = false;
      const routeIdText = this.route.snapshot.paramMap.get('id');
      this.routeId = routeIdText ? parseInt(routeIdText, 10) : -1

      this.loadData();
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  loadData() {
    if (this.isNewRecord) {
      this.createForm();
      return;
    }

    this.isDataLoading = true;
    this.atpService
      .getApproveToPay(this.routeId)
      .subscribe(
        (res) => {
          this.isDataLoading = false;

          if (res == null) {
            this.alertMessage.showAlertMessage(`ApproveToPay with ID '${this.routeId}' does NOT exist`, 'Error');
          } else {
            this.approveToPay = res;
            this.createForm(res);
          }
        },
        () => {
          this.isDataLoading = false;
          this.alertMessage.showAlertMessage(`Error loading ApproveToPay with ID '${this.routeId}'`, 'Error');
        }
      );
  }

  canEdit() {
    return this.authService.hasOperation(AuthOperation.CanEditCustomerProfile);
  }

  createForm(approveToPay: Partial<ApproveToPay> = {}) {
    this.approveToPayForm = this.formBuilder.group({
      groupCode: [approveToPay.groupCode, [Validators.required, Validators.minLength(2), Validators.maxLength(12)]],
      invoicePrefix: [approveToPay.invoicePrefix, [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
    });

    if (!this.canEdit()) {
      this.controls['groupCode'].disable();
      this.controls['invoicePrefix'].disable();
    }
  }

  saveApproveToPay() {
    if (this.isNewRecord) {
      this.createApproveToPay();
    } else {
      this.updateApproveToPay();
    }
  }

  createApproveToPay() {
    this.isDataLoading = true;

    this.atpService
      .createApproveToPay(this.editApproveToPay)
      .subscribe(
        (res) => {
          this.router.navigate([`/approve-to-pay/${res.id}`]);
          this.isDataLoading = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.isDataLoading = false;

          if (errorResponse.status === 400) {
            this.alertMessage.showAlertMessage(errorResponse.error, 'Error');
          } else {
            this.alertMessage.showAlertMessage('Error creating ApproveToPay record.', 'Error');
          }
        }
      );
  }

  updateApproveToPay() {
    const atpID = this.approveToPay.id;
    this.isDataLoading = true;

    this.atpService
      .updateApproveToPay(atpID, this.editApproveToPay)
      .subscribe(
        (res) => {
          this.approveToPay = res;
          this.createForm(res);
          this.isDataLoading = false;
          this.alertMessage.showAlertMessage('Successfully saved ApproveToPay metadata.', 'Success');
        },
        (errorResponse: HttpErrorResponse) => {
          this.isDataLoading = false;

          if (errorResponse.status === 400) {
            this.alertMessage.showAlertMessage(errorResponse.error, 'Error');
          } else {
            this.alertMessage.showAlertMessage('Error saving ApproveToPay metadata.', 'Error');
          }
        }
      );
  }

  canSaveApproveToPay() {
    if (this.isNewRecord) {
      return !this.approveToPayForm.invalid;
    } else {
      return !this.approveToPayForm.invalid
        && (
          this.editApproveToPay.groupCode !== this.approveToPay.groupCode
          || this.editApproveToPay.invoicePrefix !== this.approveToPay.invoicePrefix
        );
    }
  }
}
