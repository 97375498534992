import * as AppActions from '../../app.actions';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { LaunchDarklyFlagNames } from './launch-darkly.service';
const initialState: LDFlagSet = {
    flags: {
        [LaunchDarklyFlagNames.eventHistory]: false
    }
} as LDFlagSet;

export function featureFlagReducer(
    state: LDFlagSet = initialState,
    action: AppActions.Actions
) {
    switch (action.type) {
        case AppActions.LOAD_FEATURE_FLAGS_SUCCESS: {
            return {
                ...state,
                flags: action.flags
            };
        }
        default:
            return state;
    }
}
