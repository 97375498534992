import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { catchError, map } from 'rxjs/operators';
import {
  CreateAuditStatusNote,
  ActiveAuditResponse,
  UpdateStatusNote,
  SaveAuditStatusNote,
  ActiveCarrierAuditResponse,
  InvoiceAuditRefDataResponse,
  SaveCustomerAuditStatusNote,
  SaveBGAuditStatusNote,
  CreateAuditStatusNoteResponse,
  SendCarrierDisputeEmail,
} from './AuditQueue';
import { CustomerProfile } from '@/models/CustomerProfile';

@Injectable()
export class AuditQueueService {
  shipmentApiHeaders: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202', // -->Add this line
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  public documentQueue: ActiveAuditResponse[];

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeaders = new HttpHeaders({
      //      EnterpriseID: '7',
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
    });
  }

  // queues
  public getAuditReferences(): Observable<InvoiceAuditRefDataResponse> {
    return this.http
      .get<InvoiceAuditRefDataResponse>(`${this.startupService.financeAPIUrl}v2/references/audit-invoices`, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }
  public getDocumentQueue(): Observable<ActiveAuditResponse[]> {
    return this.http
      .get<ActiveAuditResponse[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/documents`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((data) => {
          return data.map((row) => {
            if (row.invoiceDate != null) {
              const date = new Date(row.invoiceDate);
              row.invoiceDateString = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
            }
            row.varianceString = row.variance == null ? '' : '$' + row.variance.toFixed(2);
            return row;
          });
        })
      );
  }
  public getCarrierQueue(carrierQueue: string): Observable<ActiveCarrierAuditResponse[]> {
    return this.http
      .get<ActiveCarrierAuditResponse[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/carriers/${carrierQueue}`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((data) => {
          return data.map((row) => {
            if (row.invoiceDate != null) {
              const date = new Date(row.invoiceDate);
              row.invoiceDateString = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
            }
            row.varianceString = row.variance == null ? '' : '$' + row.variance.toFixed(2);
            return row;
          });
        })
      );
  }
  public getAuditQueue(endpoint: string): Observable<ActiveAuditResponse[]> {
    return this.http
      .get<ActiveAuditResponse[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/${endpoint}`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((data) => {
          return data.map((row) => {
            if (row.invoiceDate != null) {
              const date = new Date(row.invoiceDate);
              row.invoiceDateString = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
            }
            row.varianceString = row.variance == null ? '' : '$' + row.variance.toFixed(2);
            return row;
          });
        })
      );
  }
  public updateDisputeEmails(saveList: SendCarrierDisputeEmail[], updatestatus: boolean) {
    return this.http
      .post(`${this.startupService.financeAPIUrl}v2/audit-invoices/carriers/dispute-email/${updatestatus}`, saveList)
      .pipe(catchError(this.handleError));
  }

  public completeProactiveAndNonProactiveInvoices(invoiceIds: string[]) {
    let urlParameters = '?';
    invoiceIds.forEach((invoiceID, index) => {
      urlParameters = urlParameters.concat('ids=', invoiceID, index !== invoiceIds.length - 1 ? '&' : '');
    });

    return this.http
      .post(`${this.startupService.financeAPIUrl}v2/invoices/carrier/complete${urlParameters}`, null)
      .pipe(catchError(this.handleError));
  }

  public addAuditQueue(auditQueue: CreateAuditStatusNote): Observable<CreateAuditStatusNoteResponse> {
    return this.http
      .post<CreateAuditStatusNoteResponse>(`${this.startupService.financeAPIUrl}v2/audit-invoices`, auditQueue)
      .pipe(catchError(this.handleError));
  }

  public updateAuditStatusNotes(saveList: SaveAuditStatusNote[], endpoint: string) {
    return this.http
      .patch(`${this.startupService.financeAPIUrl}v2/audit-invoices/${endpoint}`, saveList)
      .pipe(catchError(this.handleError));
  }

  public updateBGAuditStatusNotes(saveList: SaveBGAuditStatusNote[]) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/audit-invoices/bgs`, saveList).pipe(catchError(this.handleError));
  }

  public updateCustomerAuditStatusNotes(saveList: SaveCustomerAuditStatusNote[]) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/audit-invoices/customers`, saveList).pipe(catchError(this.handleError));
  }

  public deleteQueueNotes(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.startupService.financeAPIUrl}v2/audit-invoices/${id}`).pipe(catchError(this.handleError));
  }

  public getSpecializedAudit(): Observable<Array<CustomerProfile>> {
    return this.http
      .get<Array<CustomerProfile>>(`${this.startupService.financeAPIUrl}v2/customerprofiles`)
      .pipe(catchError(this.handleError));
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
