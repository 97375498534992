import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvoiceNoteModalComponent } from '../invoice-note-modal/invoice-note-modal.component';
import { Note } from '@/models/Notes';

@Component({
  selector: 'invoice-note',
  templateUrl: './invoice-note.component.html',
})
export class InvoiceNoteComponent {
  @Input() note: Note;
  @Input() canEdit: boolean;
  @Input() shipmentID: number;
  @Output() saveComment = new EventEmitter();

  constructor(private dialogService: DialogService) { }

  editModal(): void {
    let dialogLayout = {
      width: 800,
      height: 300,
      content: InvoiceNoteModalComponent,
    };

    const dialogRef: DialogRef = this.dialogService.open(dialogLayout);
    const noteModal = dialogRef.content.instance;
    noteModal.shipmentID = this.shipmentID;
    noteModal.note = this.note;

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      if (!(r instanceof DialogCloseResult)) this.saveComment.emit();
    });

  }
}
