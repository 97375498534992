import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { catchError, map } from 'rxjs/operators';
import { ShipmentSearchDTO, ShipmentDTO, PostalInfoSearchDTO } from '../services/Shipment';
import { Charge, ShipmentInvoiceAuditDTO, ShipmentInvoiceAuditSaveRateDTO } from '../models/ShipmentInvoiceAudit';
import { ShipmentInvoiceAuditRerateDTO } from '@/models/ShipmentInvoiceAuditRerate';
import { AddressSave, AccessorialSave, ItemSave, ItemSaveResult } from '@/models/ShipmentInvoiceAuditSave';
import { SaveShipmentReference, EnterpriseWithReference } from '@/models/ShipmentReference';
import { ShipmentReference, ShipmentReferencePro } from '@/models/ShipmentReference';
import { RouteAddressDTO, RouteAddressItemDTO } from '@/pages/tl-audit-edit/dto/TLAuditEditDTO';
import { ShipmentRate, UpdateChargesRequest } from '../models/rate'
import { QuoteRequest, QuoteResponse } from '@/models/BgRating';
import { EnterpriseDTO } from './Enterprise';
import { logging } from 'protractor';
import * as v3_models from '@/models/v3/Shipment';

@Injectable()
export class ShipmentService {
  shipmentApiHeaders: HttpHeaders;
  shipmentApiHeadersJson: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  public shipments: ShipmentSearchDTO[];

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) { }

  // zip code search
  search(postalCode: string): Observable<PostalInfoSearchDTO[]> {
    return this.http
      .get<PostalInfoSearchDTO[]>(`${this.startupService.shipmentApiUrl}PostalInfo/search?code=${encodeURIComponent(postalCode)}`)
      .pipe(catchError(this.handleError));
  }

  // find shipment
  public findShipments(primaryValue: string, primaryType: string): Observable<ShipmentSearchDTO[]> {
    // build the url
    let url = `${this.startupService.shipmentApiUrl}v1/shipment/findByReference/?referenceValue=${primaryValue}`;
    if (!!primaryType) {
      url = `${this.startupService.shipmentApiUrl}v1/shipment/findByReference/?referenceValue=${primaryValue}&referenceType=${primaryType}`;
    }
    // make the call
    return this.http
      .get<ShipmentSearchDTO[]>(url, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getFullShipment(shipmentId: number): Observable<v3_models.Shipment> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v3/shipment/${shipmentId}/full`;
    return this.http
      .get<any>(url, {
        headers: this.shipmentApiHeaders
      })
      .pipe(map(data => Object.assign(new v3_models.Shipment, data)));
  }

  public getShipmentQuotes(shipmentId: number, analogousId: string): Observable<v3_models.Quote[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v3/shipment/${shipmentId}/quotes/${analogousId}`;
    return this.http
      .get<v3_models.Quote[]>(url, {
        headers: this.shipmentApiHeaders
      });
  }

  public patchFullShipment(shipmentId: number, patch: any[]): Observable<any> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v3/shipment/${shipmentId}`;
    return this.http
      .patch(url, patch, {
        headers: this.shipmentApiHeaders
      });
  }

  /*
   * Converts one or more rates to a array of quotes.
   */
  public convert(rate: any[]): Observable<any[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v3/shipment/convert`;
    return this.http.post<any[]>(
      url,
      rate,
      { headers: this.shipmentApiHeaders }
    );
  }

  // find shipment
  public getShipmentByID(shipmentID: number): Observable<ShipmentDTO> {
    // make the call
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v1/shipment/${shipmentID}`;
    return this.http
      .get<ShipmentDTO>(url, {
        headers: this.shipmentApiHeaders,
      })
      .pipe(catchError(this.handleError));
  }

  // v2 addresses
  public getShipmentRouteAddresses(shipmentID: number): Observable<RouteAddressDTO[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/RouteAddress`;
    return this.http
      .get<RouteAddressItemDTO>(url, {
        headers: this.shipmentApiHeaders,
      })
      .pipe(
        map((data) => {
          return data.addresses;
        }),
        catchError(this.handleError)
      );
  }

  public saveAccessorialsInvoiceAudit(shipmentID: number, accessorialSave: AccessorialSave): Observable<any[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentAccessorial`;
    if (this.startupService.showDebug) {
      console.log('Save Accessorials JSON: ', JSON.stringify(accessorialSave));
    }
    // send body as json
    return this.http
      .put<any[]>(url, JSON.stringify(accessorialSave), {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public saveShipmentAddressInvoiceAudit(shipmentID: number, id: number, saveOriginAddress: AddressSave): Observable<any[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentAddress/${id}`;
    if (this.startupService.showDebug) {
      console.log('Save Address JSON: ', JSON.stringify(saveOriginAddress));
    }

    // send body as json
    return this.http
      .put<any[]>(url, JSON.stringify(saveOriginAddress), {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }
  public saveShipmentItemsInvoiceAudit(shipmentID: number, saveItems: ItemSave[]): Observable<ItemSaveResult> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentItem`;
    if (this.startupService.showDebug) {
      console.log('Save Items JSON: ', JSON.stringify(saveItems));
    }
    return this.http
      .put<ItemSaveResult>(url, JSON.stringify(saveItems), {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public saveShipmentRate(shipmentId: number, rates: any[]): Observable<any> {
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentId}/ShipmentRate`;
    return this.http.post(url, JSON.stringify(rates), {
      headers: this.shipmentApiHeadersJson
    });
  }

  public saveShipmentRateInvoiceAudit(shipmentID: number, saveRate: any): Observable<ShipmentInvoiceAuditSaveRateDTO> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentRate/auditRate`;
    if (this.startupService.showDebug) {
      console.log('Save New Rate JSON: ', JSON.stringify(saveRate));
    }

    return this.http
      .post<ShipmentInvoiceAuditSaveRateDTO>(url, JSON.stringify(saveRate), {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public reRateShipment(reRateShipment: ShipmentInvoiceAuditRerateDTO): Observable<any[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/Rate`;
    if (this.startupService.showDebug) {
      console.log('Re-Rate JSON: ', JSON.stringify(reRateShipment));
    }

    return this.http
      .post<any[]>(url, JSON.stringify(reRateShipment), {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public getShipmentByIDInvoiceAudit(shipmentID: number): Observable<ShipmentInvoiceAuditDTO> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/shipment/invoiceAudit`;
    return this.http
      .get<ShipmentInvoiceAuditDTO>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }

  public updateShipmentProReference(id: number, shipmentID: number, value: string): Observable<ShipmentReferencePro> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentProNumber`;
    const saveReference = JSON.stringify({
      proNumberID: id,
      proNumber: value,
    });
    if (this.startupService.showDebug) {
      console.log('Update Pro Reference JSON: ', saveReference);
    }
    return this.http
      .put<ShipmentReferencePro>(url, saveReference, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public getShipmentProReferences(shipmentID: number): Observable<ShipmentReferencePro> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentProNumber`;

    return this.http
      .get<ShipmentReferencePro>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public saveShipmentProReference(value: string, shipmentID: number): Observable<ShipmentReferencePro> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentProNumber`;
    const saveReference = JSON.stringify({
      proNumber: value,
    });
    if (this.startupService.showDebug) {
      console.log('Save Pro Reference JSON: ', saveReference);
    }
    return this.http
      .post<ShipmentReferencePro>(url, saveReference, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }
  public removeShipmentProReference(id: number, shipmentID: number): Observable<ShipmentReferencePro> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentProNumber/${id}`;
    if (this.startupService.showDebug) {
      console.log('Remove Pro Reference JSON: ', id);
    }
    return this.http
      .delete<ShipmentReferencePro>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public searchPrimarys(primaryValue: string, daysBack: number): Observable<string[]> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v1/shipment/searchByReference/?referenceValue=${primaryValue}&daysBack=${daysBack}`;

    // make the call
    return this.http
      .get<string[]>(url, {
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getShipmentReferences(shipmentID: number) {
    this.setShipmentAPIHeaders();
    return this.http.get<ShipmentReference>(`${this.startupService.shipmentApiUrl}/v2/${shipmentID}/ShipmentReference`, {
      headers: this.shipmentApiHeadersJson,
    });
  }

  public saveShipmentReferences(shipmentID: number, updatedReferences: SaveShipmentReference[]): Observable<ShipmentReference> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentReference`;
    const saveReferences = JSON.stringify({
      references: updatedReferences,
    });
    if (this.startupService.showDebug) {
      console.log('Save References JSON: ', saveReferences);
    }
    return this.http
      .post<ShipmentReference>(url, saveReferences, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public getShipmentEnterpriseReferences(enterpriseID: number): Observable<EnterpriseWithReference> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${enterpriseID}/Enterprise/references`;
    return this.http
      .get<EnterpriseWithReference>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }

  public getPendingInvoiceID(invoiceID: number): Observable<any> {
    return this.http.get(`${this.startupService.financeAPIUrl}v2/pending-invoices/${invoiceID}/pending-invoices`, { headers: this.shipmentApiHeaders });
  }

  public getPendingInvoice(data): Observable<any> {
    return this.http.get(`${this.startupService.financeAPIUrl}v2/pending-invoices/${data[0].id}`, { headers: this.shipmentApiHeaders });
  }

  public getShipmentRate(shipmentID: number): Observable<ShipmentRate> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentRate/target`;
    return this.http.get<ShipmentRate>(url, {
      headers: this.shipmentApiHeadersJson,
    })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }


  public updateCharges(request: UpdateChargesRequest): Observable<any> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${request.shipmentID}/ShipmentRate/${request.offerID}/charges/${request.entity}`;

    const body = {
      currencyCode: request.currencyCode,
      carrierCharges: null,
      customerCharges: null
    };

    if (request.entity === "carrier") {
      body.carrierCharges = request.charges;
    } else {
      body.customerCharges = request.charges;
    }

    return this.http
      .put(url, JSON.stringify(body), { headers: this.shipmentApiHeadersJson })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }

  public reRateCharge(shipmentID: number, saveRate: any): Observable<ShipmentInvoiceAuditSaveRateDTO> {
    this.setShipmentAPIHeaders();
    const url = `${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentRate/auditRate/charge`;

    return this.http
      .post<ShipmentInvoiceAuditSaveRateDTO>(url, JSON.stringify(saveRate), {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(catchError(this.handleError));
  }

  public getEnterprise(enterpriseID: number): Observable<EnterpriseDTO> {
    const url = `${this.startupService.shipmentApiUrl}v2/${enterpriseID}/enterprise`;
    return this.http
      .get<EnterpriseDTO>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }

  public getUntracked(): Observable<any> {
    return this.http.get<any[]>(`${this.startupService.shipmentApiUrl}v3/shipment/find?origin=INVEDI&tracking=false`, {
      headers: this.shipmentApiHeadersJson
    });
  }

  public saveMixAndMatchRates(shipmentID: number, carrierRateAnalagousID: string, customerRateAnalagousID: string) {
    const args = {
      carrier: carrierRateAnalagousID,
      customer: customerRateAnalagousID
    };
    return this.http.post<any[]>(`${this.startupService.shipmentApiUrl}v2/${shipmentID}/ShipmentRate/mixandmatch`, args, {
      headers: this.shipmentApiHeadersJson
    });
  }

  // helpers
  private setShipmentAPIHeaders() {
    if (!this.shipmentApiHeaders) {
      this.shipmentApiHeaders = new HttpHeaders({
        EnterpriseID:
          this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
            ? this.authService.BlueShipUser.enterpriseID.toString()
            : null,
        UserID:
          this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
        UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.userName : null,
      });
    }
    if (!this.shipmentApiHeadersJson) {
      this.shipmentApiHeadersJson = new HttpHeaders({
        // testing
        // EnterpriseID: '80015',
        EnterpriseID:
          this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
            ? this.authService.BlueShipUser.enterpriseID.toString()
            : null,
        UserID:
          this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
        UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.userName : null,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      });
    }
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }


}
