import { Injectable } from '@angular/core';
import { forkJoin, of, Observable, throwError } from 'rxjs';
import { CarrierService } from '@/services/carrier.service';
import { ShipmentService } from '@/services/shipment.service';
import { EnterpriseService } from '@/services/enterprise.service';
import { map, switchMap } from 'rxjs/operators';
import { Helpers } from '@/_shared/helpers';
import { CurrencyService } from '@/services/currency.service';
import { CarrierInvoiceAuditState } from '../models/carrier-invoice-audit-state.model';
import { AuthService } from '@/auth/auth.service';
import { CreateCarrierInvoice, EditCarrierInvoice } from '../dto/CarrierInvoiceSave';
import { CarrierInvoiceAudit, CarrierInvoiceCharge } from '../models/CarrierInvoiceAudit';
import { CarrierResponse } from '@/models/Carrier';
import { StartupService } from '@/startup.service';
import { ConversionRate } from '@/services/ConversionRate';

@Injectable({
  providedIn: 'root',
})
export class CarrierInvoiceAuditStateService {
  private helpers: Helpers;
  constructor(
    private authService: AuthService,
    private startupService: StartupService,
    private pcFinanceService: CarrierService,
    private pcShipmentService: ShipmentService,
    private pcEnterpriseService: EnterpriseService,
    private pcCurrencyService: CurrencyService
  ) {
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
  }

  loadFinanceAPI(invoiceID: number, shipmentID: number): Observable<CarrierInvoiceAuditState> {
    return new Observable<CarrierInvoiceAuditState>((observer) => {
      const carrierInvoiceAuditState = new CarrierInvoiceAuditState();
      const invoiceRecord = this.pcFinanceService.getInvoiceyID(invoiceID);
      const shipmentRecord = this.pcShipmentService.getShipmentByID(shipmentID);
      const initialFork = forkJoin([invoiceRecord, shipmentRecord]);
      initialFork
        .pipe(
          switchMap((forkResponse) => {
            // cleanup null quantities
            if (forkResponse[0] && forkResponse[0].charges) {
              forkResponse[0].charges.forEach((charge) => (charge.quantity = charge.quantity || 0));
            }
            // return throwError({ error: 'Error Error' });
            const combined$ = this.pcEnterpriseService.getEnterprise(forkResponse[1].enterpriseID);
            if (this.startupService.showCanada) {
              const invoiceDate = new Date(); // forkResponse[0].invoiceDate;
              const currencyResult$ = this.pcCurrencyService.getConversionRate(invoiceDate);
              return forkJoin(of(forkResponse[0]), of(forkResponse[1]), combined$, currencyResult$);
            } else {
              return forkJoin(of(forkResponse[0]), of(forkResponse[1]), combined$);
            }
          })
        )
        .subscribe(
          (data) => {
            carrierInvoiceAuditState.carrierInvoiceAudit = new CarrierInvoiceAudit();
            // set data
            const canadianRate = data[3] && data[3].find((x) => x.country === 'CAN') ? data[3].find((x) => x.country === 'CAN').value : 0;
            carrierInvoiceAuditState.editCurrencyCode = data[2] && data[2].enterprise ? data[2].enterprise.currencyCode : 'USD';
            carrierInvoiceAuditState.carrierInvoiceAudit.setFinanceCarrierInvoices(data[0], data[1], canadianRate);
            carrierInvoiceAuditState.carrierInvoiceAudit.setEnterpriseCountry(
              data[2].enterprise.countryName,
              data[2].enterprise.currencyCode
            );
            carrierInvoiceAuditState.isInternational = this.startupService.showCanada && data[2].enterprise.currencyCode === 'CAD';
            carrierInvoiceAuditState.invoiceTotal = data[0] ? data[0].invoiceTotal : 0;
            carrierInvoiceAuditState.invoiceTotalInUSD = data[0] && data[0].normalizedTotal ? data[0].normalizedTotal : 0;

            carrierInvoiceAuditState.quoteTotal = data[1].selectedRate ? data[1].selectedRate.carrierTotal : 0;
            carrierInvoiceAuditState.quoteTotalInUSD = data[1].selectedRate ? data[1].selectedRate.carrierTotalInUSD : 0;

            observer.next(carrierInvoiceAuditState);
            observer.complete();
          },
          (error) => {
            console.log('error triggered');
            observer.error(error);
          }
        );
    });
  }
  loadShipmentAPI(shipmentID: number) {
    return new Observable<CarrierInvoiceAuditState>((observer) => {
      const carrierInvoiceAuditState = new CarrierInvoiceAuditState();
      const records = this.pcShipmentService.getShipmentByID(shipmentID);
      // call them
      const combined = forkJoin([records]);
      combined
        .pipe(
          switchMap((forkResponse) => {
            const combined$ = this.pcEnterpriseService.getEnterprise(forkResponse[0].enterpriseID);

            if (this.startupService.showCanada) {
              const currencyResult$ = this.pcCurrencyService.getConversionRate(new Date());
              return forkJoin(of(forkResponse[0]), combined$, currencyResult$);
            } else {
              return forkJoin(of(forkResponse[0]), combined$);
            }
          })
        )
        .subscribe(
          (data) => {
            carrierInvoiceAuditState.carrierInvoiceAudit = new CarrierInvoiceAudit();
            // set data
            const canadianRate = data[2] && data[2].find((x) => x.country === 'CAN') ? data[2].find((x) => x.country === 'CAN').value : 0;
            carrierInvoiceAuditState.carrierInvoiceAudit.setShipmentCarrierInvoices(data[0], this.helpers, canadianRate);
            carrierInvoiceAuditState.carrierInvoiceAudit.setEnterpriseCountry(
              data[1].enterprise.countryName,
              data[1].enterprise.currencyCode
            );
            carrierInvoiceAuditState.isInternational = this.startupService.showCanada && data[1].enterprise.currencyCode === 'CAD';
            carrierInvoiceAuditState.invoiceTotal = data[0].selectedRate ? data[0].selectedRate.carrierTotal : 0;
            carrierInvoiceAuditState.invoiceTotalInUSD = data[0].selectedRate ? data[0].selectedRate.carrierTotalInUSD : 0;
            carrierInvoiceAuditState.quoteTotal = data[0].selectedRate ? data[0].selectedRate.carrierTotal : 0;
            carrierInvoiceAuditState.quoteTotalInUSD = data[0].selectedRate ? data[0].selectedRate.carrierTotalInUSD : 0;
            observer.next(carrierInvoiceAuditState);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
  saveInvoice(
    carrierInvoice: CarrierInvoiceAudit,
    serviceTotal: number,
    freightTotal: number,
    charges: CarrierInvoiceCharge[]
  ): Observable<any> {
    return new Observable<any>((observer) => {
      if (carrierInvoice.invoiceID !== 0) {
        const theRecord = this.updateCarrierInvoice(carrierInvoice, serviceTotal, freightTotal, charges);
        this.pcFinanceService.updateCarrierInvoice(carrierInvoice.invoiceID, theRecord).subscribe(
          (data) => {
            observer.next(data);
          },
          (error) => {
            observer.error(error);
          }
        );
        // debugging
        // return observer.error({ error: 'Error Error' });
        // observer.next(carrierInvoice);
        // observer.complete();
      } else {
        const theRecord = this.createCarrierInvoice(carrierInvoice, serviceTotal, freightTotal, charges);
        this.pcFinanceService.createCarrierInvoice(theRecord).subscribe(
          (data) => {
            observer.next(data);
          },
          (error) => {
            observer.error(error);
          }
        );
      }
    });
  }

  loadLTLCarriers(scac: string, value: string): Observable<CarrierResponse[]> {
    return new Observable<CarrierResponse[]>((observer) => {
      if (scac) {
        this.pcFinanceService.getLtlSCACCarriers(scac).subscribe(
          (data) => {
            observer.next(data);
          },
          (error) => {
            observer.error(error);
          }
        );
      } else {
        this.pcFinanceService.getLtlCarriers(value).subscribe(
          (data) => {
            observer.next(data);
          },
          (error) => {
            observer.error(error);
          }
        );
      }
    });
  }

  loadTLCarriers(isDOTSearch: boolean, value: string): Observable<CarrierResponse[]> {
    return new Observable<CarrierResponse[]>((observer) => {
      if (isDOTSearch) {
        this.pcFinanceService.getTlCarriersByIdentifier(value).subscribe(
          (data) => {
            observer.next(data);
          },
          (error) => {
            observer.error(error);
          }
        );
      } else {
        this.pcFinanceService.getTlCarriersByName(value).subscribe(
          (data) => {
            observer.next(data);
          },
          (error) => {
            observer.error(error);
          }
        );
      }
    });
  }

  private createCarrierInvoice(
    carrierInvoice: CarrierInvoiceAudit,
    serviceTotal: number,
    freightTotal: number,
    charges: CarrierInvoiceCharge[]
  ): CreateCarrierInvoice {
    const theRecord = new CreateCarrierInvoice(
      carrierInvoice,
      carrierInvoice.invoiceDate,
      carrierInvoice.invoiceNumber,
      carrierInvoice.weight,
      carrierInvoice.paymentMethod,
      serviceTotal,
      freightTotal,
      charges
    );
    return theRecord;
  }
  private updateCarrierInvoice(
    carrierInvoice: CarrierInvoiceAudit,
    serviceTotal: number,
    freightTotal: number,
    charges: CarrierInvoiceCharge[]
  ): EditCarrierInvoice {
    const theRecord = new EditCarrierInvoice(
      carrierInvoice,
      carrierInvoice.invoiceDate,
      carrierInvoice.invoiceNumber,
      carrierInvoice.weight,
      carrierInvoice.paymentMethod,
      serviceTotal,
      freightTotal,
      charges
    );
    return theRecord;
  }
}
