import { InvoiceService } from '@/services/invoice.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { ConfirmationDialogService } from '@/_shared/confirmation-dialog/confirmation-dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConvertJSONToCSVHelper, FormatDataExportExcel, FormDuplicateCarrierCtrls, IDeleteDuplicateCarrier, IDuplicateCarrier, IExportDuplicate, IResetDuplicateCarrier } from './duplicate-carrier.interface';
@Component({
  selector: 'app-duplicate-carrier',
  templateUrl: './duplicate-carrier.component.html',
  styleUrls: ['./duplicate-carrier.component.scss']
})
export class DuplicateCarrierComponent implements OnInit {

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  formGrup: FormGroup;
  ctrlFrm = FormDuplicateCarrierCtrls;

  day = new Date().getDate();
  month = new Date().getMonth();
  year = new Date().getFullYear();

  todayDateStart = new Date(this.year, this.month, this.day);
  todayDateEnd = new Date(this.year, this.month, this.day);
  today = new Date(this.year, this.month, this.day);

  loading: boolean = false;
  dataTable: IDuplicateCarrier[] = [];
  convertJson: string = '';
  selectCarrier: number[] = [];


  constructor(private formBuild: FormBuilder,
    private invoiceService: InvoiceService,
    private confimation: ConfirmationDialogService
  ) { }

  ngOnInit(): void {
    this.initializeFrm();
    this.searchDataTables();
  }

  initializeFrm(): void {
    this.formGrup = this.formBuild.group({
      [this.ctrlFrm.startDate]: [
        new Date(this.todayDateStart.setDate(this.todayDateStart.getDate() - 30)), [Validators.required]],
      [this.ctrlFrm.endDate]: [this.todayDateEnd, [Validators.required]]
    });
  }

  searchDataTables(): void {
    this.loading = true;
    let dataExport: IExportDuplicate[] = [];
    this.invoiceService.getDuplicateCarrier(this.formGrup.value).subscribe((data) => {
      this.dataTable = data;

      if (data.length > 0) {
        dataExport = FormatDataExportExcel.formt(data);
        this.convertJson = ConvertJSONToCSVHelper(dataExport);
      }
      else
        this.convertJson = '';

      this.loading = false;
    });
  }

  onChangeDateStart(value: Date): void {
    if (this.formGrup.value.endDate < value)
      this.formGrup.controls[this.ctrlFrm.endDate].setValue(null);
  }

  onChangeDateEnd(value: Date): void {
    if (this.formGrup.value.startDate > value)
      this.formGrup.controls[this.ctrlFrm.startDate].setValue(null);
  }

  resetCarrier(id: number): void {
    let dataReset: IResetDuplicateCarrier[] = [
      {
        pendingInvoiceID: id,
        skipDuplicateCheck: true
      }
    ]

    this.invoiceService.resetDuplicateCarrier(dataReset)
      .subscribe(() => {
        this.alertMessage.showAlertMessage('Invoice has been Reset.', 'Success');
        this.searchDataTables();
      }, () => this.alertMessage.showAlertMessage('Error resetting invoice', 'Error'));
  }

  async deleteCarrier(id?: number): Promise<void> {
    let idDelete: IDeleteDuplicateCarrier = {
      pendingInvoiceIDs: id ? [id] : this.selectCarrier
    };

    let promiseConfirmation = await this.confimation.confirm('Confirm', 'Are you sure you want to delete this invoice?').catch(() => { });

    if (promiseConfirmation) {
      this.invoiceService.deleteDuplicateCarrier(idDelete).subscribe(() => {
        this.alertMessage.showAlertMessage('Successfully Deleted Duplicate Invoice.', 'Success');
        this.searchDataTables();
      }, () => this.alertMessage.showAlertMessage('Error deleting Duplicate Carrier', 'Error'))
    }
  }

  downloadExportData(csvPreppedData: string): void {
    if (csvPreppedData && csvPreppedData.length > 0) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + escape(csvPreppedData));
      const fileName = 'Export-All-Invoice-Report-' + new Date().getDate();
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
