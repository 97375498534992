import { Component, OnInit, ViewChild } from '@angular/core';
import { ProactiveService } from '../../services/proactive.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ProactiveResponse, ReleaseCustomerInvoice, DisputeCustomerInvoice, RevertCustomerInvoice, RevertActionResult } from '../../models/Proactive';
import { ProactiveHoldListComponent } from '../../components/proactive-hold-list/proactive-hold-list.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-proactive-hold',
  templateUrl: './proactive-hold.component.html',
  styleUrls: ['./proactive-hold.component.scss'],
})
export class ProactiveHoldComponent implements OnInit {
  recordBook: ProactiveResponse[] = [];
  recordCount = 0;
  isDataLoading = true;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild('proactiveHoldGrid', { static: false })
  proactiveGrid: ProactiveHoldListComponent;

  constructor(private router: Router, private authService: AuthService, private pcProactiveService: ProactiveService) { }

  ngOnInit() {
    this.loadRecords();
  }

  // events
  onDisputeButtonEvent(recordList: number[]) {
    const recordArray: DisputeCustomerInvoice[] = [];
    for (let index = 0; index < recordList.length; index++) {
      const record = this.recordBook.filter((s) => s.id === recordList[index]);
      const disputeInvoice = {
        id: record[0].id,
        enterpriseID: record[0].enterpriseID,
        enterpriseAccountNumber: record[0].ownerAccount,
      } as DisputeCustomerInvoice;
      recordArray.push(disputeInvoice);
    }

    // remove it from the server
    this.setDispute(recordArray, recordList);
  }

  onReleaseButtonEvent(recordList: number[]) {
    const recordArray: ReleaseCustomerInvoice[] = [];
    for (let index = 0; index < recordList.length; index++) {
      const record = this.recordBook.filter((s) => s.id === recordList[index]);
      const releaseInvoice = {
        id: record[0].id,
        enterpriseID: record[0].enterpriseID,
        enterpriseAccountNumber: record[0].ownerAccount,
      } as ReleaseCustomerInvoice;
      recordArray.push(releaseInvoice);
    }

    // remove it from the server
    this.setRelease(recordArray, recordList);
  }

  onRevertButtonEvent(recordList: number[]) {
    const recordArray = this.recordBook
      .filter(s => recordList.includes(s.id))
      .map(s => ({
        id: s.id,
        enterpriseID: s.enterpriseID,
        enterpriseAccountNumber: s.ownerAccount,
      }) as ReleaseCustomerInvoice);

    // revert them at the server
    this.setRevert(recordArray, recordList);
  }

  // helpers
  private loadRecords() {
    this.pcProactiveService
      .getProactiveHold()
      .pipe(
        map((data) => {
          return data.map((row) => {
            row.sent = row.invoiceStatus !== 'ProactiveHold' ? true : false;
            row.id = row.invoiceID;
            return row;
          });
        })
      )
      .subscribe(
        (responseList) => {
          this.recordBook = responseList;
          this.recordCount = responseList.length;
          this.isDataLoading = false;
        },
        (error) => {
          this.alertMessage.showAlertMessage('Error Loading Proactive Holds.', 'Error');
          this.isDataLoading = false;
        }
      );
  }

  // service calls
  setRelease(releases: ReleaseCustomerInvoice[], recordList: number[]) {
    this.appSpinner.loading = true;
    this.pcProactiveService.releaseProactiveHold(releases).subscribe(
      (data) => {
        // remove them from the grid
        this.proactiveGrid.removeRecords(recordList);
        this.alertMessage.showAlertMessage('Successfully released records', 'Success');
        this.appSpinner.loading = false;
        this.recordCount--;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error releasing records', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }

  setDispute(disputes: DisputeCustomerInvoice[], recordList: number[]) {
    this.appSpinner.loading = true;
    this.pcProactiveService.disputeProactiveHold(disputes).subscribe(
      (data) => {
        // remove them from the grid
        this.proactiveGrid.removeRecords(recordList);
        this.alertMessage.showAlertMessage('Successfully disputed records', 'Success');
        this.recordCount--;
        this.appSpinner.loading = false;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error disputing records', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }

  setRevert(invoices: RevertCustomerInvoice[], recordList: number[]) {
    this.appSpinner.loading = true;
    this.pcProactiveService.revertProactiveHold(invoices).subscribe(
      (data: RevertActionResult) => {
        // remove them from the grid
        const revertedRecords = recordList.filter(r => data.succeeded.includes(r));
        this.proactiveGrid.removeRecords(revertedRecords);
        if (data.failed.length) {
          this.alertMessage.showAlertMessage(`Error reverting ${data.failed.length} of ${invoices.length} record(s)`, 'Error');
        } else {
          this.alertMessage.showAlertMessage('Successfully reverted records', 'Success');
        }
        this.recordCount -= revertedRecords.length;
        this.appSpinner.loading = false;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error reverting records', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }
}
