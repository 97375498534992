import { QuoteDetail, QuoteHistory, ShipmentQuoteHistoryResponse } from '@/models/ShipmentQuoteHistoryResponse';
import { ShipmentHistoryService } from '@/services/shipment-history.service';
import { ShipmentService } from '@/services/shipment.service';
import { Component, Input, OnInit } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mix-and-match',
  templateUrl: './mix-and-match.component.html',
  styleUrls: ['./mix-and-match.component.scss']
})
export class MixAndMatchComponent implements OnInit {
  @Input()
  shipmentID: number;
  shipmentQuoteHistory: ShipmentQuoteHistoryResponse;
  quotes: QuoteHistory[] = [];
  quote1Selected: QuoteHistory;
  quote2Selected: QuoteHistory;

  carrierRateAnalagousID: string;
  customerRateAnalagousID: string;
  canSave: boolean;

  constructor(public dialog: DialogRef, private shipmentHistoryService: ShipmentHistoryService, private shipmentService: ShipmentService) { }

  ngOnInit() {
    this.getQuoteHistory();
  }

  public getQuoteHistory(): void {
    this.shipmentHistoryService.getQuoteHistory(this.shipmentID).subscribe((shipmentQuoteHistoryResponse) => {
      this.shipmentQuoteHistory = shipmentQuoteHistoryResponse;
      this.quotes = this.shipmentQuoteHistory.quotes;
      const quoteSelected = this.quotes.filter((quote) => quote.isSelected)[0];

      if (quoteSelected) {
        const quoteSelectedID = quoteSelected.analogousID;
        this.selectCarrier(quoteSelectedID);
        this.selectCustomer(quoteSelectedID);
      }
    });
  }

  selectCarrier(quoteID: string) {
    if (!quoteID || quoteID.length === 0) {
      return;
    }
    this.quote1Selected = this.quotes.filter((quote) => quote.analogousID === quoteID)[0];
    if (this.quote1Selected) {
      this.carrierRateAnalagousID = this.quote1Selected.analogousID;
    }
  }

  selectCustomer(quoteID: string) {
    if (!quoteID || quoteID.length === 0) {
      return;
    }
    this.quote2Selected = this.quotes.filter((quote) => quote.analogousID === quoteID)[0];
    if (this.quote2Selected) {
      this.customerRateAnalagousID = this.quote2Selected.analogousID;
    }
  }

  saveDisabled() {
    const carrier = this.quotes.find(x => x.analogousID === this.carrierRateAnalagousID);
    const customer = this.quotes.find(x => x.analogousID === this.customerRateAnalagousID);
    if (carrier && customer) {
      if (carrier.scac !== customer.scac) {
        this.canSave = false;
        return true;
      }
      if (carrier.isSelected && customer.isSelected) {
        this.canSave = false;
        return true;
      }
      this.canSave = true;
      return false;
    }
  }

  saveNewRate() {
    if (this.canSave !== true) return;
    this.shipmentService.saveMixAndMatchRates(this.shipmentID, this.carrierRateAnalagousID, this.customerRateAnalagousID)
      .subscribe(x =>
        
        this.dialog.close(x)
      );
  }

  closeDialog() {
    this.dialog.close();
  }
}
