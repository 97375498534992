import { Component, OnInit, Input } from '@angular/core';
import { CarrierInvoiceItem, PendingCarrierInvoice } from '@/services/Invoice';
import { InvoiceService } from '@/services/invoice.service';

@Component({
  selector: 'app-unmatched-details',
  templateUrl: './unmatched-details.component.html',
  styleUrls: ['./unmatched-details.component.scss']
})
export class UnmatchedDetailsComponent implements OnInit {

  @Input() data: PendingCarrierInvoice;

  items: CarrierInvoiceItem[];

  constructor(private invoiceService: InvoiceService) { }

  ngOnInit() {
    this.invoiceService
      .getCarrierInvoiceItems(this.data.id)
      .subscribe(x => {
        this.items = x;
      });
  }
}
