import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as CarrierInvoiceAuditActions from '../actions/carrier-invoice-audit.actions';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { CarrierInvoiceAuditStateService } from '../services/carrier-invoice-audit-state.service';

@Injectable()
export class CarrierInvoiceAuditEffects {
  @Effect()
  loadCarrierInvoiceEdit$ = this.actions$.pipe(
    ofType(CarrierInvoiceAuditActions.LOAD_CARRIER_INVOICE_EDIT),
    mergeMap((x: CarrierInvoiceAuditActions.LoadCarrierInvoiceEdit) =>
      this.invoiceAuditStateService.loadFinanceAPI(x.invoiceID, x.shipmentID).pipe(
        map((initialState) => {
          return new CarrierInvoiceAuditActions.LoadCarrierInvoiceSuccess(initialState);
        }),
        catchError((err) => {
          return of(new CarrierInvoiceAuditActions.LoadCarrierInvoiceError(err.error));
        })
      )
    )
  );

  @Effect()
  loadCarrierInvoiceAdd$ = this.actions$.pipe(
    ofType(CarrierInvoiceAuditActions.LOAD_CARRIER_INVOICE_ADD),
    mergeMap((x: CarrierInvoiceAuditActions.LoadCarrierInvoiceAdd) =>
      this.invoiceAuditStateService.loadShipmentAPI(x.shipmentID).pipe(
        map((initialState) => {
          return new CarrierInvoiceAuditActions.LoadCarrierInvoiceSuccess(initialState);
        }),
        catchError((err) => {
          return of(new CarrierInvoiceAuditActions.LoadCarrierInvoiceError(err.error));
        })
      )
    )
  );

  @Effect()
  carrierInvoiceSave$ = this.actions$.pipe(
    ofType(CarrierInvoiceAuditActions.CARRIER_INVOICE_SAVE),
    mergeMap((x: CarrierInvoiceAuditActions.CarrierInvoiceSave) =>
      this.invoiceAuditStateService.saveInvoice(x.carrierInvoice, x.serviceTotal, x.freightTotal, x.charges).pipe(
        map((updatedState) => {
          return new CarrierInvoiceAuditActions.CarrierInvoiceSaveSuccess(updatedState);
        }),
        catchError((err) => {
          return of(new CarrierInvoiceAuditActions.CarrierInvoiceSaveError(err.error));
        })
      )
    )
  );

  // carrier

  @Effect()
  CarrierLTLLoad$ = this.actions$.pipe(
    ofType(CarrierInvoiceAuditActions.CARRIER_LTL_LOAD),
    mergeMap((x: CarrierInvoiceAuditActions.CarrierLTLLoad) =>
      this.invoiceAuditStateService.loadLTLCarriers(x.scac, x.value).pipe(
        map((initialState) => {
          return new CarrierInvoiceAuditActions.CarrierLTLLoadSuccess(initialState);
        }),
        catchError((err) => {
          return of(new CarrierInvoiceAuditActions.CarrierLoadError(err.error));
        })
      )
    )
  );

  @Effect()
  CarrierTLSearch$ = this.actions$.pipe(
    ofType(CarrierInvoiceAuditActions.CARRIER_TL_SEARCH),
    mergeMap((x: CarrierInvoiceAuditActions.CarrierTLSearch) =>
      this.invoiceAuditStateService.loadTLCarriers(x.isDotSearch, x.value).pipe(
        map((initialState) => {
          return new CarrierInvoiceAuditActions.CarrierTLSearchSuccess(initialState);
        }),
        catchError((err) => {
          return of(new CarrierInvoiceAuditActions.CarrierLoadError(err.error));
        })
      )
    )
  );

  constructor(private actions$: Actions, private invoiceAuditStateService: CarrierInvoiceAuditStateService) {}
}
