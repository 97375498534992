import { AuthService } from '@/auth/auth.service';
import { IComment, INote, Notes, NotesDTO } from '@/models/Notes';
import { StartupService } from '@/startup.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()

export class NotesInvoiceService {
  constructor(
    private startupService: StartupService,
    private http: HttpClient,
    private authService: AuthService) { }

  public httpOptions = {
    headers: new HttpHeaders({
      UserName: this.authService.BlueShipUser.userName,
      EnterpriseID: this.authService.BlueShipUser.enterpriseID.toString(),
      UserID: this.authService.BlueShipUser.userID.toString()
    }),
  };

  getNotes(shipmentId: number): Observable<NotesDTO> {
    return this.http.get<NotesDTO>(`${this.startupService.shipmentApiUrl}v2/${shipmentId}/ShipmentNote`, {
      headers: this.httpOptions.headers
    });
  }

  saveNote(shipmentId: number, note: INote): Observable<Notes> {
    return this.http.post<Notes>(`${this.startupService.shipmentApiUrl}v2/${shipmentId}/ShipmentNote`, note, {
      headers: this.httpOptions.headers
    })
  }

  addNoteComment(shipmentId: number, noteId: number, comment: IComment): Observable<Notes> {
    return this.http.put<Notes>(`${this.startupService.shipmentApiUrl}v2/${shipmentId}/ShipmentNote/${noteId}`, comment, {
      headers: this.httpOptions.headers
    })
  }
}
