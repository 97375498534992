import { ShipmentDTO, AddressDTO, SelectedRate, Item, BillingAddressDTO } from 'src/app/services/Shipment';
import { AuditNoteDetailResponse, ShipmentInvoiceNoDetailResponse } from 'src/app/services/InvoiceAudit';
import { Globals } from '../../_shared/globals';
import { CustomerProfile } from '../../models/CustomerProfile';

export namespace ShipmentInvoiceAuditUI {
  export class Shipment {
    shipmentID: number;
    invoiceID: number;
    enterpriseID: number;
    bol: string;
    enterpriseName: string;
    accountNumber: string;
    proActive: boolean;
    status: string;
    mode: string;
    weightUOM: string;
    originalPlannedWeightUOM: string;
    dateCreated: Date = null;
    isSelectedRate: boolean;
    billTo: BillingAddress;
    origin: Address;
    destination: Address;
    references: Reference[];
    carrierQuote: CarrierQuote;
    carrierInvoices: CarrierInvoice[] = [];
    auditNotes: AuditNote[] = null;
    customerProfile: CustomerProfile = null;
    displayREMSWarningMessage: boolean = false;
    displayREMSWarningMessageRevert: boolean = false;
    currencyCode?: string;
    customerTotal: number = 0;

    constructor(shipmentDTO: ShipmentDTO, invoiceID: number, enterprise: string, proActive: boolean) {
      this.shipmentID = shipmentDTO.id;
      this.invoiceID = invoiceID;
      this.bol = shipmentDTO.primaryReference;
      this.references = shipmentDTO.references;
      this.status = shipmentDTO.status;
      this.mode = shipmentDTO.mode;
      this.isSelectedRate = shipmentDTO.selectedRate != null;
      this.billTo = new BillingAddress(shipmentDTO.billingAddress);
      this.carrierQuote = new CarrierQuote(shipmentDTO.selectedRate, shipmentDTO.distance, shipmentDTO.items);
      this.enterpriseID = shipmentDTO.enterpriseID;
      this.currencyCode = shipmentDTO.selectedRate.currencyCode;

      // set the weights
      this.weightUOM = 'lbs'; // hard coded because we convert everything to lbs
      this.originalPlannedWeightUOM = 'lbs';

      if (!isNaN(Date.parse(shipmentDTO.dateCreated))) {
        this.dateCreated = new Date(shipmentDTO.dateCreated);
      }

      if (shipmentDTO.addresses != null && shipmentDTO.addresses.length > 1) {
        this.origin = new Address(shipmentDTO.addresses[0], shipmentDTO.actualPickupDate);
        this.destination = new Address(shipmentDTO.addresses[1], shipmentDTO.actualDeliveryDate);
      }

      this.enterpriseName = enterprise;
      this.accountNumber = shipmentDTO.accountNumber;
      this.proActive = proActive;
      this.customerTotal = shipmentDTO.selectedRate.customerTotal;
    }

    public setCustomerProfile(customerProfile: CustomerProfile) {
      this.customerProfile = customerProfile;
    }

    public getPlannedWeight(items: Item[]) {
      let total = 0;
      for (const item of items) {
        total += item.originalPlannedWeight;
      }
      return total;
    }

    // function needed because this get updated separately from the entire dataset
    public setCarrierInvoices(shipmentInvoiceNoDetailResponse: ShipmentInvoiceNoDetailResponse[]) {
      // filter specific invoices
      this.carrierInvoices = [];
      const invoices = shipmentInvoiceNoDetailResponse.filter(function (el) {
        return el.type === 'Carrier';
      });

      // create the invoices
      for (const invoice of invoices) {
        this.carrierInvoices.push(new CarrierInvoice(invoice));
      }

      if (this.carrierInvoices.length >= 1) {
        const record = this.carrierInvoices.filter((item) => item.invoiceId === this.invoiceID)[0];
        this.validateToDisplayREMSWarnMessage(record, shipmentInvoiceNoDetailResponse);
        this.revertToDisplayREMSWarnMessage(record, shipmentInvoiceNoDetailResponse);
      }

      // if there is more than one carrier invoice get the one that matches the invoiceID sent in
      if (this.carrierInvoices.length > 1) {
        // get the correct record
        const record = this.carrierInvoices.filter((item) => item.invoiceId === this.invoiceID)[0];

        // remove it from the list
        this.carrierInvoices = this.carrierInvoices.filter((item) => item.invoiceId !== this.invoiceID);

        // add this record back to list
        this.carrierInvoices.unshift(record);
      }
    }

    private validateToDisplayREMSWarnMessage(
      selectedCarrierInvoice: CarrierInvoice,
      shipmentInvoiceNoDetailResponse: ShipmentInvoiceNoDetailResponse[]
    ) {
      const customerInvoiceByAnalogousID = shipmentInvoiceNoDetailResponse.find(
        (invoice) => invoice.analogousID == selectedCarrierInvoice.invoiceId && invoice.status == 'Approved' && invoice.type == 'Customer'
      );

      if (customerInvoiceByAnalogousID) this.displayREMSWarningMessage = true;
    }

    private revertToDisplayREMSWarnMessage(
      selectedCarrierInvoice: CarrierInvoice,
      shipmentInvoiceNoDetailResponse: ShipmentInvoiceNoDetailResponse[]
    ): void {
      this.displayREMSWarningMessageRevert = shipmentInvoiceNoDetailResponse.every((invoice) =>
        invoice.analogousID != 0 ? invoice.analogousID != selectedCarrierInvoice.invoiceId : false
      );
    }

    // function needed because this get updated separately from the entire dataset
    public setAuditNotes(auditNoteDetailResponseList: AuditNoteDetailResponse[]) {
      this.auditNotes = mapAuditNotes(auditNoteDetailResponseList, true);
    }
  }

  export class Reference {
    type: string;
    value: string;
  }

  export class Address {
    companyName: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    actualDate: Date;
    countryCode: string;
    internationalGoverningDistrict: string;

    url() {
      const fullAddress = `${this.addressLine1} ${this.city} ${this.stateProvince} ${this.postalCode}`;
      return 'https://www.google.com/search?q=' + encodeURIComponent(fullAddress);
    }

    constructor(address: AddressDTO, actualDate: string) {
      this.companyName = address.companyName;
      this.name = address.contactName;
      this.addressLine1 = address.addressLine1;
      this.addressLine2 = address.addressLine2;
      this.stateProvince = address.stateProvince;
      this.city = address.city;
      this.countryCode = address.countryCode;
      this.postalCode = address.postalCode;
      this.internationalGoverningDistrict = address.internationalGoverningDistrict;
      this.actualDate = null;
      if (!isNaN(Date.parse(actualDate))) {
        this.actualDate = new Date(actualDate);
      }
    }
  }

  export class BillingAddress {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateProvince: string;
    postalCode: string;

    constructor(address: BillingAddressDTO) {
      this.name = address.companyName;
      this.addressLine1 = address.addressLine1;
      this.addressLine2 = address.addressLine2;
      this.city = address.city;
      this.stateProvince = address.stateProvince;
      this.postalCode = address.postalCode;
    }
  }

  export class CarrierInvoice {
    invoiceId: number;
    invoiceNumber: string;
    invoiceDate: Date;
    status: string;
    scac: string;
    mcNumber: string;
    dotNumber: string;
    carrierCode: string;
    mode: string;
    weight = 0;
    isMin: boolean;
    isMax: boolean;
    isBBill: boolean;
    carrierName: string;
    isSecondary: boolean;
    auditor: string;
    distance: number;
    isOpen = false;
    subTotal = 0;
    grandTotal = 0;
    chargeType: string;
    lineHaulCharges: Charge[] = null;
    accessorialCharges: Charge[] = null;
    currencyCode?: string;

    constructor(shipmentInvoiceNoDetailResponse: ShipmentInvoiceNoDetailResponse) {
      this.invoiceId = shipmentInvoiceNoDetailResponse.invoiceID;
      this.invoiceNumber = shipmentInvoiceNoDetailResponse.invoiceNumber;
      this.invoiceDate = shipmentInvoiceNoDetailResponse.invoiceDate;
      this.status = shipmentInvoiceNoDetailResponse.status;
      this.distance = shipmentInvoiceNoDetailResponse.distance;
      this.scac = shipmentInvoiceNoDetailResponse.scac;
      this.mcNumber = shipmentInvoiceNoDetailResponse.mcNumber;
      this.dotNumber = shipmentInvoiceNoDetailResponse.dotNumber;
      this.carrierCode = shipmentInvoiceNoDetailResponse.carrierCode;
      this.mode = shipmentInvoiceNoDetailResponse.mode;
      this.isBBill = shipmentInvoiceNoDetailResponse.isBBill;
      this.carrierName = shipmentInvoiceNoDetailResponse.carrierName;
      this.isSecondary = shipmentInvoiceNoDetailResponse.isSecondary;
      this.auditor = shipmentInvoiceNoDetailResponse.auditor;
      this.currencyCode = shipmentInvoiceNoDetailResponse.currencyCode;
      const charges = shipmentInvoiceNoDetailResponse.charges.map(
        ({ description, freightClass, rate, rateQualifier, quantity, weight, isMin, isMax, amount: total, type, group = '', ediCode, error = false }) => ({
          description,
          freightClass,
          rate,
          rateQualifier,
          quantity,
          weight,
          isMin,
          isMax,
          total,
          type,
          group,
          ediCode,
          error,
        })
      );

      // not ideal, but map would not work with the filter inline
      for (const charge of charges) {
        charge.group = Globals.ChargeTypes.indexOf(charge.type) === -1 ? 'Accessorial' : 'Linehaul';
      }

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === 'Linehaul';
        });
        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });

        // sort them and set the totals
        this.subTotal = 0;
        if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
          this.lineHaulCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.subTotal = +this.lineHaulCharges.map(this.itemTotal).reduce(this.sum).toFixed(2);
        }
        this.grandTotal = this.subTotal;
        if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
          this.accessorialCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
          this.grandTotal += this.accessorialCharges.map(this.itemTotal).reduce(this.sum);
          this.grandTotal = +this.grandTotal.toFixed(2);
        }
        this.weight = this.getTotalWeight(this.lineHaulCharges);
      }

      // set the weight.  If the items dont have it use the root weight
      if (this.weight === 0) {
        this.weight = shipmentInvoiceNoDetailResponse.invoiceWeight;
      }
    }
    private getTotalWeight(charges: Charge[]): number {
      let total = 0;
      for (const item of charges) {
        if (item.type === 'ITEM') {
          total += item.weight;
        }
      }
      return total;
    }

    private itemTotal(item) {
      return item.total;
    }
    private sum(prev, next) {
      return prev + next;
    }
  }

  export class CarrierQuote {
    id: string;
    scac: string;
    mcNumber: string;
    dotNumber: string;
    carrierCode: string;
    carrierName: string;
    total: number;
    isTotalError: boolean;
    priceSheetID: string;
    contractID: string;
    contractName: string;
    distance: number;
    mode: string;
    weight = 0;
    isMin: boolean;
    isMax: boolean;
    originalPlannedWeight = 0;
    subTotal = 0;
    grandTotal = 0;
    chargeType: string;
    lineHaulCharges: Charge[] = null;
    accessorialCharges: Charge[] = null;
    currencyCode?: string;
    carrierAccountNumber?: string;

    constructor(selectedRate: SelectedRate, distance: number, items: Item[]) {
      this.isTotalError = false;
      this.distance = distance;
      if (selectedRate == null) {
        return;
      }
      this.scac = selectedRate.scac;
      this.dotNumber = selectedRate.dotNumber;
      this.carrierCode = selectedRate.carrierCode;
      this.mcNumber = selectedRate.mcNumber;
      this.carrierName = selectedRate.carrierName;
      this.mode = selectedRate.mode;
      this.id = selectedRate.id;
      this.priceSheetID = selectedRate.priceSheetID;
      this.contractID = selectedRate.contractID;
      this.contractName = selectedRate.contractName;
      this.currencyCode = selectedRate.currencyCode;
      this.carrierAccountNumber = selectedRate.carrierAccountNumber;

      const charges =
        selectedRate && Array.isArray(selectedRate.carrierCharges)
          ? selectedRate.carrierCharges.map(
            ({
              chargeID,
              description,
              freightClass,
              fakFreightClass,
              rate,
              rateQualifier,
              quantity,
              weight,
              isMin,
              isMax,
              amount: total,
              type,
              group = '',
              ediCode,
              error = false,
            }) => ({
              chargeID: chargeID,
              description,
              freightClass,
              fakFreightClass,
              rate,
              rateQualifier,
              quantity,
              weight,
              isMin,
              isMax,
              total,
              type,
              group,
              ediCode,
              error,
            })
          )
          : [];

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // not ideal, but map would not work with the filter inline
        for (const charge of charges) {
          charge.group = Globals.ChargeTypes.indexOf(charge.type) === -1 ? 'Accessorial' : 'Linehaul';
        }

        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === 'Linehaul';
        });

        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });
      }

      this.updateCalculatedFields(items);
    }

    public updateCalculatedFields(items: Item[] = []) {
      this.subTotal = 0;
      if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
        this.lineHaulCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
        this.subTotal = +this.lineHaulCharges.map(this.itemTotal).reduce(this.sum).toFixed(2);
      }
      this.grandTotal = this.subTotal;
      if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
        this.accessorialCharges.sort((a, b) => (a.total < b.total ? 1 : -1));
        this.grandTotal += this.accessorialCharges.map(this.itemTotal).reduce(this.sum);
        this.grandTotal = +this.grandTotal.toFixed(2);
      }

      if (items && items.length) {
        this.weight = this.getTotalWeightQuote(items);
      }
    }

    public getTotalWeightQuote(itemList: Item[]): number {
      let total = 0;
      for (const item of itemList) {
        total += item.weightUOM === 'lbs' ? item.weight : this.kToLbs(item.weight);
      }
      return total;
    }

    private kToLbs(k: number): number {
      const nearExact = k / 0.45359237;
      return Math.round(nearExact);
    }

    private itemTotal(item) {
      return item.total;
    }
    private sum(prev, next) {
      return prev + next;
    }
  }

  export class Charge {
    description: string;
    freightClass: number;
    fakFreightClass?: number;
    rate: number;
    rateQualifier: string;
    quantity: number;
    weight: number;
    total: number;
    group: string;
    type: string;
    error: boolean;
    ediCode: string;
  }

  export class AuditNote {
    id: number;
    invoiceAuditID: number;
    invoiceNumber: string;
    date: Date;
    queueType: string;
    queueStatus: string;
    noteStatus: string;
    noteType: string;
    note: string;
    user: string;
    assignedTo: string;
    errorType: string;
    contractReason: string;
    settlementReason: string;
  }

  export function mapAuditNotes(details: AuditNoteDetailResponse[], sortByDate: boolean = false): AuditNote[] {
    const auditNotes = details.map(
      ({
        noteDetailID: id,
        invoiceAuditID,
        invoiceNumber,
        noteDateCreated: date,
        queueType,
        queueStatus,
        noteStatus,
        noteType,
        note: note,
        noteUserCreated: user,
        assignedTo,
        errorType,
        contractReason,
        settlementReason,
      }) => ({
        id,
        invoiceAuditID,
        invoiceNumber,
        date,
        queueType,
        queueStatus,
        noteStatus,
        noteType,
        note,
        user,
        assignedTo,
        errorType,
        contractReason,
        settlementReason,
      })
    );
    return sortByDate ? auditNotes.sort((a, b) => (a.date < b.date ? 1 : -1)) : auditNotes;
  }
}
