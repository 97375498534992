import { StartupData } from '@/models/StartupData';
import { Action } from '@ngrx/store';
import { User } from 'oidc-client';

export const USER_AUTHENTICATION_SUCCESS = '[CARRIER] User Authentication Success';

export class UserAuthenticationSuccess implements Action {
  readonly type = USER_AUTHENTICATION_SUCCESS;
  constructor(public user: User, public startupData: StartupData) {}
}

export type Actions = UserAuthenticationSuccess;
