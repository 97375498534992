import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { catchError } from 'rxjs/operators';
import { ShipmentDocumentDTO, ShipmentDocumentUploadDTO } from '../models/ShipmentDocument';

@Injectable({
  providedIn: 'root',
})
export class ShipmentDocumentService {
  shipmentApiHeaders: HttpHeaders;
  shipmentApiHeadersTest: HttpHeaders;

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeaders = new HttpHeaders({
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
    });
  }

  // get all the documents for the shipment
  public getDocumentsForShipment(shipmentId: number): Observable<ShipmentDocumentDTO[]> {
    const url = `${this.startupService.documentAPIUrl}v1/document/all/${shipmentId}`;
    return this.http.get<ShipmentDocumentDTO[]>(url).pipe(
      catchError((err) => {
        return observableThrowError(err);
      })
    );
  }

  // get the file data
  public getDocumentData(shipmentId: number, document: string): Observable<string> {
    const url = `${this.startupService.documentAPIUrl}v1/document/combined/${shipmentId}/${document}`;
    return this.http
      .get<string>(url, {
        responseType: 'text' as 'json',
      })
      .pipe(
        catchError((err) => {
          return observableThrowError(err);
        })
      );
  }

  public getDocumentDataByPath(path: string): Observable<string> {
    return this.http.get(path, {
      responseType: 'text',
    });
  }

  public uploadDocument(document: ShipmentDocumentUploadDTO): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers: headers, observe: 'response' as 'body' };
    const url = `${this.startupService.documentAPIUrl}v1/document`;
    const observable = Observable.create((observer) => {
      this.http.post<any>(url, JSON.stringify(document), options).subscribe(
        (data) => {
          observer.next(true);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }

  /**
   * Renames a document by file type
   * @param document target document
   * @param updatedFileType new file type
   * @returns updated document
   */
  renameDocument(document: ShipmentDocumentDTO, updatedFileType: string) {
    const endpoint = `${this.startupService.documentAPIUrl}v1/document/${document.shipmentId}/rename/${document.name}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.patch<ShipmentDocumentDTO>(endpoint, { updatedFileType }, { headers });
  }

  // helpers
  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
