import { TLAuditDetailOldState } from '../models/tl-audit-detail-state-old.model';
import * as TLAuditDetailActions from '../actions/tl-audit-detail-old.actions';

const initialState: TLAuditDetailOldState = {
  showManageReasons: false,
  manageReasonsSaveInProgress: false,
  saveReasonSuccess: false,
  saveReasonError: null,
  showAssignAuditor: false,
  loadAuditorsError: null,
  savedAuditorChangesSuccess: false,
  savedAuditorChangesError: null,
  tlAuditors: null,
  selectedAuditor: '',
  updatedAuditor: null,
  assignAuditorSaveInProgress: false,
  tlAudit: null,
  loadingTLAudit: false,
  loadingTLAuditSuccess: false,
  loadingTLAuditError: null,
  tlAuditReasonData: null,
  shipmentID: 0,
  invoiceID: 0,
  tlAuditID: 0,
  updateReasonSuccess: false,
  updateReasonError: null,
  addCommentSuccess: false,
  addCommentError: null,
};
export function TLAuditDetailReducerOld(state: TLAuditDetailOldState = initialState, action: TLAuditDetailActions.Actions) {
  switch (action.type) {
    case TLAuditDetailActions.SHOW_ASSIGN_AUDITOR_OLD: {
      return {
        ...state,
        tlAuditID: action.auditID,
        savedAuditorChangesSuccess: false,
        savedAuditorChangesError: null,
        selectedAuditor: action.auditor,
        showAssignAuditor: action.visible,
      };
    }
    case TLAuditDetailActions.LOAD_TL_AUDITOR_LIST_OLD: {
      return { ...state };
    }
    case TLAuditDetailActions.LOAD_TL_AUDITOR_LIST_SUCCESS_OLD: {
      return { ...state, tlAuditors: action.auditors };
    }
    case TLAuditDetailActions.LOAD_TL_AUDITOR_LIST_ERROR_OLD: {
      return { ...state, loadAuditorsError: action.message };
    }
    case TLAuditDetailActions.ADD_AUDITOR_TO_AUDIT_OLD: {
      return { ...state, savedAuditorChangesError: null, savedAuditorChangesSuccess: false, assignAuditorSaveInProgress: true };
    }
    case TLAuditDetailActions.REMOVE_AUDITOR_TO_AUDIT_OLD: {
      return { ...state, savedAuditorChangesError: null, savedAuditorChangesSuccess: false, assignAuditorSaveInProgress: true };
    }
    case TLAuditDetailActions.ADD_AUDITOR_TO_AUDIT_SUCCESS_OLD: {
      return {
        ...state,
        assignAuditorSaveInProgress: false,
        savedAuditorChangesSuccess: true,
        updatedAuditor: action.updatedAudit.auditor,
      };
    }
    case TLAuditDetailActions.ADD_AUDITOR_TO_AUDIT_ERROR_OLD: {
      return { ...state, assignAuditorSaveInProgress: false, savedAuditorChangesSuccess: false, savedAuditorChangesError: action.message };
    }

    case TLAuditDetailActions.SHOW_MANAGE_REASON_OLD: {
      return { ...state, showManageReasons: action.visible };
    }
    case TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL_OLD: {
      return {
        ...state,
        loadingTLAudit: true,
        invoiceID: action.invoiceID,
        shipmentID: action.shipmentID,
        saveReasonSuccess: false,
        saveReasonError: null,
        addCommentSuccess: false,
        addCommentError: null,
        updateReasonSuccess: false,
        updateReasonError: null,
        tlAudit: null,
      };
    }
    case TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL_SUCCESS_OLD: {
      return {
        ...state,
        loadingTLAudit: false,
        loadingTLAuditSuccess: true,
        tlAuditReasonData: action.reasonData,
        tlAuditID: action.tlAuditDetail.invoiceAuditID,
        tlAudit: action.tlAuditDetail,
      };
    }
    case TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL_ERROR_OLD: {
      return { ...state, loadingTLAudit: false, loadingTLAuditSuccess: false, loadingTLAuditError: action.message };
    }
    case TLAuditDetailActions.SAVE_REASON_OLD: {
      return {
        ...state,
        saveReasonSuccess: false,
        saveReasonError: null,
        addCommentSuccess: false,
        addCommentError: null,
        updateReasonSuccess: false,
        updateReasonError: null,
        manageReasonsSaveInProgress: true,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_SUCCESS_OLD: {
      const currentAudit = state.tlAudit;
      const currentReasons = currentAudit.reasons;
      currentReasons
        .splice(0, 0, action.newSavedReason)
        .sort((a, b) => +b.status.localeCompare(a.status) || +a.reason.localeCompare(b.reason) - 1);

      return {
        ...state,
        manageReasonsSaveInProgress: false,
        saveReasonSuccess: true,
        tlAudit: currentAudit,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_ERROR_OLD: {
      return { ...state, manageReasonsSaveInProgress: false, saveReasonSuccess: false, saveReasonError: action.message };
    }
    case TLAuditDetailActions.SAVE_REASON_STATUS_OLD: {
      return {
        ...state,
        saveReasonSuccess: false,
        saveReasonError: null,
        addCommentSuccess: false,
        addCommentError: null,
        updateReasonError: null,
        updateReasonSuccess: false,
        manageReasonsSaveInProgress: true,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_STATUS_SUCCESS_OLD: {
      const currentAudit = state.tlAudit;
      const currentReason = currentAudit.reasons.filter((x) => x.reasonID === action.newSavedReason.reasonID);
      if (currentReason && currentReason.length === 1) {
        currentReason[0].status = action.newSavedReason.status;
        currentReason[0].comments = action.newSavedReason.comments;
      }
      currentAudit.reasons.sort((a, b) => +b.status.localeCompare(a.status) || +a.reason.localeCompare(b.reason) - 1);
      return {
        ...state,
        manageReasonsSaveInProgress: false,
        updateReasonSuccess: true,
        tlAudit: currentAudit,
      };
    }
    case TLAuditDetailActions.SAVE_REASON_STATUS_ERROR_OLD: {
      return { ...state, manageReasonsSaveInProgress: false, updateReasonSuccess: false, updateReasonError: action.message };
    }
    case TLAuditDetailActions.ADD_AUDIT_SAVE_REASON_OLD: {
      return { ...state, saveReasonSuccess: false, saveReasonError: null, manageReasonsSaveInProgress: true };
    }
    case TLAuditDetailActions.ADD_AUDIT_SAVE_REASON_SUCCESS_OLD: {
      return {
        ...state,
        manageReasonsSaveInProgress: false,
        saveReasonSuccess: true,
        tlAudit: action.newSavedAudit,
      };
    }
    case TLAuditDetailActions.ADD_AUDIT_SAVE_REASON_ERROR_OLD: {
      return { ...state, manageReasonsSaveInProgress: false, saveReasonSuccess: false, saveReasonError: action.message };
    }
    case TLAuditDetailActions.ADD_COMMENT_OLD: {
      return {
        ...state,
        addCommentSuccess: false,
        addCommentError: null,
        saveReasonSuccess: false,
        saveReasonError: null,
        updateReasonSuccess: false,
        updateReasonError: null,
        manageReasonsSaveInProgress: true,
      };
    }
    case TLAuditDetailActions.ADD_COMMENT_SUCCESS_OLD: {
      const currentAudit = state.tlAudit;
      const currentReason = currentAudit.reasons.filter((x) => x.reasonID === action.newSavedComment.reasonID);
      if (currentReason && currentReason.length === 1) {
        const currentComments = currentReason[0].comments;
        if (currentComments) {
          currentComments.splice(0, 0, action.newSavedComment);
        } else {
          currentReason[0].comments = [action.newSavedComment];
        }
      }
      return {
        ...state,
        manageReasonsSaveInProgress: false,
        addCommentSuccess: true,
        tlAudit: currentAudit,
      };
    }
    case TLAuditDetailActions.ADD_COMMENT_ERROR_OLD: {
      return { ...state, manageReasonsSaveInProgress: false, addCommentSuccess: false, addCommentError: action.message };
    }

    default:
      return state;
  }
}
