import { MarkupRuleResponse } from '@/models/BgRating';
import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ShipmentUI } from '../../pages/audit/Shipment.ui';
import { ShipmentInvoiceAuditUI } from '../../pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';

@Component({
  selector: 'shipment-data-header',
  templateUrl: './shipment-data-header.component.html',
  styleUrls: ['../shipment-edit/shipment-edit.component.scss', './shipment-data-header.component.scss'],

  providers: [CurrencyPipe]
})
export class ShipmentDataHeaderComponent implements OnInit {

  @Input()
  isAuditor: boolean;

  @Input()
  shipmentData: ShipmentInvoiceAuditUI.Shipment;

  @Input()
  markupRule: MarkupRuleResponse;

  @Input() pendingInvoice: any;

  @Output()
  originAddressChange: EventEmitter<ShipmentUI.AddressUI> = new EventEmitter<ShipmentUI.AddressUI>();

  @Output()
  destinationAddressChange: EventEmitter<ShipmentUI.AddressUI> = new EventEmitter<ShipmentUI.AddressUI>();

  @Output()
  editingAddress: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  editingOriginAddress: boolean = false;
  editingDestinationAddress: boolean = false;
  customerPricing: string

  private originCopy: ShipmentInvoiceAuditUI.Address;
  private destinationCopy: ShipmentInvoiceAuditUI.Address;

  constructor(
    private currencyPipe: CurrencyPipe
  ) {

  }

  ngOnInit(): void {
    this.setCustomerPricing();
  }

  setCustomerPricing() {
    if (this.markupRule && this.markupRule.markupType) {

      let markupValue = this.markupRule.markupType.toLowerCase() == "flat"
        ? this.markupRule.markupType + " " + this.currencyPipe.transform(this.markupRule.markup)
        : this.markupRule.markup + '%';

      this.customerPricing = markupValue;
    }
    else {
      this.customerPricing = "N/A";
    }
  }

  editOrigin() {
    this.editingOriginAddress = true;
    this.editingAddress.emit(true);

    this.originCopy = Object.assign({}, this.shipmentData.origin);
  }

  editDestination() {
    this.editingDestinationAddress = true;
    this.editingAddress.emit(true);

    this.destinationCopy = Object.assign({}, this.shipmentData.destination);
  }

  closeOriginEdit(addressWasChanged: boolean) {
    this.editingOriginAddress = false;
    this.editingAddress.emit(this.editingDestinationAddress);

    if (!addressWasChanged) {
      this.shipmentData.origin = this.originCopy;
    }
  }

  closeDestinationEdit(addressWasChanged: boolean) {
    this.editingDestinationAddress = false;
    this.editingAddress.emit(this.editingOriginAddress);

    if (!addressWasChanged) {
      this.shipmentData.destination = this.destinationCopy;
    }
  }

  copyContractBill() {
    navigator.clipboard.writeText(this.shipmentData.billTo.name);
  }

  public onOriginAddressChange($event) {
    this.originAddressChange.emit($event);
  }

  public onDestinationAddressChange($event) {
    this.destinationAddressChange.emit($event);
  }

  url(address: ShipmentUI.AddressUI) {
    const fullAddress = `${address.addressLine1} ${address.city} ${address.stateProvince} ${address.postalCode}`;
    return 'https://www.google.com/search?q=' + encodeURIComponent(fullAddress);
  }
}
