export class AddressRerate {
  earliestDate: Date;
  city: string;
  state: string;
  zip: string;
  country: string;
  sequence: number;
}

export class DimensionRerate {
  height: number;
  width: number;
  length: number;
  units: string;
}

export class QuantityRerate {
  value: number;
  units: string;
}

export class WeightRerate {
  units: string;
  value: number;
}

export class ItemRerate {
  freightClass: string;
  sequence: number;
  weight: WeightRerate;
  dimensions: DimensionRerate;
  quantity: QuantityRerate;
}

export class SelectedRate {
  scac: string;
  service: string;
}

export class ShipmentInvoiceAuditRerateDTO {
  accountNumber: string;
  primaryReference: string;
  addresses: AddressRerate[];
  items: ItemRerate[];
  accessorials: string[];
  selectedRate: SelectedRate;
}
