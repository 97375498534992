import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { catchError, map } from 'rxjs/operators';
import { ShipmentHistory } from '../services/ShipmentHistory';
import { ShipmentQuoteHistoryResponse } from '@/models/ShipmentQuoteHistoryResponse';
import { EventHistory } from './EventHistory';
@Injectable({
  providedIn: 'root',
})
export class ShipmentHistoryService {
  shipmentApiHeaders: HttpHeaders;
  public shipmentHistoryBook: ShipmentHistory[];
  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeaders = new HttpHeaders({
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null
    });
  }

  getHistory(id: number): Observable<ShipmentHistory[]> {
    return this.http
      .get<ShipmentHistory[]>(`${this.startupService.financeAPIUrl}v2/shipments/${id}/audit-history`, {
        headers: this.shipmentApiHeaders,
      })
      .pipe(
        map((data) => (this.shipmentHistoryBook = data)),
        catchError(this.handleError)
      );
  }

  getQuoteHistory(shipmentID: number): Observable<ShipmentQuoteHistoryResponse> {
    return this.http.get<ShipmentQuoteHistoryResponse>(`${this.startupService.shipmentApiUrl}v2/${shipmentID}/shipment/quotehistory`, {
      headers: this.shipmentApiHeaders,
    });
  }

  getEventHistory(shipmentID: number): Observable<EventHistory[]> {
    return this.http.get<EventHistory[]>(`${this.startupService.shipmentApiUrl}v2/${shipmentID}/shipment/eventHistory`, {
      headers: this.shipmentApiHeaders
    });
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
