import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import * as TLAuditEditActions from '../actions/tl-audit-edit.actions';
import { map, catchError, mergeMap, concatMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TLEditUI } from '../models/TLEdit.ui';
import { ShipmentService } from '@/services/shipment.service';
import { InvoiceService } from '@/services/invoice.service';
import { ShipmentDocumentService } from '@/services/shipment-document.service';
import { Globals } from '@/_shared/globals';

@Injectable()
export class TLAuditEditEffects {
  @Effect()
  retrieveTLAuditEditData$ = this.actions$.pipe(
    ofType(TLAuditEditActions.LOAD_TL_AUDIT_EDIT),
    mergeMap((action: TLAuditEditActions.LoadTLAuditEdit) => {
      const shipment = this.pcShipmentService.getShipmentByID(action.shipmentID);
      const carrierInvoices = this.pcInvoiceService.getCarrierInvoicesByID(action.shipmentID);
      const addresses = this.pcShipmentService.getShipmentRouteAddresses(action.shipmentID);
      const combined = forkJoin([shipment, carrierInvoices, addresses]);

      return combined.pipe(
        concatMap((actionAndShipment: any) => {
          const enterprise = this.pcShipmentService.getShipmentEnterpriseReferences(actionAndShipment[0].enterpriseID);

          const enterpriseDTO = this.pcShipmentService.getEnterprise(actionAndShipment[0].enterpriseID);
          const combinedEnterprise = forkJoin([enterprise, enterpriseDTO]);

          const shipmentUI = new TLEditUI.TLShipment(actionAndShipment[0], actionAndShipment[0].invoiceID, actionAndShipment[2]);
          shipmentUI.setCarrierInvoices(actionAndShipment[1]);
          // call them
          return combinedEnterprise.pipe(
            map((data: any) => {
              shipmentUI.setEnterpriseData(data[0]);
              shipmentUI.setEnterpriseDTO(data[1]);
              return new TLAuditEditActions.LoadTLAuditEditSuccess(shipmentUI);
            }),
            catchError((err: HttpErrorResponse) => {
              return of(new TLAuditEditActions.LoadTLAuditEditError(err.message));
            })
          );
        })
      );
    })
  );

  @Effect()
  moveTLAuditEditData$ = this.actions$.pipe(
    ofType(TLAuditEditActions.MOVE_TL_AUDIT_EDIT),
    mergeMap((action: TLAuditEditActions.MoveTLAuditEdit) =>
      this.pcInvoiceService.moveInvoice(action.updateCarrierInvoice, action.invoiceID).pipe(
        map(() => {
          return new TLAuditEditActions.MoveTLAuditEditSuccess();
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditEditActions.MoveTLAuditEditError(err.message));
        })
      )
    )
  );

  @Effect()
  deleteTLAuditEditData$ = this.actions$.pipe(
    ofType(TLAuditEditActions.DELETE_TL_AUDIT_EDIT),
    mergeMap((action: TLAuditEditActions.DeleteTLAuditEdit) =>
      // call them
      this.pcInvoiceService.deleteInvoice(action.invoiceID).pipe(
        map(() => {
          return new TLAuditEditActions.DeleteTLAuditEditSuccess();
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditEditActions.DeleteTLAuditEditError(err.message));
        })
      )
    )
  );

  @Effect()
  loadDocumentsTLAuditEditData$ = this.actions$.pipe(
    ofType(TLAuditEditActions.LOAD_TL_AUDIT_DOCUMENTS),
    mergeMap((action: TLAuditEditActions.LoadTLAuditDocuments) =>
      // call them
      this.pcDocumentService.getDocumentsForShipment(action.shipmentID).pipe(
        map((data) => {
          const docTypeList = Globals.DocumentTypes;
          const filteredDocumentList = data.filter((doc) => docTypeList.find(({ value }) => doc.type === value));
          return new TLAuditEditActions.LoadTLAuditDocumentsSuccess(filteredDocumentList);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditEditActions.LoadTLAuditDocumentsError(err.message));
        })
      )
    )
  );

  @Effect()
  uploadDocumentsData$ = this.actions$.pipe(
    ofType(TLAuditEditActions.UPLOAD_TL_AUDIT_DOCUMENT),
    mergeMap((action: TLAuditEditActions.UploadTLAuditDocument) =>
      this.pcDocumentService.uploadDocument(action.uploadDocument).pipe(
        map((data) => {
          return new TLAuditEditActions.UploadTLAuditDocumentSuccess(action.uploadDocument.name);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Error uploading document TL Edit Lite');
          return of(new TLAuditEditActions.UploadTLAuditDocumentError(err.message, err.status));
        })
      )
    )
  );

  @Effect()
  downDocumentsTLAuditEditData$ = this.actions$.pipe(
    ofType(TLAuditEditActions.DOWNLOAD_TL_AUDIT_DOCUMENTS),
    mergeMap((action: TLAuditEditActions.DownloadTLAuditDocuments) =>
      this.pcDocumentService.getDocumentDataByPath(action.path).pipe(
        map((data) => {
          return new TLAuditEditActions.DownloadTLAuditDocumentsSuccess(data);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('Error' + err.status);
          return of(new TLAuditEditActions.DownloadTLAuditDocumentsError(err.message));
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private pcShipmentService: ShipmentService,
    private pcInvoiceService: InvoiceService,
    private pcDocumentService: ShipmentDocumentService
  ) { }
}
