import { AppState } from '@/appstate.model';
import { AuthService } from '@/auth/auth.service';
import { CurrencyService } from '@/services/currency.service';
import { Enterprise } from '@/services/Enterprise';
import { UpdateCarrierInvoice, UpdateCarrierInvoiceAction } from '@/services/Invoice';
import { ShipmentService } from '@/services/shipment.service';
import { Helpers } from '@/_shared/helpers';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditInvoiceParameters } from '../../../../models/edit-invoice-parameters';
import { DeleteTLAuditEdit, LoadTLAuditEdit, MoveTLAuditEdit } from '../../actions/tl-audit-edit.actions';
import { TLEditUI } from '../../models/TLEdit.ui';
import { TLAuditEditHelperService } from '../../services/tl-audit-edit-helper.service';
import { BBill, TruckloadAuditReasonUI } from '@/pages/tl-audit/models/TruckloadAudit.ui';
import { LoadSettlementReasons, ShowBBill } from '@/pages/tl-audit/actions/tl-audit-bbill.actions';
import { TLAuditService } from '@/pages/tl-audit/services/tl-audit.service';
import { CarrierService } from '@/services/carrier.service';
import { InvoiceStatus } from '@/pages/invoices/enums';

@Component({
  selector: 'app-tl-invoice-list',
  templateUrl: './tl-invoice-list.component.html',
  styleUrls: ['./tl-invoice-list.component.scss'],
})
export class TlInvoiceListComponent implements OnInit {
  errorList: string[];
  subtotalVariance: number;
  totalVariance: number;
  shipment: TLEditUI.TLShipment;
  shipmentID: number;
  helpers: Helpers = null;
  showCreateBBill = false;

  // selected invoice
  currentInvoiceNumber: string;
  currentInvoiceId: number;
  openedMoveShipment: boolean;
  openedDeleteInvoice: boolean;
  deletingInvoice = false;
  openedCompleteInvoice: boolean;
  uncompletedInvoiceEvents: TruckloadAuditReasonUI[] = [];
  enterprise: Enterprise;
  conversion: number;

  constructor(
    private invoiceHelperService: TLAuditEditHelperService,
    private authService: AuthService,
    private store: Store<AppState>,
    private pcShipmentService: ShipmentService,
    private currencyService: CurrencyService,
    private tlAuditService: TLAuditService,
    private carrierService: CarrierService,
  ) {
    this.helpers = new Helpers(this.authService);
  }

  ngOnInit() {
    this.setListeners();
    this.getEnterprise();
  }

  private getCurrencyCode(date: Date): void {
    this.currencyService.getConversionRate(date).subscribe((info) => {
      this.conversion = info.find((data) => data.country == 'CAN').value;
    });
  }

  get userName(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem('userName') || '';
    }
    sessionStorage.setItem('userName', this.authService.BlueShipUser.name);
    return this.authService.BlueShipUser.name;
  }

  protected toggleInvoiceOpen(index: number) {
    if (this.shipment.carrierInvoices.length === 1) {
      return;
    }
    this.shipment.carrierInvoices[index].isOpen = !this.shipment.carrierInvoices[index].isOpen;
  }
  //complete tab
  protected onOpenCompleteDialog(invoiceId: number, invoiceNumber: string) {
    this.currentInvoiceId = invoiceId;
    this.currentInvoiceNumber = invoiceNumber;
    this.tlAuditService.getTLIncidents(invoiceId, this.shipment.shipmentID).subscribe(
      (data) => {
        this.uncompletedInvoiceEvents = data.reasons.filter((reason) => reason.status.toLocaleLowerCase() !== 'complete');
      }
    );
    this.openedCompleteInvoice = true;
  }

  protected closeCompleteDialog() {
    this.openedCompleteInvoice = false;
  }

  protected completeInvoice() {
    this.carrierService
      .completeCarrierInvoice(this.currentInvoiceId)
      .subscribe(
        (invoice) => {
          if (parseInt(invoice.status) === InvoiceStatus.Complete.valueOf()) {
            this.shipment.carrierInvoices.find((invoice) => invoice.invoiceId === this.currentInvoiceId).status = 'Complete';
          }
        },
      );
    this.closeCompleteDialog();
  }

  //bbill tab
  public onShowBBill(invoiceId: number, invoiceNumber: string) {
    this.currentInvoiceId = invoiceId;
    this.currentInvoiceNumber = invoiceNumber;
    const bbill = new BBill(
      invoiceId,
      this.shipment.shipmentID,
      invoiceNumber,
      this.shipment.accountNumber
    );
    this.store.dispatch(new ShowBBill(true, invoiceId, bbill));
  }

  protected onCloseBBill() {
    this.store.dispatch(new ShowBBill(false, 0, null));
  }

  // edit in new tab
  public editingInvoice: boolean = false;

  public editInvoiceParameters: EditInvoiceParameters = <EditInvoiceParameters>{
    invoiceID: 0,
    shipmentID: 0,
    isEditMode: true,
  };

  public onEditInvoice(invoiceId: number) {
    this.editInvoiceParameters.invoiceID = invoiceId;
    this.editInvoiceParameters.shipmentID = this.shipmentID;
    this.editInvoiceParameters.isEditMode = true;
    this.editInvoiceParameters.enterprise = this.enterprise;
    this.editInvoiceParameters.selectedRateCurrencyCode = this.shipment.currencyCode;

    this.editingInvoice = true;
    document.body.classList.add("not-scroll");
  }

  public closeEditInvoice() {
    this.editingInvoice = false;
    document.body.classList.remove("not-scroll");
  }

  public onInvoiceUpdated($event) {
    this.store.dispatch(new LoadTLAuditEdit(this.editInvoiceParameters.invoiceID, this.shipmentID));
    this.closeEditInvoice();
  }

  // action access

  //complete invoice
  protected hideCompleteBtn(status: string): boolean {
    if ((status === 'Complete' || status === 'Approved') || !this.helpers.hasOperation('canComplete_Invoice')) {
      return true;
    }
  }

  // b bill
  protected hideBBillBtn(status: string): boolean {
    if ((status !== 'Manual') || !this.helpers.hasOperation('CanCreate_BBill')) {
      return true;
    }
  }
  protected hideEditMoveBtn(status: string): boolean {
    if ((status !== 'Manual' && status !== 'New') || !this.helpers.hasOperation('canEdit_Invoice')) {
      return true;
    }
  }
  protected hideDeleteBtn(status: string): boolean {
    // must have one of these permissions
    if (!this.helpers.hasOperation('canDelete_Invoice') && !this.helpers.hasOperation('CanDelete_InvoiceAdmin')) {
      return true;
    }
    // if its not manual or new AND they dont have the admin delete
    if (status !== 'Manual' && status !== 'New') {
      if (!this.helpers.hasOperation('CanDelete_InvoiceAdmin')) {
        return true;
      }
    }
    return false;
  }

  // delete invoice
  get deleteInvoiceDisabled(): boolean {
    return this.openedDeleteInvoice || this.deletingInvoice;
  }

  protected onOpenDeleteDialog(invoiceId: number, invoiceNumber: string) {
    this.currentInvoiceId = invoiceId;
    this.currentInvoiceNumber = invoiceNumber;
    this.openedDeleteInvoice = true;
  }

  protected closeDeleteDialog() {
    this.openedDeleteInvoice = false;
  }

  protected deleteInvoice() {
    this.closeDeleteDialog();
    this.store.dispatch(new DeleteTLAuditEdit(this.currentInvoiceId));
  }

  // moving invoice
  protected onOpenMoveDialog(invoiceId: number, invoiceNumber: string) {
    this.currentInvoiceId = invoiceId;
    this.currentInvoiceNumber = invoiceNumber;
    this.openedMoveShipment = true;
  }

  protected closeMoveDialog() {
    this.openedMoveShipment = false;
  }

  protected moveInvoice(primaryReference: string) {
    this.closeMoveDialog();
    const obj = {
      confirmedOverwrite: true,
      auditor: this.userName, // TODO determine
      updateCarrierAction: UpdateCarrierInvoiceAction.Move,
      closingNote: 'Moved to Shipment ' + primaryReference,
      primaryReference: primaryReference,
    } as UpdateCarrierInvoice;
    this.store.dispatch(new MoveTLAuditEdit(this.currentInvoiceId, obj));
  }

  // private helpers
  private openFirstInvoice() {
    if (this.shipment && this.shipment.carrierInvoices && this.shipment.carrierInvoices.length > 0) {
      this.shipment.carrierInvoices[0].isOpen = true;
    }
  }

  private auditInvoice() {
    if (!this.hasShipmentAnyInvoice()) {
      return;
    }

    // calculate the subtotal variance
    this.subtotalVariance = this.invoiceHelperService.auditCalculateSubtotalCostVariance(
      this.shipment.carrierInvoices[0],
      this.shipment.carrierQuote
    );

    // calculate the total variance
    this.totalVariance = this.invoiceHelperService.auditCalculateTotalCostVariance(
      this.shipment.carrierInvoices[0],
      this.shipment.carrierQuote
    );

    // compare the invoice to the quote
    this.invoiceHelperService.auditInvoiceTotals(
      this.shipment.carrierInvoices[0].lineHaulCharges,
      this.shipment.carrierQuote.lineHaulCharges
    );
    this.invoiceHelperService.auditInvoiceTotals(
      this.shipment.carrierInvoices[0].accessorialCharges,
      this.shipment.carrierQuote.accessorialCharges
    );

    // other possible errors
    // weight
    this.errorList = [];
    const total = this.invoiceHelperService.auditCalculateWeightVariance(
      this.shipment.mode,
      this.shipment.carrierInvoices[0].weight,
      this.shipment.carrierQuote.weight
    );
    if (total !== 0) {
      this.errorList.push('Weight variance: ' + total.toLocaleString() + ' lbs');
    }

    // Scac
    let errorMsg = this.invoiceHelperService.auditScacVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
    if (errorMsg.length !== 0) {
      this.errorList.push(errorMsg);
    }
    const invoiceMode = this.shipment.carrierInvoices[0].mode;

    if (invoiceMode !== 'LTL' && invoiceMode !== 'LTL Volume' && invoiceMode !== 'Volume') {
      // MC Number
      errorMsg = this.invoiceHelperService.auditMCNumberVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
      if (errorMsg.length !== 0) {
        this.errorList.push(errorMsg);
      }

      // dot number
      errorMsg = this.invoiceHelperService.auditDOTNumberVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
      if (errorMsg.length !== 0) {
        this.errorList.push(errorMsg);
      }

      // carrier code
      errorMsg = this.invoiceHelperService.auditCarrierCodeNumberVariance(this.shipment.carrierInvoices[0], this.shipment.carrierQuote);
      if (errorMsg.length !== 0) {
        this.errorList.push(errorMsg);
      }
    }

    // is there a pro
    const result = this.shipment.references.find((x) => x.type === 'PRO');
    if (result == null) {
      this.errorList.push('Missing PRO number');
    }

    // is there a selected rate
    if (!this.shipment.isSelectedRate) {
      this.errorList.push('No selected rate');
    }
  }

  private hasShipmentAnyInvoice(): boolean {
    return this.shipment.carrierInvoices.length === 0 ? false : true;
  }
  private removeInvoiceFromUI() {
    const that = this;
    this.shipment.carrierInvoices = this.shipment.carrierInvoices.filter(function (obj) {
      return obj.invoiceId !== that.currentInvoiceId;
    });
  }

  private isFirstInvoice(): boolean {
    if (!this.hasShipmentAnyInvoice()) {
      return;
    }
    return this.shipment.carrierInvoices[0].invoiceId === this.currentInvoiceId;
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditEditState.shipment)
      .subscribe((x) => {
        if (x) {
          this.shipment = x;
          this.getCurrencyCode(this.shipment.carrierInvoices[0].invoiceDate);
          this.enterprise = x.enterprise.enterprise;
          this.shipmentID = x.shipmentID;
          this.openFirstInvoice();
          this.auditInvoice();
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.movedInvoiceSuccess)
      .subscribe((x) => {
        if (x) {
          // should we close the tab or remove the invoice?
          if (this.isFirstInvoice()) {
            window.close();
          } else {
            this.removeInvoiceFromUI();
          }
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.deletedInvoiceSuccess)
      .subscribe((x) => {
        if (x) {
          // should we close the tab or remove the invoice?
          if (this.isFirstInvoice()) {
            window.close();
          } else {
            this.removeInvoiceFromUI();
          }
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.deletingInvoice)
      .subscribe((x) => {
        if (x) {
          this.deletingInvoice = true;
        } else {
          this.deletingInvoice = false;
        }
      });
    this.store
      .select((x) => x.TLAuditBBillState.showBBill)
      .subscribe((x) => {
        this.showCreateBBill = x;
      });
    this.store
      .select((x) => x.TLAuditBBillState.savedSuccess)
      .subscribe((x) => {
        if (x) {
          this.shipment.carrierInvoices.find((invoice) => invoice.invoiceId === this.currentInvoiceId).isBBill = true;
          this.shipment.carrierInvoices.find((invoice) => invoice.invoiceId === this.currentInvoiceId).isSecondary = false;
        }
      });
  }

  getEnterprise(): void {
    this.pcShipmentService.getEnterprise(this.shipment.enterpriseID)
      .subscribe((response) => this.enterprise = response.enterprise);
  }
}
