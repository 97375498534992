import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { UpdateStatusNote } from '../../services/AuditQueue';
import { NotesAutoComponent } from '@/bg-common/app-autocomplete/app-autocomplete';
import { StartupService } from '@/startup.service';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';

@Component({
  selector: 'app-audit-queue-status-update',
  templateUrl: './audit-queue-status-update.component.html',
  styleUrls: ['./audit-queue-status-update.component.scss'],
})
export class AuditQueueStatusUpdateComponent implements OnInit {
  updateCache: Subject<void> = new Subject<void>();

  @ViewChild('autoComplete', { static: false })
  notesAutoComponent: NotesAutoComponent;

  @Input() selectQueue: string;

  @Input('currentQueue')
  set currentQueue(value: string) {
    this.theCurrentQueue = value;
  }

  @Input('buttonText')
  set buttonText(value: string) {
    this.theButtonText = value;
  }

  @Input('showCancel')
  set showCancel(value: boolean) {
    this.theShowCancel = value;
  }

  @Input('requireValidation')
  set requireValidation(value: boolean) {
    this.theRequireValidation = value;
  }

  @Input('queueStatusList')
  set queueStatusList(value: string[]) {
    this.statusList = value;
  }

  @Input('errorTypeList')
  set errorTypeList(value: string[]) {
    this.theErrorTypeList = value;
  }

  @Input('contractReasonList')
  set contractReasonList(value: string[]) {
    this.theContractReasonList = value;
  }

  @Input('settlementReasonList')
  set reasonList(value: string[]) {
    this.theSettlementReasonList = value;
  }

  @Input('name')
  set name(value: string) {
    this._qaName = value;
  }
  get name(): string {
    return this._qaName;
  }

  @Output()
  savenote = new EventEmitter<UpdateStatusNote>();

  @Output()
  cancel = new EventEmitter<true>();

  // sent in
  theCurrentQueue: string;
  statusList: string[];
  theErrorTypeList: string[];
  theContractReasonList: string[];
  theSettlementReasonList: string[];
  theButtonText = 'Add Queue';
  theShowCancel: boolean;
  theRequireValidation: boolean;
  disabledSaveBtn: boolean;
  selectCount = 1;
  incomingSelectedContractReason: string;
  _qaName: string;

  // selected values
  updateStatusNote: UpdateStatusNote;

  constructor(private startupService: StartupService) {
    this.updateStatusNote = new UpdateStatusNote();
  }

  ngOnInit() { }

  // outside envents
  public defaults(updateDefaults: UpdateStatusNote, selectCount: number) {
    this.updateStatusNote.selectedStatus = updateDefaults.selectedStatus;
    this.updateStatusNote.settlementReasonList = updateDefaults.settlementReasonList;
    this.updateStatusNote.selectedErrorType = updateDefaults.selectedErrorType;
    this.updateStatusNote.selectedContractReason = updateDefaults.selectedContractReason;
    this.incomingSelectedContractReason = updateDefaults.selectedContractReason;
    this.updateStatusNote.textNotes = updateDefaults.textNotes;
    this.updateStatusNote.latestNote = updateDefaults.latestNote;
    this.disabledSaveBtn = false;
    this.selectCount = selectCount;
  }
  public reset(selectCount: number) {
    this.updateStatusNote.selectedStatus = null;
    this.updateStatusNote.settlementReasonList = [];
    this.updateStatusNote.selectedErrorType = null;
    this.updateStatusNote.selectedContractReason = null;
    this.updateStatusNote.textNotes = null;
    this.updateStatusNote.textNotes = null;
    this.disabledSaveBtn = false;
    this.updateStatusNote.latestNote = null;
    this.selectCount = selectCount;
    this.notesAutoComponent.reset();
  }

  // page events
  protected onErrorTypeChanged(newValue) {
    if (newValue !== 'Contract') {
      this.updateStatusNote.selectedContractReason = null;
    } else {
      this.updateStatusNote.selectedContractReason =
        this.updateStatusNote.selectedContractReason !== null
          ? this.updateStatusNote.selectedContractReason
          : this.incomingSelectedContractReason;
    }
  }
  protected onCancel(event) {
    this.cancel.emit(true);
  }

  public onUpdateNote(event) {
    this.disabledSaveBtn = true;
    this.savenote.emit(this.updateStatusNote);
    this.notesAutoComponent.SaveNote();
  }

  public linkNote(event) {
    this.updateStatusNote.textNotes = event;
  }

  public valueChange(): void {
    if (this.updateStatusNote.selectedStatus === 'Complete') {
      if (this.theCurrentQueue == 'Customer')
        this.updateStatusNote.textNotes = this.updateStatusNote.latestNote || this.updateStatusNote.settlementReasonList.join(', ');
      else
        this.updateStatusNote.textNotes = 'User completed queue';
    }
  }

  protected dataQA(prefix: string, suffix: string): string {
    return `${prefix}-${this.name}-${suffix}`;
  }
}
