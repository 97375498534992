import { CarrierInvoiceAuditState } from '../models/carrier-invoice-audit-state.model';
import * as CarrierInvoiceAuditActions from '../actions/carrier-invoice-audit.actions';

const initialState: CarrierInvoiceAuditState = {
  carrierInvoiceAudit: null,
  loading: false,
  loadingError: null,
  saving: false,
  savedAudit: null,
  savingError: null,
  serviceTotal: 0,
  freightTotal: 0,
  serviceTotalInUSD: 0,
  freightTotalInUSD: 0,
  editCurrencyCode: 'USD',
  conversionRate: 0,
  charges: null,
  distance: 0,
  invoiceTotalInUSD: 0,
  invoiceTotal: 0,
  quoteTotal: 0,
  quoteTotalInUSD: 0,
  editMode: false,
  isInternational: false,
  carrierLoading: false,
  carrierLoadingError: null,
  carriers: null,
  editInProgress: false,
};
export function CarrierInvoiceAuditReducer(state: CarrierInvoiceAuditState = initialState, action: CarrierInvoiceAuditActions.Actions) {
  switch (action.type) {
    case CarrierInvoiceAuditActions.LOAD_CARRIER_INVOICE_EDIT: {
      return { ...state, loading: true };
    }
    case CarrierInvoiceAuditActions.LOAD_CARRIER_INVOICE_ADD: {
      return { ...state, loading: true };
    }
    case CarrierInvoiceAuditActions.LOAD_CARRIER_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        conversionRate: action.initialState.carrierInvoiceAudit.canadianConversionRate,
        editCurrencyCode: action.initialState.carrierInvoiceAudit.enterpriseCurrencyCode,
        carrierInvoiceAudit: action.initialState.carrierInvoiceAudit,
        invoiceID: action.initialState.carrierInvoiceAudit.invoiceID,
        serviceTotal: action.initialState.serviceTotal,
        freightTotal: action.initialState.freightTotal,
        invoiceTotal: action.initialState.invoiceTotal,
        invoiceTotalInUSD: action.initialState.invoiceTotalInUSD,
        quoteTotal: action.initialState.quoteTotal,
        quoteTotalInUSD: action.initialState.quoteTotalInUSD,
        charges: action.initialState.carrierInvoiceAudit.charges,
        weight: action.initialState.carrierInvoiceAudit.weight,
        distance: action.initialState.carrierInvoiceAudit.distance,
        paymentMethod: action.initialState.carrierInvoiceAudit.paymentMethod,
        invoiceDate: action.initialState.carrierInvoiceAudit.invoiceDate,
        invoiceNumber: action.initialState.carrierInvoiceAudit.invoiceNumber,
        editMode: action.initialState.carrierInvoiceAudit.invoiceID !== 0,
        isInternational: action.initialState.isInternational,
      };
    }
    case CarrierInvoiceAuditActions.LOAD_CARRIER_Invoice_ERROR: {
      return { ...state, loading: false, loadingError: action.message };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_TOTAL_CHANGED: {
      return { ...state, invoiceTotal: action.total, invoiceTotalInUSD: action.totalInUSD };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_SERVICE_TOTAL_CHANGED: {
      return { ...state, serviceTotal: action.total, serviceTotalInUSD: action.totalInUSD };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_FREIGHT_TOTAL_CHANGED: {
      return { ...state, freightTotal: action.total, freightTotalInUSD: action.totalInUSD };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_DISTANCE_CHANGED: {
      return { ...state, distance: action.distance };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_CARRIER_CHANGED: {
      return { ...state, carrierInvoiceAudit: action.carrierInvoiceAudit };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_CHARGES_IS_EDITING: {
      return { ...state, editInProgress: action.editInProgress };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_CHARGES_CHANGED: {
      return { ...state, charges: action.charges };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_SAVE: {
      return { ...state, saving: true };
    }
    case CarrierInvoiceAuditActions.CARRIER_INVOICE_SAVE_SUCCESS: {
      return { ...state, saving: false, savedAudit: action.updatedState };
    }
    case CarrierInvoiceAuditActions.CARRIER_Invoice_SAVE_ERROR: {
      return { ...state, saving: false, savingError: action.message };
    }
    case CarrierInvoiceAuditActions.CARRIER_LTL_LOAD: {
      return { ...state, carrierLoading: true };
    }
    case CarrierInvoiceAuditActions.CARRIER_LTL_LOAD_SUCCESS: {
      return { ...state, carrierLoading: false, carriers: action.tlCarriers };
    }
    case CarrierInvoiceAuditActions.CARRIER_TL_SEARCH: {
      return { ...state, carrierLoading: true };
    }
    case CarrierInvoiceAuditActions.CARRIER_TL_SEARCH_SUCCESS: {
      return { ...state, carrierLoading: false, carriers: action.tlCarriers };
    }
    case CarrierInvoiceAuditActions.CARRIER_LOAD_ERROR: {
      return { ...state, carrierLoading: false, savingError: action.message };
    }
    case CarrierInvoiceAuditActions.CLEAN_AUDIT_SAVE_STATE: {
      return { ...state, saving: false, savedAudit: null };
    }
    default:
      return state;
  }
}
