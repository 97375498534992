import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SaveMessage, TruckloadAuditReasonListUI } from '@/pages/tl-audit/models/TruckloadAudit.ui';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { NewTruckloadAuditIncident } from '@/pages/tl-audit/models/TLAudit';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { TLIncidentTypeDTO } from '../../dto/IncidentDTO';
import { SaveReason } from '../../actions/tl-audit-detail.actions';
import { requiredValidatorLogic } from '@/_shared/dropDown-required-validator.directive';
import { AuthService } from '@/auth/auth.service';

@Component({
  selector: 'app-tl-manage-reason',
  templateUrl: './tl-manage-reason.component.html',
  styleUrls: ['./tl-manage-reason.component.scss'],
})
export class TlManageReasonComponent implements OnInit {
  constructor(private authService: AuthService, private formBuilder: FormBuilder, private store: Store<AppState>) { }

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  currentInvoiceID: number;
  currentShipmentID: number;
  tlAuditReasons = [];
  unfilteredTLReasons: TLIncidentTypeDTO[];
  addReasonForm: FormGroup = null;
  showAddReasonForm = false;
  actionBtnDisabled = false;
  username: string;
  reasonList: TruckloadAuditReasonListUI;
  ngOnInit() {
    //temporary until incident API uses token
    this.username = this.authService.BlueShipUser.userName;
    this.setListeners();
    this.createForm();
  }

  // TODO subscribe to listener
  public onReasonUpdate(saveMsg: SaveMessage) {
    this.alertMessage.showAlertMessage(saveMsg.message, saveMsg.type);
  }
  protected toggleAddReasonForm() {
    this.showAddReasonForm = !this.showAddReasonForm;
  }
  protected saveNewReason() {
    this.actionBtnDisabled = true;
    if (!this.addReasonForm.valid) {
      const error = this.addReasonForm.errors.join(',');
      this.actionBtnDisabled = false;
      this.alertMessage.showAlertMessage(error, 'Error');
      return;
    }

    const reason = this.tlAuditReasons.find((x) => x.value === this.addReasonForm.value.newTLAuditReason);
    const comment = this.addReasonForm.value.newReasonComment !== null ? this.addReasonForm.value.newReasonComment : '';
    const newReason = new NewTruckloadAuditIncident(this.currentInvoiceID, this.currentShipmentID, reason.text, comment, this.username);
    this.store.dispatch(new SaveReason(newReason));
  }
  protected resetAddReasonForm() {
    this.addReasonForm.reset();
    this.showAddReasonForm = false;
  }

  private createForm() {
    this.addReasonForm = this.formBuilder.group({
      newTLAuditReason: [null, requiredValidatorLogic],
      newReasonComment: ['', [Validators.minLength(3), Validators.maxLength(500)]],
    });
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailState.loadingTLAuditError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Something went wrong:' + x, 'Error');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.tlIncidentTypes)
      .subscribe((x) => {
        if (x) {
          this.unfilteredTLReasons = x;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.invoiceID)
      .subscribe((x) => {
        this.currentInvoiceID = x;
      });
    this.store
      .select((x) => x.TLAuditDetailState.shipmentID)
      .subscribe((x) => {
        this.currentShipmentID = x;
      });
    this.store
      .select((x) => x.TLAuditDetailState.tlAuditReasonList)
      .subscribe((x) => {
        this.reasonList = x;
        if (this.unfilteredTLReasons && this.reasonList) {
          this.setAndFilterReasons(this.unfilteredTLReasons);
          this.checkToExpandAddReason();
          this.showSpinner(false);
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.manageReasonsSaveInProgress)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(true, 'Saving...');
          this.actionBtnDisabled = true;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.saveReasonError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Adding new event failed:' + x, 'Error');
          this.actionBtnDisabled = false;
          this.showSpinner(false);
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.saveReasonSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.actionBtnDisabled = false;
          this.setAndFilterReasons(this.unfilteredTLReasons);
          this.resetAddReasonForm();
          this.alertMessage.showAlertMessage('Event saved successfully', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.updateReasonSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.actionBtnDisabled = false;
          this.alertMessage.showAlertMessage('Event updated successfully', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.updateReasonError)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.alertMessage.showAlertMessage('Updating status on event failed:' + x, 'Error');
          this.actionBtnDisabled = false;
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.addCommentSuccess)
      .subscribe((x) => {
        if (x) {
          this.showSpinner(false);
          this.actionBtnDisabled = false;
          this.alertMessage.showAlertMessage('Comment saved successfully', 'Information');
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.addCommentError)
      .subscribe((x) => {
        if (x) {
          this.alertMessage.showAlertMessage('Adding new comment failed:' + x, 'Error');

          this.showSpinner(false);
          this.actionBtnDisabled = false;
        }
      });
  }

  private setAndFilterReasons(unfilteredReasons: TLIncidentTypeDTO[]) {
    this.tlAuditReasons = unfilteredReasons.filter(type => !type.isHidden).map((type) => {
      return {
        text: type.value,
        value: type.id,
      };
    });

    if (this.reasonList && this.reasonList.reasons && this.reasonList.reasons.length > 0) {
      const filteredList = this.tlAuditReasons.filter((reason) => {
        const foundMatch = this.reasonList.reasons.find((x) => x.reason === reason.text);
        if (foundMatch === undefined) {
          return reason;
        }
      });
      this.tlAuditReasons = filteredList;
    }
  }

  private checkToExpandAddReason() {
    if (this.reasonList && this.reasonList.reasons && this.reasonList.reasons.length > 0) {
      this.showAddReasonForm = false;
    } else {
      this.showAddReasonForm = true;
    }
  }
  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }
}
