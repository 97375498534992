import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { StartupService } from '../startup.service';
import { catchError, map } from 'rxjs/operators';
import { CarrierResponse, SearchResultDC, CreateCarrierInvoice, CarrierInvoiceResponse, EditCarrierInvoice } from '../models/Carrier';

@Injectable()
export class CarrierService {
  constructor(private http: HttpClient, private startupService: StartupService) { }

  public httpFinanceOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  public getInvoiceyID(invoiceID: number): Observable<CarrierInvoiceResponse> {
    return this.http
      .get<CarrierInvoiceResponse>(`${this.startupService.financeAPIUrl}v2/invoices/${invoiceID}`, {
        headers: this.httpFinanceOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }

  public getLtlCarriers(value: string): Observable<CarrierResponse[]> {
    return this.http
      .get<CarrierResponse[]>(`${this.startupService.financeAPIUrl}v2/carriers/ltlFilter`, {
        headers: this.httpFinanceOptions.headers,
        params: { 'filter': value }
      })
      .pipe(catchError(this.handleError));
  }

  public getLtlSCACCarriers(scac: string): Observable<CarrierResponse[]> {
    return this.http
      .get<CarrierResponse[]>(`${this.startupService.financeAPIUrl}v2/carriers/scac`, {
        headers: this.httpFinanceOptions.headers,
        params: { 'scac': scac }
      })
      .pipe(catchError(this.handleError));
  }

  public getTlCarriersByName(value: string): Observable<CarrierResponse[]> {
    return this.http
      .get<SearchResultDC[]>(`${this.startupService.carrierApiUrl}search/name`, {
        headers: this.httpFinanceOptions.headers,
        params: { 'substring': value }
      })
      .pipe(
        map((data) => {
          return data.map(
            (item) =>
              new CarrierResponse(
                item.carrierID,
                item.displayName,
                item.scac,
                item.mcNumber,
                item.dotNumber,
                item.carrierCode,
                item.stateProvince,
                item.city
              )
          );
        }),
        catchError(this.handleError)
      );
  }

  public getTlCarriersByIdentifier(value: string): Observable<CarrierResponse[]> {
    return this.http
      .get<SearchResultDC[]>(`${this.startupService.carrierApiUrl}search/${value}`, {
        headers: this.httpFinanceOptions.headers,
      })
      .pipe(
        map((data) => {
          return data.map(
            (item) =>
              new CarrierResponse(
                item.carrierID,
                item.displayName,
                item.scac,
                item.mcNumber,
                item.dotNumber,
                item.carrierCode,
                item.stateProvince,
                item.city
              )
          );
        }),
        catchError(this.handleError)
      );
  }

  public createCarrierInvoice(carrierInvoice: CreateCarrierInvoice): Observable<any> {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/invoices/carrier`, carrierInvoice, {
      headers: this.httpFinanceOptions.headers,
    });
  }
  public updateCarrierInvoice(invoiceID: number, editCarrierInvoice: EditCarrierInvoice) {
    return this.http
      .put(`${this.startupService.financeAPIUrl}v2/invoices/${invoiceID}/carrier`, editCarrierInvoice, {
        headers: this.httpFinanceOptions.headers,
      })
      .pipe(catchError(this.handleError));
  }
  public completeCarrierInvoice(invoiceID): Observable<CarrierInvoiceResponse> {
    return this.http
      .put<CarrierInvoiceResponse>(
        `${this.startupService.financeAPIUrl}v2/invoices/${invoiceID}/carrier/complete`,
        {},
        {
          headers: this.httpFinanceOptions.headers,
        }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
