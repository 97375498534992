import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { StartupService } from '../startup.service';
import { catchError, map } from 'rxjs/operators';
import { CustomerProfile, CustomerProfileRefDataResponse, CreateCustomerProfile, UpdateCustomerProfile } from '../models/CustomerProfile';
import { EnterpriseChildDTO, IUpdateCustomerPendingInvoice } from '../services/Collection';
import { AuthService } from '@/auth/auth.service';

@Injectable()
export class CustomerService {
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private startupService: StartupService,
    private auth: AuthService
  ) {
    this.headers = this.auth.setHttpHeaders(this.headers);
  }

  public proactiveRecordGrid: CustomerProfile[];
  public proactiveProfileSelected: CustomerProfile;

  public getCustomerProfileReferenceData(): Observable<CustomerProfileRefDataResponse> {
    return this.http
      .get<CustomerProfileRefDataResponse>(`${this.startupService.financeAPIUrl}v2/references/customerprofiles`)
      .pipe(catchError(this.handleError));
  }

  public getSpecificCustomerProfile(id: number): Observable<any> {
    return this.http.get<CustomerProfile>(`${this.startupService.financeAPIUrl}v2/customerprofiles/${id}`).pipe(
      map((data) => (this.proactiveProfileSelected = data)),
      catchError(this.handleError)
    );
  }

  public getSpecificCustomerProfileByEnterpriseID(id: number): Observable<CustomerProfile> {
    return this.http.get<CustomerProfile>(`${this.startupService.financeAPIUrl}v2/customerprofiles/find/${id}`).pipe(
      map((data) => (this.proactiveProfileSelected = data)),
      catchError(this.handleError)
    );
  }

  public getCustomerProfiles(): Observable<CustomerProfile[]> {
    return this.http.get<CustomerProfile[]>(`${this.startupService.financeAPIUrl}v2/customerprofiles/`).pipe(
      map((data) => {
        return data.map((row) => {
          row.isProactiveString = row.isProactive === false ? 'No' : 'Yes';
          return row;
        });
      })
    );
  }

  public updateCustomerProfile(profile: UpdateCustomerProfile, customerProfileID: number) {
    return this.http.put(`${this.startupService.financeAPIUrl}v2/customerprofiles/${customerProfileID}`, profile);
  }

  public createCustomerProfile(profile: CreateCustomerProfile) {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/customerprofiles/`, profile);
  }

  public getEnterprises(filter: string): Observable<EnterpriseChildDTO[]> {
    return this.http
      .get(`${this.startupService.shipmentApiUrl}v1/enterprise/search?searchString=${encodeURIComponent(filter)}`, { headers: this.headers })
      .pipe(map((data) => <EnterpriseChildDTO[]>data));
  }

  public deleteCustomerProfile(id: number) {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/customerprofiles/${id}`).pipe(catchError(this.handleError));
  }

  public updatePendingInvoices(data: IUpdateCustomerPendingInvoice): Observable<IUpdateCustomerPendingInvoice> {
    return this.http.put<IUpdateCustomerPendingInvoice>(
      `${this.startupService.financeAPIUrl}v2/pending-invoices/customer`, data);
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
