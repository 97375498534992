import { TLAuditIncidentAssignmentState } from '../models/tl-audit-detail-state.model';
import * as TLIncidentAssignmentActions from '../actions/tl-audit-incident-assignment.actions';

const initialState: TLAuditIncidentAssignmentState = {
  showAssignAuditor: false,
  loadAuditorsError: null,
  savedAuditorChangesSuccess: false,
  savedAuditorChangesError: null,
  tlAuditors: null,
  selectedAuditor: '',
  updatedAuditor: null,
  assignAuditorSaveInProgress: false,
  invoiceIDs: null,
  incidentIDs: null,
  incidentAssigments: null,
  updateAuditorSaveInProgress: false,
  updateAuditorChangesSuccess: false,
  updateAuditorChangesError: null,
};
export function TLAuditIncidentAssignmentReducer(
  state: TLAuditIncidentAssignmentState = initialState,
  action: TLIncidentAssignmentActions.Actions
) {
  switch (action.type) {
    case TLIncidentAssignmentActions.SHOW_ASSIGN_AUDITOR: {
      return {
        ...state,
        invoiceIDs: action.invoiceIDs ? action.invoiceIDs : null,
        incidentAssigments: action.auditorAssignment ? action.auditorAssignment.assigmentList : null,
        selectedAuditor: action.auditorAssignment ? action.auditorAssignment.name : '',
        savedAuditorChangesSuccess: false,
        savedAuditorChangesError: null,
        showAssignAuditor: action.visible,
        updateAuditorSaveInProgress: false,
        updateAuditorChangesSuccess: false,
        updateAuditorChangesError: null,
      };
    }
    case TLIncidentAssignmentActions.LOAD_TL_AUDITOR_LIST: {
      return { ...state };
    }
    case TLIncidentAssignmentActions.LOAD_TL_AUDITOR_LIST_SUCCESS: {
      return { ...state, tlAuditors: action.auditors };
    }
    case TLIncidentAssignmentActions.LOAD_TL_AUDITOR_LIST_ERROR: {
      return { ...state, loadAuditorsError: action.message };
    }
    case TLIncidentAssignmentActions.REMOVE_AUDITOR_TO_AUDIT: {
      return { ...state, savedAuditorChangesError: null, savedAuditorChangesSuccess: false, assignAuditorSaveInProgress: true };
    }
    case TLIncidentAssignmentActions.UPDATE_AUDITOR: {
      return { ...state, updateAuditorSaveInProgress: true, updateAuditorChangesSuccess: false, updateAuditorChangesError: null };
    }
    case TLIncidentAssignmentActions.UPDATE_AUDITOR_SUCCESS: {
      return {
        ...state,
        updateAuditorSaveInProgress: false,
        updateAuditorChangesSuccess: true,
        updateAuditorChangesError: null,
        updatedAuditor: action.newSavedAuditor,
        incidentAssigments: action.newSavedAuditor.assigmentList,
        invoiceIds: action.newSavedAuditor.assigmentList.map((x) => x.invoiceID),
      };
    }
    case TLIncidentAssignmentActions.UPDATE_AUDITOR_ERROR: {
      return {
        ...state,
        updateAuditorSaveInProgress: false,
        updateAuditorChangesSuccess: false,
        updateAuditorChangesError: action.message,
      };
    }

    default:
      return state;
  }
}
