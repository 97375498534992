import {
  TruckloadAuditOldDTO,
  TruckloadAuditReasonOldDTO,
  TLAuditReasonCommentOldDTO,
  TruckloadAuditSaveOldDTO,
} from '@/pages/tl-audit-old/dto/TruckloadAuditOLDDTO';

export class TruckloadAuditOldUI {
  shipmentID: number;
  invoiceID: number;
  enterpriseID: number;
  invoiceAuditID: number;
  invoiceNumber: string;
  invoiceTotal: number;
  invoiceTotalString: string;
  carrierName: string;
  carrierCode: string;
  buyRate: number;
  buyRateString: string;
  sellRate: number;
  sellRateString: string;
  margin: number;
  marginString: string;
  variance: number;
  varianceString: string;
  customer: string;
  customerAccount: string;
  primaryReference: string;
  invoiceCreatedDate: Date;
  invoiceCreatedDateString: string;
  isSecondary: boolean;
  auditor: string;
  auditorAssignedDate: Date;
  auditorAssignedDateString: string;
  auditCreatedDate: Date;
  auditCreatedDateString: string;
  auditAge: number;
  auditCompletedDate: Date;
  auditCompletedDateString: string;
  reasons: TruckloadAuditReasonOldUI[];
  reasonString: string;
  showReasonLimit = 3;
  constructor(tlAudit: TruckloadAuditOldDTO) {
    if (tlAudit != null) {
      this.shipmentID = tlAudit.shipmentID;
      this.enterpriseID = tlAudit.enterpriseID;
      this.invoiceID = tlAudit.invoiceID;
      this.invoiceAuditID = tlAudit.invoiceAuditID;
      this.primaryReference = tlAudit.primaryReference;
      this.carrierName = tlAudit.carrierName;
      this.carrierCode = tlAudit.carrierCode;
      this.customer = tlAudit.customer;
      this.customerAccount = tlAudit.customerAccount;
      this.isSecondary = tlAudit.isSecondary;
      this.auditor = tlAudit.auditor;
      this.invoiceNumber = tlAudit.invoiceNumber;
      this.invoiceTotal = tlAudit.invoiceTotal;
      this.invoiceTotalString = tlAudit.invoiceTotal == null ? '' : '$' + tlAudit.invoiceTotal.toFixed(2);
      this.buyRate = tlAudit.shipmentBuyRate;
      this.buyRateString = tlAudit.shipmentBuyRate == null ? '' : '$' + tlAudit.shipmentBuyRate.toFixed(2);
      this.sellRate = tlAudit.shipmentSellRate;
      this.sellRateString = tlAudit.shipmentSellRate == null ? '' : '$' + tlAudit.shipmentSellRate.toFixed(2);
      if (tlAudit.shipmentSellRate !== null && tlAudit.shipmentBuyRate !== null) {
        this.margin = this.sellRate - this.buyRate;
        this.marginString = '$' + this.margin.toFixed(2);
      }
      if (tlAudit.shipmentBuyRate !== null && tlAudit.invoiceTotal !== null) {
        this.variance = tlAudit.shipmentBuyRate - tlAudit.invoiceTotal;
        this.varianceString = '$' + this.variance.toFixed(2);
      }

      // reason sub list
      if (tlAudit.reasons != null && tlAudit.reasons.length > 0) {
        this.reasons = tlAudit.reasons
          .sort((a, b) => +b.status.localeCompare(a.status) || +a.reason.localeCompare(b.reason) - 1)
          .map((v) => new TruckloadAuditReasonOldUI(v)) as TruckloadAuditReasonOldUI[];
        this.reasonString = tlAudit.reasons
          .sort((a, b) => +b.status.localeCompare(a.status) || +a.reason.localeCompare(b.reason) - 1)
          .map((x) => x.reason)
          .toString();
      } else {
        this.reasons = [];
        this.reasonString = '';
      }
      // dates
      // TODO short date strings
      if (!isNaN(Date.parse(tlAudit.invoiceCreatedDate))) {
        this.invoiceCreatedDate = new Date(tlAudit.invoiceCreatedDate);
        this.invoiceCreatedDateString =
          this.invoiceCreatedDate.getMonth() + 1 + '/' + this.invoiceCreatedDate.getDate() + '/' + this.invoiceCreatedDate.getFullYear();
      }
      // defaulting age
      this.auditAge = 0;
      if (!isNaN(Date.parse(tlAudit.auditCreatedDate))) {
        this.auditCreatedDate = new Date(tlAudit.auditCreatedDate);
        this.auditCreatedDateString =
          this.auditCreatedDate.getMonth() + 1 + '/' + this.auditCreatedDate.getDate() + '/' + this.auditCreatedDate.getFullYear();
        // age of audit
        const today = new Date();
        const diff = Math.abs(today.getTime() - this.auditCreatedDate.getTime());
        this.auditAge = Math.ceil(diff / (1000 * 3600 * 24));
      }

      if (!isNaN(Date.parse(tlAudit.auditorAssignedDate))) {
        this.auditorAssignedDate = new Date(tlAudit.auditorAssignedDate);
        this.auditorAssignedDateString =
          this.auditorAssignedDate.getMonth() + 1 + '/' + this.auditorAssignedDate.getDate() + '/' + this.auditorAssignedDate.getFullYear();
      }

      if (!isNaN(Date.parse(tlAudit.auditCompletedDate))) {
        this.auditCompletedDate = new Date(tlAudit.auditCompletedDate);
        this.auditCompletedDateString =
          this.auditCompletedDate.getMonth() + 1 + '/' + this.auditCompletedDate.getDate() + '/' + this.auditCompletedDate.getFullYear();
      }
    }
  }
  setTLAuditFromSave(savedAudit: TruckloadAuditSaveOldDTO) {
    this.shipmentID = savedAudit.shipmentID;
    this.invoiceID = savedAudit.invoiceID;
    this.invoiceAuditID = savedAudit.truckloadAuditID;
    this.auditor = savedAudit.auditor;

    // reason sub list
    if (savedAudit.reasons != null && savedAudit.reasons.length > 0) {
      this.reasons = savedAudit.reasons
        .sort((a, b) => b.status.localeCompare(a.status))
        .map((v) => new TruckloadAuditReasonOldUI(v)) as TruckloadAuditReasonOldUI[];
      this.reasonString = savedAudit.reasons
        .sort((a, b) => a.reason.localeCompare(b.reason))
        .map((x) => x.reason)
        .toString();
    } else {
      this.reasons = [];
      this.reasonString = '';
    }

    if (!isNaN(Date.parse(savedAudit.auditorDateAssigned))) {
      this.auditorAssignedDate = new Date(savedAudit.auditorDateAssigned);
      this.auditorAssignedDateString =
        this.auditorAssignedDate.getMonth() + 1 + '/' + this.auditorAssignedDate.getDate() + '/' + this.auditorAssignedDate.getFullYear();
    }

    this.auditAge = 0;
    this.auditCreatedDate = new Date();
    this.auditCreatedDateString =
      this.auditCreatedDate.getMonth() + 1 + '/' + this.auditCreatedDate.getDate() + '/' + this.auditCreatedDate.getFullYear();
    // age of audit
    const today = new Date();
    const diff = Math.abs(today.getTime() - this.auditCreatedDate.getTime());
    this.auditAge = Math.ceil(diff / (1000 * 3600 * 24));
  }
}

export class TruckloadAuditReasonOldUI {
  tlAuditID: number;
  reasonID: number;
  reason: string;
  createdDate: Date;
  createdDateString: string;
  status: string;
  updatedDate: Date;
  updatedDateString: string;
  createdUser: string;
  updatecUser: string;
  reasonAge: number;
  comments: TruckloadAuditReasonCommentOldUI[];

  constructor(tlReason: TruckloadAuditReasonOldDTO) {
    if (tlReason) {
      this.reasonID = tlReason.reasonID;
      this.reason = tlReason.reason;
      this.status = tlReason.status;
      this.tlAuditID = tlReason.tlAuditID;
      this.createdUser = tlReason.createdUser;
      this.updatecUser = tlReason.updatedUser;
      this.reasonAge = 0;
      if (!isNaN(Date.parse(tlReason.createdDate))) {
        this.createdDate = new Date(tlReason.createdDate);
        this.createdDateString = this.createdDate.getMonth() + 1 + '/' + this.createdDate.getDate() + '/' + this.createdDate.getFullYear();

        // age of audit
        const today = new Date();
        const diff = Math.abs(today.getTime() - this.createdDate.getTime());
        this.reasonAge = Math.ceil(diff / (1000 * 3600 * 24));
      }

      if (!isNaN(Date.parse(tlReason.updatedDate))) {
        this.updatedDate = new Date(tlReason.updatedDate);
        this.updatedDateString = this.updatedDate.getMonth() + 1 + '/' + this.updatedDate.getDate() + '/' + this.updatedDate.getFullYear();
      }
      if (tlReason.comments !== undefined && tlReason.comments !== null && tlReason.comments.length > 0) {
        this.comments = tlReason.comments
          .sort((a, b) => b.createdDate.localeCompare(a.createdDate))
          .map((v) => new TruckloadAuditReasonCommentOldUI(v)) as TruckloadAuditReasonCommentOldUI[];
      }
    }
  }
}

export class TruckloadAuditReasonCommentOldUI {
  reasonID: number;
  commentID: number;
  comment: string;
  status: string;
  createdUser: string;
  createdDate: Date;
  createdDateString: string;
  constructor(tlComment: TLAuditReasonCommentOldDTO) {
    this.reasonID = tlComment.reasonID;
    this.commentID = tlComment.commentID;
    this.comment = tlComment.comment;
    this.status = tlComment.status;
    this.createdUser = tlComment.createdUser;

    if (!isNaN(Date.parse(tlComment.createdDate))) {
      this.createdDate = new Date(tlComment.createdDate);
      this.createdDateString = this.createdDate.getMonth() + 1 + '/' + this.createdDate.getDate() + '/' + this.createdDate.getFullYear();
    }
  }
}

export class SaveMessageOld {
  message: string;
  type: string;

  constructor(msg: string, messageType: string) {
    this.message = msg;
    this.type = messageType;
  }
}

export class BBillOld {
  constructor(invoiceID: number, shipmentID: number, invoiceNumber: string, accountNumber: string) {
    this.invoiceNumber = invoiceNumber;
    this.shipmentID = shipmentID;
    this.invoiceID = invoiceID;
    this.accountNumber = accountNumber;
  }
  invoiceID: number;
  shipmentID: number;
  invoiceNumber: string;
  previousPayment: number;
  accountNumber: string;
  reasons: string[];
}
