import { NotesInvoiceService } from '@/services/notes.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IComment, INote, Note } from '@/models/Notes';

@Component({
  selector: 'invoice-note-modal',
  templateUrl: './invoice-note-modal.component.html',
  styleUrls: ['./invoice-note-modal.component.scss'],
})
export class InvoiceNoteModalComponent extends DialogContentBase implements OnInit {
  @Input() note: Note = null;
  @Input() shipmentID: number;

  noteForm: FormGroup;
  commentForm: FormGroup;
  serverError: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: DialogRef,
    private notesService: NotesInvoiceService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.BuildNoteForms();
  }

  private BuildNoteForms(): void {
    this.noteForm = this.formBuilder.group({
      priority: [false, Validators.required],
      subject: ['', Validators.required],
      comment: ['', Validators.required],
    });

    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
  }

  public okToSave(): boolean {
    if (this.note === null) return this.noteForm.invalid;
    return this.commentForm.invalid;
  }

  public onCancel(): void {
    this.dialog.close();
  }

  public onSave(): void {
    if (this.note == null) this.CreateBrandNewNote();
    else this.CreateCommentUnderNote();
  }

  private CreateCommentUnderNote(): void {
    this.notesService.addNoteComment(this.shipmentID, this.note.noteId, this.buildComment())
      .subscribe(() => {
        this.dialog.close({ action: true });
      })
  }

  private CreateBrandNewNote(): void {
    this.notesService.saveNote(this.shipmentID, this.buildNote())
      .subscribe(() => {
        this.dialog.close({ action: true });
      })
  }

  private buildNote(): INote {
    return {
      type: 'Comment',
      priority: this.noteForm.get('priority').value ? 'high' : 'normal',
      subject: this.noteForm.get('subject').value,
      comment: this.noteForm.get('comment').value,
      commentStatus: 'Active',
    };
  }

  private buildComment(): IComment {
    return {
      comment: this.commentForm.get('comment').value,
      status: 'Active',
    };
  }
}
