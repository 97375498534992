import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch-custom',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor, OnChanges {

  @Input() checkInput: boolean;
  @Input() isReadOnly: boolean = false;
  @Input() emitChanges: boolean = false;
  @Output() changeSwitch = new EventEmitter;
  public check: boolean;

  currentValue = 0;
  onChange = (_: any) => { };
  onTouch = () => { };
  isDisabled: boolean;

  ngOnChanges(): void {
    this.check = this.checkInput;
  }

  writeValue(value: boolean): void {
    this.check = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChangeSwitch(event: boolean): void {
    this.changeSwitch.emit(event);
  }

}
