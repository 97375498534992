import { Component, OnInit } from '@angular/core';
import { AppState } from '@/appstate.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-invoice-totals',
  templateUrl: './invoice-totals.component.html',
  styleUrls: ['./invoice-totals.component.scss'],
})
export class InvoiceTotalsComponent implements OnInit {
  quoteTotalInUSD: number;
  quoteTotal: number;
  invoiceTotal: number;
  invoiceTotalInUSD: number;
  isInternational: boolean;
  currencyCode: string;
  isEdit: boolean;

  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.CarrierInvoiceAuditState.invoiceTotal)
      .subscribe((x) => {
        this.invoiceTotal = x;
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.invoiceTotalInUSD)
      .subscribe((x) => {
        this.invoiceTotalInUSD = x;
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.quoteTotal)
      .subscribe((x) => {
        this.quoteTotal = x;
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.quoteTotalInUSD)
      .subscribe((x) => {
        this.quoteTotalInUSD = x;
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.editMode)
      .subscribe((x) => {
        this.isEdit = x;
      });
    this.store
      .select((x) => x.CarrierInvoiceAuditState.isInternational)
      .subscribe((x) => {
        this.isInternational = x;
      });
  }
  ngOnInit() {}
}
