import { SaveShipmentReference } from '@/models/ShipmentReference';
import { ShipmentService } from '@/services/shipment.service';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';

interface ReferenceType {
  type: string;
  value: string;
}

@Component({
  selector: 'app-tl-references',
  templateUrl: './tl-references.component.html',
  styleUrls: ['./tl-references.component.scss'],
})
export class TlReferencesComponent implements OnInit {
  @Input() public shipmentID: number;
  @Input() public enterpriseID: number;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  public view: Observable<GridDataResult>;
  public gridState: State = {};

  enterpriseReferences: any = [];

  private editedRowIndex: number;
  private editedProduct: SaveShipmentReference;

  references: SaveShipmentReference[];
  errorMessage: string = null;

  constructor(private shipmentService: ShipmentService) { }

  ngOnInit() {
    this.getShipmentReferences();
  }

  getShipmentReferences() {
    this.references = [];
    this.shipmentService.getShipmentReferences(this.shipmentID).subscribe((data) => {
      this.references = data.references;
    });

    this.getAvailableReferences();
  }

  public onStateChange(state: State) {
    this.gridState = state;
  }

  private showSpinner(show: boolean, text: string = '', modal: boolean = false) {
    this.appSpinner.loading = show;
    this.appSpinner.modal = modal;
    this.appSpinner.text = text;
  }

  public addHandler({ sender }, formInstance) {
    formInstance.reset();
    this.closeEditor(sender);

    sender.addRow(new SaveShipmentReference(0, '', '', false));
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.editedProduct = Object.assign({}, dataItem);

    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  private referencesComparer = (left: ReferenceType, right: ReferenceType) => left.type == right.type && left.value == right.value;

  get duplicatedReference(): string {
    const duplicates = this.references.filter((left, index, references) => references.findIndex(right => this.referencesComparer(left, right)) != index);
    return duplicates.length > 0 ? `${duplicates[0].type}: ${duplicates[0].value}` : null;
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }) {
    this.save(dataItem, sender, rowIndex, isNew);
  }

  public openedDeleteReference: boolean = false;
  public referenceToRemove: any = null;

  public deleteReferenceSuccessHandler() {
    this.closeDeleteReference();
    this.showSpinner(true, "Removing reference", true);
    this.references = this.references.filter((item) => item !== this.referenceToRemove);
    this.shipmentService.saveShipmentReferences(this.shipmentID, this.references).subscribe(_ => {
      this.getShipmentReferences();
      this.showSpinner(false);
    },
      (err) => {
        this.showSpinner(false);
        this.errorMessage = err;
      }
    );
  }

  public closeDeleteReference() {
    this.openedDeleteReference = false;
  }

  public removeHandler({ dataItem }) {
    this.referenceToRemove = dataItem;
    this.openedDeleteReference = true;
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.resetItem(this.editedProduct);
    this.errorMessage = null;
    this.editedRowIndex = undefined;
    this.editedProduct = undefined;
  }

  public save(data: SaveShipmentReference, sender: any, rowIndex: number, isNew?: boolean) {

    if (isNew) {
      this.references.push(data);
      sender.closeRow(rowIndex);
    }

    if (this.duplicatedReference) {
      return;
    }

    this.showSpinner(true, "Saving references!", true);
    this.shipmentService.saveShipmentReferences(this.shipmentID, this.references).subscribe(
      () => {
        this.getShipmentReferences();
        sender.closeRow(rowIndex);
        this.errorMessage = null;
        this.editedRowIndex = undefined;
        this.editedProduct = undefined;
        this.showSpinner(false);
      },
      (err) => {
        this.errorMessage = 'Error: ' + err;
        this.showSpinner(false);
        return;
      }
    );
  }

  resetItem(dataItem: any) {
    if (!dataItem) {
      return;
    }

    const originalDataItem = this.references.find((item) => item.referenceID === dataItem.referenceID);

    Object.assign(originalDataItem, dataItem);
  }

  public getAvailableReferences() {
    this.shipmentService.getShipmentEnterpriseReferences(this.enterpriseID).subscribe((data) => {
      this.enterpriseReferences = data;
    });
  }
}