import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RemoveEvent } from '@progress/kendo-angular-grid';
import { FileRestrictions, ErrorEvent, UploadComponent, FileInfo, SelectEvent } from '@progress/kendo-angular-upload';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Globals } from '@/_shared/globals';
import { Helpers } from '@/_shared/helpers';
import { AuthService } from '@/auth/auth.service';
import { ShipmentDocumentService } from '@/services/shipment-document.service';
import { ImageService } from '@/services/image.service';
import { ShipmentDocumentDTO, ShipmentDocumentUploadDTO } from '@/models/ShipmentDocument';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DocumentRenameModalContentComponent } from '../document-rename-modal-content/document-rename-modal-content.component';
import { CommonModule } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ImageRetrievalResponse } from '@/models/ShipmentDocument';

@Component({
  selector: 'app-document-list-edit',
  templateUrl: './document-list-edit.component.html',
  styleUrls: ['./document-list-edit.component.scss'],
})
export class DocumentListEditComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild('documentuploadTLbtn', { static: false })
  documentuploadTLbtn: UploadComponent;

  private _shipmentID: number;
  private _pro: string;
  private _primary: string;

  selectedUploadDocumentType: string;
  fileTypeDictionary: { [key: string]: string };
  uploadDocumentList = Globals.DocumentTypes.filter((obj) => obj.canUpload === true);
  documentList = Globals.DocumentTypes;
  documentFiles: any[] = [];
  gridData: ShipmentDocumentDTO[];
  helpers: Helpers;
  loadingDocuments: boolean;
  uploadDocumentRestrictions = Globals.UploadFileRestrictions;
  refreshingDocumentsText: string = "Refresh Documents";

  get shipmentID(): number {
    return this._shipmentID;
  }
  get pro(): string {
    return this._pro;
  }
  get primary(): string {
    return this._primary;
  }

  @Input('shipmentID')
  set shipmentID(shipmentID: number) {
    this._shipmentID = shipmentID;
  }

  @Input('pro')
  set pro(pro: string) {
    this._pro = pro;
  }

  @Input('primary')
  set primary(primary: string) {
    this._primary = primary;
  }

  constructor(
    private authService: AuthService,
    private pcShipmentService: ShipmentDocumentService,
    private pcImageService: ImageService,
    private dialogService: DialogService,
  ) {
    this.fileTypeDictionary = this.documentList.reduce((accumulator, { text, value }) => {
      accumulator[`${value}`] = `${text}`;
      return accumulator;
    }, {} as { [key: string]: string });
    this.loadingDocuments = false;
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);
    this.loadDocuments();
  }

  loadDocuments(): void {
    this.loadingDocuments = true;
    this.pcShipmentService.getDocumentsForShipment(this.shipmentID).subscribe(
      (documentList) => {
        // only interested in documents that they can upload
        this.gridData = documentList.filter((elem) => this.documentList.find(({ value }) => elem.type === value));
        this.loadingDocuments = false;
      },
      (err) => {
        this.loadingDocuments = false;
      }
    );
  }

  removeDocument(e: RemoveEvent) {
    // TODO
  }

  selectDocumentEventHandler(event: SelectEvent): void {
    // stop auto upload
    event.preventDefault();

    const extensions = event.files.map((file) => file.extension);
    if (!this.helpers.hasAllowedExtensionsOnly(extensions)) {
      const { allowedExtensions } = Globals.UploadFileRestrictions;
      this.alertMessage.showAlertMessage(`Invalid file type selected. Allowed file types: ${allowedExtensions.join(', ')}`, 'Error');
      return;
    }

    // loop for all files
    event.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        const reader = new FileReader();

        // called when done reading in the file
        reader.onloadend = () => {
          // create the object
          const base64String = reader.result.toString();
          const documentObj = {
            type: this.selectedUploadDocumentType,
            name: file.name.replace(/\.[^/.]+$/, ''),
            format: Globals.AllowedExtensionsFormatMap[file.extension.toLowerCase()],
            source: 'Api',
            shipmentId: this.shipmentID,
            base64Data: base64String.substr(base64String.indexOf(',') + 1),
          } as ShipmentDocumentUploadDTO;

          this.uploadDocument(documentObj, file, reader);
        };

        // read the data
        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  refreshDocuments() {
    this.refreshingDocumentsText = "Refreshing...";
    this.pcImageService.getDocuments(this.primary, this.pro)
      .subscribe(data => {
        this.refreshingDocumentsText = "Refresh Documents";
        if (data.response.added.length > 0) {
          this.gridData = [];
          this.loadDocuments();
        }
        else {
          this.alertMessage.showAlertMessage('No new documents were found.', 'Error');
        }
      },
        (err) => {
          this.refreshingDocumentsText = "Refresh Documents";
          if (err.ok === false) {
            this.alertMessage.showAlertMessage(err.message, 'Error');
          }
        }
      );
  }

  openExistingDocument(document: any) {
    if (!document.path) {
      const err = 'Error downloading document' + document.name + '::' + this._shipmentID;
      console.log(err);
      this.alertMessage.showAlertMessage(err, 'Error');
    }

    let concatFormat = this.detectQueryString(document.path) ? "&format=pdf" : "?format=pdf";
    this.pcShipmentService.getDocumentDataByPath(document.path.concat(concatFormat))
      .subscribe(data => {
        this.helpers.downloadFile(data, document.name, document.format, true);
      });
  }

  private detectQueryString(url: string): boolean {
    var pattern = new RegExp(/\?.+=.*/g);
    return pattern.test(url);
  }

  // service calls
  uploadDocument(documentObj: ShipmentDocumentUploadDTO, file: FileInfo, reader: FileReader) {
    this.pcShipmentService.uploadDocument(documentObj).subscribe(
      (response) => {
        // call the endpoint to save the document
        this.documentFiles.push({ ...file, src: <string>reader.result });
        this.documentFiles = [...this.documentFiles];

        // emit that it was successful
        this.documentuploadTLbtn.success.emit();
        this.alertMessage.showAlertMessage(`This file ${file.name} was successfully uploaded.`, 'Success');

        this.documentuploadTLbtn.showFileList = false;
        this.selectedUploadDocumentType = null;
        this.loadDocuments();
      },
      (err) => {
        if (err.status === 409) {
          this.alertMessage.showAlertMessage('This document already exists for this invoice / type.', 'Error');
        } else {
          if (err.error.errors !== undefined) {
            this.alertMessage.showAlertMessage(err.error.errors.type[0], 'Error');
          } else {
            this.alertMessage.showAlertMessage(err.error, 'Error');
          }
        }
      }
    );
  }

  disableRename(document: ShipmentDocumentDTO) {
    // api generated documents are not renameable
    return document.source === 'Api_AutoGenerated';
  }

  openRenameModal(document: ShipmentDocumentDTO) {
    const dialogRef = this.dialogService.open({
      content: DocumentRenameModalContentComponent,
      width: 500,
      title: 'Rename Document',
      actions: [{ text: 'Cancel' }, { text: 'Rename', primary: true }],
    });
    const dialogContent = dialogRef.content.instance as any;

    // set required component props
    dialogContent.document = document;
    dialogContent.documentTypes = this.uploadDocumentList.filter(
      (documentType) => documentType.value !== document.type,
    );

    dialogRef.dialog.instance.action.subscribe(({ text }) => {
      if (text === 'Rename') {
        const { selectedDocumentType } = dialogContent;
        this.renameDocument(document, selectedDocumentType);
      }
    });
  }

  private async renameDocument(document: ShipmentDocumentDTO, selectDocumentType: string) {
    try {
      this.loadingDocuments = true;

      const updatedDocument = await this.pcShipmentService.renameDocument(document, selectDocumentType).toPromise();

      // update document in grid
      this.gridData = this.gridData.map(item => {
        if (item.path !== document.path) return item;
        return updatedDocument;
      });

      this.loadingDocuments = false;
      this.alertMessage.showAlertMessage('Successfully renamed document.', 'Success');
    } catch (err) {
      console.error(err);
      this.alertMessage.showAlertMessage('Error attempting to rename document', 'Error');
      this.loadingDocuments = false;
    }
  }
}
