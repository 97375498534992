import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import * as TLAuditDetailActions from '../actions/tl-audit-detail.actions';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TLAuditService } from '../services/tl-audit.service';

@Injectable()
export class TLAuditDetailEffects {
  @Effect()
  retrieveTLAuditDetailData$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL),
    mergeMap((action: TLAuditDetailActions.LoadTLAuditDetail) => {
      const tlAuditRecord = this.tlAuditService.getTLIncidents(action.invoiceID, action.shipmentID);
      const reasonData = this.tlAuditService.getTLIncidentReferenceData();
      // call them
      const combined = forkJoin([tlAuditRecord, reasonData]);
      return combined.pipe(
        map((data) => {
          return new TLAuditDetailActions.LoadTLAuditDetailSuccess(data[0], data[1]);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditDetailActions.LoadTLAuditDetailError(err.message));
        })
      );
    })
  );

  @Effect()
  saveTLAuditReason$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.SAVE_REASON),
    mergeMap((action: TLAuditDetailActions.SaveReason) =>
      this.tlAuditService.addTLReason(action.newReason).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.SaveReasonSuccess(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.SaveReasonError(err.error));
        })
      )
    )
  );

  @Effect()
  updateReasonStatus$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.SAVE_REASON_STATUS),
    mergeMap((action: TLAuditDetailActions.SaveReasonStatus) =>
      this.tlAuditService.updateIncidentStatus(action.updateReason).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.SaveReasonStatusSuccess(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.SaveReasonStatusError(err.error));
        })
      )
    )
  );

  @Effect()
  addComment$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.ADD_COMMENT),
    mergeMap((action: TLAuditDetailActions.AddComment) =>
      this.tlAuditService.addComment(action.newComment).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.AddCommentSuccess(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.AddCommentError(err.error));
        })
      )
    )
  );

  constructor(private actions$: Actions, private tlAuditService: TLAuditService) { }
}
