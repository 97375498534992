import { Component, Input } from '@angular/core';
import { Tracking } from '@/services/Shipment';

@Component({
  selector: 'ltl-tracking-history',
  templateUrl: './ltl-tracking-history.component.html',
  styleUrls: ['./ltl-tracking-history.component.scss'],
})
export class LtlTrackingHistoryComponent {

  @Input() gridView: Tracking[];

}
