import { Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { User } from 'oidc-client';
import { Observable, Subject } from 'rxjs';
import { StartupData } from '../../models/StartupData';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<object> = new Subject<object>();

  user: User;
  config: StartupData;
  constructor() {
    this.flags = {
      [LaunchDarklyFlagNames.eventHistory]: false,
      [LaunchDarklyFlagNames.unmatchedInvoiceImages]: false
    };
  }

  init(config: StartupData, user: User): Observable<LDFlagSet> {
    return new Observable<LDFlagSet>(() => {
      this.user = user;
      const userInfo = {
        key: this.user.profile.name,
        email: this.user.profile.email,
        name: this.user.profile.name
      };

      this.ldClient = initialize(`${config.launchDarklyKey}`, userInfo);

      this.ldClient.on('ready', () => {
        this.setFlags();
      });

      this.ldClient.on('change', (flags) => {
        if (flags[LaunchDarklyFlagNames.eventHistory] !== undefined) {
          this.flags[LaunchDarklyFlagNames.eventHistory] =
            flags[LaunchDarklyFlagNames.eventHistory].current;
        }
        else if (flags[LaunchDarklyFlagNames.unmatchedInvoiceImages] !== undefined) {
          this.flags[LaunchDarklyFlagNames.unmatchedInvoiceImages] =
            flags[LaunchDarklyFlagNames.unmatchedInvoiceImages].current;
        }
        this.flagChange.next(this.flags);
      });
    });
  }

  setFlags() {
    this.flags = this.ldClient.allFlags();
    this.flagChange.next(this.flags);
  }

  changeUser(user) {
    if (user !== 'Anonymous') {
      this.ldClient.identify({ key: user, name: user, anonymous: false });
    } else {
      this.ldClient.identify({ key: 'anon', anonymous: true });
    }
  }
}

export enum LaunchDarklyFlagNames {
  eventHistory = 'gryphon-event-history',
  unmatchedInvoiceImages = 'gryphon-unmatched-invoice-images',
}
