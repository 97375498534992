import { AppState } from '@/appstate.model';
import { ShipmentService } from '@/services/shipment.service';
import { StartupService } from '@/startup.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Store } from '@ngrx/store';
import { TLEditUI } from '../../models/TLEdit.ui';

@Component({
  selector: 'app-tl-header',
  templateUrl: './tl-header.component.html',
  styleUrls: ['./tl-header.component.scss'],
})
export class TlHeaderComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  shipment: TLEditUI.TLShipment;
  proDialogOpened = false;
  editedUpdatedPRO: string;
  proInfo: any;
  disableSubmit: boolean = false;
  error: string = null;

  constructor(private store: Store<AppState>, private startupService: StartupService, private shipmentService: ShipmentService) { }
  ngOnInit() {
    this.setListeners();
    this.getProInformation();
  }

  getProInformation() {
    this.shipmentService.getShipmentProReferences(this.shipment.shipmentID).subscribe((resp) => {
      this.proInfo = resp;
    });
  }

  // Page events
  protected clickAuditHistory() {
    window.open(`/shipment-history?shipmentID=${this.shipment.shipmentID}`, '_blank');
  }

  protected onClickInvoiceList() {
    window.open(`/shipment-search?q=${this.shipment.bol}`, '_blank');
  }

  protected clickShipmentDetails() {
    const url = this.startupService.backOfficeUrl + '/shipment-edit/' + this.shipment.shipmentID;
    window.open(url, '_blank');
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditEditState.shipment)
      .subscribe((x) => {
        if (x) {
          this.shipment = x;
        }
      });
  }

  updateEditPro() {
    this.error = null;
    this.disableSubmit = false;
    this.proDialogOpened = true;
    this.shipment.pros ? (this.editedUpdatedPRO = this.shipment.pros) : (this.editedUpdatedPRO = '');
  }

  updateAddPRO() {
    this.error = null;
    this.disableSubmit = true;

    //remove all whitespace
    this.editedUpdatedPRO = this.editedUpdatedPRO.replace(/\s+/g, '');

    //insert a new pro if we don't have any previous existing
    if (!this.shipment.pros) {
      this.shipmentService.saveShipmentProReference(this.editedUpdatedPRO, this.shipment.shipmentID).subscribe(
        (resp) => {
          this.shipment.pros = this.editedUpdatedPRO;
          this.getProInformation();
          this.proDialogOpened = false;
          this.disableSubmit = false;
          this.alertMessage.showAlertMessage('PRO Updated!', 'Success');
        },
        (err) => {
          console.log(err);
          this.disableSubmit = false;
          this.error = err;
        }
      );
    }

    //if pros on shipment, update the value of the existing pro
    if (this.shipment.pros) {
      let currentProInfo = this.proInfo.proNumbers.find((x) => x.proNumber === this.shipment.pros);
      if (!currentProInfo) {
        alert("Can't update PRO reference when 2 PROs exist.");
        return;
      }
      this.shipmentService
        .updateShipmentProReference(currentProInfo.proNumberID, this.shipment.shipmentID, this.editedUpdatedPRO)
        .subscribe(
          (resp) => {
            this.shipment.pros = this.editedUpdatedPRO;
            this.getProInformation();
            this.proDialogOpened = false;
            this.disableSubmit = false;
            this.alertMessage.showAlertMessage('PRO Updated!', 'Success');
          },
          (err) => {
            console.log(err);
            this.disableSubmit = false;
            this.error = err;
          }
        );
    }
  }
}
