import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import * as TLAuditDetailActions from '../actions/tl-audit-detail-old.actions';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TLAuditServiceOld } from '../services/tl-audit-old.service';

@Injectable()
export class TLAuditDetailEffectsOld {
  @Effect()
  retrieveTLAuditDetailData$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.LOAD_TL_AUDIT_DETAIL_OLD),
    mergeMap((action: TLAuditDetailActions.LoadTLAuditDetailOld) => {
      const tlAuditRecord = this.tlAuditServicce.getTLAudit(action.invoiceID, action.shipmentID);
      const reasonData = this.tlAuditServicce.getTLAuditReferenceData();
      // call them
      const combined = forkJoin([tlAuditRecord, reasonData]);
      return combined.pipe(
        map((data) => {
          return new TLAuditDetailActions.LoadTLAuditDetailSuccessOld(data[0], data[1]);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditDetailActions.LoadTLAuditDetailErrorOld(err.message));
        })
      );
    })
  );

  @Effect()
  saveTLAuditReason$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.SAVE_REASON_OLD),
    mergeMap((action: TLAuditDetailActions.SaveReasonOld) =>
      this.tlAuditServicce.addTLReason(action.newReason).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.SaveReasonSuccessOld(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.SaveReasonErrorOld(err.error));
        })
      )
    )
  );

  @Effect()
  updateReasonStatus$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.SAVE_REASON_STATUS_OLD),
    mergeMap((action: TLAuditDetailActions.SaveReasonStatusOld) =>
      this.tlAuditServicce.updateTLReason(action.invoiceAuditID, action.reasonID, action.status, action.comment).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.SaveReasonStatusSuccessOld(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.SaveReasonStatusErrorOld(err.error));
        })
      )
    )
  );

  @Effect()
  saveTLAuditWithReason$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.ADD_AUDIT_SAVE_REASON_OLD),
    mergeMap((action: TLAuditDetailActions.AddAuditSaveReasonOld) =>
      this.tlAuditServicce.addTLAudit(action.invoiceID, action.shipmentID, action.reason, action.comment).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.AddAuditSaveReasonSuccessOld(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.AddAuditSaveReasonErrorOld(err.error));
        })
      )
    )
  );

  @Effect()
  addComment$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.ADD_COMMENT_OLD),
    mergeMap((action: TLAuditDetailActions.AddCommentOld) =>
      this.tlAuditServicce.addComment(action.invoiceAuditID, action.reasonID, action.comment).pipe(
        map((initialState) => {
          return new TLAuditDetailActions.AddCommentSuccessOld(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditDetailActions.AddCommentErrorOld(err.error));
        })
      )
    )
  );

  @Effect()
  retrieveTLAuditorList$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.LOAD_TL_AUDITOR_LIST_OLD),
    mergeMap((action: TLAuditDetailActions.LoadTLAuditorListOld) =>
      this.tlAuditServicce.getAuditors().pipe(
        map((data) => {
          return new TLAuditDetailActions.LoadTLAuditorListSuccessOld(data);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditDetailActions.LoadTLAuditorListErrorOld(err.message));
        })
      )
    )
  );
  @Effect()
  addAuditorToAudit$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.ADD_AUDITOR_TO_AUDIT_OLD),
    mergeMap((action: TLAuditDetailActions.AddAuditorToAuditOld) =>
      this.tlAuditServicce.addAuditor(action.tlauditID, action.name).pipe(
        map((data) => {
          return new TLAuditDetailActions.AddAuditorToAuditSuccessOld(data);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditDetailActions.AddAuditorToAuditErrorOld(err.message));
        })
      )
    )
  );

  @Effect()
  removeAuditorFromAudit$ = this.actions$.pipe(
    ofType(TLAuditDetailActions.REMOVE_AUDITOR_TO_AUDIT_OLD),
    mergeMap((action: TLAuditDetailActions.RemoveAuditorFromAuditOld) =>
      this.tlAuditServicce.removeAuditor(action.tlauditID).pipe(
        map((data) => {
          return new TLAuditDetailActions.AddAuditorToAuditSuccessOld(data);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(new TLAuditDetailActions.AddAuditorToAuditErrorOld(err.message));
        })
      )
    )
  );

  constructor(private actions$: Actions, private tlAuditServicce: TLAuditServiceOld) {}
}
