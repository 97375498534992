import { Component, OnInit, OnDestroy, ViewChild, Renderer2, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '@/auth/auth.service';
import { StartupService } from '@/startup.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuditDocumentsListComponent } from '@/components/audit-documents-list/audit-documents-list.component';
import { AuditListComponent } from '@/components/audit-list/audit-list.component';
import { AuditCarrierListComponent } from '@/components/audit-carrier-list/audit-carrier-list.component';
import { AuditEmailListComponent } from '@/components/audit-email-list/audit-email-list.component';
import { AuditBgListComponent } from '@/components/audit-bg-list/audit-bg-list.component';
import { AuditReturnListComponent } from '@/components/audit-return-list/audit-return-list.component';
import { AuditSecondaryListComponent } from '@/components/audit-secondary-list/audit-secondary-list.component';
import { AuditQueueStatusUpdateComponent } from '@/bg-common/audit-queue-status-update/audit-queue-status-update.component';
import { ShipmentDocumentService } from '../../services/shipment-document.service';
import { NotesAutoComponent } from '@/bg-common/app-autocomplete/app-autocomplete';
import {
  ActiveAuditResponse,
  ActiveCarrierAuditResponse,
  UpdateStatusNote,
  SaveAuditStatusNote,
  InvoiceAuditRefDataResponse,
  SaveCustomerAuditStatusNote,
  SaveBGAuditStatusNote,
  SendCarrierDisputeEmail,
} from '@/services/AuditQueue';
import { AuditQueueService } from '@/services/queue.service';
import { Helpers } from '@/_shared/helpers';
import { Globals } from '@/_shared/globals';
import { FileRestrictions, UploadEvent, SuccessEvent, FileInfo, SelectEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { FilterFactory } from '@/_shared/FilterFactory';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { ShipmentDocumentUploadDTO } from '@/models/ShipmentDocument';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CustomerProfile } from '@/models/CustomerProfile';
import { ConfirmationDialogService } from '@/_shared/confirmation-dialog/confirmation-dialog.service';
import { AuditCarrierInvoiceUI } from './AuditCarrierInvoice.ui';
import { ConvertJSONToCSVHelper } from '../duplicate-carrier/duplicate-carrier.interface';
import { InvoiceService } from '@/services/invoice.service';
import { finalize } from 'rxjs/operators';
import { parseNumber } from '@progress/kendo-angular-intl';
import { asyncScheduler } from 'rxjs/internal/scheduler/async';
import { AuditUserResponse } from '@/services/InvoiceAudit';

@Component({
  selector: 'app-collection',
  templateUrl: './audit-queue.component.html',
  styleUrls: ['./audit-queue.component.scss'],
})
export class AuditQueueComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox', { static: false })
  public storageAutoComplete: NotesAutoComponent;

  @ViewChild('auditDocumentGrid', { static: false })
  auditDocumentChild: AuditDocumentsListComponent;

  @ViewChild('auditGrid', { static: false })
  auditChild: AuditListComponent;

  @ViewChild('auditBGGrid', { static: false })
  auditBGChild: AuditBgListComponent;

  @ViewChild('auditReturnGrid', { static: false })
  auditReturnChild: AuditReturnListComponent;

  @ViewChild('auditSecondaryGrid', { static: false })
  auditSecondaryChild: AuditSecondaryListComponent;

  @ViewChild('auditCarrierGrid', { static: false })
  auditCarrierChild: AuditCarrierListComponent;

  @ViewChild('auditEmailListGrid', { static: false })
  auditEmailListGrid: AuditEmailListComponent;

  @ViewChild('theNoteControl', { static: false })
  auditNoteStatusUpdateComponent: AuditQueueStatusUpdateComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild('documentuploadTLbtn', { static: false })
  documentuploadTLbtn: UploadComponent;

  filtersGridUpdate: boolean;
  // records
  recordAuditGrid: ActiveAuditResponse[];
  recordEmailGrid: ActiveCarrierAuditResponse[];
  theRecords: ActiveAuditResponse[];
  theCarrierRecords: ActiveCarrierAuditResponse[];
  responseList: ActiveAuditResponse[];

  defaultUpdateStatusNote = new UpdateStatusNote();
  documentAuditResponseRecord: ActiveAuditResponse;
  showDocumentGrid: boolean;
  showCarrierGrid: boolean;
  showAuditGrid: boolean;
  showBGAuditGrid: boolean;
  showReturnAuditGrid: boolean;
  showSecondaryAuditGrid: boolean;
  showEmailGrid: boolean;
  refreshPage: boolean;
  selectedItem: String;
  currentURL: string;
  uploading = false;
  file: any;
  showMoveSecondaryInvoiceTypeDialog: boolean;
  showMassAssignAuditorDialog: boolean;
  auditorList: AuditUserResponse[];
  public uploadSaveUrl: string;
  public uploadRestrictions = Globals.UploadFileRestrictions;

  queueType: string;
  documentType: { text: string; value: string } = {
    text: '',
    value: '',
  };
  buttonText: string;

  selectedCarrierQueue = 'New';
  helpers: Helpers = null;
  showCheckboxes: boolean;
  isProactiveOrNonProactive: boolean = false;
  showSettlementReasons: boolean;
  showQueues: boolean;
  isDataloading: boolean;
  openedOkDialog: boolean;
  openedDialog: boolean;
  uploadErrorText = '';
  uploadSuccessText = '';
  opened: boolean;
  recordCount: number;
  invoiceAuditReferences: InvoiceAuditRefDataResponse;
  docClickSubscription: any = null;

  queueList = Globals.QueueList;
  documentList = Globals.DocumentTypes.filter((obj) => obj.canUpload === true);
  documentFiles: any[] = [];
  carrierQueueList: string[];
  queueStatusList: string[];
  errorTypeList: string[];
  contractReasonList: string[];
  settlementReasonList: string[];
  selectedRecords: string[] = [];
  navigationSubscription;
  GridFilter = new FilterFactory();
  secondaryInvoiceTypes = Globals.SecondaryInvoiceTypes;
  invoiceCategoryToMove: string;
  selectedAuditor: { fullName: string; id: number } = { fullName: '', id: 0 };

  selectedQueue: { text: string; value: string } = {
    text: 'Proactive',
    value: 'Proactive',
  };

  public showPageAlert: boolean = false;
  public isErrorMessage: boolean = false;
  public pageAlertMessage: string = '';
  dataExport: string;

  isSpecializedAudit: boolean = false;
  specializedAuditUser: Array<CustomerProfile> = [];

  constructor(
    private pcService: AuditQueueService,
    private startupService: StartupService,
    private invoiceService: InvoiceService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private authService: AuthService,
    private pcShipmentService: ShipmentDocumentService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    // need this because URL can change but its really the same page loading different components
    this.refreshPage = false;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.setQueueType();
        this.loadPage();
      }
    });
  }

  emitFiltersGridUpdate(value: boolean) {
    this.filtersGridUpdate = value;
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.docClickSubscription) {
      this.docClickSubscription();
    }
  }

  ngOnInit() {
    // are they allowed to view this page
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    if (!this.helpers.hasOperation('InvoiceAudit')) {
      this.router.navigate([`not-authorized`]);
    }

    this.openedDialog = false;
    this.openedOkDialog = false;
    this.showEmailGrid = false;
    this.buttonText = 'Update Status / Note';
    this.uploadSaveUrl = `${this.startupService.documentAPIUrl}v1/document`;
    if (this.docClickSubscription == null) {
      this.docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
    }
    this.GridFilter.init();
  }

  loadPage() {
    // make sure the references are loaded first
    if (this.invoiceAuditReferences == null) {
      this.loadReferences();
      return;
    }

    this.initialize();
    this.hideGrids();
    this.clearRecordset();
    this.loadAuditorList()

    // show the correct controls
    if (this.queueType !== 'Document' && this.queueType !== 'Carrier') {
      this.showQueues = true;
    }

    this.loadRecords();
  }

  public get disableCompleteInvoiceButton(): boolean {
    const canCompleteOperations = this.helpers.hasOperation('CanComplete_Invoice');
    return !this.selectedRecords || (this.selectedRecords && this.selectedRecords.length < 1) || !canCompleteOperations;
  }

  public OnCompleteInvoice(): void {
    let bodyMsg = 'Are you sure you want to complete the selected invoices?';
    const that = this;
    this.confirmationDialogService
      .confirm('Confirm action', bodyMsg, 'Complete', 'Cancel', null, [])
      .then(function (result) {
        if (result === true) {
          that.completeCarrierInvoice();
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  private completeCarrierInvoice(): void {
    let filterSelectedRecords = this.selectedRecords.filter((item, index) => {
      return this.selectedRecords.indexOf(item) === index;
    });

    this.pcService.completeProactiveAndNonProactiveInvoices(filterSelectedRecords).subscribe((response: any[]) => {
      if (response) {
        let invoicesFailed = response.filter((r) => r.message);
        this.loadPage();
        let hasErrors = invoicesFailed && invoicesFailed.length > 0;
        if (hasErrors) {
          this.pageAlertMessage = 'The following invoices cannot be updated: <br>';
          invoicesFailed.forEach((invoice, index) => {
            this.pageAlertMessage = this.pageAlertMessage.concat(
              invoice.id,
              ' : ',
              invoice.message,
              index !== invoicesFailed.length - 1 ? '<br>' : ''
            );
          });
        } else {
          this.pageAlertMessage = 'The selected invoices were successfully set as complete';
        }
        this.showAlert(hasErrors);
      }
    });
  }

  private showAlert(isErrorMessage: boolean): void {
    this.isErrorMessage = isErrorMessage;
    this.showPageAlert = true;
  }

  // page control events
  protected OnSaveNote(dataItem: UpdateStatusNote) {
    // save to the correct queue type
    switch (this.queueType) {
      case 'Document':
        this.saveDocumentStatusNote(dataItem, 'documents');
        this.auditDocumentChild.removeSelections();
        break;

      case 'Customer':
        this.saveCustomerAuditStatusNote(dataItem);
        this.auditChild.removeSelections();
        break;

      case 'BG':
        this.saveBGAuditStatusNote(dataItem);
        this.auditBGChild.removeSelections();
        break;

      case 'Return':
        this.saveAuditStatusNote(dataItem, 'returns');
        this.auditReturnChild.removeSelections();
        break;

      case 'Return':
        this.saveAuditStatusNote(dataItem, 'secondary');
        this.auditSecondaryChild.removeSelections();
        break;

      case 'Quick Tariff':
        this.saveAuditStatusNote(dataItem, 'quick-tariffs');
        this.auditChild.removeSelections();
        break;

      case 'Carrier':
        this.saveAuditStatusNote(dataItem, 'carriers');
        this.auditCarrierChild.removeSelections();
        break;
    }

    // clear the section which will close the control
    this.selectedRecords = [];
  }
  protected onQueueChanged(newValue) {
    this.selectedQueue = newValue;
    this.queueType = this.selectedQueue.text;
    this.refreshPage = false;
    this.isSpecializedAudit = false;
    this.emitFiltersGridUpdate(false);
    this.loadPage();
  }
  protected onCarrierQueueChanged(newValue) {
    this.selectedCarrierQueue = newValue;
    this.refreshPage = false;
    this.loadPage();
  }
  protected OnRefresh() {
    this.refreshPage = true;
    this.emitFiltersGridUpdate(true);
    this.loadPage();
  }
  private cancelEmail() {
    this.showEmailGrid = false;
    this.selectedRecords = [];
  }
  private onSendEmails() {
    this.opened = false;
    // make sure the last record is saved
    this.auditEmailListGrid.updateRecord();
    this.showSpinner(true, 'Sending Emails...', true);

    // save the records
    this.updateDisputeEmails(this.createEmailList());
  }
  protected OnPrepareEmail() {
    if (this.startupService.hideActions) {
      this.openedOkDialog = true;
      return;
    }

    // copy the records
    this.recordEmailGrid = this.theCarrierRecords.reduce((records, item) => {
      if (this.selectedRecords.some((id) => item.id === +id)) {
        records.push(Object.assign({}, item)); // copy -- don't point
      }
      return records;
    }, [] as ActiveCarrierAuditResponse[]);
    this.showEmailGrid = true;
  }

  protected onSearchFilter(search: string = null): void {
    // filter the records
    if (search) {
      this.recordAuditGrid = this.theRecords.filter((obj) => this.GridFilter.filterQueue(obj, search, this.queueType));
      // this.recordAuditGrid = this.theRecords.filter(obj => this.filterDelegate(obj, search));
    } else {
      this.recordAuditGrid = this.theRecords;
    }

    this.goToFirstPage();
  }
  private filterDelegate(obj: any, search: string): boolean {
    search = search.toLowerCase().trim();

    return (
      (obj.varianceString != null && obj.varianceString.toString().toLowerCase().indexOf(search.split('$').join('')) > -1) ||
      (obj.invoiceDateString != null && obj.invoiceDateString.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.auditor != null && obj.auditor.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.owner != null && obj.owner.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.pro != null && obj.pro.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.primaryReference != null && obj.primaryReference.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.shipper != null && obj.shipper.toString().toLowerCase().indexOf(search) > -1 && this.queueType === 'Document') ||
      (obj.invoiceNumber != null && obj.invoiceNumber.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.scac != null && obj.scac.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.latestNote != null && obj.latestNote.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.billName != null && obj.billName.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.status != null && obj.status.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.errorType != null && obj.errorType.toString().toLowerCase().indexOf(search) > -1 && this.queueType === 'BG') ||
      (obj.errorReason != null && obj.errorReason.toString().toLowerCase().indexOf(search) > -1 && this.queueType === 'BG') ||
      (obj.settlementReason != null && obj.settlementReason.toString().toLowerCase().indexOf(search) > -1) ||
      (obj.consignee != null && obj.consignee.toString().toLowerCase().indexOf(search) > -1 && this.queueType === 'Document') ||
      (obj.queue != null && obj.queue.toString().toLowerCase().indexOf(search) > -1)
    );
  }

  // children event messages
  public OnViewDetails(dataItem) {
    if (this.startupService.hideActions) {
      this.openedOkDialog = true;
      return;
    }
    this.router.navigate([]).then((result) => {
      window.open(`/audit-edit-shipment/${dataItem.shipmentID}`, '_blank');
    });
  }
  public OnUploadDocuments(dataItem: ActiveAuditResponse) {
    this.uploadErrorText = '';
    this.uploadSuccessText = '';
    this.documentAuditResponseRecord = dataItem;
    this.open();
  }
  public OnAuditInvoice(dataItem: ActiveAuditResponse) {
    if (this.startupService.hideActions) {
      this.openedOkDialog = true;
      return;
    }

    this.currentURL = `/shipment-invoices/${dataItem.id}/${dataItem.shipmentID}/${dataItem.invoiceID}?owner=${dataItem.owner.replace(/%/g, '%25')}&pro=${dataItem.proActive}`;

    this.router.navigate([]).then((result) => {
      window.open(this.currentURL, '_blank');
    });
  }
  public OnRecordsSelected(recordList: string[]) {
    if (this.auditNoteStatusUpdateComponent && this.queueStatusList) {
      this.auditNoteStatusUpdateComponent.reset(recordList.length);
    }
    if (recordList.length > 0) {
      this.setDefaults(recordList.length);
    }
    this.selectedRecords = recordList;
  }
  private setDefaults(selectCount: number) {
    // do we need to get the record?
    if (selectCount > 1 && this.queueType !== 'Carrier') {
      return;
    }

    // get the record from the correct grid.
    // We ONLY call this is thier is one selected record except for carrier
    switch (this.queueType) {
      case 'Carrier':
        this.defaultUpdateStatusNote.selectedStatus = this.selectedCarrierQueue;
        break;

      case 'Document':
        this.defaultUpdateStatusNote.selectedStatus = this.auditDocumentChild.getSelectedRecords()[0].status;
        break;

      case 'Quick Tariff':
        this.defaultUpdateStatusNote.selectedStatus = this.auditChild.getSelectedRecords()[0].status;
        break;

      case 'BG':
        this.defaultUpdateStatusNote.selectedStatus = this.auditBGChild.getSelectedRecords()[0].status;
        this.defaultUpdateStatusNote.selectedErrorType = this.auditBGChild.getSelectedRecords()[0].errorType;
        this.defaultUpdateStatusNote.selectedContractReason = this.auditBGChild.getSelectedRecords()[0].errorReason;
        break;

      case 'Return':
        this.defaultUpdateStatusNote.selectedStatus = this.auditReturnChild.getSelectedRecords()[0].status;
        break;

      case 'Secondary':
        this.defaultUpdateStatusNote.selectedStatus = this.auditSecondaryChild.getSelectedRecords()[0].status;
        break;

      case 'Customer':
        let activeAuditResponse: ActiveAuditResponse;
        activeAuditResponse = this.auditChild.getSelectedRecords()[0];
        this.defaultUpdateStatusNote.selectedStatus = activeAuditResponse.status;
        this.defaultUpdateStatusNote.latestNote = this.auditChild.getSelectedRecords()[0].latestNote;
        this.defaultUpdateStatusNote.settlementReasonList =
          activeAuditResponse.settlementReason == null ? null : activeAuditResponse.settlementReason.split(',');
        break;
    }
    if (this.auditNoteStatusUpdateComponent) {
      this.auditNoteStatusUpdateComponent.defaults(this.defaultUpdateStatusNote, selectCount);
    }
  }

  // helpers
  private initialize() {
    this.showEmailGrid = false;
    this.showQueues = false;
    this.isDataloading = false;
    this.recordCount = 0;
    this.selectedRecords = [];
    this.errorTypeList = [];
    this.contractReasonList = [];
    this.settlementReasonList = [];
    this.theRecords = [];
    this.theCarrierRecords = [];
  }
  private hideGrids() {
    this.showDocumentGrid = false;
    this.showBGAuditGrid = false;
    this.showReturnAuditGrid = false;
    this.showSecondaryAuditGrid = false;
    this.showAuditGrid = false;
    this.showCarrierGrid = false;
    this.showCheckboxes = true;
  }
  private showSpinner(show: boolean, text: string = '', modal: boolean = false) {
    this.appSpinner.loading = show;
    this.appSpinner.modal = modal;
    this.appSpinner.text = text;
  }
  private clearRecordset() {
    this.recordAuditGrid = [];
  }

  private setQueueType() {
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    this.queueType = this.route.snapshot.paramMap.get('queuetype');
    this.queueType = this.queueType !== null ? this.helpers.upperCaseFirst(this.queueType) : 'Proactive';
    if (this.queueList && this.queueList.length > 0) {
      let queueSelected = this.queueList.find((ql) => ql.text === this.queueType);

      if (queueSelected) this.selectedQueue = { text: queueSelected.text.toString(), value: queueSelected.value.toString() };
    }
  }
  private createEmailList() {
    const sendCarrierDisputeEmailList = [];
    for (const record of this.recordEmailGrid) {
      const sendCarrierDisputeEmailRecord = {
        shipmentID: record.shipmentID,
        invoiceAuditID: record.id,
        enterpriseName: record.owner,
        primaryReference: record.primaryReference,
        invoiceNumber: record.invoiceNumber,
        scac: record.scac,
        cleanedNote: record.aTeamNote,
        status: record.status,
      };

      // add to list
      sendCarrierDisputeEmailList.push(sendCarrierDisputeEmailRecord);
    }
    return sendCarrierDisputeEmailList;
  }

  get cacheKey() {
    return this.queueType === undefined || this.queueType === null ? '' : this.queueType.replace(' ', '').trim();
  }

  get cacheKeyGrid() {
    return this.queueType === undefined || this.queueType === null ? '' : this.queueType.replace(' ', '').trim() + '-gridSettings';
  }

  get canSendEmail(): boolean {
    return this.recordEmailGrid.every((record) => !!record.aTeamNote);
  }

  private loadRecords() {
    this.isDataloading = true;
    this.showSettlementReasons = false;
    switch (this.queueType) {
      case 'Document':
        this.queueStatusList = this.invoiceAuditReferences.docsAuditStatus;
        this.isProactiveOrNonProactive = false;
        this.loadDocumentQueue();
        break;

      case 'Quick Tariff':
        this.queueStatusList = this.invoiceAuditReferences.quickTariffAuditStatus;
        this.isProactiveOrNonProactive = false;
        this.loadAuditQueue('quick-tariffs');
        break;

      case 'Customer':
        this.queueStatusList = this.invoiceAuditReferences.customerAuditStatus;
        this.settlementReasonList = this.invoiceAuditReferences.settlementReasons;
        this.showSettlementReasons = true;
        this.isProactiveOrNonProactive = false;
        this.loadAuditQueue('customers');
        break;

      case 'BG':
        this.queueStatusList = this.invoiceAuditReferences.bgAuditStatus;
        this.errorTypeList = this.invoiceAuditReferences.errorTypes;
        this.contractReasonList = this.invoiceAuditReferences.contractReason;
        this.isProactiveOrNonProactive = false;
        this.loadAuditBGQueue('bgs');
        break;

      case 'Carrier':
        this.queueStatusList = this.invoiceAuditReferences.carrierAuditStatus;
        this.isProactiveOrNonProactive = false;
        this.loadCarrierQueue();
        break;

      case 'Return':
        this.showCheckboxes = false;
        this.isProactiveOrNonProactive = false;
        this.loadAuditReturnQueue('returns');
        break;

      case 'Secondary':
        this.showCheckboxes = true;
        this.isProactiveOrNonProactive = false;
        this.loadAuditSecondaryQueue('secondaries');
        break;

      case 'Proactive':
        this.showCheckboxes = true;
        this.isProactiveOrNonProactive = true;
        this.loadAuditQueue('proactive');
        break;

      case 'Non-Proactive':
        this.showCheckboxes = true;
        this.isProactiveOrNonProactive = true;
        this.loadAuditQueue('nonproactive');
        break;
    }

  }
  private goToFirstPage() {
    // hide the note adding if we reset the grids
    this.selectedRecords = [];

    switch (this.queueType) {
      case 'Document':
        this.auditDocumentChild.firstPage();
        break;
      case 'BG':
        this.auditBGChild.firstPage();
        break;
      case 'Return':
        this.auditReturnChild.firstPage();
        break;
      case 'Secondary':
        this.auditSecondaryChild.firstPage();
        break;
      case 'Customer':
      case 'Quick Tariff':
      case 'Proactive':
      case 'Non-Proactive':
        this.auditChild.firstPage();
        break;
      case 'Carrier':
        this.auditCarrierChild.firstPage();
        break;
    }
  }
  private handleError(err) {
    this.isDataloading = false;
    if (err.statusText !== 'Not Found') {
      this.alertMessage.showAlertMessage('Error getting the ' + this.queueType + ' queue.', 'Error');
    }
  }
  private onDocumentClick(e: any): void {
    if (this.showEmailGrid) {
      this.auditEmailListGrid.documentClick(e);
    }
  }

  // save functions
  private saveAuditStatusNote(dataItem: UpdateStatusNote, endpoint: string) {
    // loop for all selected records
    const saveList: SaveAuditStatusNote[] = [];
    const removeList: ActiveAuditResponse[] = [];
    for (const id of this.selectedRecords) {
      // get the record in the grid
      const gridRecord = this.recordAuditGrid.filter((obj) => obj.id === +id);
      const record = this.createAuditObject(+id, gridRecord[0].shipmentID, dataItem);

      // did they choose a status
      if (record.status == null) {
        record.status = gridRecord[0].status;
      }

      if (endpoint !== 'carrier') {
        // update the record in the grid or remove it?
        if (dataItem.selectedStatus === 'Complete' || dataItem.selectedStatus === 'Return') {
          this.recordCount = this.recordCount - 1;
          removeList.push(gridRecord[0]);
        } else {
          gridRecord[0].latestNote = dataItem.textNotes;
          gridRecord[0].status = dataItem.selectedStatus;
        }
      } else {
        const shouldRemove = this.selectedCarrierQueue !== dataItem.selectedStatus ? true : false;
        shouldRemove && (removeList.push(gridRecord[0]), (this.recordCount = this.recordCount - 1));
      }
      // add to list
      saveList.push(record);
    }

    // remove any completed records
    this.recordAuditGrid = this.recordAuditGrid.filter(function (el) {
      return !removeList.includes(el);
    });

    // force the grid to update. TODO - If we dont do this the Grid doesnt realize the data has changed.
    const newArray = [...this.recordAuditGrid];
    this.recordAuditGrid = newArray;
    // Update the records
    this.updateAuditStatusNotes(saveList, endpoint);
  }

  private createAuditObject(id: number, shipmentID: number, dataItem: UpdateStatusNote) {
    return {
      invoiceAuditID: +id,
      shipmentID: shipmentID,
      note: dataItem.textNotes,
      status: dataItem.selectedStatus,
    };
  }

  private saveBGAuditStatusNote(dataItem: UpdateStatusNote) {
    // loop for all selected records
    const saveList: SaveBGAuditStatusNote[] = [];
    const removeList: ActiveAuditResponse[] = [];
    for (const id of this.selectedRecords) {
      // get the record in the grid
      const gridRecord = this.recordAuditGrid.filter((obj) => obj.id === +id);
      const record = {
        invoiceAuditID: +id,
        shipmentID: gridRecord[0].shipmentID,
        note: dataItem.textNotes,
        status: dataItem.selectedStatus,
        errorType: dataItem.selectedErrorType,
        contractReason: dataItem.selectedContractReason,
      };

      // did they choose a status
      if (record.status == null) {
        record.status = gridRecord[0].status;
      }

      // update the record in the grid or remove it?
      if (dataItem.selectedStatus === 'Complete') {
        removeList.push(gridRecord[0]);
      } else {
        gridRecord[0].latestNote = dataItem.textNotes;
        gridRecord[0].status = dataItem.selectedStatus;
      }
      // add to the list
      saveList.push(record);
    }

    // remove any completed records
    this.recordAuditGrid = this.recordAuditGrid.filter(function (el) {
      return !removeList.includes(el);
    });

    // force the grid to update. TODO - If we dont do this the Grid doesnt realize the data has changed.
    const newArray = [...this.recordAuditGrid];
    this.recordAuditGrid = newArray;
    // Update the records
    this.updateBGAuditStatusNotes(saveList);
  }
  private saveCustomerAuditStatusNote(dataItem: UpdateStatusNote) {
    // loop for all selected records
    const saveList: SaveCustomerAuditStatusNote[] = [];
    const removeList: ActiveAuditResponse[] = [];
    const reasonSelect = dataItem.settlementReasonList.length > 0;

    for (const id of this.selectedRecords) {
      const gridRecord = this.recordAuditGrid.filter((obj) => obj.id === +id);
      let textNote = dataItem.textNotes;
      let settlementReasons = dataItem.settlementReasonList;

      if (dataItem.selectedStatus === 'Complete') {
        if (!textNote) textNote = gridRecord[0].latestNote || gridRecord[0].settlementReason;
        if (!reasonSelect) settlementReasons = gridRecord[0].settlementReason.split(',');
      }

      const record = {
        invoiceAuditID: +id,
        shipmentID: gridRecord[0].shipmentID,
        note: textNote,
        status: dataItem.selectedStatus,
        settlementReasons: settlementReasons,
      };

      // did they choose a status
      if (record.status == null) {
        record.status = gridRecord[0].status;
      }

      // update the record in the grid or remove it?
      if (dataItem.selectedStatus === 'Complete') {
        removeList.push(gridRecord[0]);
      } else {
        gridRecord[0].latestNote = dataItem.textNotes;
        gridRecord[0].status = dataItem.selectedStatus;
      }
      // add to list
      saveList.push(record);
    }

    // remove any completed records
    this.recordAuditGrid = this.recordAuditGrid.filter(function (el) {
      return !removeList.includes(el);
    });

    // force the grid to update. TODO - If we dont do this the Grid doesnt realize the data has changed.
    const newArray = [...this.recordAuditGrid];
    this.recordAuditGrid = newArray;

    this.updateCustomerStatusNotes(saveList);
  }
  private saveDocumentStatusNote(dataItem: UpdateStatusNote, endpoint: string) {
    // loop for all selected records
    const saveList: SaveAuditStatusNote[] = [];
    const removeList: ActiveAuditResponse[] = [];
    for (const id of this.selectedRecords) {
      // get the record in the grid
      const gridRecord = this.recordAuditGrid.filter((obj) => obj.invoiceID === +id);
      const record = this.createAuditObject(gridRecord[0].id, gridRecord[0].shipmentID, dataItem);

      // did they choose a status
      if (record.status == null) {
        record.status = gridRecord[0].status;
      }

      if (endpoint !== 'carrier') {
        // update the record in the grid or remove it?
        if (dataItem.selectedStatus === 'Complete' || dataItem.selectedStatus === 'Return') {
          this.recordCount = this.recordCount - 1;
          removeList.push(gridRecord[0]);
        } else {
          gridRecord[0].latestNote = dataItem.textNotes;
          gridRecord[0].status = dataItem.selectedStatus;
        }
      } else {
        const shouldRemove = this.selectedCarrierQueue !== dataItem.selectedStatus ? true : false;
        shouldRemove && (removeList.push(gridRecord[0]), (this.recordCount = this.recordCount - 1));
      }
      // add to list
      saveList.push(record);
    }

    // remove any completed records
    this.recordAuditGrid = this.recordAuditGrid.filter(function (el) {
      return !removeList.includes(el);
    });

    // force the grid to update. TODO - If we dont do this the Grid doesnt realize the data has changed.
    const newArray = [...this.recordAuditGrid];
    this.recordAuditGrid = newArray;
    // Update the records
    this.updateAuditStatusNotes(saveList, endpoint);
  }

  private updateDisputeEmails(saveList: SendCarrierDisputeEmail[]) {
    this.theRecords = [];
    this.recordAuditGrid = [];
    this.recordCount = 0;

    this.pcService.updateDisputeEmails(saveList, this.selectedCarrierQueue === 'New').subscribe(
      (responseList) => {
        // once we send the send the email
        // reload main carrier grid to show it was sent
        // and hide email grid

        this.showSpinner(false);
        if (this.showEmailGrid) {
          this.showEmailGrid = false;
          this.selectedRecords = [];
          this.loadRecords(); // reload the records to get the real notes again
        }
      },
      (error) => {
        this.showSpinner(false);
        this.alertMessage.showAlertMessage('Error updating the selected records.', 'Error');
      }
    );
  }
  private updateAuditStatusNotes(saveList: SaveAuditStatusNote[], endpoint: string) {
    this.pcService.updateAuditStatusNotes(saveList, endpoint).subscribe(
      (responseList) => {
        this.selectedRecords = [];
        this.alertMessage.showAlertMessage('Sent Successfully', 'Success');
        this.loadRecords(); // reload the records to get the real notes again

        if (this.showEmailGrid) {
          this.showEmailGrid = false;
        }
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error updating the selected records.', 'Error');
      }
    );
  }
  private updateBGAuditStatusNotes(saveList: SaveBGAuditStatusNote[]) {
    this.pcService.updateBGAuditStatusNotes(saveList).subscribe(
      (responseList) => {
        this.selectedRecords = [];
        this.alertMessage.showAlertMessage('Saved Successfully.', 'Success');
        this.loadRecords(); // reload the records to get the real notes again
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error updating the selected records.', 'Error');
      }
    );
  }
  private updateCustomerStatusNotes(saveList: SaveCustomerAuditStatusNote[]) {
    this.pcService.updateCustomerAuditStatusNotes(saveList).subscribe(
      (responseList) => {
        this.selectedRecords = [];
        this.alertMessage.showAlertMessage('Saved Successfully.', 'Success');
        this.loadRecords(); // reload the records to get the real notes again
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error updating the selected records.', 'Error');
      }
    );
  }

  private loadDocumentQueue() {
    this.showDocumentGrid = true;

    // load the records
    this.pcService.getDocumentQueue().subscribe(
      (responseList) => {
        if (responseList && Array.isArray(responseList)) {
          let dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setDocumentModelExcel(responseList);
          this.dataExport = ConvertJSONToCSVHelper(dataModel)

          this.theRecords = responseList;
          this.recordAuditGrid = responseList;
          this.recordCount = responseList.length;
          this.isDataloading = false;
          this.alertMessage.hideAlertMessage();
          if (!this.refreshPage) {
            this.goToFirstPage();
          }
          this.storageAutoComplete.reset();
        }
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  private loadCarrierQueue() {
    this.showCarrierGrid = true;

    // load the records
    this.pcService.getCarrierQueue(this.selectedCarrierQueue).subscribe(
      (responseList) => {
        if (responseList && Array.isArray(responseList)) {
          let dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setCarrierModelExcel(responseList);
          this.dataExport = ConvertJSONToCSVHelper(dataModel)

          this.theRecords = responseList;
          this.theCarrierRecords = responseList;
          this.recordAuditGrid = responseList;
          this.recordCount = responseList.length;
          this.isDataloading = false;
          this.auditCarrierChild.removeSelections();
          this.alertMessage.hideAlertMessage();
          if (!this.refreshPage) {
            this.goToFirstPage();
          }

          this.storageAutoComplete.reset();
        }
      },
      (err) => {
        this.handleError(err);
      }
    );
  }
  private loadAuditBGQueue(endpoint: string) {
    this.showBGAuditGrid = true;
    this.loadAuditQueue(endpoint);
    this.showAuditGrid = false;
  }
  private loadAuditReturnQueue(endpoint: string) {
    this.showReturnAuditGrid = true;
    this.loadAuditQueue(endpoint);
    this.showAuditGrid = false;
  }

  private loadAuditSecondaryQueue(endpoint: string) {
    this.showSecondaryAuditGrid = true;
    this.loadAuditQueue(endpoint);
    this.showAuditGrid = false;
  }

  private loadAuditQueue(endpoint: string) {
    this.showAuditGrid = true;

    // load the records
    this.pcService.getAuditQueue(endpoint).subscribe(
      (responseList) => {
        if (responseList && Array.isArray(responseList)) {
          this.theRecords = responseList;
          let dataModel;

          switch (endpoint) {
            case "quick-tariffs":
              dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setQuickTariffModelExcel(responseList);
              break;
            case "bgs":
              dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setBGModelExcel(responseList);
              break;
            case "returns":
              dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setReturnModelExcel(responseList);
              break;
            case "secondaries":
              dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setSecundaryModelExcel(responseList);
              break;
            default:
              if (endpoint !== "proactive")
                dataModel = AuditCarrierInvoiceUI.CarrierInvoiceAudit.setProactiveModelExcel(responseList);
              break;
          }

          if (dataModel) {
            this.dataExport = ConvertJSONToCSVHelper(dataModel);
          }


          this.recordAuditGrid = responseList;
          this.responseList = responseList;
          this.alertMessage.hideAlertMessage();

          if (endpoint == 'proactive' || endpoint == 'nonproactive') {
            this.specializedAudit(this.isSpecializedAudit);
          }
          else {
            this.recordCount = responseList.length;

          }



          if (!this.refreshPage) {
            this.goToFirstPage();
          }
          this.isDataloading = false;
          this.storageAutoComplete.reset();
        }
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  private loadReferences() {
    this.pcService.getAuditReferences().subscribe(
      (responseList) => {
        this.invoiceAuditReferences = responseList;
        this.carrierQueueList = this.invoiceAuditReferences.carrierAuditStatus.filter((e) => e !== 'Return' && e !== 'Complete');
        this.loadPage();
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error loading the reference records', 'Error');
      }
    );
  }

  // upload documents
  private dropdownChangeHandler(event) {
    const resultValue = this.documentList.filter((word) => word.value === event);
    this.documentType.text = resultValue[0].text.toString();
    this.documentType.value = resultValue[0].value.toString();
  }
  public close(status) {
    this.openedDialog = false;
    this.documentType.text = '';
    this.documentType.value = '';
    this.documentFiles = [];
  }
  public open() {
    this.selectedItem = '';
    this.openedDialog = true;
  }
  public openCofirm() {
    this.opened = true;
  }
  public closeConfirm() {
    this.opened = false;
  }
  public closeOkConfirm() {
    this.openedOkDialog = false;
  }
  selectDocumentEventHandler(event: SelectEvent): void {
    // stop auto upload
    event.preventDefault();

    const extensions = event.files.map((file) => file.extension);
    if (!this.helpers.hasAllowedExtensionsOnly(extensions)) {
      const { allowedExtensions } = Globals.UploadFileRestrictions;
      this.uploadErrorText = `Invalid file type selected. Allowed file types: ${allowedExtensions.join(', ')}`;
      return;
    }

    // loop for all files
    event.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        const reader = new FileReader();

        // called when done reading in the file
        reader.onloadend = () => {
          // create the object
          const base64String = reader.result.toString();
          const documentObj = {
            type: this.selectedItem,
            name: file.name.replace(/\.[^/.]+$/, ''),
            format: Globals.AllowedExtensionsFormatMap[file.extension.toLowerCase()],
            source: 'Api',
            shipmentId: this.documentAuditResponseRecord.shipmentID,
            base64Data: base64String.substr(base64String.indexOf(',') + 1),
          } as ShipmentDocumentUploadDTO;

          this.uploadDocument(documentObj, file, reader);
        };

        // read the data
        reader.readAsDataURL(file.rawFile);
      }
    });
  }
  // service calls
  uploadDocument(documentObj: ShipmentDocumentUploadDTO, file: FileInfo, reader: FileReader) {
    this.uploadErrorText = '';
    this.uploadSuccessText = '';
    this.pcShipmentService.uploadDocument(documentObj).subscribe(
      (response) => {
        // call the endpoint to save the document
        this.documentFiles.push({ ...file, src: <string>reader.result });
        this.documentFiles = [...this.documentFiles];

        // emit that it was successful
        this.documentuploadTLbtn.success.emit();
        this.uploadSuccessText = `This file ${file.name} was successfully uploaded.`;
      },
      (err) => {
        if (err.status === 409) {
          this.uploadErrorText = 'This document already exists for this invoice / type.';
        } else {
          if (err.error.errors !== undefined) {
            this.uploadErrorText = err.error.errors.type[0];
          } else {
            this.uploadErrorText = err.error;
          }
        }
      }
    );
  }

  public exportExcel(): void {
    Helpers.helpersDownloadExportData(this.dataExport, `${this.queueType} `);
  }

  public linkSearch(event) {
    this.onSearchFilter(event);
  }

  public specializedAudit(event: boolean): void {
    this.storageAutoComplete.reset();
    this.isDataloading = true;

    if (event) this.isSpecializedAudit = true;
    else this.isSpecializedAudit = false;

    this.filterSpecializedAudit(this.isSpecializedAudit);
  }

  private filterSpecializedAudit(isSpecializedAudit: boolean): void {
    this.pcService.getSpecializedAudit().subscribe((data) => {
      let proactive: ActiveAuditResponse[] = [];
      let proactiveSpecialize: ActiveAuditResponse[] = [];
      this.specializedAuditUser = data.filter((data) => data.isSpecializedAudit);

      this.responseList.forEach((audit) => {
        if (this.specializedAuditUser.find((user) => audit.enterpriseID === user.enterpriseID)) proactiveSpecialize.push(audit);
        else proactive.push(audit);
      });

      this.isDataloading = false;
      this.theRecords = this.recordAuditGrid = isSpecializedAudit ? proactiveSpecialize : proactive;
      this.dataExport = ConvertJSONToCSVHelper(AuditCarrierInvoiceUI.CarrierInvoiceAudit.setProactiveModelExcel(this.theRecords));
      this.recordCount = this.recordAuditGrid.length;
    });
  }

  public showMoveSecondaryInvoiceTypeModal(): void {
    this.showMoveSecondaryInvoiceTypeDialog = true;
    this.invoiceCategoryToMove = '';
  }

  public showMassAssignaAuditorModal(): void {
    this.showMassAssignAuditorDialog = true;
    this.selectedAuditor = { fullName: '', id: 0 };
  }

  private closeMoveSecondaryInvoiceTypeModal(): void {
    this.showMoveSecondaryInvoiceTypeDialog = false;
    this.invoiceCategoryToMove = '';
  }

  private closeMassAssignAuditorModal(): void {
    this.showMassAssignAuditorDialog = false;
    this.selectedAuditor = { fullName: '', id: 0 };
  }

  public refreshrecords(): void {
    this.loadRecords(); // reload the records to get the real records again
    this.isDataloading = true;
    this.selectedRecords = [];
  }

  public moveSecondaryCategory(): void {
    this.isDataloading = true;
    let secondaryInvoiceList = [];
    let time = 4000;

    this.selectedRecords.forEach((invoiceID) => {
      secondaryInvoiceList.push(parseNumber(invoiceID));
    });

    this.invoiceService.MassMoveSecondaryInvoiceCategory(secondaryInvoiceList, this.invoiceCategoryToMove)
      .pipe(
        finalize(() => {
          asyncScheduler.schedule(() => this.refreshrecords(), 2000);
        }))
      .subscribe(
        response => {
          if (response.badInvoiceIDs.length > 0) {
            this.alertMessage.showAlertMessage('This action was performed partially, the next object(s) got error(s): ' +
              '{ IDs: ' + response.badInvoiceIDs.join(', ') + " } " + response.errorMessage, 'Warning');
          } else {
            this.alertMessage.showAlertMessage('Sent Successfully', 'Success');
            time = 300;
          }

        },
        err => {
          asyncScheduler.schedule(() => this.isDataloading = false, 3500);
          this.alertMessage.showAlertMessage(err.error, 'Error',);
        });

    this.closeMoveSecondaryInvoiceTypeModal();
  }

  public massAssignAuditor(): void {
    this.isDataloading = true;
    let invoiceList = [];
    let time = 4000;

    this.selectedRecords.forEach((invoiceID) => {
      invoiceList.push(parseNumber(invoiceID));
    });

    this.invoiceService.massAssignAuditor(invoiceList, this.selectedAuditor.fullName)
      .pipe(
        finalize(() => {
          asyncScheduler.schedule(() => this.refreshrecords(), 2000);
        }))
      .subscribe(
        response => {
          if (response.badInvoiceIDs.length > 0) {
            this.alertMessage.showAlertMessage('This action was performed partially, the next object(s) got error(s): ' +
              '{ IDs: ' + response.badInvoiceIDs.join(', ') + " } " + response.errorMessage, 'Warning');
          } else {
            this.alertMessage.showAlertMessage('Sent Successfully', 'Success');
            time = 300;
          }

        },
        err => {
          asyncScheduler.schedule(() => this.isDataloading = false, 3500);
          this.alertMessage.showAlertMessage(err.error, 'Error',);
        });

    this.closeMassAssignAuditorModal();
  }

  public invoiceCategorySelectionChange(event: any): void {
    this.invoiceCategoryToMove = event;
  }

  public auditorSelectionChange(event: any): void {
    this.selectedAuditor = event;
  }

  private loadAuditorList() {
    // load the records
    this.invoiceService.getAuditors().subscribe(
      (data) => {
        this.auditorList = data;
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  protected clickAssignToMe() {
    const filteredAuditor = this.auditorList.filter((obj) => obj.fullName === this.authService.BlueShipUser.name)[0];

    if (filteredAuditor == null) {
      this.alertMessage.showAlertMessage('You are not found in the Auditor list', 'Error');
      return;
    }

    this.selectedAuditor = filteredAuditor;
    this.massAssignAuditor();
  }
}
