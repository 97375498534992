import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProactiveService } from '../../services/proactive.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuthService } from '../../auth/auth.service';
import { ManualProactiveResponse, SelectManualProactive } from '../../models/Proactive';
import { ManualProactiveListComponent } from '../../components/manual-proactive-list/manual-proactive-list.component';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { Observable } from 'rxjs';
import { ShipmentService } from '@/services/shipment.service';
import { ShipmentHistoryService } from '@/services/shipment-history.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-manual-proactive',
  templateUrl: './manual-proactive.component.html',
  styleUrls: ['./manual-proactive.component.scss'],
})
export class ManualProactiveComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(ManualProactiveListComponent, { static: true })
  manualProactiveListComponent: ManualProactiveListComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  selectedRecord = new ManualProactiveResponse();
  radioItems: string[] = [];
  recordBook: ManualProactiveResponse[];

  model = { option: '' };
  isDataLoading = true;
  recordCount = 0;
  chooseRatePopup = false;
  ratePopupLoading = false;

  constructor(private pcProactiveService: ProactiveService, private shipmentHistoryService: ShipmentHistoryService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.loadRecords();
  }

  // events
  protected OnViewDetails(dataItem: ManualProactiveResponse) {
    this.router.navigate([]).then((result) => {
      window.open(`/shipment-history?shipmentID=${dataItem.shipmentID}`, '_blank');
    });
  }
  protected OnSelectRate(dataItem: ManualProactiveResponse) {
    this.selectedRecord = dataItem;
    this.chooseRatePopup = true;
    this.ratePopupLoading = true;
    this.initChooseRate().subscribe(() => this.ratePopupLoading = false);
  }
  protected closeChooseRate() {
    this.model.option = '';
    this.chooseRatePopup = false;
  }
  protected chooseRate() {
    this.chooseRatePopup = false;
    const chosenRate = this.selectedRecord.rates.filter((filterid) => filterid.id.toString() === this.model.option);
    const selectManualProactiveRate = new SelectManualProactive(chosenRate[0], this.selectedRecord);

    // remove it from the server
    this.selectManualRate(selectManualProactiveRate);
    this.model.option = '';
  }

  // helpers
  private loadRecords() {
    this.pcProactiveService.getManualProactive().subscribe(
      (responseList) => {
        this.recordBook = responseList;
        this.recordCount = responseList.length;
        this.isDataLoading = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.alertMessage.showAlertMessage('Error Loading Proactive Holds.', 'Error');
      }
    );
  }

  private removeRecords(chosenRate: SelectManualProactive) {
    this.manualProactiveListComponent.removeRecord(chosenRate.id);
  }

  private initChooseRate(): Observable<void> {
    return this.shipmentHistoryService.getQuoteHistory(this.selectedRecord.shipmentID)
      .pipe(map(data => {
        this.selectedRecord.rates = data.quotes.map(quote => ({
          id: data.shipmentID,
          quoteID: quote.cost.quoteID,
          analagousID: quote.analogousID,
          isSelected: quote.isSelected,
          isOriginalSelected: quote.originalSelected,
          quoteTotal: quote.cost.total,
          total: quote.cost.total,
          userCreated: quote.createdBy,
          dateCreated: quote.updatedDate,
          scac: quote.scac,
          carrierName: quote.carrier,
          quoteDate: quote.quoteDate,
          radioButtonItem: ''
        }));
        
        this.selectedRecord.rates.forEach(rate => rate.radioButtonItem = rate.toString());
        this.radioItems = [...this.selectedRecord.rates.map(c => c.id.toString())];
      }))
  }

  // service calls
  private selectManualRate(chosenRate: SelectManualProactive) {
    this.appSpinner.loading = true;
    this.pcProactiveService.selectManualHold(chosenRate).subscribe(
      (data) => {
        this.removeRecords(chosenRate);
        this.recordCount--;
        this.alertMessage.showAlertMessage('Rate Updated Successfully', 'Success');
        this.appSpinner.loading = false;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Chosing Rate', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }
}
