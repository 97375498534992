import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TruckloadAuditReasonUI } from '@/pages/tl-audit/models/TruckloadAudit.ui';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { SaveReasonStatus, AddComment } from '../../actions/tl-audit-detail.actions';
import { NewComment, UpdateIncident } from '../../models/TLAudit';
import { AuthService } from '@/auth/auth.service';

@Component({
  selector: 'app-tl-reason-comment',
  templateUrl: './tl-reason-comment.component.html',
  styleUrls: ['./tl-reason-comment.component.scss'],
})
export class TlReasonCommentComponent implements OnInit {
  @Input('reason')
  reason: TruckloadAuditReasonUI;

  tlAuditReasonStatus = [
    { text: 'New', value: 'New' },
    { text: 'Complete', value: 'Complete' },
  ];

  toggled: boolean = false;
  reasonCommentForm: FormGroup;
  hideComment = true;
  originalReasonStatus: string;
  reasonID: number;
  actionBtnDisabled = false;
  userName: string;
  constructor(private fb: FormBuilder, private authService: AuthService, private store: Store<AppState>) { }

  ngOnInit() {
    this.userName = this.authService.BlueShipUser.userName;
    this.reasonID = this.reason.reasonID;
    this.originalReasonStatus = this.reason.status;
    this.createForm();
    this.setListeners();
  }

  public save() {
    const origStatus = (this.originalReasonStatus || '').trim().toLowerCase();
    const reasonStatus = (this.reasonCommentForm.get('reasonStatusDropDown').value || '').trim().toLowerCase();
    const reasonComment = (this.reasonCommentForm.get('reasonComment').value || '').trim();
    const { reasonID, shipmentID } = this.reason;
    const userName = this.userName;

    if (reasonStatus !== origStatus) {
      const isComplete = reasonStatus === 'complete';
      this.store.dispatch(
        new SaveReasonStatus(
          new UpdateIncident(
            reasonID,
            shipmentID,
            reasonComment,
            isComplete,
            userName
          )
        )
      );
    } else {
      if (reasonComment.length > 0) {
        this.store.dispatch(
          new AddComment(new NewComment(this.reasonID, reasonComment, userName))
        );
      }
    }
  }

  protected toggleGrid(e: MouseEvent): void {
    e.preventDefault();
    this.toggled = !this.toggled;
  }

  public resetForm() {
    this.reasonCommentForm.reset({
      reasonStatusDropDown: this.reason.status,
      reasonComment: '',
    });
    this.hideComment = true;
  }

  protected selectionChange(value: any): void {
    this.hideComment = false;
  }

  private createForm() {
    this.reasonCommentForm = this.fb.group({
      reasonStatusDropDown: [this.reason.status],
      reasonComment: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
    });
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailState.manageReasonsSaveInProgress)
      .subscribe((x) => {
        this.actionBtnDisabled = x;
      });
    this.store
      .select((x) => x.TLAuditDetailState.updateReasonSuccess)
      .subscribe((x) => {
        if (x) {
          this.originalReasonStatus = this.reason.status;
          this.resetForm();
        }
      });
    this.store
      .select((x) => x.TLAuditDetailState.addCommentSuccess)
      .subscribe((x) => {
        if (x) {
          this.resetForm();
        }
      });
  }
}
