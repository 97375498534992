import {
  QuoteRequest,
  Item as QuoteItem,
  Rate as BgRate,
  Charge as BgCharge,
  QuoteResponse,
  QuoteMarkupRequest,
  UpliftRequest,
  RateToMarkup,
  MarkupCharge,
  Item,
  Charge,
} from '../models/BgRating';
import { Rate } from '../models/ShipmentInvoiceAudit';
import { ShipmentUI } from '../pages/audit/Shipment.ui';
import { AuditItemListComponent } from './shipping-edit/audit-item-list/audit-item-list.component';
import { ShipmentInvoiceAuditUI as ui } from '@/pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';

export class BgRatingHelper {
  readonly Linehaul = 'linehaul';
  readonly Accessorial = 'accessorial';

  createQuoteRequest(uiShipment: ShipmentUI.Shipment, shipment: any, auditItemListComponent: AuditItemListComponent, rateShipmentAll: boolean = false): QuoteRequest {
    const request = <QuoteRequest>{
      accountNumber: uiShipment.accountNumber,
      originPostal: uiShipment.origin.postalCode,
      originStateProvince: uiShipment.origin.stateProvince,
      originCity: uiShipment.origin.city,
      originCountry: uiShipment.origin.countryCode,
      destinationCountry: uiShipment.destination.countryCode,
      destinationPostal: uiShipment.destination.postalCode,
      destinationStateProvince: uiShipment.destination.stateProvince,
      destinationCity: uiShipment.destination.city,
      shipmentDate: shipment.addresses[0].actualDate || shipment.addresses[0].earliestDate,
      carriers: rateShipmentAll ? [] : [uiShipment.scac, 'BENCHMARK'],
      services: [...uiShipment.getAccessorialCodes()],
      items: this.mapItems(auditItemListComponent),
      options: {
        skipMarkup: false,
        skipSave: false,
        skipDistance: false,
        skipFilter: false,
        skipDynamicLtlRating: false,
        skipLtlRating: false,
        skipVolumeRating: false,
        includeProposal: false,
      },
    };

    return request;
  }

  createQuoteMarkupRequest(
    parameters: {
      scac: string;
      accountNumber: string;
      originCountryCode: string;
      originPostalCode: string;
      originStateProvince: string;
      destinationCountryCode: string;
      destinationPostalCode: string;
      destinationStateProvince: string;
      shipmentDate: Date;
      mode: string;
      currencyCode: string;
    },
    items: ShipmentUI.ItemUI[],
    charges: MarkupCharge[]
  ) {
    return <QuoteMarkupRequest>{
      accountNumber: parameters.accountNumber,
      originCountry: parameters.originCountryCode,
      originPostal: parameters.originPostalCode,
      originStateProvince: parameters.originStateProvince,
      destinationCountry: parameters.destinationCountryCode,
      destinationPostal: parameters.destinationPostalCode,
      destinationStateProvince: parameters.destinationStateProvince,
      shipmentDate: parameters.shipmentDate,
      items: items.map(
        (item) =>
          <Item>{
            freightClass: item.freightClass.toString(),
            height: item.height,
            length: item.length,
            width: item.width,
            itemUnitType: item.quantityUOM,
            quantity: item.quantity,
            weight: item.weight,
          }
      ),
      ratesToMarkup: [
        <RateToMarkup>{
          description: 'Standard LTL',
          scac: parameters.scac,
          mode: parameters.mode,
          charges: charges,
          currencyCode: parameters.currencyCode
        },
      ],
    };
  }

  createUpliftRequest(
    parameters: {
      scac: string;
      accountNumber: string;
      originCountryCode: string;
      originPostalCode: string;
      originStateProvince: string;
      destinationCountryCode: string;
      destinationPostalCode: string;
      destinationStateProvince: string;
      upliftAmount: number;
      upliftType: string;
      shipmentDate: Date;
      mode: string;
      currencyCode: string;
    },
    items: ShipmentUI.ItemUI[],
    charges: MarkupCharge[]
  ) {
    return <UpliftRequest>{
      accountNumber: parameters.accountNumber,
      originCountry: parameters.originCountryCode,
      originPostal: parameters.originPostalCode,
      originStateProvince: parameters.originStateProvince,
      destinationCountry: parameters.destinationCountryCode,
      destinationPostal: parameters.destinationPostalCode,
      destinationStateProvince: parameters.destinationStateProvince,
      upliftAmount: parameters.upliftAmount,
      upliftType: parameters.upliftType,
      shipmentDate: parameters.shipmentDate,
      items: items.map(
        (item) =>
          <Item>{
            freightClass: item.freightClass.toString(),
            height: item.height,
            length: item.length,
            width: item.width,
            itemUnitType: item.quantityUOM,
            quantity: item.quantity,
            weight: item.weight,
          }
      ),
      ratesToMarkup: [
        <RateToMarkup>{
          description: 'Standard LTL',
          scac: parameters.scac,
          mode: parameters.mode,
          charges: charges,
          currencyCode: parameters.currencyCode
        },
      ],
    };
  }

  mapRates(response: QuoteResponse, carrierCharges: ui.Charge[] = null): Rate[] {
    const returnedRates = response.rates || [];

    const rates = returnedRates
      .filter((rate) => rate && rate.customerRate && rate.carrierRate)
      .map((rate) => this.mapRate(response.id, response.distance, rate));

    returnedRates.forEach((rate) => {
      const serviceRates = this.mapServiceRates(response.id, response.distance, rate);

      rates.push(...serviceRates);
    });

    //Update the properties on the markup with the properties from the original Invoice
    if (carrierCharges) {
      for (let i = 0; i < rates.length; i++) {
        for (let j = 0; j < rates[i].carrierCharges.length; j++) {
          const charge = rates[i].carrierCharges[j];
          const lookupCharge = carrierCharges.find((c) => c.description == charge.description);

          charge.rateQualifier = lookupCharge.rateQualifier;
          charge.quantity = lookupCharge.quantity;
          charge.rate = charge.amount / (charge.quantity || 1);
        }
      }
    }

    return rates;
  }

  getBGChargeType(type: string) {
    switch (type.toLocaleUpperCase()) {
      case 'LINEHAUL':
      case 'ITEM':
      case 'DISCOUNT':
      case 'ADJUSTMENT':
      case 'MG_MINMAX_ADJ':
      case 'SMC_MIN_ADJ':
      case 'DEFICIT':
        return 'Linehaul';
      case 'ACCESSORIAL_FUEL':
      case 'FUEL':
        return 'Fuel';
      case 'ACCESSORIAL':
      case 'ACCESSORIAL_PERCENTAGE_TOTAL':
      case 'UNKNOWN':
        return 'Accessorial';
      default:
        if (type.startsWith('ACCESSORIAL_')) {
          return 'Accessorial';
        } else {
          return 'Linehaul';
        }
    }
  }

  getBGChargeSubType(type: string) {
    switch (type.toLocaleUpperCase()) {
      case 'LINEHAUL':
      case 'ITEM':
        return 'Linehaul';
      case 'DISCOUNT':
        return 'Discount';
      case 'ADJUSTMENT':
        return 'Adjustment';
      case 'MG_MINMAX_ADJ':
        return 'Adjustment';
      case 'SMC_MIN_ADJ':
        return 'SmcAdjustment';
      case 'DEFICIT':
        return 'Deficit';
      case 'ACCESSORIAL_FUEL':
      case 'FUEL':
        return 'FuelSurcharge';
      case 'ACCESSORIAL':
      case 'ACCESSORIAL_PERCENTAGE_TOTAL':
      case 'UNKNOWN':
        return 'Accessorial';
      default:
        if (type.startsWith('ACCESSORIAL_')) {
          return 'Accessorial';
        } else {
          return 'Linehaul';
        }
    }
  }

  private mapItems(auditItemListComponent: AuditItemListComponent): QuoteItem[] {
    return auditItemListComponent.records
      .filter(x => x.weight > 0.0)
      .map((item) => {
        return <QuoteItem>{
          freightClass: (item.freightClass as number).toString(),
          weight: item.weight,
          length: item.length,
          width: item.width,
          height: item.height,
          quantity: item.quantity,
          itemUnitType: item.quantityUOM,
        };
      });
  }

  private calculateTotal = (charges): number => {
    return charges.map((charge) => charge.amount).reduce((acc, chargeAmount) => acc + chargeAmount, 0);
  };

  private filterCharges = (rate: { charges: BgCharge[] }, lowerType: string): BgCharge[] => {
    if (rate && rate.charges) {
      rate.charges.filter((ch) => (ch.type || '').toLocaleLowerCase() === lowerType);
    }
    return [];
  };

  private filterServiceCharges = (rate: { serviceLevelCharges: BgCharge[] }, lowerType: string): BgCharge[] => {
    if (rate && rate.serviceLevelCharges) {
      rate.serviceLevelCharges.filter((ch) => (ch.type || '').toLocaleLowerCase() === lowerType);
    }
    return [];
  };

  private mapCharge(ch: BgCharge, billedWeight: number) {
    const isLinehaul = ch.type.toLocaleLowerCase() === this.Linehaul;
    const isAccessorial = ch.type.toLocaleLowerCase() === this.Accessorial;

    return {
      type: isLinehaul || isAccessorial ? ch.subType : ch.type,
      subType: ch.subType,
      description: ch.description,
      amount: ch.amount,
      rate: ch.rate,
      rateQualifier: ch.rateQualifier,
      quantity: ch.quantity,
      weight: ch.weight || billedWeight,
      dimWeight: 0,
      freightClass: ch.freightClass,
      fakFreightClass: ch.fakFreightClass,
      isMin: ch.isMin,
      isMax: ch.isMax,
      isNonTaxable: false,
      isLinehaul: isLinehaul,
      isAccessorial: isAccessorial,
      ediGroup: null,
      ediCode: ch.code,
      group: '',
    };
  }

  private mapServiceCharges = (rate: { serviceLevelCharges: BgCharge[] }, billedWeight: number) => {
    if (rate && rate.serviceLevelCharges) {
      return rate.serviceLevelCharges.map((ch) => this.mapCharge(ch, billedWeight));
    }
    return [];
  };

  private mapCharges = (rate: { charges: BgCharge[] }, billedWeight: number) => {
    if (rate && rate.charges) {
      return rate.charges.map((ch) => this.mapCharge(ch, billedWeight));
    }
    return [];
  };

  private mapServiceRates(id: string, distance: number, rate: BgRate): Rate[] {
    const response = [];

    (rate.carrierRate.serviceLevels || []).forEach((sl) => {
      const slCarrier = sl;

      const slCustomer =
        rate.customerRate != null
          ? rate.customerRate.serviceLevels.find((serviceLevel) => serviceLevel.analogousID === sl.analogousID)
          : null;

      const slThirdParty =
        rate.customerThirdRate != null
          ? rate.customerThirdRate.serviceLevels.find((serviceLevel) => serviceLevel.analogousID === sl.analogousID)
          : null;

      const newSlRate = <Rate>{
        id: rate.carrierRate.analogousID.toString(),
        requestGroupID: id,
        contractId: rate.contractID,
        contractName: rate.contractName,
        carrierAccountNumber: rate.carrierAccountNumber,
        carrierAccountNumberDisplay: rate.carrierAccountNumberDisplay,
        carrierName: rate.carrierName,
        scac: rate.scac,
        mode: rate.mode,
        service: slCarrier.description,
        serviceDays: rate.serviceDays,
        distance: distance,
        expectedDeliveryDate: new Date(rate.estimatedDelivery),
        carrierIdentifier: rate.carrierName,
        originService: rate.serviceOrigin,
        destinationService: rate.serviceDestination,
        currencyCode: rate.currencyCode,
        priceSheetID: rate.source,
        filterReason: rate.isExcluded ? rate.excludeReason : '',
        isExcluded: rate.isExcluded,
        isSelected: false,
        maxLiability: rate.maxLiability.toString(),
        carrierLiabilityOld: rate.maxLiability.toString(),
        carrierLiabilityNew: rate.maxLiability.toString(),
        carrierQuoteNumber: slCarrier.carrierQuoteNumber,
        note: rate.discountType,
        isPremium: false,
        isLeastCost: false,
        isTMS: false,
        carrierTotal: slCarrier ? slCarrier.totalCharge : 0,
        carrierNormalizedTotal: rate.carrierRate ? rate.carrierRate.totalChargeInUSD : 0,
        customerTotal: slCustomer !== null ? slCustomer.totalCharge : 0,
        customerNormalizedTotal: rate.customerRate ? rate.customerRate.totalChargeInUSD : 0,
        thirdPartyTotal: slThirdParty !== null ? slThirdParty.totalCharge : 0,
        carrierServiceTotal: this.calculateTotal(this.filterServiceCharges(slCarrier, this.Accessorial)),
        carrierLinehaulTotal: this.calculateTotal(this.filterServiceCharges(slCarrier, this.Linehaul)),
        customerServiceTotal: this.calculateTotal(this.filterServiceCharges(slCustomer, this.Accessorial)),
        customerLinehaulTotal: this.calculateTotal(this.filterServiceCharges(slCustomer, this.Linehaul)),
        thirdPartyServiceTotal: this.calculateTotal(this.filterServiceCharges(slThirdParty, this.Accessorial)),
        thirdPartyLinehaulTotal: this.calculateTotal(this.filterServiceCharges(slThirdParty, this.Linehaul)),
        isBlockInsurance: false,
        dateCreated: new Date(),
        carrierCharges: this.mapServiceCharges(slCarrier, rate.billedWeight),
        customerCharges: this.mapServiceCharges(slCustomer, rate.billedWeight),
        thirdPartyCharges: this.mapServiceCharges(slThirdParty, rate.billedWeight),
        markups: [...rate.customerRate.markups],
        normalizedCurrencyCode: 'USD',
        thirdPartyNormalizedTotal: rate.customerThirdRate ? rate.customerThirdRate.totalChargeInUSD : 0,
        isManualCreate: false,
        billingAddress: rate.billingAddress,
      };

      response.push(newSlRate);
    });

    return response;
  }

  private mapRate(id: string, distance: number, rate: BgRate): Rate {
    return {
      id: rate.carrierRate.analogousID,
      requestGroupID: id,
      contractId: rate.contractID,
      contractName: rate.contractName,
      carrierAccountNumber: rate.carrierAccountNumber,
      carrierAccountNumberDisplay: rate.carrierAccountNumberDisplay,
      carrierName: rate.carrierName,
      scac: rate.scac,
      mode: rate.mode,
      service: rate.carrierRate.description,
      serviceDays: rate.serviceDays,
      distance: distance,
      expectedDeliveryDate: new Date(rate.estimatedDelivery),
      carrierIdentifier: rate.carrierName,
      originService: rate.serviceOrigin,
      destinationService: rate.serviceDestination,
      currencyCode: rate.currencyCode,
      priceSheetID: rate.source,
      filterReason: rate.isExcluded ? rate.excludeReason : '',
      isExcluded: rate.isExcluded,
      isSelected: false,
      maxLiability: rate.maxLiability.toString(),
      carrierLiabilityOld: rate.maxLiability.toString(),
      carrierLiabilityNew: rate.maxLiability.toString(),
      carrierQuoteNumber: rate.carrierRate.carrierQuoteNumber,
      note: rate.discountType,
      isPremium: false,
      isLeastCost: false,
      isTMS: false,
      carrierTotal: rate.carrierRate ? rate.carrierRate.totalCharge : 0,
      carrierNormalizedTotal: rate.carrierRate ? rate.carrierRate.totalChargeInUSD : 0,
      normalizedCurrencyCode: 'USD',
      customerTotal: rate.customerRate ? rate.customerRate.totalCharge : 0,
      customerNormalizedTotal: rate.customerRate ? rate.customerRate.totalChargeInUSD : 0,
      thirdPartyTotal: rate.customerThirdRate ? rate.customerThirdRate.totalCharge : 0,
      carrierServiceTotal: this.calculateTotal(this.filterCharges(rate.carrierRate, this.Accessorial)),
      carrierLinehaulTotal: this.calculateTotal(this.filterCharges(rate.carrierRate, this.Linehaul)),
      customerServiceTotal: this.calculateTotal(this.filterCharges(rate.customerRate, this.Accessorial)),
      customerLinehaulTotal: this.calculateTotal(this.filterCharges(rate.customerRate, this.Linehaul)),
      thirdPartyServiceTotal: this.calculateTotal(this.filterCharges(rate.customerThirdRate, this.Accessorial)),
      thirdPartyLinehaulTotal: this.calculateTotal(this.filterCharges(rate.customerThirdRate, this.Linehaul)),
      thirdPartyNormalizedTotal: rate.customerThirdRate ? rate.customerThirdRate.totalChargeInUSD : 0,
      dateCreated: new Date(),
      carrierCharges: this.mapCharges(rate.carrierRate, rate.billedWeight),
      customerCharges: this.mapCharges(rate.customerRate, rate.billedWeight),
      thirdPartyCharges: this.mapCharges(rate.customerThirdRate, rate.billedWeight),
      markups: [...rate.customerRate.markups],
      isManualCreate: false,
      billingAddress: rate.billingAddress,
      rating: rate.rating
    };
  }
}
