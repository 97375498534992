import { QuoteCharge, QuoteDetail } from '@/models/ShipmentQuoteHistoryResponse';
import { Globals } from '@/_shared/globals';
import { Component, Input, OnInit, AfterViewInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-shipment-quote-charge',
  templateUrl: './shipment-quote-charge.component.html',
  styleUrls: ['./shipment-quote-charge.component.scss'],
})
export class ShipmentQuoteChargeComponent implements OnChanges {
  @Input()
  quoteDetail: QuoteDetail;
  isLinehaulExpanded: boolean = true;
  isAccessorailsExpanded: boolean = true;
  lineHaulCharges: Array<QuoteCharge>;
  lineHaulChargesSubtotal: number;
  accessorialsCharges: Array<QuoteCharge>;
  accessorialsChargesSubtotal: number;
  chargesTotal: number;
  constructor() {}

  ngOnChanges() {
    this.lineHaulCharges = this.filterCharges(this.quoteDetail.charges, false);
    this.lineHaulChargesSubtotal = this.calculateSubtotal(this.lineHaulCharges);
    this.accessorialsCharges = this.filterCharges(this.quoteDetail.charges, true);
    this.accessorialsChargesSubtotal = this.calculateSubtotal(this.accessorialsCharges);
    this.chargesTotal = this.lineHaulChargesSubtotal + this.accessorialsChargesSubtotal;
  }

  onToggleLinehaul(e) {
    e.preventDefault();
    this.isLinehaulExpanded = !this.isLinehaulExpanded;
  }

  onToggleAccessorials(e) {
    e.preventDefault();
    this.isAccessorailsExpanded = !this.isAccessorailsExpanded;
  }

  filterCharges(charges: Array<QuoteCharge>, isAccessorial: boolean): Array<QuoteCharge> {
    return charges.filter((charge) => charge.isAccessorial === isAccessorial).sort((a, b) => (a.type <= b.type ? 1 : -1)) || [];
  }

  calculateSubtotal(charges: Array<QuoteCharge>): number {
    return charges.reduce((acc, charge) => {
      return acc + charge.amount;
    }, 0);
  }

  getQualifier(rateCode: string): string {
    const qualifier = Globals.Qualifiers.find((r) => r.rateCode == rateCode);
    return qualifier ? qualifier.rateQualifier.substring(0, qualifier.rateQualifier.indexOf('(')) : rateCode;
  }
}
