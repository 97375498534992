import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoiceService } from '@/services/invoice.service';
import { Router } from '@angular/router';
import { AuthService } from '@/auth/auth.service';
import { Helpers } from '@/_shared/helpers';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { InvoicesUnmatchedListComponent } from '@/components/invoices-unmatched-list/invoices-unmatched-list.component';
import {
  PendingCarrierInvoice,
  PendingCarrierInvoiceStatus,
  SavePendingInvoice,
  UpdatePendingInvoiceAction,
  MatchPendingInvoiceManualBOL,
} from '@/services/Invoice';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-collection',
  templateUrl: './invoices-unmatched.component.html',
  styleUrls: ['./invoices-unmatched.component.scss'],
})
export class InvoicesUnmatchedComponent implements OnInit {
  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild('invoiceGrid', { static: false })
  invoiceChild: InvoicesUnmatchedListComponent;

  helpers: Helpers;
  recordCount: number | null;
  isDataloading: boolean;
  recordInvoiceGrid: PendingCarrierInvoice[];
  private day_as_milliseconds: number = 86400000;
  private todayDate: number = new Date().getTime();
  public statusList: string[] = ['CarrierInvoiceShipmentNotFound', 'ShipmentNotFoundEmailSent'];
  protected mode = 'LTL';

  protected get recordCountLabel(): string {
    if (this.recordCount == null) {
      return '';
    }
    const s = this.recordCount !== 1 ? 's' : '';
    return `- ${this.recordCount} Record${s}`;
  }

  constructor(private pcService: InvoiceService, private router: Router, private authService: AuthService) {
    let mode = JSON.parse(localStorage.getItem('mode'));
    if (mode) this.mode = mode;
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);

    // are they allowed to view this page
    if (!this.helpers.hasOperation('CanWorkUnmatchedInvoices')) {
      this.router.navigate([`not-authorized`]);
    }

    // prepare all the requests
    this.loadData();
  }

  public loadData(): void {
    this.recordInvoiceGrid = [];
    this.recordCount = null;
    this.isDataloading = true;
    this.pcService.getInvoicesByStatus(this.statusList, this.mode,).subscribe(
      this.loadCompleted.bind(this),
      (error) => {
        this.alertMessage.showAlertMessage('Error retrieving unmatched Invoices.', 'Error');
        this.isDataloading = false;
      },
      () => {
        this.isDataloading = false;
        window.setTimeout(this.invoiceChild.applyFilter.bind(this.invoiceChild));
      }
    );
  }

  private loadCompleted(records: PendingCarrierInvoice[]): void {
    if (records && Array.isArray(records)) {

      records.forEach(data => data.aged = this.calculateDays(data.dateCreated));
      this.recordInvoiceGrid = records;
      this.recordCount = records.length;
    }
  }

  protected modeChanged(e: Event): void {
    e.preventDefault();
    localStorage.setItem('mode', JSON.stringify(this.mode));
    this.loadData();
  }

  private calculateDays(date: Date): number {
    let dateCreate = new Date(date).getTime();
    return Math.round((this.todayDate - dateCreate) / this.day_as_milliseconds);
  }

  onRemoveInvoice(invoice: PendingCarrierInvoice) {
    this.recordInvoiceGrid = this.recordInvoiceGrid.filter(i => i.id !== invoice.id);
    this.recordCount = this.recordInvoiceGrid.length;
  }

  // events
  public onInvoiceReset(resetInvoices: PendingCarrierInvoice[]) {
    this.appSpinner.loading = true;
    const invoice = {
      updatePendingInvoiceAction: UpdatePendingInvoiceAction.Reset,
      status: PendingCarrierInvoiceStatus[PendingCarrierInvoiceStatus.PendingCarrierInvoiceReceived],
    } as SavePendingInvoice;

    const observableBatch = [];
    const resetIDList: number[] = [];
    for (const dataItem of resetInvoices) {
      observableBatch.push(this.pcService.UnmatchedInvoice(dataItem.id, invoice));
      resetIDList.push(dataItem.id);
    }

    // call them
    this.appSpinner.loading = true;
    const combined = forkJoin(observableBatch);
    combined.subscribe(
      (data) => {
        // all went well no errors
        this.invoiceChild.removeRecords(resetIDList);
        this.recordCount = this.recordCount - resetIDList.length;
        this.appSpinner.loading = false;
        this.alertMessage.showAlertMessage('Invoice Record(s) have been Reset.', 'Success');
      },
      (error) => {
        this.appSpinner.loading = false;
        this.alertMessage.showAlertMessage('Error Resetting Invoice Record(s).', 'Error');
      }
    );
  }

  // TODO this one is not working yet
  public onInvoiceResubmit(dataItem: PendingCarrierInvoice) {
    this.appSpinner.loading = true;
    const invoice = {
      invoiceID: dataItem.id,
      updatePendingInvoiceAction: UpdatePendingInvoiceAction.SetShipment,
      shipmentID: dataItem.shipmentID,
      status: PendingCarrierInvoiceStatus[PendingCarrierInvoiceStatus.PendingCarrierInvoiceReceived],
    } as SavePendingInvoice;

    // reset the record
    this.pcService.UnmatchedInvoice(dataItem.id, invoice).subscribe(
      (data) => {
        this.alertMessage.showAlertMessage('Invoice Record has been Resubmitted.', 'Success');
        this.appSpinner.loading = false;
        this.invoiceChild.removeRecords([dataItem.id]);
        this.recordCount--;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Resubmitting Invoice Record.', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }

  public onInvoicePickReference(dataItem: MatchPendingInvoiceManualBOL) {
    this.appSpinner.loading = true;
    const invoice = {
      invoiceID: dataItem.id,
      updatePendingInvoiceAction: UpdatePendingInvoiceAction.SelectedBOL,
      selectedPrimaryReference: dataItem.primaryReference,
      status: PendingCarrierInvoiceStatus[PendingCarrierInvoiceStatus.PendingCarrierInvoiceReceived],
    } as SavePendingInvoice;

    // reset the record
    this.pcService.UnmatchedInvoice(dataItem.id, invoice).subscribe(
      (data) => {
        this.alertMessage.showAlertMessage('Invoice was submitted to the system.', 'Success');
        this.appSpinner.loading = false;
        this.invoiceChild.removeRecords([dataItem.id]);
        this.recordCount--;
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Submitting Invoice Record.', 'Error');
        this.appSpinner.loading = false;
      }
    );
  }

  public onInvoiceAssign(): void {
    this.loadData();
  }

  protected onInvoiceDelete({ id }: PendingCarrierInvoice): void {
    this.isDataloading = true;

    this.pcService.deletePendingInvoice(id).subscribe(
      () => {
        this.isDataloading = false;
        const index = this.recordInvoiceGrid.findIndex((inv) => inv.id === id);
        if (index >= 0) {
          this.invoiceChild.removeRecords([id]);
          this.recordCount--;
        }
        this.alertMessage.showAlertMessage('Successfully Deleted Invoice.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error deleting Unmatched Invoice.', 'Error');
        this.isDataloading = false;
      }
    );
  }

  protected onInvoicesFiltered({ length }: PendingCarrierInvoice[]): void {
    this.recordCount = length;
  }
}
