import { AuthService } from '@/auth/auth.service';

import { ImageRetrievalResponse } from '@/models/ShipmentDocument';
import { StartupService } from '@/startup.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ImageService {
  constructor(
    private startupService: StartupService,
    private http: HttpClient,
    private authService: AuthService) { }

  public httpOptions = {
    headers: new HttpHeaders({
      UserName: this.authService.BlueShipUser.userName,
      EnterpriseID: this.authService.BlueShipUser.enterpriseID.toString(),
      UserID: this.authService.BlueShipUser.userID.toString(),
      'x-functions-key': `${this.startupService.imageServiceFunctionKey}`,
      'Content-Type': 'application/json'
    }),
  };

  public getDocuments(pro: string, primaryReference: string): Observable<ImageRetrievalResponse> {
    return this.http.get<ImageRetrievalResponse>(`${this.startupService.imageAPIUrl}GetDocumentListBasedOnPrimaryReferenceFunction/${pro}/${primaryReference}`, {
      headers: this.httpOptions.headers
    });
  }
}
