import { Action } from '@ngrx/store';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { StartupData } from './models/StartupData';

export const LOAD_APP_CONFIG = '[APP] Load App Config';
export const LOAD_APP_CONFIG_SUCCESS = '[APP] Load App Config Success';
export const LOAD_FEATURE_FLAGS_SUCCESS = '[APP] Load Feature Flags Success';

export class LoadAppConfig implements Action {
  readonly type = LOAD_APP_CONFIG;
  constructor() { }
}
export class LoadAppConfigSuccess implements Action {
  readonly type = LOAD_APP_CONFIG_SUCCESS;
  constructor(public startupData: StartupData) { }
}

export class LoadFeatureFlagsSuccess implements Action {
  readonly type = LOAD_FEATURE_FLAGS_SUCCESS;
  constructor(public flags: LDFlagSet) { }
}

export type Actions = LoadAppConfig | LoadAppConfigSuccess | LoadFeatureFlagsSuccess;
