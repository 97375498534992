import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from '../auth/auth.service';
import { StartupService } from '../startup.service';
import { ConversionRate } from './ConversionRate';
import { IConversionResponse, ICurrencyConversion } from './currencyCoversion';
@Injectable()
export class CurrencyService {
  shipmentApiHeaders: HttpHeaders;
  shipmentApiHeadersJson: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private startupService: StartupService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.shipmentApiHeadersJson = new HttpHeaders({
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,

      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,

      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.userName : null
    });
  }

  public getConversionRate(currentDate: Date): Observable<ConversionRate[]> {
    const url = `${this.startupService.shipmentApiUrl}v2/currency?effectiveDate=` + this.datePipe.transform(currentDate, 'shortDate');
    return this.http
      .get<ConversionRate[]>(url, {
        headers: this.shipmentApiHeadersJson,
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return observableThrowError(err);
        })
      );
  }

  public currencyConversion(currencyData: ICurrencyConversion): Observable<IConversionResponse> {
    return this.http.post<IConversionResponse>(`${this.startupService.shipmentApiUrl}v2/currency`, currencyData, {
      headers: this.shipmentApiHeadersJson,
    }).pipe(
      catchError((err) => {
        console.log(err);
        return observableThrowError(err);
      })
    );
  }
}
