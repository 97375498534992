import { Component, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ControlContainer, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  template: `
    <ul *ngIf="shouldShowErrors()" class="p-0 m-0 ml-1 mt-1" [id]="id">
      <li class="text-danger list-unstyled" *ngFor="let error of listOfErrors()">
        <label class="mb-0">{{ error }}</label>
      </li>
    </ul>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ShowErrorsComponent,
      multi: true
    }
  ]
})
export class ShowErrorsComponent implements ControlValueAccessor, OnInit {
  private static readonly errorMessages = {
    required: (params, label) => (label ? label + ' is required' : 'Required'),
    minlength: (params, label) => 'The min number of characters is ' + params.requiredLength,
    maxlength: (params, label) => 'The max allowed number of characters is ' + params.requiredLength,
    min: (params, label) => 'Must be ' + params.min + ' or more',
    max: (params, label) => 'Must be ' + params.max + ' or less',
    pattern: (params, label) => 'The required pattern is: ' + params.requiredPattern,
    invalidCityStateZip: (params, label) => 'Please enter a valid ' + label,
    strongPassword: (params, label) => 'Strong password is required',
    mustBeLater: (params, label) => 'Must be later than ' + params.time,
    todayOrLater: (params, label) => 'Must be today or later',
    dateTooDistant: (params, label) => 'Must be closer to today',
    requiredWhenHazardousFormatRules: (params, label) => 'Must be a 4 digit number',
    numericAndDashes: (params, label) => 'Only numbers and dashes allowed',
    needsWeight: (params, label) => 'Valid weight is required',
    needsFreight: (params, label) => 'Class is required',
    EDMNameInvalid: (params, label) => 'OM Name is required'
  };

  control: AbstractControl;
  @Input()
  protected formControlName: string;
  @Input()
  protected formControl: FormControl;
  @Input()
  protected label: string;
  @Input()
  protected id: string;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private parentFormContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    this.updateFormControlRef();
  }

  shouldShowErrors(): boolean {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]))
      .filter(val => val);
  }

  // do nothing, we're just using the ControlValueAccessor to access the error list
  writeValue(value) {}
  registerOnChange(fn) {}
  registerOnTouched(fn) {}

  private updateFormControlRef() {
    if (this.formControlName) {
      this.control = this.parentFormContainer.control.get(this.formControlName);
    } else {
      this.control = this.formControl;
    }
  }

  private getMessage(type: string, params: any) {
    if (ShowErrorsComponent.errorMessages[type]) {
      return ShowErrorsComponent.errorMessages[type](params, this.label);
    }
    return null;
  }
}
