import { AppState } from '@/appstate.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TLEditUI } from '../../models/TLEdit.ui';

@Component({
  selector: 'app-tl-address',
  templateUrl: './tl-address.component.html',
  styleUrls: ['./tl-address.component.scss'],
})
export class TlAddressComponent implements OnInit {
  allAddresses: TLEditUI.Address[];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.setListeners();
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditEditState.shipment)
      .subscribe((x) => {
        if (x) {
          this.allAddresses = x.multiStopAddresses;
        }
      });
  }
}
