import { Injectable } from '@angular/core';
import { Effect, ofType, Actions, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';
import { LaunchDarklyService } from './launch-darkly.service';
import * as AuthActions from '../../auth/actions/authentication.actions';
import * as AppActions from '../../app.actions';

@Injectable()
export class FeatureFlagEffects {
    constructor(
        private actions$: Actions,
        private launchDarklyService: LaunchDarklyService
    ) { }
    @Effect()
    $init = this.actions$.pipe(
        ofType(AuthActions.USER_AUTHENTICATION_SUCCESS),
        mergeMap((action: AuthActions.UserAuthenticationSuccess) =>
            this.launchDarklyService.init(action.startupData, action.user).pipe(
                map((flags) => {
                    return new AppActions.LoadFeatureFlagsSuccess(flags);
                })
            )
        )
    );
}
