import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TruckloadAuditReasonOldUI } from '@/pages/tl-audit-old/models/TruckloadAuditOld.ui';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { SaveReasonStatusOld, AddCommentOld } from '../../actions/tl-audit-detail-old.actions';

@Component({
  selector: 'app-tl-reason-comment-old',
  templateUrl: './tl-reason-comment-old.component.html',
  styleUrls: ['./tl-reason-comment-old.component.scss'],
})
export class TlReasonCommentComponentOld implements OnInit {
  @Input('reason')
  reason: TruckloadAuditReasonOldUI;

  tlAuditReasonStatus = [
    { text: 'New', value: 'New' },
    { text: 'Complete', value: 'Complete' },
  ];
  reasonCommentForm: FormGroup;
  hideComment = true;
  originalReasonStatus: string;
  reasonID: number;
  actionBtnDisabled = false;
  constructor(private fb: FormBuilder, private store: Store<AppState>) {}

  ngOnInit() {
    this.reasonID = this.reason.reasonID;
    this.originalReasonStatus = this.reason.status;
    this.createForm();
    this.setListeners();
  }

  public save() {
    if (this.reasonCommentForm.get('reasonStatusDropDown').value !== this.originalReasonStatus) {
      this.store.dispatch(
        new SaveReasonStatusOld(
          this.reason.tlAuditID,
          this.reason.reasonID,
          this.reasonCommentForm.get('reasonStatusDropDown').value,
          this.reasonCommentForm.get('reasonComment').value
        )
      );
    } else {
      if (this.reasonCommentForm.get('reasonComment').value !== '') {
        this.store.dispatch(
          new AddCommentOld(this.reason.tlAuditID, this.reason.reasonID, this.reasonCommentForm.get('reasonComment').value)
        );
      }
    }
  }

  public resetForm() {
    this.reasonCommentForm.reset({
      reasonStatusDropDown: this.reason.status,
      reasonComment: '',
    });
    this.hideComment = true;
  }

  protected selectionChange(value: any): void {
    this.hideComment = false;
  }

  private createForm() {
    this.reasonCommentForm = this.fb.group({
      reasonStatusDropDown: [this.reason.status],
      reasonComment: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
    });
  }

  private setListeners() {
    this.store
      .select((x) => x.TLAuditDetailOldState.manageReasonsSaveInProgress)
      .subscribe((x) => {
        this.actionBtnDisabled = x;
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.updateReasonSuccess)
      .subscribe((x) => {
        if (x) {
          this.originalReasonStatus = this.reason.status;
          this.resetForm();
        }
      });
    this.store
      .select((x) => x.TLAuditDetailOldState.addCommentSuccess)
      .subscribe((x) => {
        if (x) {
          this.resetForm();
        }
      });
  }
}
