import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-modal-custom',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @ViewChild('templateContent', { static: false }) template: TemplateRef<any>;
  @ViewChild('templateButtons', { static: false }) buttons: TemplateRef<any>;
  private dialog: DialogRef;

  constructor(
    private dialogService: DialogService
  ) { }

  showConfirmation(optionsModal: any): void {
    this.dialog = this.dialogService.open({
      title: optionsModal.title,
      width: optionsModal.width,
      height: optionsModal.height,
      content: this.template,
      actions: this.buttons,
    });
  }

  closeModal(): void {
    this.dialog.close();
  }
}
