import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { StartupService } from '../startup.service';
import { catchError, map } from 'rxjs/operators';
import {
  ProactiveResponse,
  ManualProactiveResponse,
  SelectManualProactive,
  ReleaseCustomerInvoice,
  DisputeCustomerInvoice,
  TLDocHoldProactiveResponse,
  RevertCustomerInvoice,
} from '../models/Proactive';

@Injectable()
export class ProactiveService {
  constructor(private http: HttpClient, private startupService: StartupService) { }

  public getManualProactive(): Observable<ManualProactiveResponse[]> {
    return this.http.get<ManualProactiveResponse[]>(`${this.startupService.financeAPIUrl}v2/proactives/manual`).pipe(
      map((response: ManualProactiveResponse[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  public selectManualHold(chosenRate: SelectManualProactive) {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/proactives/manual`, chosenRate);
  }
  public getProactiveHold(): Observable<ProactiveResponse[]> {
    return this.http.get<ProactiveResponse[]>(`${this.startupService.financeAPIUrl}v2/proactives/`).pipe(
      map((response: ProactiveResponse[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public getTLDocProactiveHold(): Observable<TLDocHoldProactiveResponse[]> {
    return this.http.get<TLDocHoldProactiveResponse[]>(`${this.startupService.financeAPIUrl}v2/proactives/tldoc`).pipe(
      map((response: TLDocHoldProactiveResponse[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public releaseProactiveHold(releaseInvoice: ReleaseCustomerInvoice[]) {
    return this.http.put(`${this.startupService.financeAPIUrl}v2/proactives/release`, releaseInvoice).pipe(
      map((response: boolean) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  public disputeProactiveHold(disputeInvoice: DisputeCustomerInvoice[]) {
    return this.http.put(`${this.startupService.financeAPIUrl}v2/proactives/dispute`, disputeInvoice).pipe(
      map((response: boolean) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  public revertProactiveHold(revertInvoices: RevertCustomerInvoice[]) {
    return this.http.put(`${this.startupService.financeAPIUrl}v2/proactives/revert`, revertInvoices).pipe(
      map((response) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
