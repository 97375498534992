export class ShipmentRefTypes {
  public static CustomShipmentAccessorials: Array<{ text: String; value: String }> = [
    { text: 'COD', value: 'COD' },
    { text: 'Freeze Protection Fee', value: 'DNF' }
  ];

  public static CustomLocationAccessorials: Array<{ text: String; value: String }> = [
    { text: 'COD', value: 'COD' },
    { text: 'Pup Needed for Delivery', value: 'PUP' },
    { text: 'Hotel Delivery', value: 'HOD' },
    { text: 'Hotel Pickup', value: 'HOP' }
  ];

  public static CustomDeliveryAccessorials: Array<{ text: String; value: String }> = [
    { text: 'Pup Needed for Delivery', value: 'PUP' },
    { text: 'Hotel Delivery', value: 'HOD' }
  ];

  public static CustomPickupAccessorials: Array<{ text: String; value: String }> = [{ text: 'Hotel Pickup', value: 'HOP' }];

  public static CustomProductAccessorials: Array<{ text: String; value: String }> = [{ text: 'Freeze Protection Fee', value: 'DNF' }];

  public static LocationAccessorials: Array<{ text: String; value: String }> = [
    { text: 'Airport Delivery', value: 'APD' },
    { text: 'Airport Pickup', value: 'ARP' },
    { text: 'Appointment', value: 'APT' },
    { text: 'Container Freight Station Delivery', value: 'CFP:DP' },
    { text: 'Container Freight Station Pickup', value: 'CFP:O' },
    { text: 'Grocery Warehouse Delivery', value: 'GRD' },
    { text: 'Grocery Warehouse Pickup', value: 'GRP' },
    { text: 'Inside Delivery', value: 'ID2' },
    { text: 'Inside Pickup', value: 'IP' },
    { text: 'Lift Gate at Delivery', value: 'LGD' },
    { text: 'Lift Gate Pickup', value: 'LGO' },
    { text: 'Limited Access Fee', value: 'LACS' },
    { text: 'Military Access', value: 'MIL' },
    { text: 'Notify Consignee', value: 'MNC' },
    { text: 'Prepaid and Add', value: 'PSP' },
    { text: 'Residential Delivery', value: 'RD' },
    { text: 'Residential Pickup', value: 'RP' },
    { text: 'School Delivery', value: 'SD' },
    { text: 'Sorting and Segregation', value: 'SEG' },
    { text: 'Trade Show Delivery', value: 'TRDS' },
    { text: 'Trade Show Pickup', value: 'TRDS1' }
  ];

  public static DeliveryAccessorials: Array<{ text: String; value: String }> = [
    { text: 'Airport Delivery', value: 'APD' },
    { text: 'Appointment', value: 'APT' },
    { text: 'Container Freight Station Delivery', value: 'CFP:DP' },
    { text: 'Grocery Warehouse Delivery', value: 'GRD' },
    { text: 'Inside Delivery', value: 'ID2' },
    { text: 'Lift Gate at Delivery', value: 'LGD' },
    { text: 'Notify Consignee', value: 'MNC' },
    { text: 'Residential Delivery', value: 'RD' },
    { text: 'School Delivery', value: 'SD' },
    { text: 'Trade Show Delivery', value: 'TRDS' }
  ];

  public static ShipmentAccessorials: Array<{ text: String; value: String }> = [
    { text: 'Freeze Protection Fee', value: 'DNF' },
    { text: 'Hazardous Materials', value: 'HAZ' },
    { text: 'Limited Access Fee', value: 'LACS' },
    { text: 'Military Access', value: 'MIL' },
    { text: 'Over Dimension', value: 'OVD' },
    { text: 'Prepaid and Add', value: 'PSP' },
    { text: 'Single Shipment', value: 'SING' },
    { text: 'Sorting and Segregation', value: 'SEG' },
    { text: 'Tanker Endorsement', value: 'TNK' },
  ];

  public static PickupAccessorials: Array<{ text: String; value: String }> = [
    { text: 'Airport Pickup', value: 'ARP' },
    { text: 'Container Freight Station Pickup', value: 'CFP:O' },
    { text: 'Grocery Warehouse Pickup', value: 'GRP' },
    { text: 'Inside Pickup', value: 'IP' },
    { text: 'Lift Gate Pickup', value: 'LGO' },
    { text: 'Residential Pickup', value: 'RP' },
    { text: 'Trade Show Pickup', value: 'TRDS1' }
  ];

  public static ProductAccessorials: Array<{ text: String; value: String }> = [
    { text: 'Freeze Protection Fee', value: 'DNF' },
    { text: 'Grocery Warehouse Delivery', value: 'GRD' },
    { text: 'Grocery Warehouse Pickup', value: 'GRP' },
    { text: 'Hazardous Materials', value: 'HAZ' },
    { text: 'Over Dimension', value: 'OVD' },
    { text: 'Sorting and Segregation', value: 'SEG' },
    { text: 'Tanker Endorsement', value: 'TNK' },
    { text: 'Trade Show Delivery', value: 'TRDS' }
  ];

  public static pieceTypes: Array<String> = [
    'Bag',
    'Boxes',
    'Bucket',
    'Bundles',
    'Can',
    'Canister',
    'Cartons',
    'Cases',
    'Cylinder',
    'Drums',
    'Jerrican',
    'Pails',
    'Pieces',
    'Poles',
    'Reel',
    'Rolls',
    'Totes'
  ];

  public static unitTypes: Array<String> = [
    'Bag',
    'Boxes',
    'Bucket',
    'Bundles',
    'Can',
    'Canister',
    'Cartons',
    'Cases',
    'Crates',
    'Cylinder',
    'Drums',
    'Jerrican',
    'Pails',
    'Pallets',
    'Pieces',
    'Poles',
    'Reel',
    'Rolls',
    'Totes'
  ];

  public static freightClasses: Array<String> = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];

  public static quantityUOMs: Array<String> = [
    'Bag',
    'Boxes',
    'Bucket',
    'Bundles',
    'Can',
    'Canister',
    'Cartons',
    'Cases',
    'Crates',
    'Cylinder',
    'Drums',
    'Jerrican',
    'Pails',
    'Pallets',
    'Pieces',
    'Poles',
    'Reel',
    'Rolls',
    'Totes'
  ];

  public static weightTypes: Array<String> = ['lbs', 'kgs'];

  public static dimensionTypes: Array<String> = ['in', 'cm'];

  public static hazmatPackageGroups: Array<String> = ['I', 'II', 'III', 'NA'];

  public static hazmatClasses: Array<String> = [
    '1',
    '1.1',
    '1.2',
    '1.3',
    '1.4',
    '1.4G',
    '1.5',
    '1.6',
    '2',
    '2.1',
    '2.2',
    '2.3',
    '3',
    '3 (6.1)',
    '3 (8)',
    '4',
    '4.1',
    '4.2',
    '4.3',
    '5',
    '5.1',
    '5.1 (3)',
    '5.1 (6.1,8)',
    '5.1 (8)',
    '5.2',
    '5.2 (8)',
    '5.5',
    '6',
    '6.1',
    '6.1 (5.1)',
    '6.1 (3)',
    '7',
    '8',
    '8 (3)',
    '8 (5.1)',
    '8 (6.1)',
    '9'
  ];
}
