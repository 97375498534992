import { Action } from '@ngrx/store';
import { CarrierInvoiceAuditState } from '../models/carrier-invoice-audit-state.model';
import { CarrierInvoiceAudit, CarrierInvoiceCharge } from '../models/CarrierInvoiceAudit';
import { CarrierResponse } from '@/models/Carrier';

export const LOAD_CARRIER_INVOICE_EDIT = '[Carrier Invoice Audit API] Load Carrier Invoice Edit';
export const LOAD_CARRIER_INVOICE_ADD = '[Carrier Invoice Audit API] Load Carrier Invoice Add';
export const LOAD_CARRIER_INVOICE_SUCCESS = '[Carrier Invoice Audit API] Load Carrier Invoice Success';
export const LOAD_CARRIER_Invoice_ERROR = '[Carrier Invoice Audit API] Load Carrier Invoice Error';

export class LoadCarrierInvoiceEdit implements Action {
  readonly type = LOAD_CARRIER_INVOICE_EDIT;
  constructor(public invoiceID: number, public shipmentID: number) {}
}

export class LoadCarrierInvoiceAdd implements Action {
  readonly type = LOAD_CARRIER_INVOICE_ADD;
  constructor(public shipmentID: number) {}
}
export class LoadCarrierInvoiceSuccess implements Action {
  readonly type = LOAD_CARRIER_INVOICE_SUCCESS;
  constructor(public initialState: CarrierInvoiceAuditState) {}
}
export class LoadCarrierInvoiceError implements Action {
  readonly type = LOAD_CARRIER_Invoice_ERROR;
  constructor(public message: string) {}
}
export class CleanAuditSaveState implements Action {
  readonly type = CLEAN_AUDIT_SAVE_STATE;
  constructor() {}
}
export const CLEAN_AUDIT_SAVE_STATE = '[Carrier Invoice Audit] Clean Save State';
export const CARRIER_INVOICE_TOTAL_CHANGED = '[Carrier Invoice Audit] Carrier Invoice Total Changed';
export const CARRIER_INVOICE_SERVICE_TOTAL_CHANGED = '[Carrier Invoice Audit] Carrier Invoice Service Total Changed';
export const CARRIER_INVOICE_FREIGHT_TOTAL_CHANGED = '[Carrier Invoice Audit] Carrier Invoice Freight Total Changed';
export const CARRIER_INVOICE_DISTANCE_CHANGED = '[Carrier Invoice Audit] Carrier Invoice Distance Changed';
export const CARRIER_INVOICE_CARRIER_CHANGED = '[Carrier Invoice Audit] Carrier Invoice Carrier Changed';
export const CARRIER_INVOICE_CHARGES_IS_EDITING = '[Carrier Invoice Audit] Carrier Invoice Charges Is Editing';
export const CARRIER_INVOICE_CHARGES_CHANGED = '[Carrier Invoice Audit] Carrier Invoice Charges Changed';

export class CarrierInvoiceTotalChanged implements Action {
  readonly type = CARRIER_INVOICE_TOTAL_CHANGED;
  constructor(public total: number, public totalInUSD: number) {}
}

export class CarrierInvoiceServiceTotalChanged implements Action {
  readonly type = CARRIER_INVOICE_SERVICE_TOTAL_CHANGED;
  constructor(public total: number, public totalInUSD: number) {}
}

export class CarrierInvoiceFreightTotalChanged implements Action {
  readonly type = CARRIER_INVOICE_FREIGHT_TOTAL_CHANGED;
  constructor(public total: number, public totalInUSD: number) {}
}

export class CarrierInvoiceDistanceChanged implements Action {
  readonly type = CARRIER_INVOICE_DISTANCE_CHANGED;
  constructor(public distance: number) {}
}
export class CarrierInvoiceCarrierChanged implements Action {
  readonly type = CARRIER_INVOICE_CARRIER_CHANGED;
  constructor(public carrierInvoiceAudit: CarrierInvoiceAudit) {}
}

export class CarrierInvoiceChargesEditMode implements Action {
  readonly type = CARRIER_INVOICE_CHARGES_IS_EDITING;
  constructor(public editInProgress: boolean) {}
}

export class CarrierInvoiceChargesChanged implements Action {
  readonly type = CARRIER_INVOICE_CHARGES_CHANGED;
  constructor(public charges: CarrierInvoiceCharge[]) {}
}

export const CARRIER_INVOICE_SAVE = '[Carrier Invoice Audit API] Carrier Invoice Save';
export const CARRIER_INVOICE_SAVE_SUCCESS = '[Carrier Invoice Audit API]  Carrier Invoice Save Success';
export const CARRIER_Invoice_SAVE_ERROR = '[Carrier Invoice Audit API] Carrier Invoice Save Error';

export class CarrierInvoiceSave implements Action {
  readonly type = CARRIER_INVOICE_SAVE;
  constructor(
    public carrierInvoice: CarrierInvoiceAudit,
    public serviceTotal: number,
    public freightTotal: number,
    public charges: CarrierInvoiceCharge[]
  ) {}
}
export class CarrierInvoiceSaveSuccess implements Action {
  readonly type = CARRIER_INVOICE_SAVE_SUCCESS;
  constructor(public updatedState: CarrierInvoiceAudit) {}
}
export class CarrierInvoiceSaveError implements Action {
  readonly type = CARRIER_Invoice_SAVE_ERROR;
  constructor(public message: string) {}
}

export const CARRIER_LTL_LOAD = '[Carrier Invoice Audit API] Carrier LTL Load';
export const CARRIER_TL_SEARCH = '[Carrier API]  Carrier TL Search';
export const CARRIER_LTL_LOAD_SUCCESS = '[Carrier Invoice Audit API]  Carrier LTL Load Success';
export const CARRIER_TL_SEARCH_SUCCESS = '[Carrier API]  Carrier TL Search Success';
export const CARRIER_LOAD_ERROR = '[Carrier Invoice Audit API] Carrier Load Error';

export class CarrierLTLLoad implements Action {
  readonly type = CARRIER_LTL_LOAD;
  constructor(public scac: string, public value: string) {}
}

export class CarrierTLSearch implements Action {
  readonly type = CARRIER_TL_SEARCH;
  constructor(public isDotSearch: boolean, public value: string) {}
}

export class CarrierLTLLoadSuccess implements Action {
  readonly type = CARRIER_LTL_LOAD_SUCCESS;
  constructor(public tlCarriers: CarrierResponse[]) {}
}
export class CarrierTLSearchSuccess implements Action {
  readonly type = CARRIER_TL_SEARCH_SUCCESS;
  constructor(public tlCarriers: CarrierResponse[]) {}
}

export class CarrierLoadError implements Action {
  readonly type = CARRIER_LOAD_ERROR;
  constructor(public message: string) {}
}

export type Actions =
  | LoadCarrierInvoiceEdit
  | LoadCarrierInvoiceAdd
  | LoadCarrierInvoiceSuccess
  | LoadCarrierInvoiceError
  | CleanAuditSaveState
  | CarrierInvoiceTotalChanged
  | CarrierInvoiceServiceTotalChanged
  | CarrierInvoiceFreightTotalChanged
  | CarrierInvoiceDistanceChanged
  | CarrierInvoiceCarrierChanged
  | CarrierInvoiceChargesEditMode
  | CarrierInvoiceChargesChanged
  | CarrierInvoiceSave
  | CarrierInvoiceSaveSuccess
  | CarrierInvoiceSaveError
  | CarrierLTLLoad
  | CarrierTLSearch
  | CarrierLTLLoadSuccess
  | CarrierTLSearchSuccess
  | CarrierLoadError;
