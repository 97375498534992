export class NewTruckloadAuditReasonOld {
  constructor(id: number, reason: string, comment: string) {
    this.tlAuditID = id;
    this.reason = reason;
    this.comment = comment;
  }
  tlAuditID: number;
  reason: string;
  comment: string;
}

export class ResetTLInvoiceOld {
  shipmentID: number;
  invoiceNumber: string;
}
