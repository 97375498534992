import { ShipmentDocumentDTO, ShipmentDocumentUploadDTO } from '@/models/ShipmentDocument';
import { UpdateCarrierInvoice } from '@/services/Invoice';
import { Action } from '@ngrx/store';
import { TLEditUI } from '../models/TLEdit.ui';

export const LOAD_TL_AUDIT_EDIT = '[Finance/Shipment API] Load TL Audit Edit';
export const LOAD_TL_AUDIT_EDIT_SUCCESS = '[Finace/Shipment API] Load TL Audit Edit Success';
export const LOAD_TL_AUDIT_EDIT_ERROR = '[Finance/Shipment API] Load TL Audit Edit Error';

export const MOVE_TL_AUDIT_EDIT = '[Finance API] Move TL Audit Edit';
export const MOVE_TL_AUDIT_EDIT_SUCCESS = '[Finace API] Move TL Audit Edit Success';
export const MOVE_TL_AUDIT_EDIT_ERROR = '[Finance API] Move TL Audit Edit Error';

export const DELETE_TL_AUDIT_EDIT = '[Finance API] Delete TL Audit Edit';
export const DELETE_TL_AUDIT_EDIT_SUCCESS = '[Finace API] Delete TL Audit Edit Success';
export const DELETE_TL_AUDIT_EDIT_ERROR = '[Finance API] Delete TL Audit Edit Error';

export const LOAD_TL_AUDIT_DOCUMENTS = '[Document API] Load Documents for TL Shipment';
export const LOAD_TL_AUDIT_DOCUMENTS_SUCCESS = '[Document API] Load Documents for TL Shipment Success';
export const LOAD_TL_AUDIT_DOCUMENTS_ERROR = '[Document API] Load Documents for TL Shipment Error';

export const UPLOAD_TL_AUDIT_DOCUMENT = '[Document API] Upload Document for TL Shipment';
export const UPLOAD_TL_AUDIT_DOCUMENT_SUCCESS = '[Document API] Upload Document for TL Shipment Success';
export const UPLOAD_TL_AUDIT_DOCUMENT_ERROR = '[Document API] Upload Document for TL Shipment Error';

export const DOWNLOAD_TL_AUDIT_DOCUMENTS = '[Document API] Download Document for TL Shipment';
export const DOWNLOAD_TL_AUDIT_DOCUMENTS_SUCCESS = '[Document API] Download Document for TL Shipment Success';
export const DOWNLOAD_TL_AUDIT_DOCUMENTS_ERROR = '[Document API] Download Document for TL Shipment Error';

export class LoadTLAuditEdit implements Action {
  readonly type = LOAD_TL_AUDIT_EDIT;
  constructor(public invoiceID: number, public shipmentID: number) {}
}

export class LoadTLAuditEditSuccess implements Action {
  readonly type = LOAD_TL_AUDIT_EDIT_SUCCESS;
  constructor(public tlEditDetail: TLEditUI.TLShipment) {}
}

export class LoadTLAuditEditError implements Action {
  readonly type = LOAD_TL_AUDIT_EDIT_ERROR;
  constructor(public message: string) {}
}

export class MoveTLAuditEdit implements Action {
  readonly type = MOVE_TL_AUDIT_EDIT;
  constructor(public invoiceID: number, public updateCarrierInvoice: UpdateCarrierInvoice) {}
}

export class MoveTLAuditEditSuccess implements Action {
  readonly type = MOVE_TL_AUDIT_EDIT_SUCCESS;
  constructor() {}
}

export class MoveTLAuditEditError implements Action {
  readonly type = MOVE_TL_AUDIT_EDIT_ERROR;
  constructor(public message: string) {}
}

export class DeleteTLAuditEdit implements Action {
  readonly type = DELETE_TL_AUDIT_EDIT;
  constructor(public invoiceID: number) {}
}

export class DeleteTLAuditEditSuccess implements Action {
  readonly type = DELETE_TL_AUDIT_EDIT_SUCCESS;
  constructor() {}
}

export class DeleteTLAuditEditError implements Action {
  readonly type = DELETE_TL_AUDIT_EDIT_ERROR;
  constructor(public message: string) {}
}

export class LoadTLAuditDocuments implements Action {
  readonly type = LOAD_TL_AUDIT_DOCUMENTS;
  constructor(public shipmentID: number) {}
}

export class LoadTLAuditDocumentsSuccess implements Action {
  readonly type = LOAD_TL_AUDIT_DOCUMENTS_SUCCESS;
  constructor(public shipmentdocuments: ShipmentDocumentDTO[]) {}
}

export class LoadTLAuditDocumentsError implements Action {
  readonly type = LOAD_TL_AUDIT_DOCUMENTS_ERROR;
  constructor(public message: string) {}
}

export class UploadTLAuditDocument implements Action {
  readonly type = UPLOAD_TL_AUDIT_DOCUMENT;
  constructor(public uploadDocument: ShipmentDocumentUploadDTO) {}
}

export class UploadTLAuditDocumentSuccess implements Action {
  readonly type = UPLOAD_TL_AUDIT_DOCUMENT_SUCCESS;
  constructor(public fileName: string) {}
}

export class UploadTLAuditDocumentError implements Action {
  readonly type = UPLOAD_TL_AUDIT_DOCUMENT_ERROR;
  constructor(public message: string, public errorCode: number) {}
}

export class DownloadTLAuditDocuments implements Action {
  readonly type = DOWNLOAD_TL_AUDIT_DOCUMENTS;
  constructor(public path: string) {}
}

export class DownloadTLAuditDocumentsSuccess implements Action {
  readonly type = DOWNLOAD_TL_AUDIT_DOCUMENTS_SUCCESS;
  constructor(public fileData: string) {}
}

export class DownloadTLAuditDocumentsError implements Action {
  readonly type = DOWNLOAD_TL_AUDIT_DOCUMENTS_ERROR;
  constructor(public message: string) {}
}

export type Actions =
  | LoadTLAuditEdit
  | LoadTLAuditEditSuccess
  | LoadTLAuditEditError
  | MoveTLAuditEdit
  | MoveTLAuditEditSuccess
  | MoveTLAuditEditError
  | DeleteTLAuditEdit
  | DeleteTLAuditEditSuccess
  | DeleteTLAuditEditError
  | LoadTLAuditDocuments
  | LoadTLAuditDocumentsSuccess
  | LoadTLAuditDocumentsError
  | UploadTLAuditDocument
  | UploadTLAuditDocumentSuccess
  | UploadTLAuditDocumentError
  | DownloadTLAuditDocuments
  | DownloadTLAuditDocumentsSuccess
  | DownloadTLAuditDocumentsError;
