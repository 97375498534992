export class EnterpriseDTO {
  enterprise: Enterprise;
  enterpriseConfiguration: EnterpriseConfiguration;
  enterprisePricingConfiguration: EnterprisePricingConfiguration;
  enterpriseSalesRep: EnterpriseSalesRep;
  location: string;
  department: string;
  accountManager: string;
}

export class Enterprise {
  enterpriseID: number;
  name: string;
  accountNumber: string;
  customerCode: string;
  isDemo: boolean;
  isTruckload: boolean;
  isPrepaidAccount: boolean;
  isAccountHold: boolean;
  isRetired: boolean;
  isMatrixIQ: boolean;
  countryCode: string;
  countryName: string;
  currencyCode: string;
  currencyName: string;
}

export class EnterpriseConfiguration {
  enterpriseConfigurationID: number;
  enterpriseID: number;
  tmsid: string;
  tmskey: string;
  specialServicesEmail: string;
  specialServicesPhone: string;
  operationsEmail: string;
  operationsPhone: string;
  shipmentNotificationEmail: string;
  insuranceCustomerChargeMinValue: number;
  insuranceCustomerMarkup: number;
  isEmailInsurance: boolean;
  insuranceRequestEmail: string;
  shipmentErrorEmailRecipients: string;
  techErrorEmailRecipients: string;
  legalName: string;
  volumeCarrierMinValue: number;
  truckloadRateMarkupPercent: number;
  volumeRateMarkupPercent: number;
  isOwnFreightDesk: boolean;
  isEmailTlvolume: boolean;
  tlvolumeEmail: string;
  numberRatesToDisplay: number;
  applyCustomerPriceMarkup: boolean;
  rateRequestNotificationEmail: string;
  proActiveAuditNumber: string;
  masterBillAccountNumber: string;
  isMasterBill: boolean;
  carrierInvoiceNegVariance: number;
  customerInvoiceThreshold: number;
  isBlueShipTender: boolean;
  isGeneratePrimaryReference: boolean;
  pendingShipmentNotificationEmail: string;
  carrierInvoicePosVariance: number;
  mgdownMarkup: number;
  truckloadManagerEmail: string;
  showCorporateTl: boolean;
  isHouseAccount: boolean;
  autoAssignProstatus: string;
  website: string;
  isWhiteList: boolean;
  isBlueShipBilling: boolean;
  isProActive: boolean;
  isOpenBook: boolean;
}
export class EnterprisePricingConfiguration {
  enterprisePricingConfigurationID: number;
  enterpriseID: number;
  ruleType: string;
  startDate: Date;
  endDate: Date;
  noEndDate: boolean;
  isDateIndexOptOut: boolean;
  isCarrierIndexOptOut: boolean;
  isCostIndexOptOut: boolean;
  isCarrierSpecificPricing: boolean;
  billToNameOverride: string;
  minimumMarginAmount: number;
  leastCostPositioningOptOut: boolean;
  isPremiumPositioningOptOut: boolean;
  round: number;
}

export class EnterpriseSalesRep {
  enterpriseSalesRepID: number;
  enterpriseID: number;
  employeeID: number;
  startDate: Date;
  endDate: Date;
  isPrimary: boolean;
  isAutoApprove: boolean;
  employeeName: string;
  employeeEmail: string;
  employeePhone: string;
}
