import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CollectionService } from '../../services/collection.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { StartupService } from '../../startup.service';
import { Helpers } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { ConfirmationDialogService } from '../../_shared/confirmation-dialog/confirmation-dialog.service';
import {
  CollectionDocument,
  CollectionNote,
  CollectionPayment,
  References,
  Collection,
  CreateNote,
  CreatePayment,
  SavePayment,
  SaveNote,
  SaveCollection,
} from '../../services/Collection';
import { FileRestrictions, UploadEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { Globals } from '@/_shared/globals';
import { CollectionNoteListComponent } from '../../components/collection-note-list/collection-note-list.component';
import { CollectionPaymentListComponent } from '../../components/collection-payment-list/collection-payment-list.component';
import { CollectionInvoiceListComponent } from '../../components/collection-invoice-list/collection-invoice-list.component';
import { CollectionStatusesComponent } from '../../components/collection-statuses/collection-statuses.component';

@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss'],
})
export class CollectionDetailsComponent implements OnInit {
  @ViewChild('noteGrid', { static: false })
  noteChild: CollectionNoteListComponent;

  @ViewChild('paymentGrid', { static: false })
  paymentChild: CollectionPaymentListComponent;

  @ViewChildren('invoiceList')
  invoiceChild: CollectionInvoiceListComponent;

  @ViewChild('statusesList', { static: false })
  statusesChild: CollectionStatusesComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  collection: Collection = null;
  recordPaymentGrid: CollectionPayment[];
  recordNoteGrid: CollectionNote[];
  recordDocumentGrid: CollectionDocument[];
  recordInvoiceGrid: CollectionDocument[];
  invoiceGridShowLoader = false;

  selectedDocumentType: any;
  isDataloading: boolean;
  showFileUpload: boolean;
  references: References = null;
  openEditor: number;
  dateWrittenOff: Date;
  editedRowIndex: number;
  collectionId: number;
  tempRecordID: number;
  helpers: Helpers;

  public defaultDocumentItem: { name: string; id: number } = {
    name: 'Please select...',
    id: null,
  };

  paymentFormGroup: FormGroup;

  uploading = false;
  file: any;
  public uploadSaveUrl: string;
  public uploadRestrictions = Globals.UploadFileRestrictions;

  constructor(
    private pcService: CollectionService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private startupService: StartupService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  // events
  ngOnInit() {
    this.isDataloading = false;
    this.showFileUpload = false;
    this.tempRecordID = -1;
    this.openEditor = 0;
    this.helpers = new Helpers(this.authService);
    this.uploadSaveUrl = `${this.startupService.financeAPIUrl}v2/collections/documents `;

    // are they allowed to view this page
    if (!this.helpers.hasOperation('CanViewCollection') && !this.helpers.hasOperation('CanWorkCollection')) {
      this.router.navigate([`not-authorized`]);
    }

    // load references
    this.pcService.getReferences().subscribe((data) => {
      this.references = data;
      this.loadRecords();
    });
  }

  protected onStatusChanged($event) {
    // update the DB
    this.pcService.update(this.collection.id, $event.savecollection).subscribe(
      (data) => {
        this.collection = this.pcService.collection;
        this.collection.totalPayments = this.getTotalPayents();
        this.updateRemainingBalance();

        // if ($event.callback && typeof $event.callback === 'function') {
        //  $event.callback();
        // }
        this.alertMessage.showAlertMessage('Successfully Updated.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Updating Statuses.', 'Error');
      }
    );
  }

  ////////////////////////////////////////////////////////
  // payment handlers
  public onPaymentInsert(dataItem) {
    // insert into the DB
    this.insertPaymentDB(dataItem);
  }
  public onPaymentUpdate(dataItem) {
    // update the db
    this.updatePaymentDB(dataItem);
  }
  public onPaymentRemove(dataItem) {
    // delete from the DB
    this.pcService.deletePayment(dataItem.id, this.collection.id).subscribe(
      (data) => {
        this.collection.payments = data;
        this.collection.totalPayments = this.getTotalPayents();
        this.updateRemainingBalance();
        this.recordPaymentGrid = data;
        this.alertMessage.showAlertMessage('Payment Successfully Deleted.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Deleting Payment.', 'Error');
      }
    );
  }

  ////////////////////////////////////////////////////
  // document handlers
  public onReImportInvoices() {
    this.invoiceGridShowLoader = true;
    this.pcService.reImportInvoices(this.collectionId).subscribe(
      (collection) => {
        this.invoiceGridShowLoader = false;
        this.loadRecords();
      },
      (error) => {
        this.invoiceGridShowLoader = false;
        this.alertMessage.showAlertMessage('Error Re-Importing outstanding Invoices for Bad Debt.', 'Error');
      }
    );
  }
  public onDownloadDocument(dataItem) {
    const self = this;
    this.pcService.getDocument(dataItem.id).subscribe(
      (data) => {
        const document = [data];
        this.helpers.downloadFile(document, dataItem.fileName, dataItem.fileFormat);
      },
      (err) => {
        this.alertMessage.showAlertMessage('Error Downloading Document.', 'Error');
      }
    );
  }

  protected documentTypeChanged(obj) {
    this.showFileUpload = obj.id == null ? false : true;
    this.selectedDocumentType = obj;
  }
  public onDocumentRemove(event, dataItem) {
    // confirm
    const that = this;
    this.confirmationDialogService
      .confirm('Confirm Deletion', 'Do you really want to delete this record?', 'Delete', 'Cancel')
      .then(function (result) {
        if (result === true) {
          that.removeDocument(dataItem);
        }
      })
      .catch(() => 'Nope!');
  }
  uploadEventHandler(e: UploadEvent) {
    // we only allow one file
    this.file = e.files[0];
    e.data = {
      collectionId: this.collection.id,
      filename: this.file.name.replace(/\.[^/.]+$/, ''),
      documentTypeId: this.selectedDocumentType.id,
      documentType: this.selectedDocumentType.name,
      documentFormat: this.file.extension.substr(1),
    };
  }
  successEventHandler(e: SuccessEvent) {
    this.collection.documents = e.response.body.documents;
    this.recordDocumentGrid = this.collection.documents;
    this.collection.invoices = e.response.body.invoices;
    this.recordInvoiceGrid = this.collection.invoices;
  }
  errorEventHandler(e: ErrorEvent) {
    // this.alertMessage.showAlertMessage(e.response.error, 'Error');
  }

  ////////////////////////////////////////////////////
  // Note handlers
  public onNoteInsert(dataItem) {
    this.insertNoteDB(dataItem);
  }
  public onNoteUpdate(dataItem) {
    this.updateNoteDB(dataItem);
  }
  public onNoteRemove(dataItem) {
    // delete from the DB
    this.pcService.deleteNote(dataItem.id, this.collection.id).subscribe(
      (data) => {
        this.collection.notes = data;
        this.recordNoteGrid = data;
        this.alertMessage.showAlertMessage('Note Successfully Deleted.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Deleting Note.', 'Error');
      }
    );
  }

  // helpers
  public loadRecords() {
    this.isDataloading = true;
    this.collectionId = +this.route.snapshot.paramMap.get('id');
    this.pcService.getCollection(this.collectionId).subscribe(
      (data) => {
        this.collection = this.pcService.collection;
        this.recordNoteGrid = this.collection.notes;
        this.recordPaymentGrid = this.collection.payments;
        this.recordDocumentGrid = this.collection.documents;
        this.recordInvoiceGrid = this.collection.invoices;
        this.collection.totalPayments = this.getTotalPayents();
        this.dateWrittenOff = new Date(this.collection.dateWrittenOff);
        this.isDataloading = false;

        // only import invoices if we don't have any yet and this is a
        // new collection identified by query string
        if (this.collection.invoices.length === 0) {
          if (this.route.snapshot.queryParamMap.get('n') === 'true') {
            this.invoiceGridShowLoader = true;
            this.pcService.importInvoices(this.collectionId).subscribe(
              (invoices) => {
                this.invoiceGridShowLoader = false;
                this.recordInvoiceGrid = invoices;
                this.collection.invoices = invoices;
              },
              (error) => {
                this.invoiceGridShowLoader = false;
                this.alertMessage.showAlertMessage('Error Importing outstanding Invoices for Bad Debt.', 'Error');
              }
            );
          }
        }
      },
      (error) => {
        this.isDataloading = false;
        this.alertMessage.showAlertMessage('Error Loading Collection Information.', 'Error');
      }
    );
  }

  // payments
  private updatePaymentDB(record: CollectionPayment) {
    const saveRecord = new SavePayment();
    saveRecord.collectionID = this.collection.id;
    saveRecord.authorizationCode = record.authorizationCode;
    saveRecord.paymentAmount = record.paymentAmount;
    saveRecord.agencyInvoiceNumber = record.agencyInvoiceNumber;
    saveRecord.agencyInvoiceAmount = record.agencyInvoiceAmount;
    saveRecord.paymentDate = record.paymentDate;

    this.pcService.updatePayment(record.id, saveRecord).subscribe(
      (data) => {
        this.collection.payments = data;
        this.collection.totalPayments = this.getTotalPayents();
        this.updateRemainingBalance();

        this.isOverPayment();
        this.recordPaymentGrid = data;
        this.alertMessage.showAlertMessage('Payment Successfully Updated.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Updating Payment.', 'Error');
      }
    );
  }
  private insertPaymentDB(record: CollectionPayment) {
    const saveRecord = new CreatePayment();
    Object.assign(saveRecord, record);
    saveRecord.collectionID = this.collection.id;
    saveRecord.agencyID = this.collection.agencyID;
    saveRecord.escalatedAgencyID = this.collection.escalatedAgencyID;
    saveRecord.collectorID = this.collection.collectorID;

    this.pcService.savePayment(saveRecord).subscribe(
      (data) => {
        this.collection.payments = data;
        this.collection.totalPayments = this.getTotalPayents();
        this.updateRemainingBalance();

        this.isOverPayment();
        this.recordPaymentGrid = data;
        this.alertMessage.showAlertMessage('Payment Successfully Added.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Saving Payment.', 'Error');
      }
    );
  }

  // Notes
  private updateNoteDB(record: CollectionNote) {
    const saveRecord = new SaveNote();
    saveRecord.collectionID = this.collection.id;
    saveRecord.note = record.noteText;

    this.pcService.updateNote(record.id, saveRecord).subscribe(
      (data) => {
        this.collection.notes = data;
        this.recordNoteGrid = data;
        this.alertMessage.showAlertMessage('Note Successfully Updated.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Updating Note.', 'Error');
      }
    );
  }
  private insertNoteDB(record: CollectionNote) {
    const saveRecord = new CreateNote();
    saveRecord.note = record.noteText;
    saveRecord.collectionID = this.collection.id;

    this.pcService.saveNote(saveRecord).subscribe(
      (data) => {
        this.collection.notes = data;
        this.recordNoteGrid = data;
        this.alertMessage.showAlertMessage('Note Successfully Added.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Inserting Note.', 'Error');
      }
    );
  }

  public removeDocument(dataItem) {
    // remove the record  from the array
    this.recordDocumentGrid = this.recordDocumentGrid.filter((obj) => obj !== dataItem);
    this.collection.documents = this.collection.documents.filter((obj) => obj !== dataItem);

    // delete from the DB
    this.pcService.deleteDocument(dataItem.id, this.collection.id).subscribe(
      (data) => {
        this.alertMessage.showAlertMessage('Document Successfully Deleted.', 'Success');
      },
      (error) => {
        this.alertMessage.showAlertMessage('Error Deleting Document.', 'Error');
      }
    );
  }

  // helpers
  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }
  get userName(): string {
    if (!this.authService.BlueShipUser) {
      return sessionStorage.getItem('userName') || '';
    }
    sessionStorage.setItem('userName', this.authService.BlueShipUser.name);
    return this.authService.BlueShipUser.name;
  }
  public getTotalPayents(): number {
    let total = 0;
    if (this.collection.payments.length > 0) {
      this.collection.payments.forEach((x) => (total += x.paymentAmount));
    }
    return total;
  }
  public updateRemainingBalance() {
    this.collection.remainingBalance = this.collection.balanceSentToCollection - this.collection.totalPayments;
    this.checkStatusForBalance();
  }

  public checkStatusForBalance() {
    const currentStatus = this.references.statuses.find((obj) => obj.id === this.collection.statusID).status;

    if (this.collection.remainingBalance > 0 && currentStatus === 'Closed – Paid in Full') {
      const changeToStatus = this.references.statuses.find((obj) => obj.status === 'Receiving Payments').id;
      this.collection.statusID = changeToStatus;

      // persist the new status
      const sc = new SaveCollection();
      sc.dateWrittenOff = this.dateWrittenOff;
      sc.statusID = this.collection.statusID;
      sc.agencyID = this.collection.agencyID;
      sc.escalatedAgencyID = this.collection.escalatedAgencyID;
      sc.collectorID = this.collection.collectorID;

      this.onStatusChanged({ savecollection: sc, callback: null });
    }
  }
  public isOverPayment() {
    if (this.collection.remainingBalance < 0) {
      this.alertMessage.showAlertMessage('This causes the total payments to be more than the Balance sent to collection.', 'Warning');
    }
  }
}
