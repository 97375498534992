import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { StartupService } from '../startup.service';
import { catchError, map } from 'rxjs/operators';
@Injectable()
export class HealthService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202', // -->Add this line
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {}

  // collections
  isAvailable(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.startupService.financeAPIUrl}v2/healthmonitor/isavailable`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
