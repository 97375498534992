import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as TLAuditBBillActions from '../actions/tl-audit-bbill.actions';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { TLAuditService } from '../services/tl-audit.service';

@Injectable()
export class TLAuditBBillEffects {
  @Effect()
  saveBBillData$ = this.actions$.pipe(
    ofType(TLAuditBBillActions.SAVE_BBILL),
    mergeMap((action: TLAuditBBillActions.SaveBBill) =>
      this.tlAuditServicce.createTruckloadBBill(action.bbill, action.invoiceID).pipe(
        map((initialState) => {
          return new TLAuditBBillActions.SaveBBillSuccess(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditBBillActions.SaveBBillError(err.error));
        })
      )
    )
  );
  @Effect()
  getBBillRefData$ = this.actions$.pipe(
    ofType(TLAuditBBillActions.LOAD_SETTLEMENT_REASONS),
    mergeMap((action: TLAuditBBillActions.LoadSettlementReasons) =>
      this.tlAuditServicce.getTLAuditBBillSettlmentReasonData().pipe(
        map((initialState) => {
          return new TLAuditBBillActions.LoadSettlementReasonsSuccess(initialState);
        }),
        catchError((err) => {
          return of(new TLAuditBBillActions.LoadSettlementReasonsError(err.error));
        })
      )
    )
  );
  constructor(private actions$: Actions, private tlAuditServicce: TLAuditService) {}
}
