import { ShipmentDTO, CarrierCharge, Item } from '@/services/Shipment';
import { CarrierInvoiceResponse } from '@/models/Carrier';
import { Invoice, InvoiceCharge } from '@/services/InvoiceAudit';
import { Globals } from '@/_shared/globals';
import { Helpers } from '@/_shared/helpers';
import { ActiveAuditResponse, ActiveCarrierAuditResponse } from '@/services/AuditQueue';

export namespace AuditCarrierInvoiceUI {
  export class CarrierInvoiceAudit implements Invoice {
    shipmentID: number;
    invoiceID: number;
    invoiceTotal: number;
    status: string;
    mode: string;
    enterpriseName: string;
    carrierCode = 'n/a';
    bol: string;
    pro = '';
    dateShipped: Date = null;
    dateCreated: Date = null;
    mcNumber = 'n/a';
    dotNumber = 'n/a';
    lane: string;
    extraPro = '';
    // editable
    invoiceNumber = '';
    invoiceDate = new Date();
    paymentMethod: string;
    scac: string;
    carrierName: string;
    weight: number;
    distance: number;

    lineHaulCharges: InvoiceCharge[] = null;
    accessorialCharges: InvoiceCharge[] = null;
    currencyCode?: string;
    normalizedTotal?: number;
    qualifiers = Globals.Qualifiers;
    helpers: Helpers;
    constructor() { }

    // for a new invoice
    public setFinanceCarrierInvoices(invoiceRecord: CarrierInvoiceResponse, shipmentRecord: ShipmentDTO) {
      this.invoiceID = invoiceRecord.invoiceID;
      this.shipmentID = invoiceRecord.shipmentID;
      this.distance = invoiceRecord.distance;
      this.status = invoiceRecord.status;
      this.mode = invoiceRecord.mode;

      this.invoiceTotal = invoiceRecord.invoiceTotal;
      this.scac = invoiceRecord.scac;

      this.currencyCode = invoiceRecord.currencyCode;
      this.mcNumber = invoiceRecord.mcNumber === null || invoiceRecord.mcNumber.length === 0 ? 'n/a' : invoiceRecord.mcNumber;
      this.dotNumber = invoiceRecord.dotNumber === null || invoiceRecord.dotNumber.length === 0 ? 'n/a' : invoiceRecord.dotNumber;
      this.carrierCode = invoiceRecord.carrierCode === null || invoiceRecord.carrierCode.length === 0 ? 'n/a' : invoiceRecord.carrierCode;
      this.weight = invoiceRecord.invoiceWeight;
      this.carrierName = invoiceRecord.carrierName;
      this.normalizedTotal = invoiceRecord.normalizedTotal;

      if (invoiceRecord.invoiceDate != null) {
        this.invoiceDate = new Date(invoiceRecord.invoiceDate);
      }
      if (invoiceRecord.actualShipDate != null) {
        this.dateShipped = new Date(invoiceRecord.actualShipDate);
      }
      if (shipmentRecord.dateCreated != null) {
        this.dateCreated = new Date(shipmentRecord.dateCreated);
      }

      // from shipping API
      this.enterpriseName = shipmentRecord.enterpriseName;
      this.bol = shipmentRecord.primaryReference;
      this.setProNumber(shipmentRecord.references);
      this.setLane(shipmentRecord.addresses);
      this.setPaymentMethod(shipmentRecord.paymentMethod);
      this.invoiceNumber = invoiceRecord.invoiceNumber;

      // Map to carrier charge
      const convertedCharges = invoiceRecord.charges.map(
        ({
          invoiceChargeID: chargeID,
          description,
          freightClass,
          fakFreight: fakFreightClass,
          rate,
          rateQualifier: rateCode,
          rateQualifier = '',
          quantity,
          weight,
          dimWeight,
          amount,
          type,
          ediCode,
          group = '',
        }) => ({
          chargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateQualifier,
          rateCode,
          quantity,
          weight,
          dimWeight,
          amount,
          type,
          ediCode,
          group,
        })
      );

      const newCharges = this.addAdditionalCharges(convertedCharges);
      this.setCharges(newCharges);

      // map the rate codes for both charge types
      if (this.lineHaulCharges != null) {
        this.lineHaulCharges.map(this.getQualifier, this);
      }
      if (this.accessorialCharges != null) {
        this.accessorialCharges.map(this.getQualifier, this);
      }
    }
    public setShipmentCarrierInvoices(record: ShipmentDTO, helpers: Helpers) {
      this.shipmentID = record.id;
      this.bol = record.primaryReference;
      this.distance = record.distance;
      this.status = record.status;
      this.mode = record.mode;
      this.carrierName = record.carrierName;
      this.enterpriseName = record.enterpriseName;
      this.currencyCode = record.selectedRate.currencyCode;
      this.invoiceTotal = record.selectedRate.carrierTotal;
      this.scac = record.selectedRate.scac;
      this.mcNumber =
        record.selectedRate.mcNumber === null || record.selectedRate.mcNumber.length === 0 ? 'n/a' : record.selectedRate.mcNumber;
      this.dotNumber =
        record.selectedRate.dotNumber === null || record.selectedRate.dotNumber.length === 0 ? 'n/a' : record.selectedRate.dotNumber;
      this.carrierCode =
        record.selectedRate.carrierCode === null || record.selectedRate.carrierCode.length === 0 ? 'n/a' : record.selectedRate.carrierCode;
      this.weight = this.getTotalWeight(record.items);

      if (!isNaN(Date.parse(record.dateCreated))) {
        this.dateCreated = new Date(record.dateCreated);
      }
      if (!isNaN(Date.parse(record.actualPickupDate))) {
        this.dateShipped = new Date(record.actualPickupDate);
      }

      this.setProNumber(record.references);
      this.setLane(record.addresses);
      this.setPaymentMethod(record.paymentMethod);
      this.invoiceNumber = this.pro;

      // only prefill on TL modes
      if (helpers.isTL(record.mode) && this.pro === '') {
        this.invoiceNumber = record.primaryReference;
      }

      record.selectedRate.carrierCharges = this.addAdditionalCharges(record.selectedRate.carrierCharges);
      this.setCharges(record.selectedRate.carrierCharges);

      // map the rate codes for both charge types
      if (this.lineHaulCharges != null) {
        this.lineHaulCharges.map(this.getQualifier, this);
      }
      if (this.accessorialCharges != null) {
        this.accessorialCharges.map(this.getQualifier, this);
      }
    }
    public get charges(): InvoiceCharge[] {
      if (this.accessorialCharges == null || this.lineHaulCharges == null) {
        return null;
      }
      return this.accessorialCharges.concat(this.lineHaulCharges);
    }

    // helpers
    private addAdditionalCharges(carrierCharges: any[]): CarrierCharge[] {
      // are there any linehauls? (filter out Fuel types since those are Accessorial)
      if (!carrierCharges.some((o) => Globals.ChargeTypes.indexOf(o.type) >= 0)) {
        const charge = [
          {
            chargeID: -1,
            description: 'Linehaul',
            freightClass: 0,
            fakFreightClass: 0,
            rate: 0.0,
            rateQualifier: 'FR',
            quantity: 0.0,
            weight: 0.0,
            amount: 0.0,
            type: 'ITEM',
            group: ' Linehaul',
            dimWeight: 0,
            ediCode: '',
            sequence: 0,
            isMin: false,
            isMax: false,
            isNonTaxable: false,
            error: false,
          } as CarrierCharge,
        ];
        carrierCharges.push(...charge);
      }

      // are there any accessorials? (include Fuel types since those are Accessorial)
      if (!carrierCharges.some((o) => Globals.ChargeTypes.indexOf(o.type) < 0)) {
        const charge = [
          {
            chargeID: -2,
            description: 'Fuel Surcharge Percent',
            freightClass: 0,
            fakFreightClass: 0,
            rate: 0.0,
            rateQualifier: 'PCT',
            quantity: 0.0,
            weight: 0.0,
            amount: 0.0,
            type: 'ACCESSORIAL_FUEL',
            group: 'Accessorial',
            dimWeight: 0,
            ediCode: '',
            sequence: 0,
            isMin: false,
            isMax: false,
            isNonTaxable: false,
            error: false,
          } as CarrierCharge,
        ];
        carrierCharges.push(...charge);
      }
      return carrierCharges;
    }
    private setLane(addresses: any) {
      if (addresses.length < 2) {
        return;
      }
      let originLane = addresses[0].internationalGoverningDistrict === null ? (addresses[0].stateProvince + ', ' + addresses[0].postalCode) : (addresses[0].countryCode + ', ' + addresses[0].internationalGoverningDistrict);
      let destinationLane = addresses[1].internationalGoverningDistrict === null ? (addresses[1].stateProvince + ', ' + addresses[1].postalCode) : (addresses[1].countryCode + ', ' + addresses[1].internationalGoverningDistrict);
      this.lane =
        originLane + ' to ' + destinationLane;
    }
    private setPaymentMethod(paymentMethod: string) {
      this.paymentMethod = paymentMethod === null || paymentMethod === undefined ? 'Third Party' : paymentMethod;
    }
    private setProNumber(references: any) {
      const item = references.filter(function (el) {
        return el.type === 'PRO';
      });
      // if no item found leave default
      // else if one grab it
      if (item.length === 1) {
        this.pro = item[0].value;
      }
      // multiple pros, it appears the call returns them in order they were added to the shipment
      // so grabbing last in the list is prudent, even without date to proove
      if (item.length > 1) {
        this.pro = item[item.length - 1].value;
        item.pop();
        this.extraPro = item.map((it) => it.value).join(', ');
      }
    }
    private setCharges(carrierCharges: any[]) {
      const charges = carrierCharges.map(
        ({
          chargeID: invoiceChargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateQualifier: rateCode,
          rateQualifier = '',
          quantity,
          weight,
          isMin,
          isMax,
          dimWeight,
          amount,
          type,
          ediCode,
          group = '',
          canEdit = '',
        }) => ({
          invoiceChargeID,
          description,
          freightClass,
          fakFreightClass,
          rate,
          rateCode,
          rateQualifier,
          quantity,
          weight,
          isMin,
          isMax,
          dimWeight,
          amount,
          type,
          ediCode,
          group,
          canEdit,
        })
      );

      // not ideal, but map would not work with the filter inline
      for (const charge of charges) {
        charge.group = Globals.ChargeTypes.indexOf(charge.type) === -1 ? 'Accessorial' : ' Linehaul';
      }

      // split the list and sort them
      if (charges != null && charges.length > 0) {
        // split them
        this.lineHaulCharges = charges.filter(function (el) {
          return el.group === ' Linehaul';
        });
        this.accessorialCharges = charges.filter(function (el) {
          return el.group === 'Accessorial';
        });

        // sort them
        if (this.lineHaulCharges != null && this.lineHaulCharges.length > 0) {
          this.lineHaulCharges.sort((a, b) => (a.amount < b.amount ? 1 : -1));
        }
        if (this.accessorialCharges != null && this.accessorialCharges.length > 0) {
          this.accessorialCharges.sort((a, b) => (a.amount < b.amount ? 1 : -1));
        }
      }
    }
    private getQualifier(invoiceCharge: InvoiceCharge) {
      const record = this.qualifiers.find((obj) => {
        return obj.rateCode === invoiceCharge.rateQualifier;
      });

      // If we dont find it keep it as it is
      if (record == null) {
        invoiceCharge.canEdit = false;
      } else {
        invoiceCharge.rateQualifier = record.rateQualifier;
        invoiceCharge.canEdit = true;
      }
    }
    private getTotalWeight(itemList: Item[]): number {
      let total = 0;
      for (const item of itemList) {
        total += item.weightUOM === 'lbs' ? item.weight : this.kToLbs(item.weight);
      }
      return total;
    }
    private kToLbs(k: number): number {
      const nearExact = k / 0.45359237;
      return Math.round(nearExact);
    }

    public static setCarrierModelExcel(data: ActiveCarrierAuditResponse[]) {
      return data.map(data => {
        return {
          Owner: data.owner,
          Account: data.ownerAccount,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          Bill_Name: data.billName,
          SCAC: data.scac,
          Variance: data.variance,
          A_Team_Note: data.aTeamNote,
          Sent: data.emailHistory,
          Ship_Date: data.actualDate
        }
      })
    }

    public static setProactiveModelExcel(data: ActiveAuditResponse[]) {
      return data.map(data => {
        return {
          Auditor: data.auditor,
          Owner: data.owner,
          Account: data.ownerAccount,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          Age: data.agedDays,
          Pro: data.pro,
          SCAC: data.scac,
          Variance: data.variance,
          Margin: data.margin,
          Status: data.status,
          Latest_Note: data.latestNote,
          Ship_Date: data.actualDate
        }
      })
    }

    public static setSecundaryModelExcel(data: ActiveAuditResponse[]) {
      return data.map(data => {
        return {
          Type: data.secondaryCategory,
          Auditor: data.auditor,
          Owner: data.owner,
          Account: data.ownerAccount,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          Pro: data.pro,
          SCAC: data.scac,
          Variance: data.variance,
          Latest_Note: data.latestNote,
          Ship_Date: data.actualDate
        }
      })
    }

    public static setQuickTariffModelExcel(data: ActiveAuditResponse[]) {
      return data.map(data => {
        return {
          Auditor: data.auditor,
          Owner: data.owner,
          Account: data.ownerAccount,
          Contract_Name: data.contractName,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          Pro: data.pro,
          SCAC: data.scac,
          Variance: data.variance,
          Status: data.status,
          Latest_Note: data.latestNote,
          Ship_Date: data.actualDate
        }
      })
    }

    public static setBGModelExcel(data: ActiveAuditResponse[]) {
      return data.map(data => {
        return {
          Auditor: data.auditor,
          Owner: data.owner,
          Account: data.ownerAccount,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          Pro: data.pro,
          SCAC: data.scac,
          Variance: data.variance,
          Status: data.status,
          Latest_Note: data.latestNote,
          Error_Type: data.errorType,
          Ship_Date: data.actualDate
        }
      })
    }


    public static setReturnModelExcel(data: ActiveAuditResponse[]) {
      return data.map(data => {
        return {
          Auditor: data.auditor,
          Owner: data.owner,
          Account: data.ownerAccount,
          Second: data.isSecondary,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          Pro: data.pro,
          SCAC: data.scac,
          Variance: data.variance,
          Queue: data.queue,
          Latest_Note: data.latestNote,
          Ship_Date: data.actualDate
        }
      })
    }

    public static setDocumentModelExcel(data: ActiveAuditResponse[]) {
      return data.map(data => {
        return {
          Auditor: data.auditor,
          Shipper: data.shipper,
          Account: data.ownerAccount,
          Consignee: data.consignee,
          Primary_Reference: data.primaryReference,
          Invoice_Number: data.invoiceNumber,
          Invoice_Date: data.invoiceDate,
          SCAC: data.scac,
          Variance: data.variance,
          Status: data.status,
          Latest_Note: data.latestNote,
          Ship_Date: data.actualDate
        }
      })
    }
  }



}
