export class ActiveAuditResponse {
  id: number;
  shipmentID: number;
  enterpriseID: number;
  invoiceNumber: string;
  invoiceID: number;
  invoiceDate: Date;
  invoiceDateString: string;
  proActive: boolean;
  shipper: string;
  consignee: string;
  scac: string;
  queue: string;
  owner: string;
  ownerAccount: string;
  primaryReference: string;
  variance: number;
  varianceString: string;
  secondaryCategory: string;
  auditor: string;
  status: string;
  isSecondary: boolean;
  latestNote: string;
  errorType: string;
  errorReason: string;
  settlementReason: string;
  billName: string;
  pro: string;
  contractName?: string;
  margin?: number;
  agedDays?: number;
  actualDate?: Date;
}

export class GridState {
  take: number;
  skip: number;
  sort: [];

  constructor() {
    this.take = 25;
    this.skip = 0;
    this.sort = [];
  }
}

export class ActiveCarrierAuditResponse extends ActiveAuditResponse {
  invoiceID: number;
  billName: string;
  ownerAccount: string;
  type: string;
  lastUser: string;
  lastUpdated: string;
  settlementReason: string;
  aTeamNote: string;
  emailHistory: string;
  hasSecondary: boolean;
  // isSecondary: boolean;
}

// add a queue response
export class CreateAuditStatusNoteResponse {
  id: number;
  assignedTo: string;
  invoiceNumber: string;
  shipmentID: number;
  note: string;
  status: string;
  errorType: string;
  contractReason: string;
}

// updates a queue
export class CreateAuditStatusNote {
  assignedTo: string;
  shipmentID: number;
  invoiceNumber: string;
  queueType: string;
  note: string;
  status: string;
  settlementReasons: string[];
  errorType: string;
  contractReason: string;
}

// updates to notes
export class SaveAuditStatusNote {
  invoiceAuditID: number;
  shipmentID: number;
  note: string;
  status: string;
}
export class SaveBGAuditStatusNote {
  invoiceAuditID: number;
  shipmentID: number;
  note: string;
  status: string;
  errorType: string;
  contractReason: string;
}
export class SaveCustomerAuditStatusNote {
  invoiceAuditID: number;
  shipmentID: number;
  note: string;
  status: string;
  settlementReasons: string[];
}
export class UpdateStatusNote {
  selectedStatus: string;
  selectedErrorType: string;
  selectedContractReason: string;
  settlementReasonList: string[];
  textNotes: string;
  selectCount: number;
  latestNote?: string;
}

// references
export class InvoiceAuditRefDataResponse {
  contractReason: string[];
  errorTypes: string[];
  settlementReasons: string[];
  bgAuditStatus: string[];
  quickTariffAuditStatus: string[];
  customerAuditStatus: string[];
  carrierAuditStatus: string[];
  docsAuditStatus: string[];
}

export class SendCarrierDisputeEmail {
  shipmentID: number;
  invoiceAuditID: number;
  enterpriseName: number;
  primaryReference: string;
  invoiceNumber: string;
  scac: string;
  cleanedNote: string;
  status: string;
}
