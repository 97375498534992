import { Charge } from '@/services/InvoiceAudit';

export class ShipmentQuoteHistoryResponse {
  shipmentID: number;
  primaryReference: string;
  quotes: [QuoteHistory];
}

export class QuoteHistory {
  carrierIdentifier: string;
  carrier: string;
  scac: string;
  isSelected: boolean;
  isExcluded: boolean;
  originalSelected: boolean;
  quoteDate: Date;
  updatedBy: string;
  updatedDate: Date;
  chargeModel: string;
  createdBy: string;
  status: string;
  analogousID: string;
  // Carrier price details
  charge: QuoteDetail;
  // Customer price details
  cost: QuoteDetail;
}

export class QuoteDetail {
  quoteID: number;
  contractID: string;
  contractName: string;
  carrier: string;
  pricesheetID: string;
  total: number;
  subTotal: number;
  freightTotal: number;
  serviceTotal: number;
  originalTotal: number;
  minimum: number;
  minimumDifferential: number;
  normalizedTotal: number;
  editCost: number;
  normalizedCurrencyCode: string;
  totalWeight: 0;
  mCNumber: string;
  charges: [QuoteCharge];
  freightCharges: [QuoteCharge];
  serviceCharges: [QuoteCharge];
}

export class QuoteCharge extends Charge {
  quoteID: number;
  sequenceNumber: number;
  itemGroupID: number;
  ediCode: string;
  quoteTotalWeighting: number;
  isLinehaul: boolean;
  isAccessorial: boolean;
}
