import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends DialogContentBase implements OnInit {
  readonly DEFAULT_CONFIRM_TEXT = 'Okay';
  readonly DEFAULT_CANCEL_TEXT = 'Cancel';

  @Input()
  content: string;

  @Input()
  confirmText: string;

  @Input()
  cancelText: SafeHtml;

  private sanitizedContent: string;

  constructor(
    public dialog: DialogRef,
    private sanitizer: DomSanitizer) {
    super(dialog);
  }

  ngOnInit() {
    this.sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, this.content);
  }
}
