export class AddressSave {
  earliestDate: Date;
  latestDate: Date;
  actualDate?: Date;
  lateReason: string;
  lateReasonCode: string;
  earliestAppointmentDate?: Date;
  latestAppointmentDate?: Date;
  actualAppointmentDate?: Date;
  appointmentType?: any;
  appointmentNumber?: any;
  locationCode: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  isResidential?: boolean;
  isPrimary?: boolean;
  companyName: string;
  stateProvince: string;
  countryCode: string;
  contactName: string;
  contactPhone: string;
  locationComments: string;
  latDegrees?: any;
  latDirection?: any;
  longDegrees?: any;
  longDirection?: any;
  sequence: number;
}

export class AccessorialSave {
  accessorials: AccessorialsEntity[];
}
export class AccessorialsEntity {
  code: string;
  name: string;
}

export class DimensionSave {
  type: string;
  uom: string;
  length: number;
  width: number;
  height: number;
}

export class HazMatDetailSave {
  shippingName: string;
  hazMatIdentifier: string;
  packageGroup: string;
  contactName: string;
  contactPhone: string;
  isHazMatPlacards?: any;
  placardsDetails?: any;
  flashPointTempUom?: any;
  flashPointTemp?: any;
  emsnumber?: any;
  hazMatClass: string;
}

export class QuantitySave {
  uom: string;
  value: number;
  numberOfPieces: number;
  piecesUom: string;
}

export class WeightSave {
  uom: string;
  value: number;
}

export class ItemSaveResult {
  shipmentID: number;
  items: ItemSave[];
}

export class ItemSave {
  itemID: number;
  itemIdentifier = '';
  description: string;
  freightClass: number;
  nmfc: string;
  hawb = '';
  cubicVolume = 0;
  isStackable = false;
  productNumber = '';
  isTempSensitive = false;
  lowTemp = 0;
  highTemp = 0;
  tempUom = 'F';
  fluidVolume = 0;
  fluidVolumeUom = '';
  glcode = '';
  monetaryValue = 0;
  orderRef = '';
  itemCategoryName?= '';
  itemCommodityName?= '';
  pickupAddressSequence = 1;
  dropOffAddressSequence = 2;
  dimension: DimensionSave;
  hazMatDetail: HazMatDetailSave = null;
  quantity: QuantitySave;
  weight: WeightSave;
}
