import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { conver24HourTo12Hour, convert12HourTo24Hour, rowCallback } from '@/_shared/helpers';
import { ApproveToPaySchedule, DayOfWeek } from '@/models/ApproveToPaySchedule';
import { ApproveToPayService } from '@/services/approve-to-pay-service';
import { ConfirmDialogComponent } from '@/bg-common/confirm-dialog/confirm-dialog.component';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { AuthService } from '@/auth/auth.service';
import { AuthOperation } from '@/auth/AuthOperation';

class ApproveToPayScheduleRecord {
  id: number;
  approveToPayID: number;
  dayOfWeek: DayOfWeek;
  hour: number;
  twelveHourClock: 'AM' | 'PM';
  get time() { return `${this.hour} ${this.twelveHourClock}`; }
};

@Component({
  selector: 'app-approve-to-pay-schedule-list',
  templateUrl: './approve-to-pay-schedule-list.component.html',
})
export class ApproveToPayScheduleListComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;
  @ViewChild('grid', { static: false })
  grid: GridComponent;
  @Input('approveToPayID')
  approveToPayID: number;

  editRow: Omit<ApproveToPayScheduleRecord, 'time'>;
  destroy$ = new Subject();
  schedules: ApproveToPayScheduleRecord[];
  isDataLoading = false;
  dayOfWeekList = Object.values(DayOfWeek);
  rowCallback = rowCallback;

  constructor(
    private atpService: ApproveToPayService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getSchedules();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getSchedules() {
    this.isDataLoading = true;

    this.atpService
      .getApproveToPaySchedules(this.approveToPayID)
      .subscribe(
        (res) => {
          this.schedules = res.map((x) => this.mapScheduleDtoToRecord(x)).reverse();
          this.isDataLoading = false;
        },
        () => {
          this.alertMessage.showAlertMessage('Error loading Approve To Pay Schedules', 'Error');
          this.isDataLoading = false;
        }
      );
  }

  canEdit() {
    return this.authService.hasOperation(AuthOperation.CanEditCustomerProfile);
  }

  editHandler({ rowIndex }: { rowIndex: number }) {
    this.cancelEditing();

    this.editRow = { ...this.schedules[rowIndex] };
    this.grid.editRow(rowIndex);
  }

  cancelEditing() {
    if (this.editRow) {
      this.grid.closeRow(this.schedules.findIndex((x) => x.id === this.editRow.id));
      this.editRow = null;
    }
  }

  saveHandler() {
    this.editRow.id === -1 ? this.createSchedule() : this.updateSchedule();
  }

  removeHandler({ dataItem }: { dataItem: ApproveToPayScheduleRecord }) {
    this.cancelEditing();

    const dialog = this.dialogService.open({
      title: 'Confirm',
      content: ConfirmDialogComponent
    });

    const instance = dialog.content.instance as ConfirmDialogComponent;
    instance.content = `Are you sure you want to delete schedule '${dataItem.dayOfWeek} - ${dataItem.time}'?`;
    instance.confirmText = 'Delete';

    dialog.result
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (!(result instanceof DialogCloseResult)) {
          this.deleteSchedule(dataItem);
        }
      });
  }

  addHandler() {
    this.editRow = {
      id: -1,
      approveToPayID: this.approveToPayID,
      dayOfWeek: DayOfWeek.Monday,
      hour: 1,
      twelveHourClock: 'AM',
    };
    this.grid.addRow(this.editRow);
  }

  mapScheduleDtoToRecord(schedule: ApproveToPaySchedule) {
    return Object.assign(new ApproveToPayScheduleRecord(), {
      ...schedule,
      ...conver24HourTo12Hour(schedule.hour),
    });
  }

  updateSchedule() {
    const updateSchedule = {
      ...this.editRow,
      hour: convert12HourTo24Hour(this.editRow.hour, this.editRow.twelveHourClock),
    };

    this.isDataLoading = true;

    this.atpService.updateApproveToPaySchedule(this.editRow.id, updateSchedule).subscribe(
      (res) => {
        const index = this.schedules.findIndex((x) => x.id === res.id);
        this.schedules[index] = this.mapScheduleDtoToRecord(res);

        this.cancelEditing();
        this.isDataLoading = false;
        this.alertMessage.showAlertMessage('Successfully updated schedule.', 'Success');
      },
      () => {
        this.alertMessage.showAlertMessage('Error updating Approve To Pay Schedules', 'Error');
        this.isDataLoading = false;
      }
    );
  }

  createSchedule() {
    const updateSchedule = {
      ...this.editRow,
      hour: convert12HourTo24Hour(this.editRow.hour, this.editRow.twelveHourClock),
    };

    this.isDataLoading = true;

    this.atpService.createApproveToPaySchedule(this.editRow.approveToPayID, updateSchedule).subscribe(
      (res) => {
        this.schedules.unshift(this.mapScheduleDtoToRecord(res));
        this.cancelEditing();
        this.isDataLoading = false;
        this.alertMessage.showAlertMessage('Successfully created a new schedule.', 'Success');
      },
      () => {
        this.alertMessage.showAlertMessage('Error updating Approve To Pay Schedules', 'Error');
        this.isDataLoading = false;
      }
    );
  }

  deleteSchedule(schedule: ApproveToPayScheduleRecord) {
    this.isDataLoading = true;

    this.atpService
      .deleteApproveToPaySchedule(schedule.id)
      .subscribe(
        () => {
          const index = this.schedules.findIndex((x) => x.id === schedule.id);
          this.schedules.splice(index, 1);
          this.isDataLoading = false;
          this.alertMessage.showAlertMessage(`Successfully deleted schedule '${schedule.dayOfWeek} - ${schedule.time}'.`, 'Success');
        },
        () => {
          this.alertMessage.showAlertMessage('Error deleting Approve To Pay Schedules', 'Error');
          this.isDataLoading = false;
        }
      );
  }
}
