import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { rowCallback } from '@/_shared/helpers';
import { ApproveToPayService } from '@/services/approve-to-pay-service';
import { ConfirmDialogComponent } from '@/bg-common/confirm-dialog/confirm-dialog.component';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { CustomerProfile } from '@/models/CustomerProfile';
import { CustomerService } from '@/services/customer.service';
import { AuthService } from '@/auth/auth.service';
import { AuthOperation } from '@/auth/AuthOperation';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-approve-to-pay-profile-list',
  templateUrl: './approve-to-pay-profile-list.component.html',
})
export class ApproveToPayProfileListComponent implements OnInit {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;
  @ViewChild('grid', { static: false })
  grid: GridComponent;
  @Input('approveToPayID')
  approveToPayID: number;

  destroy$ = new Subject();
  profileToAdd: CustomerProfile;
  profileLinks: CustomerProfile[];
  customerProfiles: (CustomerProfile & { display: string })[];
  customerProfilesFiltered: (CustomerProfile & { display: string })[];
  isDataLoading = false;
  rowCallback = rowCallback;

  constructor(
    private atpService: ApproveToPayService,
    private authService: AuthService,
    private customerService: CustomerService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getCustomerProfileLinks();
    this.getCustomerProfile();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public handleFilterChange(filter: string) {
    filter = filter.toLowerCase();
    this.customerProfilesFiltered = this.customerProfiles.filter(
      (x) => x.display.toLowerCase().indexOf(filter) !== -1
    );
  }

  public handleValueChange(value: string) {
    const index = this.customerProfiles.findIndex(x => x.display === value);
    this.profileToAdd = this.customerProfiles[index];
  }

  getCustomerProfile() {
    this.customerService
      .getCustomerProfiles()
      .subscribe(
        (res) => {
          this.customerProfilesFiltered = this.customerProfiles = res
            .map(x => ({ ...x, display: `${x.accountNumber} - ${x.accountName}` }));
        },
        () => {
          this.alertMessage.showAlertMessage(`Failed to load CustomerProfiles`, 'Error');
        }
      );
  }

  getCustomerProfileLinks() {
    this.isDataLoading = true;

    this.atpService
      .getCustomerProfileLinks(this.approveToPayID)
      .subscribe(
        (res) => {
          this.profileLinks = res.reverse();
          this.isDataLoading = false;
        },
        () => {
          this.alertMessage.showAlertMessage(`Failed to load CustomerProfiles linked to ApproveToPayID '${this.approveToPayID}'`, 'Error');
          this.isDataLoading = false;
        }
      );
  }

  canEdit() {
    return this.authService.hasOperation(AuthOperation.CanEditCustomerProfile);
  }

  removeHandler({ dataItem }: { dataItem: CustomerProfile }) {
    const dialog = this.dialogService.open({
      title: 'Confirm',
      content: ConfirmDialogComponent
    });

    const instance = dialog.content.instance as ConfirmDialogComponent;
    instance.content = `Are you sure you want to remove customer profile '${dataItem.accountName}' from the schedule?`;
    instance.confirmText = 'Remove';

    dialog.result
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (!(result instanceof DialogCloseResult)) {
          this.deleteCustomerProfileLink(dataItem.customerProfileID);
        }
      });
  }

  addHandler() {
    this.isDataLoading = true;

    this.atpService.createCustomeProfileLink(this.approveToPayID, this.profileToAdd.customerProfileID).subscribe(
      () => {
        this.profileLinks.unshift(this.profileToAdd);
        this.isDataLoading = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.isDataLoading = false;
        if (errorResponse.status === 400) {
          this.alertMessage.showAlertMessage(errorResponse.error, 'Error');
        } else {
          this.alertMessage.showAlertMessage(`Failed to link CustomerProfileID '${this.profileToAdd.customerProfileID}' to ApproveToPayID '${this.approveToPayID}'`, 'Error');
        }
      }
    );
  }

  deleteCustomerProfileLink(customerProfileId: number) {
    this.isDataLoading = true;

    this.atpService
      .deleteCustomeProfileLink(this.approveToPayID, customerProfileId)
      .subscribe(
        () => {
          const index = this.profileLinks.findIndex((x) => x.customerProfileID === customerProfileId);
          this.profileLinks.splice(index, 1);
          this.isDataLoading = false;
        },
        () => {
          this.alertMessage.showAlertMessage(`Failed to unlink CustomerProfileID '${customerProfileId}' from ApproveToPayID '${this.approveToPayID}'`, 'Error');
          this.isDataLoading = false;
        }
      );
  }
}
