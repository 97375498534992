import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  alertMessages = new Subject<{ message: string, type: string }>();
  isLoading = new BehaviorSubject<boolean>(false);

  constructor() { }

  alert(message: string, type: string): void {
    this.alertMessages.next({ message: message, type: type });
  }

  loading(state: boolean): void {
    this.isLoading.next(state);
  }
}
