import { TLAuditIncidentCommentDTO, TruckloadAuditDTO, TruckloadAuditIncidentDTO } from '@/pages/tl-audit/dto/TruckloadAuditDTO';
import { ShipmentIncidentDTO, TLIncidentAssignmentDTO, TLIncidentCommentDTO, TLIncidentDTO } from '../dto/IncidentDTO';

export class TruckloadAuditUI {
  shipmentID: number;
  invoiceID: number;
  enterpriseID: number;
  invoiceAuditID: number;
  invoiceNumber: string;
  invoiceTotal: number;
  invoiceTotalString: string;
  carrierName: string;
  carrierCode: string;
  buyRate: number;
  buyRateString: string;
  sellRate: number;
  sellRateString: string;
  margin: number;
  marginString: string;
  variance: number;
  varianceString: string;
  customer: string;
  customerAccount: string;
  primaryReference: string;
  invoiceCreatedDate: Date;
  invoiceCreatedDateString: string;
  isSecondary: boolean;
  auditCreatedDate: Date;
  auditCreatedDateString: string;
  auditAge: number;
  reasons: TruckloadAuditReasonUI[];
  reasonString: string;
  showReasonLimit = 3;
  reasonIDs: number[];
  auditor: TruckloadAuditReasonAssignmentUI;
  uninvoicedAgeDays: number;
  saleRep: string;
  constructor(tlAudit: TruckloadAuditDTO) {
    if (tlAudit != null) {
      this.shipmentID = tlAudit.shipmentID;
      this.enterpriseID = tlAudit.enterpriseID;
      this.invoiceID = tlAudit.invoiceID;
      this.invoiceAuditID = tlAudit.invoiceAuditID;
      this.primaryReference = tlAudit.primaryReference;
      this.carrierName = tlAudit.carrierName;
      this.carrierCode = tlAudit.carrierCode;
      this.customer = tlAudit.customer;
      this.customerAccount = tlAudit.customerAccount;
      this.isSecondary = tlAudit.isSecondary;
      this.invoiceNumber = tlAudit.invoiceNumber;
      this.invoiceTotal = tlAudit.invoiceTotal;
      this.invoiceTotalString = tlAudit.invoiceTotal == null ? '' : '$' + tlAudit.invoiceTotal.toFixed(2);
      this.buyRate = tlAudit.shipmentBuyRate;
      this.buyRateString = tlAudit.shipmentBuyRate == null ? '' : '$' + tlAudit.shipmentBuyRate.toFixed(2);
      this.sellRate = tlAudit.shipmentSellRate;
      this.sellRateString = tlAudit.shipmentSellRate == null ? '' : '$' + tlAudit.shipmentSellRate.toFixed(2);
      this.saleRep = tlAudit.saleRep || '';
      this.uninvoicedAgeDays =
        tlAudit.uninvoicedAgeDays === null || tlAudit.uninvoicedAgeDays === undefined ? 0 : tlAudit.uninvoicedAgeDays;
      if (tlAudit.shipmentSellRate !== null && tlAudit.shipmentBuyRate !== null) {
        this.margin = this.sellRate - this.buyRate;
        this.marginString = '$' + this.margin.toFixed(2);
      }
      if (tlAudit.shipmentBuyRate !== null && tlAudit.invoiceTotal !== null) {
        this.variance = tlAudit.shipmentBuyRate - tlAudit.invoiceTotal;
        this.varianceString = '$' + this.variance.toFixed(2);
      }

      // reason sub list
      this.auditor = null;
      this.auditAge = 0;
      if (tlAudit.incidents != null && tlAudit.incidents.length > 0) {
        this.reasons = tlAudit.incidents
          .sort((a, b) => +b.status.localeCompare(a.status) || +a.type.localeCompare(b.type) - 1)
          .map((v) => new TruckloadAuditReasonUI(v)) as TruckloadAuditReasonUI[];
        this.reasonString = tlAudit.incidents
          .sort((a, b) => +b.status.localeCompare(a.status) || +a.type.localeCompare(b.type) - 1)
          .map((x) => x.type)
          .toString();
        const earliest = tlAudit.incidents.reduce((pre, cur) => (Date.parse(pre.createdDate) > Date.parse(cur.createdDate) ? cur : pre));
        if (!isNaN(Date.parse(earliest.createdDate))) {
          this.auditCreatedDate = new Date(earliest.createdDate);
          this.auditCreatedDateString =
            this.auditCreatedDate.getMonth() + 1 + '/' + this.auditCreatedDate.getDate() + '/' + this.auditCreatedDate.getFullYear();
          // age of audit
          const today = new Date();
          const diff = Math.abs(today.getTime() - this.auditCreatedDate.getTime());
          this.auditAge = Math.ceil(diff / (1000 * 3600 * 24));
        }

        this.reasonIDs = tlAudit.incidents.map((v) => v.incidentID) as number[];
        // must be changed when we allow assignments of users
        const incidentsWithAssignments = tlAudit.incidents.filter((x) => {
          return x.assignments !== null && x.assignments.length > 0;
        });
        if (incidentsWithAssignments && incidentsWithAssignments.length > 0) {
          const assignments = incidentsWithAssignments.map((inc) => {
            return new AssignmentIncidentUI(inc.invoiceID, inc.incidentID, inc.assignments[0].assignmentID);
          }) as AssignmentIncidentUI[];
          this.auditor = new TruckloadAuditReasonAssignmentUI(null, null);
          this.auditor.setAssignment(
            incidentsWithAssignments[0].assignments[0].name,
            incidentsWithAssignments[0].assignments[0].email,
            assignments
          );
        }
      } else {
        this.reasons = [];
        this.reasonString = '';
        this.reasonIDs = [];
      }
      // dates
      if (!isNaN(Date.parse(tlAudit.invoiceCreatedDate))) {
        this.invoiceCreatedDate = new Date(tlAudit.invoiceCreatedDate);
        this.invoiceCreatedDateString =
          this.invoiceCreatedDate.getMonth() + 1 + '/' + this.invoiceCreatedDate.getDate() + '/' + this.invoiceCreatedDate.getFullYear();
      }
    }
  }
}

export class TruckloadAuditReasonListUI {
  invoiceID: number;
  shipmentID: number;
  reasons: TruckloadAuditReasonUI[];
  reasonString: string;
  constructor(shipmentIncidentDTO: ShipmentIncidentDTO, invoiceID: number) {
    this.shipmentID = shipmentIncidentDTO.shipment.shipmentID;
    this.invoiceID = invoiceID;
    const matchingInvoiceIncidents = shipmentIncidentDTO.incidents.filter((incident) => {
      return (incident.invoice && incident.invoice.invoiceID === invoiceID) || incident.invoice === null;
    });
    if (matchingInvoiceIncidents && matchingInvoiceIncidents.length > 0) {
      this.reasons = matchingInvoiceIncidents.map((i) => {
        const reason = new TruckloadAuditReasonUI(null);
        reason.setAuditReason(i, this.shipmentID);
        return reason;
      }) as TruckloadAuditReasonUI[];
      this.reasonString = matchingInvoiceIncidents
        .sort((a, b) => +b.status.status.localeCompare(a.status.status) || +a.type.localeCompare(b.type) - 1)
        .map((x) => x.type)
        .toString();
    } else {
      this.reasons = [];
      this.reasonString = '';
    }
  }
}

export class TruckloadAuditReasonUI {
  tlAuditID: number;
  invoiceID: number;
  shipmentID: number;
  reasonID: number;
  reason: string;
  createdDate: Date;
  createdDateString: string;
  status: string;
  updatedDate: Date;
  updatedDateString: string;
  createdUser: string;
  updatecUser: string;
  reasonAge: number;
  comments: TruckloadAuditReasonCommentUI[];
  assignment: TruckloadAuditReasonAssignmentUI;
  constructor(tlIncident: TruckloadAuditIncidentDTO) {
    if (tlIncident) {
      this.reasonID = tlIncident.incidentID;
      this.reason = tlIncident.type;
      this.status = tlIncident.status;
      this.invoiceID = tlIncident.invoiceID;
      this.shipmentID = tlIncident.shipmentID;
      this.createdUser = tlIncident.createdUser;
      this.updatecUser = tlIncident.updatedUser;
      this.reasonAge = 0;
      if (!isNaN(Date.parse(tlIncident.createdDate))) {
        this.createdDate = new Date(tlIncident.createdDate);
        this.createdDateString = this.createdDate.getMonth() + 1 + '/' + this.createdDate.getDate() + '/' + this.createdDate.getFullYear();

        // age of audit
        const today = new Date();
        const diff = Math.abs(today.getTime() - this.createdDate.getTime());
        this.reasonAge = Math.ceil(diff / (1000 * 3600 * 24));
      }

      if (!isNaN(Date.parse(tlIncident.updatedDate))) {
        this.updatedDate = new Date(tlIncident.updatedDate);
        this.updatedDateString = this.updatedDate.getMonth() + 1 + '/' + this.updatedDate.getDate() + '/' + this.updatedDate.getFullYear();
      }
      if (tlIncident.comments !== undefined && tlIncident.comments !== null && tlIncident.comments.length > 0) {
        this.comments = tlIncident.comments
          .sort((a, b) => b.createdDate.localeCompare(a.createdDate))
          .map((v) => new TruckloadAuditReasonCommentUI(v)) as TruckloadAuditReasonCommentUI[];
      }
    }
  }

  setAuditReason(matchingIncident: TLIncidentDTO, shipmentID: number) {
    this.reasonID = matchingIncident.incidentID;
    this.reason = matchingIncident.type;
    this.status = matchingIncident.status.status;
    this.invoiceID = matchingIncident.invoice ? matchingIncident.invoice.invoiceID : 0;
    this.shipmentID = shipmentID;
    this.reasonAge = 0;
    // TODO refactor in phase 2, currently expected 1 assignment per incident
    // which represents the auditor
    if (matchingIncident.assignments && matchingIncident.assignments.length > 0) {
      const assignmentIDList = matchingIncident.assignments.map((a) => {
        return new AssignmentIncidentUI(
          matchingIncident.invoice ? matchingIncident.invoice.invoiceID : 0,
          matchingIncident.incidentID,
          a.assignmentID
        );
      }) as AssignmentIncidentUI[];
      this.assignment = new TruckloadAuditReasonAssignmentUI(matchingIncident.assignments[0], assignmentIDList);
    }
    if (!isNaN(Date.parse(matchingIncident.status.statusDate))) {
      this.createdDate = new Date(matchingIncident.status.statusDate);
      this.createdDateString = this.createdDate.getMonth() + 1 + '/' + this.createdDate.getDate() + '/' + this.createdDate.getFullYear();

      // age of audit
      const today = new Date();
      const diff = Math.abs(today.getTime() - this.createdDate.getTime());
      this.reasonAge = Math.ceil(diff / (1000 * 3600 * 24));
    }
    if (matchingIncident.comments && matchingIncident.comments.length > 0) {
      this.comments = matchingIncident.comments
        .sort((a, b) => b.createdDate.localeCompare(a.createdDate))
        .map((v) => {
          const reasonComment = new TruckloadAuditReasonCommentUI(null);
          reasonComment.setComment(matchingIncident, v);
          return reasonComment;
        }) as TruckloadAuditReasonCommentUI[];
    }
  }
}

export class TruckloadAuditReasonCommentUI {
  reasonID: number;
  commentID: number;
  comment: string;
  status: string;
  createdUser: string;
  createdDate: Date;
  createdDateString: string;
  constructor(tlComment: TLAuditIncidentCommentDTO) {
    if (tlComment) {
      this.reasonID = tlComment.incidentID;
      this.commentID = tlComment.commentID;
      this.comment = tlComment.comment;
      this.status = tlComment.status;
      this.createdUser = tlComment.createdUser;

      if (!isNaN(Date.parse(tlComment.createdDate))) {
        this.createdDate = new Date(tlComment.createdDate);
        this.createdDateString = this.createdDate.getMonth() + 1 + '/' + this.createdDate.getDate() + '/' + this.createdDate.getFullYear();
      }
    }
  }

  setComment(incident: TLIncidentDTO, comment: TLIncidentCommentDTO) {
    this.reasonID = incident.incidentID;
    this.commentID = 0; // TODO wire up comment Id
    this.comment = comment.content;
    this.status = comment.status;
    this.createdUser = comment.createdBy;
    if (!isNaN(Date.parse(comment.createdDate))) {
      this.createdDate = new Date(comment.createdDate);
      this.createdDateString = this.createdDate.getMonth() + 1 + '/' + this.createdDate.getDate() + '/' + this.createdDate.getFullYear();
    }
  }
}

export class TruckloadAuditReasonAssignmentUI {
  assigmentList: AssignmentIncidentUI[];
  name: string;
  email: string;
  isComplete: boolean;
  constructor(assignment: TLIncidentAssignmentDTO, assignments: AssignmentIncidentUI[]) {
    if (assignment) {
      this.assigmentList = assignments;
      this.name = assignment.name;
      this.email = assignment.email;
      this.isComplete = assignment.isComplete;
    }
  }

  setAssignment(name: string, email: string, assignments: AssignmentIncidentUI[]) {
    this.email = email;
    this.name = name;
    this.assigmentList = assignments;
  }
}

export class AssignmentIncidentUI {
  invoiceID: number;
  assignmentID: number;
  incidentID: number;
  constructor(invoiceID: number, incidentID: number, assignmentID: number) {
    this.invoiceID = invoiceID;
    this.incidentID = incidentID;
    this.assignmentID = assignmentID;
  }
}

export class SaveMessage {
  message: string;
  type: string;

  constructor(msg: string, messageType: string) {
    this.message = msg;
    this.type = messageType;
  }
}

export class BBill {
  constructor(invoiceID: number, shipmentID: number, invoiceNumber: string, accountNumber: string) {
    this.invoiceNumber = invoiceNumber;
    this.shipmentID = shipmentID;
    this.invoiceID = invoiceID;
    this.accountNumber = accountNumber;
  }
  invoiceID: number;
  shipmentID: number;
  invoiceNumber: string;
  previousPayment: number;
  accountNumber: string;
  reasons: string[];
}
