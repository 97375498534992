import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Helpers } from '@/_shared/helpers';
import { AuthOperation } from './AuthOperation';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private helpers: Helpers, private router: Router) { }

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let canAccess = this.authService.isLoggedIn;
    if (!canAccess) {
      this.authService.startAuthentication(state.url);
      return false;
    }

    if (state.url.includes('invoice-import')) {
      canAccess = this.helpers.hasOperation(AuthOperation.CanImport_Invoice);
    }

    if (state.url.includes('invoice-aging') ||
      state.url.includes('invoices-summary') ||
      state.url.includes('duplicate-invoices')) {
      canAccess = this.helpers.hasOperation(AuthOperation.CanAccess_InvoiceVisibility);
    }

    if (state.url.includes('audit-queue')) {
      canAccess = this.helpers.hasOperation(AuthOperation.InvoiceAudit);
    }

    if (state.url.includes('proactive')) {
      canAccess = this.helpers.hasOperation(AuthOperation.CanEditProactive);
    }

    if (state.url.includes('customer-profile') ||
      state.url.includes('approve-to-pay')) {
      canAccess = this.helpers.hasOperation(AuthOperation.CanViewCustomerProfile) ||
        this.helpers.hasOperation(AuthOperation.CanEditCustomerProfile);
    }

    if (state.url.includes('bad-debt')) {
      canAccess = this.helpers.hasOperation(AuthOperation.CanViewCollection) ||
        this.helpers.hasOperation(AuthOperation.CanWorkCollection);
    }

    if (state.url.includes('invoices/unmatched')) {
      canAccess = this.helpers.hasOperation(AuthOperation.CanWorkUnmatchedInvoices);
    }

    if (!canAccess) {
      this.router.navigateByUrl('/');
    }

    return canAccess;
  }
}
