import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { catchError, map } from 'rxjs/operators';
import {
  CollectionItem,
  Collection,
  CollectionNote,
  CollectionDocument,
  CollectionPayment,
  SaveCollection,
  CreateNote,
  SavePayment,
  SaveNote,
  CreatePayment,
  References,
  EnterpriseChildDTO,
  PotentialCollection,
  CreateCollection,
} from '../services/Collection';

@Injectable()
export class CollectionService {
  shipmentApiHeaders: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202', // -->Add this line
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  public collectionBook: CollectionItem[];
  public collection: Collection;

  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeaders = new HttpHeaders({
      //      EnterpriseID: '7',
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
      /*
      'Access-Control-Allow-Origin': 'http://localhost:4200', // -->Add this line
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json'
      */
    });
  }

  // collections
  isAvailable(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.startupService.financeAPIUrl}v2/healthmonitor/isavailable`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCollections(id: string): Observable<CollectionItem[]> {
    if (id !== '0') {
      return this.http
        .get<CollectionItem[]>(`${this.startupService.financeAPIUrl}v2/collections?status=${id}`, {
          headers: this.httpOptions.headers,
        })
        .pipe(
          map((data) => (this.collectionBook = data)),
          catchError(this.handleError)
        );
    } else {
      return this.http
        .get<CollectionItem[]>(`${this.startupService.financeAPIUrl}v2/collections/`, {
          headers: this.httpOptions.headers,
        })
        .pipe(
          map((data) => (this.collectionBook = data)),
          catchError(this.handleError)
        );
    }
  }
  public getCollection(collectionId: number) {
    return this.http.get<Collection>(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}`).pipe(
      map((data) => (this.collection = data)),
      catchError(this.handleError)
    );
  }
  public update(collectionId: number, collection: SaveCollection) {
    return this.http.patch(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}`, collection).pipe(
      map((response: Collection) => {
        this.collection = response;
      }),
      catchError(this.handleError)
    );
  }
  public findCollections(accountNumber: string) {
    return this.http.get(`${this.startupService.financeAPIUrl}v2/collections/find/${accountNumber}`).pipe(
      map((response: PotentialCollection) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public importEnterprise(createCollection: CreateCollection) {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/collections/`, createCollection).pipe(
      map((response: Collection) => {
        this.collection = response;
      }),
      catchError(this.handleError)
    );
  }
  public reImportInvoices(collectionId: number) {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}/documents/reimport`, collectionId).pipe(
      map((response: Collection) => {
        this.collection = response;
        return response;
      }),
      catchError(this.handleError)
    );
  }

  public importInvoices(collectionId: number) {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}/documents/import`, collectionId).pipe(
      map((response: CollectionDocument[]) => {
        this.collection.invoices = response;
        return response;
      }),
      catchError(this.handleError)
    );
  }

  // notes
  public updateNote(noteId: number, note: SaveNote) {
    return this.http.put(`${this.startupService.financeAPIUrl}v2/collections/notes/${noteId}`, note).pipe(
      map((response: CollectionNote[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public saveNote(note: CreateNote): Observable<CollectionNote[]> {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/collections/notes`, note).pipe(
      map((response: CollectionNote[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public deleteNote(noteId: number, collectionId: number) {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}/notes/${noteId}`).pipe(
      map((response: CollectionNote[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  // documents
  public deleteDocument(documentId: number, collectionId: number) {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}/documents/${documentId}`).pipe(
      map((response: Response) => {}),
      catchError(this.handleError)
    );
  }
  public getDocument(documentId: number): Observable<any> {
    return this.http
      .get(`${this.startupService.financeAPIUrl}v2/collections/documents/${documentId}`)
      .pipe(map((data) => <CollectionDocument>data));
  }

  // payments
  public updatePayment(paymentId: number, payment: SavePayment): Observable<CollectionPayment[]> {
    return this.http.put(`${this.startupService.financeAPIUrl}v2/collections/payments/${paymentId}`, payment).pipe(
      map((response: CollectionPayment[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  public savePayment(payment: CreatePayment): Observable<CollectionPayment[]> {
    return this.http.post(`${this.startupService.financeAPIUrl}v2/collections/payments`, payment).pipe(
      map((response: CollectionPayment[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  public deletePayment(paymentId: number, collectionId: number): Observable<CollectionPayment[]> {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/collections/${collectionId}/payments/${paymentId}`).pipe(
      map((response: CollectionPayment[]) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  // enterprises
  public getEnterprises(filter: string): Observable<EnterpriseChildDTO[]> {
    return this.http
      .get(`${this.startupService.shipmentApiUrl}v1/enterprise/search?searchString=${encodeURIComponent(filter)}`, {
        headers: this.shipmentApiHeaders,
      })
      .pipe(map((data) => <EnterpriseChildDTO[]>data));
  }

  // references
  public getReferences(): Observable<References> {
    return this.http.get(`${this.startupService.financeAPIUrl}v2/references/collections`).pipe(map((data) => <References>data));
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }

  public deleteBadDebt(id: number): Observable<Collection> {
    return this.http.delete(`${this.startupService.financeAPIUrl}v2/collections/${id}`).pipe(
      map((response: Collection) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
}
