import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { Observer, throwError as observableThrowError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ImageDetailPending } from '@/models/ImageDetailPending';
import { ImageDetailPendingService } from '@/services/image-detail-pending.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@/auth/auth.service';

declare var Tiff: any;

@Component({
  selector: 'app-carrier-image-details',
  templateUrl: './carrier-image-details.component.html',
  styleUrls: ['./carrier-image-details.component.scss']
})
export class CarrierImageDetailsComponent implements OnInit {
  @Input() displayDialog: boolean = false;

  @Output() closeDialog = new EventEmitter();
  @Output() editNextRecord = new EventEmitter();
  @Output() databaseRecordsChange = new EventEmitter();
  @Output() recordDeleted = new EventEmitter();

  private _carrierImageDetail: ImageDetailPending;
  get carrierImageDetail(): ImageDetailPending {
    return this._carrierImageDetail;
  }
  @Input()
  set carrierImageDetail(value: ImageDetailPending) {
    this.initializeForm(value);

    this._carrierImageDetail = value;
  }

  isLoading: boolean = false;
  isImageLoading: boolean = false;
  isImageSuccessful: boolean = false;
  applyImageResize: boolean = false;
  canDelete: boolean = false;

  carrierImageDetailsForm: FormGroup;
  carrierImageDetailsCtrl = FormCarrierImageDetailsCtrl;
  carrierImageTypes = constImageNames;

  imageSrc: SafeUrl;

  constructor(
    private formBuilder: FormBuilder,
    private imageDetailPendingService: ImageDetailPendingService,
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.canDelete = this.authService.BlueShipUser.operations.some((item) => item == 'CanDeleteDocument');
  }

  initializeForm(detail: ImageDetailPending) {
    if (!detail) return;

    this.isImageLoading = true;
    this.isImageSuccessful = false;
    this.applyImageResize = false;
    this.imageSrc = null;

    this.imageDetailPendingService
      .getPdfBlobImageDetailPendingById(detail.imageDetailPendingID)
      .pipe(
        mergeMap((blob: Blob) => this.readAsDataURL(blob)),
        catchError((err) => this.handleError(err))
      )
      .subscribe(
        (blob) => {
          this.isImageLoading = false;

          let convertedBlob = blob.includes('image/tiff', 0)
            ? this.convertTiffBlobForBrowser(blob)
            : blob;

          this.imageSrc =
            this.sanitizer.bypassSecurityTrustResourceUrl(convertedBlob);

          this.isImageSuccessful = true;
        }
      );

    this.carrierImageDetailsForm = this.formBuilder.group({
      [this.carrierImageDetailsCtrl.imageDetailPendingID]: new FormControl({
        value: detail.imageDetailPendingID,
        disabled: true
      }),
      [this.carrierImageDetailsCtrl.pro]: new FormControl(
        detail.pro,
        Validators.maxLength(255)
      ),
      [this.carrierImageDetailsCtrl.primaryReference]: new FormControl(
        detail.primaryReference,
        Validators.maxLength(255)
      ),
      [this.carrierImageDetailsCtrl.isError]: new FormControl(detail.isError),
      [this.carrierImageDetailsCtrl.errorMessage]: new FormControl({
        value: detail.errorMessage,
        disabled: true
      }),
      [this.carrierImageDetailsCtrl.imageName]: new FormControl(
        detail.imageName
      ),
      [this.carrierImageDetailsCtrl.imageType]: new FormControl({
        value: detail.imageType,
        disabled: true
      }),
      [this.carrierImageDetailsCtrl.image]: new FormControl(detail.imageBinary),
      [this.carrierImageDetailsCtrl.isSaveAndStore]: new FormControl(
        detail.isSaveAndStore
      ),
      [this.carrierImageDetailsCtrl.source]: new FormControl({
        value: detail.source,
        disabled: true
      })
    });
  }

  readAsDataURL(blob: Blob): Observable<string> {
    return new Observable((obs: Observer<string>) => {
      const reader: FileReader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result as string);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  convertTiffBlobForBrowser(blob: string): string {
    this.applyImageResize = true; // For some reason tiff images are high resolution

    let blobBase64 = blob.replace('data:image/tiff;base64,', '');
    const blobByteChars = atob(blobBase64);
    const blobBytes = new Array(blobByteChars.length);
    for (let i = 0; i < blobByteChars.length; i++) {
      blobBytes[i] = blobByteChars.charCodeAt(i);
    }
    const binaryData = new Uint8Array(blobBytes);
    const tiff = new Tiff({ buffer: binaryData });
    const dataUrl = tiff.toCanvas().toDataURL('image/jpeg');

    return dataUrl;
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }

  onSaveDetails() {
    this.isLoading = true;

    let dataToSave = {
      ...this.carrierImageDetailsForm.value,
      image: null
    };

    this.imageDetailPendingService
      .mergeImageDetailPending(
        this.carrierImageDetail.imageDetailPendingID,
        dataToSave
      )
      .subscribe(
        () => {
          this.isLoading = false;

          this.databaseRecordsChange.emit();
          this.editNextRecord.emit(true);
        },
        (err): any => {
          this.isLoading = false;

          this.handleError(err);
        }
      );
  }

  onDeleteDetails() {
    this.isLoading = true;

    this.imageDetailPendingService
      .deleteImageDetailPending(this.carrierImageDetail.imageDetailPendingID)
      .subscribe(
        () => {
          this.isLoading = false;

          this.recordDeleted.emit();
        },
        (err): any => {
          this.isLoading = false;

          this.handleError(err);
        }
      );
  }

  onCloseDialog() {
    this.displayDialog = false;
    this.closeDialog.emit();
  }

  onSaveAndStoreCarrierImageDetails() {
    this.isLoading = true;

    this.imageDetailPendingService
      .mergeImageDetailPending(this.carrierImageDetail.imageDetailPendingID, {
        isSaveAndStore: !this.carrierImageDetail.isSaveAndStore
      })
      .subscribe(
        () => {
          this.isLoading = false;

          this.databaseRecordsChange.emit();
          this.editNextRecord.emit(true);
        },
        (err): any => {
          this.isLoading = false;

          this.handleError(err);
        }
      );
  }

  onSkip() {
    this.editNextRecord.emit(true);
  }
}

enum FormCarrierImageDetailsCtrl {
  imageDetailPendingID = 'imageDetailPendingID',
  pro = 'pro',
  primaryReference = 'primaryReference',
  isError = 'isError',
  errorMessage = 'errorMessage',
  imageName = 'imageName',
  imageType = 'imageType',
  image = 'image',
  isSaveAndStore = 'isSaveAndStore',
  source = 'source'
}

const constImageNames: string[] = [
  'Certificate-Insurance',
  'Commercial',
  'Delivery-Receipt',
  'Letter-Authority',
  'On-Hand-Notice',
  'Quote-Document',
  'Tendered-BOL',
  'Weight-Inspection'
];
