import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-convert-to-unmatched-dialog',
  templateUrl: './convert-to-unmatched-dialog.component.html',
  styleUrls: ['./convert-to-unmatched-dialog.component.scss']
})
export class ConvertToUnmatchedDialogComponent extends DialogContentBase implements OnInit {

  @Input()
  referenceNumber: string;

  formGroup: FormGroup;

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      referenceNumber: new FormControl(this.referenceNumber, Validators.required)
    });
  }

  onCancel() {
    this.dialog.close();
  }

  onSubmit() {
    this.dialog.close(this.formGroup.value);
  }
}
