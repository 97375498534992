import { SpecialImportResponse } from "@/models/SpecialImport";
import { StartupService } from "@/startup.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export const enum ImportMode {
  ManagmentFees = 'management-fees',
  SmallParcel = 'small-parcel'
};

export const ImportValidators = [
    {
        modes: [ImportMode.ManagmentFees, ImportMode.SmallParcel],
        props: ['date'],
        error: (x) => 'Invalid Date',
        validate: (x) => !isNaN(Date.parse(x))
    },
    {
        modes: [ImportMode.ManagmentFees, ImportMode.SmallParcel],
        props: ['sell', 'buy', 'pro', 'invoicenumber', 'accountnumber'],
        error: (x) => `Missing ${x.toUpperCase()}`,
        validate: (x) => !!x
    },
    {
        modes: [ImportMode.ManagmentFees, ImportMode.SmallParcel],
        props: ['sell', 'buy'],
        error: (x) => `Incorrect format for ${x.toUpperCase()}. This value should be numeric`,
        validate: (x) => !x || !isNaN(Number(x))
    },
    {
        modes: [ImportMode.ManagmentFees, ImportMode.SmallParcel],
        props: ['pro', 'invoicenumber'],
        error: (x) => `Incorrect format for ${x.toUpperCase()}. ${x.toUpperCase()} has a minimum character limit of 4 and a maximum character limit of 50`,
        validate: (x) => !x || (x.length >= 4 && x.length <= 50)
    },
    {
        modes: [ImportMode.ManagmentFees],
        props: ['shipper', 'city', 'state', 'zip', 'quantity', 'reference', 'comments'],
        error: (x) => `Missing ${x.toUpperCase()}`,
        validate: (x) => !!x
    },
    {
        modes: [ImportMode.ManagmentFees],
        props: ['zip'],
        error: (x) => `${x.toUpperCase()} has a maximum character length of 10`,
        validate: (x) => !x || x.length <= 10
    },
    {
        modes: [ImportMode.ManagmentFees],
        props: ['quantity'],
        error: (x) => `Incorrect format for ${x.toUpperCase()}. This value should be numeric`,
        validate: (x) => !x || !isNaN(Number(x))
    },
    {
        modes: [ImportMode.SmallParcel],
        props: ['bol#', 'scac'],
        error: (x) => `Missing ${x.toUpperCase()}`,
        validate: (x) => !!x
    }
];


@Injectable()
export class ImportService {

    constructor(private startupService: StartupService, private http: HttpClient) { }

    public uploadSpecialImportFile(file: File, mode: string): Observable<SpecialImportResponse[]> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<SpecialImportResponse[]>(`${this.startupService.financeAPIUrl}v2/imports/${mode}`, formData);
    }

    public validateImportFile(parsedImoprt: any, mode: ImportMode): string[] {
        const errors = [];
        parsedImoprt.accountnumber = parsedImoprt.accountnumber || parsedImoprt['rems#'] || parsedImoprt['account#'];
        parsedImoprt.invoicenumber = parsedImoprt.invoicenumber || parsedImoprt['invoice#'] || parsedImoprt['inv#'];
        parsedImoprt.quantity = parsedImoprt.quantity || parsedImoprt.pcs;

        var validators = ImportValidators.filter(x => x.modes.some(x => x == mode));

        for (const validator of validators) {
            for (const prop of validator.props) {
                if (!validator.validate(parsedImoprt[prop])) {
                    errors.push(validator.error(prop));
                }
            }
        }

        return errors;
    }
}