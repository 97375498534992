import { Reference } from './ShipmentInvoiceAudit';
export class EnterpriseWithReference {
  enterprise: EnterpriseEdit;
  references: ShipmentEnterpriseReference[];
}
export class EnterpriseEdit {
  customer: string;
  accountNumber: string;
  salesRep: string;
  operationsContact: string;
  location: string;
  department: string;
  accountManager: string;
}
export class ShipmentEnterpriseReference {
  enterpriseReferenceTypeID: number;
  isRequired: boolean;
  isDefaulted: boolean;
  isPrimary: boolean;
  type: string;
  value: string;
  values: string[];
}

export class SaveShipmentReference {
  referenceID: number;
  type: string;
  value: string;
  isPrimary: boolean;

  constructor(referenceid: number, type: string, value: string, isPrimary: boolean) {
    this.referenceID = referenceid;
    this.type = type;
    this.value = value;
    this.isPrimary = isPrimary;
  }
}

export class ShipmentReference {
  shipmentID: number;
  references: Reference[];
}

export class ShipmentReferencePro {
  shipmentID: number;
  proNumbers: ShipmentReferenceProNumber[];
}

export class ShipmentReferenceProNumber {
  dateCreated: Date;
  proNumberID: number;
  proNumber: string;
}
