import { Component, Input } from '@angular/core';
import { ShipmentDocumentDTO } from '@/models/ShipmentDocument';

@Component({
  selector: 'document-rename-modal',
  templateUrl: './document-rename-modal-content.component.html',
  styleUrls: ['./document-rename-modal-content.scss'],
})
export class DocumentRenameModalContentComponent {
  @Input() document: ShipmentDocumentDTO;
  @Input() documentTypes: DocumentType[] = [];

  selectedDocumentType?: DocumentType;
}
