import { Injectable } from '@angular/core';
import { TLEditUI } from '../models/TLEdit.ui';

@Injectable()
export class TLAuditEditHelperService {
  constructor() {}

  public auditInvoiceTotals(carrierInvoicesCharges: TLEditUI.Charge[], carrierQuoteCharges: TLEditUI.Charge[]) {
    if (carrierQuoteCharges == null || carrierInvoicesCharges == null) {
      return;
    }
    for (const charge of carrierInvoicesCharges) {
      charge.error = carrierQuoteCharges.find((x) => x.total === charge.total) == null ? true : false;
    }
  }
  public auditCalculateTotalCostVariance(carrierInvoice: TLEditUI.CarrierInvoice, carrierQuote: TLEditUI.CarrierQuote): number {
    return carrierQuote.grandTotal - carrierInvoice.grandTotal;
  }
  public auditCalculateSubtotalCostVariance(carrierInvoice: TLEditUI.CarrierInvoice, carrierQuote: TLEditUI.CarrierQuote): number {
    return carrierQuote.subTotal - carrierInvoice.subTotal;
  }
  public auditScacVariance(carrierInvoice: TLEditUI.CarrierInvoice, carrierQuote: TLEditUI.CarrierQuote): string {
    if (
      (carrierInvoice.scac === '' && carrierQuote.scac === null) ||
      (carrierInvoice.scac === null && carrierQuote.scac === '') ||
      carrierInvoice.scac === carrierQuote.scac
    ) {
      return '';
    }
    return `SCAC variance - Invoice: ${carrierInvoice.scac} | Shipment: ${carrierQuote.scac}`;
  }
  public auditMCNumberVariance(carrierInvoice: TLEditUI.CarrierInvoice, carrierQuote: TLEditUI.CarrierQuote): string {
    if (!carrierInvoice.mcNumber && !carrierQuote.mcNumber) {
      return '';
    }
    return !(carrierInvoice.mcNumber === carrierQuote.mcNumber)
      ? `MC number variance - Invoice: ${carrierInvoice.mcNumber} | Shipment: ${carrierQuote.mcNumber}`
      : '';
  }
  public auditDOTNumberVariance(carrierInvoice: TLEditUI.CarrierInvoice, carrierQuote: TLEditUI.CarrierQuote): string {
    if (!carrierInvoice.dotNumber && !carrierQuote.dotNumber) {
      return '';
    }
    return !(carrierInvoice.dotNumber === carrierQuote.dotNumber)
      ? `DOT number variance - Invoice: ${carrierInvoice.dotNumber} | Shipment: ${carrierQuote.dotNumber}`
      : '';
  }
  public auditCarrierCodeNumberVariance(carrierInvoice: TLEditUI.CarrierInvoice, carrierQuote: TLEditUI.CarrierQuote): string {
    if (!carrierInvoice.carrierCode && !carrierQuote.carrierCode) {
      return '';
    }
    return !(carrierInvoice.carrierCode === carrierQuote.carrierCode)
      ? `Carrier Code mismatch - Invoice: ${carrierInvoice.carrierCode || 'no code'} | Shipment: ${carrierQuote.carrierCode || 'no code'}`
      : '';
  }
  public auditCalculateWeightVariance(mode: string, totalInvoiceWeight: number, totalQuoteWeight: number): number {
    if (mode !== 'LTL') {
      return 0;
    }

    // we only care about weight over 5
    const difference = Math.abs(totalInvoiceWeight - totalQuoteWeight);
    if (difference > 5) {
      return difference;
    }
    return 0;
  }
}
