import { Component, OnInit, Input, ViewChild, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { process, State } from '@progress/kendo-data-query';
import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { AuthService } from '../../../auth/auth.service';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { ShipmentRefTypes } from '../../../_shared/ShipmentRefTypes';
import { Item } from 'src/app/services/Shipment';
import { Helpers } from '../../../_shared/helpers';
import { ConfirmationDialogService } from '@/_shared/confirmation-dialog/confirmation-dialog.service';
import { ModalComponent } from '@/_shared/modal/modal.component';

export enum ShipmentMode {
  LTL,
  LTLVolume,
}

@Component({
  selector: 'app-audit-item-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './audit-item-list.component.html',
  styleUrls: ['./audit-item-list.component.scss'],
})
export class AuditItemListComponent implements OnInit {
  @ViewChild('itemGrid', { static: false })
  private recordGrid: GridComponent;
  get theRecords(): Item[] {
    return this.records;
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }

  @Input('theRecords')
  set theRecords(value: Item[]) {

    this.records = value;
    this.setRecords();
  }

  @Input()
  name: string;


  @Output() onModalClass = new EventEmitter();
  gridView: GridDataResult;
  public formGroup: FormGroup = null;

  get controls() {
    return this.formGroup.controls;
  }

  // sent in
  isDataLoading: boolean;
  records: Item[] = [];

  mode: ShipmentMode;
  selectableSettings: SelectableSettings;
  editedRowIndex = -1;
  tempRecordID = -1;
  isNew = false;
  isQuantityError = false;
  helpers: Helpers;

  freightClasses: any[];
  weightTypes: any[];
  pieceTypes: any[];
  unitTypes: any[];
  hazmatClasses: any[];
  packageGroups: any[];
  dimensionTypes: any[];
  totalWeightUOM: string;
  selectedfreightClass: number;

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private confirmationDialogService: ConfirmationDialogService,
    private cd: ChangeDetectorRef
  ) {
    this.freightClasses = ShipmentRefTypes.freightClasses;
    this.weightTypes = ShipmentRefTypes.weightTypes;
    this.pieceTypes = ShipmentRefTypes.pieceTypes;
    this.unitTypes = ShipmentRefTypes.unitTypes;
    this.dimensionTypes = ShipmentRefTypes.dimensionTypes;
    this.hazmatClasses = ShipmentRefTypes.hazmatClasses;
    this.packageGroups = ShipmentRefTypes.hazmatPackageGroups;
  }

  public state: State = {
    skip: 0,
    take: 50,
    sort: [
      {
        field: 'ProductName',
        dir: 'asc',
      },
    ],
  };

  public gridData: any = process(this.records, this.state);

  ngOnInit() {
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    this.setRecords();
    this.setMode();
    this.checkQuantity();
  }

  ngOnChanges() {
    this.records = this.theRecords;
    this.setRecords();
  }

  // page events
  protected onNewItem() {
    if (this.formGroup) {
      return;
    }

    // new data model
    this.tempRecordID--;
    let dataItem = new Item();
    dataItem = {
      id: this.tempRecordID,
      quantity: 1,
      quantityUOM: 'Pallets',
      weight: 1,
      weightUOM: 'lbs',
      originalPlannedWeight: 1,
      originalPlannedWeightUOM: 'lbs',
      pieces: 0,
      piecesUOM: 'Pieces',
      length: 0,
      height: 0,
      width: 0,
      dimensionUOM: 'in',
      freightClass: null,
      description: '',
      nmfc: '',
      isTempSensitive: false,
    };

    this.isNew = true;
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = 0;

    // add one to the list and edit it
    this.theRecords.unshift(dataItem);
    this.setRecords();
    this.recordGrid.editRow(0, this.formGroup);

    // validate the form
    // this.helpers.markAllAsTouchedDirty(this.formGroup);
    // this.helpers.updateAllValidity(this.formGroup);
  }
  protected onEditItem({ sender, rowIndex, dataItem }) {
    // are they trying to add one now?
    if (this.isNew) {
      this.helpers.markAllAsTouchedDirty(this.formGroup);
      this.helpers.updateAllValidity(this.formGroup);
      return;
    }

    this.isNew = false;
    this.closeEditor(sender);

    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.recordGrid.editRow(rowIndex, this.formGroup);
  }
  protected onRemoveItem({ rowIndex }) {
    this.confirmationDialogService
      .confirm('Confirm Deletion', 'Do you want to delete this item?', 'Delete', 'Cancel')
      .then((result) => {
        if (result === true) {
          // are they trying to add one now?
          if (this.isNew) {
            return;
          }
          this.records.splice(rowIndex, 1);
          this.gridData = process(this.records, this.state);
          this.setMode();
          this.checkQuantity();
          this.cd.detectChanges();
        }
      });
  }
  protected onCancelItem({ sender, rowIndex }) {
    if (this.isNew) {
      this.records.shift();
      this.gridData = process(this.records, this.state);
    }
    this.isNew = false;
    this.closeEditor(sender, rowIndex);
  }
  protected onSaveItem({ sender, rowIndex, formGroup, isNew }) {
    const record = this.records.find(({ id }) => id === this.formGroup.value.id);
    Object.assign(record, this.formGroup.value);

    // force zeros
    record.pieces = record.pieces == null ? 0 : record.pieces;
    record.piecesUOM = record.piecesUOM === '' ? 'Pieces' : record.piecesUOM;
    record.height = record.height == null ? 0 : record.height;
    record.width = record.width == null ? 0 : record.width;
    record.length = record.length == null ? 0 : record.length;
    this.closeEditor(sender, rowIndex);
    this.isNew = false;
    this.setMode();
    this.checkQuantity();
  }
  protected rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // public
  public valid(): boolean {
    if (this.records.length === 0 || this.isNew || this.isQuantityError) {
      return false;
    }
    if (this.formGroup === null) {
      return true;
    }
    return this.formGroup.valid && this.isNew;
  }

  // helpers
  public hideRemoveBtn(item: any): boolean {
    return this.records.length === 1;
  }
  protected checkQuantity() {
    this.isQuantityError = this.records.findIndex((obj) => obj.quantity > 0) === -1;
  }

  private setMode() {
    this.mode = ShipmentMode.LTL;

    // are there more than 5 pallets
    const records = this.records.filter((obj) => obj.quantityUOM === 'Pallets');
    const totalPallets = records.reduce(function (a, b) {
      return a + b.quantity;
    }, 0);

    // is the weight over 5000?
    let noDimensionsCount = 0;
    let totalWeight = 0;
    this.records.forEach((item) => {
      const weight = item.weight;
      const weightUOM = item.weightUOM;
      if (weight && !isNaN(weight)) {
        if (weightUOM === 'lbs') {
          totalWeight += weight;
        } else if (weightUOM === 'kgs') {
          totalWeight += weight * 2.20462;
        }
      }
      if (item.length === 0 || item.height === 0 || item.width === 0) {
        noDimensionsCount++;
      }
    });

    let totalKgCount = this.records.filter(({ weightUOM }) => weightUOM === "kgs").length;

    if (totalKgCount == this.records.length) {
      let totalWeightKg = this.records
        .filter(({ weightUOM }) => weightUOM === "kgs")
        .map(x => x.weight)
        .reduce((sum, record) => sum + record);
      this.setTotalWeightUOM(totalWeightKg, "kgs");
    } else {
      this.setTotalWeightUOM(totalWeight, "lbs");
    }

    // set the mode
    if (totalWeight > 5000 || totalPallets > 5) {
      this.mode = ShipmentMode.LTLVolume;
    } else {
      this.mode = ShipmentMode.LTL;
    }
  }

  protected dataQA(prefix: string, suffix: string): string {
    return `${prefix}-${this.name}-${suffix}`;
  }
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = null;
    this.formGroup = null;
  }

  private setRecords(): void {
    this.gridData = process(this.records, this.state);
  }

  public setRecordsChangeDetection(): void {
    this.gridData = process(this.records, this.state);
    this.cd.detectChanges();
  }

  createFormGroup = (dataItem) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      quantity: new FormControl(dataItem.quantity, [Validators.required, Validators.min(0)]),
      quantityUOM: new FormControl(dataItem.quantityUOM),
      weight: new FormControl(dataItem.weight, [Validators.required, Validators.min(1)]),
      weightUOM: new FormControl(dataItem.weightUOM),
      pieces: new FormControl(dataItem.pieces),
      piecesUOM: new FormControl(dataItem.piecesUOM),
      length: new FormControl(dataItem.length),
      height: new FormControl(dataItem.height),
      width: new FormControl(dataItem.width),
      dimensionUOM: new FormControl(dataItem.dimensionUOM),
      freightClass: new FormControl(dataItem.freightClass, Validators.required),
      description: new FormControl(dataItem.description, Validators.required),
      nmfc: new FormControl(dataItem.nmfc),
    });


  openModalReclassItem(): void {
    this.onModalClass.emit(this.gridData);
  }

  private setTotalWeightUOM(weight: number, uom: string) {
    this.totalWeightUOM = `${Math.round(weight)} ${uom}`;
  }
}
