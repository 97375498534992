import { AppState } from '@/appstate.model';
import { AuthService } from '@/auth/auth.service';
import { ShipmentDocumentDTO, ShipmentDocumentUploadDTO } from '@/models/ShipmentDocument';
import { Globals } from '@/_shared/globals';
import { Helpers } from '@/_shared/helpers';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileInfo, SelectEvent, UploadComponent } from '@progress/kendo-angular-upload';
import {
  DownloadTLAuditDocuments,
  DownloadTLAuditDocumentsError,
  LoadTLAuditDocuments,
  UploadTLAuditDocument,
  UploadTLAuditDocumentError,
} from '../../actions/tl-audit-edit.actions';

@Component({
  selector: 'app-tl-documents',
  templateUrl: './tl-documents.component.html',
  styleUrls: ['./tl-documents.component.scss'],
})
export class TlDocumentsComponent implements OnInit {
  @Input('shipmentID')
  set shipmentID(shipmentID: number) {
    this._shipmentID = shipmentID;
  }

  @ViewChild('documentuploadTLbtn', { static: false })
  documentuploadTLbtn: UploadComponent;

  _shipmentID: number;
  selectedUploadDocumentType: string;
  fileTypeDictionary: { [key: string]: string };
  uploadDocumentList = Globals.DocumentTypes.filter((obj) => obj.canUpload === true);
  documentList = Globals.DocumentTypes;
  documentFiles: any[] = [];
  gridData: ShipmentDocumentDTO[];
  helpers: Helpers;
  loadingDocuments: boolean;
  uploadDocumentRestrictions = Globals.UploadFileRestrictions;

  constructor(private authService: AuthService, private store: Store<AppState>) {
    this.fileTypeDictionary = this.documentList.reduce((accumulator, { text, value }) => {
      accumulator[`${value}`] = `${text}`;
      return accumulator;
    }, {} as { [key: string]: string });
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);
    this.setListeners();
    this.loadDocuments();
  }

  protected uploadFile(event: SelectEvent): void {
    // stop auto upload
    event.preventDefault();

    const extensions = event.files.map((file) => file.extension);
    if (!this.helpers.hasAllowedExtensionsOnly(extensions)) {
      const { allowedExtensions } = Globals.UploadFileRestrictions;
      this.store.dispatch(
        new UploadTLAuditDocumentError(`Invalid file type selected. Allowed file types: ${allowedExtensions.join(', ')}`, 0)
      );
      return;
    }

    const fileInfo = event.files[0];
    if (fileInfo.rawFile) {
      const reader = new FileReader();

      let documentObj = null;
      // called when done reading in the file
      reader.onloadend = () => {
        // create the object
        const base64String = reader.result.toString();
        documentObj = {
          type: this.selectedUploadDocumentType,
          name: fileInfo.name.replace(/\.[^/.]+$/, ''),
          format: Globals.AllowedExtensionsFormatMap[fileInfo.extension.toLowerCase()],
          source: 'Api',
          shipmentId: this._shipmentID,
          base64Data: base64String.substr(base64String.indexOf(',') + 1),
        } as ShipmentDocumentUploadDTO;

        if (documentObj) {
          this.store.dispatch(new UploadTLAuditDocument(documentObj));
        }
      };
      // read the data
      reader.readAsDataURL(fileInfo.rawFile);
    }
  }

  protected openExistingDocument(document: ShipmentDocumentDTO) {
    // TODO handle if no path
    if (document.path === '') {
      console.log('Error downloading document' + document.name + '::' + this._shipmentID);
      this.store.dispatch(new DownloadTLAuditDocumentsError('Could not download document'));
    }
    this.store.dispatch(new DownloadTLAuditDocuments(document.path));
  }

  private loadDocuments(): void {
    this.store.dispatch(new LoadTLAuditDocuments(this._shipmentID));
  }
  private convert(base64Data: string) {
    const file = Globals.base64ToBlob(base64Data, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
  private setListeners() {
    this.store
      .select((x) => x.TLAuditEditState.loadingDocuments)
      .subscribe((x) => {
        this.loadingDocuments = x;
      });
    this.store
      .select((x) => x.TLAuditEditState.documents)
      .subscribe((x) => {
        if (x) {
          this.gridData = x;
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.uploadingDocumentSuccess)
      .subscribe((x) => {
        if (x) {
          this.documentuploadTLbtn.success.emit();
          this.selectedUploadDocumentType = null;
          this.loadDocuments();
        }
      });
    this.store
      .select((x) => x.TLAuditEditState.downloadingRawData)
      .subscribe((x) => {
        if (x) {
          this.convert(x);
        }
      });
  }
}
