import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/appstate.model';
import { CarrierInvoiceAudit } from '../../models/CarrierInvoiceAudit';
import { Helpers } from '@/_shared/helpers';
import { AuthService } from '@/auth/auth.service';
import { Globals } from '@/_shared/globals';
import { CarrierResponse } from '@/models/Carrier';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  CarrierLTLLoad,
  CarrierTLSearch,
  CarrierInvoiceCarrierChanged,
  CarrierInvoiceDistanceChanged,
} from '../../actions/carrier-invoice-audit.actions';
import { CarrierInvoiceAuditReducer } from '../../reducers/carrier-invoice-audit.reducer';

@Component({
  selector: 'app-carrier-form',
  templateUrl: './carrier-form.component.html',
  styleUrls: ['./carrier-form.component.scss'],
})
export class CarrierFormComponent implements OnInit {
  get controls() {
    return this.carrierForm.controls;
  }

  get isFormValid() {
    return this.carrierForm ? this.carrierForm.valid : false;
  }

  @ViewChild('tldropdownlist', { static: false })
  tlDropDownList: any;

  initialLoad: boolean;
  carrierInvoiceAudit: CarrierInvoiceAudit;
  paymentTypes = Globals.PaymentTypes;
  carrierList: CarrierResponse[] = null;
  controlCarrierList: CarrierResponse[] = null;

  carrierForm: FormGroup = null;
  carrierNameError = '';
  carrierSearchValue = '';
  isDotSearch: boolean;
  lastLength: 0;
  isLoadingCarriers = false;

  helpers: Helpers = null;

  constructor(private store: Store<AppState>, private authService: AuthService, private formBuilder: FormBuilder) {
    this.helpers = new Helpers(this.authService);
    
    this.store
      .select((x) => x.CarrierInvoiceAuditState.carrierInvoiceAudit)
      .subscribe((x) => {
        if (x) {
          this.carrierInvoiceAudit = x;
          this.store.dispatch(new CarrierTLSearch(this.isDotSearch, this.carrierInvoiceAudit.carrierName));
        }
      });

    this.store
      .select((x) => x.CarrierInvoiceAuditState.carriers)
      .subscribe((x) => {
        this.controlCarrierList = this.carrierList = x;
        if (this.carrierList !== null && this.carrierList.length > 0) {
          this.carrierList.sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1));
          if (this.tlDropDownList != null) {
            this.tlDropDownList.toggle(true);
          }
          if (this.carrierForm == null) {
            this.createForm();
          }
        }
      });
  }

  ngOnInit() {
    this.isDotSearch = false;
    this.initialLoad = true;
    if (this.carrierInvoiceAudit.scac || (this.carrierInvoiceAudit.carrierName && this.carrierInvoiceAudit.carrierName.length > 2)) {
      if (this.isLTL()) {
        this.store.dispatch(new CarrierLTLLoad(this.carrierInvoiceAudit.scac, ''));
      } else {
        this.store.dispatch(new CarrierTLSearch(this.isDotSearch, this.carrierInvoiceAudit.carrierName));
      }
    } else {
      this.createForm();
    }
  }

  private createForm() {
    let refCarrierID = 0;
    // if we didnt find it in the list we need to clear it out.
    if (
      this.carrierInvoiceAudit.carrierName != null &&
      (this.controlCarrierList == null ||
        this.controlCarrierList.length === 0 ||
        this.carrierInvoiceAudit.carrierName.toUpperCase() !== this.controlCarrierList[0].carrierName.toUpperCase())
    ) {
      if (this.carrierInvoiceAudit.carrierName !== '') {
        this.carrierNameError = `Carrier '${this.carrierInvoiceAudit.carrierName}' not found.`;
        this.carrierInvoiceAudit.carrierName = '';
      }
    }

    if (this.controlCarrierList !== null && this.controlCarrierList.length > 0) {
      if (!this.isLTL()) {
        // TL compare DOT
        const findmatch = this.controlCarrierList.filter(
          (c) =>
            c.carrierName.toUpperCase() === this.carrierInvoiceAudit.carrierName.toUpperCase() &&
            c.dotNumber === this.carrierInvoiceAudit.dotNumber
        );
        if (findmatch.length === 1) {
          refCarrierID = findmatch[0].refCarrierID;
        }
      } else {
        // LTL compare SCAC
        const findmatch = this.controlCarrierList.filter(
          (c) =>
            c.carrierName.toUpperCase() === this.carrierInvoiceAudit.carrierName.toUpperCase() && c.scac === this.carrierInvoiceAudit.scac
        );
        if (findmatch.length === 1) {
          refCarrierID = findmatch[0].refCarrierID;
        }
      }
    }

    this.carrierForm = this.formBuilder.group({
      carrierName: [refCarrierID !== 0 ? refCarrierID : '', [Validators.required, this.carrierNameValidator.bind(this)]],
      invoiceNumber: [this.carrierInvoiceAudit.invoiceNumber, [Validators.required]],
      invoiceDate: [this.carrierInvoiceAudit.invoiceDate, [Validators.required]],
      paymentMethod: [this.carrierInvoiceAudit.paymentMethod, [Validators.required]],
      weight: [this.carrierInvoiceAudit.weight, [Validators.required, Validators.min(1)]],
      distance: [this.carrierInvoiceAudit.distance, [Validators.required, Validators.min(0)]],
    });

    // validate the form
    this.helpers.markAllAsTouchedDirty(this.carrierForm);
    this.helpers.updateAllValidity(this.carrierForm);
  }

  protected carrierNameValidator(control: FormControl) {
    if (!this.isLTL()) {
      if (this.carrierForm) {
        // no list so defintily not found
        if (this.controlCarrierList.length === 0) {
          return { invalidCarrier: true };
        }

        // find it in the list?
        const value = this.carrierForm.get('carrierName').value;
        const index = this.controlCarrierList.findIndex((i) => i.refCarrierID === value);
        if (index === -1) {
          return { invalidCarrier: true };
        }

        // only need to force the section on the first page load
        if (this.initialLoad) {
          this.setCarrier(this.controlCarrierList[index]);
          this.initialLoad = false;
        }
      }
    }
    return null;
  }
  protected isLTL(): boolean {
    return this.helpers.isLTL(this.carrierInvoiceAudit.mode);
  }

  // distance change
  protected distanceBlur() {
    this.carrierInvoiceAudit.distance = this.carrierForm.get('distance').value;
    this.store.dispatch(new CarrierInvoiceDistanceChanged(this.carrierInvoiceAudit.distance));
  }

  // various search methods for both LTL and TL carriers
  protected searchInputKeyUp(e: KeyboardEvent): void {
    const value = this.carrierForm.get('carrierName').value;
    if (e.keyCode === 13 && value.length >= 3) {
      e.preventDefault();
      if (this.isLTL()) {
        this.onLtlCarrierNameFilter(value);
      } else {
        this.onSearchTLCarriers();
      }
    }
  }
  protected onCarrierNamevalueChange(value) {
    // did we find it?
    this.carrierNameError = '';
    const index = this.controlCarrierList.findIndex((i) => i.refCarrierID === value);

    if (index === -1) {
      this.carrierInvoiceAudit.scac = 'n/a';
      this.carrierInvoiceAudit.mcNumber = 'n/a';
      this.carrierInvoiceAudit.dotNumber = 'n/a';
      this.carrierInvoiceAudit.carrierCode = 'n/a';
      this.store.dispatch(new CarrierInvoiceCarrierChanged(this.carrierInvoiceAudit));
      return;
    }

    // set the values
    this.setCarrier(this.controlCarrierList[index]);
    this.helpers.updateAllValidity(this.carrierForm);
  }
  protected onTlCarrierNameFilter(value) {
    this.carrierForm.get('carrierName').setErrors({ invalidCarrier: true });
    this.carrierSearchValue = value.trim();
    if (this.carrierSearchValue.length < 3) {
      this.controlCarrierList = [];
    }
  }
  protected nameSearch() {
    this.isDotSearch = false;
    this.controlCarrierList = [];
    this.carrierForm.get('carrierName').setValue('');
  }
  protected dotSearch() {
    this.isDotSearch = true;
    this.controlCarrierList = [];
    this.carrierForm.get('carrierName').setValue('');
  }
  protected onSearchTLCarriers() {
    this.store.dispatch(new CarrierTLSearch(this.isDotSearch, this.carrierSearchValue));
  }
  protected onLtlCarrierNameFilter(value) {
    value = value.trim();
    if (value.length > 3) {
      this.controlCarrierList = this.carrierList.filter((s) => s.carrierName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length === 3 && this.lastLength < value.length) {
      this.store.dispatch(new CarrierLTLLoad(null, this.carrierSearchValue));
    } else if (value.length < 3) {
      this.controlCarrierList = [];
    }
    this.lastLength = value.length;
  }

  // update carrier info and emit event
  private setCarrier(carrier: CarrierResponse) {
    this.carrierInvoiceAudit.scac = carrier.scac;
    this.carrierInvoiceAudit.mcNumber = carrier.mcNumber;
    this.carrierInvoiceAudit.dotNumber = carrier.dotNumber;
    this.carrierInvoiceAudit.carrierCode = carrier.carrierCode;
    this.carrierInvoiceAudit.carrierName = carrier.carrierName;
    this.store.dispatch(new CarrierInvoiceCarrierChanged(this.carrierInvoiceAudit));
  }
}
