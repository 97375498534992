import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SaveCollection } from '../../services/Collection';
import { Helpers } from '../../_shared/helpers';
import { References } from '../../services/Collection';
import { AuthService } from '../../auth/auth.service';
import { combineStr } from '@progress/kendo-angular-dropdowns/dist/es2015/util';

@Component({
  selector: 'app-collection-statuses',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './collection-statuses.component.html',
})
export class CollectionStatusesComponent implements OnInit {
  @Input()
  references: References;

  @Input()
  dateWrittenOff: Date;

  @Input()
  statusID: number;

  @Input()
  collectorID: number;

  @Input()
  agencyID: number;

  @Input()
  remainingBalance: number;

  @Input()
  escalatedAgencyID: number;

  @Output()
  statuschanged: EventEmitter<any> = new EventEmitter<any>();

  // variables
  helpers: Helpers;

  constructor(private authService: AuthService) {
    this.itemDisabled = this.itemDisabled.bind(this);
  }

  ngOnInit() {
    this.helpers = new Helpers(this.authService);
  }

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    if (this.remainingBalance > 0 && itemArgs.dataItem.status === 'Closed – Paid in Full') {
      return true;
    }
    return false;
  }

  public statusChanged(dataItem) {
    const sc = new SaveCollection();
    sc.dateWrittenOff = this.dateWrittenOff;
    sc.statusID = this.statusID;
    sc.agencyID = this.agencyID;
    sc.escalatedAgencyID = this.escalatedAgencyID;
    sc.collectorID = this.collectorID;

    this.statuschanged.emit({
      savecollection: sc,
      callback: this.refreshFunction,
    });
  }

  // outside functions
  public refreshFunction() { }

  // helpers
  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }
  public getStatus(id: number): string {
    return this.references.statuses.find((obj) => obj.id === id).status;
  }
  public getCollector(id: number): string {
    const item = this.references.collectors.find((obj) => obj.id === id);
    return item == null ? 'n/a' : item.collector;
  }
  public getAgency(id: number): string {
    const item = this.references.agencies.find((obj) => obj.id === id);
    return item == null ? 'n/a' : item.agency;
  }
  public getEscalatedAgency(id: number): string {
    const item = this.references.escalatedAgencies.find((obj) => obj.id === id);
    return item == null ? 'n/a' : item.agency;
  }
}
