import { NotesInvoiceService } from '@/services/notes.service';
import { Component, Input, OnInit } from '@angular/core';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { InvoiceNoteModalComponent } from '../invoice-note-modal/invoice-note-modal.component';
import { Notes } from '@/models/Notes';

@Component({
  selector: 'invoice-notes',
  templateUrl: './invoice-notes.component.html',
  styleUrls: ['./invoice-notes.component.scss'],
})
export class InvoiceNotesComponent implements OnInit {

  notes: Notes = null;
  @Input() shipmentID: number;
  @Input() canEditnote: boolean;

  constructor(
    private dialogService: DialogService,
    private notesService: NotesInvoiceService
  ) { }

  ngOnInit(): void {
    this.setListeners();
  }

  private setListeners(): void {
    this.notesService.getNotes(this.shipmentID).subscribe((notes) => {
      this.notes = new Notes(notes);
    });
  }

  public editModal(): void {
    const dialogRef = this.dialogService.open({
      width: 800,
      content: InvoiceNoteModalComponent,
    });

    const noteModal = dialogRef.content.instance;
    noteModal.shipmentID = this.shipmentID;
    noteModal.note = null;

    dialogRef.result.subscribe((r: { [key: string]: string }) => {
      if (!(r instanceof DialogCloseResult)) this.setListeners();
    });
  }

  public onSaveComment(): void {
    this.setListeners();
  }

}
