import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { CustomerProfile } from '../../models/CustomerProfile';
import { CustomerService } from '../../services/customer.service';
import { PageChangeEvent, GridDataResult, SelectableSettings, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Helpers, getSettings, setSettings } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-customer-profile-list',
  templateUrl: './customer-profile-list.component.html',
  styleUrls: ['./customer-profile-list.component.scss'],
})
export class CustomerProfileListComponent implements OnInit {
  get theRecords(): any[] {
    return this.GridDataAll;
  }

  @Input('theRecords')
  set theRecords(value: any[]) {
    this.GridDataAll = value;
    this.GridDataCurrent = value;
    this.setRecords();
  }

  @Input('loadingData')
  set loadingData(value: boolean) {
    this.isDataLoading = value;
  }

  @Input() set cacheKeyGrid(value) {
    this._cacheKeyGrid = value;
  }
  @Output() removeEvent = new EventEmitter<String>();

  GridDataCurrent: any[];
  GridDataAll: any[];
  GridData: GridDataResult;
  public selectableSettings: SelectableSettings;
  pageSize: number;
  skip: number;
  range = { start: null, end: null };
  public state: State = {};
  gridView: GridDataResult = null;
  isDataLoading = false;
  public sort: SortDescriptor[] = [{ field: 'AccountName', dir: 'asc' }];
  searchText: string;
  helpers: Helpers;
  _cacheKeyGrid: string;

  constructor(private pcService: CustomerService, private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.init();
  }

  init() {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
    };
    this.pageSize = 25;
    this.skip = 0;
    this.helpers = new Helpers(this.authService);
    this.GridDataCurrent = this.GridDataAll;
    this.setRecords();
    this.sort = getSettings(this._cacheKeyGrid, 'accountNumber', 'asc');
  }

  protected searchFilter(search: string = null): void {
    search
      ? (this.GridDataCurrent = this.GridDataAll.filter((obj) => this.filterDelegate(obj, search)))
      : (this.GridDataCurrent = this.GridDataAll);
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  protected pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.setRecords();
  }

  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
    setSettings(this._cacheKeyGrid, this.sort);
  }

  private setRecords(): void {
    const records = orderBy(this.GridDataCurrent, this.sort);
    this.GridData = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }

  private applyTableState(state: State): void {
    this.GridData = process(this.GridDataCurrent, state);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.applyTableState(this.state);
  }

  protected hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }

  protected viewDetails(e, dataitem) {
    this.router.navigate([`/customer-profile-details/${dataitem.customerProfileID}`], dataitem);
  }

  removedetails(dataItem) {
    this.removeEvent.emit(dataItem);
  }

  public refreshGrid(): void {
    this.setRecords();
  }

  private filterProperty(property, search) {
    return property != null && property.toString().toLowerCase().indexOf(search) > -1;
  }

  private filterDelegate(obj: CustomerProfile, search: string): boolean {
    search = search.toLowerCase();
    return (
      this.filterProperty(obj.accountName, search) ||
      this.filterProperty(obj.accountNumber, search) ||
      this.filterProperty(obj.isProactiveString, search) ||
      this.filterProperty(obj.proactiveAuditNumber, search)
    );
  }

  public rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  linkSearch(event) {
    this.searchFilter(event);
  }
}
