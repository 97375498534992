import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult, RemoveEvent } from '@progress/kendo-angular-grid';
import { AuthService } from '@/auth/auth.service';
import { InvoiceService } from '@/services/invoice.service';
import { ShipmentService } from '@/services/shipment.service';
import { ShipmentSearchDTO, SelectedRate, ShipmentDTO } from '@/services/Shipment';
import { DeleteShipmentInvoice, ShipmentInvoice } from '@/models/Shipment';
import { Helpers } from '@/_shared/helpers';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { ShipmentInvoiceAuditUI } from '@/pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import { StartupService } from '@/startup.service';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { EditInvoiceParameters } from '../../models/edit-invoice-parameters';
import { CustomerService } from '../../services/customer.service';
import { InvoiceMethodEnum } from '@/models/InvoiceMethodEnum';
import { Enterprise } from '@/services/Enterprise';
import { LaunchDarklyFlagNames, LaunchDarklyService } from '@/services/launch-darkly/launch-darkly.service';
import { ISubscription } from 'rxjs/Subscription';

const DeleteableInvoiceStatuses = ['Manual', 'New'];

const ErrorTypes = ['Carrier', 'Customer', 'CustomerInsurance'];

const NoVarianceTypes = ['CarrierInsurance', 'Customer', 'CustomerInsurance'];

const RefreshMessages = ['carrierInvoiceCreated', 'carrierInvoiceUpdated'];

@Component({
  selector: 'app-shipment-search',
  templateUrl: './shipment-search.component.html',
  styleUrls: ['./shipment-search.component.scss'],
})
export class ShipmentSearchComponent implements OnInit, OnDestroy {
  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  private messageEventHandler: any;

  auditNotes?: ShipmentInvoiceAuditUI.AuditNote[];
  enableNewInvoice: boolean;
  data: GridDataResult;
  helpers: Helpers;
  invoiceToDelete: DeleteShipmentInvoice;
  loading: boolean;
  loadingAuditNotes: boolean;
  primaryShipment: ShipmentSearchDTO;
  shipmentDetail: ShipmentDTO;
  searchText: string;
  searchTextOriginal: string;
  showDeleteDialog: boolean;
  pageAlert: { message: string; type: string };
  showPageAlert = false;
  isProactive: boolean = false;
  searchFilterChecked = false;

  primaryText: string;
  primarys: string[] = null;
  primaryData: string[] = null;
  primarysFiltered: string[] = null;
  enterprise: Enterprise;
  showEventHistoryFlag = false;
  launchDarklyFlagChange$: ISubscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private invoiceService: InvoiceService,
    private shipmentService: ShipmentService,
    private startupService: StartupService,
    private pcCustomerService: CustomerService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.helpers = new Helpers(this.authService);
    this.loading = false;
    this.enableNewInvoice = false;
    this.loadingAuditNotes = false;
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.resetFields();
      this.searchText = queryParams.get('q');
      this.searchTextOriginal = queryParams.get('q');
      this.search();
      this.subscribeToRevertInvoiceResponse();
    });

    this.messageEventHandler = this.handleMessageEvent.bind(this);
    window.addEventListener('message', this.messageEventHandler);
    this.showEventHistoryFlag =
      this.launchDarklyService.flags[LaunchDarklyFlagNames.eventHistory];
    this.launchDarklyFlagChange$ =
      this.launchDarklyService.flagChange.subscribe((flags) => {
        this.showEventHistoryFlag = flags[LaunchDarklyFlagNames.eventHistory];
      });
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.messageEventHandler);
    this.launchDarklyFlagChange$.unsubscribe();
  }

  get showEventHistory(): boolean {
    return this.showEventHistoryFlag && !!this.primaryShipment
  }

  get enterpriseName(): string {
    return this.primaryShipment ? this.primaryShipment.enterpriseName : null;
  }

  get accountNumber(): string {
    return this.primaryShipment ? this.primaryShipment.accountNumber : null;
  }

  get allowSearch(): boolean {
    return !!this.searchText && !this.loading;
  }

  get canCreateCarrierInvoice(): boolean {
    return this.helpers.hasOperation('CanCreate_CarrierInvoice');
  }

  get legacyBlueShipGoToEditUrl(): string {
    if (this.primaryShipment) {
      return this.startupService.legacyBlueShipUrl + 'Ship/Edit/' + this.primaryShipment.shipmentID;
    }
  }

  get deleteDialogTitle(): string {
    if (!this.invoiceToDelete) {
      return '';
    }
    return `Delete Invoice Number ${this.invoiceToDelete.invoiceNumber}`;
  }

  get primaryReferenceValue(): string {
    return this.primaryShipment ? this.primaryShipment.value : null;
  }

  get showSearchResult(): boolean {
    return this.primaryShipment != null;
  }

  get alertClasses(): string[] {
    const classes = ['alert'];
    if (this.pageAlert) {
      classes.push(`alert-${this.pageAlert.type}`);
    }
    return classes;
  }

  get alertIconClasses(): string[] {
    const classes = ['fas'];
    if (this.pageAlert) {
      if (this.pageAlert.type === 'danger') {
        classes.push('fa-exclamation-circle');
      } else if (this.pageAlert.type === 'info') {
        classes.push('fa-info-circle');
      } else {
        classes.push('fa-spinner', 'fa-spin');
      }
    }
    return classes;
  }

  public revertInvoiceToManual(invoice: ShipmentInvoice): void {
    this.loading = true;
    this.showSpinner(true, 'Please, wait a moment...');
    this.invoiceService.RevertInvoiceToManual(invoice.invoiceID, invoice.analogousID, invoice.shipmentID);
  }

  public showRevertButton(invoice: ShipmentInvoice): boolean {
    let showRevertButton = false;

    if (
      (this.shipmentDetail.mode == 'LTL' ||
        this.shipmentDetail.mode == 'LTL Volume' ||
        this.shipmentDetail.mode == 'Volume' ||
        this.shipmentDetail.mode == 'VOLUME_LTL') &&
      this.helpers.hasOperation('CanRevertInvoiceToManual')
    ) {
      const isApprovedCarrierInvoice = invoice.type === 'Carrier' && invoice.status === 'Approved';
      if (isApprovedCarrierInvoice && this.data) {
        const invoices = this.data.data;
        const customerInvoice = invoices.find((inv) => inv.invoiceID === invoice.analogousID);
        showRevertButton = customerInvoice
          ? customerInvoice.status === 'Approved' || customerInvoice.status === 'Complete'
          : false;
      }
    }

    return showRevertButton;
  }

  public showCalculatorButton(invoice: ShipmentInvoice): boolean {
    return invoice.type === 'Carrier';
  }

  private subscribeToRevertInvoiceResponse(): void {
    this.invoiceService.revertToManualInvoiceObservable().subscribe((response: any) => {
      if (response && response.isSuccess) {
        switch (response.method) {
          case InvoiceMethodEnum.RevertInvoiceToManual:
            this.invoiceService.deleteInvoiceCustomer(response.invoiceID, response.analogousID);
            break;
          case InvoiceMethodEnum.DeleteInvoiceCustomer:
            this.invoiceService.getPendingInvoiceByInvoiceID(response.invoiceID, response.analogousID);
            break;
          case InvoiceMethodEnum.GetPendingInvoiceByInvoiceID:
            this.invoiceService.changeInvoiceStatus(response.invoiceID, response.analogousID);
            break;
          default:
            this.loading = false;
            this.showSpinner(false);
            this.setAlert('Action done Succesful', 'info');
            this.loadInvoices();
            break;
        }
      } else if (response && response.isSuccess) {
        this.loading = false;
        this.showSpinner(false);
        this.setAlert('An error ocurred trying to do the request');
      }
    });
  }

  canComputeVariance(invoice: ShipmentInvoice): boolean {
    return !NoVarianceTypes.some((type) => invoice.type.toUpperCase() === type.toUpperCase());
  }

  computeVariance(invoice: ShipmentInvoice, selectedRate: SelectedRate): number {
    const type = invoice.type.toUpperCase();
    const rateTotal = type === 'CARRIER' ? selectedRate.carrierTotal : selectedRate.customerTotal;
    return rateTotal - invoice.invoiceTotal;
  }

  // delete section
  deleteInvoice(e: RemoveEvent): void {
    this.invoiceToDelete = e.dataItem;
    this.showDeleteDialog = true;
  }
  deleteInvoiceCancel(): void {
    this.showDeleteDialog = false;
  }

  deleteInvoiceAction(): void {
    this.showDeleteDialog = false;
    if (this.invoiceToDelete) {
      const index = this.data.data.findIndex((d) => d.invoiceID === this.invoiceToDelete.invoiceID);
      if (index >= 0) {
        const invoice = this.data.data[index];
        invoice.isDeleting = true;
        this.invoiceService.deleteInvoice(invoice.invoiceID).subscribe(
          () => {
            this.showDeleteDialog = false;
            this.loadInvoices();
            this.alertMessage.showAlertMessage(`Invoice number ${invoice.invoiceNumber} was deleted.`, 'info');
          },
          (error) => {
            invoice.isDeleting = false;
            this.alertMessage.showAlertMessage(
              `Failed to delete invoice. ${typeof error === 'string' ? error : 'An error occurred.'}`,
              'error'
            );
          }
        );
      }
    }
  }

  isInvoiceDeleteable(invoice: ShipmentInvoice): boolean {
    const isCarrierInvoice = () => invoice.type.toUpperCase() === 'CARRIER';
    const userCanDelete = () =>
      this.helpers.hasOperation('CanDelete_Invoice') && DeleteableInvoiceStatuses.some((status) => invoice.status === status);
    const adminCanDelete = () => this.helpers.hasOperation('CanDelete_InvoiceAdmin');
    return isCarrierInvoice() && (userCanDelete() || adminCanDelete());
  }

  isInvoiceDeleteDisabled(invoice: ShipmentInvoice): boolean {
    return this.isLoadingVariance(invoice) || invoice.isDeleting;
  }
  // end delete section
  isLoadingVariance(invoice: ShipmentInvoice): boolean {
    return invoice.variance == null && this.canComputeVariance(invoice);
  }

  handleMessageEvent(e: MessageEvent): void {
    const data = e.data as { data: any; type: string };
    if (data && typeof data === 'object' && RefreshMessages.some((msg) => msg === e.data.type)) {
      this.setAlert('Refreshing...', 'light');
      this.resetFields();
      this.search();
    }
  }

  searchFilterCheckedEvent(event) {
    this.searchFilterChecked = event.target.checked;
  }

  search(): void {
    this.enableNewInvoice = false;
    this.showPageAlert = false;
    this.loading = true;
    this.showSpinner(true, 'Searching...');
    // find and set the primary shipment
    this.shipmentService.findShipments(this.searchText, null).subscribe(
      (shipmentsResult) => {
        const shipments = shipmentsResult || [];
        let primaryShipments = shipments.filter((s) => s.type.toUpperCase() === 'BOL' || s.type.toUpperCase() === 'PRO');
        primaryShipments = primaryShipments.filter((item, index) => primaryShipments.findIndex(t => t.shipmentID == item.shipmentID) === index);

        if (primaryShipments.length === 0) {
          this.alertMessage.showAlertMessage('No shipment found', 'Information');
          this.setAlert('No shipment found', 'info');
          this.loading = false;
          this.showSpinner(false);
          return;
        } else if (primaryShipments.length > 1) {
          this.setAlert('Primary reference found for more than 1 shipment. Please correct in BlueShip or contact IT.');
          this.loading = false;
          this.showSpinner(false);
          return;
        }
        this.primaryShipment = primaryShipments[0];
        this.showSpinner(false);
        // load the invoices for the primary shipment
        this.loadShipmentDetailThenInvoice();
        this.loadAuditNotes();
      },
      (error) => {
        this.loading = false;
        this.alertMessage.showAlertMessage('Error loading the page', 'Error');
      }
    );
  }

  public handlePrimaryFilterChange(filter: any): void {
    filter.trim();
    if (filter.length === 5) {
      console.log('Search');
      this.searchPrimarys(filter)
    }
    else if (filter.length > 5) {
      this.primarysFiltered = this.primaryData.filter((s) => s.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    }
    else {
      this.primarysFiltered = [];
      this.primaryData = [];
    }
  }

  private searchPrimarys(filter) {
    this.shipmentService
      .searchPrimarys(filter, this.startupService.daysBack)
      .subscribe((ep) => {
        this.primarysFiltered = ep;
        this.primaryData = ep;
      });
  }

  loadShipmentDetailThenInvoice(): any {
    if (this.primaryShipment) {
      this.shipmentService.getShipmentByID(this.primaryShipment.shipmentID).subscribe(
        (data) => {

          if (this.primaryShipment.type === "PRO") {
            this.primaryShipment.value = data.primaryReference;
            this.primaryShipment.type = "BOL";
          }

          this.shipmentDetail = data;
          this.shipmentService.getEnterprise(this.shipmentDetail.enterpriseID).subscribe((response) => {
            this.enterprise = response.enterprise;
            this.enableNewInvoice = true;
          });

          this.loadInvoices();

          this.pcCustomerService.getSpecificCustomerProfileByEnterpriseID(this.shipmentDetail.enterpriseID).subscribe((response) => {
            this.isProactive = response && response.isProactive;
          });
        },
        (error) => {
          this.loading = false;
          this.alertMessage.showAlertMessage('Error loading the page', 'Error');
        }
      );
    }
  }

  loadInvoices(): any {
    if (this.primaryShipment) {
      this.invoiceService.getAllInvoicesForShipment(this.primaryShipment.shipmentID).subscribe(
        (data) => {
          const invoices = data;
          if (invoices) {
            invoices.sort((a, b) => (a.invoiceID > b.invoiceID ? 1 : -1));
            this.data = {
              data: invoices,
              total: invoices.length,
            };
            invoices.forEach(async (invoice) => {
              if (this.shipmentDetail && this.shipmentDetail.selectedRate) {
                const variance = this.computeVariance(invoice, this.shipmentDetail.selectedRate);
                invoice.variance = variance;
              } else {
                invoice.variance = 0 - invoice.invoiceTotal;
              }
            });
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.alertMessage.showAlertMessage('Error loading the page', 'Error');
        }
      );
    }
  }

  loadAuditNotes(): void {
    this.loadingAuditNotes = true;
    if (this.primaryShipment) {
      this.invoiceService.getAuditNotes(this.primaryShipment.shipmentID).subscribe(
        (auditNotesDetail) => {
          this.loadingAuditNotes = false;
          this.auditNotes = ShipmentInvoiceAuditUI.mapAuditNotes(auditNotesDetail);
        },
        (error) => {
          this.loadingAuditNotes = false;
          this.alertMessage.showAlertMessage(
            `Failed to load audit notes. ${typeof error === 'string' ? error : 'An error occurred.'}`,
            'error'
          );
        }
      );
    }
  }

  searchInputKeyUp(e: KeyboardEvent): void {
    if (e.code === 'Enter' && this.allowSearch) {
      e.preventDefault();
      this.sendToSearchPage();
    }
  }

  searchOrRedirect(): void {
    if (this.searchFilterChecked) {
      this.searchText = this.primaryText;
    }
    this.searchText = this.searchText.trim();
    if (this.searchText.toUpperCase() === this.searchTextOriginal.toUpperCase()) {
      this.search();
    } else {
      this.sendToSearchPage();
    }
  }

  sendToSearchPage(): void {
    this.router.navigateByUrl(`/shipment-search?q=${this.searchText}`);
  }

  resetFields(): void {
    this.auditNotes = null;
    this.data = {
      data: [],
      total: 0,
    };
    this.invoiceToDelete = null;
    this.primaryShipment = null;
  }

  setAlert(message: string, type: string = 'danger'): void {
    this.pageAlert = { type, message };
    this.showPageAlert = true;
  }

  showError(invoice: ShipmentInvoice): boolean {
    return invoice.isError && ErrorTypes.some((type) => type === invoice.type);
  }

  private showSpinner(show: boolean, text: string = '') {
    this.appSpinner.loading = show;
    this.appSpinner.text = text;
  }

  public editingInvoice: boolean = false;
  public editingCanadaInvoice = false;

  public editInvoiceDialogTitle: string = '';

  public editInvoiceParameters: EditInvoiceParameters = <EditInvoiceParameters>{
    invoiceID: 0,
    shipmentID: 0,
    isEditMode: true,
    enterprise: null,
  };

  public addCarrierInvoice() {
    this.editInvoiceDialogTitle = 'Add Carrier Invoice';
    this.editInvoiceParameters.invoiceID = 0;
    this.editInvoiceParameters.shipmentID = this.primaryShipment.shipmentID;
    this.editInvoiceParameters.isEditMode = false;
    this.editInvoiceParameters.enterprise = this.enterprise;
    this.editInvoiceParameters.selectedRateCurrencyCode = this.shipmentDetail.selectedRate.currencyCode;
    this.editInvoiceParameters.existingInvoiceCountOnShipment = this.data.data.filter(invoice => invoice.type.toUpperCase() === "CARRIER").length;

    if (this.startupService.showCanada) {
      this.editingCanadaInvoice = true;
    } else {
      this.editingInvoice = true;
    }

    document.body.classList.add('not-scroll');
  }

  public closeEditInvoice() {
    this.editingInvoice = false;
    this.editingCanadaInvoice = false;

    document.body.classList.remove('not-scroll');
  }

  public onInvoiceCreated($event) {
    this.loadInvoices();
    this.closeEditInvoice();
  }

  public get isLTL(): boolean {
    return this.helpers.isLTLOrInternational(this.shipmentDetail.mode || '');
  }

  public onAuditInvoice($event: { dataItem: any }) {
    const dataItem = $event.dataItem;

    const ltlAudit = `/shipment-invoices/0/${dataItem.shipmentID}/${dataItem.invoiceID}?owner=${this.enterpriseName.replace(/%/g, '%25')}&pro=${this.isProactive}`;
    const tlAudit = `/truckload-edit/${dataItem.shipmentID}/${dataItem.invoiceID}`;

    const url = this.isLTL ? ltlAudit : tlAudit;
    window.open(url, '_blank');
  }

  public get isAuditButtonEnabled(): boolean {
    if (this.isLTL) {
      return this.helpers.hasOperation('InvoiceAudit');
    } else {
      return this.helpers.hasOperation('CanEditTLDocProactive');
    }
  }
}
