import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Invoice, InvoiceCharge } from '../../services/InvoiceAudit';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { AuditCarrierUpdateListComponent } from '@/bg-common/audit-carrier-update-list/audit-carrier-update-list.component';
import { ShipmentService } from '@/services/shipment.service';
import { UpdateChargesRequest, ChargeDTO } from '@/models/rate'
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { CarrierCharge, Charge } from '../../models/ShipmentInvoiceAudit';
import { Globals } from '../../_shared/globals';
import { CurrencyService } from '@/services/currency.service';

@Component({
  selector: 'app-ltl-buy-sell-rate-edit',
  templateUrl: './ltl-buy-sell-rate-edit.component.html',
  styleUrls: ['./ltl-buy-sell-rate-edit.component.scss'],
})
export class LtlBuySellRateEditComponent extends DialogContentBase implements OnInit {
  @ViewChild('auditCarrierGrid', { static: true })
  private chargesGrid: AuditCarrierUpdateListComponent;

  @ViewChild(SpinnerComponent, { static: true })
  spinner: SpinnerComponent;

  @Input()
  parameters: EditLtlRateParameters;

  invoice: Invoice = {
    charges: [],
    distance: 0
  };

  errorMessage: string = null;
  private currencyValueCAD: number;

  constructor(
    private shipmentService: ShipmentService,
    public dialogRef: DialogRef,
    private pcCurrencyService: CurrencyService
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    const { lineHaulCharges, accessorialCharges } = this.parameters.quote;
    const charges = lineHaulCharges.concat(accessorialCharges);

    this.invoice = {
      charges: charges.map(ch => {
        const qualifier = Globals.Qualifiers.find(r => r.rateQualifier == ch.rateQualifier) || Globals.Qualifiers.find(r => r.rateCode == ch.rateQualifier);

        return <InvoiceCharge>{
          amount: ch.amount,
          canEdit: true,
          description: ch.description,
          dimWeight: ch.weight,
          freightClass: ch.freightClass,
          fakFreightClass: ch.fakFreightClass,
          invoiceChargeID: ch.chargeID,
          rateQualifier: qualifier ? qualifier.rateQualifier : ch.rateQualifier,
          rateCode: qualifier ? qualifier.rateCode : null,
          rate: ch.rate,
          weight: ch.weight,
          isMin: ch.isMin,
          isMax: ch.isMax,
          quantity: ch.quantity,
          type: ch.type,
          ediCode: ch.ediCode,
          group: ch.isAccessorial ? 'Accessorial' : ' Linehaul'
        };
      }),
      currencyCode: this.parameters.currencyCode,
      distance: this.parameters.distance
    };

    this.getCurrency();
  }

  isSavingCharges: boolean = false;

  public savedCharges: {
    carrierCharges: Charge[],
    customerCharges: Charge[]
  };

  getCurrency(): void {
    this.pcCurrencyService.getConversionRate(new Date)
      .subscribe(response => this.currencyValueCAD = response.find(data => data.country == 'CAN').value);
  }

  save() {
    this.isSavingCharges = true;
    this.spinner.loading = true;
    this.spinner.text = "Saving the charges...";

    this.shipmentService.updateCharges(this.createRequest()).subscribe((response) => {
      this.dialogRef.close(response);
    }, (error) => {
      console.log(error);
      this.spinner.loading = false;
      this.isSavingCharges = false;
      this.errorMessage = `An error ocurred while updating the charges: ${error.error || error}`;
    });
  }

  createRequest(): UpdateChargesRequest {
    const mapType = (type: string) => {
      switch (type) {
        case 'ITEM':
          return 'LINEHAUL';
        case 'MG_MINMAX_ADJ':
          return 'MgMinMaxAdjustment';
        case 'SMC_MIN_ADJ':
          return 'SmcMinAdj';
        case 'ACCESSORIAL_FUEL':
          return 'Fuel';
        default:
          return type;
      }
    }

    const isLinehaulGroup = (type: string): boolean => Globals.ChargeTypes.indexOf(type) >= 0;

    const charges = this.chargesGrid.getCharges()
      .map((value: InvoiceCharge) => {
        const type = value.type ? value.type.trim().toLocaleUpperCase() : '';
        const isLinehaul = isLinehaulGroup(type);
        const total = isNaN(value.amount) ? 0 : value.amount;
        let amountInUSD: number;

        if (this.parameters.currencyCode !== 'USD')
          amountInUSD = total / this.currencyValueCAD;
        else { amountInUSD = total; }

        return <ChargeDTO>{
          chargeID: value.invoiceChargeID <= 0 ? 0 : value.invoiceChargeID,
          description: value.description,
          freightClass: value.freightClass,
          fakFreightClass: value.fakFreightClass,
          quantity: value.quantity,
          rate: value.rate,
          rateQualifier: value.rateCode,
          rateCode: value.rateCode,
          isAccessorial: !isLinehaul,
          isLinehaul: isLinehaul,
          amount: total,
          amountInUSD: Math.round(amountInUSD),
          total: total,
          type: mapType(type),
          weight: value.weight,
          isMin: value.isMin,
          isMax: value.isMax,
          ediCode: value.ediCode
        };
      });

    return <UpdateChargesRequest>{
      charges,
      currencyCode: this.parameters.currencyCode,
      entity: this.parameters.isBuyRate ? 'carrier' : 'customer',
      offerID: this.parameters.quote.id,
      shipmentID: this.parameters.shipmentID,
    };
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public onTotalChanged($event) {
  }

  public isEditing: boolean = false;

  public onEditInProgress($event) {
    this.isEditing = $event;
  }

  public get isSubmitDisabled() {
    return this.isEditing || this.isSavingCharges ? 'disabled' : null;
  }
}

export class EditLtlRateParameters {
  title: string;
  invoiceID: number;
  shipmentID: number;
  currencyCode?: string;
  quote: {
    id: string;
    lineHaulCharges: Charge[],
    accessorialCharges: Charge[]
  };
  isBuyRate: boolean;
  distance: number;
};
