import { TLAuditBBillOldState } from '../models/tl-audit-detail-state-old.model';
import * as TLAuditBBillActions from '../actions/tl-audit-bbill-old.actions';

const initialState: TLAuditBBillOldState = {
  showBBill: false,
  saveInProgress: false,
  savedError: null,
  savedSuccess: false,
  tlSettlementReasons: null,
  loadingSettlementReasons: false,
  loadingSettlementReasonError: null,
  bbill: null,
  invoiceID: 0,
};
export function TLAuditBBillReducerOld(state: TLAuditBBillOldState = initialState, action: TLAuditBBillActions.Actions) {
  switch (action.type) {
    case TLAuditBBillActions.SHOW_BBILL_OLD: {
      return {
        ...state,
        showBBill: action.visible,
        invoiceID: action.invoiceID,
        bbill: action.bbill,
        saveInProgress: false,
        savedError: null,
        savedSuccess: false,
      };
    }
    case TLAuditBBillActions.SAVE_BBILL_OLD: {
      return { ...state, saveInProgress: true };
    }

    case TLAuditBBillActions.SAVE_BBILL_SUCCESS_OLD: {
      return {
        ...state,
        saveInProgress: false,
        savedSuccess: true,
      };
    }
    case TLAuditBBillActions.SAVE_BBILL_ERROR_OLD: {
      return { ...state, saveInProgress: false, savedError: action.message, savedSuccess: false };
    }
    case TLAuditBBillActions.LOAD_SETTLEMENT_REASONS_OLD: {
      return {
        ...state,
        loadingSettlementReasons: true,
        loadingSettlementReasonError: null,
      };
    }
    case TLAuditBBillActions.LOAD_SETTLEMENT_REASONS_SUCCESS_OLD: {
      return { ...state, loadingSettlementReasons: false, tlSettlementReasons: action.tlAuditRefData.tlAuditSettlementReasons };
    }

    case TLAuditBBillActions.LOAD_SETTLEMENT_REASONS_ERROR_OLD: {
      return {
        ...state,
        loadingSettlementReasons: false,
        loadingSettlementReasonError: action.message,
      };
    }

    default:
      return state;
  }
}
