export type ApproveToPaySchedule = {
  id: number;
  approveToPayID: number,
  dayOfWeek: DayOfWeek,
  hour: number
};

export type UpdateApproveToPaySchedule = Omit<ApproveToPaySchedule, 'id' | 'approveToPayID'>;

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  EndOfMonth = 'ENDOFMONTH',
}
