export class ShipmentDocumentDTO {
  documentId: string;
  name: string;
  type: string;
  format: string;
  notes: number;
  source: string;
  size: number;
  shipmentId: number;
  dateCreated: Date;
  dateDeleted: Date;
  metaData: { [key: string]: string };
  path: string;
}

export class ImageRetrievalResponse {
  batchID: string;
  pro: string;
  primaryReference: string;
  scac: string;
  response: ResponseList
}

export class ResponseList {
  existingImages: string;
  added: string[];
  errors: string[];
}

export class ShipmentDocumentUploadDTO {
  name: string;
  type: string;
  format: string;
  notes?: number;
  source: string;
  shipmentId: number;
  base64Data: string;
}
