import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InvoiceService } from '@/services/invoice.service';
import { Invoice, SpecialImportResponse } from '@/models/SpecialImport';
import { FileUploader } from 'ng2-file-upload';
import { NotificationService } from '@/audit/services/notification.service';
import { ImportMode, ImportService } from '@/services/import.service';

@Component({
  selector: 'app-invoice-import',
  templateUrl: './invoice-import.component.html',
  styleUrls: ['./invoice-import.component.scss']
})

export class InvoiceImportComponent implements OnInit {

  file: File;
  fileError = false;
  fileLoaded = false;
  importForm: FormGroup;
  invoices: Invoice[] = [];
  isImporting = false;
  importFinished = false;
  importResponse: SpecialImportResponse[] = [];
  hasBaseDropZoneOver = false;
  uploader: FileUploader = new FileUploader({ url: '' });
  fileIsValid: boolean = true;

  constructor(
    private importService: ImportService,
    private notifications: NotificationService,
  ) {
    this.importForm = new FormGroup({
      file: new FormControl(null),
      mode: new FormControl(ImportMode.ManagmentFees, [Validators.required]),
    });
  }

  ngOnInit() { }

  exportResults() {
    if (this.importResponse.length === 0) return;
    let csvContent = Object.keys(this.importResponse[0]).join(',') + '\n';
    csvContent += this.importResponse.reduce((acc, res) => {
      acc += Object.values(res).join(',') + '\n';
      return acc;
    }, '');

    let encodedUri = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));

    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', this.file.name.replace('.csv', '_import_results.csv'));
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  uploadFile() {
    this.isImporting = true;
    this.importService.uploadSpecialImportFile(this.file, this.importForm.controls.mode.value)
      .subscribe(
        (response: SpecialImportResponse[]) => {
          this.isImporting = false;
          this.importFinished = true;
          this.importResponse = response;
        },
        err => {
          console.error(err);
          this.notifications.alert('Error importing file', 'Error');
          this.startOver();
        }
      );
  }

  startOver() {
    this.fileError = false;
    this.fileIsValid = true;
    this.isImporting = false;
    this.fileLoaded = false;
    this.importFinished = false;
    this.importForm.reset();
    this.importForm.controls.mode.setValue(ImportMode.ManagmentFees);
  }

  getInvoiceValidity(invoice: any) {
    const errors = this.importService.validateImportFile(invoice, this.importForm.controls.mode.value);
    const isValid = !errors.length;

    if (!isValid) this.fileIsValid = false;
    return { isValid, errors };
  }

  private parseCSV(csv: string) {
    let rows: string[] = csv.split(/\r\n|\n/);
    let headers = rows.shift().split(',')
      .map(h => h.toLocaleLowerCase().replace(/ /g, ''));
    this.invoices = rows.reduce((acc, row) => {
      if (row.replace(/,/g, '').trim() === '') return acc;
      let invoice = row.split(',').reduce((acc, val, i) => {
        if (acc[headers[i]]) {
          acc[headers[i] + '1'] = val;
        } else {
          acc[headers[i]] = val;
        }
        return acc;
      }, {});
      invoice['validity'] = this.getInvoiceValidity(invoice);
      acc.push(invoice);
      return acc;
    }, []);
    this.fileLoaded = true;
    this.fileError = false;
  }

  private processData() {
    if (!this.file.name || !this.file.name.toLowerCase().endsWith('.csv')) {
      this.fileError = true;
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = _e => {
      this.parseCSV(fileReader.result as string);
    };
    fileReader.readAsText(this.file);
  }

  fileDropped(e: File[]) {
    this.file = e[0];
    this.processData();
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileChanged(e: Event) {
    this.file = (e.target as HTMLInputElement).files[0];
    this.processData();
  }

  isActiveStep(stepNumber: number) {
    switch (stepNumber) {
      case 1:
        if (!this.importFinished && !this.fileLoaded) {
          return true;
        }
        break;
      case 2:
        if (!this.importFinished && this.fileLoaded) {
          return true;
        }
        break;
      case 3:
        if (this.importFinished) {
          return true;
        }
        break;
      default:
        return false;
    }
  }

  isNumber(a: any) {
    return !isNaN(Number(a));
  }
}
