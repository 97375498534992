import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ShipmentService } from '@/services/shipment.service';
import { PostalInfoSearchDTO } from '@/services/Shipment';
import { map } from 'rxjs/operators';
import { ShipmentUI } from '../../pages/audit/Shipment.ui';

const AutoCompleteHotKeys = ['Backspace', 'Delete', ' '];

@Component({
  selector: 'address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
})
export class AddressEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  address: ShipmentUI.AddressUI;

  @Input()
  name: string;

  @ViewChild('zipInput', { static: false })
  zipInput: AutoCompleteComponent;

  cityStateZip: string;
  cityStateZipLabel: string;
  formGroup: FormGroup;
  data: PostalInfoSearchDTO[];

  private readonly dblClickHandler: (e: MouseEvent) => void;
  private readonly keyDownHandler: (e: KeyboardEvent) => void;

  public isEditing: boolean;

  private get zipInputElement(): HTMLInputElement {
    return this.zipInput.searchbar.input.nativeElement as HTMLInputElement;
  }

  constructor(private shipmentService: ShipmentService) {
    this.cityStateZipLabel = '';
    this.dblClickHandler = this.dblClick.bind(this);
    this.keyDownHandler = this.keyDown.bind(this);
    this.data = [];
    this.isEditing = false;
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      companyName: new FormControl(this.address.companyName, Validators.required),
      addressLine1: new FormControl(this.address.addressLine1, Validators.required),
      addressLine2: new FormControl(this.address.addressLine2),
      city: new FormControl(this.address.city, Validators.required),
      stateProvince: new FormControl(this.address.stateProvince, Validators.required),
      postalCode: new FormControl(this.address.postalCode, Validators.required),
      countryCode: new FormControl(this.address.countryCode, Validators.required),
    });

    this.cityStateZip = this.getCityStateZip();
  }

  ngAfterViewInit(): void {
    this.zipInputElement.setAttribute('data-qa', this.dataQA('input', 'city-state-zip'));
    this.zipInputElement.addEventListener('dblclick', this.dblClickHandler);
    this.zipInputElement.addEventListener('keydown', this.keyDownHandler);
    // KENDO HACK: make these changes after DOM has been drawn
    // otherwise there will be UI jumping and unwanted wrapping
    this.zipInputElement.setAttribute('size', '1');
  }

  ngOnDestroy() {
    this.zipInputElement.removeEventListener('dblclick', this.dblClickHandler);
    this.zipInputElement.removeEventListener('keydown', this.keyDownHandler);
  }

  protected blur(): void {
    if (this.isEditing) {
      if (this.data.length === 1) {
        this.updateAddress(this.data[0]);
      }
      this.toggleEditMode(false);
    }
  }

  private updateAddress(addr: PostalInfoSearchDTO): void {
    this.address.city = addr.city;
    this.address.stateProvince = addr.stateProvince;
    this.address.postalCode = addr.postalCode;
    this.address.countryCode = addr.countryCode;
  }

  protected dataQA(prefix: string, suffix: string): string {
    return `${prefix}-${this.name}-${suffix}`;
  }

  private dblClick(e: MouseEvent): void {
    e.preventDefault();
    this.toggleEditMode(true);
  }

  protected edit(e: MouseEvent): void {
    e.preventDefault();
    this.toggleEditMode(true);
  }

  protected filterChange(filter: string): void {
    const code = (filter || '').trim();
    if (code.length < 3) {
      this.data = [];
      this.zipInput.toggle(false);
      return;
    }
    this.zipInput.loading = true;
    this.shipmentService
      .search(code)
      .pipe(
        map((data) => {
          return data.map((item) => {
            return {
              ...item,
              zipCityState: `${item.city}, ${item.stateProvince} ${item.postalCode}`,
            };
          });
        })
      )
      .subscribe((data) => {
        this.data = data || [];
        this.zipInput.loading = false;
      });
  }

  private getCityStateZip(): string {
    return `${this.address.city}, ${this.address.stateProvince} ${this.address.postalCode}`;
  }

  private keyDown(e: KeyboardEvent): void {
    if (!this.isEditing && AutoCompleteHotKeys.indexOf(e.key) >= 0) {
      e.preventDefault();
      this.toggleEditMode(true);
    }
  }

  public toggleEditMode(isEditing: boolean): void {
    this.isEditing = isEditing;
    if (isEditing) {
      this.cityStateZip = '';
      this.cityStateZipLabel = `Current: ${this.getCityStateZip()}`;
      this.zipInput.focus();
    } else {
      this.cityStateZip = this.getCityStateZip();
      this.cityStateZipLabel = '';
    }
  }

  public valid(): boolean {
    if (this.formGroup === null) {
      return false;
    }
    return this.formGroup.valid;
  }

  protected valueChange(value: string): void {
    const zipCityState = value || '';
    if (zipCityState) {
      const addr = this.data.find((item) => item.zipCityState === zipCityState);
      if (addr != null) {
        this.updateAddress(addr);
        this.toggleEditMode(false);
      }
    }
  }

  public getAddress(): ShipmentUI.AddressUI {
    return Object.assign({}, this.address) as ShipmentUI.AddressUI;
  }
}
