import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { StartupService } from '@/startup.service';
import {
  TruckloadAuditOldDTO,
  TLAuditReasonCommentOldDTO,
  TruckloadAuditReasonOldDTO,
  TruckloadAuditSaveOldDTO,
  TLAuditRefDataOldResponse,
  TLBBillOld,
} from '../dto/TruckloadAuditOLDDTO';
import { Observable } from 'rxjs';
import {
  TruckloadAuditOldUI,
  TruckloadAuditReasonOldUI,
  TruckloadAuditReasonCommentOldUI,
} from '@/pages/tl-audit-old/models/TruckloadAuditOld.ui';
import { AuditUserResponse } from '../../../services/InvoiceAudit';
import { NewTruckloadAuditReasonOld } from '@/pages/tl-audit-old/models/TLAuditOld';

@Injectable()
export class TLAuditServiceOld {
  shipmentApiHeaders: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient, private startupService: StartupService) {}

  public getTLAudits(): Observable<TruckloadAuditOldUI[]> {
    const observable = Observable.create((observer) => {
      return this.http.get<TruckloadAuditOldDTO[]>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/active`).subscribe(
        (data) => {
          if (data) {
            const tlAuditData = data
              .map((v) => new TruckloadAuditOldUI(v))
              .sort((b, a) => (a.auditAge < b.auditAge ? -1 : a.auditAge > b.auditAge ? 1 : 0));
            observer.next(tlAuditData);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }

  public getTLAudit(invoiceID: number, shipmentID: number): Observable<TruckloadAuditOldUI> {
    const observable = Observable.create((observer) => {
      return this.http
        .get<TruckloadAuditOldDTO>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${invoiceID}/${shipmentID}`)
        .subscribe(
          (data) => {
            const tlAuditData = new TruckloadAuditOldUI(data);
            tlAuditData.shipmentID = shipmentID;
            tlAuditData.invoiceID = invoiceID;
            observer.next(tlAuditData);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public getAuditors(): Observable<AuditUserResponse[]> {
    return this.http.get<AuditUserResponse[]>(`${this.startupService.financeAPIUrl}v2/user/tl-auditors`, {
      headers: this.httpOptions.headers,
    });
  }

  public getTLAuditReferenceData(): Observable<TLAuditRefDataOldResponse> {
    const url = `${this.startupService.financeAPIUrl}v2/references/audit-truckload`;
    return this.http.get<TLAuditRefDataOldResponse>(url, {
      headers: this.httpOptions.headers,
    });
  }

  public addTLAudit(invoiceID: number, shipmentID: number, reason: string, comment: string): Observable<TruckloadAuditOldUI> {
    const observable = Observable.create((observer) => {
      this.http
        .post<TruckloadAuditSaveOldDTO>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/`, {
          invoiceID: invoiceID,
          shipmentID: shipmentID,
          reasons: [{ reason: reason, comment: comment }],
        })
        .subscribe(
          (data) => {
            const savedReason = new TruckloadAuditOldUI(null);
            savedReason.setTLAuditFromSave(data);
            observer.next(savedReason);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public addTLReason(newReason: NewTruckloadAuditReasonOld): Observable<TruckloadAuditReasonOldUI> {
    const observable = Observable.create((observer) => {
      this.http
        .post<TruckloadAuditReasonOldDTO>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${newReason.tlAuditID}/reason`, {
          reason: newReason.reason,
          comment: newReason.comment,
        })

        .subscribe(
          (data) => {
            const savedReason = new TruckloadAuditReasonOldUI(data);
            observer.next(savedReason);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public updateTLReason(invoiceAuditID: number, reasonID: number, status: string, comment: string): Observable<TruckloadAuditReasonOldUI> {
    const observable = Observable.create((observer) => {
      this.http
        .put<TruckloadAuditReasonOldDTO>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${invoiceAuditID}/reason/${reasonID}`, {
          status: status,
          comment: comment,
        })
        .subscribe(
          (data) => {
            const savedReason = new TruckloadAuditReasonOldUI(data);
            observer.next(savedReason);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public addComment(invoiceAuditID: number, reasonID: number, comment: string): Observable<TruckloadAuditReasonCommentOldUI> {
    const observable = Observable.create((observer) => {
      this.http
        .post<TLAuditReasonCommentOldDTO>(
          `${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${invoiceAuditID}/reason/${reasonID}/AddComment`,
          {
            comment: comment,
          }
        )
        .subscribe(
          (data) => {
            const savedComment = new TruckloadAuditReasonCommentOldUI(data);
            observer.next(savedComment);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public addAuditor(auditID: number, auditor: string): Observable<TruckloadAuditOldUI> {
    const observable = Observable.create((observer) => {
      this.http
        .patch<TruckloadAuditSaveOldDTO>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${auditID}/add-auditor`, {
          auditor: auditor,
        })
        .subscribe(
          (data) => {
            const savedReason = new TruckloadAuditOldUI(null);
            savedReason.setTLAuditFromSave(data);
            observer.next(savedReason);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public removeAuditor(auditID: number): Observable<TruckloadAuditOldUI> {
    const observable = Observable.create((observer) => {
      this.http
        .patch<TruckloadAuditSaveOldDTO>(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${auditID}/remove-auditor`, null)
        .subscribe(
          (data) => {
            const savedReason = new TruckloadAuditOldUI(null);
            savedReason.setTLAuditFromSave(data);
            observer.next(savedReason);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
    return observable;
  }

  public createTruckloadBBill(bbill: TLBBillOld, carrierInvoiceID: number): Observable<boolean> {
    const observable = Observable.create((observer) => {
      this.http.post(`${this.startupService.financeAPIUrl}v2/audit-invoices/tl/${carrierInvoiceID}/bbill`, bbill).subscribe(
        (data) => {
          observer.next(true);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return observable;
  }
}
